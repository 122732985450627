import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductInstanceForTransaction } from '../../api/productInstances';
import { useProduct } from '../../api/products';
import CallToAction from '../../components/domain/CallToAction';
import HighlightCardElement from '../../components/elements/HighlightCardElement';
import { formatDatetime } from '../../utils';

interface OwnedProductHighlightProps {
    productInstance: ProductInstanceForTransaction;
    onSelect: () => void;
    selectCallToAction?: CallToAction;
}

export function OwnedProductHighlight(props: OwnedProductHighlightProps) {
    const productResult = useProduct(props.productInstance.product);

    const fetchDone = productResult.isSuccess || productResult.isError;

    if (!fetchDone) {
        return (
            <div
                className='spinner-border text-primary align-self-center'
                role='status'></div>
        );
    } else {
        return (
            <HighlightCardElement
                informationElements={[
                    {
                        header: (
                            <FormattedMessage
                                id='views.nike.OrderProductHighlight.productHighlightTableProductHeader'
                                description='In a owned product highlight card, this is the table header for the product name, in the Nike workflow.'
                                defaultMessage='Product'
                            />
                        ),
                        value: productResult.data!.name
                    },
                    {
                        header: (
                            <FormattedMessage
                                id='views.nike.OrderProductHighlight.productHighlightTableOwnedSinceHeader'
                                description='In a owned product highlight card, this is the table header for the owned since date, in the Nike workflow.'
                                defaultMessage='Owned since'
                            />
                        ),
                        value: formatDatetime(props.productInstance.transaction.completed_date)
                    }
                ]}
                callToActions={[
                    {
                        message: props.selectCallToAction ? (
                            props.selectCallToAction.message
                        ) : (
                            <FormattedMessage
                                id='views.nike.OwnedProductHighlight.defaultCallToAction'
                                description='On a owned product highlight card, this is the default call to action button if not other is specified.'
                                defaultMessage='Select'
                            />
                        ),
                        onClick: props.onSelect
                    }
                ]}
            />
        );
    }
}
