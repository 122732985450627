import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useContactAuthentication from '../../../hooks/lookup/authentication/useContactAuthentication';
import useActivity from '../../../hooks/useActivity';
import useScannerValue from '../../../hooks/useScannerValue';
import { Logger } from '../../../logs/Logger';
import badgeNfc from '../../../static/nike/badge-nfc-alt.png';
import { isNfcValue, normalizeNfcValue } from '../../../utils';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ErrorView from '../../../views/common/ErrorView';
import ViewTitle from '../../../views/nike/ViewTitle';
import EmployeeSearchWorkflow from './EmployeeSearchWorkflow';

interface BadgeRegistrationWorkflowProps {
    badgeValue: string | undefined;
    overwriteIsEnabled?: boolean;
    onHome?: () => void;
    onInactivity?: () => void;
}

export default function BadgeRegistrationWorkflow(props: BadgeRegistrationWorkflowProps) {
    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;
    const [currentLookup, changeCurrentLookup] = useState<string | null>(null);
    const [, newActivity] = useActivity();

    const {
        isInProgress: contactAuthenticationIsInProgress,
        isSuccess: contactAuthenticationIsSuccess,
        result: contactResult
    } = useContactAuthentication({ lookupValue: currentLookup });

    useScannerValue((value) => {
        const validNfcValue = isNfcValue(value);
        if (validNfcValue) {
            changeCurrentLookup(normalizeNfcValue(value));
        }
        newActivity();
    });

    // FIXME: This flow should take on some of the responsibilities of the EmployeeSearchWorkflow.
    if (props.badgeValue === undefined && currentLookup == null) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}>
                <div className='flex-grow-1 text-center'>
                    <ViewTitle>
                        <FormattedMessage
                            id='views.nike.BadgeRegistrationWorkflow.overwriteBadge.selectAthlete.title'
                            description='This is the title displayed on the screen telling the manager to scan the new badge (to overwrite the old one with).'
                            defaultMessage='Register new badge'
                        />
                    </ViewTitle>
                    <p>
                        <FormattedMessage
                            id='views.nike.BadgeRegistrationWorkflow.overwriteBadge.selectAthlete.message'
                            description='This is the message displayed on the screen telling the manager to scan the new badge (to overwrite the old one with).'
                            defaultMessage='Scan the new badge that will be used by the employee.'
                        />
                    </p>
                    <img
                        src={badgeNfc}
                        className='dashboard-scan-image mb-3 filter-primary'
                        alt='scan'
                    />
                </div>
            </BaseView>
        );
    } else if (contactResult && contactAuthenticationIsSuccess && !contactAuthenticationIsInProgress) {
        return (
            <ErrorView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
                title={
                    <FormattedMessage
                        id='workflow.nike.registration.ContactAddBadgeWorkflow.errorTitleBadgeInUse'
                        description='The title on the error view when the badge is already in use by somebody else on the add badge workflow'
                        defaultMessage='Your badge could not get registered'
                    />
                }
                message={
                    <FormattedMessage
                        id='workflow.nike.registration.ContactAddBadgeWorkflow.errorMessageBadgeInUse'
                        description='The message on the error view when the badge is already in use by somebody else on the add badge workflow'
                        defaultMessage='This badge is already registered to somebody else. If you think this is a mistake, please contact your coach.'
                    />
                }
            />
        );
    } else {
        return (
            <EmployeeSearchWorkflow
                badgeValue={props.badgeValue ? props.badgeValue : currentLookup!}
                onEmployeeFound={(emp) => {
                    Logger.log('Selected employee in badge registration.', { contact: emp.id }, props.badgeValue ? props.badgeValue : currentLookup!);
                }}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                overwriteIsEnabled={props.overwriteIsEnabled}
            />
        );
    }
}
