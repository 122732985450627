import { useState } from 'react';

import InfoDetailView from '../../views/lending/help/InfoDetailView';
import InfoView from '../../views/lending/help/InfoView';

enum LendingInfoWorkflowViews {
    INFO_MAIN = 'info_main',
    INFO_DETAIL = 'info_detail'
}

interface LendingInfoWorkflowProps {
    onBack: () => void;
    onHome: () => void;
    onInactivity: () => void;
}

const LendingInfoWorkflow = (props: LendingInfoWorkflowProps) => {
    const [currentView, changeCurrentView] = useState(LendingInfoWorkflowViews.INFO_MAIN);
    const [title, changeTitle] = useState<React.ReactNode>('');
    const [content, changeContent] = useState<React.ReactNode>('');

    const getDetailView = (title: React.ReactNode, content: React.ReactNode) => {
        changeTitle(title);
        changeContent(content);
        changeCurrentView(LendingInfoWorkflowViews.INFO_DETAIL);
    };

    switch (currentView) {
        case LendingInfoWorkflowViews.INFO_DETAIL:
            return (
                <InfoDetailView
                    onHome={props.onHome}
                    onBack={() => changeCurrentView(LendingInfoWorkflowViews.INFO_MAIN)}
                    onInactivity={props.onInactivity}
                    title={title}
                    content={content}
                />
            );
        default:
            return (
                <InfoView
                    onBack={props.onBack}
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                    onInfo={getDetailView}
                />
            );
    }
};

export default LendingInfoWorkflow;
