import { useIntl } from 'react-intl';

import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import ReturnWorkflow from '../../../nike/ReturnWorkflow';
import { NikeRole, isNikeContactData } from '../../../nike/api';

type ReturnItemsActionProps = AllowedActionViewProps;

function ReturnItemsAction(props: ReturnItemsActionProps) {
    const intl = useIntl();
    if (props.contact === undefined) return undefined;
    if (
        !isNikeContactData(props.contact.additional_data) ||
        (isNikeContactData(props.contact.additional_data) && props.contact.additional_data.role === NikeRole.DELIVERY)
    )
        return undefined;

    const returnItemsAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.ReturnItemsAction',
            description: 'This is the return action on the Nike dashboard',
            defaultMessage: 'Return item(s)'
        }),
        icon: <i className='bi bi-arrow-repeat dashboard-action-icon' />,
        view: (
            <ReturnWorkflow
                loggedInContact={props.contact}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
                onLogout={props.onHome}
            />
        )
    };

    return returnItemsAction;
}

export default ReturnItemsAction;
