import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest } from '../useManagedWebsocket';

export class WebsocketTerminalOnCommand extends WebsocketCommand {
    data: {
        terminalOn: boolean;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        if (request.value === undefined || typeof request.value !== 'boolean') throw new Error('Incorect information passed.');
        this.data = {
            terminalOn: request.value
        };
    }

    public async execute(): Promise<WebsocketCommandResponse> {
        window.location.href = this.data.terminalOn === false ? '/standby' : '/';
        return { success: true };
    }
}
