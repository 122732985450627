import React from 'react';

export interface HelpActionProps {
    title?: React.ReactNode;
    subtext?: React.ReactNode;
    onClick?: () => void;
}

function HelpAction(props: HelpActionProps) {
    return (
        <button
            className='card w-100 my-4'
            onClick={props.onClick}>
            <div className='card-body w-100'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                        {props.title ? <h4 className={props.subtext ? '' : 'mb-0'}>{props.title}</h4> : undefined}
                        {props.subtext ? <p className='mb-0'>{props.subtext}</p> : undefined}
                    </div>
                    <h2 className='text-primary bg-white mb-0'>
                        <i className='bi bi-arrow-right-circle-fill' />
                    </h2>
                </div>
            </div>
        </button>
    );
}

export default HelpAction;
