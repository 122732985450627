import { useEffect } from 'react';

import { ServiceWorkerInitState, TerminalInitState } from '../TerminalInit';
import { Logger } from '../logs/Logger';

interface Props {
    initialTerminalState: TerminalInitState;
    initialServiceWorkerState: ServiceWorkerInitState;
}

const healthCheckTimer = 3 * 60 * 1000; //3 minutes

export default function useTerminalStartupHealthCheck(props: Props) {
    useEffect(() => {
        if (props.initialTerminalState === TerminalInitState.DONE && props.initialServiceWorkerState === ServiceWorkerInitState.DONE) {
            Logger.log('No longer checking terminal and service worker init state as they are both on DONE.');
        } else {
            const timer = setTimeout(() => {
                Logger.log(
                    "Terminal and serviceworker state didn't change for 7 minutes -> refreshing screen.",
                    {},
                    { terminalState: props.initialTerminalState, serviceWorkerState: props.initialServiceWorkerState }
                );
                window.location.href = '/';
            }, healthCheckTimer);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [props.initialTerminalState, props.initialServiceWorkerState]);
}
