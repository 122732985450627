import { MdOutlineLocalLaundryService } from 'react-icons/md';
import { useIntl } from 'react-intl';

import { useContactGroups } from '../../api/contactGroups';
import { Contact } from '../../api/contacts';
import { TransactionType } from '../../common/transactions';
import ActionCard from '../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../components/domain/AllowedAction';
import { isLaundry } from '../../services/contact/GlobalPermissions';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import JustDrop from '../JustDrop';

const CompanyName = 'Van Ende Roxy';

type LaundryVanendeRoxyProps = AllowedActionViewProps;

const LaundryVanendeRoxy = (props: LaundryVanendeRoxyProps) => {
    let company = null;
    const { data: deliverers, isSuccess, isIdle } = useContactGroups();
    if (isSuccess && deliverers) {
        company = deliverers.find((d) => d.name == CompanyName);
    }
    if (!isIdle && !isSuccess) {
        return (
            <LoadingView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    } else if (isSuccess && company != null) {
        return (
            <JustDrop
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
                senderData={{
                    senderContact: props.contact as Contact
                }}
                receiverData={{
                    contactGroup: company
                }}
                autoAssignSlot={false}
                transactionType={TransactionType.PUDO}
            />
        );
    } else if (company == null) {
        return <ErrorView title={'Could not find Van Ende Roxy in your contacts.'} />;
    }
    return <ErrorView title={'something went wrong.'} />;
};

function LaundryVanendeRoxyAction(props: AllowedActionViewProps) {
    const intl = useIntl();
    if (!isLaundry(props.contact)) return undefined;
    const LaundryVanendeRoxyAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.AuthorisedContactWorkflow.LaundryVanendeAction',
            description: 'This is the Laundry Vanende action for AuthorisedContactWorkflow',
            defaultMessage: 'Laundry dropoff'
        }),
        icon: (
            <MdOutlineLocalLaundryService
                className='dashboard-action-icon'
                size={264}
            />
        ),
        view: <LaundryVanendeRoxy {...props} />,
        highlight: isLaundry(props.contact)
    };
    return LaundryVanendeRoxyAction;
}

export default LaundryVanendeRoxyAction;
