import { Transaction } from '../../common/transactions';

export function sortTransactionsByCreatedOnDate(transactions: Transaction[]): Transaction[] {
    return transactions.sort((a, b) => {
        return new Date(a.created_date).getTime() - new Date(b.created_date).getTime();
    });
}
export function sortTransactionsByCompletedDate(transactions: Transaction[]): Transaction[] {
    return transactions.sort((a, b) => {
        const a_date = a.completed_date !== null ? a.completed_date : 0;
        const b_date = b.completed_date !== null ? b.completed_date : 0;
        return new Date(a_date).getTime() - new Date(b_date).getTime();
    });
}
