import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import useActivity from '../../hooks/useActivity';

interface ScannerModalProps {
    show: boolean;
    changeShow: (value: boolean) => void;
    children?: React.ReactNode;
    lending?: boolean;
}

const ScannerModal = (props: ScannerModalProps) => {
    const [, newActivity] = useActivity();

    const handleClose = () => {
        newActivity();
        props.changeShow(false);
    };

    return (
        <Modal
            centered
            size='xl'
            show={props.show}
            onHide={handleClose}>
            <Modal.Body>
                <div className='modal-body'>
                    {props.children ? (
                        props.children
                    ) : (
                        <div>
                            <h5 className='font-weight-bold'>
                                <FormattedMessage
                                    id='views.elements.ScannerModal.ScanOptionsIntro'
                                    description='This is the intro of the scan options on the scanner modal'
                                    defaultMessage='To access the distriSPOT you can:'
                                />
                            </h5>
                            <ul className='modal-body modal-body-element'>
                                <li>
                                    <FormattedMessage
                                        id='views.elements.ScannerModal.ScanOptions1'
                                        description='The first of the scan options on the scanner modal'
                                        defaultMessage='Scan your Barcode or QR code on the scanner.'
                                    />
                                </li>
                                {props.lending ? (
                                    <></>
                                ) : (
                                    <li>
                                        <FormattedMessage
                                            id='views.elements.ScannerModal.ScanOptions2'
                                            description='The second of the scan options on the scanner modal'
                                            defaultMessage='Scan your Employee Badge on the RFID scanner. Look for the {rfdIcon} icon'
                                            values={{
                                                rfdIcon: (
                                                    <h4 className='m-0 d-inline-block'>
                                                        <i className='bi bi-broadcast' />
                                                    </h4>
                                                )
                                            }}
                                        />
                                    </li>
                                )}
                                <li>
                                    <FormattedMessage
                                        id='views.elements.ScannerModal.ScanOptions3'
                                        description='The third of the scan options on the scanner modal'
                                        defaultMessage='Select one of the other menu options.'
                                    />
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-center'>
                <div className='modal-footer'>
                    <button
                        className='primary-button btn-lg'
                        onClick={handleClose}>
                        <FormattedMessage
                            id='views.elements.ScannerModal.closeButton'
                            description='The close button on the scanner modal'
                            defaultMessage='Close'
                        />
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ScannerModal;
