import { Product } from '../../api/products';
import { Transaction } from '../../common/transactions';

interface ProductInformationCompactProps {
    product: Product;
    transactions: Transaction[];
    onMarkTransactions?: () => void;
}

function ProductInformationCompact(props: ProductInformationCompactProps) {
    return (
        <p className='d-flex align-items-center'>
            <span className='font-weight-bold pe-1'>{props.transactions.length}x </span> {props.product.name}
            <span
                className='text-primary fs-3 bg-white ms-3'
                onClick={props.onMarkTransactions}>
                <i className='bi bi-arrow-right-circle-fill' />
            </span>
        </p>
    );
}

export default ProductInformationCompact;
