import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface BicycleQuestionModalProps {
    open: boolean;
    onAnswer: (answer: string) => void;
}

const BicycleQuestionModal = (props: BicycleQuestionModalProps) => {
    const bikeTypes: string[] = ['Standard Bike', 'Speed Pedelec', 'E-Bike'];
    const [answer, changeAnswer] = useState('');

    const onAnswer = () => {
        props.onAnswer(answer);
    };
    return (
        <Modal
            centered
            size='lg'
            animation={false}
            show={props.open}>
            <Form
                onSubmit={(event) => {
                    onAnswer();
                    event.preventDefault();
                }}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label htmlFor='selectBykeType'>
                            <FormattedMessage
                                id='views.BicycleQuestionModal.ModalQuestionTitle'
                                description='The title of the bicycle question modal.'
                                defaultMessage='What type of bike are you using?'
                            />
                        </Form.Label>
                        <Form.Control
                            as='select'
                            id='selectBykeType'
                            value={answer}
                            onChange={(event) => changeAnswer(event.target.value)}>
                            {bikeTypes.map((bikeType) => {
                                return (
                                    <option
                                        key={bikeType}
                                        value={bikeType}>
                                        {bikeType}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-center'>
                    <button
                        type='submit'
                        className='primary-button btn-lg'>
                        <FormattedMessage
                            id='views.BicycleQuestionModal.confirmAnswer'
                            description='The button label to confirm the bicycle question answer.'
                            defaultMessage='Submit'
                        />
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default BicycleQuestionModal;
