import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Contact } from '../../../api/contacts';
import { Product, useProducts } from '../../../api/products';
import { useShops } from '../../../api/shops';
import { Slot, SlotForTransaction, getRealIdFromId, getTransactionsFromSlot } from '../../../api/slots';
import { SpotLayoutItem, useSpotLayoutItems } from '../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { StockFill, getValidSlotsIncludedInSFR, useMutateConfirmStockFillReport } from '../../../api/stockFills';
import { useMutateCreateTransaction, useMutateTransactionStatus, useMutateUpdateTransaction, useTransactions } from '../../../api/transactions';
import { TransactionStatus, TransactionStatusEventType, TransactionType, UpdateTransactionStatus } from '../../../common/transactions';
import useActivity from '../../../hooks/useActivity';
import useTransactionsWithSlots from '../../../hooks/useTransactionsWithSlots';
import { Logger } from '../../../logs/Logger';
import { AuthenticatedContact } from '../../../services/contact/GlobalPermissions';
import { getRandomString } from '../../../utils';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import LoadingView from '../../../views/common/LoadingView';
import ManualStockRefillChangeOverview from '../../../views/vending/ManualStockRefillChangeOverview';

interface ManualStockRefillTransactionUpdateProps {
    contact: AuthenticatedContact | Contact | undefined;
    report: StockFill | undefined;
    selectedSlots: Slot[] | undefined;
    transactionSlotMap: Map<string, SlotForTransaction[]>;
    spotSlotMap: Map<SpotLayoutItem, Slot>;
    editSelection: () => void;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

const ManualStockRefillTransactionUpdate = (props: ManualStockRefillTransactionUpdateProps) => {
    const [, newActivity] = useActivity();

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: transactionSlotMap } = useTransactionsWithSlots({
        spotId: spot?.id
    });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);

    const [originalSlots] = useState<Slot[] | undefined>(
        props.report !== undefined ? getValidSlotsIncludedInSFR(transactionSlotMap, props.report.id) : undefined
    );
    const [includedSlots, changeIncludedSlots] = useState<Slot[] | undefined>();
    const [excludedSlots, changeExcludedSlots] = useState<Slot[] | undefined>();
    useEffect(() => {
        const include: Slot[] = [];
        const exclude: Slot[] = [];
        props.selectedSlots?.forEach((slot) => {
            const isFound = originalSlots?.find((s) => s.id === slot.id);
            if (isFound === undefined) {
                include.push(slot);
            }
        });
        originalSlots?.forEach((slot) => {
            const isFound = props.selectedSlots?.find((s) => s.id === slot.id);
            if (isFound === undefined) {
                exclude.push(slot);
            }
        });
        changeIncludedSlots(include);
        changeExcludedSlots(exclude);
    }, [originalSlots, props.selectedSlots]);

    const [toUpdateAmount, changeToUpdateAmount] = useState<number>(0);

    useEffect(() => {
        changeToUpdateAmount(() => {
            let amount = 0;
            if (excludedSlots && excludedSlots.length >= 1) amount += 1;
            if (includedSlots && includedSlots.length >= 1) amount += 1;
            return amount;
        });
    }, [excludedSlots, includedSlots]);

    const [updatePending, changeUpdatePending] = useState<boolean | undefined>(undefined);
    const [stockRefillUpdateSuccess, changeStockRefillUpdateSuccess] = useState<boolean | undefined>(undefined);
    useTransactions(undefined, {
        enabled: stockRefillUpdateSuccess === true,
        onSuccess: (transactions) => {
            changeStockRefillUpdateSuccess(false);
            changeUpdatePending(false);
        }
    });
    const confirmStockFillReport = useMutateConfirmStockFillReport({
        onSuccess: (stockFill) => {
            Logger.log('confirmed stockfill report', { contact: props.contact?.id }, stockFill);
            changeStockRefillUpdateSuccess(true);
            changeUpdatePending(false);
        },
        onError: (err) => {
            Logger.error(err);
            changeStockRefillUpdateSuccess(false);
        }
    });
    const dropoffTransaction = useMutateTransactionStatus({
        onSuccess: (transaction) => {
            changeToUpdateAmount(toUpdateAmount - 1);
        },
        onError: (err) => {
            changeToUpdateAmount(toUpdateAmount - 1);
            Logger.error(err);
        }
    });
    const cancelTransaction = useMutateUpdateTransaction({
        onSuccess: (transaction) => {
            changeToUpdateAmount(toUpdateAmount - 1);
        },
        onError: (err) => {
            changeToUpdateAmount(toUpdateAmount - 1);
            Logger.error(err);
        }
    });
    const createTransaction = useMutateCreateTransaction({
        onSuccess: (transaction) => {
            if (props.report === undefined) {
                dropoffTransaction.mutateAsync({
                    transaction: transaction,
                    transactionStatusUpdate: {
                        event: TransactionStatusEventType.DROPOFF,
                        dropoff: {
                            slot_id: transaction.slot_id
                        }
                    }
                });
            } else changeToUpdateAmount(toUpdateAmount - 1);
        },
        onError: (err) => Logger.error(err)
    });

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.report ? props.onBack : props.editSelection}
        />
    );

    useEffect(() => {
        if (props.report !== undefined && toUpdateAmount === 0 && updatePending === true) {
            confirmStockFillReport.mutate({
                report_code: props.report.report_code,
                updateVariables: {
                    completed_by: props.contact!.id,
                    account: props.contact!.account
                }
            });
        } else if (toUpdateAmount === 0 && updatePending === true) {
            changeUpdatePending(false);
        }
    }, [toUpdateAmount, updatePending]);

    const isBeingFilled = (item: SpotLayoutItem): boolean => {
        if (props.selectedSlots) {
            const isDefinetlyFilled = props.selectedSlots.find((s) => s.id === item.slot);
            if (isDefinetlyFilled !== undefined) return true;
        }
        return false;
    };

    const getSlotMap = (): Map<Product | null, SpotLayoutItem[]> | undefined => {
        if (spotLayoutItems === undefined) {
            return undefined;
        }
        const result: Map<Product | null, SpotLayoutItem[]> = new Map();
        spotLayoutItems.forEach((item) => {
            if (item.slot) {
                const product = products?.find((prod) => prod.slots.includes(getRealIdFromId(item.slot as string)));
                if (product && result.get(product)) {
                    result.set(product, [...result.get(product)!, item]);
                } else if (product) {
                    result.set(product, [item]);
                } else {
                    if (result.get(null)) {
                        result.set(null, [...result.get(null)!, item]);
                    } else result.set(null, [item]);
                }
            }
        });
        return result;
    };

    const submit = () => {
        newActivity();
        changeUpdatePending(true);
        if (includedSlots) {
            includedSlots.forEach((slot) => {
                const product = products?.find((p) => p.slots.includes(+slot.id));
                const transactionArray = getTransactionsFromSlot(props.transactionSlotMap, slot);
                const transaction = transactionArray.find((t) => TransactionStatus.before_dropoff_states().includes(t.status));
                if (transaction === undefined) {
                    createTransaction.mutate({
                        createTransaction: {
                            account_id: spot!.account,
                            slot_id: slot!.id,
                            tt_number: getRandomString(20),
                            receiver_id: undefined,
                            receiver_group_id: undefined,
                            sender_id: props.contact?.id,
                            sender_group_id: undefined,
                            shipping_notes: 'none',
                            notifications_config: undefined,
                            product_ids: product ? [product?.id] : [],
                            product_instances: undefined,
                            product_instance_ids: undefined,
                            type: TransactionType.VENDING
                        }
                    });
                } else if (props.report === undefined) {
                    dropoffTransaction.mutate({
                        transaction: transaction,
                        transactionStatusUpdate: {
                            event: TransactionStatusEventType.DROPOFF,
                            dropoff: {
                                slot_id: transaction.slot_id
                            }
                        }
                    });
                }
            });
        }
        if (excludedSlots) {
            excludedSlots.forEach((slot) => {
                const slotForTrans = props.transactionSlotMap.get(slot.id);
                if (slotForTrans) {
                    cancelTransaction.mutate({
                        transaction: slotForTrans[0].transaction,
                        updateTransaction: {
                            status: UpdateTransactionStatus.CANCELLED
                        }
                    });
                }
            });
        }
    };

    if (updatePending === undefined) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}>
                <ManualStockRefillChangeOverview
                    spotSlotMap={props.spotSlotMap}
                    slotProductMap={getSlotMap()}
                    isBeingFilled={isBeingFilled}
                    editSelection={() => {
                        newActivity();
                        props.editSelection();
                    }}
                />
                <div className='d-flex flex-column align-items-center'>
                    <button
                        className='primary-button primary-button-small'
                        onClick={submit}>
                        submit
                    </button>
                </div>
            </BaseView>
        );
    } else if (updatePending === true) {
        return (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.vending.management.manualstockrefillupdate.loadingscreen'
                        description='The stock update loading message to inform the user that the update will run in the background.'
                        defaultMessage='The stock is being updated. This may take a while so if you are finished you can go back to the homescreen. The update will continue in the background.'
                    />
                }
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else {
        props.onHome ? props.onHome() : props.onInactivity && props.onInactivity();
        //this screen should not be shown but it is there as a failsafe.
        return (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.vending.management.manualstockrefillupdate.updateFinished'
                        description='The stock update loading message to inform the user that the update is finished and he/she will be redirected to home.'
                        defaultMessage='The update is finished you are being redirected to the home screen.'
                    />
                }
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    }
};

export default ManualStockRefillTransactionUpdate;
