import { partition } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CgSoftwareDownload, CgSoftwareUpload } from 'react-icons/cg';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Product } from '../../api/products';
import { useSlots } from '../../api/slots';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import ProductListItemSlotsField from './ProductListItemSlotsField';
import { SpotLayoutItemProductAmount } from './WarehouseWorkflow';

interface Props {
    products: Product[];
    instances: SpotLayoutItemProductAmount[];
    buttonContent?: JSX.Element;
    update?: (prodId: number) => void;

    listInfoCallback?: (info: ListInfo[]) => void;
    dropoff?: boolean;
}

export interface ListInfo {
    total: number;
    prod: Product;
    slots: string[];
    props: Props;
}

export default function ProductList(props: Props) {
    const [incorrectData, changeIncorrectData] = useState<ListInfo[]>();
    const [correctData, changeCorrectData] = useState<ListInfo[]>();

    useEffect(() => {
        const result: ListInfo[] = [];
        props.products.forEach((prod) => {
            const instances = props.instances.filter((p) => p.product.id === prod.id);
            let total = 0;
            const slots: string[] = [];
            instances.forEach((i) => {
                if (i.amount !== 0 && i.spotLayoutItem.slot !== undefined) slots.push(i.spotLayoutItem.slot);
                total += i.amount;
            });
            if (total !== 0) result.push({ total, prod, slots, props });
        });
        const [correct, incorrect] = partition(result, (o) => o.total > 0);

        correct.sort(sortAlphabetically);
        incorrect.sort(sortAlphabetically);

        changeCorrectData(correct);
        changeIncorrectData(incorrect);
        props.listInfoCallback && props.listInfoCallback(result);
    }, [props.products, props.instances]);

    return (
        <>
            {incorrectData && incorrectData.length > 0 && (
                <>
                    <p>
                        <FormattedMessage
                            id='views.WarehouseWorkflow.checkout.ProductList.AddedItemsError.message'
                            description='The message telling the user that some scales measured an increase in weight (this is not allowed)'
                            defaultMessage='Some items were added to the scales, please remove them again.'
                        />
                    </p>
                    <div className='information-card white shadow-none'>
                        <div className='card-body d-flex flex-column'>
                            {incorrectData.map((o) => {
                                return (
                                    <ProductListItem
                                        key={o.prod.id}
                                        product={o.prod}
                                        amount={o.total}
                                        buttonContent={props.buttonContent}
                                        update={props.update}
                                        dropoff={props.dropoff}
                                        slots={o.slots}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
            {correctData && correctData.length > 0 && (
                <div className={'information-card white shadow-none' + (incorrectData && incorrectData.length > 0 && ' opacity-25')}>
                    <div className='card-body d-flex flex-column'>
                        {correctData.map((o) => {
                            return (
                                <ProductListItem
                                    key={o.prod.id}
                                    product={o.prod}
                                    amount={o.total}
                                    buttonContent={props.buttonContent}
                                    update={props.update}
                                    dropoff={props.dropoff}
                                    slots={o.slots}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}

interface PoductProps {
    product: Product;
    amount: number;
    buttonContent?: JSX.Element;
    update?: (prodId: number) => void;
    slots: string[];

    dropoff?: boolean;
}

function ProductListItem(props: PoductProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: slots } = useSlots({ spot: spot?.id });
    const style = props.amount > 0 ? '' : 'text-danger';
    return (
        <div className='d-flex flex-row justify-content-between align-items-start pb-0 w-100'>
            <div className='d-flex flex-column w-50'>
                <p>{props.product.name}</p>
                {props.product.image && (
                    <img
                        src={props.product.image}
                        width={150}
                        className='mt-1'
                    />
                )}
            </div>
            <div className='d-flex flex-column justify-content-between align-items-start w-25'>
                <span className='d-flex flex-column justify-content-start align-items-start col-12'>
                    <h5 className='mb-0'>EAN</h5>
                    <span className='text-truncate w-100'>{props.product.ean}</span>
                </span>
                <span>
                    <ProductListItemSlotsField
                        slotIds={props.slots}
                        slots={slots}
                    />
                </span>
            </div>
            <div className='d-flex flex-column align-items-end w-25'>
                <h5>
                    <FormattedMessage
                        id='ProductListItem.Amount.label'
                        description='The label indecating the amount field on the product list item for the warehouse workflow.'
                        defaultMessage='Amount'
                    />
                </h5>
                <p className={style}>
                    {props.amount > 0 ? (
                        <>
                            {props.dropoff !== true ? <CgSoftwareUpload /> : <CgSoftwareDownload />}
                            {props.amount}
                        </>
                    ) : (
                        <>
                            {props.dropoff !== true ? <CgSoftwareDownload /> : <CgSoftwareUpload />}
                            {-props.amount}
                        </>
                    )}
                </p>

                {props.update !== undefined && props.buttonContent !== undefined && props.amount > 0 && (
                    <button
                        className='primary-button ms-2'
                        onClick={() => props.update!(props.product.id)}>
                        {props.buttonContent}
                    </button>
                )}
            </div>
        </div>
    );
}

function sortAlphabetically(a: ListInfo, b: ListInfo) {
    if (a.slots[0] < b.slots[0]) {
        return -1;
    }
    if (a.slots[0] > b.slots[0]) {
        return 1;
    }
    return 0;
}
