import { memo, useEffect, useState } from 'react';

import { Slot, getColumnNumber, getRowNumber } from '../../api/slots';
import { SpotLayoutItem } from '../../api/spotLayoutItems';
import useActivity from '../../hooks/useActivity';

interface LargeMultiSpotLayoutProps {
    clickableSpotlayoutItem: (item: SpotLayoutItem, isAlreadySelected: boolean) => boolean;
    alreadySelected?: Slot[];
    spotLayout: Map<string, SpotLayoutItem[]>;
    onSubmit: (items: SpotLayoutItem[]) => void;
    onCancel: () => void;
    spotSlotMap: Map<SpotLayoutItem, Slot>;
}

interface LargeMultiSpotRowSelectProps {
    clickableSpotlayoutItem: (item: SpotLayoutItem, isAlreadySelected: boolean) => boolean;
    rowKeys: string[];
    selectedRow?: string;
    selectRow: (key: string) => void;
    isAlreadySaved: (key: string) => boolean;
}

interface LargeMultiSpotColumnSelectProps {
    clickableSpotlayoutItem: (item: SpotLayoutItem, isAlreadySelected: boolean) => boolean;
    columnItems: SpotLayoutItem[];
    selectedColumns: string[];
    selectColumn: (item: SpotLayoutItem | SpotLayoutItem[], isAlreadySelected: boolean) => void;
    getLabel: (item: SpotLayoutItem) => string;
    spotSlotMap: Map<SpotLayoutItem, Slot>;
}

const LargeMultiSpotLayout = (props: LargeMultiSpotLayoutProps) => {
    const [, newActivity] = useActivity();
    const [selection, changeSelection] = useState<Map<string, SpotLayoutItem[]>>(new Map());
    const [selectedRow, changeSelectedRow] = useState<string | undefined>();
    const [possibleColumns, changePossibleColumns] = useState<SpotLayoutItem[]>([]);
    const [selectedColumns, changeSelectedColumns] = useState<string[]>([]);

    const onSubmit = () => {
        newActivity();
        const selected: SpotLayoutItem[] = [];
        selection.forEach((row) => {
            selected.push(...row);
        });
        if (selected.length > 0) {
            props.onSubmit(selected);
            changeSelection(new Map());
            changeSelectedRow(undefined);
            changePossibleColumns([]);
            changeSelectedColumns([]);
        }
    };

    useEffect(() => {
        if (props.alreadySelected) {
            const map = new Map();
            props.alreadySelected!.forEach((s) => {
                if (map.get(getRowNumber(s))) {
                    map.set(getRowNumber(s), [...map.get(getRowNumber(s)), getSpotFromSlot(props.spotSlotMap, s)]);
                } else {
                    map.set(getRowNumber(s), [getSpotFromSlot(props.spotSlotMap, s)]);
                }
            });
            changeSelection(map);
        }
    }, [props.alreadySelected]);

    useEffect(() => {
        if (selectedRow === undefined || props.spotLayout.get(selectedRow) === undefined) {
            changePossibleColumns([]);
        } else {
            changePossibleColumns(props.spotLayout.get(selectedRow)!);
            const alreadySelectedColumns = selection.get(selectedRow);
            if (alreadySelectedColumns) {
                changeSelectedColumns(
                    alreadySelectedColumns.map((c) => {
                        const slot = props.spotSlotMap.get(c);
                        if (slot) return getColumnNumber(slot);
                        return '';
                    })
                );
            } else changeSelectedColumns([]);
        }
    }, [props.spotLayout, selectedRow, props.clickableSpotlayoutItem, props.alreadySelected]);

    const updateSelectedColumns = (column: SpotLayoutItem | SpotLayoutItem[], isAlreadySelected: boolean) => {
        newActivity();
        if (column instanceof Array) {
            column = column.filter((item) => props.clickableSpotlayoutItem(item, isAlreadySelected));
            const result = column.map((item) => {
                const slot = props.spotSlotMap.get(item);
                if (slot) return getColumnNumber(slot);
                return '';
            });
            changeSelectedColumns(result);
            saveSelected(result);
        } else {
            const slot = props.spotSlotMap.get(column);
            if (slot) {
                let result = [...selectedColumns];
                if (result.includes(getColumnNumber(slot))) {
                    result = result.filter((c) => c !== getColumnNumber(slot!));
                    changeSelectedColumns(result);
                } else {
                    result = [...result, getColumnNumber(slot)];
                    changeSelectedColumns(result);
                }
                saveSelected(result);
            }
        }
    };

    const saveSelected = (selectedColumns: string[]) => {
        if (selectedRow) {
            const allItems = props.spotLayout.get(selectedRow);
            if (allItems) {
                const updatedRows = new Map([...selection]);
                const filteredItems = allItems.filter((item) => {
                    const slot = props.spotSlotMap.get(item);
                    return slot !== undefined && selectedColumns.find((i) => i === getColumnNumber(slot!)) !== undefined;
                });
                updatedRows.set(selectedRow, filteredItems);
                changeSelection(updatedRows);
            }
        }
    };

    const getColumnLabel = (item: SpotLayoutItem) => {
        const slot = props.spotSlotMap.get(item);
        return slot ? getColumnNumber(slot) : '';
    };

    const isAlreadySaved = (key: string): boolean => {
        const itterableKeys = selection.keys();
        let itterableValue = itterableKeys.next();
        while (itterableValue.value) {
            if (itterableValue.value === key) return true;
            itterableValue = itterableKeys.next();
        }
        return false;
    };

    function isSubmittable(): boolean {
        const selected: SpotLayoutItem[] = [];
        selection.forEach((row) => {
            selected.push(...row);
        });
        return selected.length === 0;
    }

    return (
        <div className='multiSpot multiSpot-large'>
            <span>
                <LargeMultiSpotRowSelect
                    clickableSpotlayoutItem={props.clickableSpotlayoutItem}
                    rowKeys={[...props.spotLayout.keys()]}
                    selectRow={(row) => {
                        newActivity();
                        changeSelectedRow(row);
                    }}
                    selectedRow={selectedRow}
                    isAlreadySaved={isAlreadySaved}
                />
                <LargeMultiSpotColumnSelect
                    spotSlotMap={props.spotSlotMap}
                    getLabel={getColumnLabel}
                    clickableSpotlayoutItem={props.clickableSpotlayoutItem}
                    columnItems={possibleColumns}
                    selectColumn={updateSelectedColumns}
                    selectedColumns={selectedColumns}
                />
            </span>
            <div className='d-flex flex-column align-items-center'>
                <button
                    disabled={isSubmittable()}
                    className='success-button success-button-small'
                    onClick={onSubmit}>
                    submit
                </button>
                <button
                    className='warning-button warning-button-small'
                    onClick={props.onCancel}>
                    cancel
                </button>
            </div>
        </div>
    );
};

const LargeMultiSpotRowSelect = (props: LargeMultiSpotRowSelectProps) => {
    return (
        <div className='select'>
            <div>
                <p>Row</p>
            </div>
            {props.rowKeys
                .sort((a, b) => parseFloat(a) - parseFloat(b))
                .map((key) => {
                    let style = '';
                    if (props.isAlreadySaved(key)) style = 'saved-row';
                    if (props.selectedRow && props.selectedRow === key) style = 'selected';
                    return (
                        <button
                            key={key}
                            className={style}
                            onClick={() => props.selectRow(key)}>
                            {key}
                        </button>
                    );
                })}
        </div>
    );
};

const LargeMultiSpotColumnSelect = (props: LargeMultiSpotColumnSelectProps) => {
    const allSelected =
        props.columnItems.filter((i) => {
            return props.clickableSpotlayoutItem(i, false);
        }).length === props.selectedColumns.length;

    return (
        <div className='select'>
            <div>
                <p>Column</p>
                {props.columnItems.length === 0 ? (
                    <></>
                ) : (
                    <button
                        onClick={(e) => {
                            props.selectColumn(allSelected ? [] : props.columnItems, allSelected);
                        }}>
                        {allSelected ? 'Clear all' : 'All'}
                    </button>
                )}
            </div>
            {props.columnItems.length === 0 ? (
                <p>Please first select a row.</p>
            ) : (
                props.columnItems.map((item) => {
                    let style = '';
                    const slot = props.spotSlotMap.get(item);
                    if (slot) {
                        if (props.selectedColumns.includes(getColumnNumber(slot!))) {
                            style = 'selected';
                        } else if (!props.clickableSpotlayoutItem(item, props.selectedColumns.includes(getColumnNumber(slot!)))) style = 'disabled';
                        return (
                            <button
                                key={item.id}
                                disabled={!props.clickableSpotlayoutItem(item, props.selectedColumns.includes(getColumnNumber(slot!)))}
                                className={style}
                                onClick={() => props.selectColumn(item, props.selectedColumns.includes(getColumnNumber(slot!)))}>
                                {props.getLabel(item)}
                            </button>
                        );
                    }
                })
            )}
        </div>
    );
};

export default memo(LargeMultiSpotLayout);

function getSpotFromSlot(spotSlotMap: Map<SpotLayoutItem, Slot>, slot: Slot): SpotLayoutItem | undefined {
    let item;
    spotSlotMap.forEach((val, key) => {
        if (val.id === slot.id) {
            item = key;
            return;
        }
    });
    return item;
}
