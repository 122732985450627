import { useEffect, useState } from 'react';

import { LookupState } from './transactions/actions';

export interface LookupImplementation<T, S> {
    lookupValue?: S | null;
    requiredDataFetched: boolean;
    result?: T | null;
    refetchTransactions: () => void;
    isRefetching: boolean;
    error?: boolean;
}

export interface LookupResult<T, S> {
    lookupValue?: S;
    result?: T | null;
    state: LookupState;
    isSuccess: boolean;
    isError: boolean;
    isInProgress: boolean;
    isIdle: boolean;
    isRefetching: boolean;
}

interface CurrentLookup<S> {
    value: S;
}

function useBaseLookup<T, S>(lookupImpl: LookupImplementation<T, S>): LookupResult<T, S> {
    const [currentLookup, changeCurrentLookup] = useState<CurrentLookup<S> | null>(null);
    const [state, changeState] = useState(LookupState.IDLE);

    const isIdle = state === LookupState.IDLE;
    const isInProgress = state === LookupState.IN_PROGRESS;
    const isRefetching = state === LookupState.REFETCHING;
    const isSuccess = state === LookupState.SUCCESS;
    const isError = state === LookupState.ERROR;

    useEffect(() => {
        if (lookupImpl.error === true) {
            changeState(LookupState.ERROR);
        }
        if (isInProgress) {
            if (lookupImpl.requiredDataFetched) {
                if (!lookupImpl.result || (Array.isArray(lookupImpl.result) && lookupImpl.result.length === 0)) {
                    lookupImpl.refetchTransactions();
                    changeState(LookupState.REFETCHING);
                } else {
                    changeState(LookupState.SUCCESS);
                }
            }
        } else if (isRefetching) {
            if (lookupImpl.requiredDataFetched && !lookupImpl.isRefetching) {
                changeState(LookupState.SUCCESS);
            }
        }
    }, [
        isInProgress,
        isRefetching,
        lookupImpl,
        lookupImpl.error,
        lookupImpl.requiredDataFetched,
        lookupImpl.result,
        lookupImpl.refetchTransactions,
        changeState,
        lookupImpl.isRefetching
    ]);

    useEffect(() => {
        if (lookupImpl.lookupValue === null || lookupImpl.lookupValue === undefined) {
            changeCurrentLookup(null);
            changeState(LookupState.IDLE);
        } else {
            changeCurrentLookup({
                value: lookupImpl.lookupValue
            });
            changeState(LookupState.IN_PROGRESS);
        }
    }, [lookupImpl.lookupValue]);

    return {
        lookupValue: currentLookup?.value,
        result: lookupImpl.result,
        state: state,
        isSuccess,
        isError,
        isInProgress,
        isIdle,
        isRefetching
    };
}

export default useBaseLookup;
