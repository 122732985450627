const useSelectTerminalDriver = (windowsTerminal) => {
    try {
        // windows drivers
        // eslint-disable-next-line no-undef
        if (windowsTerminal !== null) {
            return windowsTerminal.TerminalApp;
        } else {
            return window.TerminalApp;
        }
    } catch {
        // android drivers
        return window.TerminalApp;
    }
};

export default useSelectTerminalDriver;
