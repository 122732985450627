import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { Contact } from '../api/contacts';
import { Slot, SlotForTransaction, useSlots } from '../api/slots';
import { SpotLayoutItem, useSpotLayoutItems } from '../api/spotLayoutItems';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import { Transaction, TransactionType } from '../common/transactions';
import useActivity from '../hooks/useActivity';
import useOpenSlot from '../hooks/useOpenSlot';
import { useAllSpotLayoutItemsForSlots } from '../hooks/useSpotLayoutItemForSlot';
import { Logger } from '../logs/Logger';
import { isAdmin, isDelivery } from '../services/contact/GlobalPermissions';
import BackAndHomeNavigationButtons from '../views/common/BackAndHomeNavigationButtons';
import BaseView from '../views/common/BaseView';
import ErrorView from '../views/common/ErrorView';
import LoadingView from '../views/common/LoadingView';
import SlotInformation from '../views/elements/SlotInformation';
import SpotLayout from '../views/elements/SpotLayout';
import { isNikeDeliveryPerson } from './nike/permissions';

interface SlotsOverviewProps {
    filterObject?: JSX.Element;
    transactionTypeFilter?: TransactionType[];
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    transactions: Map<string, SlotForTransaction[]>;
    highlightedItems?: SpotLayoutItem[];
    selectableItems?: SpotLayoutItem[];
    loggedInContact?: Contact;
    title?: React.ReactNode;

    cancelTransaction?: (transaction: Transaction) => void;
    changeTransactionSlot?: (transaction: Transaction) => void;
    removeTransaction?: (transaction: Transaction) => void;
}

const SlotsOverview = (props: SlotsOverviewProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const [, newActivity] = useActivity();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const [slotOpenError, changeSlotOpenError] = useState(false);
    const [openSlot] = useOpenSlot();
    const availableSlotsResult = useSlots({ spot: spot?.id });
    const availableSlots = availableSlotsResult.data ? availableSlotsResult.data : [];
    const allItems = useAllSpotLayoutItemsForSlots();

    const [selectedSlot, changeSelectedSlot] = useState<Slot | undefined>(undefined);
    const [selectedItem, changeSelectedItem] = useState<SpotLayoutItem | undefined>(undefined);
    const [selectedSlotTransactions, changeSelectedSlotTransactions] = useState<Transaction[] | undefined>(undefined);

    function selectSlot(item: SpotLayoutItem) {
        newActivity();
        changeSelectedItem(item);
        const slot = availableSlots.find((slot) => {
            return slot.id === item.slot;
        });
        changeSelectedSlot(slot);
        if (slot) {
            const transactionsOnSlot = props.transactions.get(slot.id);
            if (transactionsOnSlot) {
                changeSelectedSlotTransactions(transactionsOnSlot.map((t) => t.transaction));
            } else {
                changeSelectedSlotTransactions(undefined);
            }
        }
    }

    useEffect(() => {
        if (selectedSlot) {
            const transactionsOnSlot = props.transactions.get(selectedSlot.id);
            if (transactionsOnSlot) {
                changeSelectedSlotTransactions(transactionsOnSlot.map((t) => t.transaction));
            } else {
                changeSelectedSlotTransactions(undefined);
            }
        }
    }, [props.transactions]);

    const onOpenSlot = async () => {
        newActivity();
        const spotLayoutItem = spotLayoutItems ? spotLayoutItems.find((li) => li.slot && li.slot === selectedSlot?.id) : undefined;
        if (spotLayoutItem && selectedSlot) {
            await openSlot({ spotLayoutItem: spotLayoutItem })
                .then((result) => {
                    changeSlotOpenError(!result);
                })
                .catch((error) => {
                    Logger.error(error);
                    changeSlotOpenError(true);
                });
        }
    };

    const disableSlot = () => {
        //TODO
        Logger.log('should disable this slot');
    };

    let title: React.ReactNode;
    if (!(availableSlotsResult.isSuccess || availableSlotsResult.isError)) {
        title = (
            <FormattedMessage
                id='views.SlotStatusView.slotsResultIsLoading'
                description='This is the message displayed on the Slot status view when the slot information is loading'
                defaultMessage='Searching all slots. Please wait.'
            />
        );
        return (
            <LoadingView
                title={title}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    } else if ((availableSlotsResult.isSuccess && (allItems == undefined || allItems.length <= 0)) || slotOpenError) {
        title = (
            <FormattedMessage
                id='views.SlotStatusView.slotsResultError'
                description='This is the message displayed on the Slot status view when an error occurred trying to find all slots'
                defaultMessage='An error occurred trying to find an all slots'
            />
        );
        if (slotOpenError) {
            title = 'Opening the slot resulted in a error.';
        }

        return (
            <ErrorView
                title={title}
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}
            />
        );
    }

    title = props.title ? (
        props.title
    ) : (
        <FormattedMessage
            id='views.SlotStatusView.SlotsFound'
            description='This is the message displayed on the Slot status view when all slots are found'
            defaultMessage='Slot status overview'
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            {props.filterObject}
            <h3 className='mt-4 text-center'>{title}</h3>
            <SpotLayout
                onSelect={selectSlot}
                highlightedItems={props.highlightedItems}
                selectableItems={props.selectableItems}
                enableColumnButtons={
                    props.loggedInContact &&
                    (isNikeDeliveryPerson(props.loggedInContact) || isAdmin(props.loggedInContact) || isDelivery(props.loggedInContact))
                }
                enableZoom={true}
            />
            {selectedSlot && selectedItem ? (
                <SlotInformation
                    slot={selectedSlot}
                    spotLayoutItem={selectedItem}
                    openSlot={onOpenSlot}
                    disableSlot={disableSlot}
                    transactions={selectedSlotTransactions}
                    contact={props.loggedInContact}
                    cancelTransaction={props.cancelTransaction}
                    changeTransactionSlot={props.changeTransactionSlot}
                    removeTransaction={props.removeTransaction}
                />
            ) : (
                <div className='mt-3'>
                    <p className='text-center'>Select a slot to get its information.</p>
                </div>
            )}
        </BaseView>
    );
};
export default SlotsOverview;
