import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { Contact } from '../api/contacts';
import { Slot, useSlots } from '../api/slots';
import { useSpotLayoutItems } from '../api/spotLayoutItems';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import { useAppSelector } from '../hooks/redux';
import useOpenRakinda from '../hooks/useOpenRakinda';

interface Props {
    selectedContact?: Contact;
}

export default function ScaleOverMaxWeightWarnings(props: Props) {
    const { openRakinda, availableAccessControls } = useOpenRakinda({ contact: props.selectedContact });
    const [invalidSlots, changeInvalidSlots] = useState<Slot[]>([]);
    const scalesWeightState = useAppSelector((state) => state.scalesWeight);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: slots } = useSlots({ spot: spot?.id });
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });

    useEffect(() => {
        const result: Slot[] = [];
        Object.entries(scalesWeightState.currentState).forEach(([spotLayoutItemId, amount]) => {
            const item = spotLayoutItems?.find((i) => i.id == spotLayoutItemId);
            if (item && item.slot != null) {
                const slot = slots?.find((s) => s.id == item.slot);
                if (slot && slot.settings_transactions_limit_for_vending !== undefined) {
                    if (slot.settings_transactions_limit_for_vending < amount) {
                        result.push(slot);
                    }
                }
            }
        });
        changeInvalidSlots(result);
    }, [scalesWeightState.currentState, slots, spotLayoutItems]);

    if (invalidSlots.length === 0) return <></>;

    const slotIds = invalidSlots.map((s) => s.slot_nr);
    return (
        <Modal
            centered
            size='lg'
            animation={false}
            show={invalidSlots.length > 0}>
            <Modal.Body className='text-center'>
                <h5>
                    <FormattedMessage
                        id='WarehouseWorkflow.scaleMaxedOut.ErrorMessage'
                        description='The message displayed on the screen when one or more scales have to many items on them.'
                        defaultMessage='The following scale(s) are over there maximum amount of items.'
                    />
                </h5>
                <p>{slotIds.join(', ')}</p>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex flex-row justify-content-end align-items-center w-100'>
                    {availableAccessControls.map((ac) => {
                        return (
                            <button
                                key={ac.uuid}
                                className='primary-button'
                                onClick={() => {
                                    openRakinda(ac, props.selectedContact);
                                }}>
                                {ac.name}
                            </button>
                        );
                    })}
                </div>
            </Modal.Footer>
        </Modal>
    );
}
