import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useProductArray } from '../../api/products';
import { useSlot } from '../../api/slots';
import { Transaction } from '../../common/transactions';
import { nikeProductParameters } from '../../workflow/nike/VendingWorkflow';
import ProductParametersElement from './ProductParametersElement';

interface TransactionProductsCardElementProps {
    transaction: Transaction;
    onOpen?: () => void;
    urgent?: boolean;
}

const TransactionProductsCardElement = (props: TransactionProductsCardElementProps) => {
    const { data: slot, isSuccess } = useSlot(props.transaction.slot_id);
    const productResults = useProductArray(props.transaction.product_instances.map((productInstance) => productInstance.product));

    let productInformation: React.ReactNode;
    if (props.transaction.product_instances.length === 0) {
        // This case is when no product information is attached to the transaction. Try to use the shipping notes.
        if (props.transaction.shipping_notes === '') {
            productInformation = (
                <FormattedMessage
                    id='views.nike.TransactionProductsCardElement.noProductInformation'
                    description='This message is shown on a product card if no product information is available (in the nike flow)'
                    defaultMessage='No product information available'
                />
            );
        } else {
            productInformation = props.transaction.shipping_notes;
        }
    } else if (props.transaction.product_instances.length > 0) {
        productInformation = productResults.map((productResult, productResultIndex) => {
            const productInstance = props.transaction.product_instances.find((i) => i.product === productResult.data?.id);
            if (productInstance) {
                if (productResult.isSuccess && productResult.data) {
                    return (
                        <div key={productInstance.id}>
                            <h6 className='font-weight-bold'>{productResult.data.name}</h6>
                            <ProductParametersElement
                                product={productResult.data}
                                parameters={nikeProductParameters}
                            />
                        </div>
                    );
                } else if (productResult.isError) {
                    return (
                        <FormattedMessage
                            key={productInstance.id}
                            id='views.nike.TransactionProductsCardElement.errorDetailProductInformation'
                            description='This message is shown on a product card if there was an error getting detailed product information (in the nike flow)'
                            defaultMessage='Product details not available due to an error'
                        />
                    );
                } else {
                    return (
                        <div key={productInstance.id}>
                            <FormattedMessage
                                id='views.nike.TransactionProductsCardElement.loadingDetailProductInformation'
                                description='This message is shown on a product card if it is loading the detailed product information (in the nike flow)'
                                defaultMessage='Loading additional product details'
                            />
                            <div
                                className='text-center spinner-border'
                                role='status'></div>
                        </div>
                    );
                }
            }
        });
    } else {
        productInformation = (
            <>
                <FormattedMessage
                    id='views.nike.TransactionProductsCardElement.loadingProductInformation'
                    description='This message is shown on a product card if the product information is loading (in the nike flow)'
                    defaultMessage='Loading product information'
                />
                <div
                    className='text-center spinner-border'
                    role='status'></div>
            </>
        );
    }

    if (props.transaction.product_instances.length === 0 && props.transaction.shipping_notes === '') return <></>;
    return (
        <div
            className='information-card white shadow-none'
            onClick={props.onOpen}
            role={props.onOpen ? 'button' : ''}>
            <div className='card-body d-flex flex-row justify-content-between align-items-center'>
                <div>{productInformation}</div>
                {props.onOpen ? (
                    <div>
                        <span className='btn btn-link text-primary text-decoration-none text-uppercase'>Open</span>
                        <span className='badge badge-dark'>{isSuccess && slot ? slot.slot_nr : '?'}</span>
                    </div>
                ) : undefined}
            </div>
        </div>
    );
};

export default TransactionProductsCardElement;
