import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact, useMutateUpdateContact } from '../../api/contacts';
import { useMutateCreateOrder } from '../../api/orders';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import ContactForm, { ContactFormFields } from '../../forms/ContactForm';
import useContactAuthentication from '../../hooks/lookup/authentication/useContactAuthentication';
import useActivity from '../../hooks/useActivity';
import useScannerValue from '../../hooks/useScannerValue';
import { Logger } from '../../logs/Logger';
import { normalizeNfcValue } from '../../utils';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import ScanProcessingModal from '../../views/nike/ScanProcessingModal';
import ViewTitle from '../../views/nike/ViewTitle';
import HelpOrthopedicConfirmation from '../../views/nike/help/HelpOrthopedicConfirmation';
import HelpOrthopedicEmployee from '../../views/nike/help/HelpOrthopedicEmployee';
import HelpOrthopedicManager from '../../views/nike/help/HelpOrthopedicManager';
import { isNikeTerminalData } from './api';
import { canOrderOrthopedicShoes } from './permissions';

interface HelpOrthopedicWorkflowProps {
    loggedInContact: Contact;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}
function HelpOrthopedicWorkflow(props: HelpOrthopedicWorkflowProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const [buyerContact, changeBuyerContact] = useState<null | Contact>(canOrderOrthopedicShoes(props.loggedInContact) ? props.loggedInContact : null);
    const [receiverContact, changeReceiverContact] = useState<null | Contact>(canOrderOrthopedicShoes(props.loggedInContact) ? null : props.loggedInContact);
    const [submitting, changeSubmitting] = useState(false);
    const [submitted, changeSubmitted] = useState(false);
    const [submitError, changeSubmitError] = useState(false);
    const [currentLookup, changeCurrentLookup] = useState<string | null>(null);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    const [loading, changeLoading] = useState<boolean>(false);
    const mutateContactUpdate = useMutateUpdateContact({
        onSuccess: (contact) => {
            changeLoading(false);
            changeContactInformationSubmitted(true);
            changeReceiverContact(contact);
        },
        onError: (err) => {
            changeLoading(false);
            Logger.log(err, { contact: props.loggedInContact.id });
        }
    });
    const [contactInformationSubmitted, changeContactInformationSubmitted] = useState<boolean>(false);

    const intl = useIntl();

    const createOrder = useMutateCreateOrder();
    const [, newActivity] = useActivity();

    const {
        isIdle: contactAuthenticationIsIdle,
        isInProgress: contactAuthenticationIsInProgress,
        isSuccess: contactAuthenticationIsSuccess,
        isError: contactAuthenticationIsError,
        result: contactResult
    } = useContactAuthentication({ lookupValue: currentLookup });

    useScannerValue((value) => {
        if (!buyerContact || !receiverContact) {
            changeCurrentLookup(normalizeNfcValue(value));
        }
    });

    const onReset = () => {
        changeCurrentLookup(null);
    };

    useEffect(() => {
        if (contactResult) {
            if (buyerContact) {
                changeReceiverContact(contactResult);
            } else if (receiverContact && canOrderOrthopedicShoes(contactResult)) {
                changeBuyerContact(contactResult);
            }
            onReset();
        }
    }, [contactResult]);

    const onCancelConfirmation = () => {
        newActivity();
        changeReceiverContact(null);
    };
    const onSelectReceiver = (receiver: Contact) => {
        changeReceiverContact(receiver);
    };

    const orthopedicRequestProductId = terminal && isNikeTerminalData(terminal.additional_data) && terminal.additional_data.orthopedic_request_product_id;

    const onConfirm = (additionalInformation: string) => {
        Logger.log('Confirmed orthopedic request.', { contact: receiverContact?.id }, receiverContact, additionalInformation);
        if (!orthopedicRequestProductId) {
            Logger.error('No orthopedic request product id configured!');
            return;
        }
        changeSubmitting(true);
        // Can change this to ordered immediately. The service worker should make sure the
        createOrder
            .mutateAsync({
                createOrder: {
                    receiver: receiverContact!.id,
                    buyer: buyerContact!.id,
                    additional_data: {
                        remarks: additionalInformation
                    },
                    product_orders: [
                        {
                            product: orthopedicRequestProductId,
                            amount: 1
                        }
                    ]
                }
            })
            .then((result) => {
                Logger.log('Orthopedic request submitted', { contact: receiverContact?.id, product: orthopedicRequestProductId }, result);
                changeSubmitted(true);
            })
            .catch(() => {
                changeSubmitError(true);
            });
    };

    const homeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    let content: React.ReactNode;
    if (submitError) {
        content = (
            <ErrorView
                title='Request could not be submitted'
                message='Your request for orthopedic support could not be submitted. Try again later.'
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
            />
        );
    } else if (submitted) {
        content = (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}>
                <ViewTitle>
                    <FormattedMessage
                        id='workflow.nike.HelpOrthopedicWorkflow.successTitle'
                        description='The title on the page when the orthopedic request has been successfully received.'
                        defaultMessage='Orthopedic support request submitted'
                    />
                </ViewTitle>
                <p>
                    <FormattedMessage
                        id='workflow.nike.HelpOrthopedicWorkflow.successMessage'
                        description='The message on the page when the orthopedic request has been successfully received.'
                        defaultMessage='Your request for orthopedic support has been submitted. You or your coach will be contacted soon.'
                    />
                </p>
            </BaseView>
        );
    } else if (submitting) {
        content = (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.nike.HelpOrthopedicWorkflow.submittingTitle'
                        description='The title on the page when the orthopedic request has been submitted, but we are waiting for a response from the server.'
                        defaultMessage='Orthopedic support request submitting...'
                    />
                }
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    } else if (buyerContact && receiverContact) {
        //TODO add contact form
        if (contactInformationSubmitted) {
            content = (
                <HelpOrthopedicConfirmation
                    buyer={buyerContact}
                    receiver={receiverContact}
                    onConfirm={onConfirm}
                    onCancel={onCancelConfirmation}
                    onBack={onCancelConfirmation}
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                />
            );
        } else {
            const title = intl.formatMessage({
                id: 'vending.orthopedicSupport.addContactInfo.title',
                description: 'Message telling the user to provide at least a email or phonenumber.',
                defaultMessage: 'Please provide us with either an email and/or a phonenumber.'
            });

            return (
                <ContactForm
                    title={title}
                    loading={loading}
                    onSubmit={(contact: Contact) => {
                        changeLoading(true);
                        mutateContactUpdate.mutate({ contact: contact });
                    }}
                    existingContact={receiverContact}
                    accountId={spot!.account!}
                    fields={[ContactFormFields.EMAIL, ContactFormFields.PHONENUMBER]}
                    requiredFields={[]}
                    onInactivity={props.onInactivity}
                    onBack={onCancelConfirmation}
                    onHome={props.onHome}
                />
            );
        }
    } else if (buyerContact) {
        content = (
            <HelpOrthopedicManager
                buyer={buyerContact}
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
                onSelectReceiver={onSelectReceiver}
            />
        );
    } else {
        content = (
            <HelpOrthopedicEmployee
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    }

    let failureMessage: React.ReactNode = undefined;
    if (contactResult && !buyerContact) {
        // In this case we expected somebody who has the permissions to order orthopedic shoes.
        failureMessage = (
            <FormattedMessage
                id='workflow.nike.HelpOrthopedicWorkflow.failureCannotOrderOrthopedic'
                description="This failure message is shown on the scanner processing modal if we expected the badge to be from a manager, but it wasn't"
                defaultMessage='This person cannot order orthopedic shoes. Contact your team coach for help.'
            />
        );
    }

    Logger.log('ortho id', {}, orthopedicRequestProductId);
    if (!orthopedicRequestProductId) {
        return (
            <ErrorView
                title='Orthopedic support requests cannot be requested from this terminal'
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
            />
        );
    }
    return (
        <>
            {content}
            <ScanProcessingModal
                idle={contactAuthenticationIsIdle}
                error={contactAuthenticationIsError}
                success={!!(buyerContact && receiverContact)}
                done={contactAuthenticationIsSuccess}
                reset={onReset}
                failureMessage={failureMessage}
            />
        </>
    );
}

export default HelpOrthopedicWorkflow;
