import { omit } from 'lodash';

import { CreateProductInstance, ProductInstance, ProductInstanceWithProduct } from '../api/productInstances';
import { Product } from '../api/products';

export interface TransactionContact {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    language: string;
}

export interface TransactionContactGroup {
    id: number;
    name: string;
}

export enum TransactionStatus {
    NEW = 'TRS000001',
    READY_FOR_DROPOFF = 'TRS000002',
    DROPOFF_IN_PROGRESS = 'TRS000003',
    DROPOFF_DONE = 'TRS000013',
    READY_FOR_PICKUP = 'TRS000004',
    PICKUP_IN_PROGRESS = 'TRS000005',
    PICKUP_DONE = 'TRS000015',
    COMPLETED = 'TRS000006',
    REMOVE_PARCEL_IN_PROGRESS = 'TRS000199',
    CANCELLED = 'TRS000099',
    ERROR = 'TRS000899'
}

export namespace TransactionStatus {
    export function is_in_progress_status() {
        return [TransactionStatus.PICKUP_IN_PROGRESS, TransactionStatus.DROPOFF_IN_PROGRESS, TransactionStatus.CANCELLED];
    }

    export function is_valid_dropoff_status() {
        return [TransactionStatus.READY_FOR_DROPOFF, TransactionStatus.DROPOFF_IN_PROGRESS];
    }
    export function is_valid_pickup_status() {
        return [TransactionStatus.READY_FOR_PICKUP, TransactionStatus.PICKUP_IN_PROGRESS];
    }
    export function is_valid_canceled_status() {
        return [TransactionStatus.CANCELLED, TransactionStatus.REMOVE_PARCEL_IN_PROGRESS];
    }

    export function before_dropoff_states() {
        return [TransactionStatus.NEW, TransactionStatus.READY_FOR_DROPOFF, TransactionStatus.DROPOFF_IN_PROGRESS];
    }

    export function inside_distrispot_states() {
        return [TransactionStatus.DROPOFF_DONE, TransactionStatus.READY_FOR_PICKUP, TransactionStatus.PICKUP_IN_PROGRESS, TransactionStatus.CANCELLED];
    }
}

export enum TransactionType {
    PUDO = 'pudo',
    VENDING = 'vending',
    LENDING = 'lending',
    RETURN = 'return'
}

export interface Transaction {
    id: string;
    url: string;
    sender?: TransactionContact | null;
    sender_group?: TransactionContactGroup | null;
    receiver?: TransactionContact | null;
    receiver_group?: TransactionContactGroup | null;
    spot_id?: string;
    spot_url?: string;
    slot_id?: string;
    slot_url?: string;
    slot_nr?: string;
    tt_number: string;
    shipping_notes: string;
    status: TransactionStatus;
    type: TransactionType;
    status_update: string;
    notifications_config: any;
    product_instances: ProductInstance[];
    real_id: number;
    product_instances_url: string;
    created_date: string;
    completed_date: string | null;

    settings_dropoff_in_progress_timeout_in_sec_value: number | null;
    settings_pickup_in_progress_timeout_in_sec_value: number | null;
    settings_remove_parcel_in_progress_timeout_in_sec_value: number | null;

    dropoff_code: string;
    dropoff_qr: string;
    pickup_code: string;
    pickup_qr: string;
    remove_parcel_code: string;
    remove_parcel_qr: string;

    slot_reservation: SlotReservation;
    slot_reservation_aux: SlotReservation | null;

    stock_fill_product?: {
        id: number;
        stock_fill_id: number;
    };
}

interface SlotReservation {
    slot: number;
    item_count: number;
    created_date: Date;
    end_date: Date;
    notification_time_for_scheduled_transaction: any | null;
    expected_dropoff_time: any | null;
    expected_pickup_time: any | null;
    close_transaction_at_expected_pickup_time: boolean;
}

export const isTransaction = (transaction: any): transaction is Transaction => {
    return (transaction as Transaction).url !== undefined && (transaction as Transaction).url.includes('/transactions/');
};

export interface TransactionWithProduct extends Transaction {
    productInstancesWithProduct: ProductInstanceWithProduct[];
}

export interface TransactionStatusResult {
    id: string;
    url: string;
    status: TransactionStatus;
}

export enum TransactionStatusEventType {
    DROPOFF = 'dropoff',
    PICKUP = 'pickup',
    ERROR = 'error',
    MOVE = 'change_spot_slot'
}

export interface TransactionDropoffEvent {
    slot_id?: string;
}

export interface TransactionPickupEvent {
    receiver?: number | null;
}

export interface TransactionErrorEvent {
    message?: string;
    code?: string;
    additional_info?: string;
}

export interface TransactionStatusUpdate {
    event: TransactionStatusEventType;
    event_date?: Date;
    dropoff?: TransactionDropoffEvent;
    pickup?: TransactionPickupEvent;
    error?: TransactionErrorEvent;
    change_spot_slot?: {
        new_spot_id: string;
        new_slot_id?: string;
    };
}

// Subset of create transaction
export enum CreateTransactionStatus {
    NEW = 'TRS000001',
    READY_FOR_DROPOFF = 'TRS000002',
    // DROPOFF_DONE = "TRS000003",
    READY_FOR_PICKUP = 'TRS000004',
    // PICKUP_DONE = "TRS000005",
    COMPLETED = 'TRS000006'
    // CANCELLED = "TRS000099",
    // ERROR = "TRS000899",
}

// Subset of create transaction
export enum UpdateTransactionStatus {
    // NEW = "TRS000001",
    // READY_FOR_DROPOFF = "TRS000002",
    // DROPOFF_DONE = "TRS000003",
    // READY_FOR_PICKUP = "TRS000004",
    // PICKUP_DONE = "TRS000005",
    // COMPLETED = "TRS000006",
    CANCELLED = 'TRS000099'
    // ERROR = "TRS000899",
}

export interface CreateTransaction {
    account_id: number | string;
    sender_id?: number | string;
    sender_group_id?: number | string;
    receiver_id?: number | string;
    receiver_group_id?: number | string;
    tt_number: string;
    shipping_notes?: string;
    customer_ref?: string;
    slot_id?: number | string | null;
    status?: CreateTransactionStatus;
    type?: TransactionType | null;
    callback_url?: string;
    notifications_config?: NotificationsConfig;
    products?: Array<Product>;
    product_ids?: number[];
    product_instances?: Array<CreateProductInstance>;
    product_instance_ids?: Array<number | string>;
}

export interface UpdateTransaction {
    status: UpdateTransactionStatus;
}

export enum NotificationsConfigProfile {
    DEFAULT = 'default',
    NO_NOTIFICATIONS = 'no_notifications',
    NOTIFY_SENDER = 'notify_sender'
}

export interface NotificationsConfig {
    profile?: NotificationsConfigProfile;
}

export function updateTransactionsFromUpdate(
    transactionId: string,
    transactionStatusUpdate: TransactionStatusUpdate,
    transactions?: Transaction[]
): Transaction[] | undefined {
    if (!transactions) return undefined;

    let success = false;
    const resultTransactions = transactions.map((tx) => {
        if (tx.id === transactionId) {
            success = true;
            return modifyTransactionFromStatusUpdate(tx, transactionStatusUpdate);
        } else {
            return tx;
        }
    });
    return success ? resultTransactions : undefined;
}

export function transactionStatusFromUpdate(transactionStatusUpdate: TransactionStatusUpdate): TransactionStatus {
    if (transactionStatusUpdate.event === TransactionStatusEventType.DROPOFF) {
        return TransactionStatus.DROPOFF_IN_PROGRESS;
    } else if (transactionStatusUpdate.event === TransactionStatusEventType.PICKUP) {
        return TransactionStatus.PICKUP_IN_PROGRESS;
    } else {
        return TransactionStatus.ERROR;
    }
}

export function modifyTransactionFromStatusUpdate(transaction: Transaction, transactionStatusUpdate: TransactionStatusUpdate): Transaction {
    const status = transactionStatusFromUpdate(transactionStatusUpdate);
    return {
        status: status,
        ...omit(transaction, ['status'])
    };
}

export function getTransactionStatusName(status: TransactionStatus, isWaitingToBeMovedHere: boolean): string {
    if (isWaitingToBeMovedHere) {
        return 'waiting to be moved here';
    }
    switch (status) {
        case TransactionStatus.NEW:
            return 'new';
        case TransactionStatus.READY_FOR_DROPOFF:
            return 'ready for dropoff';
        case TransactionStatus.DROPOFF_IN_PROGRESS:
            return 'dropoff in progress';
        case TransactionStatus.DROPOFF_DONE:
            return 'dropoff done';
        case TransactionStatus.READY_FOR_PICKUP:
            return 'ready for pickup';
        case TransactionStatus.PICKUP_IN_PROGRESS:
            return 'pickup in progress';
        case TransactionStatus.PICKUP_DONE:
            return 'pickup done';
        case TransactionStatus.COMPLETED:
            return 'completed';
        case TransactionStatus.CANCELLED:
            return 'cancelled and awaiting parcel removal';
        case TransactionStatus.REMOVE_PARCEL_IN_PROGRESS:
            return 'cancelled parcel removal in progress';
        case TransactionStatus.ERROR:
            return 'error';
    }
}
