import { ChangeEvent, FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Locale } from '../App';
import { Contact, NotificationChannel } from '../api/contacts';
import useActivity from '../hooks/useActivity';
import { parseNumberToInternational } from '../services/PhoneNumbers';
import BackAndHomeNavigationButtons from '../views/common/BackAndHomeNavigationButtons';
import BaseView from '../views/common/BaseView';
import ViewTitle from '../views/nike/ViewTitle';
import { NikeRole } from '../workflow/nike/api';

export enum ContactFormFields {
    FIRSTNAME = 'firstname',
    LASTNAME = 'lastname',
    EMAIL = 'email',
    PHONENUMBER = 'phonenumber',
    BIRTHDAY = 'birthday',
    BADGE_NR = 'badge number',
    EMPLOYEE_ID = 'employee number',
    LANGUAGE = 'language',
    NOTIFICATION_CHANNELS = 'notification channel'
}

export interface formValue {
    value: any;
    valid: boolean;
    message: string;
}

interface ContactFormProps {
    title: React.ReactNode | string;
    onSubmit: (contact: Contact) => void;
    accountId: number;
    loading?: boolean;

    fields: ContactFormFields[];
    requiredFields: ContactFormFields[];
    existingContact?: Contact;

    onHome?: () => void;
    onInactivity?: () => void;
    onBack?: () => void;

    belgiumPhoneOnly?: boolean;
}

const ContactForm = (props: ContactFormProps) => {
    const [, newActivity] = useActivity();
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const [firstname, changeFirstname] = useState<formValue>({
        value: props.existingContact ? props.existingContact.first_name : '',
        valid: props.requiredFields.includes(ContactFormFields.FIRSTNAME) ? false : true,
        message: ''
    });
    const [lastname, changeLastname] = useState<formValue>({
        value: props.existingContact ? props.existingContact.last_name : '',
        valid: props.requiredFields.includes(ContactFormFields.LASTNAME) ? false : true,
        message: ''
    });
    const [email, changeEmail] = useState<formValue>({
        value: props.existingContact ? props.existingContact.email : '',
        valid: props.requiredFields.includes(ContactFormFields.EMAIL) ? false : true,
        message: ''
    });
    const [birthday, changeBirthday] = useState<formValue>({
        value: props.existingContact ? props.existingContact.birthday : '',
        valid: props.requiredFields.includes(ContactFormFields.BIRTHDAY) ? false : true,
        message: ''
    });
    const [badgeNr, changeBadgeNr] = useState<formValue>({
        value: '',
        valid: props.requiredFields.includes(ContactFormFields.BADGE_NR) ? false : true,
        message: ''
    });
    const [employeeId, changeEmployeeId] = useState<formValue>({
        value: '',
        valid: props.requiredFields.includes(ContactFormFields.EMPLOYEE_ID) ? false : true,
        message: ''
    });
    const [phoneNumber, changePhoneNumber] = useState<formValue>({
        value: props.existingContact ? props.existingContact.mobile : '',
        valid: props.requiredFields.includes(ContactFormFields.PHONENUMBER) ? false : true,
        message: ''
    });

    const checkValidity = (channels: NotificationChannel[]): boolean => {
        if (channels.length === 0) {
            changeEmail({ value: email.value, valid: false, message: 'Please enter either a phonenumber or an email.' });
            changePhoneNumber({ value: phoneNumber.value, valid: false, message: 'Please enter either a phonenumber or an email.' });
            return false;
        }

        const validFields: boolean[] = [];
        let valid = true;
        props.requiredFields.forEach((field) => {
            const value = getValue(field)?.value;
            valid = value !== '' && value !== undefined;
            getChangeFunction(field)!({
                value: value ? value : '',
                valid: valid,
                message: !valid ? 'Please enter a valid ' + field + '.' : ''
            });
            validFields.push(valid);
        });
        if (!valid) return valid;
        return !validFields.includes(false);
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const channels = getNotificationChanels();
        if (!checkValidity(channels)) return;

        newActivity();
        const contact: Contact = {
            //TODO nike_employee_id should become employee_id
            id: props.existingContact ? props.existingContact.id : 0,
            first_name: firstname.value,
            last_name: lastname.value,
            email: email.value,
            mobile: parseNumberToInternational(phoneNumber.value),
            url: props.existingContact ? props.existingContact.url : '',
            title: props.existingContact ? props.existingContact.title : '',
            notification_channels: channels,
            language: props.existingContact ? props.existingContact.language : Locale.DUTCH,
            account: props.accountId,
            birthday: birthday.value,
            contact_groups: props.existingContact ? props.existingContact.contact_groups : [],
            contact_group_urls: props.existingContact ? props.existingContact.contact_group_urls : [],
            additional_data: props.existingContact
                ? props.existingContact.additional_data
                : {
                      role: NikeRole.INTERIM,
                      login_allowed: true,
                      badge_nr: badgeNr.value,
                      nike_employee_id: employeeId.value
                  }
        };
        props.onSubmit(contact);
    };

    function getNotificationChanels(): NotificationChannel[] {
        const channels = [];
        if (email.value !== '') channels.push(NotificationChannel.EMAIL);
        if (phoneNumber.value !== '') channels.push(NotificationChannel.SMS);
        return channels;
    }

    function onChangeField(event: ChangeEvent<HTMLInputElement>, field: ContactFormFields, changeFunction: (value: React.SetStateAction<formValue>) => void) {
        newActivity();
        let valid = true;
        if (props.requiredFields.includes(field)) {
            valid = event.target.value !== '';
        }
        changeFunction({
            value: event.target.value,
            valid: valid,
            message: 'Please enter a valid ' + field + '.'
        });
    }
    function onChangeFieldNew(value: string, field: ContactFormFields, changeFunction: (value: React.SetStateAction<formValue>) => void) {
        newActivity();
        let valid = true;
        if (props.requiredFields.includes(field)) {
            valid = value !== '';
        }
        changeFunction({
            value: value,
            valid: valid,
            message: 'Please enter a valid ' + field + '.'
        });
    }

    function getValue(field: ContactFormFields) {
        switch (field) {
            case ContactFormFields.FIRSTNAME:
                return firstname;
            case ContactFormFields.LASTNAME:
                return lastname;
            case ContactFormFields.EMAIL:
                return email;
            case ContactFormFields.BADGE_NR:
                return badgeNr;
            case ContactFormFields.BIRTHDAY:
                return birthday;
            case ContactFormFields.EMPLOYEE_ID:
                return employeeId;
            case ContactFormFields.PHONENUMBER:
                return phoneNumber;
        }
    }

    function getChangeFunction(field: ContactFormFields) {
        switch (field) {
            case ContactFormFields.FIRSTNAME:
                return changeFirstname;
            case ContactFormFields.LASTNAME:
                return changeLastname;
            case ContactFormFields.EMAIL:
                return changeEmail;
            case ContactFormFields.BADGE_NR:
                return changeBadgeNr;
            case ContactFormFields.BIRTHDAY:
                return changeBirthday;
            case ContactFormFields.EMPLOYEE_ID:
                return changeEmployeeId;
            case ContactFormFields.PHONENUMBER:
                return changePhoneNumber;
        }
        return undefined;
    }

    function isRequired(field: ContactFormFields): boolean {
        return props.requiredFields.includes(field);
    }

    function isVisible(field: ContactFormFields): boolean {
        return props.fields.includes(field);
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            {props.existingContact && (
                <h5 className='text-center'>
                    {props.existingContact?.first_name} {props.existingContact?.last_name}
                </h5>
            )}
            <ViewTitle>{props.title}</ViewTitle>

            <form
                onSubmit={onSubmit}
                noValidate={true}>
                {isVisible(ContactFormFields.FIRSTNAME) ? (
                    <div className='input-group mt-4 justify-content-between'>
                        <div className='col-md-6'>
                            <label
                                htmlFor='firstname'
                                className={isRequired(ContactFormFields.FIRSTNAME) ? 'required' : ''}>
                                <FormattedMessage
                                    id='views.lending.UserInfoFormView.firstnameLabel'
                                    description='The firstname label on the lending user info form view'
                                    defaultMessage='Firstname'
                                />
                            </label>
                            <input
                                className='form-control'
                                type='text'
                                id='firstname'
                                autoComplete='off'
                                required
                                value={firstname.value}
                                onChange={(e) => onChangeField(e, ContactFormFields.FIRSTNAME, changeFirstname)}
                            />
                            {firstname.valid ? <></> : <div className='form-error'>{firstname.message}</div>}
                        </div>

                        <div className='col-md-6'>
                            <label
                                htmlFor='lastname'
                                className={isRequired(ContactFormFields.LASTNAME) ? 'required' : ''}>
                                <FormattedMessage
                                    id='views.lending.UserInfoFormView.lastnameLabel'
                                    description='The lastname label on the lending user info form view'
                                    defaultMessage='Lastname'
                                />
                            </label>
                            <input
                                className='form-control'
                                type='text'
                                id='lastname'
                                autoComplete='off'
                                required
                                value={lastname.value}
                                onChange={(e) => onChangeField(e, ContactFormFields.LASTNAME, changeLastname)}
                            />
                            {lastname.valid ? <></> : <div className='form-error'>{lastname.message}</div>}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {isVisible(ContactFormFields.BIRTHDAY) ? (
                    <div className='form-row mt-2'>
                        <div className='col'>
                            <label
                                htmlFor='birthday'
                                className={isRequired(ContactFormFields.BIRTHDAY) ? 'required' : ''}>
                                <FormattedMessage
                                    id='views.birthdayLabel'
                                    description='The birthday label'
                                    defaultMessage='Birthday'
                                />
                            </label>
                            <input
                                className='form-control'
                                type='date'
                                id='birthday'
                                required
                                autoComplete='off'
                                value={birthday.value}
                                onChange={(e) => onChangeField(e, ContactFormFields.BIRTHDAY, changeBirthday)}
                            />
                            {birthday.valid ? <></> : <div className='form-error'>{birthday.message}</div>}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {isVisible(ContactFormFields.PHONENUMBER) ? (
                    <div className='form-row mt-2'>
                        <div className='col from-group'>
                            <label
                                htmlFor='phone'
                                className={isRequired(ContactFormFields.PHONENUMBER) ? 'required' : ''}>
                                <FormattedMessage
                                    id='views.lending.UserInfoFormView.mobileNumberLabel'
                                    description='The mobile number label on the lending user info form view'
                                    defaultMessage='Mobile number'
                                />
                            </label>
                            {props.belgiumPhoneOnly === true ? (
                                <div className='input-group'>
                                    <div className='input-group-prepend'>
                                        <span
                                            className='input-group-text'
                                            id='inputGroupPrepend'>
                                            +32
                                        </span>
                                    </div>
                                    <input
                                        className='form-control'
                                        type='tel'
                                        id='phone'
                                        name='phone'
                                        inputMode='numeric'
                                        autoComplete='off'
                                        onChange={(e) => onChangeField(e, ContactFormFields.PHONENUMBER, changePhoneNumber)}
                                        value={phoneNumber.value}
                                    />
                                </div>
                            ) : (
                                <input
                                    className='form-control'
                                    type='tel'
                                    id='phone'
                                    name='phone'
                                    inputMode='numeric'
                                    autoComplete='off'
                                    onChange={(e) => onChangeField(e, ContactFormFields.PHONENUMBER, changePhoneNumber)}
                                    value={phoneNumber.value}
                                />
                            )}

                            {phoneNumber.valid ? <></> : <div className='form-error'>{phoneNumber.message}</div>}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {isVisible(ContactFormFields.EMAIL) ? (
                    <div className='form-row mt-2'>
                        <div className='col'>
                            <label
                                htmlFor='email'
                                className={isRequired(ContactFormFields.EMAIL) ? 'required' : ''}>
                                Email
                            </label>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <span
                                        className='input-group-text'
                                        id='inputGroupPrepend'>
                                        @
                                    </span>
                                </div>
                                <input
                                    className='form-control'
                                    type='email'
                                    id='email'
                                    autoComplete='off'
                                    value={email.value}
                                    onChange={(e) => onChangeField(e, ContactFormFields.EMAIL, changeEmail)}
                                    aria-describedby='inputGroupPrepend'
                                />
                            </div>
                            {email.valid ? <></> : <div className='form-error'>{email.message}</div>}
                            {/**
                                 * <EmailInput
                                email={email.value}
                                required={isRequired(ContactFormFields.EMAIL)}
                                valid={email.valid}
                                onEmailChange={(value) => onChangeFieldNew(value, ContactFormFields.EMAIL, changeEmail)}
                                errorMessage={email.message}
                            />
                                 */}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <div className='input-group mt-4 justify-content-between'>
                    {isVisible(ContactFormFields.EMPLOYEE_ID) ? (
                        <div className='col-md-6'>
                            <label
                                htmlFor='employeeId'
                                className={isRequired(ContactFormFields.EMPLOYEE_ID) ? 'required' : ''}>
                                <FormattedMessage
                                    id='views.employeeIdLabel'
                                    description='The employee id label'
                                    defaultMessage='Employee number'
                                />
                            </label>
                            <input
                                className='form-control'
                                type='text'
                                id='employeeId'
                                autoComplete='off'
                                required
                                value={employeeId.value}
                                onChange={(e) => onChangeField(e, ContactFormFields.EMPLOYEE_ID, changeEmployeeId)}
                            />
                            {employeeId.valid ? <></> : <div className='form-error'>{employeeId.message}</div>}
                        </div>
                    ) : (
                        <></>
                    )}

                    {isVisible(ContactFormFields.BADGE_NR) ? (
                        <div className='col-md-6'>
                            <label
                                htmlFor='badgeNr'
                                className={isRequired(ContactFormFields.BADGE_NR) ? 'required' : ''}>
                                <FormattedMessage
                                    id='views.badgeNumberLabel'
                                    description='The badge number label'
                                    defaultMessage='Badge number'
                                />
                            </label>
                            <input
                                className='form-control'
                                type='text'
                                id='badgeNr'
                                autoComplete='off'
                                required
                                value={badgeNr.value}
                                onChange={(e) => onChangeField(e, ContactFormFields.BADGE_NR, changeBadgeNr)}
                            />
                            {badgeNr.valid ? <></> : <div className='form-error'>{badgeNr.message}</div>}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className='text-center mt-4'>
                    <button
                        type='submit'
                        className='primary-button btn-lg'>
                        <FormattedMessage
                            id='views.lending.UserInfoFormView.submitButton'
                            description='The submitButton on the lending user info form view'
                            defaultMessage='Submit'
                        />
                    </button>
                </div>
            </form>
        </BaseView>
    );
};

export default ContactForm;
