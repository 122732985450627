import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Contact } from '../../../api/contacts';
import { useSlots } from '../../../api/slots';
import { SpotLayoutItem, useSpotLayoutItems } from '../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { Transaction, TransactionType } from '../../../common/transactions';
import useTransactionsWithSlots from '../../../hooks/useTransactionsWithSlots';
import { slotAcceptsTransactionType } from '../../../services/slot/SlotFilter';
import SlotsOverview from '../../../workflow/SlotsOverview';
import LoadingView from '../../common/LoadingView';

interface SlotsByTypeOverviewProps {
    filterObject?: JSX.Element;
    transactionTypeFilter?: TransactionType[];
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    loggedInContact?: Contact;

    cancelTransaction?: (transaction: Transaction) => void;
    changeTransactionSlot?: (transaction: Transaction) => void;
    removeTransaction?: (transaction: Transaction) => void;
}

export default function SlotsByTypeOverview(props: SlotsByTypeOverviewProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const { data: slotsMap } = useTransactionsWithSlots({ spotId: spot?.id });
    const availableSlotsResult = useSlots({ spot: spot?.id });
    const availableSlots = availableSlotsResult.data ? availableSlotsResult.data : [];

    const [highlightedItems, changeHighlightedItems] = useState<SpotLayoutItem[] | undefined>();
    useEffect(() => {
        if (props.transactionTypeFilter === undefined || (props.transactionTypeFilter && props.transactionTypeFilter.length === 0)) {
            changeHighlightedItems([]);
            return;
        }
        if (props.transactionTypeFilter) {
            changeHighlightedItems(
                spotLayoutItems?.filter((item) => {
                    if (item.slot) {
                        const slot = availableSlots.find((slot) => slot.id === item.slot);
                        return slot !== undefined ? props.transactionTypeFilter!.some((t) => slotAcceptsTransactionType(slot!, t)) : false;
                    }
                    return false;
                })
            );
        }
    }, [spotLayoutItems, props.transactionTypeFilter, availableSlots]);

    if (slotsMap) {
        return (
            <SlotsOverview
                filterObject={props.filterObject}
                transactionTypeFilter={props.transactionTypeFilter}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
                transactions={slotsMap}
                selectableItems={spotLayoutItems}
                highlightedItems={highlightedItems}
                loggedInContact={props.loggedInContact}
                title={
                    <FormattedMessage
                        id='management.SlotByTypeOverview.title'
                        description='This is the message displayed on the SlotByTypeOverview'
                        defaultMessage='Slot type overview'
                    />
                }
                cancelTransaction={props.cancelTransaction}
                changeTransactionSlot={props.changeTransactionSlot}
                removeTransaction={props.removeTransaction}
            />
        );
    }
    return (
        <LoadingView
            onInactivity={props.onInactivity}
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
}
