import Connection from '../../Connection';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand } from '../useManagedWebsocket';

export class WebsocketPowerCycleCommand extends WebsocketCommand {
    public async execute(connection: Connection): Promise<WebsocketCommandResponse> {
        connection.reset();
        return { success: true };
    }
}
