import React from 'react';
import { FormattedMessage } from 'react-intl';

import ViewTitle from '../nike/ViewTitle';
import BackAndHomeNavigationButtons from './BackAndHomeNavigationButtons';
import BaseView from './BaseView';

interface LoadingViewProps {
    title?: React.ReactNode;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

function LoadingView(props: LoadingViewProps) {
    const homeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                {props.title ? (
                    props.title
                ) : (
                    <FormattedMessage
                        id='views.nike.LoadingView.defaultTitle'
                        description='The default title on the Nike loading screen'
                        defaultMessage='Loading. Please wait...'
                    />
                )}
            </ViewTitle>
            <div
                className='spinner-border text-primary align-self-center'
                role='status'
                style={{ width: '6rem', height: '6rem' }}></div>
        </BaseView>
    );
}

export default LoadingView;
