import Fuse from 'fuse.js';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContactGroup } from '../../../api/contactGroups';
import DefaultList from '../DefaultList';
import ContactGroupListElement from './ContactGroupListElement';

interface ContactGroupSelectViewProps {
    onSelect: (contactGroup: ContactGroup) => void;
    contactGroups: ContactGroup[];
}

const ContactGroupSelectView = (props: ContactGroupSelectViewProps) =>
    useMemo(() => {
        const fuseOptions = {
            includeScore: true,
            keys: ['name'],
            fieldNormWeight: 1
        };
        const fuse = new Fuse(props.contactGroups, fuseOptions);

        const filter = async (value: string): Promise<JSX.Element[]> => {
            return new Promise((resolve, reject) => {
                if (value === '') {
                    const result = props.contactGroups.map((contactGroup) => {
                        return ContactGroupListElement(contactGroup, props.onSelect);
                    });
                    resolve(result);
                }
                const fuseResults = fuse.search(value);
                const result = fuseResults
                    .map((res) => res.item)
                    .map((contactGroup) => {
                        return ContactGroupListElement(contactGroup, props.onSelect);
                    });
                resolve(result);
            });
        };

        return (
            <DefaultList
                elements={props.contactGroups.map((contactGroup) => {
                    return ContactGroupListElement(contactGroup, props.onSelect);
                })}
                headers={[
                    <th>
                        <FormattedMessage
                            id='views.elements.ContactGroupSelectView.tableHeaderGroupName'
                            description='The header of the ContactGroupSelectView list'
                            defaultMessage='Group Name'
                        />
                    </th>
                ]}
                asyncFilter={filter}
            />
        );
    }, [props.contactGroups]);

export default ContactGroupSelectView;
