import { ProtonCommands } from '../../services/ProtonScalesService';
import { IProtonRequest, isProtonRequest } from './IProtonRequest';

export interface WeightChangeRequest extends IProtonRequest {
    header: {
        action: ProtonCommands.WEIGHT_CHANGE;
    } & Omit<IProtonRequest['header'], 'action'>;
    payload: {
        clusterUri: string;
        dataBinding: { enable: boolean; esl: unknown; skuUniNo: string; weight: unknown };
        state: {
            count: number;
            countingAccuracy: boolean;
            locked: boolean;
            online: boolean;
            weight: number;
            weightStable: boolean;
        };
        type: string;
    }[];
}

export function isWeightChangeEvent(request: unknown): request is WeightChangeRequest {
    return (
        isProtonRequest(request) &&
        (request as WeightChangeRequest).ext &&
        (request as WeightChangeRequest).ext.appType &&
        (request as WeightChangeRequest).ext.appType === 'proton' &&
        (request as WeightChangeRequest).header &&
        (request as WeightChangeRequest).header.action &&
        (request as WeightChangeRequest).header.action === ProtonCommands.WEIGHT_CHANGE &&
        (request as WeightChangeRequest).type === 'request'
    );
}
