import React from 'react';

import { Logger } from './logs/Logger';
import { getRandomString } from './utils';

export const ReadyForUpdateContext = React.createContext({
    readyForUpdate: false,
    readyForUpdateChange: (value) => {}
});

class AppUpdate extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.id = null;

        this.readyForUpdateChange = this.readyForUpdateChange.bind(this);

        this.state = {
            readyForUpdate: false,
            readyForUpdateChange: this.readyForUpdateChange
        };
    }

    readyForUpdateChange(value) {
        this.setState({
            readyForUpdate: value
        });
    }

    canUpdate() {
        let canUpdate = this.state.readyForUpdate && this.props.serviceWorkerUpdating;

        navigator.serviceWorker.ready.then((registration) => {
            // If a service worker is waiting, send it our willingness to update
            if (registration.waiting) {
                Logger.debug('Inform waiting service worker of our willingness to update.', {}, registration.waiting, canUpdate);
                registration.waiting.postMessage({
                    type: 'APP_UPDATE_READY',
                    ready: canUpdate,
                    clientId: this.id
                });
            }
        });
    }

    initAppUpdate(registration) {
        Logger.debug('Waiting for an app update.');
        this.id = getRandomString(20);

        registration.onupdatefound = (registration) => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
                return;
            }
            installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                    Logger.debug('New service worker installed.');
                    // New service worker got installed. Signal our willingness to update.
                    this.canUpdate();
                }
            };
        };

        this.canUpdate();
    }

    componentDidMount() {
        this._isMounted = true;
        navigator.serviceWorker.ready.then((registration) => {
            if (!this._isMounted) return;

            Logger.debug('Serviceworker ready in AppUpdate with controller.', {}, navigator.serviceWorker.controller);
            Logger.debug('Serviceworker ready in AppUpdate with registration.', {}, registration);
            this.initAppUpdate(registration);

            navigator.serviceWorker.oncontrollerchange = () => {
                Logger.debug('Controller changed, reload now!');
                window.location.reload();
            };
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        navigator.serviceWorker.oncontrollerchange = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.serviceWorkerUpdating !== prevProps.serviceWorkerUpdating || this.state.readyForUpdate !== prevState.readyForUpdate) {
            this.canUpdate();
        }
    }

    render() {
        return <ReadyForUpdateContext.Provider value={this.state}>{this.props.children}</ReadyForUpdateContext.Provider>;
    }
}

export default AppUpdate;
