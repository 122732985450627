import { FormattedMessage } from 'react-intl';

import { CallToActionSize, CallToActionType } from '../../components/domain/CallToAction';
import CallToActionElement from '../../components/elements/CallToActionElement';
import { ProductInstanceOwnership } from '../../workflow/nike/utils';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import { ReviewReturnedProductHighlightCard } from './ReviewReturnedProductHighlightCard';
import ViewTitle from './ViewTitle';

interface ReviewReturnedProductViewProps {
    productInstanceOwned: ProductInstanceOwnership;
    onPutBack?: () => void;
    onDispose?: () => void;
    onSlotEmpty?: () => void;
    onReturnToStock?: () => void;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

export default function ReviewReturnedProductView(props: ReviewReturnedProductViewProps) {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.ReviewReturnedProductView.title'
                    description='The title on the page where a delivery person reviews a returned product in the Nike workflow.'
                    defaultMessage='Review product'
                />
            </ViewTitle>
            <ReviewReturnedProductHighlightCard productInstanceOwned={props.productInstanceOwned} />
            {props.onPutBack ? (
                <CallToActionElement
                    message={
                        <FormattedMessage
                            id='views.nike.ReviewReturnedProductView.itemPutBackAction'
                            description='The call to action on the review product page to put the item back in the distrispot. (nike flow)'
                            defaultMessage='This item is accepted. I want to put it back in the DistriSPOT now.'
                        />
                    }
                    type={CallToActionType.SUCCESS}
                    size={CallToActionSize.LARGE}
                    className='mb-4'
                    onClick={props.onPutBack}
                />
            ) : undefined}
            {props.onDispose ? (
                <CallToActionElement
                    message={
                        <FormattedMessage
                            id='views.nike.ReviewReturnedProductView.itemDisposeAction'
                            description='The call to action on the review product page to indicate the item was rejected and disposed of. (nike flow)'
                            defaultMessage='This item is rejected. I have disposed of it.'
                        />
                    }
                    type={CallToActionType.DANGER}
                    size={CallToActionSize.LARGE}
                    className='mb-4'
                    onClick={props.onDispose}
                />
            ) : undefined}
            {props.onSlotEmpty ? (
                <CallToActionElement
                    message={
                        <FormattedMessage
                            id='views.nike.ReviewReturnedProductView.slotEmptyAction'
                            description='The call to action on the review product page to indicate the was empty. (nike flow)'
                            defaultMessage='The slot is empty.'
                        />
                    }
                    type={CallToActionType.WARNING}
                    size={CallToActionSize.LARGE}
                    className='mb-4'
                    onClick={props.onSlotEmpty}
                />
            ) : undefined}
            {props.onReturnToStock ? (
                <CallToActionElement
                    message={
                        <FormattedMessage
                            id='views.nike.ReviewReturnedProductView.itemReturnToStockAction'
                            description='The call to action on the review product page to indicate the item was accepted and returned to stock. (nike flow)'
                            defaultMessage="This item is accepted but I don't want to put it back in the DistriSPOT."
                        />
                    }
                    type={CallToActionType.OUTLINE_SUCCESS}
                    size={CallToActionSize.LARGE}
                    className='mb-4'
                    onClick={props.onReturnToStock}
                />
            ) : undefined}
            {props.onBack ? (
                <CallToActionElement
                    message={
                        <FormattedMessage
                            id='views.nike.ReviewReturnedProductView.itemIgnoreAction'
                            description='The call to action on the review product page to indicate the item was ignored for now. (nike flow)'
                            defaultMessage="I don't know what to do with this item at the moment."
                        />
                    }
                    type={CallToActionType.OUTLINE_PRIMARY}
                    size={CallToActionSize.LARGE}
                    className='mb-4'
                    onClick={props.onBack}
                />
            ) : undefined}
        </BaseView>
    );
}
