import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { Contact } from '../api/contacts';
import { Slot } from '../api/slots';
import { useSpotLayoutItems } from '../api/spotLayoutItems';
import { useSpotLayout } from '../api/spotLayouts';
import { SpotType, useSpot } from '../api/spots';
import { useMutateTransactionStatus, useTransaction } from '../api/transactions';
import { Transaction, TransactionStatus, TransactionStatusEventType } from '../common/transactions';
import useActivity from '../hooks/useActivity';
import useOpenSlot from '../hooks/useOpenSlot';
import useTransactionPickupForSlot from '../hooks/useTransactionsPickupForSlot';
import { Logger } from '../logs/Logger';
import { addTransactionInProgress } from '../service-worker/TransactionInProgressDatabase';
import SlotConfirmationView from '../views/SlotConfirmationView';
import { SlotOpenState, SlotOpenView } from '../views/SlotOpenView';

interface PickupProperties {
    slot: Slot;
    transaction?: Transaction;
    transactions?: Transaction[];
    skipConfirm?: boolean;
    receiver?: Contact | null;
    endSession?: boolean; // only given in lending workflow
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
}

const Pickup = (props: PickupProperties) => {
    const [transaction, changeTransaction] = useState<Transaction | undefined>(props.transaction);
    const transactions = useTransactionPickupForSlot(props.slot);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });

    const [slotConfirmed, changeSlotConfirmed] = useState(props.skipConfirm === true);
    const [slotOpening, changeSlotOpening] = useState(false);
    const [slotOpened, changeSlotOpened] = useState(false);
    const [, newActivity] = useActivity();

    const transactionStatusMutation = useMutateTransactionStatus();
    const [slotOpenError, changeSlotOpenError] = useState(false);
    const [openSlot] = useOpenSlot();

    const spotLayoutItem = spotLayoutItems ? spotLayoutItems.find((li) => li.slot && li.slot === props.slot.id) : undefined;

    useEffect(() => {
        if (transactions !== undefined && transactions.length > 1) {
            changeTransaction(undefined);
        }
    }, [transactions]);

    const [inProgressTransactionSaved, changeInProgressTransactionSaved] = useState<boolean>(false);
    const toUpdateTransaction = useTransaction(
        transaction?.url,
        undefined,
        transaction &&
            transaction?.status !== TransactionStatus.PICKUP_IN_PROGRESS &&
            transaction?.status !== TransactionStatus.CANCELLED &&
            transaction?.status !== TransactionStatus.PICKUP_DONE &&
            slotConfirmed
    );

    useEffect(() => {
        if (toUpdateTransaction) {
            //Storing last transactions inside localstorage because the react query is not reliable enough
            let newTransactionArray: string[] = window.localStorage.getItem('lastTransactionsPickedUp')
                ? JSON.parse(window.localStorage.getItem('lastTransactionsPickedUp')!)
                : [];
            if (newTransactionArray.length >= 10) newTransactionArray = newTransactionArray.slice(newTransactionArray.length - 11);
            if (!newTransactionArray.includes(toUpdateTransaction.id)) {
                newTransactionArray.push(toUpdateTransaction.id);
                window.localStorage.setItem('lastTransactionsPickedUp', JSON.stringify(newTransactionArray));
            }

            changeTransaction(toUpdateTransaction);
            if (!inProgressTransactionSaved && toUpdateTransaction.status === TransactionStatus.PICKUP_IN_PROGRESS) {
                addTransactionInProgress(toUpdateTransaction);
                changeInProgressTransactionSaved(true);
            }
        }
    }, [toUpdateTransaction]);

    useEffect(() => {
        const doOpen = async (transaction?: Transaction) => {
            await openSlot({ spotLayoutItem: spotLayoutItem! })
                .then((result) => {
                    doPickup();
                })
                .catch((error) => {
                    Logger.error(error);
                    changeSlotOpenError(true);
                });
        };
        if (
            transaction !== undefined &&
            (TransactionStatus.is_valid_pickup_status().includes(transaction.status) || transaction.status === TransactionStatus.CANCELLED) &&
            slotConfirmed &&
            !slotOpening &&
            spotLayoutItem
        ) {
            changeSlotOpening(true);
            doOpen(transaction);
        } else if (transactions !== undefined && slotConfirmed && !slotOpening && spotLayoutItem) {
            changeSlotOpening(true);
            doOpen();
        }
    }, [transaction, transaction?.status, slotConfirmed, slotOpening, spotLayoutItem, transactions]);

    function doPickup() {
        changeSlotOpened(true);
        if (props.endSession === undefined || props.endSession === true) {
            if (transaction !== undefined) {
                transactionStatusMutation.mutate({
                    transaction: transaction,
                    transactionStatusUpdate: {
                        event: TransactionStatusEventType.PICKUP,
                        pickup: {
                            receiver: props.receiver ? (typeof props.receiver.id === 'number' ? props.receiver.id : parseInt(props.receiver.id)) : null
                        }
                    }
                });
            } else if (transactions !== undefined) {
                transactions.forEach((transaction) => {
                    if (transaction.status === TransactionStatus.READY_FOR_PICKUP) {
                        transactionStatusMutation.mutate({
                            transaction: transaction,
                            transactionStatusUpdate: {
                                event: TransactionStatusEventType.PICKUP,
                                pickup: {
                                    receiver: props.receiver ? (typeof props.receiver.id === 'number' ? props.receiver.id : parseInt(props.receiver.id)) : null
                                }
                            }
                        });
                    }
                });
            }
        }
    }

    const onConfirmedSlot = () => {
        newActivity();
        changeSlotConfirmed(true);
    };

    /**
     * Identification process should be added here if the transaction requires it
     * (before slot confirm)
     */

    if (!slotConfirmed) {
        return (
            <SlotConfirmationView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
                slot={props.slot}
                onConfirm={onConfirmedSlot}
            />
        );
    } else {
        let slotOpenState: SlotOpenState;
        if (transaction !== undefined) {
            // These are the opening SLOT cases
            if (!transaction?.status || transaction?.status === TransactionStatus.NEW) {
                slotOpenState = SlotOpenState.IN_PROGRESS;
            } else if (transaction?.status === TransactionStatus.ERROR || slotOpenError) {
                slotOpenState = SlotOpenState.ERROR;
            } else if ((transaction?.status === TransactionStatus.READY_FOR_PICKUP || transaction?.status === TransactionStatus.CANCELLED) && !slotOpened) {
                slotOpenState = SlotOpenState.IN_PROGRESS;
            } else if (slotOpened) {
                slotOpenState = SlotOpenState.SUCCESS;
            } else {
                slotOpenState = SlotOpenState.ERROR; // Unknown state
                Logger.log('Unexpected state trying to open slot on pickup.', { transaction: transaction.id }, transaction, slotOpened);
            }
        } else if (transactions !== undefined || slotOpened) {
            if (slotOpened) {
                slotOpenState = SlotOpenState.SUCCESS;
            } else {
                slotOpenState = SlotOpenState.IN_PROGRESS;
            }
        } else {
            slotOpenState = SlotOpenState.ERROR;
        }
        return (
            <SlotOpenView
                spotType={spot ? spot.type : SpotType.STANDARD_DISTRISPOT}
                slotInfos={[{ slot: props.slot, state: slotOpenState }]}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                endSession={props.endSession}
            />
        );
    }
};

export default Pickup;
