import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { changeAllowRedirect, forward } from '../../redux/ViewSlice';
import ActionCard from '../domain/ActionCard';
import { ActionCardElement } from './ActionCardElement';

interface ActionCardGridElementProps {
    actions: (ActionCard | undefined)[];
    onSelectAction: (action: ActionCard, automatic?: boolean) => void;
}

export default function ActionCardGridElement(props: ActionCardGridElementProps) {
    const dispatch = useAppDispatch();
    const ViewState = useAppSelector((state) => state.viewState);

    useEffect(() => {
        const selectedAction = props.actions.find((action) => action?.id && ViewState.allowRedirect.includes(action.id));
        if (selectedAction) {
            dispatch(changeAllowRedirect({ views: [selectedAction!.id!], value: false }));
            props.onSelectAction(selectedAction);
        }
    }, []);

    return (
        <div className='d-flex flex-row flex-wrap justify-content-center'>
            {props.actions.map((action) => {
                if (action) {
                    return (
                        <ActionCardElement
                            key={action.actionText}
                            onClick={() => {
                                dispatch(changeAllowRedirect({ views: [], value: false }));
                                if (action.id) dispatch(forward(action.id));
                                props.onSelectAction(action);
                            }}
                            onCallToActionClick={() => {
                                props.onSelectAction(action.callToAction!);
                            }}
                            description={action.icon ? action.actionText : undefined}
                            callToAction={action.callToAction}
                            fullWidth={action.fullWidth}
                            color={action.color}
                            highlight={action.highlight}>
                            <span className={action.highlight ? ' text-light' : action.color ? 'text-' + action.color : ''}>
                                {action.icon ? action.icon : action.actionText}
                            </span>
                        </ActionCardElement>
                    );
                }
            })}
        </div>
    );
}
