import React, { useState } from 'react';
import { ImArrowDown2 } from 'react-icons/im';
import { FormattedMessage } from 'react-intl';

import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { TerminalWorkflowType } from '../../api/terminals';
import { ActionCardElement } from '../../components/elements/ActionCardElement';
import Scanner from '../../demo/Scanner';
import { useAppSelector } from '../../hooks/redux';
import useLongPress from '../../hooks/useLongPress';
import useTerminal from '../../hooks/useTerminal';
import useTheme from '../../hooks/useTheme';
import { SliceStatus } from '../../redux/ReduxCommonVariables';
import telloportLogo from '../../static/Logo Telloport Horizontal.png';
import imesDexisLogo from '../../static/imes-dexis-logo.png';
import InactivityModal from '../elements/InactivityModal';

interface BaseViewProps {
    children: React.ReactNode;
    onInactivity?: () => void;
    navbarItems?: React.ReactNode;
    ShowQrHelp?: boolean;
    disallowShowOpenedSlots?: boolean;
}

const BaseView = (props: BaseViewProps) => {
    const { themeLogo } = useTheme();
    const terminal = useTerminal();
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const spotLayoutLogsStore = useAppSelector((state) => state.spotLayoutLogs);
    const extraBrandStyling = { width: '5em' };
    const [showScannerToast, changeShowScannerToast] = useState<boolean>(false);

    const openScanner = () => {
        changeShowScannerToast(!showScannerToast);
    };
    const longPress = useLongPress(openScanner, 3000);

    return (
        <>
            <div>
                {spotLayoutLogsStore.status !== SliceStatus.INIT &&
                    spotLayoutLogsStore.openedSpotLayoutItems.length > 0 &&
                    props.disallowShowOpenedSlots !== true && <div className='alert alert-warning'>Not all SLOTs are closed.</div>}
            </div>
            <nav className='navbar'>
                <div className='navbar-brand'>
                    <img
                        className='img-fluid'
                        alt='Logo'
                        src={themeLogo}
                    />
                </div>
                {props.navbarItems}
            </nav>
            <div className='flex-grow-1 d-flex flex-column'>{props.children}</div>
            {themeLogo !== telloportLogo ? (
                <div className='footer'>
                    <div>
                        {terminal?.workflow === TerminalWorkflowType.IMES_DEXIS_NIKE ? (
                            <img
                                style={extraBrandStyling}
                                alt='Imes Dexis'
                                src={imesDexisLogo}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>
                        <span>Powered by&nbsp;</span>
                        <div className='footer-telloport-logo'>
                            <img
                                className='img-fluid'
                                alt='DistriSPOT'
                                src={telloportLogo}
                            />
                        </div>
                    </div>
                    {spot ? (
                        <div>
                            <small
                                className='footer-spot-name'
                                {...longPress}>
                                {spot.name}
                            </small>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : undefined}
            {props.ShowQrHelp ? (
                <div className='scanner-help-message-backdrop'>
                    <div className='scanner-help-message mb-3'>
                        <ActionCardElement
                            description={
                                <FormattedMessage
                                    id='views.HomeView.scanHereMessage'
                                    description='The text show on the home screen when the user wants to use the scanner'
                                    defaultMessage='Use the scanner below'
                                />
                            }
                            highlight
                            fullWidth={true}
                            color='success'>
                            <ImArrowDown2
                                size={265}
                                className='scanner-help-message-icon'
                            />
                        </ActionCardElement>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {props.onInactivity ? <InactivityModal onInactivity={props.onInactivity} /> : <></>}
            {showScannerToast ? <Scanner /> : <></>}
        </>
    );
};

export default BaseView;
