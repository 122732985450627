import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Contact } from '../../../api/contacts';
import { useProducts } from '../../../api/products';
import { useShops } from '../../../api/shops';
import { SpotLayoutItem, useSpotLayoutItems } from '../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { useTransactions } from '../../../api/transactions';
import { useMutateBulkMoveTransactions } from '../../../api/transactions/bulk/BulkMoveTransactions';
import { TransactionStatusChangeEventValue, useMutateBulkUpdateTransactionStatus } from '../../../api/transactions/bulk/BulkUpdateTransactionStatus';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useActivity from '../../../hooks/useActivity';
import { Logger } from '../../../logs/Logger';
import { sync } from '../../../redux/ScalesWeightSlice';
import { AuthenticatedContact } from '../../../services/contact/GlobalPermissions';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ProductChanges from '../ProductChanges';
import { SpotLayoutItemProductAmount, mapToSpotLayoutItemProductAmount } from '../WarehouseWorkflow';
import CheckoutConfirmationModal from './CheckoutConfirmationModal';

interface Props extends AllowedActionViewProps {
    contact: Contact | AuthenticatedContact;
}

export default function Checkout(props: Props) {
    const [, newActivity] = useActivity();
    const dispatch = useAppDispatch();
    const [modalInfo, changeModalInfo] = useState<{ valid: SpotLayoutItemProductAmount[]; other: SpotLayoutItemProductAmount[] }>();
    const moveTransactions = useMutateBulkMoveTransactions({
        onSuccess: (data, vars) => {
            const syncData: { [spotLayoutItemId: string]: number } = {};
            vars.info?.forEach((transaction) => {
                const to = spotLayoutItems?.find((spt) => spt.slot !== undefined && transaction.to !== undefined && spt.slot === transaction.to);
                const from = spotLayoutItems?.find((spt) => spt.slot !== undefined && transaction.from !== undefined && spt.slot === transaction.from);
                if (to) {
                    if (syncData[to.id] !== undefined) syncData[to.id] = syncData[to.id] - 1;
                    else syncData[to.id] = -1;
                }
                if (from) {
                    if (syncData[from.id] !== undefined) syncData[from.id] = syncData[from.id] + 1;
                    else syncData[from.id] = 1;
                }
            });
            dispatch(sync({ changes: syncData }));
            if (updateTransactions.isIdle && moveTransactions.isIdle) onHome();
        }
    });
    const updateTransactions = useMutateBulkUpdateTransactionStatus({
        onSuccess: (data) => {
            const syncData: { [spotLayoutItemId: string]: number } = {};
            data.forEach((tr) => {
                const spotLayoutItem = spotLayoutItems?.find(
                    (spt) =>
                        spt.slot !== undefined &&
                        ((tr.slot_id !== null && spt.slot === tr.slot_id) ||
                            (tr.slot_reservation !== null && +spt.slot?.replace('SLT', '') === tr.slot_reservation.slot))
                );
                if (spotLayoutItem) {
                    if (syncData[spotLayoutItem.id] !== undefined) {
                        syncData[spotLayoutItem.id] = syncData[spotLayoutItem.id] + 1;
                    } else {
                        syncData[spotLayoutItem.id] = 1;
                    }
                }
            });
            dispatch(sync({ changes: syncData }));
            if (updateTransactions.isIdle && moveTransactions.isIdle) onHome();
        }
    });
    const scalesWeightState = useAppSelector((state) => state.scalesWeight);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [updating, changeUpdating] = useState<boolean>(false);
    const { data: transactions } = useTransactions(
        {
            spot: spot?.id,
            status: TransactionStatus.READY_FOR_PICKUP
        },
        { enabled: !!spot }
    );

    const [update, changeUpdate] = useState<boolean | undefined>(undefined);
    const [snapshot, changeSnapshot] = useState<SpotLayoutItemProductAmount[]>([]);

    useEffect(() => refresh(), [products, spotLayoutItems]);

    useEffect(() => {
        if (update === false) changeUpdate(true);
    }, [scalesWeightState.currentState]);

    const refresh = () => {
        newActivity();
        if ((update === true || update === undefined) && products && spotLayoutItems) {
            newActivity();
            changeSnapshot(mapToSpotLayoutItemProductAmount(products, spotLayoutItems, scalesWeightState.difference));
            changeUpdate(false);
        }
    };

    const submit = async (valid: SpotLayoutItemProductAmount[], other: SpotLayoutItemProductAmount[]) => {
        newActivity();
        changeUpdating(true);
        changeModalInfo({ valid, other });
        Logger.log('valid', {}, valid);
        Logger.log('other', {}, other);
        if (other.length < 1) {
            handleClose(false, { valid, other });
        }
    };
    const handleClose = async (someoneInside: boolean, info?: { valid: SpotLayoutItemProductAmount[]; other: SpotLayoutItemProductAmount[] }) => {
        newActivity();
        Logger.log('info', {}, someoneInside, info);
        const actualInfo = info ? info : modalInfo;
        if (actualInfo === undefined) return;

        let trsToUpdate: Transaction[] = [];
        actualInfo?.valid.forEach((value) => {
            if (value.amount > 0) {
                const validTransactions = transactions!.filter((tr) => tr.slot_id === value.spotLayoutItem.slot);
                trsToUpdate.push(...validTransactions.slice(0, value.amount));
            }
        });
        let trsToOrphan: Transaction[] = [];
        if (someoneInside === false) {
            actualInfo?.other.forEach((value) => {
                if (value.amount > 0) {
                    let validTransactions = transactions!.filter((tr) => tr.slot_id === value.spotLayoutItem.slot);
                    validTransactions = validTransactions.filter((tr) => trsToUpdate.find((t) => t.id === tr.id) === undefined);
                    trsToOrphan.push(...validTransactions.slice(0, value.amount));
                }
            });
        }

        const trsToMove: { transaction: Transaction; to: SpotLayoutItem }[] = [];
        let trsToDrop = 0;
        [...actualInfo.valid, ...actualInfo.other].forEach((value) => {
            if (value.amount < 0) {
                const foundTransactions = trsToOrphan.filter((tr) => tr.product_instances[0].product === value.product.id);
                foundTransactions.push(...trsToUpdate.filter((tr) => tr.product_instances[0].product === value.product.id));
                if (foundTransactions.length < -value.amount) {
                    trsToDrop -= value.amount; //will actually add because amount should be negative in this case
                }
                const validTransactions = foundTransactions.slice(0, -value.amount);
                const mapped = validTransactions.map((tr) => {
                    return { transaction: tr, to: value.spotLayoutItem };
                });
                trsToMove.push(...mapped);

                trsToOrphan = trsToOrphan.filter((tr) => trsToMove.find((t) => t.transaction.id === tr.id) === undefined);
                trsToUpdate = trsToUpdate.filter((tr) => trsToMove.find((t) => t.transaction.id === tr.id) === undefined);
            }
        });

        Logger.log('update', {}, trsToUpdate.length);
        Logger.log('orphan', {}, trsToOrphan.length);
        Logger.log('move', {}, trsToMove.length);
        Logger.log('drop', {}, trsToDrop);

        if (trsToUpdate.length > 0) {
            await updateTransactions.mutate({
                initial_state: 'pickup_waiting',
                event: TransactionStatusChangeEventValue.PICKUP_IN_PROGRESS,
                transaction_ids: trsToUpdate.map((t) => t.real_id),
                account_id: spot!.account,
                fast_transition: true,
                receiver_id: props.contact.id
            });
        }
        if (trsToOrphan.length > 0) {
            await updateTransactions.mutate({
                initial_state: 'pickup_waiting',
                event: TransactionStatusChangeEventValue.PICKUP_IN_PROGRESS,
                transaction_ids: trsToOrphan.map((t) => t.real_id),
                account_id: spot!.account,
                fast_transition: true,
                receiver_id:
                    terminal?.default_receiver_contact && typeof terminal?.default_receiver_contact === 'number'
                        ? terminal?.default_receiver_contact
                        : undefined
            });
        }
        if (trsToMove.length > 0) {
            moveTransactions.mutate({
                account_id: spot!.account,
                transaction_spot_slot_array: trsToMove.map((toMove) => {
                    return {
                        transaction: toMove.transaction.id,
                        new_spot_id: spot!.id,
                        new_slot_id: toMove.to.slot,
                        fast_transition: true
                    };
                }),
                info: trsToMove.map((toMove) => {
                    return {
                        transactionId: toMove.transaction.id,
                        from: toMove.transaction.slot_id,
                        to: toMove.to.slot
                    };
                })
            });
        }

        //if there are no changes just go home on submit
        if (trsToUpdate.length === 0 && trsToOrphan.length === 0 && trsToMove.length === 0) onHome();
    };

    const onHome = () => {
        changeUpdating(false);
        changeModalInfo(undefined);
        props.onHome && props.onHome();
    };

    if (transactions === undefined || !updateTransactions.isIdle) {
        return (
            <BaseView
                navbarItems={
                    <BackAndHomeNavigationButtons
                        onLogout={props.onInactivity}
                        onHome={props.onHome}
                    />
                }>
                <div className='d-flex flex-column align-items-center'>
                    <Spinner
                        animation='border'
                        role='status'
                    />
                </div>
            </BaseView>
        );
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={
                <BackAndHomeNavigationButtons
                    onLogout={props.onInactivity}
                    onHome={props.onHome}
                />
            }>
            <div className='d-flex flex-column align-items-center'>
                <h3>
                    <FormattedMessage
                        id='views.WarehouseWorkflow.checkout.title'
                        description='The title on the checkout page in the warehouse workflow.'
                        defaultMessage='Welcome {name}'
                        values={{
                            name: props.contact.first_name + ' ' + props.contact.last_name
                        }}
                    />
                </h3>
                <ProductChanges
                    snapshot={snapshot}
                    submit={submit}
                />
                {update && (
                    <button
                        onClick={refresh}
                        className={'secondary-button btn-lg'}>
                        <FormattedMessage
                            id='views.WarehouseWorkflow.checkout.RefreshButton.label'
                            description='The refresh button label on the checkout page.'
                            defaultMessage='Refresh'
                        />
                    </button>
                )}
            </div>
            <CheckoutConfirmationModal
                show={updating}
                handleClose={() => handleClose(false)}
                modalInfo={modalInfo}
            />
        </BaseView>
    );
}
