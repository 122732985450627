import { ProductInstanceForTransaction, ProductInstanceWithProduct } from '../../api/productInstances';
import { Product } from '../../api/products';
import { Slot, SlotForTransaction, getIdFromRealId } from '../../api/slots';
import { Transaction, TransactionStatus, TransactionWithProduct } from '../../common/transactions';

export function toIdMap<T, K>(key: (item: T) => K, items?: T[]): Map<K, T> {
    if (!items) return new Map<K, T>();
    return new Map<K, T>(items.map((item) => [key(item), item]));
}

/**
 * Transform a list of Slots to a map of slotId -> Slot for easy lookup.
 * @param slots The list of Slots
 * @return a Map of slotId -> Slot data
 */
export function transformSlotsToIdMap<T extends Slot>(slots?: T[]): Map<string, T> {
    return toIdMap((slot) => slot.id, slots);
}

export function transformProductsToIdMap<T extends Product>(products?: T[]): Map<string | number, T> {
    return toIdMap((product) => product.id, products);
}

export function transformTransactionsToIdMap<T extends Transaction>(transactions?: T[]): Map<string, T> {
    return toIdMap((transaction) => transaction.id, transactions);
}

export function transformTransactionsToSlotMap(
    slotsIdMap: Map<string, Slot>,
    transactions?: Transaction[],
    excludeScheduledTransactions?: boolean
): Map<string, SlotForTransaction[]> {
    const slotsMap = new Map<string, SlotForTransaction[]>();
    if (!transactions) {
        return slotsMap;
    }
    return transactions.reduce((result, item) => {
        if (item.slot_id || item.slot_reservation_aux) {
            let slot: undefined | Slot;
            if (item.slot_id) {
                slot = slotsIdMap.get(item.slot_id);
            }
            if (slot === undefined && excludeScheduledTransactions !== true && item.slot_reservation_aux !== null) {
                const key = Array.from(slotsIdMap.keys()).find((id) => id === getIdFromRealId(item.slot_reservation_aux!.slot));
                if (key !== undefined) slot = slotsIdMap.get(key);
            }

            if (slot) {
                let transactions = result.get(slot.id);
                if (!transactions) {
                    transactions = [];
                }
                transactions.push({
                    transaction: item,
                    ...slot
                });
                result.set(slot.id, transactions);
            }
        }
        return result;
    }, slotsMap);
}

export function aggregateTransactionsByProduct<T extends Transaction>(transactions: T[], products: Product[]): [Map<Product, T[]>, T[]] {
    const productsIdMap = transformProductsToIdMap(products);

    const productTransactionsMap = new Map<Product, T[]>();
    const transactionsUnknownProduct: T[] = [];
    for (const transaction of transactions) {
        if (transaction.product_instances.length === 0) {
            transactionsUnknownProduct.push(transaction);
        } else {
            for (const productInstance of transaction.product_instances) {
                const product = productsIdMap.get(productInstance.product);
                if (!product && !transactionsUnknownProduct.includes(transaction)) {
                    transactionsUnknownProduct.push(transaction);
                } else if (product) {
                    let productTransactions = productTransactionsMap.get(product);
                    if (!productTransactions) {
                        productTransactions = [];
                    }
                    productTransactions.push(transaction);
                    productTransactionsMap.set(product, productTransactions);
                }
            }
        }
    }

    return [productTransactionsMap, transactionsUnknownProduct];
}

export function mergeTransactionsWithProducts(transactions: Transaction[], products: Product[]): TransactionWithProduct[] {
    const productsIdMap = transformProductsToIdMap(products);
    return transactions.map((transaction) => {
        return {
            ...transaction,
            productInstancesWithProduct: transaction.product_instances.map((productInstance): ProductInstanceWithProduct => {
                return {
                    ...productInstance,
                    productFetched: productsIdMap.get(productInstance.product)
                };
            })
        };
    });
}

export interface ProductInstanceOwnership {
    productInstance: ProductInstanceForTransaction;
    transferDone?: Transaction;
    transferInProgress?: Transaction;
}

export function transformTransactionHistoryToOwnershipInfo(transactions: Transaction[]) {
    // First sort all transactions on created_date, oldest first
    const transactionsSorted = transactions.sort((t1, t2) => {
        const t1_created_date = new Date(t1.created_date);
        const t2_created_date = new Date(t2.created_date);
        return t1_created_date.getTime() - t2_created_date.getTime();
    });

    // Then reduce the history to a Map of product instances to OwnershipInfo
    return transactionsSorted.reduce((result, transaction) => {
        // For each product instance, update the result
        result = transaction.product_instances.reduce((result, productInstance) => {
            if (transaction.status === TransactionStatus.PICKUP_IN_PROGRESS || transaction.status === TransactionStatus.COMPLETED) {
                // Is the transaction pickup done? Then we count the transfer of ownership as being complete.
                result.set(productInstance.id, {
                    productInstance: {
                        ...productInstance,
                        transaction: transaction
                    },
                    transferDone: transaction
                });
            } else if (
                transaction.status === TransactionStatus.DROPOFF_IN_PROGRESS ||
                transaction.status === TransactionStatus.READY_FOR_PICKUP ||
                transaction.status === TransactionStatus.READY_FOR_DROPOFF ||
                transaction.status === TransactionStatus.NEW
            ) {
                // Is the product instance (about to be) dropped off in the distrispot? Then we return an in progress result and let the
                // caller decide what to do with it, as it is context dependant.
                result.set(productInstance.id, {
                    productInstance: {
                        ...productInstance,
                        transaction: transaction
                    },
                    transferInProgress: transaction
                });
            } else if (transaction.status === TransactionStatus.ERROR) {
                // A transaction in error invalidates everything we thought we knew about this transaction.
                result.delete(productInstance.id);
            }
            // All the other states (cancelled) don't have impact on ownership so ignore those.
            return result;
        }, result);

        return result;
    }, new Map<string | number, ProductInstanceOwnership>());
}

const locationIdNameMap = {
    A1: 'Blue Ribbon',
    A2: 'Court',
    A3: 'Windrunner',
    F1: 'Cortez',
    F2: 'Wings',
    E1: 'Equipment & Returns',
    S1: 'NONDC',
    S2: 'NONDC',
    S3: 'NONDC',
    S4: 'NONDC',
    S5: 'NLS',
    S6: 'Maintenance & Engineering',
    S7: 'NONDC',
    S8: 'NONDC',
    S9: 'NONDC',
    SD: "Distribution Cross DC's",
    SN: 'NONDC',
    SP: 'NONDC',
    SW: 'NONDC',
    XX: 'NONDC',
    S0: 'NONDC',
    Q0: 'NONDC',
    VR: 'NONDC',
    VB: 'NONDC',
    VG: 'NONDC',
    SZ: 'MDP',
    g: 'No department/Wrong team ID'
};

export function teamIdToLocation(teamId: string): string | undefined {
    const locationId = teamId.slice(0, 2);
    const locationIdGuard = (locationId: string): locationId is keyof typeof locationIdNameMap => {
        return locationId in locationIdNameMap;
    };

    if (locationIdGuard(locationId)) {
        return locationIdNameMap[locationId];
    }
    return undefined;
}
