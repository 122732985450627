import { FormattedMessage } from 'react-intl';

import { Product } from '../../api/products';
import { Slot } from '../../api/slots';
import ActionCard from '../../components/domain/ActionCard';
import ActionCardGridElement from '../../components/elements/ActionCardGridElement';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ViewTitle from '../nike/ViewTitle';

interface SlotTypeChoiceViewProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onSelect?: (productId: number) => void;
    onHelp: () => void;
    products?: Product[];
    slots?: Slot[];
    actions: ActionCard[];
}

const SlotTypeChoiceView = (props: SlotTypeChoiceViewProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const filteredActions = (): ActionCard[] => {
        const result: ActionCard[] = [];
        const specs: string[] = [];
        props.slots?.forEach((s) => {
            specs.push(s.specification.name);
        });

        if (specs.includes('HAVN_MODEL_0') || specs.includes('Streetwaves Haven 1') || specs.includes('HAVN - bootstrapped')) {
            const action = props.actions.find((a) => a.actionText.toLocaleUpperCase().includes('E-BIKE LOCKER'));
            if (action) {
                result.push(action);
            }
        }
        if (specs.includes('Door_S - bootstrapped') || specs.includes('Door_M - bootstrapped') || specs.includes('Door_L - bootstrapped')) {
            const action = props.actions.find((a) => a.actionText.toLocaleUpperCase().includes('E-LOCKER'));
            if (action) {
                result.push(action);
            }
        }
        const action = props.actions.find((a) => a.actionText.includes('?'));
        if (action) {
            result.push(action);
        }
        return result;
    };

    const selectAction = (action: ActionCard) => {
        if (props.onHelp && action.actionText.includes('?')) {
            props.onHelp();
        }
        if (props.onSelect) {
            if (action.actionText.toLocaleUpperCase().includes('E-BIKE LOCKER')) {
                const product = props.products?.find((p) => p.name.includes('fietslocker'));
                if (product != undefined) {
                    props.onSelect(+product.id);
                }
            } else if (action.actionText.toLocaleUpperCase().includes('E-LOCKER')) {
                const product = props.products?.find((p) => p.name.includes('gewone locker'));
                if (product != undefined) {
                    props.onSelect(+product.id);
                }
            }
        }
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.lending.SlotTypeChoiceView.title'
                    description='The title on the SlotTypeChoiceView.'
                    defaultMessage='What type of SLOT do you want to use?'
                />
            </ViewTitle>
            <ActionCardGridElement
                actions={filteredActions()}
                onSelectAction={selectAction}
            />
        </BaseView>
    );
};

export default SlotTypeChoiceView;
