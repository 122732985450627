import { useEffect, useState } from 'react';

import { Contact, isContact } from '../../api/contacts';
import { Logger } from '../../logs/Logger';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest } from '../useManagedWebsocket';

declare global {
    interface WindowEventMap {
        authenticate_contact_event: CustomEvent<Contact>;
    }
}

export class WebsocketAuthenticateContactCommand extends WebsocketCommand {
    data: {
        contact: Contact;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        const contact = JSON.parse(request.contact as string);
        if (contact == null || !isContact(contact)) {
            throw new Error('Incorect information passed.');
        }
        this.data = {
            contact: contact
        };
    }

    public async execute(): Promise<WebsocketCommandResponse> {
        Logger.log(`Contact with id ${this.data.contact.id} authenticated via websocket (backend).`, { contact: this.data.contact.id }, this.data);
        window.dispatchEvent(new CustomEvent('authenticate_contact_event', { detail: this.data.contact }));
        return { success: true };
    }
}

interface Props {
    callback?: (contact: Contact) => void;
}

export default function useWebsocketContactAuthentication(props: Props) {
    const [contact, changeContact] = useState<Contact | undefined>(undefined);

    useEffect(() => {
        const listener = (event: { detail: Contact }) => {
            props.callback && props.callback(event.detail);
            changeContact(event.detail);
        };
        window.addEventListener('authenticate_contact_event', listener);
        return () => {
            window.removeEventListener('authenticate_contact_event', listener);
        };
    }, []);

    return contact;
}
