import { useEffect, useState } from 'react';

import { Transaction } from '../../../common/transactions';
import { LookupResult } from '../useBaseLookup';
import useLookupDropoffCode from './useLookupDropoffCode';
import useLookupDropoffQr from './useLookupDropoffQr';
import useLookupDropoffUserBadge from './useLookupDropoffUserBadge';
import useLookupPickupCode from './useLookupPickupCode';
import useLookupPickupQr from './useLookupPickupQr';
import useLookupPickupUserBadge from './useLookupPickupUserBadge';
import useLookupRemovalCode from './useLookupRemovalCode';
import useLookupRemovalQr from './useLookupRemovalQr';

export enum LookupFlags {
    NONE = 0,
    DROPOFF_QR = 1 << 0,
    DROPOFF_CODE = 1 << 1,
    PICKUP_QR = 1 << 2,
    PICKUP_CODE = 1 << 3,
    REMOVAL_QR = 1 << 6,
    REMOVAL_CODE = 1 << 7,
    PICKUP_USER_BADGE = 1 << 4,
    DROPOFF_USER_BADGE = 1 << 5,
    COMPLETED = 2 << 7,
    ALL = ~(~0 << 10)
}

interface TransactionsLookupOptions {
    lookup: LookupFlags;
    onSuccess?: (transactionResults: TransactionResult[]) => void;
}
type TransactionsLookupOptionsPartial = Partial<TransactionsLookupOptions>;

class DefaultTransactionsLookupOptions implements TransactionsLookupOptions {
    lookup = LookupFlags.ALL;
}

interface TransactionsLookup {
    options: TransactionsLookupOptions;
    value: string;
}

export enum LookupState {
    IDLE = 'idle',
    IN_PROGRESS = 'in_progress',
    REFETCHING = 'refetching',
    SUCCESS = 'success',
    ERROR = 'error'
}

export interface TransactionResult {
    transaction: Transaction;
    lookup: LookupFlags;
}

interface LookupTypeResult {
    lookupType: LookupFlags;
    lookupResult: LookupResult<Transaction[], string>;
    isEnabled: boolean;
}

const lookupTypeImplementations = new Map<LookupFlags, any>();
lookupTypeImplementations.set(LookupFlags.DROPOFF_CODE, useLookupDropoffCode);
lookupTypeImplementations.set(LookupFlags.DROPOFF_QR, useLookupDropoffQr);
lookupTypeImplementations.set(LookupFlags.PICKUP_CODE, useLookupPickupCode);
lookupTypeImplementations.set(LookupFlags.PICKUP_QR, useLookupPickupQr);
lookupTypeImplementations.set(LookupFlags.DROPOFF_USER_BADGE, useLookupDropoffUserBadge);
lookupTypeImplementations.set(LookupFlags.PICKUP_USER_BADGE, useLookupPickupUserBadge);
lookupTypeImplementations.set(LookupFlags.REMOVAL_CODE, useLookupRemovalCode);
lookupTypeImplementations.set(LookupFlags.REMOVAL_QR, useLookupRemovalQr);

export const useTransactionsLookup = () => {
    const [currentLookup, changeCurrentLookup] = useState<TransactionsLookup | null>(null);

    const lookupTypeResults: LookupTypeResult[] = [];
    for (const [lookupType, lookupTypeHook] of lookupTypeImplementations.entries()) {
        const lookupTypeEnabled = !!currentLookup && (currentLookup.options.lookup & lookupType) === lookupType;
        const lookupResult = lookupTypeHook({
            lookupValue: lookupTypeEnabled ? currentLookup!.value : null
        }) as LookupResult<Transaction[], string>;
        lookupTypeResults.push({
            lookupType: lookupType,
            lookupResult: lookupResult,
            isEnabled: lookupTypeEnabled
        });
    }

    const results = lookupTypeResults
        .map((result) => {
            if (result.isEnabled && result.lookupResult.isSuccess && result.lookupResult.result) {
                return result.lookupResult.result.map((tx) => {
                    return {
                        transaction: tx,
                        lookup: result.lookupType
                    };
                });
            } else {
                return [];
            }
        })
        .flat();

    const lookup = (value: string, options?: TransactionsLookupOptionsPartial) => {
        changeCurrentLookup({
            value: value,
            options: {
                ...new DefaultTransactionsLookupOptions(),
                ...options
            }
        });
    };

    const reset = () => {
        changeCurrentLookup(null);
    };

    const isIdle = currentLookup == null;
    const isSuccess = lookupTypeResults.every((lookupTypeResult) => {
        return !lookupTypeResult.isEnabled || lookupTypeResult.lookupResult.isSuccess;
    });
    const isError = lookupTypeResults.some((lookupTypeResult) => {
        return lookupTypeResult.isEnabled && lookupTypeResult.lookupResult.isError;
    });
    const isInProgress = lookupTypeResults.some((lookupTypeResult) => {
        return lookupTypeResult.isEnabled && lookupTypeResult.lookupResult.isInProgress;
    });

    useEffect(() => {
        if (isSuccess && currentLookup && currentLookup.options.onSuccess) {
            currentLookup.options.onSuccess(results);
        }
    }, [isSuccess, currentLookup]);

    return {
        lookup: lookup,
        reset: reset,
        lookupValue: currentLookup?.value,
        transactionResults: results,
        isSuccess: isSuccess,
        isError: isError,
        isIdle: isIdle,
        isInProgress: isInProgress
    };
};
