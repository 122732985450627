import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../api/contacts';
import { ProductInstance } from '../../api/productInstances';
import { Product, useProduct } from '../../api/products';
import { getFullName } from '../../utils';
import { nikeProductParameters } from '../../workflow/nike/VendingWorkflow';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import { ProductParameter } from './ProductConfigurationView';
import ViewTitle from './ViewTitle';

interface ReturnConfirmationViewProps {
    productInstance: ProductInstance;
    onConfirm: (reason?: string) => void;
    returnFor?: Contact | null;
    productParameters?: ProductParameter[] | null;
    onHome?: () => void;
    onLogout?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

interface ProductCardProps {
    product?: Product;
    productParameters?: ProductParameter[] | null;
}

const returnReasons = new Map<string, React.ReactNode>();
returnReasons.set(
    'damaged',
    <FormattedMessage
        id='views.nike.ReturnConfirmationView.ReturnReasonDamaged'
        description='A return reason on the Nike return reason page'
        defaultMessage='Damaged'
    />
);
returnReasons.set(
    'wrong_size',
    <FormattedMessage
        id='views.nike.ReturnConfirmationView.ReturnReasonWrongSize'
        description='A return reason on the Nike return reason page'
        defaultMessage='Wrong size'
    />
);
returnReasons.set(
    'other',
    <FormattedMessage
        id='views.nike.ReturnConfirmationView.ReturnReasonOther'
        description='A return reason on the Nike return reason page'
        defaultMessage='Other'
    />
);

function ProductCard(props: ProductCardProps) {
    const productParameters = props.productParameters ? props.productParameters : [];

    return (
        <div className='card my-4'>
            <div className='card-body'>
                {props.product ? (
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                        <h4>{props.product.name}</h4>
                        {productParameters.map((productParameter) => {
                            const parameterValue = props.product ? productParameter.getValue(props.product) : undefined;
                            return (
                                <p key={productParameter.name}>
                                    {productParameter.name} <span className='font-weight-bold'>{parameterValue ? parameterValue : 'unknown'}</span>
                                </p>
                            );
                        })}
                    </div>
                ) : (
                    <div className='d-flex flex-row justify-content-center'>
                        <div
                            className='spinner-border text-primary align-self-center'
                            role='status'></div>
                    </div>
                )}
            </div>
        </div>
    );
}

function ReturnConfirmationView(props: ReturnConfirmationViewProps) {
    const productResult = useProduct(props.productInstance.product);
    const [returnReason, changeReturnReason] = useState('');

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.ReturnConfirmationView.title'
                    description='The title on the page where you confirm the return of your item in the Nike workflow'
                    defaultMessage='Confirm the return of your item'
                />
            </ViewTitle>
            {props.returnFor ? (
                <p className='text-center'>
                    for <span className='font-weight-bold'>{getFullName(props.returnFor)}</span>
                </p>
            ) : undefined}
            <p className='text-center'>
                <FormattedMessage
                    id='views.nike.ReturnConfirmationView.introMessage'
                    description='The intro message on the page where you confirm the return of your item in the Nike workflow'
                    defaultMessage='You are about to return the following products. Please verify that this is correct.'
                />
            </p>
            <ProductCard
                product={productResult.data}
                productParameters={nikeProductParameters}
            />
            <h6 className='text-center'>
                <FormattedMessage
                    id='views.nike.ReturnConfirmationView.returnReasonTitle'
                    description='The subtitle asking for the reason of return on the page where you confirm the return of your item in the Nike workflow'
                    defaultMessage='May we ask the reason for return?'
                />
            </h6>
            <div className='d-flex flex-row justify-content-center'>
                {Array.from(returnReasons.entries()).map(([returnReasonCurrent, displayReturnReason]) => {
                    return (
                        <button
                            key={returnReasonCurrent}
                            className={`btn rounded-pill mx-2 ${returnReason === returnReasonCurrent ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => {
                                if (returnReasonCurrent === returnReason) {
                                    changeReturnReason('');
                                } else {
                                    changeReturnReason(returnReasonCurrent);
                                }
                            }}>
                            {displayReturnReason}
                        </button>
                    );
                })}
            </div>
            <div className='d-flex flex-row mt-5'>
                <button
                    className='btn btn-outline-primary btn-lg mx-4 flex-grow-1'
                    onClick={props.onBack}>
                    <FormattedMessage
                        id='views.nike.ReturnConfirmationView.cancelReturnButton'
                        description='The cancel button on the page where you confirm the return of your item in the Nike workflow'
                        defaultMessage='Cancel and go back'
                    />
                </button>
                <button
                    className='btn btn-primary btn-lg mx-4 flex-grow-1'
                    disabled={returnReason === ''}
                    onClick={() => {
                        props.onConfirm(returnReason);
                    }}>
                    <FormattedMessage
                        id='views.nike.ReturnConfirmationView.confirmReturnButton'
                        description='The confirm return button on the page where you confirm the return of your item in the Nike workflow'
                        defaultMessage='Yes! Return item'
                    />
                </button>
            </div>
        </BaseView>
    );
}

export default ReturnConfirmationView;
