import { PlatformHardwareDriver, SpotLayoutItem } from '../../api/spotLayoutItems';
import { ADAMHardwareObject, checkADAMDriverOptions, getADAMHardwareOptions, mapADAMHardwareOptions } from './utils/ADAMHardwareOptions';
import {
    KerongHardwareObject,
    getKerongHardwareOptions,
    isValidKerongDriver,
    isValidKerongSpotLayoutItem,
    mapKerongHardwareOptions
} from './utils/KerongHardwareOptions';
import { PSPGHardwareObject, SlotAction, checkPSPGDriverOptions, getPSPGHardwareOptions, isValidPSPGSpotLayoutItem } from './utils/PSPGHardwareOptions';
import { ZhilaiHardwareObject, getZhilaiHardwareOptions, isValidZhilaiSpotLayoutItem, mapZhilaiHardwareOptions } from './utils/ZhilaiHardwareOptions';

export interface BackendHardwareDriverOptionsObject {
    driver: PlatformHardwareDriver;
    config: {
        board?: string;
        slot?: string;
        ip?: string;
        row?: string;
        column?: string;

        //ADAM
        username?: string;
        password?: string;
        port?: string;
        doorOpenTime?: number;

        //PROTON
        clusterURI?: string;
        deviceId?: number[];
        eslId?: number;
        enable?: boolean;
    };
}

export interface TerminalHardwareObject {
    driver: PlatformHardwareDriver;
}

//converters
export function getHardwareDriverConfig(spotLayoutItems: SpotLayoutItem[]): TerminalHardwareObject[] {
    const all: TerminalHardwareObject[] = [];
    spotLayoutItems
        .filter((i) => i.terminal === null)
        .forEach((spotLayoutItem) => {
            const options = getHardwareDriverOptions(spotLayoutItem);
            if (options !== null) all.push(options);
        });

    return [
        ...mapZhilaiHardwareOptions(all.filter((o) => o.driver === PlatformHardwareDriver.ZHILAI) as ZhilaiHardwareObject[]),
        ...mapKerongHardwareOptions(all.filter((o) => isValidKerongDriver(o.driver)) as KerongHardwareObject[]),
        ...mapADAMHardwareOptions(all.filter((o) => o.driver === PlatformHardwareDriver.ADAM6060) as ADAMHardwareObject[]),
        ...(all.filter((o) => o.driver === PlatformHardwareDriver.PSPG) as PSPGHardwareObject[])
    ];
}

/**
 * Returns the hardware driver options for a given spot layout item and slot action.
 * @param spotLayoutItem The spot layout item to retrieve the hardware driver options for.
 * @param action Optional slot action to pass to the hardware options functions.
 * @returns The terminal hardware object containing the hardware driver options for the given spot layout item, or null if the driver is not recognized.
 */
export function getHardwareDriverOptions(spotLayoutItem: SpotLayoutItem, action?: SlotAction): TerminalHardwareObject | null {
    const relevantDrivers = spotLayoutItem.hardware_driver_options.data.filter(
        (o) => ![PlatformHardwareDriver.PROTON, PlatformHardwareDriver.UNKNOWN].includes(o.driver)
    );
    const driver = relevantDrivers[0]?.driver;

    switch (driver) {
        case PlatformHardwareDriver.ZHILAI:
            return getZhilaiHardwareOptions(spotLayoutItem);
        case PlatformHardwareDriver.PSPG:
            return getPSPGHardwareOptions(spotLayoutItem, action);
        case PlatformHardwareDriver.KERONG_12CU:
        case PlatformHardwareDriver.KERONG_CU:
        case PlatformHardwareDriver.KERONG_SCU:
            return getKerongHardwareOptions(spotLayoutItem);
        case PlatformHardwareDriver.ADAM6060:
            return getADAMHardwareOptions(spotLayoutItem);
        default:
            return null;
    }
}

//checks
/**
 * This function will return the true if the options are completly valid (they will also be typed as BackendHardwareDriverOptionsObject[])
 * @param options
 * @returns
 */
export function checkHardwareDriverOptions(options: any[]): options is BackendHardwareDriverOptionsObject[] {
    if (areAllBackendHardwareDriverOptionsObject(options)) {
        return checkAllOptionsValidForDriver(options);
    }
    return false;
}

export function checkHardwareDriverOptionsBySpotLayoutItem(spotLayoutItem: SpotLayoutItem): boolean {
    return checkHardwareDriverOptions(spotLayoutItem.hardware_driver_options.data);
}

function areAllBackendHardwareDriverOptionsObject(options: any[]): options is BackendHardwareDriverOptionsObject[] {
    return !options.map(isBackendHardwareDriverOptionsObject).includes(false);
}

function isBackendHardwareDriverOptionsObject(options: any): options is BackendHardwareDriverOptionsObject {
    return 'driver' in options;
}

function checkAllOptionsValidForDriver(options: BackendHardwareDriverOptionsObject[]): boolean {
    return !options.map(checkOptionValidForDriver).includes(false);
}

function checkOptionValidForDriver(options: BackendHardwareDriverOptionsObject): boolean {
    switch (options.driver) {
        case PlatformHardwareDriver.ZHILAI:
            return checkZHILAIDriverOptions(options);
        case PlatformHardwareDriver.PSPG:
            return checkPSPGDriverOptions(options);
        case PlatformHardwareDriver.KERONG_CU:
        case PlatformHardwareDriver.KERONG_SCU:
        case PlatformHardwareDriver.KERONG_12CU:
            return checkKERONGDriverOptions(options);
        case PlatformHardwareDriver.ADAM6060:
            return checkADAMDriverOptions(options);
        default:
            return false;
    }
}

export function checkZHILAIDriverOptions(options: BackendHardwareDriverOptionsObject): boolean {
    return !(!options.config.slot || !options.config.board || options.driver !== PlatformHardwareDriver.ZHILAI);
}

function checkKERONGDriverOptions(options: BackendHardwareDriverOptionsObject): boolean {
    return (
        !!options.config.slot &&
        !!options.config.board &&
        !!options.config.ip &&
        (options.driver === PlatformHardwareDriver.KERONG_CU ||
            options.driver === PlatformHardwareDriver.KERONG_SCU ||
            options.driver === PlatformHardwareDriver.KERONG_12CU)
    );
}

export function canOpen(item: SpotLayoutItem): boolean {
    return isValidZhilaiSpotLayoutItem(item) || isValidKerongSpotLayoutItem(item) || isValidPSPGSpotLayoutItem(item);
}
