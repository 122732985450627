import { useContext } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { Slot } from '../api/slots';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import { TerminalWorkflowType } from '../api/terminals';
import { Transaction } from '../common/transactions';
import useTransactionsWithSlots from './useTransactionsWithSlots';

const useTransactionPickupForSlot = (slot: Slot): Transaction[] | undefined => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: transactionSlotMap } = useTransactionsWithSlots({
        spotId: spot?.id
    });
    if (terminal?.workflow !== TerminalWorkflowType.PUDO && terminal?.workflow !== TerminalWorkflowType.PUDO_PRIVATE) return undefined;
    if (slot.settings_allow_multiple_pudo_pickup_transactions) {
        return transactionSlotMap?.get(slot.id)?.map((slot) => slot.transaction);
    } else return undefined;
};

export default useTransactionPickupForSlot;
