import { PlatformHardwareDriver, SpotLayoutItem } from '../../../api/spotLayoutItems';
import { Logger } from '../../../logs/Logger';
import { BackendHardwareDriverOptionsObject, TerminalHardwareObject } from '../DriverSlotObjects';

export enum SlotAction {
    OPEN,
    CLOSE,
    OPEN_AND_CLOSE
}

export interface PSPGHardwareObject extends TerminalHardwareObject {
    column: string;
    row: string;
    action?: SlotAction;
    ip?: string;
}

export function getPSPGHardwareOptions(spotLayoutItem: SpotLayoutItem, action?: SlotAction): PSPGHardwareObject | null {
    const options = spotLayoutItem.hardware_driver_options.data;
    if (options.find((option) => !checkPSPGDriverOptions(option)) !== undefined) {
        Logger.error('incorrect driver params given', {}, spotLayoutItem);
        return null;
    }
    return getPSPGDriverOptions(spotLayoutItem, action);
}

function getPSPGDriverOptions(spotLayoutItem: SpotLayoutItem, action?: SlotAction): PSPGHardwareObject {
    const slotAction = action !== undefined ? action : SlotAction.OPEN_AND_CLOSE;
    const options = spotLayoutItem.hardware_driver_options.data;
    return {
        driver: PlatformHardwareDriver.PSPG,
        column: options[0].config.column!,
        row: options[0].config.row!,
        ip: options[0].config.ip,
        action: slotAction
    };
}

export function isValidPSPGSpotLayoutItem(spotLayoutItem: SpotLayoutItem): boolean {
    return spotLayoutItem.hardware_driver_options.data.some((driver) => checkPSPGDriverOptions(driver));
}

export function checkPSPGDriverOptions(options: BackendHardwareDriverOptionsObject): boolean {
    return options.driver === PlatformHardwareDriver.PSPG && options.config.row !== undefined && options.config.column !== undefined;
}
