import { useEffect, useState } from 'react';

import { Product } from '../../../api/products';
import { Slot, getColumnNumber, getRowNumber } from '../../../api/slots';
import { SpotLayoutItem, sortBySlot } from '../../../api/spotLayoutItems';

interface StockRefillProductWithSlotsViewProps {
    product: Product | null;
    spotLayoutItems: SpotLayoutItem[];
    spotSlotMap: Map<SpotLayoutItem, Slot>;
}

const StockRefillProductWithSlotsView = (props: StockRefillProductWithSlotsViewProps) => {
    const [sortedItems, changeSortedItems] = useState<SpotLayoutItem[]>([]);
    const [rowLabels, changeRowLabels] = useState<string[]>([]);
    const [columnLabels, changeColumnLabels] = useState<string[]>([]);

    useEffect(() => {
        changeSortedItems(props.spotLayoutItems?.sort((a, b) => sortBySlot(a, b)));
    }, [props.spotLayoutItems]);

    useEffect(() => {
        const keys: string[] = [];
        const values: string[] = [];

        sortedItems.forEach((item) => {
            const slot = props.spotSlotMap.get(item);
            if (slot) {
                if (keys.indexOf(getRowNumber(slot)) !== -1) {
                    values[keys.indexOf(getRowNumber(slot))] = values[keys.indexOf(getRowNumber(slot))] + ',' + getColumnNumber(slot);
                } else {
                    keys.push(getRowNumber(slot));
                    values[keys.indexOf(getRowNumber(slot))] = getColumnNumber(slot);
                }
            }
        });

        changeRowLabels(keys);
        changeColumnLabels(values);
    }, [sortedItems, props.spotSlotMap]);

    return (
        <div className='information-card'>
            <p className='title'>{props.product ? props.product.name : 'No linked product'}</p>

            {rowLabels.map((value, index) => {
                return (
                    <p key={value}>
                        Row: {value} SLOTS: {columnLabels[index]}
                    </p>
                );
            })}
            <span className='text-success fw-bold'>total {props.spotLayoutItems.length}</span>
        </div>
    );
};

export default StockRefillProductWithSlotsView;
