import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact, useMutateContactAdd } from '../../api/contacts';
import { Logger } from '../../logs/Logger';
import UserInfoFormView from '../../views/lending/UserInfoFormView';
import { UserSpecificType } from './LendingWorkflow';

export enum AdditionalDataType {
    SHORT_TEX = 'short_text',
    LONG_TEXT = 'long_text',
    SINGLE_CHOICE = 'single_choice',
    MULTIPLE_CHOICE = 'multiple_choice',
    DROP_DOWN_SINGLE_CHOICE = 'drop_down_single_choice',
    DROP_DOWN_MULTIPLE_CHOICE = 'drop_down_multiple_choice',
    DATE_PICKER = 'date_picker',
    DATE_TIME_PICKER = 'date_time_picker'
}

interface UserInfoWorkflowProps {
    onHome?: () => void;
    onInactivity?: () => void;
    onBack?: () => void;
    onSubmit: (contact: Contact) => void;
    slotType?: number[] | null;
    account_id: number;
    customWorkflow?: UserSpecificType;
    contact?: Contact;
}

const UserInfoWorkflow = (props: UserInfoWorkflowProps) => {
    const { currentLocale } = useContext<TerminalContextInterface>(TerminalContext);
    const [contact, changeContact] = useState<Contact | undefined>(props.contact ? props.contact : undefined);
    const [questionModalOpen, changeQuestionModalOpen] = useState<boolean>(false);
    const [userSubmit, changeUserSubmit] = useState<boolean>(false);

    const mutateContactAdd = useMutateContactAdd({
        onSuccess: (contact) => {
            changeContact(contact);
        },
        onError: (err) => {
            Logger.error(err);
        }
    });

    useEffect(() => {
        if (contact?.id && userSubmit) {
            props.onSubmit(contact);
        }
    }, [contact]);

    const onSubmit = async (newContact: Contact) => {
        changeContact(newContact);
        changeUserSubmit(true);
        if (!props.slotType?.includes(23) || props.customWorkflow != UserSpecificType.BREE) {
            await mutateContactAdd.mutate({
                contact: newContact
            });
        } else {
            changeQuestionModalOpen(true);
        }
    };

    const onModalAnswer = async (answer: string) => {
        changeQuestionModalOpen(false);
        if (contact) {
            const newContact: Contact = {
                ...contact,
                additional_data: {
                    form_fields: [
                        {
                            type: AdditionalDataType.DROP_DOWN_SINGLE_CHOICE,
                            label: 'What kind of bicycle are you using?',
                            name: 'bicycle_type',
                            options: [
                                {
                                    label: 'Standard Bike',
                                    value: 'Standard Bike'
                                },
                                {
                                    label: 'Speed Pedelec',
                                    value: 'Speed Pedelec'
                                },
                                {
                                    label: 'E-Bike',
                                    value: 'E-Bike'
                                }
                            ],
                            value: answer,
                            editable: true,
                            visible: true,
                            disabled: false
                        }
                    ]
                }
            };
            changeContact(newContact);
            await mutateContactAdd.mutate({
                contact: newContact
            });
        }
    };

    return (
        <UserInfoFormView
            onBack={props.onBack}
            onHome={props.onHome}
            onInactivity={props.onHome}
            onSubmit={onSubmit}
            contact={contact}
            account_id={props.account_id}
            modalOpen={questionModalOpen}
            modalOnAnswer={onModalAnswer}
            language={currentLocale}
        />
    );
};

export default UserInfoWorkflow;
