import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { ContactGroup, useContactGroup, useContactGroups } from '../../api/contactGroups';
import { Contact, useContact, useContacts } from '../../api/contacts';
import { Slot } from '../../api/slots';
import { Spot } from '../../api/spots';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import LoadingView from '../../views/common/LoadingView';
import ContactGroupSelectView from '../../views/elements/contactGroups/ContactGroupSelectView';
import ContactSelectView from '../../views/elements/contacts/ContactSelectView';

enum ReceiverType {
    CONTACT,
    CONTACT_GROUP
}

interface SelectReceiverWorkflowProps {
    contact: Contact | undefined;
    spot: Spot;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onSelect: (contactResult: Contact | ContactGroup, receiverHint?: string) => void;
    onSelectSlot?: (slot: Slot) => void;
}

const SelectReceiverWorkflow = (props: SelectReceiverWorkflowProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const [receiver, changeReceiver] = useState<Contact | ContactGroup | undefined>(undefined);

    const [receiverType, changeReceiverType] = useState<ReceiverType>(ReceiverType.CONTACT);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: defaultReceiverContact } = useContact(terminal?.default_receiver_contact);
    const { data: receiversContactGroup } = useContactGroup(terminal?.receiver_contact_group_url);
    const { data: contactsCompanyResults } = useContacts(receiversContactGroup?.contacts, { accountId: props.spot.account });
    const { data: allGroups } = useContactGroups();
    const [receiverGroups, changeReceiverGroups] = useState<ContactGroup[]>([]);

    useEffect(() => {
        if (allGroups && receiversContactGroup) changeReceiverGroups(allGroups.filter((g) => g.parent && g.parent === receiversContactGroup.url));
    }, [allGroups]);

    const contactSelected = (contact: Contact | ContactGroup, receiverHint?: string) => {
        props.onSelect && props.onSelect(contact, receiverHint);
    };

    const TypeSelect = () => (
        <div className='button-select'>
            <button
                className={receiverType === ReceiverType.CONTACT ? 'selected' : ''}
                onClick={() => changeReceiverType(ReceiverType.CONTACT)}>
                Contacts
            </button>
            <button
                className={receiverType === ReceiverType.CONTACT_GROUP ? 'selected' : ''}
                onClick={() => changeReceiverType(ReceiverType.CONTACT_GROUP)}>
                Groups
            </button>
        </div>
    );

    if (receiverType === ReceiverType.CONTACT) {
        if (receiver === undefined && contactsCompanyResults !== undefined) {
            const contactsToDisplay = receiversContactGroup ? contactsCompanyResults : [];
            const contacts = defaultReceiverContact ? [defaultReceiverContact, ...contactsToDisplay] : contactsToDisplay;
            if (props.contact) {
                return (
                    <BaseView
                        onInactivity={props.onInactivity}
                        navbarItems={backHomeNav}>
                        <TypeSelect></TypeSelect>
                        <ContactSelectView
                            onSelect={contactSelected}
                            contacts={contacts}
                        />
                    </BaseView>
                );
            } else {
                return (
                    <BaseView
                        onInactivity={props.onInactivity}
                        navbarItems={backHomeNav}>
                        <TypeSelect></TypeSelect>
                        <ContactSelectView
                            onSelect={(c) => props.onSelect(c, undefined)}
                            contacts={contacts}
                        />
                    </BaseView>
                );
            }
        } else {
            return (
                <LoadingView
                    onHome={props.onHome}
                    onBack={props.onBack}
                    onInactivity={props.onInactivity}></LoadingView>
            );
        }
    } else {
        if (receiver === undefined && allGroups !== undefined) {
            if (props.contact) {
                return (
                    <BaseView
                        onInactivity={props.onInactivity}
                        navbarItems={backHomeNav}>
                        <TypeSelect></TypeSelect>
                        <ContactGroupSelectView
                            onSelect={contactSelected}
                            contactGroups={receiverGroups}
                        />
                    </BaseView>
                );
            } else {
                return (
                    <BaseView
                        onInactivity={props.onInactivity}
                        navbarItems={backHomeNav}>
                        <TypeSelect></TypeSelect>
                        <ContactGroupSelectView
                            onSelect={(c) => props.onSelect(c, undefined)}
                            contactGroups={receiverGroups}
                        />
                    </BaseView>
                );
            }
        } else {
            return (
                <LoadingView
                    onHome={props.onHome}
                    onBack={props.onBack}
                    onInactivity={props.onInactivity}></LoadingView>
            );
        }
    }
};

export default SelectReceiverWorkflow;
