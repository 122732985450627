import _ from 'lodash';

import { usePrinters } from '../api/IOT/printers';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import { Terminal } from '../api/terminals';
import { Logger } from '../logs/Logger';
import { TerminalLogViewModel } from '../logs/TerminalLog';
import Printer from '../printing/Printer';
import { WebsocketPrinterStatusCommand } from '../websocket/commands/WebsocketPrinterStatusCommand';
import { WebsocketCommandResponseStatus, WebsocketCommandType } from '../websocket/useManagedWebsocket';
import useInterval from './useInterval';

interface Props {
    terminal: Terminal | null;
    websocket: WebSocket | null;
}
const MAX_LENGTH = 30000;
const backendStatusUpdateInterval = 4.5 * 60 * 1000; // 4.5 minutes

export default function useBackendStatusUpdate(props: Props) {
    const { data: spotLayout } = useSpotLayout(props.terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: printers } = usePrinters(spot?.id, { enabled: spot !== undefined });
    useInterval(
        async () => {
            if (props.terminal && props.websocket) {
                Logger.debug('Sending statusUpdate to backend.');
                if (printers !== undefined && printers.length > 0) {
                    try {
                        const pritnerExists = await Printer.getInstance().pritnerExists();
                        if (pritnerExists) {
                            new WebsocketPrinterStatusCommand({ command: WebsocketCommandType.PRINTER_STATUS }).execute().then((result) => {
                                props.websocket!.send(
                                    JSON.stringify({
                                        message: result.message,
                                        status: result.success ? WebsocketCommandResponseStatus.SUCCESS : WebsocketCommandResponseStatus.ERROR,
                                        command: WebsocketCommandType.PRINTER_STATUS,
                                        terminal: props.terminal
                                    })
                                );
                            });
                        } else {
                            props.websocket.send(
                                JSON.stringify({
                                    message: 'No printer found. So skipping printer status check.',
                                    status: WebsocketCommandResponseStatus.SUCCESS,
                                    command: WebsocketCommandType.PRINTER_STATUS,
                                    terminal: props.terminal
                                })
                            );
                        }
                    } catch (error) {
                        Logger.error(error);
                        props.websocket.send(
                            JSON.stringify({
                                message:
                                    'Unable to get printers. Make sure BrowserPrint APK is installed and premissions to fetch were given to the Application.',
                                status: WebsocketCommandResponseStatus.ERROR,
                                command: WebsocketCommandType.PRINTER_STATUS,
                                terminal: props.terminal
                            })
                        );
                    }
                } else Logger.log('No printers received from backend, therefore skipping sending status updates.');

                props.websocket.send(
                    JSON.stringify({
                        message: 'Status of terminal is healty',
                        status: WebsocketCommandResponseStatus.SUCCESS,
                        command: WebsocketCommandType.STATUS_UPDATE,
                        terminal: props.terminal
                    })
                );

                Logger.getLogs().then((logs) => {
                    if (logs) {
                        chunkMessagesToValidSize(logs).forEach((cl) => {
                            props.websocket!.send(
                                JSON.stringify({
                                    message: cl,
                                    status: WebsocketCommandResponseStatus.SUCCESS,
                                    command: WebsocketCommandType.LOGS,
                                    terminal: props.terminal
                                })
                            );
                        });
                        logs && Logger.removeById(logs.map((l) => l.raw_data.id));
                    }
                });
            }
        },
        props.websocket?.OPEN ? backendStatusUpdateInterval : null
    );
}

function chunkMessagesToValidSize(messages: TerminalLogViewModel[]): TerminalLogViewModel[][] {
    if (messages.length === 1) {
        Logger.warn(
            'Message content was to big to be sent via websocket (there was probably to much data stored inside the log).',
            messages[0].filter_data ? messages[0].filter_data : {},
            messages[0].message,
            messages[0].message_type,
            messages[0].event_timestamp
        );
        return [];
    }
    const length = JSON.stringify(messages).length;
    if (length <= MAX_LENGTH) {
        return [messages];
    }

    const amountOfChunks = Math.ceil(length / MAX_LENGTH);
    let result = _.chunk(messages, Math.ceil(messages.length / amountOfChunks));

    while (result.some((x) => JSON.stringify(x).length > MAX_LENGTH)) {
        const temp: any[][] = [];
        result.forEach((message) => {
            if (JSON.stringify(message).length > MAX_LENGTH) {
                temp.push(...chunkMessagesToValidSize(message));
            } else temp.push(message);
        });
        result = temp;
    }
    return result;
}
