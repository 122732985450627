import { useContext, useEffect, useMemo, useState } from 'react';

import { TerminalContext } from '../../../TerminalInit';
import { useTransactionsReceiverContactGroup } from '../../../api/contactGroups';
import { Contact, ContactsAllLookup, useContactGroupsContactsAll, useMutateAuthenticateContact } from '../../../api/contacts';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import { checkIfTransactionInProgressIsStillValid } from '../../../service-worker/TransactionInProgressDatabase';
import { arraysEqual } from '../../../services/global/arrays';
import { asyncTransactionFilter } from '../../../services/transaction/TransactionFilters';
import useBaseLookup from '../useBaseLookup';
import { isMemberOfContactGroup } from './useLookupDropoffUserBadge';

interface LookupPickupUserBadgeProps {
    lookupValue: string | null;
}

const findTransactionsByPickupContact = (
    transactions: Transaction[],
    authenticatedContact?: Contact | null,
    contactsData?: (ContactsAllLookup | undefined)[]
): Promise<Transaction[]> => {
    return asyncTransactionFilter(transactions, async (transaction: Transaction) => {
        if (
            authenticatedContact &&
            ((transaction.receiver && transaction.receiver.id === authenticatedContact.id) ||
                (transaction.receiver_group && isMemberOfContactGroup(authenticatedContact, transaction.receiver_group.id, contactsData)))
        ) {
            if (transaction.status === TransactionStatus.PICKUP_IN_PROGRESS) {
                await checkIfTransactionInProgressIsStillValid(transaction);
                return true;
            }
            return transaction.status === TransactionStatus.READY_FOR_PICKUP;
        }
    });
};

interface LookupPickupUserBadgeByContactProps {
    contact?: Contact | null;
}

export const useLookupPickupUserBadgeByContact = (props: LookupPickupUserBadgeByContactProps) => {
    const { terminal } = useContext(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url, undefined, { enabled: !!props.contact });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [result, changeResult] = useState<Transaction[] | undefined>(undefined);

    const spotTransactionsResult = useTransactions({ spot: spot?.id });
    const { data: transactions } = spotTransactionsResult;

    const transactionsReceiverGroupResult = useTransactionsReceiverContactGroup(spotTransactionsResult.data);
    const contactGroupsContactsAllResult = useContactGroupsContactsAll(transactionsReceiverGroupResult.map((r) => r.data));

    const requiredDataFetched =
        !!transactions &&
        (spotTransactionsResult.isSuccess || spotTransactionsResult.isError) &&
        transactionsReceiverGroupResult.every((result) => {
            return result.isIdle || result.isSuccess || result.isError;
        }) &&
        contactGroupsContactsAllResult.every((result) => {
            return result.isIdle || result.isSuccess || result.isError;
        });

    useMemo((): void => {
        if (!props.contact || !transactions || !requiredDataFetched) return;
        findTransactionsByPickupContact(
            transactions,
            props.contact,
            contactGroupsContactsAllResult.map((item) => item.data)
        ).then((tr) => {
            if (tr && (result === undefined || !arraysEqual(tr, result))) {
                changeResult(tr);
            }
        });
    }, [props.contact, transactions, requiredDataFetched, contactGroupsContactsAllResult]);

    const LookupImpl = {
        lookupValue: props.contact,
        requiredDataFetched,
        result: result,
        refetchTransactions: () => undefined,
        isRefetching: false
    };

    return useBaseLookup(LookupImpl);
};

const useLookupPickupUserBadge = (props: LookupPickupUserBadgeProps) => {
    const mutateAuthenticateContact = useMutateAuthenticateContact();
    const { data: authenticatedContact, mutate: authenticate, isSuccess: authenticateIsSuccess, isError: authenticateIsError } = mutateAuthenticateContact;
    const foundTransactionsResult = useLookupPickupUserBadgeByContact({ contact: authenticatedContact });

    const [currentLookupValue, changeCurrentLookupValue] = useState<string | null>(null);

    useEffect(() => {
        if (props.lookupValue && props.lookupValue !== currentLookupValue) {
            changeCurrentLookupValue(props.lookupValue);
        }
    }, [currentLookupValue, props.lookupValue, changeCurrentLookupValue]);

    useEffect(() => {
        if (currentLookupValue) {
            authenticate({
                authenticateContact: {
                    key: currentLookupValue
                }
            });
        }
    }, [currentLookupValue, authenticate]);

    const requiredDataFetched =
        ((authenticateIsSuccess || authenticateIsError) && !authenticatedContact) || foundTransactionsResult.isSuccess || foundTransactionsResult.isError;

    const foundTransactions = foundTransactionsResult ? foundTransactionsResult.result : [];
    const LookupImpl = {
        lookupValue: props.lookupValue,
        requiredDataFetched,
        result: foundTransactions,
        refetchTransactions: () => undefined,
        isRefetching: false
    };

    return useBaseLookup(LookupImpl);
};

export default useLookupPickupUserBadge;
