import { ServiceWorkerInit } from '../../ServiceWorkerInit';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand } from '../useManagedWebsocket';

export class WebsocketServiceWorkerResetCommand extends WebsocketCommand {
    public async execute(sw: ServiceWorkerInit): Promise<WebsocketCommandResponse> {
        sw.serviceWorkerReset(false);
        window.location.href = '/';
        return { success: true };
    }
}
