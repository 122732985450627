import { useContext, useMemo, useState } from 'react';

import { TerminalContext } from '../../../TerminalInit';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import { Logger } from '../../../logs/Logger';
import { checkIfTransactionInProgressIsStillValid } from '../../../service-worker/TransactionInProgressDatabase';
import { arraysEqual } from '../../../services/global/arrays';
import { asyncTransactionFilter } from '../../../services/transaction/TransactionFilters';
import useBaseLookup from '../useBaseLookup';

interface LookupDropoffCodeProps {
    lookupValue: string | null;
}

const findTransactionsByDropoffCode = (transactions: Transaction[], code: string): Promise<Transaction[]> => {
    // For all customers right now, dropoff code is the last 4 characters of tt_number
    return asyncTransactionFilter(transactions, async (transaction: Transaction) => {
        if (transaction.status === TransactionStatus.DROPOFF_IN_PROGRESS) {
            if (
                (transaction.dropoff_code === '' && transaction.tt_number.length >= code.length && transaction.tt_number.slice(-code.length) === code) ||
                transaction.dropoff_code === code
            ) {
                try {
                    await checkIfTransactionInProgressIsStillValid(transaction);
                    return true;
                } catch (error) {
                    Logger.log('No transaction found so assuming something went wrong but the backend is still available. (not ideal)');
                    return true;
                }
            }
        } else if (transaction.dropoff_code === undefined || transaction.status !== TransactionStatus.READY_FOR_DROPOFF) {
            return false;
        } else if (transaction.dropoff_code === '') {
            return transaction.tt_number.length >= code.length && transaction.tt_number.slice(-code.length) === code;
        } else {
            return transaction.dropoff_code === code;
        }
    });
};

const useLookupDropoffCode = (props: LookupDropoffCodeProps) => {
    const { terminal } = useContext(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url, undefined, { enabled: props.lookupValue != null });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [result, changeResult] = useState<Transaction[] | undefined>(undefined);

    const spotTransactionsResult = useTransactions({ spot: spot?.id });
    const { data: transactions, refetch: refetchTransactions, isFetching: transactionsIsFetching } = spotTransactionsResult;

    const requiredDataFetched = !!transactions && (spotTransactionsResult.isSuccess || spotTransactionsResult.isError);

    useMemo((): void => {
        if (!props.lookupValue || !transactions || !requiredDataFetched) return;
        findTransactionsByDropoffCode(transactions, props.lookupValue).then((tr) => {
            if (tr && (result === undefined || !arraysEqual(tr, result))) {
                changeResult(tr);
            }
        });
    }, [props.lookupValue, transactions, requiredDataFetched]);

    const LookupImpl = {
        lookupValue: props.lookupValue,
        requiredDataFetched,
        result: result,
        refetchTransactions,
        isRefetching: transactionsIsFetching
    };

    return useBaseLookup(LookupImpl);
};

export default useLookupDropoffCode;
