import Connection from '../../Connection';
import { Logger } from '../../logs/Logger';
import { TerminalEvents } from '../terminal/Events';

export class WindowsEventListener {
    connection: Connection;

    constructor(connection: Connection) {
        this.connection = connection;
        chrome.webview.addEventListener('message', this.eventHandler);
    }

    private eventHandler = (event: any) => {
        Logger.debug(event);
        if (JSON.parse(event.data).scannerValue) {
            const newScannerValue = JSON.parse(event.data).scannerValue.trim();
            let newValue = '';
            for (let i = 0; i < newScannerValue.length; i++) {
                newValue += newScannerValue.charAt(i);
                if ((i + 1) % 2 === 0) {
                    newValue += ' ';
                }
            }
            window.dispatchEvent(new CustomEvent(TerminalEvents.SCANNER_RESULT, { detail: newValue }));
        } else if (JSON.parse(event.data).appVersion && JSON.parse(event.data).status) {
            if (JSON.parse(event.data).status === 'Initialised') {
                Logger.log('Received init response windows', {}, event.data);
                localStorage.setItem('windows', `${JSON.parse(event.data).appVersion != null ? JSON.parse(event.data).appVersion : 0}`);
            } else {
                Logger.log('something went wrong during initialisation, restarting the terminal');
                Logger.error(JSON.parse(event.data).status);
                this.connection.reset();
            }
        }
    };
}
