import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Attribute } from '../../../api/attribute';
import { Category, useCategories } from '../../../api/category';
import { Contact } from '../../../api/contacts';
import { Product } from '../../../api/products';
import { useShops } from '../../../api/shops';
import { Slot, useSlots } from '../../../api/slots';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { Transaction } from '../../../common/transactions';
import { getOldestTransaction } from '../../../services/transaction/TransactionFilters';
import Pickup from '../../Pickup';
import CategroyFilter from './CategoryFilter';
import ShopProductAttributeFilter from './ShopProductAttributesFilter';

interface ShopWorkflowProps {
    contact: Contact;
    onHome?: () => void;
    onInactivity?: () => void;
}

const ShopWorkflow = (props: ShopWorkflowProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: slots } = useSlots({ spot: spot?.id });
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: allCategories, isLoading: categoriesLoading } = useCategories(shops && shops.length > 0 ? { shop: shops[0].id } : undefined);

    const [categories, changeCategories] = useState<Category[] | undefined>(allCategories?.filter((c) => c.account === spot?.account));
    useEffect(() => {
        changeCategories(allCategories?.filter((c) => c.account === spot?.account));
    }, [allCategories, spot?.account]);

    const [product, changeProduct] = useState<Product | undefined>();
    const [products, changeProducts] = useState<Product[] | undefined>();
    const [attributes, changeAttributes] = useState<Attribute[] | undefined>();
    const [transaction, changeTransaction] = useState<Transaction | undefined>();
    const [slot, changeSlot] = useState<Slot | undefined>();
    const [parentCategoryHistory, changeParentCategoryHistory] = useState<Category[]>([]);

    const selectProducts = (products: Product[], attributes: Attribute[], parentHistory: Category[]) => {
        changeParentCategoryHistory(parentHistory.reverse());
        const uniqueAttributes: Attribute[] = [];
        attributes.forEach((attr) => {
            if (
                !uniqueAttributes.find((a: Attribute) => {
                    if (a.id === attr.id) {
                        return a;
                    }
                })
            )
                uniqueAttributes.push(attr);
        });
        changeAttributes(uniqueAttributes);
        changeProducts(products);
    };

    const onBack = () => {
        if (product !== undefined) {
            changeProduct(undefined);
            changeAttributes(undefined);
        } else if (products !== undefined) {
            changeProducts(undefined);
        }
    };

    const attributeFilterSubmit = (transactions: Transaction[]) => {
        if (!slots) return;
        const oldestTransaction = getOldestTransaction(transactions);

        changeTransaction(oldestTransaction);
        const slot = slots.find((slot) => slot.id === oldestTransaction.slot_id);
        if (slot) {
            changeSlot(slot);
        }
    };

    if (transaction && slot) {
        return (
            <Pickup
                slot={slot}
                transaction={transaction}
                receiver={props.contact}
                skipConfirm={true}
                onBack={() => {
                    changeTransaction(undefined);
                    changeSlot(undefined);
                }}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else if (products && attributes) {
        return (
            <ShopProductAttributeFilter
                submit={attributeFilterSubmit}
                products={products}
                attributes={attributes}
                onBack={onBack}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
                spotId={spot!.id}
            />
        );
    } else {
        return (
            <CategroyFilter
                parents={[]}
                parentHistory={parentCategoryHistory}
                spot={spot}
                allCategories={categories}
                categoriesLoading={categoriesLoading}
                selectProducts={selectProducts}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
                removeHistory={() => {
                    if (parentCategoryHistory.length !== 0) {
                        changeParentCategoryHistory([]);
                    }
                }}
            />
        );
    }
};

export default ShopWorkflow;
