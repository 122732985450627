import { useContext } from 'react';
import { ImExit } from 'react-icons/im';
import { useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { TerminalWorkflowType } from '../api/terminals';
import ActionCard from '../components/domain/ActionCard';
import { AllowedActionViewProps } from '../components/domain/AllowedAction';

export function AccessControllAction(props: AllowedActionViewProps): ActionCard | undefined {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const intl = useIntl();
    const onClick = () => {
        props.onInactivity && props.onInactivity();
    };

    if (props.contact && terminal?.workflow === TerminalWorkflowType.WAREHOUSE) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.warehouse.exit.action',
                description: 'This is the warehouse exit Action button that will open a door',
                defaultMessage: 'Exit'
            }),
            icon: (
                <ImExit
                    size={265}
                    className='dashboard-action-icon'
                />
            ),
            onAction: onClick
        };
    }
    return undefined;
}
