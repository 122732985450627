import { memo, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { TerminalWorkflowType } from '../../api/terminals';
import { useLookupPickupUserBadgeByContact } from '../../hooks/lookup/transactions/useLookupPickupUserBadge';
import LoadingView from '../../views/common/LoadingView';
import SelectContactView from '../../views/nike/SelectContactView';
import ShopWorkflow from '../vending/Shop/ShopWorkflow';
import { PickupWorkflow } from './PickupWorkflow';
import VendingWorkflow from './VendingWorkflow';
import { canPickupForOthers } from './permissions';

enum CollectViews {
    LOADING = 'loading',
    PICKUP = 'pickup',
    VENDING = 'vending'
}

interface CollectWorkflowProps {
    loggedInContact: Contact;
    onHome?: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
}

const CollectWorkflowFunction = (props: CollectWorkflowProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const [currentView, changeCurrentView] = useState(CollectViews.LOADING);
    const [pickupContact, changePickupContact] = useState<null | Contact>(canPickupForOthers(props.loggedInContact) ? null : props.loggedInContact);

    // Are there pudo items available for this contact? If yes, then show an overview of them.
    const transactionsPickupResult = useLookupPickupUserBadgeByContact({
        contact: pickupContact
    });

    const loadingDone = (transactionsPickupResult.isSuccess || transactionsPickupResult.isError) && transactionsPickupResult.result;

    useEffect(() => {
        if (currentView === CollectViews.LOADING && loadingDone) {
            const pickupsPending = transactionsPickupResult.isSuccess && transactionsPickupResult.result ? transactionsPickupResult.result.length : 0;
            if (pickupsPending > 0) {
                changeCurrentView(CollectViews.PICKUP);
            } else {
                changeCurrentView(CollectViews.VENDING);
            }
        }
    }, [currentView, loadingDone, transactionsPickupResult.isSuccess, transactionsPickupResult.result]);

    const onHome = props.onHome
        ? () => {
              changeCurrentView(CollectViews.LOADING);
              changePickupContact(null);
              props.onHome!();
          }
        : undefined;

    const onInactivity = props.onInactivity
        ? () => {
              changeCurrentView(CollectViews.LOADING);
              changePickupContact(null);
              props.onInactivity!();
          }
        : undefined;

    const onSelectContact = (contact: Contact) => {
        changePickupContact(contact);
    };

    if (!pickupContact) {
        return (
            <SelectContactView
                onSelectContact={onSelectContact}
                loggedInContact={props.loggedInContact}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else if (currentView === CollectViews.LOADING) {
        return (
            <LoadingView
                onHome={onHome}
                onInactivity={onInactivity}
                title={
                    <FormattedMessage
                        id='workflow.nike.CollectWorkflow.loadingTitle'
                        description='The title when we are checking for items to collect (pick up).'
                        defaultMessage='Searching available pickups'
                    />
                }
            />
        );
    } else if (currentView === CollectViews.PICKUP) {
        return (
            <PickupWorkflow
                transactions={transactionsPickupResult.result!}
                onHome={props.onLogout}
                onInactivity={onInactivity}
            />
        );
    } else if (currentView === CollectViews.VENDING) {
        if (terminal?.workflow === TerminalWorkflowType.VENDING) {
            return (
                <ShopWorkflow
                    contact={props.loggedInContact}
                    onHome={onHome}
                    onInactivity={onInactivity}
                />
            );
        }
        return (
            <VendingWorkflow
                loggedInContact={props.loggedInContact}
                surrogateContact={pickupContact}
                onHome={onHome}
                onInactivity={onInactivity}
                onLogout={props.onLogout}
            />
        );
    } else {
        throw new Error('Unexpected state in CollectWorkflow');
    }
};

export const CollectWorkflow = memo(CollectWorkflowFunction);
