import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../../nike/ViewTitle';

interface InfoDetailViewProps {
    onHome: () => void;
    onBack: () => void;
    onInactivity: () => void;
    title: React.ReactNode;
    content: React.ReactNode;
}

const InfoDetailView = (props: InfoDetailViewProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>{props.title}</ViewTitle>
            <p className='text-center'>{props.content}</p>
        </BaseView>
    );
};

export default InfoDetailView;
