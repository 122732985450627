import { useContext, useEffect, useState } from 'react';
import { IoCaretDown, IoCaretUp } from 'react-icons/io5';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { useSlots } from '../../api/slots';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { useTransaction } from '../../api/transactions';
import { Transaction, TransactionContact, TransactionStatus, getTransactionStatusName } from '../../common/transactions';
import useActivity from '../../hooks/useActivity';
import { AuthenticatedContact, isDelivery } from '../../services/contact/GlobalPermissions';
import TransactionProductsCardElement from '../nike/TransactionProductsCardElement';
import Loader from './Loader';
import TransactionTypePill from './transactions/TransactionTypePill';

interface TransactionInformationProps {
    contact?: AuthenticatedContact | Contact;
    transaction: Transaction;
    pickup?: () => void;
    cancelTransaction?: (transaction: Transaction) => void;
    changeTransactionSlot?: (transaction: Transaction) => void;
    removeTransaction?: (transaction: Transaction) => void;
    specificActionButton?: JSX.Element;
    selectedSlot?: number;
    showOnLoad?: boolean;
    color?: string;
}

const TransactionInformation = (props: TransactionInformationProps) => {
    const [, newActivity] = useActivity();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: slots } = useSlots({ spot: spot?.id });
    const [transaction, changeTransaction] = useState<Transaction | undefined>(undefined);
    const refetchedTransaction = useTransaction(undefined, props.transaction.id, transaction === undefined);
    const [senderInfo, changeSenderInfo] = useState<JSX.Element>(<></>);
    const [receiverInfo, changeReceiverInfo] = useState<JSX.Element>(<></>);
    const [show, changeShow] = useState<boolean>(props.showOnLoad ? props.showOnLoad : false);

    useEffect(() => {
        changeTransaction(refetchedTransaction);
    }, [refetchedTransaction]);

    const transactionInfo = transaction !== undefined ? transaction : props.transaction;
    const slot = slots?.find((s) => s.id === transactionInfo.slot_id);
    const isWaitingToBeMovedHere = transactionInfo.slot_reservation_aux !== null && transactionInfo.slot_id !== undefined && slot === undefined;

    const switchTransactionShow = (value: boolean) => {
        changeShow(value);
        newActivity();
    };

    const getNotificationChannel = (contact: TransactionContact) => {
        const result = (
            <>
                {contact.email && 'email'}
                {contact.mobile && contact.email && ' / '}
                {contact.mobile && 'SMS'}
            </>
        );
        return result;
    };

    useEffect(() => {
        transactionInfo.receiver
            ? changeReceiverInfo(
                  <dl className='row'>
                      <p className='col-sm-5'>
                          {transactionInfo.receiver.first_name} {transactionInfo.receiver.last_name}
                      </p>
                      <p className='col-sm-5'>{getNotificationChannel(transactionInfo.receiver)}</p>
                  </dl>
              )
            : transactionInfo.receiver_group
            ? changeReceiverInfo(
                  <dl className='row'>
                      <p className='col-sm-12'>{transactionInfo.receiver_group.name}</p>
                  </dl>
              )
            : changeReceiverInfo(
                  <dl className='row'>
                      <p className='col-sm-12'>unknown</p>
                  </dl>
              );

        transactionInfo.sender
            ? changeSenderInfo(
                  <dl className='row'>
                      <p className='col-sm-5'>
                          {transactionInfo.sender.first_name} {transactionInfo.sender.last_name}
                      </p>
                      <p className='col-sm-5'>{getNotificationChannel(transactionInfo.sender)}</p>
                  </dl>
              )
            : transactionInfo.sender_group
            ? changeSenderInfo(
                  <dl className='row'>
                      <p className='col-sm-12'>{transactionInfo.sender_group.name}</p>
                  </dl>
              )
            : changeSenderInfo(
                  <dl className='row'>
                      <p className='col-sm-12'>unknown</p>
                  </dl>
              );
    }, [transactionInfo]);

    const cancel = () => {
        newActivity();
        if (props.cancelTransaction) props.cancelTransaction(transactionInfo);
    };
    const changeSlot = () => {
        newActivity();
        if (props.changeTransactionSlot) props.changeTransactionSlot(transactionInfo);
    };
    const remove = () => {
        newActivity();
        if (props.removeTransaction) props.removeTransaction(transactionInfo);
    };

    return (
        <div className={props.color !== undefined ? props.color : 'information-card'}>
            <div>
                <h5
                    onClick={() => switchTransactionShow(!show)}
                    className='col-12 d-flex title justify-content-between mb-0'>
                    <span className='col-6 d-flex flex-column p-1'>
                        <p className='h5 text-truncate'>{transactionInfo.tt_number}</p>
                        <TransactionTypePill type={transactionInfo.type} />
                    </span>
                    <span className='col-6 d-flex justify-content-between'>
                        {props.pickup && (
                            <button
                                onClick={props.pickup}
                                className='w-50 success-button success-button-small ms-3 m-1'>
                                <FormattedMessage
                                    id='transaction.information.card.pickupButton'
                                    description='The message in the pickup button on the transaction information card.'
                                    defaultMessage='PickUp'
                                />
                            </button>
                        )}
                        <span>{show ? <IoCaretUp /> : <IoCaretDown />}</span>
                    </span>
                </h5>
                {show ? (
                    <div className='blue'>
                        <dl className='row'>
                            <dt className='text-center title col-sm-12'>
                                <h4>Information</h4>
                            </dt>
                        </dl>
                        <dl className='row'>
                            <dt className='h-6 col-sm-5'>Slot number</dt>
                            <p className='col-sm-7'>{transactionInfo.slot_nr}</p>
                            <dt className='h-6 col-sm-5'>Transaction ID</dt>
                            <p className='col-sm-7'>{transactionInfo.id}</p>
                            <dt className='h-6 col-sm-5'>Status</dt>
                            <p className='col-sm-7'>{getTransactionStatusName(transactionInfo.status, isWaitingToBeMovedHere)}</p>
                            <dt className='h-6 col-sm-5'>Created at</dt>
                            <p className='col-sm-7'>
                                {new Date(transactionInfo.created_date).toLocaleDateString()} {new Date(transactionInfo.created_date).toLocaleTimeString()}
                            </p>
                        </dl>
                    </div>
                ) : (
                    <></>
                )}
                {show ? <>{transactionInfo ? <TransactionProductsCardElement transaction={transactionInfo} /> : <Loader show={true} />}</> : <></>}
                {show && isDelivery(props.contact) && (
                    <div className='white'>
                        <dl className='row'>
                            <dt className='text-center title col-sm-12'>
                                <h4>Contact information</h4>
                            </dt>
                        </dl>
                        <dl className='row'>
                            <dt className='sub-title col-12'>Receiver</dt>
                        </dl>
                        {receiverInfo}

                        <dl className='row'>
                            <dt className='sub-title col-12'>Sender</dt>
                        </dl>
                        {senderInfo}
                    </div>
                )}
                {show && !isWaitingToBeMovedHere ? (
                    <>
                        {props.specificActionButton ? props.specificActionButton : <></>}
                        {props.cancelTransaction && transactionInfo.status !== TransactionStatus.CANCELLED ? (
                            <button
                                onClick={cancel}
                                className='warning-button'>
                                Cancel transaction
                            </button>
                        ) : (
                            <></>
                        )}
                        {props.changeTransactionSlot &&
                        (transactionInfo.status === TransactionStatus.READY_FOR_PICKUP || transactionInfo.status === TransactionStatus.READY_FOR_DROPOFF) ? (
                            <button
                                onClick={changeSlot}
                                className='warning-button'>
                                Move transaction
                            </button>
                        ) : (
                            <></>
                        )}
                        {props.removeTransaction && transactionInfo.status !== TransactionStatus.READY_FOR_DROPOFF ? (
                            <button
                                onClick={remove}
                                className='danger-button'>
                                Remove transaction
                            </button>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default TransactionInformation;
