import { useContext, useEffect, useState } from 'react';
import { MdOutlineHistory } from 'react-icons/md';
import { useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import ActionCard from '../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { AuthenticatedContactRoles, isGlobalContact } from '../../../services/contact/GlobalPermissions';
import { sortTransactionsByCreatedOnDate } from '../../../services/transaction/TransactionSort';
import LoadingView from '../../../views/common/LoadingView';
import TransactionList from '../../../views/elements/TransactionList';

type TransactionHistoryActionProps = AllowedActionViewProps;

const TransactionHistory = (props: TransactionHistoryActionProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: dropoffTransactions } = useTransactions({
        spot: spot?.id,
        status: TransactionStatus.READY_FOR_DROPOFF
    });
    const { data: pickupTransactions } = useTransactions({
        spot: spot?.id,
        status: TransactionStatus.READY_FOR_PICKUP
    });

    const [transactionMap, changeTransactionMap] = useState<Map<string, Transaction[]>>(new Map());
    useEffect(() => {
        if (pickupTransactions === undefined || dropoffTransactions === undefined) return;

        const newMap = new Map<string, Transaction[]>();
        newMap.set('Ready for pickup', sortTransactionsByCreatedOnDate(pickupTransactions).reverse());
        newMap.set('Ready for dropoff', sortTransactionsByCreatedOnDate(dropoffTransactions).reverse());
        changeTransactionMap(newMap);
    }, [dropoffTransactions, pickupTransactions]);

    if (dropoffTransactions === undefined || pickupTransactions === undefined) {
        return (
            <LoadingView
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    }

    return (
        <TransactionList
            {...props}
            transactionMap={transactionMap}
        />
    );
};

function TransactionHistoryAction(props: TransactionHistoryActionProps) {
    const intl = useIntl();
    if (!(isGlobalContact(props.contact) && props.contact?.additional_data.role === AuthenticatedContactRoles.NOODDORP_DELIVERY)) return undefined;

    const transactionHistory: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.DashboardWorkflow.TransactionHistory',
            description: 'This is the transaction history action on the for the delivery person',
            defaultMessage: 'Transaction history'
        }),
        icon: (
            <MdOutlineHistory
                className='dashboard-action-icon'
                size={264}
            />
        ),
        view: <TransactionHistory {...props} />
    };
    return transactionHistory;
}

export default TransactionHistoryAction;
