import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { useProducts } from '../../api/products';
import { useShops } from '../../api/shops';
import useActivity from '../../hooks/useActivity';
import ProductList, { ListInfo } from './ProductList';
import { SpotLayoutItemProductAmount } from './WarehouseWorkflow';

enum MoveDirection {
    ToOther = 'toOther',
    ToContact = 'toContact'
}

interface Props {
    snapshot: SpotLayoutItemProductAmount[];
    submit: (valid: SpotLayoutItemProductAmount[], other: SpotLayoutItemProductAmount[]) => void;
}

export default function ProductChanges(props: Props) {
    const [, newActivity] = useActivity();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);

    const [contactInstances, changeContactInstances] = useState<SpotLayoutItemProductAmount[]>([]);
    const [otherInstances, changeOtherInstances] = useState<SpotLayoutItemProductAmount[]>([]);

    useEffect(() => {
        const newContactInstances: SpotLayoutItemProductAmount[] = [];
        const newOtherInstances: SpotLayoutItemProductAmount[] = [];

        props.snapshot.forEach((value) => {
            let currentContactInst = contactInstances.find((i) => i.product.id === value.product.id);
            const currentOtherInst = otherInstances.find((i) => i.product.id === value.product.id);
            if (currentContactInst !== undefined && currentOtherInst !== undefined) {
                const currentTotal = currentContactInst.amount + currentOtherInst.amount;
                if (currentTotal < value.amount) {
                    currentContactInst.amount = value.amount - currentOtherInst.amount;
                } else if (currentTotal > value.amount) {
                    let diff = currentTotal - value.amount;
                    while (diff > 0 || (currentContactInst.amount !== 0 && currentOtherInst.amount !== 0)) {
                        if (currentOtherInst.amount > 0) {
                            currentOtherInst.amount--;
                        } else {
                            currentContactInst.amount--;
                        }
                        diff--;
                    }
                }
            } else {
                currentContactInst = value;
            }
            newContactInstances.push(currentContactInst);
            currentOtherInst !== undefined && newOtherInstances.push(currentOtherInst);
        });
        changeContactInstances(newContactInstances);
        changeOtherInstances(newOtherInstances);
    }, [props.snapshot]);

    const moveProduct = (prodId: number, direction: MoveDirection) => {
        newActivity();
        const newContactInstances = [...contactInstances];
        const newOtherInstances = [...otherInstances];

        const oldContact = newContactInstances.find((i) => i.product.id === prodId && i.amount > 0);
        const oldOther = newOtherInstances.find((i) => i.product.id === prodId && i.amount > 0);

        if (direction === MoveDirection.ToOther) {
            if (oldOther) {
                newOtherInstances.splice(newOtherInstances.indexOf(oldOther), 1);
                newOtherInstances.push({ product: oldOther.product, spotLayoutItem: oldOther.spotLayoutItem, amount: oldOther.amount + 1 });
            } else {
                newOtherInstances.push({ product: oldContact!.product, spotLayoutItem: oldContact!.spotLayoutItem, amount: 1 });
            }
            if (oldContact) {
                newContactInstances.splice(newContactInstances.indexOf(oldContact), 1);
                newContactInstances.push({ product: oldContact.product, spotLayoutItem: oldContact.spotLayoutItem, amount: oldContact.amount - 1 });
            }
        } else if (direction === MoveDirection.ToContact) {
            if (oldContact) {
                newContactInstances.splice(newContactInstances.indexOf(oldContact), 1);
                newContactInstances.push({ product: oldContact.product, spotLayoutItem: oldContact.spotLayoutItem, amount: oldContact.amount + 1 });
            } else {
                newContactInstances.push({ product: oldOther!.product, spotLayoutItem: oldOther!.spotLayoutItem, amount: 1 });
            }
            if (oldOther) {
                newOtherInstances.splice(newOtherInstances.indexOf(oldOther), 1);
                newOtherInstances.push({ product: oldOther.product, spotLayoutItem: oldOther.spotLayoutItem, amount: oldOther.amount - 1 });
            }
        }

        changeContactInstances(newContactInstances);
        changeOtherInstances(newOtherInstances);
    };

    const [contactListInfo, changeContactListInfo] = useState<ListInfo[]>([]);
    const [otherListInfo, changeOtherListInfo] = useState<ListInfo[]>([]);
    const [submitEnabled, changeSubmitEnabled] = useState<boolean>(true);
    useEffect(() => {
        changeSubmitEnabled(
            !(
                contactListInfo.some((o) => o.total < 0) ||
                otherListInfo.some((o) => o.total < 0) ||
                (contactListInfo.length === 0 && otherListInfo.length === 0)
            )
        );
    }, [contactListInfo, otherListInfo]);
    const submit = () => {
        newActivity();
        props.submit(contactInstances, otherInstances);
    };

    if (products === undefined) {
        return (
            <Spinner
                animation='border'
                role='status'
            />
        );
    }

    return (
        <div className='d-flex flex-column align-items-center w-100'>
            <h4 className='pt-2'>
                <FormattedMessage
                    id='ProductChanges.ContactListItems.title'
                    description='Title of the contact items list.'
                    defaultMessage='Your items'
                />
            </h4>
            {contactListInfo.length === 0 && (
                <p>
                    <FormattedMessage
                        id='ProductChanges.ContactListItems.EmptyMessage'
                        description='The message telling the user that its list of items is empty.'
                        defaultMessage='It seems like you did not pick up anything.'
                    />
                </p>
            )}
            <ProductList
                products={products}
                instances={contactInstances}
                update={(id) => moveProduct(id, MoveDirection.ToOther)}
                buttonContent={
                    <FormattedMessage
                        id='ProductChanges.ContactListItems.removeButton.label'
                        description='The button label for removing an item from the checkout list.'
                        defaultMessage='remove'
                    />
                }
                listInfoCallback={changeContactListInfo}
            />

            {otherListInfo.length !== 0 && (
                <div className='d-flex flex-column align-items-center text-center pt-2 w-100'>
                    <h4>
                        <FormattedMessage
                            id='ProductChanges.OtherListItems.title'
                            description='Title of the other items list.'
                            defaultMessage='Other items'
                        />
                    </h4>

                    <small>
                        <FormattedMessage
                            id='ProductChanges.OtherListItems.Info'
                            description='The message telling the user that the items in the other items list will not be linked to the user.'
                            defaultMessage='These items will not be tracked under your name.'
                        />
                    </small>
                </div>
            )}
            <ProductList
                products={products}
                instances={otherInstances}
                update={(id) => moveProduct(id, MoveDirection.ToContact)}
                buttonContent={
                    <FormattedMessage
                        id='ProductChanges.ContactListItems.addButton.label'
                        description='The button label for adding an item to the checkout list.'
                        defaultMessage='add'
                    />
                }
                listInfoCallback={changeOtherListInfo}
            />

            <button
                disabled={!submitEnabled}
                className='primary-button btn-lg my-2'
                onClick={submit}>
                <FormattedMessage
                    id='ProductChanges.ContactListItems.submitButton'
                    description='The submitButton on the warehouse workflow checkout page.'
                    defaultMessage='Submit'
                />
            </button>
        </div>
    );
}
