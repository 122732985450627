import { useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { GiStopSign } from 'react-icons/gi';
import { ImQrcode, ImRedo } from 'react-icons/im';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { ContactGroup } from '../../api/contactGroups';
import { Contact } from '../../api/contacts';
import { Product, useProducts } from '../../api/products';
import { useShops } from '../../api/shops';
import { Slot, SlotQueryStatus, useSlot, useSlots } from '../../api/slots';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { useMutateCreateTransaction } from '../../api/transactions';
import { Transaction, TransactionType } from '../../common/transactions';
import ActionCard from '../../components/domain/ActionCard';
import { LookupFlags } from '../../hooks/lookup/transactions/actions';
import usePerformLookup, { LookupType, ValueInputType } from '../../hooks/lookup/usePerformLookup';
import useActivity from '../../hooks/useActivity';
import usePinProtection from '../../hooks/usePinProtection';
import useScannerValue from '../../hooks/useScannerValue';
import { Logger } from '../../logs/Logger';
import { isGlobalContact } from '../../services/contact/GlobalPermissions';
import { getRandomString } from '../../utils';
import HomeView from '../../views/HomeView';
import LockoutView from '../../views/LockoutView';
import PinView from '../../views/PinView';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import ErrorView, { ErrorViewProps } from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import NoSlotsAvailableErrorView from '../../views/lending/NoSlotsAvailableErrorView';
import SessionChoiceView from '../../views/lending/SessionChoice';
import SlotTypeChoiceView from '../../views/lending/SlotTypeChoiceView';
import UserRegisteredView from '../../views/lending/UserRegisteredView';
import AuthenticatedContactWorkflow from '../AuthenticatedContact/AuthenticatedContactWorkflow';
import JustDrop from '../JustDrop';
import Pickup from '../Pickup';
import ScannerProcessingModal from '../ScannerProcessingModal';
import ConfirmLendingWorkflow from './ConfirmLendingWorkflow';
import LendingInfoWorkflow from './LendingInfoWorkflow';
import SelectSlotWorkflow from './SelectSlotWorkflow';

enum LendingViews {
    LOADING = 'loading',
    ERROR = 'error',
    GLOBAL_CONTACT_WORKFLOW = 'global_contact_workflow',
    //New lending flow
    NO_SLOTS_AVAILABLE = 'no_slots_available',
    SLOT_SELECT = 'slot_select',
    CONFIRM_LENDING = 'confirm_lending',
    USER_REGISTERED = 'user_registered',
    //Open my locker flow
    PICKUP = 'pickup',
    SLOT_OPEN = 'slot_open',
    PICKUP_SESSION_CHOICE = 'pickup_session_choice'
}

export interface LendingProductData {
    required_specifications: number[];
    type: string;
}

interface TransactionProcessing {
    transaction: Transaction;
}

interface StartDropoffOptions {
    transaction?: Transaction;
    tracking_number?: string;
    previousView?: LendingViews | ActionCard | null;
}

interface CurrentLookup {
    value: string;
    lookupFlags?: LookupFlags;
}

export enum UserSpecificType {
    NONE = 'none',
    BREE = 'bree'
}

export const isLendingProductData = (productData: unknown): productData is LendingProductData => {
    if (
        productData !== undefined &&
        productData !== null &&
        (productData as LendingProductData).type !== undefined &&
        (productData as LendingProductData).required_specifications !== undefined &&
        typeof (productData as LendingProductData).type == 'string'
    ) {
        if (Array.isArray((productData as LendingProductData).required_specifications)) {
            let result = true;
            (productData as LendingProductData).required_specifications.forEach((element) => {
                if (typeof element !== 'number') {
                    result = false;
                }
            });
            return result;
        } else return false;
    } else return false;
};

export default function LendingWorkflow() {
    const [showModal, changeShowModal] = useState<boolean>(false);
    const intl = useIntl();
    //TODO: Currently only supports one type at a time (might have to be an array in the future)
    const [userSpecificType, changeUserSpecificType] = useState<UserSpecificType>(UserSpecificType.BREE);
    const [transactionProcessing, changeTransactionProcessing] = useState<TransactionProcessing | undefined>(undefined);

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const productsResult = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: transactionProcessingSlot } = useSlot(transactionProcessing?.transaction.slot_id);
    const availableSlotsResult = useSlots({ spot: spot?.id, status: SlotQueryStatus.AVAILABLE, transaction_type: TransactionType.LENDING });
    const [, newActivity] = useActivity();
    const [, addPinAttempt] = usePinProtection();

    const [currentSlotType, changeCurrentSlotType] = useState<number[]>([0]);
    const [currentProduct, changeCurrentProduct] = useState<Product | undefined>(undefined);
    const [startDropoffOptions, changeStartDropoffOptions] = useState<StartDropoffOptions | null>(null);
    const [currentLookup, changeCurrentLookup] = useState<CurrentLookup | null>(null);
    const [locationAlreadySelected, changeLocationAlreadySelected] = useState<boolean>(false);
    const [contact, transaction, transactionType, loading, error, performLookup, resetLookup] = usePerformLookup();

    //badge registration
    const [currentContact, changeCurrentContact] = useState<Contact | null>(null);

    const [endSession, changeEndSession] = useState<boolean>(true);
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [errorViewProps, changeErrorViewProps] = useState<ErrorViewProps>({});

    const mutateCreateTransaction = useMutateCreateTransaction({
        onSuccess: (transaction) => {
            changeLocationAlreadySelected(true);
            changeSelectedAction(LendingViews.USER_REGISTERED);
        }
    });

    const [previeusAction, changePrevieusAction] = useState<ActionCard | LendingViews | null>(null);
    const [selectedAction, changeSelectedAction] = useState<ActionCard | LendingViews | null>(null);

    const { eBikeLockerAction, eLockerAction, helpAction, PinCodeAction, newSlotAction, continueSessionAction, endSessionAction, QRCodeAction } =
        useMemo(() => {
            return LendingActions(intl);
        }, [intl]);

    const availableSlots = availableSlotsResult.data ? availableSlotsResult.data : [];

    useEffect(() => {
        if (!loading) {
            if (error && !transaction && !contact) {
                Logger.error(error);
            }
            if (contact) {
                dismisModal();
                if (isGlobalContact(contact)) {
                    changeCurrentContact(contact);
                    showView(LendingViews.GLOBAL_CONTACT_WORKFLOW);
                }
            } else if (transaction) {
                dismisModal();
                if (transactionType === LookupType.PICKUP) {
                    changeTransactionProcessing({
                        transaction: transaction
                    });
                    if (transaction.type === TransactionType.LENDING) {
                        showView(LendingViews.PICKUP_SESSION_CHOICE);
                    } else {
                        showView(LendingViews.PICKUP);
                    }
                } else if (transactionType === LookupType.DROPOFF) {
                    startDropoff({
                        transaction: transaction
                    });
                }
            }
        } else {
            changeShowModal(true);
        }
    }, [loading, transaction, contact]);

    const showHome = useMemo(
        () => () => {
            newActivity();
            changeLocationAlreadySelected(false);
            changePrevieusAction(selectedAction);
            changeSelectedAction(null);
            resetLookup();
        },
        []
    );

    const showView = (view: LendingViews | ActionCard | null) => {
        Logger.log('Action selected', {}, view);
        newActivity();
        changePrevieusAction(selectedAction);
        changeSelectedAction(view);
    };

    const locationAvailabilityCheck = (productId: number | string) => {
        const product = productsResult.data?.find((p) => p.id === productId);

        changeCurrentProduct(product);
        if (isLendingProductData(product?.product_data)) {
            const slotType = product?.product_data.required_specifications ? product?.product_data.required_specifications : [0];
            changeCurrentSlotType(slotType);

            const filteredSlots = availableSlots.filter((slot) => {
                if (slotType.includes(slot.specification.id)) {
                    return slot;
                }
                return false;
            });

            if (filteredSlots.length === 0) {
                showView(LendingViews.NO_SLOTS_AVAILABLE);
            } else {
                showView(LendingViews.SLOT_SELECT);
            }
        }
    };

    const onSelectSlot = (newSlot: Slot | null) => {
        newActivity();
        changeSlot(newSlot);
        showView(LendingViews.CONFIRM_LENDING);
    };

    const confirmLending = (contact: Contact) => {
        Logger.log('confirm lending received now mutating transaction', { contact: contact.id }, contact);

        if (currentProduct !== undefined) {
            mutateCreateTransaction.mutate({
                createTransaction: {
                    account_id: spot!.account,
                    slot_id: slot!.id,
                    tt_number: getRandomString(20),
                    receiver_id: contact.id,
                    receiver_group_id: undefined,
                    sender_id: contact.id,
                    sender_group_id: undefined,
                    shipping_notes: 'none',
                    notifications_config: undefined,
                    product_instances: currentProduct ? [{ product: currentProduct.id }] : undefined,
                    product_instance_ids: undefined,
                    type: TransactionType.LENDING
                }
            });
            showView(LendingViews.LOADING);
        } else {
            const backHomeNav = <BackAndHomeNavigationButtons onHome={showHome} />;
            const title = (
                <FormattedMessage
                    id='views.Lending.CreateTransactionError'
                    description='This is the message displayed when there is an error with creating a transaction'
                    defaultMessage='An error occurred trying to create your session. Please try again later.'
                />
            );

            changeErrorViewProps({
                navbarItems: backHomeNav,
                onInactivity: showHome,
                title: title
            });
            showView(LendingViews.ERROR);
        }
    };

    const startDropoff = (options?: StartDropoffOptions) => {
        changeStartDropoffOptions({
            previousView: selectedAction,
            ...options
        });
        if (options?.transaction) {
            changeTransactionProcessing({ transaction: options.transaction });
        }
        showView(LendingViews.SLOT_OPEN);
    };
    const endDropoff = (back?: boolean) => {
        if (back && startDropoffOptions?.previousView) {
            showView(startDropoffOptions.previousView);
        } else {
            showHome();
        }
        changeStartDropoffOptions(null);
        changeTransactionProcessing(undefined);
    };

    const pinEntered = (pin: string) => {
        newActivity();
        Logger.log('Got pin ' + pin);
        changeCurrentLookup({ value: pin, lookupFlags: LookupFlags.PICKUP_CODE | LookupFlags.REMOVAL_CODE | LookupFlags.DROPOFF_CODE });
        performLookup({
            value: pin,
            valueInputType: ValueInputType.PIN
        });
        addPinAttempt(false);
    };

    const endPickup = useMemo(
        () => () => {
            showHome();
            changeTransactionProcessing(undefined);
        },
        []
    );

    useScannerValue((value) => {
        newActivity();
        Logger.log('New scanner value ' + value);
        resetLookup();
        changeCurrentLookup({
            value: value,
            lookupFlags:
                LookupFlags.DROPOFF_QR | LookupFlags.PICKUP_QR | LookupFlags.REMOVAL_QR | LookupFlags.DROPOFF_USER_BADGE | LookupFlags.PICKUP_USER_BADGE
        });
        performLookup({
            value: value,
            valueInputType: ValueInputType.SCANNER
        });
    });

    let content;
    switch (selectedAction) {
        case null:
            if (currentContact) {
                showView(LendingViews.GLOBAL_CONTACT_WORKFLOW);
            } else {
                content = (
                    <HomeView
                        onSelectAction={showView}
                        actions={[PinCodeAction, newSlotAction, helpAction, QRCodeAction]}
                    />
                );
            }
            break;
        case LendingViews.GLOBAL_CONTACT_WORKFLOW:
            if (currentContact) {
                content = (
                    <AuthenticatedContactWorkflow
                        onSelectAction={(action) => undefined}
                        onHome={() => {
                            changeCurrentContact(null);
                            showHome();
                        }}
                        onInactivity={() => {
                            changeCurrentContact(null);
                            showHome();
                        }}
                        contact={currentContact}
                    />
                );
            } else {
                showHome();
            }
            break;
        case helpAction:
            content = (
                <LendingInfoWorkflow
                    onBack={() => showView(previeusAction)}
                    onInactivity={showHome}
                    onHome={showHome}
                />
            );
            break;
        case newSlotAction:
            if (productsResult.data?.length === 1) {
                locationAvailabilityCheck(productsResult.data[0].id);
            }
            content = (
                <SlotTypeChoiceView
                    onHome={showHome}
                    onInactivity={showHome}
                    onBack={showHome}
                    products={productsResult.data}
                    slots={availableSlotsResult.data}
                    onSelect={(productId: number) => {
                        locationAvailabilityCheck(productId);
                    }}
                    actions={[eLockerAction, eBikeLockerAction, helpAction]}
                    onHelp={() => showView(helpAction)}
                />
            );
            break;
        case LendingViews.NO_SLOTS_AVAILABLE:
            content = (
                <NoSlotsAvailableErrorView
                    onHome={showHome}
                    onInactivity={showHome}
                />
            );
            break;
        case LendingViews.SLOT_SELECT:
            content = (
                <SelectSlotWorkflow
                    onInactivity={showHome}
                    onHome={showHome}
                    onBack={() => {
                        productsResult.data?.length === 1 ? showHome() : showView(newSlotAction);
                    }}
                    onSelect={onSelectSlot}
                    currentSlotType={currentSlotType}
                    transactionType={TransactionType.LENDING}
                />
            );
            break;
        case LendingViews.CONFIRM_LENDING:
            content = (
                <ConfirmLendingWorkflow
                    onBack={() => showView(LendingViews.SLOT_SELECT)}
                    onHome={showHome}
                    onInactivity={showHome}
                    onConfirm={confirmLending}
                    slotType={currentSlotType}
                    selectedSlot={slot}
                    customWorkflow={userSpecificType}
                    account_id={+spot!.account}
                />
            );
            break;
        case LendingViews.USER_REGISTERED:
            content = (
                <UserRegisteredView
                    onHome={showHome}
                    actions={[PinCodeAction, QRCodeAction]}
                    onSelectAction={showView}
                />
            );
            break;
        case PinCodeAction:
            content = (
                <PinView
                    onHome={showHome}
                    onBack={showHome}
                    onInactivity={showHome}
                    onSubmit={pinEntered}
                    lending={true}
                />
            );
            break;
        case LendingViews.PICKUP_SESSION_CHOICE:
            content = (
                <SessionChoiceView
                    onHome={showHome}
                    onBack={showHome}
                    onInactivity={showHome}
                    onSelectAction={showView}
                    actions={[endSessionAction, continueSessionAction]}
                />
            );
            break;
        case endSessionAction:
            changeEndSession(true);
            showView(LendingViews.PICKUP);
            break;
        case continueSessionAction:
            changeEndSession(false);
            showView(LendingViews.PICKUP);
            break;
        case LendingViews.PICKUP:
            if (transaction && transactionProcessingSlot) {
                content = (
                    <Pickup
                        onInactivity={endPickup}
                        onHome={endPickup}
                        transaction={transaction}
                        slot={transactionProcessingSlot}
                        endSession={endSession}
                        skipConfirm={true}
                    />
                );
            } else {
                content = (
                    <LoadingView
                        onInactivity={showHome}
                        onHome={showHome}
                        onBack={showHome}
                    />
                );
            }
            break;
        case LendingViews.SLOT_OPEN:
            if (transactionProcessingSlot === undefined) {
                content = (
                    <LoadingView
                        onInactivity={showHome}
                        onHome={showHome}
                        onBack={showHome}
                    />
                );
            } else {
                content = (
                    <JustDrop
                        onInactivity={endDropoff}
                        onHome={endDropoff}
                        onBack={() => {
                            endDropoff(true);
                        }}
                        receiverData={
                            transactionProcessing
                                ? {
                                      contact: transactionProcessing?.transaction.sender as Contact,
                                      contactGroup: transactionProcessing?.transaction.sender_group as ContactGroup
                                  }
                                : undefined
                        }
                        senderData={
                            transactionProcessing
                                ? {
                                      senderContact: transactionProcessing?.transaction.receiver as Contact,
                                      sender: transactionProcessing?.transaction.receiver_group as ContactGroup
                                  }
                                : undefined
                        }
                        slot={transactionProcessingSlot}
                        transaction={transactionProcessing?.transaction}
                        skipConfirm={locationAlreadySelected}
                        autoAssignSlot={false}
                    />
                );
            }
            break;
        case LendingViews.LOADING:
            content = (
                <LoadingView
                    onInactivity={showHome}
                    onHome={showHome}
                    onBack={showHome}
                />
            );
            break;
        case LendingViews.ERROR:
            content = (
                <ErrorView
                    navbarItems={errorViewProps.navbarItems}
                    onInactivity={errorViewProps.onInactivity}
                    title={errorViewProps.title}
                    message={errorViewProps.message}
                    hideErrorIcon={errorViewProps.hideErrorIcon}
                />
            );
            break;
    }

    const dismisModal = () => {
        changeShowModal(false);
    };

    return (
        <LockoutView>
            {content}
            <Modal
                centered
                size='sm'
                animation={false}
                show={showModal}
                onHide={resetLookup}>
                <Modal.Body>
                    <ScannerProcessingModal
                        isInProgress={loading}
                        currentLookup={currentLookup}
                        contactFound={contact != null}
                        transactionFound={transaction != null}
                        startDropoff={startDropoff}
                        resetLookup={resetLookup}
                        openDropoffEnabled={false}
                    />
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-center'>
                    <button
                        className='primary-button btn-lg'
                        onClick={dismisModal}>
                        <FormattedMessage
                            id='workflow.config.pudo.BarcodeNotRecognizedDismissButton'
                            description='The message shown on the button to dismiss the modal dialog that a scanned code is not valid.'
                            defaultMessage='Dismiss'
                        />
                    </button>
                </Modal.Footer>
            </Modal>
        </LockoutView>
    );
}

function LendingActions(intl: IntlShape) {
    const eBikeLockerAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.EBikeLockerAction',
            description: 'This is the use e-bike locker action on lending',
            defaultMessage: 'E-BIKE LOCKER'
        }),
        icon: <i className='bi bi-bicycle dashboard-action-icon' />
    };
    const eLockerAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.ELockerAction',
            description: 'This is the use e-locker action on lending',
            defaultMessage: 'E-LOCKER'
        }),
        icon: <i className='bi bi-safe dashboard-action-icon' />
    };
    const PinCodeAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.PinCodeAction',
            description: 'This is the PIN-code action on the lending homescreen',
            defaultMessage: 'I have a PIN-code'
        }),
        icon: <i className='bi bi-grid-3x3-gap dashboard-action-icon' />
    };
    const QRCodeAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.QRCodeAction',
            description: 'This is the QR-code action on the lending homescreen',
            defaultMessage: 'I have a QR-code'
        }),
        icon: (
            <ImQrcode
                className='dashboard-action-icon'
                size={264}
            />
        )
    };
    const newSlotAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.newSLOTAction',
            description: 'This is the new SLOT action on the lending homescreen',
            defaultMessage: 'Use a new SLOT'
        }),
        icon: <i className='bi bi-box-arrow-in-down dashboard-action-icon' />
    };
    const continueSessionAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.continueSLOTSessionAction',
            description: 'This is the continue session action in the lending pickup flow',
            defaultMessage: 'Open door and CONTINUE using the SLOT'
        }),
        icon: (
            <ImRedo
                className='dashboard-action-icon'
                size={264}
            />
        )
    };
    const endSessionAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.endSLOTSessionAction',
            description: 'This is the end session action in the lending pickup flow',
            defaultMessage: 'Open door en STOP using the SLOT'
        }),
        icon: (
            <GiStopSign
                className='dashboard-action-icon'
                size={264}
            />
        )
    };
    const helpAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.NeedHelpAction',
            description: 'This is the help action on the lending homescreen',
            defaultMessage: 'Need help?'
        }),
        icon: <i className='bi bi-question-circle dashboard-action-icon' />
    };

    return {
        eBikeLockerAction,
        eLockerAction,
        helpAction,
        PinCodeAction,
        newSlotAction,
        continueSessionAction,
        endSessionAction,
        QRCodeAction
    };
}
