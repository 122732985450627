import { FormattedMessage } from 'react-intl';

import { Contact } from '../../../api/contacts';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';
import EmployeeCard from './EmployeeCard';

interface BadgeRegistrationConfirmationViewProps {
    employee: Contact;
    onConfirm: () => void;
    onCancel: () => void;
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;
}

export default function BadgeRegistrationConfirmationView(props: BadgeRegistrationConfirmationViewProps) {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationConfirmationView.title'
                    description='The title on the view that asks the user to confirm registering his badge.'
                    defaultMessage='Confirm badge registration'
                />
            </ViewTitle>
            <p>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationConfirmationView.message'
                    description='The message on the view that asks the user to confirm registering his badge.'
                    defaultMessage='You are about to register your badge for the following person. This action cannot be undone. {cancelMessage}'
                    values={{
                        cancelMessage: (
                            <span className='font-weight-bold'>
                                <FormattedMessage
                                    id='views.nike.registration.BadgeRegistrationConfirmationView.cancelMessage'
                                    description='The additional cancel message on the view that asks the user to confirm registering his badge.'
                                    defaultMessage='If this is not you, cancel now!'
                                />
                            </span>
                        )
                    }}
                />
            </p>
            <EmployeeCard
                employee={props.employee}
                className='mb-4'
            />
            <button
                className='primary-button mb-4'
                onClick={props.onConfirm}>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationConfirmationView.registerCallToAction'
                    description='The call to action to register on the view that asks the user to confirm registering his badge.'
                    defaultMessage='This is me. Register my badge.'
                />
            </button>
            <button
                className='light-button'
                onClick={props.onCancel}>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationConfirmationView.cancelCallToAction'
                    description='The call to action to cancel on the view that asks the user to confirm registering his badge.'
                    defaultMessage='Cancel and go back'
                />
            </button>
        </BaseView>
    );
}
