/**
 * The arrays need to be exactly the same (including the order of the content)
 */
export function arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

/**
 * This function split the given array into 2 arrays one containing the elements that pass the isValid function and one containing the ones that fail.
 * @param array Array that will be split.
 * @param isValid Function that checks if the element in the array should pass or not.
 * @returns [passed[], failed[]]
 */
export function partition(array: any[], isValid: { (s: any): boolean; (arg0: any): any }): [any[], any[]] {
    return array.reduce(
        ([pass, fail], elem) => {
            return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
        },
        [[], []]
    );
}
