import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../../../TerminalInit';
import { SlotQueryStatus, useSlotsGlobal } from '../../../../api/slots';
import { SpotLayoutItem, useMultipleSpotLayoutItems } from '../../../../api/spotLayoutItems';
import { SpotLayout, useSpotLayout, useSpotLayouts } from '../../../../api/spotLayouts';
import { Spot, useSpots } from '../../../../api/spots';
import { Transaction } from '../../../../common/transactions';
import useActivity from '../../../../hooks/useActivity';
import { Logger } from '../../../../logs/Logger';
import BackAndHomeNavigationButtons from '../../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../../views/common/BaseView';
import LoadingView from '../../../../views/common/LoadingView';
import SpotLayoutView from '../../../../views/elements/SpotLayout';

interface Props {
    transaction: Transaction;
    onSubmit: (spotId: string, slotId?: string) => void;
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;
}

export default function SelectNewSlot(props: Props) {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const [, newActivity] = useActivity();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spots } = useSpots();
    const { data: layouts } = useSpotLayouts();
    const [thisSpotLayout, changeThisSpotLayout] = useState<SpotLayout | undefined>(undefined);
    const { data: items } = useMultipleSpotLayoutItems({ spotLayout: thisSpotLayout ? thisSpotLayout : undefined }, undefined, undefined, !!thisSpotLayout);
    const [selectedSpot, changeSelectedSpot] = useState<Spot | undefined>(undefined);
    const [spotLayoutItem, changeSpotLayoutItem] = useState<SpotLayoutItem | undefined>(undefined);
    const availableSlotsResult = useSlotsGlobal(
        {
            spot: selectedSpot?.id,
            transaction_type: props.transaction.type,
            status: SlotQueryStatus.AVAILABLE,
            receiver: props.transaction.receiver && props.transaction.receiver.id.toString(),
            receiver_group: props.transaction.receiver_group && props.transaction.receiver_group.id.toString()
        },
        undefined,
        undefined,
        !!selectedSpot
    );
    const [availableItems, changeAvailableItems] = useState<SpotLayoutItem[] | undefined>(undefined);

    const onSelectSpot = (spotId?: number) => {
        newActivity();
        changeSelectedSpot(spots!.find((s) => +s.id.replace('SPT', '') === spotId));
        changeSpotLayoutItem(undefined);
    };

    useEffect(() => {
        if (spotLayout && spots) onSelectSpot(+spotLayout.spot);
    }, [spotLayout, spots]);

    useEffect(() => {
        const slots = availableSlotsResult.data;
        if (slots === undefined || items === undefined) changeAvailableItems(undefined);
        else {
            changeAvailableItems(
                items.filter((sli) => {
                    return slots.find((slot) => {
                        return sli.slot === slot.id;
                    });
                })
            );
        }
    }, [availableSlotsResult.data]);

    useEffect(() => {
        if (spots && spots.length === 1) {
            newActivity();
            changeSelectedSpot(spots[0]);
        }
    }, [spots]);
    useEffect(() => {
        if (selectedSpot && layouts && layouts.length > 0) {
            newActivity();
            const foundLayout = layouts.find((l) => l.spot === +selectedSpot.id.replace('SPT', ''));
            if (foundLayout) changeThisSpotLayout(foundLayout);
            else Logger.error('No corresponding layout found for this spot!', { spot: selectedSpot.id }, selectedSpot);
        }
    }, [layouts, selectedSpot]);

    const submit = (e: any) => {
        newActivity();
        e.preventDefault();
        props.onSubmit(selectedSpot!.id, spotLayoutItem?.slot);
    };

    if (spots === undefined || thisSpotLayout === undefined) {
        return (
            <LoadingView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <h3 className='mt-4 text-center'>Move transaction</h3>

            <select
                onChange={(e) => onSelectSpot(+e.target.value)}
                className='form-control mb-3'
                value={thisSpotLayout?.spot}>
                <option value={undefined}></option>
                {spots.map((s) => {
                    return (
                        <option
                            key={s.id}
                            value={+s.id.replace('SPT', '')}>
                            {s.name}
                        </option>
                    );
                })}
            </select>
            {thisSpotLayout !== undefined && availableItems !== undefined && (
                <SpotLayoutView
                    enableZoom
                    highlightedItems={spotLayoutItem ? [spotLayoutItem] : availableItems}
                    selectableItems={availableItems}
                    onSelect={changeSpotLayoutItem}
                    spotLayoutItemsOverwrite={items}
                />
            )}
            <div className='d-flex justify-content-center my-3'>
                <button
                    onClick={submit}
                    className='btn btn-primary w-25 text-white'
                    disabled={selectedSpot === undefined}>
                    Submit
                </button>
            </div>
        </BaseView>
    );
}
