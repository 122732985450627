import Spinner from 'react-bootstrap/Spinner';

function InitializingApp(props: { initTerminal: string; initServiceWorker: string }) {
    return (
        <div className='text-center flex-grow-1 mt-2'>
            <h1>Initializing App</h1>
            <p>Terminal</p>
            <p>{props.initTerminal}</p>
            <p>ServiceWorker</p>
            <p>{props.initServiceWorker}</p>
            <Spinner
                animation='border'
                role='status'
            />
        </div>
    );
}

export default InitializingApp;
