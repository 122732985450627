import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact, useMutateContactAddBadge } from '../../../api/contacts';
import { isApiError, isApiErrorNonFieldErrors } from '../../../api/utils';
import useActivity from '../../../hooks/useActivity';
import { normalizeNfcValue } from '../../../utils';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ErrorView from '../../../views/common/ErrorView';
import LoadingView from '../../../views/common/LoadingView';
import ViewTitle from '../../../views/nike/ViewTitle';

interface ContactAddBadgeWorkflowProps {
    badgeValue: string;
    employee: Contact;
    onHome?: () => void;
    onInactivity?: () => void;
    overwriteIsEnabled?: boolean;
}

export default function ContactAddBadgeWorkflow(props: ContactAddBadgeWorkflowProps) {
    const mutateContactAddBadge = useMutateContactAddBadge();
    const [, newActivity] = useActivity();

    useEffect(() => {
        newActivity();
        mutateContactAddBadge.mutate({
            contact: props.employee,
            contactAddBadge: {
                badge_key: normalizeNfcValue(props.badgeValue),
                delete_old_keys: props.overwriteIsEnabled
            }
        });

        return () => {
            mutateContactAddBadge.reset();
        };
    }, []);

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;
    if (mutateContactAddBadge.isError) {
        if (isApiError(mutateContactAddBadge.error) && isApiErrorNonFieldErrors(mutateContactAddBadge.error.json)) {
            if (mutateContactAddBadge.error.json.non_field_errors.includes('Contact already has an associated badge')) {
                return (
                    <ErrorView
                        onInactivity={props.onInactivity}
                        navbarItems={homeNav}
                        title={
                            <FormattedMessage
                                id='workflow.nike.registration.ContactAddBadgeWorkflow.errorTitleOtherBadgeAssigned'
                                description='The title on the error view when the contact already has another badge assigned on the add badge workflow'
                                defaultMessage='Your badge could not get registered'
                            />
                        }
                        message={
                            <FormattedMessage
                                id='workflow.nike.registration.ContactAddBadgeWorkflow.errorMessageOtherBadgeAssigned'
                                description='The message on the error view when the contact already has another badge assigned on the add badge workflow'
                                defaultMessage='This person already has another registered badge. You cannot add a second badge using a DistriSPOT. If you think this is a mistake, please contact your coach.'
                            />
                        }
                    />
                );
            } else if (mutateContactAddBadge.error.json.non_field_errors.includes('That badge is already in use by somebody else')) {
                return (
                    <ErrorView
                        onInactivity={props.onInactivity}
                        navbarItems={homeNav}
                        title={
                            <FormattedMessage
                                id='workflow.nike.registration.ContactAddBadgeWorkflow.errorTitleBadgeInUse'
                                description='The title on the error view when the badge is already in use by somebody else on the add badge workflow'
                                defaultMessage='Your badge could not get registered'
                            />
                        }
                        message={
                            <FormattedMessage
                                id='workflow.nike.registration.ContactAddBadgeWorkflow.errorMessageBadgeInUse'
                                description='The message on the error view when the badge is already in use by somebody else on the add badge workflow'
                                defaultMessage='This badge is already registered to somebody else. If you think this is a mistake, please contact your coach.'
                            />
                        }
                    />
                );
            } else {
                return (
                    <ErrorView
                        onInactivity={props.onInactivity}
                        navbarItems={homeNav}
                        title={
                            <FormattedMessage
                                id='workflow.nike.registration.ContactAddBadgeWorkflow.errorTitleNoRecognizedDetails'
                                description='The title on the error view when the badge could not be registered but no recognized error message was returned on the add badge workflow'
                                defaultMessage='Your badge could not get registered'
                            />
                        }
                        message={
                            <FormattedMessage
                                id='workflow.nike.registration.ContactAddBadgeWorkflow.errorMessageNoRecognizedDetails'
                                description='The message on the error view when the badge could not be registered but no recognized error message was returned on the add badge workflow'
                                defaultMessage='Please try again or contact your coach if the error persists. These are the additional error details: {errorDetails}'
                                values={{
                                    errorDetails: mutateContactAddBadge.error.json.non_field_errors.join(', ')
                                }}
                            />
                        }
                    />
                );
            }
        }
        return (
            <ErrorView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
                title={
                    <FormattedMessage
                        id='workflow.nike.registration.ContactAddBadgeWorkflow.errorTitleNoDetails'
                        description='The title on the error view when the badge could not be registered but no error message was returned on the add badge workflow'
                        defaultMessage='Your badge could not get registered'
                    />
                }
                message={
                    <FormattedMessage
                        id='workflow.nike.registration.ContactAddBadgeWorkflow.errorMessageNoDetails'
                        description='The message on the error view when the badge could not be registered but no error message was returned on the add badge workflow'
                        defaultMessage='Please try again or contact your coach if the error persists.'
                    />
                }
            />
        );
    } else if (mutateContactAddBadge.isSuccess) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}>
                <ViewTitle>
                    <FormattedMessage
                        id='workflow.nike.registration.ContactAddBadgeWorkflow.titleSuccess'
                        description='The title on the success screen when the badge was registered on the add badge workflow'
                        defaultMessage='Badge registered successfully'
                    />
                </ViewTitle>
                <p className='mb-4 text-center'>
                    <FormattedMessage
                        id='workflow.nike.registration.ContactAddBadgeWorkflow.messageSuccess'
                        description='The message on the success screen when the badge was registered on the add badge workflow'
                        defaultMessage='You can now start using the DistriSPOT.'
                    />
                </p>
                <button
                    className='primary-button'
                    onClick={props.onHome}>
                    <FormattedMessage
                        id='workflow.nike.registration.ContactAddBadgeWorkflow.callToActionSuccess'
                        description='The call to action to go back home on the success screen when the badge was registered on the add badge workflow'
                        defaultMessage='Use DistriSPOT'
                    />
                </button>
            </BaseView>
        );
    }
    return (
        <LoadingView
            onInactivity={props.onInactivity}
            title={
                <FormattedMessage
                    id='workflow.nike.registration.ContactAddBadgeWorkflow.addingInProgressTitle'
                    description='The title on the loading screen when the badge is being registered on the add badge workflow'
                    defaultMessage='Registering badge. Please wait...'
                />
            }
        />
    );
}
