import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Transaction } from '../../common/transactions';
import TransactionProductsCardElement from './TransactionProductsCardElement';
import ViewTitle from './ViewTitle';

interface ReturnsInDistrispotSelectProps {
    transactions: Transaction[];
    onOpen: (transaction: Transaction) => void;
}

export function ReturnsInDistrispotSelect(props: ReturnsInDistrispotSelectProps) {
    return (
        <>
            <ViewTitle>
                <FormattedMessage
                    id='workflow.nike.CheckReturnsWorkflow.titleItemsReturned'
                    description='This title is shown when items have been found that were returned'
                    defaultMessage='{transactionsCount, plural, one {The following item has been returned} other {The following items have been returned}}'
                    values={{
                        transactionsCount: props.transactions.length
                    }}
                />
            </ViewTitle>
            {props.transactions.map((transaction) => {
                return (
                    <div
                        key={`item-${transaction.id}`}
                        className='align-self-stretch'>
                        <TransactionProductsCardElement
                            transaction={transaction}
                            onOpen={() => {
                                props.onOpen(transaction);
                            }}
                        />
                    </div>
                );
            })}
        </>
    );
}
