import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import flagFr from 'svg-country-flags/svg/fr.svg';
import flagEn from 'svg-country-flags/svg/gb.svg';
import flagNl from 'svg-country-flags/svg/nl.svg';

import { Locale } from '../../App';
import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';

interface LanguageSelectButtonProps {
    flag?: any;
    name: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function LanguageSelectButton(props: LanguageSelectButtonProps) {
    const style = { width: '6rem' };
    return (
        <button onClick={props.onClick}>
            {props.flag ? (
                <img
                    className='flag'
                    alt={`flag ${props.name}`}
                    src={props.flag}
                    style={style}
                />
            ) : (
                <></>
            )}
            <span className='sub-title'>{props.name}</span>
        </button>
    );
}

const LanguagePicker = () => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { currentLocale, changeCurrentLocale } = useContext<TerminalContextInterface>(TerminalContext);
    const currentLocaleDefault = currentLocale ? currentLocale : Locale.ENGLISH;
    const imgStyling = { width: '2rem' };

    useEffect(() => {
        if (spot?.settings_language && spot?.settings_language !== currentLocale) changeCurrentLocale(spot?.settings_language);
    }, [spot]);

    const [showModal, changeShowModal] = useState(false);

    let countryFlag;
    let countryAbr: string;
    if (currentLocaleDefault === Locale.ENGLISH) {
        countryFlag = (
            <img
                alt='flag EN'
                src={flagEn}
                style={imgStyling}
            />
        );
        countryAbr = 'EN';
    } else if (currentLocaleDefault === Locale.DUTCH) {
        countryFlag = (
            <img
                alt='flag NL'
                src={flagNl}
                style={imgStyling}
            />
        );
        countryAbr = 'NL';
    } else if (currentLocaleDefault === Locale.FRENCH) {
        countryFlag = (
            <img
                alt='flag FR'
                src={flagFr}
                style={imgStyling}
            />
        );
        countryAbr = 'FR';
    } else {
        countryFlag = <div />;
        countryAbr = '?';
    }

    const onLocaleChange = (locale: Locale) => {
        changeCurrentLocale(locale);
        changeShowModal(false);
    };

    return (
        <>
            <button
                className='navbar-button'
                onClick={() => {
                    changeShowModal(true);
                }}>
                {countryFlag}
                <span className='px-1 text-primary'>{countryAbr}</span>
                <i className='text-primary small bi bi-caret-down-fill' />
            </button>
            <Modal
                size='xl'
                centered={true}
                show={showModal}
                onHide={() => {
                    changeShowModal(false);
                }}
                className='modal'>
                <div className='modal-content'>
                    <Modal.Header closeButton={true}>
                        <h3 className='title'>
                            <FormattedMessage
                                id='views.elements.LanguagePicker.modalTitle'
                                description='The title that is shown in the Language Picker modal.'
                                defaultMessage='Select language'
                            />
                        </h3>
                    </Modal.Header>
                    <Modal.Body className='language-picker'>
                        <LanguageSelectButton
                            name='English'
                            flag={flagEn}
                            onClick={() => {
                                onLocaleChange(Locale.ENGLISH);
                            }}
                        />
                        <LanguageSelectButton
                            name='Nederlands'
                            flag={flagNl}
                            onClick={() => {
                                onLocaleChange(Locale.DUTCH);
                            }}
                        />
                        <LanguageSelectButton
                            name='Français'
                            flag={flagFr}
                            onClick={() => {
                                onLocaleChange(Locale.FRENCH);
                            }}
                        />
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
};

export default LanguagePicker;
