import _ from 'lodash';

import { Terminal } from '../../api/terminals';
import { Logger } from '../../logs/Logger';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandResponseStatus } from '../useManagedWebsocket';

export class WebsocketRequestLogsCommand extends WebsocketCommand {
    public async execute(terminal: Terminal | null, websocket: WebSocket): Promise<WebsocketCommandResponse> {
        Logger.getLogs().then((logs) => {
            _.chunk(logs, 100).forEach((cl) => {
                websocket?.send(JSON.stringify({ message: cl, status: WebsocketCommandResponseStatus.SUCCESS, command: this.type, terminal: terminal }));
            });
            logs && Logger.removeById(logs.map((l) => l.raw_data.id));
        });
        return { success: true };
    }
}
