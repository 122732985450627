import { useMemo } from 'react';

import { useSlots } from '../api/slots';
import { useTransactions } from '../api/transactions';
import { TransactionStatus } from '../common/transactions';
import { transformSlotsToIdMap, transformTransactionsToSlotMap } from '../workflow/nike/utils';

interface UseTransactionsWithSlotsProps {
    spotId?: string | null;
    status?: TransactionStatus[] | undefined;
    cutoffTimeDeltaMs?: number | undefined;
    excludeScheduledTransactions?: boolean;
}

function useTransactionsWithSlots(props: UseTransactionsWithSlotsProps) {
    const slotsResult = useSlots({ spot: props.spotId });

    const created_date_after = new Date();
    if (props.cutoffTimeDeltaMs) {
        created_date_after.setMilliseconds(created_date_after.getMilliseconds() - props.cutoffTimeDeltaMs);
        created_date_after.setHours(0);
        created_date_after.setMinutes(0);
        created_date_after.setSeconds(0);
        created_date_after.setMilliseconds(0);
    }

    const transactionsResult = useTransactions(
        {
            spot: props.spotId,
            status: props.status ? props.status : undefined,
            created_date_after: props.cutoffTimeDeltaMs ? created_date_after.toISOString() : undefined
        },
        { enabled: true }
    );

    // Reduce slotResults to map of slotId -> slot
    const slotsIdMap = useMemo(() => {
        if (!slotsResult.isSuccess || !slotsResult.data) {
            return undefined;
        }
        return transformSlotsToIdMap(slotsResult.data);
    }, [slotsResult.data, slotsResult.isSuccess]);

    // Transform slotResults to Map of slotId: SlotForTransaction
    const slotsMap = useMemo(() => {
        if (!slotsIdMap || !transactionsResult.isSuccess || !transactionsResult.data) {
            return undefined;
        }
        return transformTransactionsToSlotMap(slotsIdMap, transactionsResult.data, props.excludeScheduledTransactions);
    }, [slotsIdMap, transactionsResult.isSuccess, transactionsResult.data]);

    const isSuccess = slotsResult.isSuccess && transactionsResult.isSuccess;
    return {
        isSuccess: isSuccess,
        isError: slotsResult.isError || transactionsResult.isError,
        data: isSuccess ? slotsMap : undefined
    };
}

export default useTransactionsWithSlots;
