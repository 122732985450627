import { FormattedMessage } from 'react-intl';

import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../../nike/ViewTitle';
import HelpAction from '../../nike/help/HelpAction';

interface InfoViewProps {
    onHome: () => void;
    onInactivity: () => void;
    onInfo: (title: React.ReactNode, content: React.ReactNode) => void;
    onBack: () => void;
}

const InfoView = (props: InfoViewProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.lending.info.InfoOverview.title'
                    description='The title on the info overview.'
                    defaultMessage='Help'
                />
            </ViewTitle>

            <div className='row'>
                <div className='col'>
                    <HelpAction
                        title={
                            <FormattedMessage
                                id='views.lending.info.InfoViewTitle.UseSLOT'
                                description='The title on the info view of UseSLOT.'
                                defaultMessage='Use SLOT'
                            />
                        }
                        subtext={
                            <FormattedMessage
                                id='views.lending.info.InfoDetailViewSubtext.UseSLOT'
                                description='The subtext on the info view of UseSLOT.'
                                defaultMessage='How to use a new SLOT?'
                            />
                        }
                        onClick={() =>
                            props.onInfo(
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailViewSubtext.UseSLOT'
                                    description='The subtext on the info view of UseSLOT.'
                                    defaultMessage='How to use a new SLOT?'
                                />,
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailView.info.UseSLOT'
                                    description='The description on the info detailview of UseSLOT.'
                                    defaultMessage='Select the SLOT you want to use and confirm it. Fill in your personal information and confirm again. You will now recieve a Email or SMS with your PIN/QR-code. Use this to open the selected SLOT.'
                                />
                            )
                        }
                    />
                </div>
                <div className='col'>
                    <HelpAction
                        title={
                            <FormattedMessage
                                id='views.lending.info.InfoViewTitle.Usescanner'
                                description='The title on the info view of Usescanner.'
                                defaultMessage='Use scanner'
                            />
                        }
                        subtext={
                            <FormattedMessage
                                id='views.lending.info.InfoDetailViewSubtext.Usescanner'
                                description='The subtext on the info view of Usescanner.'
                                defaultMessage='How to use the scanner?'
                            />
                        }
                        onClick={() =>
                            props.onInfo(
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailViewSubtext.Usescanner'
                                    description='The subtext on the info view of Usescanner.'
                                    defaultMessage='How to use the scanner?'
                                />,
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailView.info.Usescanner'
                                    description='The description on the info detailview of Usescanner.'
                                    defaultMessage='The QR-code scanner will be located below or next to the screen. Hold your QR-code in front of it and the terminal will read it for you.'
                                />
                            )
                        }
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <HelpAction
                        title={
                            <FormattedMessage
                                id='views.lending.info.InfoViewTitle.E-BikeLocker'
                                description='The title on the info view of E-Bike Locker.'
                                defaultMessage='E-Bike Locker'
                            />
                        }
                        subtext={
                            <FormattedMessage
                                id='views.lending.info.InfoDetailViewSubtext.E-BikeLocker'
                                description='The subtext on the info view of E-BikeLocker.'
                                defaultMessage='What is a E-Bike locker?'
                            />
                        }
                        onClick={() =>
                            props.onInfo(
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailViewSubtext.E-BikeLocker'
                                    description='The subtext on the info view of E-BikeLocker.'
                                    defaultMessage='What is a E-Bike locker?'
                                />,
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailView.info.E-BikeLocker'
                                    description='The description on the info detailview of E-BikeLocker.'
                                    defaultMessage='An E-BIKE LOCKER is a smart and secure SLOT where you can park and lock your e-bike, safely. Charge your battery and store all your belongings in a handy locker with mirror and hooks.'
                                />
                            )
                        }
                    />
                </div>
                <div className='col'>
                    <HelpAction
                        title={
                            <FormattedMessage
                                id='views.lending.info.InfoViewTitle.E-Locker'
                                description='The title on the info view of E-Locker.'
                                defaultMessage='E-Locker'
                            />
                        }
                        subtext={
                            <FormattedMessage
                                id='views.lending.info.InfoDetailViewSubtext.E-Locker'
                                description='The subtext on the info view of E-Locker.'
                                defaultMessage='What is a E-locker?'
                            />
                        }
                        onClick={() =>
                            props.onInfo(
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailViewSubtext.E-Locker'
                                    description='The subtext on the info view of E-Locker.'
                                    defaultMessage='What is a E-locker?'
                                />,
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailView.info.E-Locker'
                                    description='The description on the info detailview of E-Locker.'
                                    defaultMessage='AN E-LOCKER is a smart and secure locker where you can charge your battery and store all your belongings.'
                                />
                            )
                        }
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <HelpAction
                        title={
                            <FormattedMessage
                                id='views.lending.info.InfoViewTitle.UsePIN-code'
                                description='The title on the info view of UsePIN-code.'
                                defaultMessage='Use PIN-code'
                            />
                        }
                        subtext={
                            <FormattedMessage
                                id='views.lending.info.InfoDetailViewSubtext.UsePIN-code'
                                description='The subtext on the info view of UsePIN-code.'
                                defaultMessage='How to use the PIN-code?'
                            />
                        }
                        onClick={() =>
                            props.onInfo(
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailViewSubtext.UsePIN-code'
                                    description='The subtext on the info view of UsePIN-code.'
                                    defaultMessage='How to use the PIN-code?'
                                />,
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailView.info.UsePIN-code'
                                    description='The description on the info detailview of UsePIN-code.'
                                    defaultMessage='Enter the PIN-code you recieved via Email or SMS. The terminal will tell you wat to do next. Remember, if you have a QR-code you can scan it instead of using your PIN-code.'
                                />
                            )
                        }
                    />
                </div>
                <div className='col'>
                    <HelpAction
                        title={
                            <FormattedMessage
                                id='views.lending.info.InfoViewTitle.PersonalInfo'
                                description='The title on the info view of PersonalInfo.'
                                defaultMessage='Personal info'
                            />
                        }
                        subtext={
                            <FormattedMessage
                                id='views.lending.info.InfoDetailViewSubtext.PersonalInfo'
                                description='The subtext on the info view of PersonalInfo.'
                                defaultMessage='How we use your info?'
                            />
                        }
                        onClick={() =>
                            props.onInfo(
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailViewSubtext.PersonalInfo'
                                    description='The subtext on the info view of PersonalInfo.'
                                    defaultMessage='How we use your info?'
                                />,
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailView.info.PersonalInfo'
                                    description='The description on the info detailview of PersonalInfo.'
                                    defaultMessage='Your personal information will only be used to send you the PIN/QR-code necessary for you to enter the selected SLOT. It will never be shared with third party companies.'
                                />
                            )
                        }
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <HelpAction
                        title={
                            <FormattedMessage
                                id='views.lending.info.InfoViewTitle.TimeWindow'
                                description='The title on the info view of TimeWindow.'
                                defaultMessage='Time window'
                            />
                        }
                        subtext={
                            <FormattedMessage
                                id='views.lending.info.InfoDetailViewSubtext.TimeWindow'
                                description='The subtext on the info view of TimeWindow.'
                                defaultMessage='How long can I use a SLOT?'
                            />
                        }
                        onClick={() =>
                            props.onInfo(
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailViewSubtext.TimeWindow'
                                    description='The subtext on the info view of TimeWindow.'
                                    defaultMessage='How long can I use a SLOT?'
                                />,
                                <FormattedMessage
                                    id='views.lending.info.InfoDetailView.info.TimeWindow'
                                    description='The description on the info detailview of TimeWindow.'
                                    defaultMessage='There is no maximum time set for using a SLOT. This means that if you lock away your belongings today they will stay there until you decide to pick them up.'
                                />
                            )
                        }
                    />
                </div>
            </div>
        </BaseView>
    );
};

export default InfoView;
