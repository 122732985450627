import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { Product } from '../../api/products';
import { useSpotLayout } from '../../api/spotLayouts';
import { Spot, useSpot, useSpotArray } from '../../api/spots';
import { Transaction } from '../../common/transactions';
import useActivity from '../../hooks/useActivity';
import useContactTransactionHistory from '../../hooks/useContactTransactionHistory';
import { Logger } from '../../logs/Logger';
import { DAY_MS } from '../../utils';
import { ProductAvailability, ProductAvailabilityResult } from '../../workflow/nike/VendingWorkflow';
import { isNikeSpotData } from '../../workflow/nike/api';
import { isNikeManager } from '../../workflow/nike/permissions';
import { transformTransactionHistoryToOwnershipInfo } from '../../workflow/nike/utils';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ProductConfigurationModal from './ProductConfigurationModal';
import ProductParametersElement from './ProductParametersElement';

export interface ProductParameter {
    name: string;
    getDisplayName: (intl: IntlShape) => React.ReactNode;
    getValue: (product: Product) => string | undefined | null;
}

export interface ProductConfiguration {
    products: Product[];
    defaultProduct: Product | undefined;
    name: string;
    parameters: ProductParameter[];
    productsAvailability?: Map<string, ProductAvailabilityResult>;
}

interface ProductConfigurationViewProps {
    productConfiguration: ProductConfiguration;
    buyerInContact: Contact;
    receiverContact: Contact;
    product?: Product;
    onHome?: () => void;
    onInactivity?: () => void;
    onPlaceOrder?: (product: Product) => void;
}

interface ProductAvailabilityElementProps {
    availability?: ProductAvailability;
}

interface AvailabilityProps {
    badgeColor: string;
    badgeLabel: React.ReactNode;
}

const ProductAvailabilityElement = (props: ProductAvailabilityElementProps) => {
    const availabilityBadgeColors = new Map<ProductAvailability, AvailabilityProps>();
    availabilityBadgeColors.set(ProductAvailability.UNKNOWN, {
        badgeColor: 'badge-dark',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.unknownAvailability'
                description="The availability label when we don't know if the product is available"
                defaultMessage='We could not check this items availability'
            />
        )
    });
    availabilityBadgeColors.set(ProductAvailability.AVAILABLE, {
        badgeColor: 'badge-success',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.availableAvailability'
                description='The availability label when the product is available'
                defaultMessage='This item is available'
            />
        )
    });
    availabilityBadgeColors.set(ProductAvailability.NOT_AVAILABLE, {
        badgeColor: 'badge-danger',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.notAvailableAvailability'
                description='The availability label when the product is not available'
                defaultMessage='This item is not available'
            />
        )
    });
    availabilityBadgeColors.set(ProductAvailability.AVAILABLE_IN_STOCK, {
        badgeColor: 'badge-warning',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.stockAvailableAvailability'
                description='The availability label when the product is available in stock'
                defaultMessage='This item is available in stock'
            />
        )
    });
    availabilityBadgeColors.set(ProductAvailability.ERROR_AVAILABLE_IN_STOCK, {
        badgeColor: 'badge-warning',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.stockAvailableErrorAvailability'
                description='The availability label when the product is available in stock but we could not check all other slots due to an error'
                defaultMessage='This item is available in stock'
            />
        )
    });
    availabilityBadgeColors.set(ProductAvailability.AVAILABLE_OTHER_SPOT, {
        badgeColor: 'badge-warning',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.availableNearbyDistrispotAvailability'
                description='The availability label when the product is available in a nearby DistriSPOT'
                defaultMessage='This item is available in a nearby DistriSPOT'
            />
        )
    });
    availabilityBadgeColors.set(ProductAvailability.AVAILABLE_IN_STOCK_OTHER_SPOT, {
        badgeColor: 'badge-warning',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.stockNearbyDistrispotAvailability'
                description='The availability label when the product is available in stock at a nearby DistriSPOT'
                defaultMessage='This item is available in stock at a nearby DistriSPOT'
            />
        )
    });
    availabilityBadgeColors.set(ProductAvailability.ERROR, {
        badgeColor: 'badge-danger',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.errorAvailability'
                description='The availability label when the product availability could not be checked due to an error'
                defaultMessage='We could not find your item due to an error'
            />
        )
    });
    const defaultAvailabilityBadge = {
        badgeColor: 'badge-info',
        badgeLabel: (
            <FormattedMessage
                id='views.nike.ProductConfigurationView.defaultAvailability'
                description='The availability label when the product has not been configured'
                defaultMessage='Availability will show after configuring product'
            />
        )
    };

    const availabilityProps = props.availability ? availabilityBadgeColors.get(props.availability)! : defaultAvailabilityBadge;

    return (
        <div className='d-flex flex-row align-items-center'>
            <span
                className={`badge ${availabilityProps.badgeColor} d-inline-block p-1 me-1`}
                style={{ minWidth: '1rem', minHeight: '1rem' }}
            />
            {availabilityProps.badgeLabel}
        </div>
    );
};

const ProductConfigurationView = (props: ProductConfigurationViewProps) => {
    const [selectedProduct, changeSelectedProduct] = useState<Product | null>(props.product ? props.product : null);
    const [selectOptions, changeSelectOptions] = useState(false);

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const nearbySpotIds = spot && isNikeSpotData(spot.additional_data) ? spot.additional_data.nearby_spot_ids : [];
    const nearbySpotResults = useSpotArray(nearbySpotIds);

    const contactHistoryResult = useContactTransactionHistory({
        cutoffTimeDeltaMs: 740 * DAY_MS,
        contactId: props.receiverContact.id
    });

    const [, newActivity] = useActivity();

    const cancelSelectOptions = () => {
        newActivity();
        changeSelectOptions(false);
    };

    const onSelectProduct = (product: Product | null) => {
        Logger.log('New product selected.', { product: product?.id, contact: props.receiverContact.id }, { selectedProduct: product, ...props });
        newActivity();
        changeSelectedProduct(product);
        changeSelectOptions(false);
    };

    useEffect(() => {
        // The effect that auto selects the previously ordered product
        if (
            contactHistoryResult.isSuccess &&
            contactHistoryResult.data &&
            (!selectedProduct || selectedProduct.id === props.productConfiguration.defaultProduct?.id)
        ) {
            const ownershipInfo = transformTransactionHistoryToOwnershipInfo(contactHistoryResult.data);
            const ownershipItems = Array.from(ownershipInfo.values()); // No filtering required here. If you touched an item, we will offer that one next.
            // Order by latest created date
            ownershipItems
                .sort((a, b) => {
                    let transactionA: Transaction;
                    if (a.transferInProgress) {
                        transactionA = a.transferInProgress;
                    } else if (a.transferDone) {
                        transactionA = a.transferDone;
                    } else {
                        return 0;
                    }
                    let transactionB: Transaction;
                    if (b.transferInProgress) {
                        transactionB = b.transferInProgress;
                    } else if (b.transferDone) {
                        transactionB = b.transferDone;
                    } else {
                        return 0;
                    }
                    return new Date(transactionA.created_date).getTime() - new Date(transactionB.created_date).getTime();
                })
                .reverse();
            if (ownershipItems.length > 0) {
                const productId = ownershipItems[0].productInstance.product;
                const foundProduct = props.productConfiguration.products.find((product) => {
                    return product.id === productId;
                });
                if (foundProduct) {
                    onSelectProduct(foundProduct);
                }
            } else if (props.productConfiguration.defaultProduct) {
                onSelectProduct(props.productConfiguration.defaultProduct);
            }
        }
    }, [contactHistoryResult.isSuccess, contactHistoryResult.data, props.productConfiguration.defaultProduct]);

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    let orderInformation: React.ReactNode | undefined;
    if (!props.productConfiguration.productsAvailability) {
        // For one reason or another we could not determine product availability for any product. Inform the user that he
        // can't order shoes.
        orderInformation = (
            <>
                <div className='alert alert-danger'>
                    <FormattedMessage
                        id='views.nike.ProductConfigurationView.productAvailabilityError'
                        description='Error message shown when product availability in general could not be determined on the Nike product configuration view.'
                        defaultMessage='An error occurred while determining product availability. You cannot order at the moment.'
                    />
                </div>
            </>
        );
    } else if (!selectedProduct) {
        orderInformation = (
            <>
                <button
                    className='primary-button btn-scaling-lg mt-4 btn-block'
                    onClick={() => {
                        changeSelectOptions(true);
                    }}>
                    <FormattedMessage
                        id='views.nike.ProductConfigurationView.chooseOptionsButton'
                        description='The button on the Nike product configuration view to choose the options of the product.'
                        defaultMessage='Configure'
                    />
                </button>
            </>
        );
    } else {
        const productAvailability = props.productConfiguration.productsAvailability.get(selectedProduct.id.toString());

        const editOrderButton = (
            <button
                className='light-button mt-4 mt-xl-1 btn-outline-primary flex-grow-1 me-2'
                onClick={() => {
                    changeSelectOptions(true);
                }}>
                <FormattedMessage
                    id='views.nike.ProductConfigurationView.editOrderButton'
                    description='The button on the Nike product configuration view to change the options of the product'
                    defaultMessage='Edit order'
                />
            </button>
        );

        if (
            !productAvailability ||
            productAvailability.availability === ProductAvailability.ERROR ||
            productAvailability.availability === ProductAvailability.UNKNOWN
        ) {
            // For one reason or another we could not determine product availability for this product. Inform the user that he
            // can't order this product, but could choose another one.
            orderInformation = (
                <>
                    <div className='alert alert-danger'>
                        <FormattedMessage
                            id='views.nike.ProductConfigurationView.productAvailabilitySingleProductError'
                            description='Error message shown when product availability could not be determined for the currently configured product on the Nike product configuration view.'
                            defaultMessage='We were unable to determine if this product is available and you cannot order it at the moment. Try again later or edit your order.'
                        />
                    </div>
                    <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                </>
            );
        } else {
            const placeOrderButton = (
                <button
                    className='primary-button btn-scaling-lg mt-4 mt-xl-1 flex-grow-1 ms-2'
                    onClick={() => {
                        if (props.onPlaceOrder) {
                            props.onPlaceOrder(selectedProduct);
                        }
                    }}>
                    <FormattedMessage
                        id='views.nike.ProductConfigurationView.placeOrderButton'
                        description='The button on the Nike product configuration view to order the currently configured product'
                        defaultMessage='Place order'
                    />
                </button>
            );
            const openSlotButton = (
                <button
                    className='primary-button btn-scaling-lg mt-4 mt-xl-1 flex-grow-1 ms-2'
                    onClick={() => {
                        if (props.onPlaceOrder) {
                            props.onPlaceOrder(selectedProduct);
                        }
                    }}>
                    <FormattedMessage
                        id='views.nike.ProductConfigurationView.openSlotButton'
                        description='The button on the Nike product configuration view to order the currently configured product if it is available in the current DistriSPOT'
                        defaultMessage='Open Slot'
                    />
                </button>
            );

            const userIsNikeManager = isNikeManager(props.buyerInContact);
            const nearbySpots: Spot[] = (
                nearbySpotResults && productAvailability.slotsWithTransaction
                    ? nearbySpotResults.filter((nearbySpotResult) => {
                          if (!productAvailability.slotsWithTransaction) return false;
                          return productAvailability.slotsWithTransaction.find((slot) => {
                              return nearbySpotResult.isSuccess && nearbySpotResult.data && slot.transaction.spot_id === nearbySpotResult.data.id;
                          });
                      })
                    : []
            )
                .map((item) => item.data)
                .filter((item): item is Spot => {
                    return item !== undefined;
                });

            if (productAvailability.availability === ProductAvailability.NOT_AVAILABLE) {
                if (!selectedProduct.ordering_allowed) {
                    orderInformation = (
                        <>
                            <div className='alert alert-warning'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.CannotOrderError'
                                    description='Error message shown when product is not available and can not be ordered due to its configuration.'
                                    defaultMessage='Your product is currently not available or out of stock and cannot be ordered. Come back in two days to try again. The stock will have been refilled.'
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                        </>
                    );
                } else {
                    orderInformation = (
                        <>
                            <div className='alert alert-warning'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.productNotAvailableError'
                                    description='Error message shown when product is not available on the Nike product configuration view.'
                                    defaultMessage='Your product is currently not available or out of stock. You can order it and you will be notified by your coach when it has been delivered.'
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>
                                {editOrderButton}
                                {placeOrderButton}
                            </div>
                        </>
                    );
                }
            } else if (productAvailability.availability === ProductAvailability.AVAILABLE) {
                orderInformation = (
                    <>
                        <div className='alert alert-success'>
                            <FormattedMessage
                                id='views.nike.ProductConfigurationView.productAvailableMessage'
                                description='Message shown when the product is available in the current DistriSPOT on the Nike product configuration view.'
                                defaultMessage='This product is available.'
                            />
                        </div>
                        <div className='d-flex d-row justify-content-center'>
                            {editOrderButton}
                            {openSlotButton}
                        </div>
                    </>
                );
            } else if (productAvailability.availability === ProductAvailability.AVAILABLE_IN_STOCK) {
                if (!userIsNikeManager) {
                    orderInformation = (
                        <>
                            <div className='alert alert-warning'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.productAvailableInStockNotManager'
                                    description='Message shown when the product is available in the stock of the current DistriSPOT and we do not have sufficient permissions to open it. This is on the Nike product configuration view.'
                                    defaultMessage='This product is in stock. You can contact your team coach to retrieve it for you.'
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                        </>
                    );
                } else {
                    orderInformation = (
                        <>
                            <div className='alert alert-success'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.productAvailableInStockManager'
                                    description='Message shown when the product is available in the stock of the current DistriSPOT but we have permissions to open it. This is on the Nike product configuration view.'
                                    defaultMessage='This product is available in stock. Be sure to remove the correct item from the stock.'
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>
                                {editOrderButton}
                                {openSlotButton}
                                {placeOrderButton}
                            </div>
                        </>
                    );
                }
            } else if (productAvailability.availability === ProductAvailability.ERROR_AVAILABLE_IN_STOCK) {
                if (!userIsNikeManager) {
                    orderInformation = (
                        <>
                            <div className='alert alert-warning'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.productErrorAvailableInStockNotManager'
                                    description='Message shown when an error occurred checking some slots for the product. However, the product is available in the stock of the current DistriSPOT and we do not have sufficient permissions to open it. This is on the Nike product configuration view.'
                                    defaultMessage='We could not check all slots for the product due to an error. It is however available in stock. You can contact your team coach to retrieve it for you.'
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                        </>
                    );
                } else {
                    orderInformation = (
                        <>
                            <div className='alert alert-success'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.productErrorAvailableInStockManager'
                                    description='Message shown when an error occurred checking some slots for the product. However, the product is available in the stock of the current DistriSPOT and we have permissions to open it. This is on the Nike product configuration view.'
                                    defaultMessage='We could not check all slots for the product due to an error. It is however available in stock. Be sure to remove the correct item from the stock.'
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>
                                {editOrderButton}
                                {openSlotButton}
                                {placeOrderButton}
                            </div>
                        </>
                    );
                }
            } else if (productAvailability.availability === ProductAvailability.AVAILABLE_OTHER_SPOT) {
                orderInformation = (
                    <>
                        <div className='alert alert-warning'>
                            <FormattedMessage
                                id='views.nike.ProductConfigurationView.productAvailableOtherSpotMessage'
                                description='Message shown when the product is available in a nearby DistriSPOT on the Nike product configuration view.'
                                defaultMessage='{spotCount, plural, one {This product is available in the nearby DistriSPOT: {names}. You can retrieve it there.} other {This product is available in nearby DistriSPOTs: {names}. You can retrieve it from one of them.}}'
                                values={{
                                    spotCount: productAvailability.slotsWithTransaction!.length,
                                    names: nearbySpots.map((spot) => spot.name).join(', ')
                                }}
                            />
                        </div>
                        <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                    </>
                );
            } else if (productAvailability.availability === ProductAvailability.AVAILABLE_IN_STOCK_OTHER_SPOT) {
                if (!userIsNikeManager) {
                    orderInformation = (
                        <>
                            <div className='alert alert-warning'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.productAvailableInStockOtherSpotNotManagerMessage'
                                    description='Message shown when the product is available in the stock of a nearby DistriSPOT and we are not a manager. This is on the Nike product configuration view.'
                                    defaultMessage='{spotCount, plural, one {This product is available in stock in the nearby DistriSPOT: {names}. You can ask your team coach to retrieve it there for you.} other {This product is available in stock in nearby DistriSPOTs: {names}. Your team coach can retrieve it from one of them.}}'
                                    values={{
                                        spotCount: productAvailability.slotsWithTransaction!.length,
                                        names: nearbySpots.map((spot) => spot.name).join(', ')
                                    }}
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                        </>
                    );
                } else {
                    orderInformation = (
                        <>
                            <div className='alert alert-warning'>
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.productAvailableInStockOtherSpotManagerMessage'
                                    description='Message shown when the product is available in the stock of a nearby DistriSPOT and we are a manager. This is on the Nike product configuration view.'
                                    defaultMessage='{spotCount, plural, one {This product is available in stock in the nearby DistriSPOT: {names}. You can retrieve it there.} other {This product is available in stock in nearby DistriSPOTs: {names}. You can retrieve it from one of them.}}'
                                    values={{
                                        spotCount: productAvailability.slotsWithTransaction!.length,
                                        names: nearbySpots.map((spot) => spot.name).join(', ')
                                    }}
                                />
                            </div>
                            <div className='d-flex d-row justify-content-center'>
                                {editOrderButton}
                                {placeOrderButton}
                            </div>
                        </>
                    );
                }
            } else if (productAvailability.availability === ProductAvailability.ERROR) {
                orderInformation = (
                    <>
                        <div className='alert alert-danger'>
                            <FormattedMessage
                                id='views.nike.ProductConfigurationView.productAvailabilitySingleProductErrorState'
                                description='Error message shown when product availability is in an error state for the currently configured product on the Nike product configuration view.'
                                defaultMessage='We were unable to determine if this product is available and you cannot order it at the moment. Try again later or edit your order.'
                            />
                        </div>
                        <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                    </>
                );
            } else {
                // Unknown state, probably still determining
                orderInformation = (
                    <>
                        <div className='alert alert-dark'>
                            <FormattedMessage
                                id='views.nike.ProductConfigurationView.productAvailabilitySingleProductUnknownState'
                                description='Message shown when product availability is in an unknown state for the currently configured product on the Nike product configuration view (we are probably still fetching data).'
                                defaultMessage='We are determining if this product is available. Please wait...'
                            />
                        </div>
                        <div className='d-flex d-row justify-content-center'>{editOrderButton}</div>
                    </>
                );
            }
        }
    }

    const imageSrc =
        (selectedProduct && selectedProduct.image) ||
        (props.productConfiguration.products && props.productConfiguration.products.length > 0 && props.productConfiguration.products[0].image);
    let image;
    if (imageSrc) {
        image = (
            <img
                className='my-xl-0 my-2 img-fluid img-thumbnail responsive-resize-img'
                src={imageSrc}
                alt=''
            />
        );
    } else {
        image = (
            <div className='my-xl-0 my-2 img-thumbnail responsive-resize-img w-100 d-flex flex-column justify-content-center align-items-center'>
                <span>
                    <FormattedMessage
                        id='views.nike.ProductConfigurationView.noImageMessage'
                        description='This message is displayed if there is no image configured for the selected product'
                        defaultMessage='No image available'
                    />
                </span>
            </div>
        );
    }

    const productAvailabilityElement = (
        <ProductAvailabilityElement
            availability={
                props.productConfiguration.productsAvailability &&
                selectedProduct &&
                props.productConfiguration.productsAvailability.has(selectedProduct.id.toString())
                    ? props.productConfiguration.productsAvailability.get(selectedProduct.id.toString())!.availability
                    : undefined
            }
        />
    );

    const productParametersElement = (
        <ProductParametersElement
            product={selectedProduct}
            parameters={props.productConfiguration.parameters}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <h3 className='mt-4 d-xl-none'>
                <FormattedMessage
                    id='views.nike.ProductConfigurationView.title'
                    description='The title shown on the Nike product configuration view.'
                    defaultMessage='Order a new product'
                />
            </h3>
            <div className='d-xl-none'>{productAvailabilityElement}</div>
            <div className='row'>
                <div className='col-12 col-xl-6 d-flex justify-content-center'>
                    {image}
                    <div className='d-none d-xl-block'>
                        <small>
                            {productAvailabilityElement}
                            {productParametersElement}
                        </small>
                    </div>
                </div>
                <div className='col-12 col-xl-6'>
                    <h4 className='font-weight-bold'>{selectedProduct ? selectedProduct.name : ''}</h4>
                    <div className='d-xl-none'>{productParametersElement}</div>
                    <div className='mt-4 mt-xl-1 small'>{orderInformation}</div>
                </div>
            </div>
            <p className='text-center mt-4 mt-xl-1 mb-xl-0'>
                <button
                    className='btn btn-link text-secondary'
                    onClick={props.onHome}>
                    <u>
                        <FormattedMessage
                            id='views.nike.ProductConfigurationView.cancelButton'
                            description='The cancel button on the Nike product configuration view.'
                            defaultMessage='Cancel and go to dashboard'
                        />
                    </u>
                </button>
            </p>
            {selectOptions ? (
                <ProductConfigurationModal
                    productConfiguration={props.productConfiguration}
                    product={selectedProduct}
                    onSelectProduct={onSelectProduct}
                    show={selectOptions}
                    onHide={cancelSelectOptions}
                />
            ) : (
                <></>
            )}
        </BaseView>
    );
};

export default ProductConfigurationView;
