import React from 'react';
import { Color } from 'react-bootstrap/esm/types';

export default interface ActionCard {
    id?: string;
    actionText: string;
    icon?: React.ReactNode;
    color?: Color;
    highlight?: boolean;
    callToAction?: ActionCard; // A specific call to action for this item (instead of the default entire element).
    fullWidth?: boolean;
    view?: JSX.Element;
    onAction?: () => void;
}

export const isAction = (object: unknown): object is ActionCard => Object.prototype.hasOwnProperty.call(object, 'actionText');
