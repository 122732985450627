import Connection from '../Connection';
import { ServiceWorkerInit } from '../ServiceWorkerInit';
import { Terminal } from '../api/terminals';
import { SpotLayoutLogsState } from '../redux/SpotLayoutLogsSlice';
import { WebsocketAuthenticateContactCommand } from './commands/WebsocketAuthenticateContactCommand';
import { WebsocketCacheResetCommand } from './commands/WebsocketCacheResetCommand';
import { WebsocketClearPrinterQueueCommand } from './commands/WebsocketClearPrinterQueueCommand';
import { WebsocketConfigCommand } from './commands/WebsocketConfigCommand';
import { WebsocketOpenSlotCommand } from './commands/WebsocketOpenSlotCommand';
import { WebsocketPausePrinterCommand } from './commands/WebsocketPausePrinterCommand';
import { WebsocketPcbResetCommand } from './commands/WebsocketPcbResetCommand';
import { WebsocketPowerCycleCommand } from './commands/WebsocketPowerCycleCommand';
import { WebsocketPrintLabelCommand } from './commands/WebsocketPrintLabelCommand';
import { WebsocketPrinterStatusCommand } from './commands/WebsocketPrinterStatusCommand';
import { WebsocketRefreshCommand } from './commands/WebsocketRefreshCommand';
import { WebsocketRequestLogsCommand } from './commands/WebsocketRequestLogsCommand';
import { WebsocketResetCommand } from './commands/WebsocketResetCommand';
import { WebsocketResetPrinterCommand } from './commands/WebsocketResetPrinterCommand';
import { WebsocketServiceWorkerResetCommand } from './commands/WebsocketServiceWorkerResetCommand';
import { WebsocketSetLightsCommand } from './commands/WebsocketSetLightsCommand';
import { WebsocketSlotStateRequestCommand } from './commands/WebsocketSlotStateRequestCommand';
import { WebsocketSpotSlotsStatesCommand } from './commands/WebsocketSpotSlotsStatesCommand';
import { WebsocketTerminalOnCommand } from './commands/WebsocketTerminalOnCommand';
import { WebsocketTerminalRequestCommand } from './commands/WebsocketTerminalRequestCommand';
import { WebsocketUpdateCheckCommand } from './commands/WebsocketUpdateCheckCommand';
import { WebsocketCommandRequest, WebsocketCommandType } from './useManagedWebsocket';

export interface WebsocketCommandResponse {
    success: boolean;
    message?: any;
    send?: boolean;
}

export default async function executeWebsocketCommand(
    command: WebsocketCommandRequest,
    connection: Connection,
    sw: ServiceWorkerInit,
    spotLayoutLogsState: SpotLayoutLogsState,
    websocket: WebSocket,
    terminal: Terminal | null,
    changeTerminal: (value: Terminal | null) => void,
    changeCacheReset: (value: React.SetStateAction<boolean>) => void,
    fullStateCheck: () => Promise<void>
): Promise<WebsocketCommandResponse | void> {
    try {
        switch (command.command) {
            case WebsocketCommandType.RESET:
                return await new WebsocketResetCommand(command).execute();
            case WebsocketCommandType.OPEN_SLOT:
                return await new WebsocketOpenSlotCommand(command).execute(connection);
            case WebsocketCommandType.SWRESET:
                return await new WebsocketServiceWorkerResetCommand(command).execute(sw);
            case WebsocketCommandType.REFRESH:
                return await new WebsocketRefreshCommand(command).execute();
            case WebsocketCommandType.CONFIG:
                return await new WebsocketConfigCommand(command).execute(changeTerminal);
            case WebsocketCommandType.CACHE_RESET:
                return await new WebsocketCacheResetCommand(command).execute(changeCacheReset);
            case WebsocketCommandType.UPDATE_CHECK:
                return await new WebsocketUpdateCheckCommand(command).execute();
            case WebsocketCommandType.RESET_PCB:
                return await new WebsocketPcbResetCommand(command).execute(connection);
            case WebsocketCommandType.POWER_CYCLE:
                return await new WebsocketPowerCycleCommand(command).execute(connection);
            case WebsocketCommandType.SET_LIGHTS:
                return await new WebsocketSetLightsCommand(command).execute(connection);
            case WebsocketCommandType.TERMINAL_ON:
                return await new WebsocketTerminalOnCommand(command).execute();
            case WebsocketCommandType.TERMINAL_REQUEST:
                return await new WebsocketTerminalRequestCommand(command).execute(websocket, terminal);
            case WebsocketCommandType.SLOT_STATE:
                return await new WebsocketSlotStateRequestCommand(command).execute(spotLayoutLogsState, websocket, terminal);
            case WebsocketCommandType.SPOT_SLOT_STATES:
                return await new WebsocketSpotSlotsStatesCommand(command).execute(fullStateCheck);
            case WebsocketCommandType.PRINT_LABEL:
                return await new WebsocketPrintLabelCommand(command).execute(terminal, websocket);
            case WebsocketCommandType.REQUEST_LOGS:
                return await new WebsocketRequestLogsCommand(command).execute(terminal, websocket);
            case WebsocketCommandType.RESET_PRINTER:
                return await new WebsocketResetPrinterCommand(command).execute();
            case WebsocketCommandType.PRINTER_STATUS:
                return await new WebsocketPrinterStatusCommand(command).execute();
            case WebsocketCommandType.PAUSE_PRINTER:
                return await new WebsocketPausePrinterCommand(command).execute();
            case WebsocketCommandType.CLEAR_PRINTER_QUEUE:
                return await new WebsocketClearPrinterQueueCommand(command).execute();
            case WebsocketCommandType.AUTHENTICATE_CONTACT:
                return await new WebsocketAuthenticateContactCommand(command).execute();
            default:
                throw new Error('The given websocket command does not exist.');
        }
    } catch (error) {
        return {
            success: false,
            message: (error as Error).message,
            send: true
        };
    }
}
