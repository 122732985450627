import { Contact } from '../../../api/contacts';
import { getFullName } from '../../../utils';

const ContactListElement = (contact: Contact, onSelect?: (contact: Contact) => void): JSX.Element => {
    return (
        <tr
            onClick={() => {
                if (onSelect) onSelect(contact);
            }}>
            <td
                className='py-2'
                style={{ verticalAlign: 'middle' }}>
                {getFullName(contact)}
            </td>
        </tr>
    );
};

export default ContactListElement;
