import { FormattedMessage } from 'react-intl';

import { Slot } from '../../api/slots';
import ActionCard from '../../components/domain/ActionCard';
import ActionCardGridElement from '../../components/elements/ActionCardGridElement';
import { Logger } from '../../logs/Logger';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ViewTitle from '../nike/ViewTitle';

interface ConfirmLendingViewProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onConfirm: () => void;
    actions: ActionCard[];
    selectedSlot: Slot | null;
}

const ConfirmLendingView = (props: ConfirmLendingViewProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    const onSelect = (action: ActionCard) => {
        Logger.log('User just confirmed a slot.', { slot: props.selectedSlot?.id });
        props.onConfirm();
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.lending.confirmLending.confirmLendingView.title'
                    description='The title on the confirmLendingView.'
                    defaultMessage='Are you sure you want to use SLOT {slot_nr}?'
                    values={{
                        slot_nr: props.selectedSlot?.slot_nr
                    }}
                />
            </ViewTitle>
            <ActionCardGridElement
                actions={props.actions}
                onSelectAction={onSelect}
            />
        </BaseView>
    );
};

export default ConfirmLendingView;
