import { ProtonCommands } from '../../services/ProtonScalesService';
import { IProtonEvent } from '../IProtonEvent';

export interface IProtonRequest extends IProtonEvent {
    ext: {
        appType: 'proton';
        appVer: string;
        station: string;
        bindingVer: number;
        deviceVer: number;
        resCode: number;
    };
    payload: unknown;
    type: 'request';
}

export function isProtonRequest(request: unknown): request is IProtonRequest {
    return (
        (request as IProtonRequest).type === 'request' &&
        (request as IProtonRequest).header &&
        Object.values(ProtonCommands).includes((request as IProtonRequest).header.action as ProtonCommands)
    );
}
