import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand } from '../useManagedWebsocket';

/**
 * This command will make the terminal redirect to the /reset URL.
 * Thus showing the Reset Page.
 */
export class WebsocketResetCommand extends WebsocketCommand {
    public async execute(): Promise<WebsocketCommandResponse> {
        window.location.href = '/reset/';
        return { success: true };
    }
}
