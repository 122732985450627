import { useEffect, useState } from 'react';

import { TerminalEvents } from '../events/terminal/Events';
import { IProtonEvent } from '../scales/proton/IProtonEvent';
import { sleep } from '../utils';

/**
 * Used to intercept messages from the terminal and provide them in a hook
 *
 * Add new useStates for different communication endpoints
 * @returns all configured types of events
 */
export default function useTerminalEventHandler() {
    const [protonEvent, changeProtonEvent] = useState<IProtonEvent | undefined>(undefined);
    const [scannerEvent, changeScannerEvent] = useState<string | undefined>(undefined);

    useEffect(() => {
        const listeners = new Map<string, (event: any) => void>();
        listeners.set(TerminalEvents.PROTON, (event: { detail: IProtonEvent }) => {
            changeProtonEvent(event.detail);
            waitForReset(changeProtonEvent);
        });
        listeners.set(TerminalEvents.SCANNER_RESULT, (event: { detail: string | undefined }) => {
            changeScannerEvent(event.detail);
            waitForReset(changeScannerEvent);
        });

        listeners.forEach((listener, key) => {
            window.addEventListener(key, listener);
        });
        return () => {
            listeners.forEach((listener, key) => {
                window.removeEventListener(key, listener);
            });
        };
    }, []);

    return { protonEvent, scannerEvent } as const;
}

async function waitForReset(callback: (value: undefined) => void) {
    await sleep(1000);
    callback(undefined);
}
