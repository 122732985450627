import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useActivity from '../hooks/useActivity';
import BackAndHomeNavigationButtons from './common/BackAndHomeNavigationButtons';
import BaseView from './common/BaseView';
import ScannerModal from './elements/ScannerModal';

interface PinViewProps {
    title?: string;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onSubmit: (value: string) => void;
    lending?: boolean;
}

const PinView = (props: PinViewProps) => {
    const [showScanModal, changeShowScanModal] = useState(false);
    const [pin, changePin] = useState('');
    const [, newActivity] = useActivity();

    const inputEnabled = pin.length < 4;
    const pin1 = pin.slice(0, 1);
    const pin2 = pin.slice(1, 2);
    const pin3 = pin.slice(2, 3);
    const pin4 = pin.slice(3, 4);

    const onSubmit = (event: React.FormEvent) => {
        newActivity();
        event.preventDefault();
        if (pin !== '') {
            props.onSubmit(pin);
            changePin('');
        }
    };

    const onInput = (value: string) => {
        newActivity();
        changePin(pin + value);
    };

    const onBackSpace = () => {
        newActivity();
        if (pin.length > 0) {
            changePin(pin.slice(0, -1));
        }
    };

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <h3 className='mt-3 text-center'>
                <FormattedMessage
                    id='views.PinView.title'
                    description='The title on the PuDo PIN code screen (used in both dropoff and pickup flow)'
                    defaultMessage='Enter your PIN-code'
                />
            </h3>
            <div>
                <form onSubmit={onSubmit}>
                    <div className='row justify-content-center'>
                        <div className='col d-flex justify-content-center'>
                            <div className='form-group'>
                                <input
                                    type='password'
                                    className='pin-input'
                                    value={pin1}
                                    disabled={true}
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type='password'
                                    className='pin-input'
                                    value={pin2}
                                    disabled={true}
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type='password'
                                    className='pin-input'
                                    value={pin3}
                                    disabled={true}
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type='password'
                                    className='pin-input'
                                    value={pin4}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row pt-4'>
                        <div className='col d-flex justify-content-center'>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('1');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>1</h1>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('2');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>2</h1>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('3');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>3</h1>
                            </button>
                        </div>
                        <div className='w-100' />
                        <div className='col d-flex justify-content-center'>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('4');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>4</h1>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('5');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>5</h1>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('6');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>6</h1>
                            </button>
                        </div>
                        <div className='w-100' />
                        <div className='col d-flex justify-content-center'>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('7');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>7</h1>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('8');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>8</h1>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('9');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>9</h1>
                            </button>
                        </div>
                        <div className='w-100' />
                        <div className='col d-flex justify-content-center'>
                            <button
                                type='button'
                                onClick={onBackSpace}
                                disabled={pin === ''}
                                className='pin-button pin-button-backspace'>
                                <h1 className='m-0'>
                                    <i className='bi bi-backspace' />
                                </h1>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    onInput('0');
                                }}
                                disabled={!inputEnabled}
                                className='pin-button'>
                                <h1 className='m-0'>0</h1>
                            </button>
                            <button
                                type='submit'
                                className='pin-button pin-button-submit'
                                disabled={inputEnabled}>
                                <h1 className='m-0 text-light'>
                                    <i className='bi bi-check-circle' />
                                </h1>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className='d-flex justify-content-center mt-4'>
                <button
                    className='btn btn-light'
                    onClick={() => changeShowScanModal(true)}>
                    <i className='bi bi-upc-scan me-2'></i>
                    <FormattedMessage
                        id='views.PinView.ScannerButton'
                        description='The button on the pinview screen to tell the user he/she can use there QR-code.'
                        defaultMessage='Or scan your QR-code'
                    />
                </button>
            </div>
            <ScannerModal
                show={showScanModal}
                changeShow={changeShowScanModal}
                lending={props.lending}
            />
        </BaseView>
    );
};

export default PinView;
