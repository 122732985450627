import { useContext } from 'react';
import { QueryObserverResult } from 'react-query';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Product, useProducts } from '../../../api/products';
import { useShops } from '../../../api/shops';
import { Logger } from '../../../logs/Logger';
import { LookupState } from '../transactions/actions';
import useBaseLookup, { LookupResult } from '../useBaseLookup';

interface LookupProductScanProps {
    lookupValue?: string | null;
}

const useLookupProductScan = (props: LookupProductScanProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal && props.lookupValue != null });

    if (!shops || shops.length == 0) {
        return {
            lookupValue: '',
            result: undefined,
            state: LookupState.IDLE,
            isSuccess: false,
            isError: true,
            isInProgress: false,
            isIdle: false,
            isRefetching: false
        } as LookupResult<Product, string>;
    }

    const productsResult = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);

    let foundProduct = null;
    let error = true;
    let success = true;
    if (props.lookupValue) {
        const { isSuccess, isError, data } = processScan(productsResult, props.lookupValue);
        foundProduct = data;
        error = isError;
        success = isSuccess;
    }

    const requiredDataFetched = error || success;

    const LookupImpl = {
        lookupValue: props.lookupValue,
        requiredDataFetched,
        result: foundProduct,
        refetchTransactions: () => undefined,
        isRefetching: false
    };
    return useBaseLookup(LookupImpl);
};

const processScan = (productsResult: QueryObserverResult<Product[]>, value: string) => {
    const isLoading = productsResult.isLoading;
    const isSuccess = productsResult.isSuccess;
    const isError = productsResult.isError;
    let result: Product | undefined = undefined;

    if (productsResult.isSuccess) {
        const products = productsResult.data!;
        const matchedProducts = products.filter((product) => {
            return product.product_codes.some((product_code) => product_code === value);
        });
        if (matchedProducts.length > 0) {
            if (matchedProducts.length > 1) {
                Logger.warn(
                    'Multiple products found with this code, product picker not implemented, we will use the first one.',
                    { product: matchedProducts[0].id },
                    matchedProducts
                );
            }
            result = matchedProducts[0];
        }
    }

    return {
        isSuccess: isSuccess,
        isError: isError,
        isLoading: isLoading,
        data: result
    };
};

export default useLookupProductScan;
