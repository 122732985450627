import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../../api/contacts';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';
import EmployeeCard from './EmployeeCard';

interface EmployeeSelectViewProps {
    employees: Contact[];
    onCancel: () => void;
    onSelect: (employee: Contact | null) => void;
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;
}

export default function EmployeeSelectView(props: EmployeeSelectViewProps) {
    useEffect(() => {
        if (props.employees.length == 0) {
            props.onSelect(null);
        } else if (props.employees.length == 1) {
            props.onSelect(props.employees[0]);
        }
    }, []);

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.registration.EmployeeSelectView.title'
                    description='The title on the view that asks the user to select an employee when there are multiple matches.'
                    defaultMessage='Multiple matches found'
                />
            </ViewTitle>
            <p className='mb-4'>
                <FormattedMessage
                    id='views.nike.registration.EmployeeSelectView.message'
                    description='The message on the view that asks the user to select an employee when there are multiple matches.'
                    defaultMessage='Your search returned multiple results. Can you select who you are?'
                />
            </p>
            {props.employees.map((employee) => {
                return (
                    <EmployeeCard
                        key={employee.id}
                        employee={employee}
                        className='mb-4'
                        onClick={() => {
                            props.onSelect(employee);
                        }}
                    />
                );
            })}
            <button
                className='primary-button'
                onClick={props.onCancel}>
                <FormattedMessage
                    id='views.nike.registration.EmployeeSelectView.somebodyElseCallToAction'
                    description='The call to action when somebody else on the view that asks the user to select an employee when there are multiple matches.'
                    defaultMessage="I'm somebody else"
                />
            </button>
        </BaseView>
    );
}
