import { useEffect, useState } from 'react';

import Connection, { AccessToken } from '../Connection';
import PickTerminal from '../PickTerminal';
import RefreshFailed from '../RefreshFailed';
import { ServiceWorkerInit } from '../ServiceWorkerInit';
import { ServiceWorkerInitState, TerminalInitState } from '../TerminalInit';
import { Terminal, useTerminals } from '../api/terminals';
import { Logger } from '../logs/Logger';
import ErrorInitializingView from './ErrorInitializingView';
import InitAuthentication from './InitAuthentication';
import InitializingApp from './InitializingApp';

interface Props {
    connection: Connection | null;
    serviceWorkerInit: ServiceWorkerInit;
    terminal: Terminal | null;

    initialTerminalState: TerminalInitState;
    initialServiceWorkerState: ServiceWorkerInitState;
    accessToken: AccessToken | null;

    timeToRefresh: number | null;

    changeTerminal: (terminal: Terminal | null) => void;
}

export default function useInitialization(props: Props) {
    const validSWStates = [
        ServiceWorkerInitState.REQUIRES_AUTH,
        ServiceWorkerInitState.REFRESH_FAILED,
        ServiceWorkerInitState.RETRY_REFRESH,
        ServiceWorkerInitState.UNAVAILABLE,
        ServiceWorkerInitState.DONE
    ];

    const [view, changeView] = useState<JSX.Element>(<p>Starting initialization</p>);
    const [initializationDone, changeInitializationDone] = useState<boolean>(false);
    const { data: terminals } = useTerminals(
        {
            onSuccess: (data) => {
                if (data.length === 1) {
                    props.changeTerminal(data[0]);
                }
            },
            enabled:
                props.terminal === null &&
                (props.initialServiceWorkerState === ServiceWorkerInitState.DONE ||
                    (props.initialServiceWorkerState === ServiceWorkerInitState.UNAVAILABLE && props.accessToken != null))
        },
        {
            includeAccessToken: !props.serviceWorkerInit.serviceWorkerAvailable(),
            accessToken: props.accessToken
        }
    );

    useEffect(() => {
        if (props.initialTerminalState === TerminalInitState.INIT || props.initialServiceWorkerState === ServiceWorkerInitState.INIT) {
            changeInitializationDone(false);
            changeView(
                <InitializingApp
                    initTerminal={props.initialTerminalState.valueOf()}
                    initServiceWorker={props.initialServiceWorkerState.valueOf()}
                />
            );
        } else if (props.initialTerminalState === TerminalInitState.DONE && validSWStates.includes(props.initialServiceWorkerState)) {
            changeInitializationDone(false);
            if (props.initialServiceWorkerState === ServiceWorkerInitState.REFRESH_FAILED) {
                if (props.timeToRefresh !== null) {
                    changeView(<RefreshFailed secondsUntilRetry={props.timeToRefresh} />);
                } else {
                    changeView(
                        <InitializingApp
                            initTerminal={props.initialTerminalState.valueOf()}
                            initServiceWorker={props.initialServiceWorkerState.valueOf()}
                        />
                    );
                }
            } else if (props.initialServiceWorkerState === ServiceWorkerInitState.RETRY_REFRESH) {
                changeView(
                    <InitializingApp
                        initTerminal={props.initialTerminalState.valueOf()}
                        initServiceWorker={props.initialServiceWorkerState.valueOf()}
                    />
                );
            } else if (
                props.initialServiceWorkerState === ServiceWorkerInitState.REQUIRES_AUTH ||
                (props.initialServiceWorkerState === ServiceWorkerInitState.UNAVAILABLE && props.accessToken === null)
            ) {
                Logger.log('Fetching new access token');
                props.connection?.reConfigureTerminal().catch((e) => {
                    Logger.error('Could not get new access token', {}, e);
                });
                changeView(<InitAuthentication />);
            } else if (terminals && props.terminal == null) {
                changeView(
                    <PickTerminal
                        terminals={terminals}
                        onTerminalChanged={props.changeTerminal}
                    />
                );
            } else {
                changeInitializationDone(true);
            }
        } else {
            changeView(<ErrorInitializingView {...props} />);
        }
    }, [props.initialServiceWorkerState, props.initialTerminalState, props.terminal, terminals]);

    return { view, initializationDone } as const;
}
