import { FormattedMessage } from 'react-intl';

import { ReactComponent as NikeBadge } from '../../../static/nike/nike-badge-how-does-it-work.svg';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';

interface HowDoesItWorkPart1ViewProps {
    onNext: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
}

function HowDoesItWorkPart1View(props: HowDoesItWorkPart1ViewProps) {
    const homeNav = <BackAndHomeNavigationButtons onHome={props.onLogout} />;

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <div className='d-flex flex-column align-items-center'>
                <ViewTitle>
                    <FormattedMessage
                        id='views.nike.how_does_it_work.HowDoesItWorkPart1Title'
                        description='The title of step one of the how does it work flow for Nike'
                        defaultMessage='How does it work?'
                    />
                </ViewTitle>
                <div className='text-center mt-4 m-xl-1 mb-4 mb-xl-1'>
                    <NikeBadge className='text-primary img-fluid responsive-resize-img' />
                </div>
                <h3>
                    <FormattedMessage
                        id='views.nike.how_does_it_work.HowDoesItWorkPart1Subtitle'
                        description='The subtitle of step one of the how does it work flow for Nike'
                        defaultMessage='Step 1'
                    />
                </h3>
                <p className='text-center'>
                    <span className='font-weight-bold'>
                        <FormattedMessage
                            id='views.nike.how_does_it_work.HowDoesItWorkPart1ActionMessage'
                            description='The action message of step one of the how does it work flow for Nike'
                            defaultMessage='Take out your Nike badge.'
                        />
                    </span>
                    <br />
                    <FormattedMessage
                        id='views.nike.how_does_it_work.HowDoesItWorkPart1AdditionalMessage'
                        description='The additional message of step one of the how does it work flow for Nike'
                        defaultMessage="Don't have one? Contact your team coach for solving this issue."
                    />
                </p>
                <button
                    className='primary-button mb-4 mb-xl-1'
                    onClick={props.onNext}>
                    Next step
                </button>
            </div>
        </BaseView>
    );
}

export default HowDoesItWorkPart1View;
