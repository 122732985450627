import React from 'react';

import { ReactComponent as ErrorIcon } from '../../static/nike/error-icon.svg';
import BaseView from './BaseView';

export interface ErrorViewProps {
    navbarItems?: React.ReactNode;
    title?: React.ReactNode;
    message?: React.ReactNode;
    hideErrorIcon?: boolean;
    onInactivity?: () => void;
}

function ErrorView(props: ErrorViewProps) {
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={props.navbarItems}>
            <div className='row justify-content-center'>
                <div className='col-xl-6 col-12'>
                    {props.hideErrorIcon !== true ? (
                        <div className='mt-4 mt-xl-1 d-flex flex-column align-items-center justify-content-start'>
                            <ErrorIcon className='img-fluid w-50' />
                        </div>
                    ) : undefined}
                </div>
            </div>
            <h3 className='text-center mt-3 mt-xl-2'>{props.title}</h3>
            <p className='text-center'>{props.message}</p>
        </BaseView>
    );
}

export default ErrorView;
