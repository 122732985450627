import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { Contact } from './api/contacts';
import { Transaction, TransactionContact, TransactionStatus } from './common/transactions';

export const DAY_MS = 24 * 60 * 60 * 1000;

export function getRandomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZancdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => window.setTimeout(resolve, ms));
}

export function getFullName(contact?: Contact | TransactionContact | null) {
    if (!contact) return '';
    return `${contact.first_name} ${contact.last_name}`.trim();
}

export function getTransactionSenderFullName(transaction: Transaction) {
    if (transaction.sender) {
        return getFullName(transaction.sender);
    }
    if (transaction.sender_group) {
        return transaction.sender_group.name;
    }
    return '';
}

export function getTransactionReceiverFullName(transaction: Transaction): string {
    if (transaction.receiver) {
        return getFullName(transaction.receiver);
    }
    if (transaction.receiver_group) {
        return transaction.receiver_group.name;
    }
    return '';
}

const humanReadableTransactionStatusMap = new Map<TransactionStatus, JSX.Element>();
humanReadableTransactionStatusMap.set(
    TransactionStatus.NEW,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.NEW'
        description='The human readable name for the NEW (TRS000001) transaction status'
        defaultMessage='Initializing'
    />
);
humanReadableTransactionStatusMap.set(
    TransactionStatus.READY_FOR_DROPOFF,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.READY_FOR_DROPOFF'
        description='The human readable name for the READY_FOR_DROPOFF (TRS000002) transaction status'
        defaultMessage='Ready for Dropoff'
    />
);
humanReadableTransactionStatusMap.set(
    TransactionStatus.DROPOFF_IN_PROGRESS,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.DROPOFF_DONE'
        description='The human readable name for the DROPOFF_DONE (TRS000003) transaction status'
        defaultMessage='Dropoff done'
    />
);
humanReadableTransactionStatusMap.set(
    TransactionStatus.READY_FOR_PICKUP,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.READY_FOR_PICKUP'
        description='The human readable name for the READY_FOR_PICKUP (TRS000004) transaction status'
        defaultMessage='Ready for Pickup'
    />
);
humanReadableTransactionStatusMap.set(
    TransactionStatus.PICKUP_IN_PROGRESS,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.PICKUP_DONE'
        description='The human readable name for the PICKUP_DONE (TRS000005) transaction status'
        defaultMessage='Pickup done'
    />
);
humanReadableTransactionStatusMap.set(
    TransactionStatus.COMPLETED,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.COMPLETED'
        description='The human readable name for the COMPLETED (TRS000006) transaction status'
        defaultMessage='Transaction completed'
    />
);
humanReadableTransactionStatusMap.set(
    TransactionStatus.CANCELLED,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.CANCELLED'
        description='The human readable name for the CANCELLED (TRS000099) transaction status'
        defaultMessage='Transaction cancelled'
    />
);
humanReadableTransactionStatusMap.set(
    TransactionStatus.ERROR,
    <FormattedMessage
        id='utils.getHumanReadableTransactionStatus.ERROR'
        description='The human readable name for the ERROR (TRS000899) transaction status'
        defaultMessage='Transaction error'
    />
);
export function getHumanReadableTransactionStatus(status: TransactionStatus) {
    const readableMessage = humanReadableTransactionStatusMap.get(status);
    if (readableMessage) {
        return readableMessage;
    } else {
        return (
            <FormattedMessage
                id='utils.getHumanReadableTransactionStatus.unknown_state'
                description='The human readable status message returned when the transaction is in an unknown state'
                defaultMessage='Unknown transaction state'
            />
        );
    }
}

export function formatTimeDelta(value: number) {
    const MINUTE = 60;
    const HOUR = 60 * 60;

    if (value < MINUTE) {
        const values = {
            value: value
        };
        return (
            <FormattedMessage
                id='views.LockoutView.secondsFormat'
                description='The seconds part of time duration messages (eg: locked out for x seconds)'
                defaultMessage='{value, plural, one {1 second} other {{value} seconds}}'
                values={values}
            />
        );
    } else if (value < HOUR) {
        const minutes = Math.floor(value / MINUTE);
        const seconds = Math.floor(value % MINUTE);

        const values = {
            minutes: minutes,
            seconds: seconds
        };
        return (
            <FormattedMessage
                id='views.LockoutView.minutesSecondsFormat'
                description='The minutes + seconds part of time duration messages (eg: locked out for x minutes, x seconds)'
                defaultMessage='{minutes, plural, one {1 minute} other {{minutes} minutes}}{seconds, plural, =0 {} one {, 1 second} other {, {seconds} seconds}}'
                values={values}
            />
        );
    } else {
        const hours = Math.floor(value / HOUR);
        const minutes = Math.floor(value / MINUTE);
        const seconds = Math.floor(value % MINUTE);

        const values = {
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
        return (
            <FormattedMessage
                id='views.LockoutView.hoursMinutesSecondsFormat'
                description='The hours + minutes + seconds part of time duration messages (eg: locked out for x hours, x minutes, x seconds)'
                defaultMessage='{hours, plural, one {1 hour} other {{hours} hours}}{minutes, plural, =0 {} one {, 1 minute} other {, {minutes} minutes}}{seconds, plural, =0 {} one {, 1 second} other {, {seconds} seconds}}'
                values={values}
            />
        );
    }
}

export function formatDatetime(date: Date | string | null): React.ReactNode {
    if (!date) return undefined;

    const parsedDate = new Date(date);
    return (
        <span>
            <FormattedDate
                value={parsedDate}
                dateStyle='medium'
            />
            &nbsp;
            <FormattedTime
                value={parsedDate}
                timeStyle='short'
            />
        </span>
    );
}

export function isNfcValue(value: string): boolean {
    return value.endsWith(' 90 00');
}

export function isNikeBadgeValue(value: string): boolean {
    // Nike badges always contain 4 pairs of hex characters + 90 00 terminator hex, separated by spaces.
    // This check catches non-Nike badges but also resolves the issue when the badge is not held long enough to the
    // reader, resulting in a shorter badge string.
    return isNfcValue(value) && value.length === 17;
}

export function normalizeNfcValue(value: string): string {
    if (isNfcValue(value)) {
        return value.slice(0, value.length - 6);
    }
    return value;
}
