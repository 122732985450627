import { useState } from 'react';

import ActionCard from '../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../components/domain/AllowedAction';
import ActionCardGridElement from '../../components/elements/ActionCardGridElement';
import useActivity from '../../hooks/useActivity';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ConfigureLightsAction from '../Actions/Admin/ConfigureLightsAction';
import { PrinterManagementAction } from '../Actions/Admin/PrinterManagementAction';
import TerminalConfigAction from '../UserSpecificActions/TerminalConfigAction';
import ContentManagementAction from '../contentManagement/ContentManagementAction';

type AdminWorkflowProps = AllowedActionViewProps;

const AdminWorkflow = (props: AdminWorkflowProps) => {
    const [, newActivity] = useActivity();
    const [customView, changeCustomView] = useState<JSX.Element | undefined>();

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const onSelect = (action: ActionCard) => {
        newActivity();
        if (action.view) {
            changeCustomView(action.view);
        } else {
            action.onAction && action.onAction();
        }
    };

    let content = <></>;
    if (customView === undefined) {
        content = (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}>
                <h3 className='text-center'>Admin</h3>
                <ActionCardGridElement
                    actions={[
                        ContentManagementAction({
                            contact: props.contact,
                            onBack: () => changeCustomView(undefined),
                            onHome: props.onHome,
                            onInactivity: props.onInactivity
                        }),
                        TerminalConfigAction({
                            contact: props.contact,
                            onBack: () => changeCustomView(undefined),
                            onHome: props.onHome,
                            onInactivity: props.onInactivity
                        }),
                        ConfigureLightsAction({
                            contact: props.contact,
                            onBack: () => changeCustomView(undefined),
                            onHome: props.onHome,
                            onInactivity: props.onInactivity
                        }),
                        PrinterManagementAction({
                            contact: props.contact,
                            onBack: () => changeCustomView(undefined),
                            onHome: props.onHome,
                            onInactivity: props.onInactivity
                        })
                    ]}
                    onSelectAction={onSelect}
                />
            </BaseView>
        );
    } else if (customView) {
        content = customView;
    }
    return content;
};

export default AdminWorkflow;
