import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, useQuery, useQueryClient } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { Terminal, isTerminal } from './terminals';
import { FetchOptions } from './utils';

export interface Shop {
    id: number | string;
    url: string;
    account: number | string;
    name: string;
    products: string;
}

export const isShop = (shop: any): shop is Shop => {
    return (shop as Shop).url !== undefined && (shop as Shop).url.includes('/shops/');
};

enum ShopsQueryParams {
    TERMINAL = 'terminal'
}

const shopsViewSet: ApiViewSet = {
    baseName: 'shops'
};

export interface ShopsOptions {
    terminal?: Terminal | string | number | null;
}

const defaultConfig = {
    staleTime: Infinity
};

function fetchShopsApi(options?: ShopsOptions, fetchOptions?: FetchOptions): () => Promise<Shop[]> {
    const terminalId = options && options.terminal ? (isTerminal(options.terminal) ? options.terminal.id : options.terminal).toString() : undefined;
    return apiList<Shop, ShopsQueryParams>(
        shopsViewSet,
        {
            terminal: terminalId
        },
        fetchOptions
    );
}

function fetchShopApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<Shop> {
    return apiDetail<Shop>(shopsViewSet, options, fetchOptions);
}

export async function prefetchShops(queryClient: QueryClient, terminal: Terminal, options?: FetchQueryOptions<Shop[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...options
    };

    const shops = await queryClient.fetchQuery(['shops', terminal.id.toString()], fetchShopsApi({ terminal: terminal }, fetchOptions), config);
    // Set the shop detail caches as well
    for (const shop of shops) {
        queryClient.setQueryData(['shop', shop.id], shop);
    }
    return shops;
}

export function useShops(options?: ShopsOptions, queryOptions?: UseQueryOptions<Shop[]>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();

    const config = {
        ...defaultConfig,
        onSuccess: (shops: Shop[]) => {
            // Set the shop detail cache as well
            for (const shop of shops) {
                queryClient.setQueryData(['shop', shop.id], shop);
            }
        },
        ...queryOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    const terminalId = options?.terminal ? (isTerminal(options.terminal) ? options.terminal.id : options.terminal).toString() : undefined;
    return useQuery<Shop[]>(['shops', terminalId], fetchShopsApi(options, fetchOptions), config);
}

export function getShopQueryOptions(shopId?: string, options?: UseQueryOptions<Shop>, fetchOptions?: FetchOptions): UseQueryOptions<Shop> {
    const config = {
        ...defaultConfig,
        enabled: !!shopId,
        ...options
    };

    return {
        queryKey: ['shop', shopId],
        queryFn: fetchShopApi({ id: shopId ? shopId : '' }, fetchOptions),
        ...config
    };
}

export function useShop(shopId?: string, options?: UseQueryOptions<Shop>, fetchOptions?: FetchOptions) {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Shop>(getShopQueryOptions(shopId, options, fetchOptions));
}
