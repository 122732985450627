import { useCallback, useEffect, useMemo, useState } from 'react';

import { Product } from '../../api/products';
import { Slot, SlotForTransaction, useSlots } from '../../api/slots';
import { Spot, useSpotArray } from '../../api/spots';
import { useTransactions } from '../../api/transactions';
import { isStockSlot } from '../../services/slot/SlotFilter';
import { ProductAvailability, ProductAvailabilityResult } from './VendingWorkflow';
import { NikeRole, isNikeSpotData } from './api';
import { transformSlotsToIdMap, transformTransactionsToSlotMap } from './utils';

interface SpotAvailabilityFinderProps {
    propsOnAvailabilityChange: (availability: ProductAvailabilityResult) => void;
    product: Product;
    role?: NikeRole | null;
    spot?: Spot;
    checkNearby?: boolean;
}

export const SpotAvailabilityFinder = (props: SpotAvailabilityFinderProps) => {
    const checkNearby = props.checkNearby === true;

    const [currentAvailability, changeCurrentAvailability] = useState<ProductAvailabilityResult>({
        availability: ProductAvailability.UNKNOWN
    });
    const [nearbySpotAvailability, changeNearbySpotAvailability] = useState<Map<Spot, ProductAvailabilityResult>>(new Map());

    const { data: transactions } = useTransactions({ spot: props.spot?.id });
    const slotResults = useSlots(
        {
            spot: props.spot?.id
        },
        { enabled: !!props.spot }
    );

    const nearbySpotIds = checkNearby && props.spot && isNikeSpotData(props.spot.additional_data) ? props.spot.additional_data.nearby_spot_ids : [];
    const nearbySpotResults = useSpotArray(nearbySpotIds);

    // Reduce slotResults to map of slotId -> slot
    const slotsIdMap = useMemo(() => {
        if (!slotResults.isSuccess || !slotResults.data) {
            return new Map<string, Slot>();
        }
        return transformSlotsToIdMap(slotResults.data);
    }, [slotResults.data, slotResults.isSuccess]);

    // Transform slotResults to Map of slotId: SlotForTransaction
    const slotsMap = useMemo(() => {
        return transformTransactionsToSlotMap(slotsIdMap, transactions);
    }, [slotsIdMap, transactions]);

    const vendingTransactionsForProduct = useMemo(() => {
        if (!transactions) return [];
        // Find all vending transactions that contain the product
        return transactions.filter((transaction) => {
            return (
                !(transaction.receiver || transaction.receiver_group) && // Vending transactions don't have a receiver
                transaction.product_instances.some((productInstance) => productInstance.product === props.product.id)
            );
        });
    }, [transactions, props.product.id]);

    const vendingTransactionsWithSlot: SlotForTransaction[] = useMemo(() => {
        // So we found this product in the SPOT. Now map to the Slot info to see if it is in a stock slot or a regular one.
        return vendingTransactionsForProduct
            .map((transaction) => {
                let slotInfo: SlotForTransaction | undefined;
                if (transaction.slot_id) {
                    const slotsInfo = slotsMap.get(transaction.slot_id);
                    if (slotsInfo && slotsInfo.length > 0) {
                        slotInfo = slotsInfo[0];
                    }
                }
                return slotInfo;
            })
            .filter((transaction): transaction is SlotForTransaction => transaction !== undefined);
    }, [vendingTransactionsForProduct, slotsMap]);

    const [vendingTransactionsRegular, vendingTransactionsInStock] = useMemo(() => {
        return vendingTransactionsWithSlot.reduce(
            (result, slot) => {
                const isStock = isStockSlot(slot);
                if (!isStock) {
                    return [result[0].concat([slot]), result[1]];
                } else {
                    return [result[0], result[1].concat([slot])];
                }
            },
            [new Array<SlotForTransaction>(), new Array<SlotForTransaction>()]
        );
    }, [vendingTransactionsWithSlot]);

    const determineAvailability = useCallback(() => {
        if (!transactions || !slotResults.isSuccess) {
            return {
                availability: ProductAvailability.UNKNOWN
            };
        }

        // Now, if there are regular transactions, return available
        if (vendingTransactionsRegular.length > 0) {
            return {
                availability: ProductAvailability.AVAILABLE,
                slotsWithTransaction: vendingTransactionsRegular
            };
        }

        // Now we should check if it is available in another nearby Spot, in which case this has priority over using stock.
        const allNearbySpotResultsDone = nearbySpotResults.every((nearbySpotResult) => {
            return nearbySpotResult.isSuccess || nearbySpotResult.isError;
        });
        if (!allNearbySpotResultsDone) {
            return {
                availability: ProductAvailability.UNKNOWN
            };
        }

        const otherSpotsAvailability = nearbySpotResults.map((nearbySpotResult) => {
            return nearbySpotResult.data ? nearbySpotAvailability.get(nearbySpotResult.data) : undefined;
        });

        // Aggregate all results from other spots based on availability
        const otherSpotsAvailabilityMap = otherSpotsAvailability.reduce((result, availabilityResult) => {
            if (availabilityResult) {
                const currentSlotsForAvailability = result.get(availabilityResult.availability);
                const additionalSlotsForAvailability = availabilityResult.slotsWithTransaction ? availabilityResult.slotsWithTransaction : [];
                const slotsForAvailability = (currentSlotsForAvailability ? currentSlotsForAvailability : []).concat(additionalSlotsForAvailability);
                return result.set(availabilityResult.availability, slotsForAvailability);
            }
            return result;
        }, new Map<ProductAvailability, SlotForTransaction[]>());

        // If it is available in another spot, return that information.
        if (otherSpotsAvailabilityMap.has(ProductAvailability.AVAILABLE)) {
            return {
                availability: ProductAvailability.AVAILABLE_OTHER_SPOT,
                slotsWithTransaction: otherSpotsAvailabilityMap.get(ProductAvailability.AVAILABLE)
            };
        }

        // Now fall back to returning if available in current Spot stock.
        if (vendingTransactionsInStock.length > 0) {
            // Account for the fact that a lookup in another (regular) Slot might have failed
            const slotLookupError = false; // This is no longer possible, there is only one query left.
            if (slotLookupError) {
                return {
                    availability: ProductAvailability.ERROR_AVAILABLE_IN_STOCK,
                    slotsWithTransaction: vendingTransactionsInStock
                };
            } else {
                return {
                    availability: ProductAvailability.AVAILABLE_IN_STOCK,
                    slotsWithTransaction: vendingTransactionsInStock
                };
            }
        }

        // Last option is in stock in another Spot (even if there was an error)
        if (
            otherSpotsAvailabilityMap.has(ProductAvailability.AVAILABLE_IN_STOCK) ||
            otherSpotsAvailabilityMap.has(ProductAvailability.ERROR_AVAILABLE_IN_STOCK)
        ) {
            const otherInStock = otherSpotsAvailabilityMap.get(ProductAvailability.AVAILABLE_IN_STOCK);
            const otherInStockWithError = otherSpotsAvailabilityMap.get(ProductAvailability.ERROR_AVAILABLE_IN_STOCK);

            return {
                availability: ProductAvailability.AVAILABLE_IN_STOCK_OTHER_SPOT,
                slotsWithTransaction: (otherInStock ? otherInStock : []).concat(otherInStockWithError ? otherInStockWithError : [])
            };
        }

        const nearbyLookupError = otherSpotsAvailability.some((availability) => {
            return availability === undefined;
        });
        // If we are here, this means there was nothing in this Spot or any nearby Spot found and all queries are done.
        // If any lookup result returned an error, return an error state.
        if (nearbyLookupError) {
            return {
                availability: ProductAvailability.ERROR
            };
        }

        // Now we are sure it is not available
        return {
            availability: ProductAvailability.NOT_AVAILABLE
        };
    }, [transactions, slotResults, vendingTransactionsRegular, nearbySpotResults, nearbySpotAvailability, vendingTransactionsInStock]);

    const { propsOnAvailabilityChange } = props;

    useEffect(() => {
        const availabilityResult = determineAvailability();
        if (availabilityResult.availability !== currentAvailability.availability) {
            changeCurrentAvailability(availabilityResult);
            propsOnAvailabilityChange(availabilityResult);
        }
    }, [determineAvailability, currentAvailability.availability, propsOnAvailabilityChange]);

    const nearbySpotAvailabilityChange = (spot: Spot, availability: ProductAvailabilityResult) => {
        changeNearbySpotAvailability((previousNearbySpotAvailability) => {
            const newNearbySpotAvailability = new Map(previousNearbySpotAvailability);
            newNearbySpotAvailability.set(spot, availability);
            return newNearbySpotAvailability;
        });
    };

    return (
        <>
            {nearbySpotResults.map((nearbySpotResult, index) => {
                return (
                    <SpotAvailabilityFinder
                        key={nearbySpotResult.data ? nearbySpotResult.data.id : `index-${index}`}
                        spot={nearbySpotResult.data}
                        product={props.product}
                        propsOnAvailabilityChange={(availability) => {
                            nearbySpotAvailabilityChange(nearbySpotResult.data!, availability);
                        }}
                        checkNearby={false}
                        role={props.role}
                    />
                );
            })}
        </>
    );
};
