import Printer from '../../printing/Printer';
import PrinterQueue from '../../printing/PrinterQueue';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand } from '../useManagedWebsocket';

export class WebsocketClearPrinterQueueCommand extends WebsocketCommand {
    public async execute(): Promise<WebsocketCommandResponse> {
        await Promise.all([Printer.getInstance().cancelAll(), PrinterQueue.clearQueue()]);

        return {
            success: true,
            send: true
        };
    }
}
