import React from 'react';

export enum CallToActionType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    OUTLINE_PRIMARY = 'outline-primary',
    OUTLINE_SUCCESS = 'outline-success'
}

export enum CallToActionSize {
    LARGE = 'lg',
    NORMAL = 'normal',
    SMALL = 'sm'
}

export default interface CallToAction {
    message: React.ReactNode;
    type?: CallToActionType;
    size?: CallToActionSize;
    onClick?: () => void;
}
