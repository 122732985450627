import { Logger } from '../logs/Logger';
import { IndexedDbStores, getStoreConnection } from '../services/indexedDb/IndexedDB';
import { WebsocketPrintLabelCommand } from '../websocket/commands/WebsocketPrintLabelCommand';
import { WebsocketPrinterStatusCommand } from '../websocket/commands/WebsocketPrinterStatusCommand';
import { WebsocketCommand } from '../websocket/useManagedWebsocket';
import { PRINTER_JOB_ADDED_EVENT } from './Printer';
import PrinterException from './exceptions/PrinterException';

const RELEVANT_UNTIL_MILI_SECONDS = 3 * 60 * 1000;

interface Job extends WebsocketCommand {
    relevantUntil: number;
}

export default class PrinterQueue {
    static addJob = async (job: WebsocketPrintLabelCommand | WebsocketPrinterStatusCommand): Promise<number> => {
        const store = await getStoreConnection(IndexedDbStores.PRINTER_QUEUE, 'readwrite');

        const relevantUntil = Date.now() + RELEVANT_UNTIL_MILI_SECONDS;
        const request = store.put({
            ...job,
            relevantUntil: relevantUntil
        });

        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                if (request.result !== undefined) {
                    window.dispatchEvent(new CustomEvent(PRINTER_JOB_ADDED_EVENT, { detail: relevantUntil }));
                    resolve(relevantUntil);
                } else throw new PrinterException('Job was not saved in the queue.');
            };

            request.onerror = () => {
                throw new PrinterException('Something went wrong trying to save this job into the queue.');
            };
        });
    };

    static retrieveNextJob = async (): Promise<Job | undefined> => {
        const store = await getStoreConnection(IndexedDbStores.PRINTER_QUEUE, 'readonly');
        const request = store.openCursor();
        const records: Job[] = [];

        return new Promise((resolve, reject) => {
            request.onsuccess = (event: any) => {
                const cursor = event.target.result;
                if (cursor) {
                    records.push(cursor.value);
                    cursor.continue();
                } else {
                    records.sort((a, b) => a.relevantUntil - b.relevantUntil);
                    if (records.length === 0) {
                        Logger.log('No more Jobs for the printer.');
                        resolve(undefined);
                    } else resolve(records[0] as unknown as Job);
                }
            };

            request.onerror = () => {
                throw new PrinterException('Something went wrong trying to retrieve a job from the Printing queue.');
            };
        });
    };

    static getAllJobs = async (): Promise<WebsocketCommand[] | undefined> => {
        const store = await getStoreConnection(IndexedDbStores.PRINTER_QUEUE, 'readonly');
        const request = store.getAll();

        return new Promise((resolve) => {
            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = () => {
                resolve(undefined);
            };
        });
    };

    static removeById = async (timestamp: number): Promise<void> => {
        const store = await getStoreConnection(IndexedDbStores.PRINTER_QUEUE, 'readwrite');
        store.delete(timestamp);
    };

    static gotoNext = async (timestamp: number): Promise<void> => {
        await this.removeById(timestamp);
        let next = await this.retrieveNextJob();

        while (next !== undefined) {
            if (next.relevantUntil > Date.now()) {
                window.dispatchEvent(new CustomEvent(next.type + ':' + next.relevantUntil));
                break;
            } else {
                await this.removeById(next.relevantUntil);
                next = await this.retrieveNextJob();
            }
        }
    };

    static clearQueue = async (): Promise<void> => {
        const store = await getStoreConnection(IndexedDbStores.PRINTER_QUEUE, 'readwrite');
        store.clear();
    };
}
