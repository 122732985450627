import crypto from 'crypto';

import { ProtonPacketHeader } from './ProtonPacketHeader';

export class ProtonPacket {
    ext;
    type;
    actionVersion = 'V0.1';
    protocolVer = 1;
    payload: any;
    header;
    priority = 3;
    traceId;

    constructor(action: string, type: 'response' | 'request', payload?: any, trace?: string) {
        this.header = new ProtonPacketHeader(action, trace);
        this.type = type;
        if (type === 'request') {
            this.payload = payload;
            this.traceId = crypto.randomBytes(13).toString('hex');
            this.ext = {
                appType: 'terminal'
            };
        } else {
            this.ext = {
                bindingVer: 1,
                deviceVer: 1,
                resCode: 200,
                resMsg: 'OK'
            };
        }
    }

    getType() {
        return this.type;
    }

    getProtocolVer() {
        return this.protocolVer;
    }

    getHeader() {
        return this.header;
    }
}
