import { useContext } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { TerminalContext } from '../../../TerminalInit';
import { CreateTransaction, CreateTransactionStatus, NotificationsConfig, Transaction, TransactionType } from '../../../common/transactions';
import { Logger } from '../../../logs/Logger';
import { sleep } from '../../../utils';
import { ApiError, FetchOptions, fetchApi, postApi } from '../../utils';

export interface BulkCreateTransactionsVariables {
    body: CreateTransaction & {
        quantity: number;
    };
}

export interface BulkTransactionsResponse {
    id: string;
    url: string;
    notification_time_for_scheduled_transaction: null;
    expected_dropoff_time: null;
    expected_pickup_time: null;
    close_transaction_at_expected_pickup_time: null;
    tt_number: string;
    shipping_notes: string;
    customer_ref: string;
    status: CreateTransactionStatus;
    type: TransactionType;
    callback_url: string;
    notifications_config: NotificationsConfig;
    state: 'init' | 'completed' | 'pending' | 'error';
    quantity: number;
    created_at: Date | null;
    completed_at: Date | null;
    fast_transition?: boolean;
}

export function useMutateBulkCreateTransactions(
    options?: UseMutationOptions<Transaction[], unknown, BulkCreateTransactionsVariables[]>,
    fetchOptions?: FetchOptions
) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<Transaction[], unknown, BulkCreateTransactionsVariables[]> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['transactions']);
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(bulkCreateTransactions(fetchOptions), config);
}

export function bulkCreateTransactions(options?: FetchOptions): (variables: BulkCreateTransactionsVariables[]) => Promise<Transaction[]> {
    return async (variables: BulkCreateTransactionsVariables[]): Promise<Transaction[]> => {
        const bulkResults = await Promise.all(
            variables.map(async (body) => {
                const response = await postApi(`/transactions/bulk/`, body.body, options);
                if (!response.ok) {
                    let json;
                    try {
                        json = await response.json();
                    } catch (e) {
                        throw new ApiError('Error trying to bulk create transactions');
                    }
                    throw new ApiError('Error trying to bulk create transactions', json);
                }
                return (await response.json()) as BulkTransactionsResponse;
            })
        );
        let statuses = await Promise.all(
            bulkResults.map(async (bulkResult) => {
                let bulkStatus = bulkResult;
                while (bulkStatus.state === 'pending' || bulkStatus.state === 'init') {
                    await sleep(500);
                    const fetchBulkResponse = await fetchApi(`/transactions/bulk/${bulkStatus.id}/`, {}, options);
                    if (!fetchBulkResponse.ok) {
                        let json;
                        try {
                            json = await fetchBulkResponse.json();
                        } catch (e) {
                            throw new ApiError('Error trying to get the bulk create transactions status');
                        }
                        throw new ApiError('Error trying to get the bulk create transactions status', json);
                    }
                    bulkStatus = await fetchBulkResponse.json();
                }
                return bulkStatus;
            })
        );

        statuses = statuses.filter((status) => {
            if (status.state === 'error') {
                Logger.error('Bulk create transactions went into the error state.', {}, status);
                return false;
            } else {
                return true;
            }
        });

        const transactions = await Promise.all(
            statuses.map(async (status) => {
                const trResponse = await fetchApi(`/transactions/?bulk_transaction=${status.id}&status=${status.status}`);
                if (!trResponse.ok) {
                    let json;
                    try {
                        json = await trResponse.json();
                    } catch (e) {
                        throw new ApiError('Error trying to get the resulting transaction from the bulk create.');
                    }
                    throw new ApiError('Error trying to get the resulting transaction from the bulk create.', json);
                }
                return (await trResponse.json()) as Transaction[];
            })
        );
        return transactions.flat();
    };
}
