import { SpotLayoutItem } from '../../api/spotLayoutItems';

interface SpotLayoutItemProps {
    label?: string;
    spotLayoutItem: SpotLayoutItem;
    spotWidth: number;
    spotHeight: number;
    highlighted: boolean;
    selectable: boolean;
    offset?: { X: number; Y: number };
    onSelect?: () => void;
}

const SpotLayoutItemElement = (props: SpotLayoutItemProps) => {
    const origin_x = props.offset !== undefined ? props.spotLayoutItem.origin_x - props.offset.X : props.spotLayoutItem.origin_x;
    const origin_y = props.offset !== undefined ? props.spotLayoutItem.origin_y + props.offset.Y : props.spotLayoutItem.origin_y;
    const style: React.CSSProperties = {
        position: 'absolute',
        left: `${(100 * origin_x) / props.spotWidth}%`,
        right: `${100 - (100 * (origin_x + props.spotLayoutItem.width)) / props.spotWidth}%`,
        bottom: `${(100 * origin_y) / props.spotHeight}%`,
        top: `${100 - (100 * (origin_y + props.spotLayoutItem.height)) / props.spotHeight}%`
    };

    const labelCore = props.label ? props.label : props.spotLayoutItem.name;
    const label = (
        <small className='spotlayoutitem-label'>
            {labelCore}
            {process.env.REACT_APP_SHOW_HARDWARE_IDS === '1' ? '\t' + props.spotLayoutItem.hardware_id : ''}
            {process.env.REACT_APP_SHOW_SLOT_IDS === '1' ? '\t' + props.spotLayoutItem.slot : ''}
        </small>
    );

    if (props.spotLayoutItem.terminal) {
        return (
            <div
                style={style}
                className='spotlayoutitem-terminal'></div>
        );
    } else if (props.highlighted) {
        //does contain an item (or is pudo select)
        return (
            <div
                style={style}
                className='spotlayoutitem-highlighted'
                onClick={() => {
                    if (props.onSelect) {
                        props.onSelect();
                    }
                }}>
                {label}
            </div>
        );
    } else if (props.selectable) {
        //does not contain an item
        return (
            <div
                style={style}
                className='spotlayoutitem-selectable'
                onClick={() => {
                    if (props.onSelect) {
                        props.onSelect();
                    }
                }}>
                {label}
            </div>
        );
    } else {
        //non clickable
        return (
            <div
                style={style}
                className='spotlayoutitem-unavailable'>
                <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{ height: '100%' }}>
                    {label}
                </div>
            </div>
        );
    }
};

export default SpotLayoutItemElement;
