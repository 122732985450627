import { Logger } from '../../logs/Logger';
import Printer, { PrinterStatus } from '../../printing/Printer';
import PrinterQueue from '../../printing/PrinterQueue';
import PrinterException from '../../printing/exceptions/PrinterException';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandType } from '../useManagedWebsocket';

export class WebsocketPrinterStatusCommand extends WebsocketCommand {
    public async execute(): Promise<WebsocketCommandResponse> {
        const processId = await PrinterQueue.addJob(this);
        return new Promise((resolve, reject) => {
            const listener = async () => {
                clearTimeout(timeout);
                removeListener();
                let result: PrinterStatus | undefined;
                try {
                    result = await Printer.getInstance().getPrinterStatus(processId);
                    Logger.log('Printer status received.', {}, result);
                } catch (error) {
                    Logger.error((error as PrinterException).message, {}, error);
                    result = {
                        error: (error as PrinterException).message
                    };
                    PrinterQueue.gotoNext(processId);
                }

                if (result.error != null) resolve({ success: false, message: result.error, send: true });
                else
                    resolve({
                        success: true,
                        message: result,
                        send: true
                    });
            };

            const removeListener = () => {
                window.removeEventListener(WebsocketCommandType.PRINTER_STATUS + ':' + processId, listener);
            };
            window.addEventListener(WebsocketCommandType.PRINTER_STATUS + ':' + processId, listener);

            const timeout = setTimeout(() => {
                Logger.error('EventListenerTimeout: Job no longer relevant.', {}, this);
                removeListener();
                resolve({
                    success: false,
                    message: 'Job waited in queue for to long.',
                    send: true
                });
                clearTimeout(timeout);
                PrinterQueue.removeById(processId);
            }, processId - Date.now());
        });
    }
}
