import Color from 'color';
import Fuse from 'fuse.js';
import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { ContactGroup, useContactGroup, useContactGroups } from '../api/contactGroups';
import useActivity from '../hooks/useActivity';
import BackAndHomeNavigationButtons from './common/BackAndHomeNavigationButtons';
import BaseView from './common/BaseView';

interface SenderSelectViewProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onSelect?: (sender: ContactGroup, senderHint?: string) => void;
}

const listsenders = (senders?: ContactGroup[], onSelect?: (sender: ContactGroup) => void) => {
    if (senders) {
        const cols = 2;
        const chunkedsenders: Array<Array<ContactGroup | null>> = [];
        for (let i = 0; i < senders.length; i += cols) {
            const chunk: Array<ContactGroup | null> = senders.slice(i, i + cols);
            while (chunk.length < cols) {
                chunk.push(null);
            }
            chunkedsenders.push(chunk);
        }

        const senderStyle = (sender: ContactGroup) => {
            if (sender.group_color) {
                return {
                    background: `${sender.group_color} linear-gradient(180deg, ${Color(sender.group_color).lighten(0.15)}, ${sender.group_color}) repeat-x`
                };
            }
            return {};
        };
        const senderClass = (sender: ContactGroup) => {
            let className = 'btn-sender px-4 py-3';
            if (!sender.group_color) {
                className += ' btn-light';
            }
            return className;
        };

        return chunkedsenders.map((senders) => {
            return (
                <div
                    key={senders.map((c) => (c === null ? 'null' : c.id)).join('-')}
                    className='row'>
                    {senders.map((sender, index) => {
                        if (sender !== null) {
                            return (
                                <div
                                    key={sender.id}
                                    className='col py-3'>
                                    <button
                                        key={sender.id}
                                        className={senderClass(sender)}
                                        style={senderStyle(sender)}
                                        onClick={() => {
                                            if (onSelect) {
                                                onSelect(sender);
                                            }
                                        }}>
                                        {sender.group_logo ? (
                                            <img
                                                className='img-fluid'
                                                src={sender.group_logo}
                                                alt={`${sender.name} logo`}
                                            />
                                        ) : (
                                            <h1 className='mb-0'>{sender.name}</h1>
                                        )}
                                    </button>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={'null-' + index}
                                    className='col py-3'
                                />
                            );
                        }
                    })}
                </div>
            );
        });
    } else {
        return <p>No senders found. Did you configure them?</p>;
    }
};

const SenderSelectView = (props: SenderSelectViewProps) => {
    const [searchSender, changeSearchSender] = useState<string>('');

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: deliverersContactGroup } = useContactGroup(terminal?.delivery_contacts_url);
    const { data: otherSender } = useContactGroup(terminal?.delivery_contact_other_url);
    const { data: deliverers } = useContactGroups(deliverersContactGroup?.children, undefined, {
        enabled: !!deliverersContactGroup?.children
    });
    const intl = useIntl();
    const [, newActivity] = useActivity();

    const otherSenderName = otherSender ? otherSender.name : '';

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        newActivity();
        changeSearchSender(event.target.value);
    };

    // Do the actual fuse search
    const deliverersFilteredResults = useMemo(() => {
        if (!deliverers) {
            return {
                deliverers: [],
                fuseResults: []
            };
        }
        if (searchSender === '') {
            return {
                deliverers: deliverers,
                fuseResults: []
            };
        }

        const fuseOptions = {
            includeScore: true,
            keys: ['name'],
            fieldNormWeight: 1
        };
        const fuse = new Fuse(deliverers, fuseOptions);
        const fuseResults = fuse.search(searchSender);
        return {
            deliverers: fuseResults.map((v) => v.item),
            fuseResults: fuseResults
        };
    }, [searchSender, deliverers]);

    // Remove otherSender from deliverers, it should not be in there
    const deliverersFiltered = deliverersFilteredResults.deliverers.filter((deliverer) => !otherSender || deliverer.id !== otherSender.id);
    if (otherSender) {
        const otherSenderCopy = {
            ...otherSender,
            name: otherSenderName + (searchSender !== '' ? ': ' + searchSender : '')
        };
        deliverersFiltered.push(otherSenderCopy);
    }

    const onSelect = (sender: ContactGroup) => {
        if (props.onSelect) {
            if (otherSender && sender.id === otherSender.id) {
                props.onSelect(sender, searchSender);
            } else {
                props.onSelect(sender);
            }
        }
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <h3 className='mt-3 text-center'>
                <FormattedMessage
                    id='views.SenderSelectView.title'
                    description='The title on the PuDo sender (sender) select view'
                    defaultMessage='Select the sender'
                />
            </h3>
            <form>
                <input
                    placeholder={intl.formatMessage({
                        id: 'views.SenderSelectView.senderSearchFieldPlaceholder',
                        description: 'The input field placeholder text in the PuDo sender select search field.',
                        defaultMessage: 'Enter delivery sender name'
                    })}
                    className='form-control d-inline-block'
                    type='text'
                    value={searchSender}
                    onChange={handleSearchChange}
                />
            </form>
            <div className='mt-4'>{listsenders(deliverersFiltered, onSelect)}</div>
        </BaseView>
    );
};

export default SenderSelectView;
