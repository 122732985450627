import Fuse from 'fuse.js';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../../api/contacts';
import DefaultList from '../DefaultList';
import ContactListElement from './ContactListElement';

interface ContactSelectViewProps {
    onSelect: (contact: Contact) => void;
    contacts: Contact[];
}

const ContactSelectView = (props: ContactSelectViewProps) =>
    useMemo(() => {
        const fuseOptions = {
            includeScore: true,
            keys: ['first_name', 'last_name'],
            fieldNormWeight: 1
        };
        const fuse = new Fuse(props.contacts, fuseOptions);

        const filter = async (value: string): Promise<JSX.Element[]> => {
            return new Promise((resolve, reject) => {
                if (value === '') {
                    const result = props.contacts.map((contact) => {
                        return ContactListElement(contact, props.onSelect);
                    });
                    resolve(result);
                }
                const fuseResults = fuse.search(value);
                const result = fuseResults
                    .map((res) => res.item)
                    .map((contact) => {
                        return ContactListElement(contact, props.onSelect);
                    });
                resolve(result);
            });
        };

        return (
            <DefaultList
                elements={props.contacts.map((contact) => {
                    return ContactListElement(contact, props.onSelect);
                })}
                headers={[
                    <th>
                        <FormattedMessage
                            id='views.ContactSelectView.tableHeaderName'
                            description='The header of the name column on the PuDo contact (receiver) select view'
                            defaultMessage='Name'
                        />
                    </th>
                ]}
                asyncFilter={filter}
            />
        );
    }, [props.contacts]);

export default ContactSelectView;
