import { useContext, useMemo } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Product, useProducts } from '../../../api/products';
import { useShops } from '../../../api/shops';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { Transaction, TransactionType } from '../../../common/transactions';
import useTransactionsWithSlots from '../../../hooks/useTransactionsWithSlots';
import { isStockSlot } from '../../../services/slot/SlotFilter';
import { aggregateTransactionsByProduct } from '../../../workflow/nike/utils';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import LoadingView from '../../common/LoadingView';
import ProductInformationCompact from '../ProductInformationCompact';
import ViewTitle from '../ViewTitle';

interface StockOverviewProps {
    filterObject?: JSX.Element;
    transactionTypeFilter?: TransactionType[];
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onMarkTransactions?: (transactions: Transaction[]) => void;
}

function StockOverview(props: StockOverviewProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { isSuccess: transactionSlotsIsSuccess, data: transactionSlotsMap } = useTransactionsWithSlots({ spotId: spot?.id });

    const [productsTransactionsMapAvailableNonStock, transactionsWithoutProductAvailableNonStock] = useMemo(() => {
        if (transactionSlotsIsSuccess && transactionSlotsMap !== undefined && products) {
            const nonStockSlots = Array.from(transactionSlotsMap.values()).filter((slots) => {
                return slots.length > 0 && !isStockSlot(slots[0]);
            });

            const transactionsNonStock = nonStockSlots.flatMap((slots) => slots.map((slot) => slot.transaction));

            const availableTransactionsNonStock = transactionsNonStock.filter((t) => {
                if (props.transactionTypeFilter === undefined || props.transactionTypeFilter.length === 0) return false;
                return props.transactionTypeFilter?.includes(t.type);
            });
            return aggregateTransactionsByProduct(availableTransactionsNonStock, products);
        } else {
            return [new Map<Product, Transaction[]>(), []];
        }
    }, [transactionSlotsIsSuccess, transactionSlotsMap, products, props.transactionTypeFilter]);

    const [productsTransactionsMapAvailableStock, transactionsWithoutProductAvailableStock] = useMemo(() => {
        if (transactionSlotsIsSuccess && transactionSlotsMap !== undefined && products) {
            const stockSlots = Array.from(transactionSlotsMap.values()).filter((slots) => {
                return slots.length > 0 && isStockSlot(slots[0]);
            });
            const transactionsStock = stockSlots.flatMap((slots) => slots.map((slot) => slot.transaction));
            const availableTransactionsStock = transactionsStock.filter((t) => {
                if (props.transactionTypeFilter === undefined || props.transactionTypeFilter.length === 0) return false;
                return props.transactionTypeFilter?.includes(t.type);
            });
            return aggregateTransactionsByProduct(availableTransactionsStock, products);
        } else {
            return [new Map<Product, Transaction[]>(), []];
        }
    }, [transactionSlotsIsSuccess, transactionSlotsMap, products, props.transactionTypeFilter]);

    const homeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    if (!transactionSlotsIsSuccess || transactionSlotsMap === undefined) {
        return (
            <LoadingView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            {props.filterObject}
            <ViewTitle>Stock overview</ViewTitle>
            <div className='information-card'>
                <div>
                    <h5>Available items in non-stock slots</h5>
                    {productsTransactionsMapAvailableNonStock.size === 0 ? (
                        <p>No items found</p>
                    ) : (
                        Array.from(productsTransactionsMapAvailableNonStock.entries()).map((productItemMapping) => {
                            return (
                                <ProductInformationCompact
                                    key={productItemMapping[0].id}
                                    product={productItemMapping[0]}
                                    transactions={productItemMapping[1]}
                                    onMarkTransactions={() => (props.onMarkTransactions ? props.onMarkTransactions(productItemMapping[1]) : undefined)}
                                />
                            );
                        })
                    )}
                </div>
            </div>
            <div className='information-card'>
                <div>
                    <h5>Available items in stock slots</h5>
                    {productsTransactionsMapAvailableStock.size === 0 ? (
                        <p>No items found</p>
                    ) : (
                        Array.from(productsTransactionsMapAvailableStock.entries()).map((productItemMapping) => {
                            return (
                                <ProductInformationCompact
                                    key={productItemMapping[0].id}
                                    product={productItemMapping[0]}
                                    transactions={productItemMapping[1]}
                                    onMarkTransactions={() => (props.onMarkTransactions ? props.onMarkTransactions(productItemMapping[1]) : undefined)}
                                />
                            );
                        })
                    )}
                </div>
            </div>
            <div className='information-card'>
                <div>
                    <p>{[...transactionsWithoutProductAvailableNonStock, ...transactionsWithoutProductAvailableStock].length} transactions without product</p>
                </div>
            </div>
        </BaseView>
    );
}

export default StockOverview;
