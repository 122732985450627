import { useEffect, useState } from 'react';

import { ServiceWorkerInit, ServiceWorkerInitResult } from '../ServiceWorkerInit';
import { ServiceWorkerInitState } from '../TerminalInit';
import { Logger } from '../logs/Logger';
import { getRandomInt } from '../utils';

interface Props {
    serviceWorker: ServiceWorkerInit;
    initialServiceWorkerState: ServiceWorkerInitState;
    changeInitialServiceWorkerState: (value: ServiceWorkerInitState) => void;
}

export default function useTokenRetry(props: Props) {
    const [refreshRetries, changeRefreshRetries] = useState(0);
    const [timeToRetryRefresh, changeTimeToRetryRefresh] = useState<number | null>(null);

    const onServiceWorkerInitResponse = (result: ServiceWorkerInitResult) => {
        if (result.available) {
            if (result.requiresAuth) {
                props.changeInitialServiceWorkerState(ServiceWorkerInitState.REQUIRES_AUTH);
            } else if (result.refreshFailed) {
                props.changeInitialServiceWorkerState(ServiceWorkerInitState.REFRESH_FAILED);
            } else {
                changeRefreshRetries(0);
                props.changeInitialServiceWorkerState(ServiceWorkerInitState.DONE);
            }
        } else {
            props.changeInitialServiceWorkerState(ServiceWorkerInitState.UNAVAILABLE);
        }
    };

    useEffect(() => {
        if (props.initialServiceWorkerState === ServiceWorkerInitState.REFRESH_FAILED) {
            const currentRetry = refreshRetries + 1;
            Logger.log('Access token refresh failed, retry attempt ' + currentRetry);
            const retryDate = new Date();
            const baseBackoff = Math.min(currentRetry, 12) * 10;
            const retrySeconds = getRandomInt(baseBackoff, baseBackoff * 2);
            retryDate.setSeconds(retryDate.getSeconds() + retrySeconds);
            changeRefreshRetries(currentRetry);

            let recalculateTimer = 0;
            const recalculateTimeToRetry = () => {
                const now = new Date();
                if (retryDate < now) {
                    // Time is up, retry
                    props.changeInitialServiceWorkerState(ServiceWorkerInitState.RETRY_REFRESH);
                    props.serviceWorker
                        .retryRefresh()
                        .then(onServiceWorkerInitResponse)
                        .catch(() => {
                            props.changeInitialServiceWorkerState(ServiceWorkerInitState.ERROR);
                        });

                    changeTimeToRetryRefresh(null);
                    window.clearInterval(recalculateTimer);
                } else {
                    changeTimeToRetryRefresh(Math.round((retryDate.getTime() - now.getTime()) / 1000));
                }
            };
            recalculateTimer = window.setInterval(recalculateTimeToRetry, 1000);

            return () => {
                window.clearInterval(recalculateTimer);
            };
        }
    }, [props.initialServiceWorkerState]);

    return { timeToRetryRefresh, onServiceWorkerInitResponse } as const;
}
