import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { useProducts } from '../../api/products';
import { useShops } from '../../api/shops';
import { SpotLayoutItem, useSpotLayoutItems } from '../../api/spotLayoutItems';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { TerminalWorkflowType } from '../../api/terminals';
import { useMutateBulkCreateTransactions } from '../../api/transactions/bulk/BulkCreateTransaction';
import { CreateTransactionStatus, Transaction, TransactionType } from '../../common/transactions';
import { AllowedActionViewProps } from '../../components/domain/AllowedAction';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { sync } from '../../redux/ScalesWeightSlice';
import ScaleOverMaxWeightWarnings from '../../scales/ScaleOverMaxWeightWarnings';
import { isDelivery } from '../../services/contact/GlobalPermissions';
import { getRandomString } from '../../utils';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import Loader from '../../views/elements/Loader';
import ProductList from './ProductList';
import { SpotLayoutItemProductAmount } from './WarehouseWorkflow';

interface Props extends AllowedActionViewProps {
    contact: Contact;
}

export default function WarehouseDropoff(props: Props) {
    const dispatch = useAppDispatch();
    const scalesWeightState = useAppSelector((state) => state.scalesWeight);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    const bulkCreateTransactions = useMutateBulkCreateTransactions({
        onSuccess: (data) => {
            if (spotLayoutItems) {
                dispatch(sync({ changes: onSuccess(spotLayoutItems, data), dropoff: true }));
            }
            props.onHome && props.onHome();
        }
    });

    const [changes, changeChanges] = useState<SpotLayoutItemProductAmount[]>([]);

    useEffect(() => {
        const updatedValues: SpotLayoutItemProductAmount[] = [];
        if (spotLayoutItems) {
            Object.entries(scalesWeightState.difference).forEach(([spotLayoutItemId, amount]) => {
                const spotLayoutItem = spotLayoutItems.filter((i) => i.id.toString() === spotLayoutItemId.toString())[0];
                if (spotLayoutItem && spotLayoutItem.slot !== undefined) {
                    const product = products?.find((prod) => prod.slots.includes(+spotLayoutItem.slot!.replace('SLT', '')));
                    if (product && -amount > 0) {
                        updatedValues.push({
                            spotLayoutItem,
                            product,
                            amount: -amount
                        });
                    }
                }
            });
        }
        changeChanges(updatedValues);
    }, [scalesWeightState.difference, spotLayoutItems]);

    const onSubmit = () => {
        const result = changes.map((ch) => {
            return {
                body: {
                    account_id: spot!.account,
                    status: CreateTransactionStatus.READY_FOR_PICKUP,
                    slot_id: ch.spotLayoutItem.slot,
                    tt_number: getRandomString(20),
                    sender_id: props.contact.id,
                    product_ids: [ch.product.id],
                    type: TransactionType.VENDING,
                    quantity: ch.amount,
                    fast_transition: true
                }
            };
        });
        bulkCreateTransactions.mutate(result);
    };
    let content = (
        <div className='d-flex flex-column align-items-center'>
            <Loader />
        </div>
    );
    if (products && bulkCreateTransactions.isIdle) {
        content = (
            <>
                {getTotalAmount(changes) === 0 ? (
                    <div className='d-flex flex-column align-items-center mb-2'>
                        <p>{noChangesMessage}</p>
                    </div>
                ) : (
                    <>
                        <div className='mb-2'>
                            <ProductList
                                products={products}
                                instances={changes}
                                dropoff={true}
                            />
                        </div>
                        <button
                            onClick={onSubmit}
                            className='primary-button mb-2'>
                            <FormattedMessage
                                id='WarehouseDropoff.submitButton'
                                description='The submitButton on the warehouse workflow dropoff page.'
                                defaultMessage='Submit'
                            />
                        </button>
                    </>
                )}
            </>
        );
    }

    return (
        <BaseView navbarItems={<BackAndHomeNavigationButtons onHome={props.onHome} />}>
            <div className='text-center'>
                <h3>
                    <FormattedMessage
                        id='workflow.warehouseDropoff.stockRefill.action.title'
                        description='This is the stock refill Action title.'
                        defaultMessage='Stock refill'
                    />
                </h3>
                <p>
                    <FormattedMessage
                        id='workflow.warehouseDropoff.stockRefill.action.info'
                        description='This is the stock refill Action info message.'
                        defaultMessage='Pickups will not be tracked here.'
                    />
                </p>
            </div>
            <ScaleOverMaxWeightWarnings selectedContact={props.contact} />
            {content}
        </BaseView>
    );
}

function onSuccess(spotLayoutItems: SpotLayoutItem[], transactions: Transaction[]): { [spotLayoutItemId: string]: number } {
    const state: { [spotLayoutItemId: string]: number } = {};
    spotLayoutItems.forEach((item) => {
        const rel = transactions.filter((t) => t.slot_id === item.slot);
        state[item.id] = rel.length;
    });
    return state;
}

export function WarehouseDropoffAction(props: AllowedActionViewProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const intl = useIntl();
    if (props.contact && isDelivery(props.contact) && terminal?.workflow === TerminalWorkflowType.WAREHOUSE) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.warehouseDropoff.stockRefill.action',
                description: 'This is the stock refill Action button',
                defaultMessage: 'Stock refill'
            }),
            icon: <i className='bi bi-box-arrow-in-down dashboard-action-icon' />,
            view: <WarehouseDropoff {...(props as Props)} />
        };
    }
    return undefined;
}

function getTotalAmount(changes: SpotLayoutItemProductAmount[]): number {
    return changes.reduce((total, c) => total + c.amount, 0);
}

const noChangesMessage = (
    <FormattedMessage
        id='workflow.warehouse.NoChangesYetMessage'
        description='The message that is shown in the warehouse workflow when no changes are registered on the scales.'
        defaultMessage='No changes were registered yet.'
    />
);
