import React from 'react';

import BaseView from './BaseView';

export enum SpinnerSize {
    SMALL = 'small',
    NORMAL = 'normal',
    LARGE = 'large'
}

export interface SpinnerViewProps {
    children?: React.ReactNode;
    navbarItems?: React.ReactNode;
    title?: React.ReactNode;
    size?: SpinnerSize;
    onInactivity?: () => void;
    onError?: () => void;
}

function SpinnerView(props: SpinnerViewProps) {
    const style: React.CSSProperties = {};
    if (props.size === SpinnerSize.SMALL) {
        style.width = '2rem';
        style.height = '2rem';
    } else if (props.size === SpinnerSize.NORMAL) {
        style.width = '4rem';
        style.height = '4rem';
    } else if (props.size === SpinnerSize.LARGE) {
        style.width = '6rem';
        style.height = '6rem';
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={props.navbarItems}>
            <h3 className='mt-3 text-center'>{props.title ? props.title : 'Loading'}</h3>
            {props.children}
            <div
                className='text-center spinner-border'
                role='status'
                style={style}></div>
        </BaseView>
    );
}

export default SpinnerView;
