import Printer from '../../printing/Printer';
import PrinterException from '../../printing/exceptions/PrinterException';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest } from '../useManagedWebsocket';

export class WebsocketPausePrinterCommand extends WebsocketCommand {
    data: {
        value: boolean;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        if (request.value === undefined || typeof request.value !== 'boolean') throw new Error('Incorect information passed.');
        this.data = {
            value: request.value
        };
    }

    public async execute(): Promise<WebsocketCommandResponse> {
        try {
            await Printer.getInstance().setPausePrinter(this.data.value);
            return { success: true, send: true };
        } catch (error) {
            return {
                success: false,
                message: (error as PrinterException).message,
                send: true
            };
        }
    }
}
