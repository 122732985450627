import { FormattedMessage } from 'react-intl';

import ActionCard from '../../components/domain/ActionCard';
import ActionCardGridElement from '../../components/elements/ActionCardGridElement';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ViewTitle from '../nike/ViewTitle';

interface SessionChoiceViewProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    actions: ActionCard[];
    onSelectAction: (action: ActionCard) => void;
}

const SessionChoiceView = (props: SessionChoiceViewProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.lending.SessionChoiceView.title'
                    description='The title on the sessionChoiceView.'
                    defaultMessage='Welcome back. How can we help you?'
                />
            </ViewTitle>
            <ActionCardGridElement
                actions={props.actions}
                onSelectAction={props.onSelectAction}
            />
        </BaseView>
    );
};

export default SessionChoiceView;
