import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Contact, isContact } from '../../../api/contacts';
import { useSlot } from '../../../api/slots';
import { TerminalWorkflowType } from '../../../api/terminals';
import { Transaction } from '../../../common/transactions';
import ActionCard from '../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import ActionCardGridElement from '../../../components/elements/ActionCardGridElement';
import { useLookupPickupUserBadgeByContact } from '../../../hooks/lookup/transactions/useLookupPickupUserBadge';
import { LookupType } from '../../../hooks/lookup/usePerformLookup';
import useActivity from '../../../hooks/useActivity';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ErrorView from '../../../views/common/ErrorView';
import LoadingView from '../../../views/common/LoadingView';
import TransactionInformation from '../../../views/elements/TransactionInformation';
import Pickup from '../../Pickup';
import ScannerLookupModal, { ScannerLookupCallbackResult, ScannerLookupType } from '../../lookup/ScannerLookupModal';
import { isNikeManager } from '../../nike/permissions';
import ItmseLoginAction from '../../social-login/ItsmeLogin';
import QRHelpAction from '../Scanner/QRHelpAction';
import PickupPinAction from './PickupPinAction';

interface PickupActionProps extends AllowedActionViewProps {
    authenticateContact?: (contact: Contact | undefined) => void;
}

const PickupWorkflow = (props: PickupActionProps) => {
    const [customView, changeCustomView] = useState<JSX.Element | undefined>();
    const [showQrHelp, changeShowQrHelp] = useState(false);
    const intl = useIntl();
    const backHomeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    const onSelect = (action: ActionCard) => {
        if (action.actionText.includes('QR') || action.actionText.includes('NFC') || action.actionText.includes('Scan')) {
            changeShowQrHelp(true);
            setTimeout(function () {
                changeShowQrHelp(false);
            }, 3000);
        } else if (action.view) {
            changeCustomView(action.view);
        } else {
            action.onAction && action.onAction();
        }
    };

    if (customView === undefined) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}
                ShowQrHelp={showQrHelp}>
                <div className='d-flex justify-content-center text-center mt-4'>
                    <p className='title'>
                        <FormattedMessage
                            id='views.Pickup.Title'
                            description='The pickup title.'
                            defaultMessage='PickUp'
                        />
                    </p>
                </div>
                <ActionCardGridElement
                    actions={[
                        PickupPinAction(intl, {
                            onBack: () => changeCustomView(undefined),
                            onHome: props.onHome,
                            onInactivity: props.onInactivity
                        }),
                        ItmseLoginAction({
                            contact: props.contact,
                            onBack: props.onHome,
                            onHome: props.onHome,
                            onInactivity: props.onInactivity,
                            authenticateContact: props.authenticateContact
                        }),
                        QRHelpAction({})
                    ]}
                    onSelectAction={onSelect}
                />
            </BaseView>
        );
    } else {
        return customView;
    }
};

export interface UserPickupActionProps extends AllowedActionViewProps {
    userPickups?: number;
}

const UserPickup = (props: UserPickupActionProps) => {
    const [customView, changeCustomView] = useState<JSX.Element | undefined>();

    const intl = useIntl();
    const [, newActivity] = useActivity();
    const [currentTransaction, changeCurrentTransaction] = useState<Transaction | undefined>(undefined);
    const { data: transactionProcessingSlot } = useSlot(currentTransaction?.slot_id);
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    const pickups = useLookupPickupUserBadgeByContact({ contact: props.contact });

    const doPickup = (transaction: Transaction) => {
        changeCurrentTransaction(transaction);
    };
    const onSelect = (action: ActionCard) => {
        newActivity();
        if (action.view) {
            changeCustomView(action.view);
        } else {
            action.onAction && action.onAction();
        }
    };

    const lookupCallback = (result: ScannerLookupCallbackResult) => {
        if (result.transaction) {
            if (result.transactionType === LookupType.PICKUP) {
                changeCurrentTransaction(result.transaction);
            }
        }
    };

    if (transactionProcessingSlot && currentTransaction) {
        return (
            <Pickup
                slot={transactionProcessingSlot}
                transaction={currentTransaction}
                receiver={props.contact}
                onHome={props.onHome}
                onBack={() => {
                    changeCurrentTransaction(undefined);
                }}
                onInactivity={props.onInactivity}
            />
        );
    } else if (customView) {
        return customView;
    } else if (pickups.isInProgress) {
        return <LoadingView></LoadingView>;
    } else if ((pickups.isSuccess && pickups.result) || pickups.result?.length === 0) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}>
                <div className='d-flex justify-content-center text-center mt-4'>
                    <p className='title'>
                        <FormattedMessage
                            id='views.Pickup.Title'
                            description='The pickup title.'
                            defaultMessage='PickUp'
                        />
                    </p>
                </div>
                {pickups.result?.length === 0 ? (
                    <div className='d-flex justify-content-center text-center mt-4'>
                        <FormattedMessage
                            id='views.UserPickupActionView.NoPickupsFoundMessage'
                            description='The message on the user pickup action view telling the user there are no pickups to be done.'
                            defaultMessage='There are no transactions linked to your account.'
                        />
                    </div>
                ) : (
                    <>
                        {pickups.result.map((transaction) => {
                            return (
                                <div
                                    className='information-card-list'
                                    key={transaction.id + '-info-card'}>
                                    <TransactionInformation
                                        contact={props.contact}
                                        transaction={transaction}
                                        pickup={() => doPickup(transaction)}
                                    />
                                </div>
                            );
                        })}
                    </>
                )}

                <div className='d-flex justify-content-center text-center mt-4'>
                    <p className='title'>
                        <FormattedMessage
                            id='views.UserPickupActionView.OtherOptions'
                            description='The second title on the user pickup action view announcing that there are other options for picking up transactions.'
                            defaultMessage='PickUp with PIN code'
                        />
                    </p>
                </div>

                <ActionCardGridElement
                    actions={[PickupPinAction(intl, { onBack: () => changeCustomView(undefined), onHome: props.onHome, onInactivity: props.onInactivity })]}
                    onSelectAction={(action) => onSelect(action)}
                />

                <ScannerLookupModal
                    scannerLookupTypes={[ScannerLookupType.TRANSACTION]}
                    enabled={true}
                    resultCallback={lookupCallback}
                    doReset={true}
                />
            </BaseView>
        );
    } else {
        return (
            <ErrorView
                navbarItems={backHomeNav}
                title={
                    <FormattedMessage
                        id='views.UserPickupAction.error'
                        description='The error message on the User pickup action screen'
                        defaultMessage='Something went wrong please try again later.'
                    />
                }></ErrorView>
        );
    }
};

function PickupWorkflowAction(props: PickupActionProps) {
    const intl = useIntl();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    if (terminal === null || terminal.workflow === TerminalWorkflowType.VENDING || terminal.workflow === TerminalWorkflowType.WAREHOUSE) return undefined;
    if (props.contact && isContact(props.contact)) {
        return {
            id: 'PickupWorkflowAction',
            actionText: intl.formatMessage({
                id: 'workflow.UserPickupAction',
                description: 'This is the pickup action on for a authenticated contact',
                defaultMessage: 'Personal pickup'
            }),
            icon: <i className='bi bi-box-arrow-up dashboard-action-icon' />,
            color: 'success',
            view: <UserPickup {...props} />
        };
    } else {
        if (
            terminal === null ||
            (terminal.workflow !== TerminalWorkflowType.PUDO_PRIVATE &&
                terminal.workflow !== TerminalWorkflowType.PUDO &&
                terminal.workflow !== TerminalWorkflowType.IMES_DEXIS_NIKE) ||
            (terminal.workflow === TerminalWorkflowType.IMES_DEXIS_NIKE && !isNikeManager(props.contact))
        )
            return undefined;

        return {
            id: 'PickupWorkflowAction',
            actionText: intl.formatMessage({
                id: 'workflow.PudoWorkflow.pickupAction',
                description: 'This is the pickup action on PuDo',
                defaultMessage: 'Pickup'
            }),
            icon: <i className='bi bi-box-arrow-up dashboard-action-icon' />,
            view: <PickupWorkflow {...props} />
        };
    }
}

export default PickupWorkflowAction;
