import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Slot } from '../../api/slots';

interface Props {
    slotIds: string[];
    slots: Slot[] | undefined;
}

export default function ProductListItemSlotsField(props: Props) {
    if (props.slots === undefined) {
        return (
            <Spinner
                animation='border'
                role='status'
            />
        );
    }
    const slots = props.slots.filter((s) => props.slotIds.includes(s.id));

    return (
        <>
            <h5 className='mb-0 mt-2'>
                <FormattedMessage
                    id='ProductListItemSlotsField.Label'
                    description='The label indecating the location field on the product list item for the warehouse workflow.'
                    defaultMessage='Location'
                />
            </h5>
            <p>{props.slotIds.length === 0 || slots.length === 0 ? <p>unknown</p> : slots.map((s) => s.slot_nr).join(', ')}</p>
        </>
    );
}
