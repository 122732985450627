import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import useActivity from '../../hooks/useActivity';

interface InactivityModalProps {
    redirectSeconds?: number;
    showSeconds?: number;
    onInactivity?: () => void;
}

const InactivityModal = (props: InactivityModalProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [tickDate, changeTickDate] = useState(new Date());
    const [lastActivityDate, newActivity] = useActivity();

    useEffect(() => {
        const onInactivity = props.onInactivity;
        if (onInactivity) {
            changeTickDate(new Date());
            const timerId = window.setInterval(() => {
                changeTickDate(new Date());
            }, 200);
            return () => {
                window.clearInterval(timerId);
            };
        }
    }, [props.onInactivity, changeTickDate]);

    const handleClose = () => {
        newActivity();
    };

    //TODO settings is loaded in so on next update this should be changed to only checking if the spot exists
    let redirect_after_in_seconds = 30;
    let popup_stays_visible_in_seconds = 10;
    if (spot) {
        redirect_after_in_seconds = spot.settings_redirect_after_in_sec ? spot.settings_redirect_after_in_sec : 30;
        popup_stays_visible_in_seconds = spot.settings_popup_stays_visible_in_sec ? spot.settings_popup_stays_visible_in_sec : 10;
    }
    const redirectSeconds = props.redirectSeconds ? props.redirectSeconds : redirect_after_in_seconds;
    const showSeconds = redirectSeconds - (props.showSeconds ? props.showSeconds : popup_stays_visible_in_seconds);
    const show = tickDate.getTime() - lastActivityDate.getTime() > showSeconds * 1000;
    const showInSeconds = Math.ceil((redirectSeconds * 1000 - (tickDate.getTime() - lastActivityDate.getTime())) / 1000);
    const redirect = tickDate.getTime() - lastActivityDate.getTime() > redirectSeconds * 1000;
    const redirectInSeconds = Math.ceil((redirectSeconds * 1000 - (tickDate.getTime() - lastActivityDate.getTime())) / 1000);
    const showDate = new Date();
    showDate.setSeconds(showDate.getSeconds() + showInSeconds);
    const redirectDate = new Date();
    redirectDate.setSeconds(redirectDate.getSeconds() + redirectInSeconds);

    useEffect(() => {
        const onInactivity = props.onInactivity;
        if (onInactivity && redirect) {
            onInactivity();
        }
    }, [redirect, props.onInactivity]);
    const modalValues = { redirectInSeconds: redirectInSeconds };

    return (
        <>
            <Modal
                centered
                animation={false}
                show={props.onInactivity && show}
                onHide={handleClose}>
                <Modal.Body>
                    <p className='text-center'>
                        <FormattedMessage
                            id='views.elements.InactivityModal.message'
                            description='The message that is shown on the inactivity modal'
                            defaultMessage='Returning to home screen due to inactivity in {redirectInSeconds} seconds'
                            values={modalValues}
                        />
                    </p>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-center'>
                    <button
                        className='primary-button btn-lg'
                        onClick={handleClose}>
                        <FormattedMessage
                            id='views.elements.InactivityModal.stayActiveButton'
                            description='The button to stay active on the inactivity modal.'
                            defaultMessage='Stay active'
                        />
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default InactivityModal;
