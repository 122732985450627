import { useContext, useMemo, useState } from 'react';

import { TerminalContext } from '../../../TerminalInit';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { TerminalWorkflowType } from '../../../api/terminals';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import { checkIfTransactionInProgressIsStillValid } from '../../../service-worker/TransactionInProgressDatabase';
import { arraysEqual } from '../../../services/global/arrays';
import { asyncTransactionFilter } from '../../../services/transaction/TransactionFilters';
import useBaseLookup from '../useBaseLookup';

interface LookupPickupQrProps {
    lookupValue: string | null;
}

const findTransactionsByPickupQr = (transactions: Transaction[], qr: string, workflow: TerminalWorkflowType): Promise<Transaction[]> => {
    return asyncTransactionFilter(transactions, async (transaction: Transaction) => {
        if (transaction.pickup_qr === qr || (workflow === TerminalWorkflowType.LENDING && transaction.tt_number === qr)) {
            if (transaction.status === TransactionStatus.PICKUP_IN_PROGRESS) {
                await checkIfTransactionInProgressIsStillValid(transaction);
                return true;
            }
            return transaction.status === TransactionStatus.READY_FOR_PICKUP;
        }
    });
};

const useLookupPickupQr = (props: LookupPickupQrProps) => {
    const { terminal } = useContext(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url, undefined, { enabled: props.lookupValue != null });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [result, changeResult] = useState<Transaction[] | undefined>(undefined);

    const spotTransactionsResult = useTransactions({ spot: spot?.id });
    const { data: transactions, refetch: refetchTransactions, isFetching: transactionsIsFetching } = spotTransactionsResult;

    const requiredDataFetched = !!transactions && (spotTransactionsResult.isSuccess || spotTransactionsResult.isError);

    useMemo(async (): Promise<Transaction[]> => {
        if (!props.lookupValue || !transactions || !requiredDataFetched || !terminal?.workflow) return [];
        return await findTransactionsByPickupQr(transactions, props.lookupValue, terminal?.workflow);
    }, [props.lookupValue, transactions, requiredDataFetched]).then((tr) => {
        if (tr && (result === undefined || !arraysEqual(tr, result))) {
            changeResult(tr);
        }
    });

    const LookupImpl = {
        lookupValue: props.lookupValue,
        requiredDataFetched,
        result: result,
        refetchTransactions,
        isRefetching: transactionsIsFetching
    };

    return useBaseLookup(LookupImpl);
};

export default useLookupPickupQr;
