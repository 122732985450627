import { useContext, useEffect, useMemo, useState } from 'react';

import { TerminalContext } from '../../../TerminalInit';
import { useTransactionsSenderContactGroup } from '../../../api/contactGroups';
import { Contact, ContactsAllLookup, useContactGroupsContactsAll, useMutateAuthenticateContact } from '../../../api/contacts';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import { checkIfTransactionInProgressIsStillValid } from '../../../service-worker/TransactionInProgressDatabase';
import { arraysEqual } from '../../../services/global/arrays';
import { asyncTransactionFilter } from '../../../services/transaction/TransactionFilters';
import useBaseLookup from '../useBaseLookup';

interface LookupDropoffUserBadgeProps {
    lookupValue: string | null;
}

export const isMemberOfContactGroup = (contact: Contact, contactGroupId: string | number, contactsData?: (ContactsAllLookup | undefined)[]): boolean => {
    if (!contactsData) return false;
    // Filter contacts data for the one with the contactGroupId
    const contactsDataFiltered = contactsData.find((item) => {
        return item?.contactGroupId === contactGroupId;
    });
    if (!contactsDataFiltered) return false;
    const contacts = contactsDataFiltered.contacts;
    const contactIds = contacts.map((c) => c.id);
    return contactIds.includes(contact.id);
};

const findTransactionsByDropoffContact = (
    transactions: Transaction[],
    authenticatedContact?: Contact | null,
    contactsData?: (ContactsAllLookup | undefined)[]
): Promise<Transaction[]> => {
    return asyncTransactionFilter(transactions, async (transaction: Transaction) => {
        if (
            authenticatedContact &&
            ((transaction.sender && transaction.sender.id === authenticatedContact.id) ||
                (transaction.sender_group && isMemberOfContactGroup(authenticatedContact, transaction.sender_group.id, contactsData)))
        ) {
            if (transaction.status === TransactionStatus.DROPOFF_IN_PROGRESS) {
                await checkIfTransactionInProgressIsStillValid(transaction);
                return true;
            }
            return transaction.status === TransactionStatus.READY_FOR_DROPOFF;
        }
    });
};

const useLookupDropoffUserBadge = (props: LookupDropoffUserBadgeProps) => {
    const { terminal } = useContext(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url, undefined, { enabled: props.lookupValue != null });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [result, changeResult] = useState<Transaction[] | undefined>(undefined);

    const spotTransactionsResult = useTransactions({ spot: spot?.id });
    const { data: transactions } = spotTransactionsResult;

    const transactionsSenderGroupResult = useTransactionsSenderContactGroup(spotTransactionsResult.data);
    const contactGroupsContactsAllResult = useContactGroupsContactsAll(transactionsSenderGroupResult.map((r) => r.data));

    const mutateAuthenticateContact = useMutateAuthenticateContact();
    const { data: authenticatedContact } = mutateAuthenticateContact;

    const [currentLookupValue, changeCurrentLookupValue] = useState<string | null>(null);

    useEffect(() => {
        if (props.lookupValue && props.lookupValue !== currentLookupValue) {
            changeCurrentLookupValue(props.lookupValue);
        }
    }, [currentLookupValue, props.lookupValue, changeCurrentLookupValue]);

    const mutate = mutateAuthenticateContact.mutate;
    useEffect(() => {
        if (currentLookupValue) {
            mutate({
                authenticateContact: {
                    key: currentLookupValue
                }
            });
        }
    }, [currentLookupValue, mutate]);

    const requiredDataFetched =
        !!transactions &&
        (spotTransactionsResult.isSuccess || spotTransactionsResult.isError) &&
        (mutateAuthenticateContact.isSuccess || mutateAuthenticateContact.isError) &&
        transactionsSenderGroupResult.every((result) => {
            return result.isIdle || result.isSuccess || result.isError;
        }) &&
        contactGroupsContactsAllResult.every((result) => {
            return result.isIdle || result.isSuccess || result.isError;
        });

    useMemo((): void => {
        if (!props.lookupValue || !transactions || !requiredDataFetched) return;
        findTransactionsByDropoffContact(
            transactions,
            authenticatedContact,
            contactGroupsContactsAllResult.map((item) => item.data)
        ).then((tr) => {
            if (tr && (result === undefined || !arraysEqual(tr, result))) {
                changeResult(tr);
            }
        });
    }, [props.lookupValue, transactions, requiredDataFetched, authenticatedContact, contactGroupsContactsAllResult]);

    const LookupImpl = {
        lookupValue: props.lookupValue,
        requiredDataFetched,
        result: result,
        refetchTransactions: () => undefined,
        isRefetching: false
    };

    return useBaseLookup(LookupImpl);
};

export default useLookupDropoffUserBadge;
