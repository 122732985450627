import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Contact, isContact } from '../../../api/contacts';
import { TerminalWorkflowType } from '../../../api/terminals';
import ActionCard from '../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import ActionCardGridElement from '../../../components/elements/ActionCardGridElement';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ItmseLoginAction from '../../social-login/ItsmeLogin';
import QRHelpAction from '../Scanner/QRHelpAction';
import DropoffPinAction from './DropoffPinAction';
import OpenDropoffAction from './OpenDropoff';

interface DropoffActionProps extends AllowedActionViewProps {
    authenticateContact?: (contact: Contact | undefined) => void;
}

const DropoffWorkflow = (props: DropoffActionProps) => {
    const [customView, changeCustomView] = useState<JSX.Element | undefined>();
    const [showQrHelp, changeShowQrHelp] = useState(false);
    const intl = useIntl();
    const backHomeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    const onSelect = (action: ActionCard) => {
        if (action.actionText.includes('QR') || action.actionText.includes('NFC') || action.actionText.includes('Scan')) {
            changeShowQrHelp(true);
            setTimeout(function () {
                changeShowQrHelp(false);
            }, 3000);
        } else if (action.view) {
            changeCustomView(action.view);
        } else {
            action.onAction && action.onAction();
        }
    };

    if (customView === undefined) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}
                ShowQrHelp={showQrHelp}>
                <div className='d-flex justify-content-center text-center mt-4'>
                    <p className='title'>
                        <FormattedMessage
                            id='views.DropOff.Title'
                            description='The DropOff title.'
                            defaultMessage='DropOff'
                        />
                    </p>
                </div>
                <ActionCardGridElement
                    actions={[
                        DropoffPinAction(intl, {
                            contact: props.contact,
                            onBack: () => changeCustomView(undefined),
                            onHome: props.onHome,
                            onInactivity: props.onInactivity
                        }),
                        OpenDropoffAction(intl, {
                            contact: props.contact,
                            onBack: () => changeCustomView(undefined),
                            onHome: props.onHome,
                            onInactivity: props.onInactivity
                        }),
                        ItmseLoginAction({
                            contact: props.contact,
                            onBack: props.onHome,
                            onHome: props.onHome,
                            onInactivity: props.onInactivity,
                            authenticateContact: props.authenticateContact
                        }),
                        QRHelpAction({
                            contact: props.contact
                        })
                    ]}
                    onSelectAction={(action) => onSelect(action)}
                />
            </BaseView>
        );
    } else {
        return customView;
    }
};

function DropoffWorkflowAction(props: DropoffActionProps) {
    const intl = useIntl();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    if (terminal === null) return undefined;
    if (
        terminal.workflow === TerminalWorkflowType.PUDO_PRIVATE ||
        terminal.workflow === TerminalWorkflowType.PUDO ||
        (terminal.workflow === TerminalWorkflowType.IMES_DEXIS_NIKE && props.contact && isContact(props.contact))
    )
        return {
            id: 'DropoffWorkflowAction',
            actionText: intl.formatMessage({
                id: 'workflow.PuDoWorkflow.dropoffButton',
                description: 'This is the dropoff action on PuDo',
                defaultMessage: 'Dropoff'
            }),
            icon: <i className='bi bi-box-arrow-in-down dashboard-action-icon' />,
            view: <DropoffWorkflow {...props} />
        };

    return undefined;
}

export default DropoffWorkflowAction;
