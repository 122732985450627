import Fuse from 'fuse.js';
import React, { useContext, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Product, useProducts } from '../../api/products';
import { useShops } from '../../api/shops';
import { TerminalWorkflowType } from '../../api/terminals';
import useScannerValue from '../../hooks/useScannerValue';
import { isNfcValue } from '../../utils';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import ViewTitle from '../../views/nike/ViewTitle';

interface ProductSelectWorkflowProps {
    onSelect: (product: Product) => void;
    onHome?: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
}

function ProductSelectWorkflow(props: ProductSelectWorkflowProps) {
    const [search, changeSearch] = useState('');

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const [visableItemsAmount, changeVisableItemsAmount] = useState<number>(5);
    const {
        data: products,
        isSuccess: productsIsSuccess,
        isError: productsIsError
    } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);

    useScannerValue((scannerValue) => {
        if (!isNfcValue(scannerValue)) {
            changeSearch(search + scannerValue);
        }
    });

    // Perform fuzzy search on product codes and name
    const productsFilteredResults = useMemo(() => {
        if (!products || search === '') {
            return {
                products: [],
                fuseResults: []
            };
        }

        const addedKeys = [];
        if (terminal?.workflow == TerminalWorkflowType.IMES_DEXIS_NIKE) {
            addedKeys.push({
                name: 'product_data.size',
                weight: 0.7
            });
        }

        const fuseOptions = {
            includeScore: true,
            keys: [
                ...addedKeys,
                {
                    name: 'product_codes',
                    weight: 0.5
                },
                {
                    name: 'name',
                    weight: 0.5
                }
            ],
            fieldNormWeight: 1
        };
        const fuse = new Fuse(products, fuseOptions);
        const fuseResults = fuse.search(search);
        return {
            products: fuseResults.map((v) => v.item),
            fuseResults: fuseResults
        };
    }, [search, products]);

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeSearch(event.target.value);
    };

    if (!(productsIsSuccess || productsIsError) || !products) {
        return (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.nike.ProductSelectWorkflow.loadingTitle'
                        description='The title while products are loading on the product select view'
                        defaultMessage='Loading products'
                    />
                }
                onInactivity={props.onInactivity}
                onHome={props.onHome}
            />
        );
    } else if (productsIsError) {
        return (
            <ErrorView
                title={
                    <FormattedMessage
                        id='workflow.nike.ProductSelectWorkflow.errorTitle'
                        description='The title while products could not be loaded on the product select view.'
                        defaultMessage='Error loading products'
                    />
                }
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
            />
        );
    }

    let content: React.ReactNode;
    if (productsFilteredResults.products.length === 0 && search === '') {
        content = (
            <tr>
                <td
                    colSpan={2}
                    className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.ProductSelectWorkflow.messageNoSearch'
                        description='The info message in the table when no search has been performed on the product select view.'
                        defaultMessage='Search for products using the field above or scan a product code using the scanner.'
                    />
                </td>
            </tr>
        );
    } else if (productsFilteredResults.products.length === 0) {
        content = (
            <tr>
                <td
                    colSpan={2}
                    className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.ProductSelectWorkflow.messageNoProductsFound'
                        description='The info message in the table when no products match the search term on the product select view.'
                        defaultMessage='No products found'
                    />
                </td>
            </tr>
        );
    } else {
        content = (
            <>
                {productsFilteredResults.products.slice(0, visableItemsAmount).map((product) => {
                    return (
                        <tr
                            key={product.id}
                            onClick={() => {
                                props.onSelect(product);
                            }}>
                            <td
                                className='py-4'
                                style={{ verticalAlign: 'middle' }}>
                                {product.name}
                            </td>
                            <td
                                className='py-4'
                                style={{ verticalAlign: 'middle' }}>
                                {product.product_codes[0]}
                            </td>
                        </tr>
                    );
                })}
                {productsFilteredResults.products.length > visableItemsAmount ? (
                    <tr>
                        <td
                            colSpan={2}
                            className='small'>
                            <div className='text-center mb-2'>
                                <FormattedMessage
                                    id='workflow.nike.ProductSelectWorkflow.messageProductsHidden'
                                    description='The info message in the table when products are hidden after a search on the products select screen.'
                                    defaultMessage='{hiddenCount} products hidden.'
                                    values={{
                                        hiddenCount: productsFilteredResults.products.length - visableItemsAmount
                                    }}
                                />
                            </div>
                            <div className='text-center'>
                                <button
                                    className='light-button btn-sm mb-1'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        changeVisableItemsAmount(visableItemsAmount + 5);
                                    }}>
                                    show more
                                </button>
                            </div>
                        </td>
                    </tr>
                ) : undefined}
            </>
        );
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>Select product</ViewTitle>
            <Form>
                <Form.Group>
                    <Form.Control
                        placeholder='Enter product code or search term'
                        onChange={onSearchChange}
                        value={search}
                    />
                </Form.Group>
            </Form>
            <div className='card shadow mt-3'>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage
                                    id='workflow.nike.ProductSelectWorkflow.tableHeaderName'
                                    description='The header of the name column on the Product select view for Nike'
                                    defaultMessage='Name'
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    id='workflow.nike.ProductSelectWorkflow.tableHeaderProductCode'
                                    description='The header of the product code column on the Product select view for Nike'
                                    defaultMessage='Product code'
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{content}</tbody>
                </table>
            </div>
        </BaseView>
    );
}

export default ProductSelectWorkflow;
