interface LoaderProps {
    show?: boolean;
}

const Loader = (props: LoaderProps) => {
    const style = { width: '3rem', height: '3rem' };
    return props.show !== false ? (
        <>
            <div
                className='spinner-border text-primary align-self-center'
                role='status'
                style={style}></div>
        </>
    ) : (
        <></>
    );
};

export default Loader;
