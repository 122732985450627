import { useContext, useMemo, useState } from 'react';

import { TerminalContext } from '../../../TerminalInit';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import { checkIfTransactionInProgressIsStillValid } from '../../../service-worker/TransactionInProgressDatabase';
import { arraysEqual } from '../../../services/global/arrays';
import { asyncTransactionFilter } from '../../../services/transaction/TransactionFilters';
import useBaseLookup from '../useBaseLookup';

interface LookupRemovalQrProps {
    lookupValue: string | null;
}

const findTransactionsByRemovalQr = (transactions: Transaction[], qr: string): Promise<Transaction[]> => {
    return asyncTransactionFilter(transactions, async (transaction: Transaction) => {
        if (transaction.remove_parcel_qr === qr) {
            if (transaction.status === TransactionStatus.REMOVE_PARCEL_IN_PROGRESS) {
                await checkIfTransactionInProgressIsStillValid(transaction);
                return true;
            }
            return transaction.status === TransactionStatus.CANCELLED;
        }
    });
};

const useLookupRemovalQr = (props: LookupRemovalQrProps) => {
    const { terminal } = useContext(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url, undefined, { enabled: props.lookupValue != null });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [result, changeResult] = useState<Transaction[] | undefined>(undefined);

    const spotTransactionsResult = useTransactions({ spot: spot?.id });
    const { data: transactions, refetch: refetchTransactions, isFetching: transactionsIsFetching } = spotTransactionsResult;

    const requiredDataFetched = !!transactions && (spotTransactionsResult.isSuccess || spotTransactionsResult.isError);
    const validTransactionsForRemovel = transactions?.filter((transaction) => {
        transaction.spot_id === spot?.id;
    });

    useMemo(async (): Promise<Transaction[]> => {
        if (!props.lookupValue || !validTransactionsForRemovel || !requiredDataFetched) return [];
        return await findTransactionsByRemovalQr(validTransactionsForRemovel, props.lookupValue);
    }, [props.lookupValue, validTransactionsForRemovel, requiredDataFetched]).then((tr) => {
        if (tr && (result === undefined || !arraysEqual(tr, result))) {
            changeResult(tr);
        }
    });

    const LookupImpl = {
        lookupValue: props.lookupValue,
        requiredDataFetched,
        result: result,
        refetchTransactions,
        isRefetching: transactionsIsFetching
    };

    return useBaseLookup(LookupImpl);
};

export default useLookupRemovalQr;
