import React from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedMessage } from 'react-intl';

interface ScanProcessingModalProps {
    idle: boolean;
    error: boolean;
    success: boolean;
    done: boolean;
    reset: () => void;
    failureMessage?: React.ReactNode; // This is when the lookup was successful but it was not what we had hoped for
    errorMessage?: React.ReactNode; // This is when the lookup actually resulted in an error
}

function ScanProcessingModal(props: ScanProcessingModalProps) {
    let processingModalBody: string | React.ReactNode = (
        <div className='d-flex flex-row justify-content-center'>
            <Spinner
                animation='border'
                role='status'></Spinner>
        </div>
    );
    if (props.error) {
        processingModalBody = (
            <p className='text-center'>
                {props.errorMessage ? (
                    props.errorMessage
                ) : (
                    <FormattedMessage
                        id='views.nike.ScanProcessingModal.lookupErrorModalMessage'
                        description='This message is shown on the modal popup when an error occurred trying to process the badge/QR value in the nike workflow'
                        defaultMessage='Something went wrong. Try again later.'
                    />
                )}
            </p>
        );
    } else if (props.done) {
        if (!props.success) {
            processingModalBody = (
                <p className='text-center'>
                    {props.failureMessage ? (
                        props.failureMessage
                    ) : (
                        <FormattedMessage
                            id='views.nike.ScanProcessingModal.invalidBadgeModalMessage'
                            description='This message is shown on the modal popup when the badge or code could was not recognized in the nike workflow'
                            defaultMessage='Invalid badge or code. Try again.'
                        />
                    )}
                </p>
            );
        } else {
            // Redirecting soon
            processingModalBody = (
                <p className='text-center'>
                    <FormattedMessage
                        id='views.nike.ScanProcessingModal.correctBadgeModalMessage'
                        description='This message is shown on the modal popup when the badge or code was successfully recognized in the nike workflow'
                        defaultMessage='Successfully recognized badge or code. Loading next page.'
                    />
                </p>
            );
        }
    }

    return (
        <Modal
            centered
            size='sm'
            animation={false}
            show={!props.idle}
            onHide={props.reset}>
            <Modal.Body>
                <div className='modal-body'>{processingModalBody}</div>
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-center'>
                <div className='modal-footer'>
                    <button
                        className='primary-button btn-lg'
                        onClick={props.reset}>
                        <FormattedMessage
                            id='views.nike.ScanProcessingModal.dismissButton'
                            description='The dismiss button on the processing scanner value modal (for Nike)'
                            defaultMessage='Dismiss'
                        />
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ScanProcessingModal;
