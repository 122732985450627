import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { useProducts } from '../../api/products';
import { useShops } from '../../api/shops';
import { useSpotLayoutItems } from '../../api/spotLayoutItems';
import { useSpotLayout } from '../../api/spotLayouts';
import { useAppSelector } from '../../hooks/redux';
import badgeNfc from '../../static/nike/badge-nfc-alt.png';
import ProductList from './ProductList';
import { SpotLayoutItemProductAmount, diffFound, mapToSpotLayoutItemProductAmount } from './WarehouseWorkflow';

export default function ProductDiff() {
    const scalesWeightState = useAppSelector((state) => state.scalesWeight);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const [diff, changeDiff] = useState<SpotLayoutItemProductAmount[]>([]);

    useEffect(() => {
        if (products && spotLayoutItems) changeDiff(mapToSpotLayoutItemProductAmount(products, spotLayoutItems, scalesWeightState.difference));
    }, [scalesWeightState.difference]);

    if (products === undefined) {
        return (
            <Spinner
                animation='border'
                role='status'
            />
        );
    }

    return (
        <div className='row'>
            <div className='col-12 col-xl-6 d-flex justify-content-center'>
                {!diffFound(scalesWeightState.difference) ? (
                    <img
                        src={badgeNfc}
                        className='dashboard-scan-image mb-3 filter-primary'
                        alt={'NFC badge'}
                    />
                ) : (
                    <div className='w-100'>
                        <ProductList
                            products={products}
                            instances={diff}
                        />
                    </div>
                )}
            </div>
            <div className='col-12 col-xl-6 d-flex flex-column justify-content-center align-items-center'>
                <p className='text-center'>
                    <FormattedMessage
                        id='views.HomeView.ScanMessage'
                        description='The message home screen encouraging the user to scan'
                        defaultMessage='Please scan your badge to continue.'
                    />
                </p>
            </div>
        </div>
    );
}
