export enum LogLevel {
    DEBUG = 'debug',
    INFO = 'info',
    WARN = 'warning',
    ERROR = 'error'
}

export interface TerminalLogViewModel {
    event_timestamp: number;
    filter_data: unknown;
    message: string;
    message_type: LogLevel;
    raw_data?: any;
}

export interface FilterData {
    account?: string | number;
    spot?: string | number;
    slot?: string | number;
    product?: string | number;
    shop?: string | number;
    user?: string | number;
    contact?: string | number;
    transaction?: string | number;
    spot_layout_item?: string | number;
}

export class TerminalLog {
    private message: string;
    private filter_data: FilterData;
    private message_type: LogLevel;
    private event_timestamp: number;

    private source: any;
    private data?: string; //anything can be stored in here (will be JSON.stringified)

    constructor(message: string, logLevel: LogLevel, filter_data: FilterData, data?: string, date?: Date | number, source?: any) {
        if (date !== undefined) {
            if (typeof date === 'number') {
                this.event_timestamp = date;
            } else this.event_timestamp = date.getTime();
        } else this.event_timestamp = Date.now();
        this.message = message;
        this.message_type = logLevel;
        this.filter_data = filter_data;

        try {
            if (source !== undefined) {
                this.source = source;
            } else this.source = new Error().stack?.toString().split(/\r\n|\n/)[3];
        } catch {}
        this.data = data;
    }

    static parseToViewModel(terminalLog: any): TerminalLogViewModel {
        return {
            event_timestamp: terminalLog.event_timestamp,
            message: terminalLog.message,
            message_type: terminalLog.message_type,
            filter_data: terminalLog.filter_data,
            raw_data: {
                ...(terminalLog.data ? JSON.parse(terminalLog.data) : terminalLog.data),
                id: terminalLog.id
            }
        };
    }
}
