import { SpotLayoutItem } from '../../../api/spotLayoutItems';
import { SpotType } from '../../../api/spots';
import SpotLayout from '../../elements/SpotLayout';
import SlotOpenMultiSpotLayout from './SlotOpenMultiSpotLayout';

interface SlotOpenSpotLayoutProps {
    type: SpotType;

    highlightedItems?: SpotLayoutItem[];
    onSelect?: (item: SpotLayoutItem) => void;
    selectableItems?: SpotLayoutItem[];
    markedSpotLayoutItems?: SpotLayoutItem[];
    labelForItem?: (spotLayoutItem: SpotLayoutItem) => string | undefined;
    enableColumnButtons?: boolean;
}

const SlotOpenSpotLayout = (props: SlotOpenSpotLayoutProps): JSX.Element => {
    switch (props.type) {
        case SpotType.STANDARD_DISTRISPOT:
            return <SpotLayout {...props} />;
        case SpotType.MULTI_DISTRISPOT:
            return <SlotOpenMultiSpotLayout highlightedItem={props.highlightedItems ? props.highlightedItems[0] : undefined} />;
        //TODO add shelves
        default:
            return (
                <div>
                    <p>Something went wrong in the configuration of the DistriSPOT. Therefor the layout with the opened SLOT cannot be shown.</p>
                    <p>Please contact your administrator to make sure this problem is taken care of.</p>
                </div>
            );
    }
};

export default SlotOpenSpotLayout;
