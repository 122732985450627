import { useContext, useMemo, useState } from 'react';

import { TerminalContext } from '../../../TerminalInit';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import { checkIfTransactionInProgressIsStillValid } from '../../../service-worker/TransactionInProgressDatabase';
import { arraysEqual } from '../../../services/global/arrays';
import { asyncTransactionFilter } from '../../../services/transaction/TransactionFilters';
import useBaseLookup from '../useBaseLookup';

interface LookupPickupCodeProps {
    lookupValue: string | null;
}

const findTransactionsByPickupCode = (transactions: Transaction[], code: string): Promise<Transaction[]> => {
    return asyncTransactionFilter(transactions, async (transaction: Transaction) => {
        if (transaction.pickup_code === code) {
            if (transaction.status === TransactionStatus.PICKUP_IN_PROGRESS) {
                await checkIfTransactionInProgressIsStillValid(transaction);
                return true;
            } else if (transaction.status === TransactionStatus.READY_FOR_PICKUP) {
                return true;
            }
        }
        return false;
    });
};

const useLookupPickupCode = (props: LookupPickupCodeProps) => {
    const { terminal } = useContext(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url, undefined, { enabled: props.lookupValue != null });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const [result, changeResult] = useState<Transaction[] | undefined>(undefined);

    const spotTransactionsResult = useTransactions({ spot: spot?.id });

    const { data: transactions, refetch: refetchTransactions, isFetching: transactionsIsFetching } = spotTransactionsResult;

    const requiredDataFetched = !!transactions && (spotTransactionsResult.isSuccess || spotTransactionsResult.isError);

    useMemo((): void => {
        if (!props.lookupValue || !transactions || !requiredDataFetched) return;
        findTransactionsByPickupCode(transactions, props.lookupValue).then((tr) => {
            if (tr && (result === undefined || !arraysEqual(tr, result))) {
                changeResult(tr);
            }
        });
    }, [props.lookupValue, transactions, requiredDataFetched]);

    const LookupImpl = {
        lookupValue: props.lookupValue,
        requiredDataFetched,
        result: result,
        refetchTransactions,
        isRefetching: transactionsIsFetching
    };

    return useBaseLookup(LookupImpl);
};

export default useLookupPickupCode;
