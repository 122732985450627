import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { StockFill } from '../../../api/stockFills';
import DefaultList from '../DefaultList';
import StockFillReportListElement from './StockFillReportListElement';

interface StockFillReportSelectViewProps {
    onSelect: (stockFill: StockFill) => void;
    stockFillReports: StockFill[];
}

const StockFillReportSelectView = (props: StockFillReportSelectViewProps) =>
    useMemo(() => {
        return (
            <DefaultList
                elements={props.stockFillReports
                    .sort((a, b) => b.id - a.id)
                    .map((stockFillReport) => StockFillReportListElement(stockFillReport, props.onSelect))}
                headers={[
                    <th>
                        <FormattedMessage
                            id='views.elements.stockFillReports.StockFillReportSelectView'
                            description='The header of the report number column on the StockFillReport select view'
                            defaultMessage='Report number'
                        />
                    </th>
                ]}
            />
        );
    }, [props.stockFillReports]);

export default StockFillReportSelectView;
