import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { useMutateCreateTransaction } from '../../api/transactions';
import { CreateTransactionStatus, TransactionType } from '../../common/transactions';
import { getRandomString } from '../../utils';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import ViewTitle from '../../views/nike/ViewTitle';

type ContactIdOrContactGroupId =
    | {
          contactId: string | number;
          contactGroupId?: never;
      }
    | {
          contactId?: never;
          contactGroupId: string | number;
      };

interface CompletedTransactionWorkflowProps {
    senderData: ContactIdOrContactGroupId;
    receiverData: ContactIdOrContactGroupId;
    productInstanceIds: Array<number | string>;
    shippingNotes?: string;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    children?: React.ReactNode;
}

export default function CompletedTransactionWorkflow(props: CompletedTransactionWorkflowProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    const mutateCreateTransaction = useMutateCreateTransaction();

    const [creatingTransaction, changeCreatingTransaction] = useState(false);

    useEffect(() => {
        if (!creatingTransaction && spot) {
            changeCreatingTransaction(true);
            mutateCreateTransaction.mutate({
                createTransaction: {
                    account_id: spot.account,
                    tt_number: getRandomString(20),
                    status: CreateTransactionStatus.COMPLETED,
                    receiver_id: props.receiverData.contactId,
                    receiver_group_id: props.receiverData.contactGroupId,
                    sender_id: props.senderData.contactId,
                    sender_group_id: props.senderData.contactGroupId,
                    shipping_notes: props.shippingNotes,
                    product_instance_ids: props.productInstanceIds,
                    type: TransactionType.PUDO
                }
            });
        }
    }, [spot, creatingTransaction]);

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );

    if (!creatingTransaction || !(mutateCreateTransaction.isSuccess || mutateCreateTransaction.isError)) {
        return (
            <LoadingView
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    } else if (mutateCreateTransaction.isError) {
        return (
            <ErrorView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}
            />
        );
    } else if (props.children) {
        return <>{props.children}</>;
    } else {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}>
                <ViewTitle>
                    <FormattedMessage
                        id='workflow.nike.CompletedTransactionWorkflow.successTitle'
                        description='The default success title when transferring ownership from a product instance to another contact or contact group (nike workflow)'
                        defaultMessage='Item transferred successfully!'
                    />
                </ViewTitle>
            </BaseView>
        );
    }
}
