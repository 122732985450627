import _ from 'lodash';

import { Logger } from '../logs/Logger';
import { WebsocketCommandType } from '../websocket/useManagedWebsocket';
import PrinterQueue from './PrinterQueue';
import PrinterException from './exceptions/PrinterException';
import PrinterPrintException from './exceptions/PrinterPrintException';
import PrintingServiceFactory from './printingServices/PrintingServiceFactory';

export interface PrintResponse {
    amountOfPrintedLabels: number | undefined;
    status?: PrinterStatus;
    error?: string;
}

export interface PrinterStatus {
    error?: string;
    offline?: boolean;
    paperOut?: boolean;
    paused?: boolean;
    headOpen?: boolean;
    ribbonOut?: boolean;
}

export const PRINTER_JOB_ADDED_EVENT = 'printer_job_added';

declare global {
    interface WindowEventMap {
        printer_job_added: CustomEvent<number>;
    }
}

export default class Printer {
    private static instance: Printer | null;

    private constructor() {
        window.addEventListener(PRINTER_JOB_ADDED_EVENT, jobAddedListener);
        PrinterQueue.getAllJobs().then((result) => {
            if (result?.length !== 0) {
                Logger.error(
                    'Terminal app restarted while there were still commands in the printerQeueu. Since there arent any listeners anymore they will be cleared.',
                    {},
                    result
                );
                PrinterQueue.clearQueue();
            }
        });

        Logger.log('Printer service initialised.');
    }

    public static getInstance = (): Printer => {
        if (!Printer.instance) {
            Printer.instance = new Printer();
        }
        return Printer.instance;
    };

    public pritnerExists = async (): Promise<boolean> => {
        const printerService = await PrintingServiceFactory.getPrinterService();
        Logger.log('Getting printer info', {}, printerService.printer);
        return printerService.printer !== null;
    };

    /**
     * This function will print multiple files it will wait for the last label to be printed.
     * @param filesToPrint
     * @returns a PrintResponse in case of success
     * @throws PrinterPrintException (with the printRepsonse to provide as much info as possible)
     */
    public print = async (processId: number, file: string, numberOfCopies?: number): Promise<PrintResponse> => {
        const finalResult: PrintResponse = { amountOfPrintedLabels: 0 };
        try {
            const printerService = await PrintingServiceFactory.getPrinterService();
            if (printerService == null) throw new PrinterException('Unknown printer.');

            try {
                finalResult.status = await printerService.getPrinterStatus();
            } catch {
                Logger.warn('The printer is known but the status is not, but will try to print anyway.');
            }

            if (numberOfCopies === 0 || numberOfCopies === undefined) numberOfCopies = 1;
            const filesToPrint: string[] = _.times(numberOfCopies, () => file);

            const startCount = await printerService.getTotalLabelsPrinted();
            Logger.log('start print');
            await Promise.all(filesToPrint.map(async (file) => await printerService.print(file)));
            const endCount = await printerService.getTotalLabelsPrinted();

            //make it undefined if there is no way to ask for labelcount
            if (startCount !== undefined && endCount !== undefined) finalResult.amountOfPrintedLabels = endCount - startCount;
            else finalResult.amountOfPrintedLabels = undefined;

            if (finalResult.amountOfPrintedLabels !== undefined && finalResult.amountOfPrintedLabels < filesToPrint.length && finalResult.error === undefined) {
                if (finalResult.amountOfPrintedLabels === 0)
                    throw new PrinterException('No labels where printed. Check the printer status to see what went wrong.');
                else
                    throw new PrinterException(
                        'It seems like some labels where printed but the amount of files sent is larger then the amount of labels printed.'
                    );
            }
            PrinterQueue.gotoNext(processId);
        } catch (error) {
            try {
                await this.cancelAll();
            } catch {}
            this.retryPrint(processId, true);
            throw new PrinterPrintException((error as PrinterException).message, finalResult);
        }
        return finalResult;
    };

    /**
     * Private function to wait 10 seconds and then to rerun the given command (only supports print commands)
     * @param processId
     * @param relevantCheck (optional) if set to true it will only run if the command is still valid after the timeout
     * @param timeout (default = 10000) amount of milliseconds to wait before retrying
     *
     * Note that this will only work if some process is listening to the event dispatch by this function ("printLabel:PROCESS_ID" where PROCESS_ID is the same as the param passed to this function)
     */
    private retryPrint = async (processId: number, relevantCheck?: boolean, timeout = 10000) => {
        if ((relevantCheck === true && processId > Date.now() + timeout) || !relevantCheck) {
            Logger.log(processId + ' failed but retrying in ' + timeout + 'ms');
            setTimeout(async () => {
                Logger.log('retry:' + processId);
                window.dispatchEvent(new CustomEvent(WebsocketCommandType.PRINT_LABEL + ':' + processId));
            }, timeout);
        } else {
            PrinterQueue.gotoNext(processId);
        }
    };

    /**
     * @returns string in case of an error and otherwise a Status object
     * @throws PrinterException
     */
    public getPrinterStatus = async (processId: number): Promise<PrinterStatus> => {
        Logger.log('Retreiving printer status.');
        const result = await (await PrintingServiceFactory.getPrinterService()).getPrinterStatus();
        PrinterQueue.gotoNext(processId);
        return result;
    };

    /**
     * Can be used in case the printer is paused (connection will be lost for a short amount of time)
     * @throws PrinterException
     */
    public resetPrinter = async (): Promise<void> => {
        Logger.log('reset printer');
        await (await PrintingServiceFactory.getPrinterService()).resetPrinter();
    };

    /**
     * Can be used to pause or unpause the printer.
     * @param value true will pause false will unpause
     * @throws PrinterException
     */
    public setPausePrinter = async (value: boolean): Promise<void> => {
        Logger.log('pause printer: ' + JSON.stringify(value));
        await (await PrintingServiceFactory.getPrinterService()).setPausePrinter(value);
    };

    /**
     * @param value true for enableing and false for disableing the sleep mode on the printer
     * @throws PrinterException
     */
    public setSleep = async (value: boolean): Promise<void> => {
        Logger.log('setting sleep enabled to ' + JSON.stringify(value));
        await (await PrintingServiceFactory.getPrinterService()).setSleepMode(value);
    };

    /**
     * Will clear the queue on the printer itself.
     */
    public cancelAll = async (): Promise<void> => {
        Logger.log('Clearing printer queue.');
        await (await PrintingServiceFactory.getPrinterService()).cancelAll();
    };

    public setSynchronousMode = async (value: boolean): Promise<void> => {
        Logger.log('Setting synchronous mode to' + JSON.stringify(value));
        await (await PrintingServiceFactory.getPrinterService()).setSynchronousMode(value);
    };
}

const jobAddedListener = async (event: CustomEvent<number>) => {
    const processId = event.detail;
    const currentJobs = await PrinterQueue.getAllJobs();
    if (currentJobs !== undefined && currentJobs.length === 1) {
        const command = currentJobs[0];
        window.dispatchEvent(new CustomEvent(command.type + ':' + processId));
    }
};
