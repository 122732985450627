import { useContext } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { TerminalContext } from '../../../TerminalInit';
import { Transaction } from '../../../common/transactions';
import { Logger } from '../../../logs/Logger';
import { sleep } from '../../../utils';
import { ApiError, FetchOptions, fetchApi, postApi } from '../../utils';
import { BulkTransactionsResponse } from './BulkCreateTransaction';

const ENDPOINT = '/transactions/bulk/';

interface MutateBulkMoveTransactionVariables {
    account_id: number;
    transaction_spot_slot_array: {
        transaction: string;
        new_spot_id: string;
        new_slot_id?: string;
        fast_transition?: boolean;
    }[];
    info?: {
        transactionId: string;
        from?: string;
        to?: string;
    }[];
}

export function useMutateBulkMoveTransactions(
    options?: UseMutationOptions<Transaction[], unknown, MutateBulkMoveTransactionVariables>,
    fetchOptions?: FetchOptions
) {
    const queryClient = useQueryClient();
    const config: UseMutationOptions<Transaction[], unknown, MutateBulkMoveTransactionVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, variables, context) => {
            await queryClient.invalidateQueries(['transactions']);
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(bulkMoveTransactions(fetchOptions), config);
}

export function bulkMoveTransactions(options?: FetchOptions): (variables: MutateBulkMoveTransactionVariables) => Promise<Transaction[]> {
    return async (variables: MutateBulkMoveTransactionVariables): Promise<Transaction[]> => {
        let bulkStatus;
        const response = await postApi(
            `${ENDPOINT}change_slot_spot/`,
            { account_id: variables.account_id, transaction_spot_slot_array: variables.transaction_spot_slot_array },
            options
        );
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error trying to bulk move transactions.');
            }
            throw new ApiError('Error trying to bulk move transactions.', json);
        }
        bulkStatus = (await response.json()) as BulkTransactionsResponse;

        while (bulkStatus.state === 'pending' || bulkStatus.state === 'init') {
            await sleep(500);
            const fetchBulkResponse: Response = await fetchApi(`${ENDPOINT}${bulkStatus.id}/`, {}, options);
            if (!fetchBulkResponse.ok) {
                let json;
                try {
                    json = await fetchBulkResponse.json();
                } catch (e) {
                    throw new ApiError('Error trying to get the bulk move transactions status');
                }
                throw new ApiError('Error trying to get the bulk move transactions status', json);
            }
            bulkStatus = await fetchBulkResponse.json();
        }

        if (bulkStatus.state === 'error') {
            Logger.error('Bulk move trnasactions went into the error state.', bulkStatus);
            return [];
        }

        const finalResponse = await fetchApi(`/transactions/?bulk_transaction=${bulkStatus.id}&status=${bulkStatus.status}`);
        if (!finalResponse.ok) {
            let json;
            try {
                json = await finalResponse.json();
            } catch (e) {
                throw new ApiError('Error trying to get the resulting transaction from the bulk move.');
            }
            throw new ApiError('Error trying to get the resulting transaction from the bulk move.', json);
        }
        return await finalResponse.json();
    };
}
