import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, QueryObserverResult, useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';

import { Locale } from '../App';
import { TerminalContext } from '../TerminalInit';
import { BackendHardwareDriverOptionsObject } from '../services/platformHardwareDriver/DriverSlotObjects';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { SpotLayout } from './spotLayouts';
import { ApiError, FetchOptions, putApi } from './utils';

export interface Spot {
    id: string;
    url: string;
    type: SpotType;
    account: number;
    name: string;
    address_line_1?: string;
    zip_code?: string;
    city?: string;
    slots: string;
    slots_num: number;
    available_slots_num: number;
    actions: SpotActionSettings;
    additional_data: unknown;
    sharing_allowed_for_contact_groups: number[];

    //Settings
    settings_language: Locale;
    settings_switch_lights_on_at: string;
    settings_switch_lights_off_at: string;
    settings_redirect_after_in_sec: number;
    settings_popup_stays_visible_in_sec: number;
    settings_allow_multi_persons_warehouse_access: boolean;

    settings_dropoff_in_progress_timeout_in_sec: number;
    settings_pickup_in_progress_timeout_in_sec: number;
    settings_remove_parcel_in_progress_timeout_in_sec: number;
    presence_indicator: BackendHardwareDriverOptionsObject;
}

/**
 * Type used to decide what the spotlayout should show.
 */
export enum SpotType {
    UNKNOWN = 'unknown',
    SMART_SHELVES = 'smart_shelves',
    STANDARD_DISTRISPOT = 'standard_distrispot',
    MULTI_DISTRISPOT = 'multi_distrispot'
}

export interface SpotActionSettings {
    lights_on: boolean;
}

interface MutateLightsStatusVariables {
    spot_id: string;
    body: {
        actions_turn_lights_on: boolean;
    };
}

enum SpotsQueryParams {}

const spotViewSet: ApiViewSet = {
    baseName: 'spots'
};

const defaultConfig = {
    staleTime: Infinity
};

function fetchSpotsApi(options?: FetchOptions): () => Promise<Spot[]> {
    return apiList<Spot, SpotsQueryParams>(spotViewSet, undefined, options);
}

const fetchSpotApi = (options: DetailOptions, fetchOptions?: FetchOptions): (() => Promise<Spot>) => {
    return apiDetail<Spot>(spotViewSet, options, fetchOptions);
};

export async function prefetchSpot(queryClient: QueryClient, spotLayout: SpotLayout, options?: FetchQueryOptions<Spot>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...options
    };

    const spot = await queryClient.fetchQuery(
        ['spot', undefined, { url: spotLayout.spot_url }],
        fetchSpotApi({ url: spotLayout.spot_url }, fetchOptions),
        config
    );
    // Set the spot detail cache as well
    queryClient.setQueryData(['spot', spot.id, { url: spot.url }], spot);

    return spot;
}

export function useSpots(options?: UseQueryOptions<Spot[]>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();
    const config = {
        ...defaultConfig,
        onSuccess: (spots: Spot[]) => {
            for (const spot of spots) {
                queryClient.setQueryData(['spot', spot.id, { url: spot.url }], spot);
            }
        },
        ...options
    };
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Spot[]>('spots', fetchSpotsApi(fetchOptions), config);
}

function spotUseQueryOptions(
    spotUrl?: string,
    spotId?: number | string,
    queryOptions?: UseQueryOptions<Spot>,
    fetchOptions?: FetchOptions
): UseQueryOptions<Spot> {
    const noIdOrUrl = !spotUrl && !spotId;
    const config = {
        ...defaultConfig,
        enabled: !noIdOrUrl,
        ...queryOptions
    };

    let detailOptions: DetailOptions;
    if (spotUrl) {
        detailOptions = {
            url: spotUrl
        };
    } else if (spotId) {
        detailOptions = {
            id: spotId
        };
    } else {
        detailOptions = {
            id: '' // This case only occurs when no spotId or spotUrl is provided, and the query is never actually executed
        };
    }

    return {
        queryKey: ['spot', spotId, { url: spotUrl }],
        queryFn: fetchSpotApi(detailOptions, fetchOptions),
        ...config
    };
}

export function useSpot(spotUrl?: string, spotId?: number | string, options?: UseQueryOptions<Spot>, fetchOptions?: FetchOptions) {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Spot>(spotUseQueryOptions(spotUrl, spotId, options, fetchOptions));
}

export function useSpotArray(
    spotIds?: Array<number | string> | null,
    options?: UseQueryOptions<Spot>,
    fetchOptions?: FetchOptions
): QueryObserverResult<Spot>[] {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQueries(
        spotIds
            ? spotIds.map((spotId) => {
                  return spotUseQueryOptions(undefined, spotId, options, fetchOptions) as UseQueryOptions;
              })
            : []
    ) as QueryObserverResult<Spot>[];
}

export function useMutateSpotLightsStatus(options?: UseMutationOptions<void, unknown, MutateLightsStatusVariables>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<void, unknown, MutateLightsStatusVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['spot', variables.spot_id]);
            await queryClient.invalidateQueries(['spots']);
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(updateSpotLightsStatus(fetchOptions), config);
}

export function updateSpotLightsStatus(options?: FetchOptions): (variables: MutateLightsStatusVariables) => Promise<void> {
    return async (variables: MutateLightsStatusVariables): Promise<void> => {
        const response = await putApi(`/spots/${variables.spot_id}/switch_lights/`, variables.body, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating spot lights status');
            }
            throw new ApiError('Error updating spot lights status', json);
        }
        return await response.json();
    };
}
