import { useMemo, useState } from 'react';
import { BsColumns, BsListCheck } from 'react-icons/bs';
import { TbArrowsRightLeft } from 'react-icons/tb';
import { IntlShape, useIntl } from 'react-intl';

import { Contact } from '../../api/contacts';
import { Transaction, TransactionType } from '../../common/transactions';
import ActionCard from '../../components/domain/ActionCard';
import useActivity from '../../hooks/useActivity';
//import ProductByAssignedSlotOverview from '../../../views/nike/manage_stock/ProductByAssignedSlotOverview';
import { Logger } from '../../logs/Logger';
import ManageShopView from '../../views/nike/ManageShopView';
import SlotsByTypeOverview from '../../views/nike/manage_stock/SlotsByTypeOverview';
import TransactionsByTypeOverview from '../../views/nike/manage_stock/TransactionsByTypeOverview';
import CancelTransactionWorkflow from '../nike/manage_shop/CancelTransactionWorkflow';
import RemoveTransactionWorkflow from '../nike/manage_shop/RemoveTransactionWorkflow';
import StockOverviewWorkflow from '../nike/manage_shop/StockOverviewWorkflow';
import MoveTransactionWorkflow from '../nike/manage_shop/moveTransaction/MoveTransactionWorkflow';

function manageShopActions(intl: IntlShape) {
    const stockOverview: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.ManageShopWorkflow.StockOverviewAction',
            description: 'This is the stock overview action on the Nike manage shop workflow',
            defaultMessage: 'Stock Overview'
        }),
        icon: (
            <BsListCheck
                className='dashboard-action-icon'
                size={264}
            />
        )
    };
    const slotsOverview: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.ManageShopWorkflow.SlotsOverviewAction',
            description: 'This is the slota overview action on the Nike manage shop workflow',
            defaultMessage: 'Slots Overview'
        }),
        icon: (
            <BsColumns
                className='dashboard-action-icon'
                size={264}
            />
        )
    };
    const transactionOverview: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.ManageShopWorkflow.TransactionOverviewAction',
            description: 'This is the slot overview action on the content management workflow',
            defaultMessage: 'Transaction Overview'
        }),
        icon: (
            <TbArrowsRightLeft
                className='dashboard-action-icon'
                size={264}
            />
        )
    };
    /*const ProductAssignedSlotOverview: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.ManageShopWorkflow.ProductAssignedSlotOverview',
            description: 'This is the assigned product overview action on the content management workflow',
            defaultMessage: 'Assigned product Overview'
        }),
        icon: (
            <BsInboxesFill
                className='dashboard-action-icon'
                size={264}
            />
        )
    };*/

    return {
        stockOverview,
        slotsOverview,
        transactionOverview
        //ProductAssignedSlotOverview
    };
}

interface ContentManagementWorkflowProps {
    filterObject?: JSX.Element;
    loggedInContact: Contact;
    transactionTypeFilter?: TransactionType[];
    onInactivity?: () => void;
    onHome?: () => void;
}

export default function ContentManagementWorkflow(props: ContentManagementWorkflowProps) {
    const intl = useIntl();
    const [, newActivity] = useActivity();

    const [selectedAction, changeSelectedAction] = useState<ActionCard | null>(null);
    const [removeTransaction, changeRemoveTransaction] = useState<Transaction | null>(null);
    const [cancelTransaction, changeCancelTransaction] = useState<Transaction | null>(null);
    const [moveTransaction, changeMoveTransaction] = useState<Transaction | null>(null);

    const { stockOverview, slotsOverview, transactionOverview /*, ProductAssignedSlotOverview*/ } = useMemo(() => {
        return manageShopActions(intl);
    }, [intl]);

    const onSelectAction = (action: ActionCard | null) => {
        newActivity();
        changeSelectedAction(action);
    };

    const resetAction = () => {
        onSelectAction(null);
    };

    const onRemoveTransaction = (transaction: Transaction) => {
        Logger.log(`Contact ${props.loggedInContact.id} initiated remove transaction workflow for transactions ${transaction.id}.`, {
            transaction: transaction.id,
            contact: props.loggedInContact.id
        });
        newActivity();
        changeCancelTransaction(null);
        changeMoveTransaction(null);
        changeRemoveTransaction(transaction);
    };
    const stopRemoveTransaction = () => {
        newActivity();
        changeRemoveTransaction(null);
    };

    const onCancelTransaction = (transaction: Transaction) => {
        Logger.log(`Contact ${props.loggedInContact.id} initiated cancel transaction workflow for transactions ${transaction.id}.`, {
            transaction: transaction.id,
            contact: props.loggedInContact.id
        });
        newActivity();
        changeMoveTransaction(null);
        changeRemoveTransaction(null);
        changeCancelTransaction(transaction);
    };
    const stopCancelTransaction = () => {
        newActivity();
        changeCancelTransaction(null);
    };

    const onMoveTransaction = (transaction: Transaction) => {
        Logger.log(`Contact ${props.loggedInContact.id} initiated move transaction workflow for transactions ${transaction.id}.`, {
            transaction: transaction.id,
            contact: props.loggedInContact.id
        });
        newActivity();
        changeCancelTransaction(null);
        changeRemoveTransaction(null);
        changeMoveTransaction(transaction);
    };
    const stopMoveTransaction = () => {
        newActivity();
        changeMoveTransaction(null);
    };

    if (removeTransaction !== null) {
        return (
            <RemoveTransactionWorkflow
                loggedInContact={props.loggedInContact}
                transaction={removeTransaction}
                confirmed={true}
                onInactivity={props.onInactivity}
                onBack={stopRemoveTransaction}
                onHome={props.onHome}
            />
        );
    } else if (cancelTransaction !== null) {
        return (
            <CancelTransactionWorkflow
                loggedInContact={props.loggedInContact}
                transaction={cancelTransaction}
                confirmed={true}
                onInactivity={props.onInactivity}
                onBack={stopCancelTransaction}
                onHome={props.onHome}
            />
        );
    } else if (moveTransaction !== null) {
        return (
            <MoveTransactionWorkflow
                loggedInContact={props.loggedInContact}
                transaction={moveTransaction}
                onBack={stopMoveTransaction}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    }
    if (selectedAction === slotsOverview) {
        return (
            <SlotsByTypeOverview
                filterObject={props.filterObject}
                transactionTypeFilter={props.transactionTypeFilter}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={resetAction}
                loggedInContact={props.loggedInContact}
                removeTransaction={onRemoveTransaction}
                cancelTransaction={onCancelTransaction}
                changeTransactionSlot={onMoveTransaction}
            />
        );
    } else if (selectedAction === stockOverview) {
        return (
            <StockOverviewWorkflow
                filterObject={props.filterObject}
                transactionTypeFilter={props.transactionTypeFilter}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={resetAction}
                loggedInContact={props.loggedInContact}
                removeTransaction={onRemoveTransaction}
                cancelTransaction={onCancelTransaction}
                changeTransactionSlot={onMoveTransaction}
            />
        );
    } else if (selectedAction === transactionOverview) {
        return (
            <TransactionsByTypeOverview
                filterObject={props.filterObject}
                transactionTypeFilter={props.transactionTypeFilter}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={resetAction}
                loggedInContact={props.loggedInContact}
                removeTransaction={onRemoveTransaction}
                cancelTransaction={onCancelTransaction}
                changeTransactionSlot={onMoveTransaction}
            />
        );
    } /*else if (selectedAction === ProductAssignedSlotOverview) {
        return (
            <ProductByAssignedSlotOverview
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={resetAction}
                loggedInContact={props.loggedInContact}
            />
        );
    }*/ else {
        return (
            <ManageShopView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                actions={[stockOverview, slotsOverview, transactionOverview /*, ProductAssignedSlotOverview*/]}
                onSelectAction={onSelectAction}
            />
        );
    }
}
