import { useContext } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { TerminalContext } from '../../../TerminalInit';
import { CreateTransactionStatus, Transaction } from '../../../common/transactions';
import { sleep } from '../../../utils';
import { ApiError, FetchOptions, fetchApi, postApi } from '../../utils';
import { BulkTransactionsResponse } from './BulkCreateTransaction';

const ENDPOINT = '/transactions/bulk/';

export enum TransactionStatusChangeEventValue {
    DROPOFF_IN_PROGRESS = 'dropoff_in_progress',
    PICKUP_IN_PROGRESS = 'pickup_in_progress',
    REMOVE_PARCEL_IN_PROGRESS = 'remove_parcel_in_progress',
    CANCEL = 'cancel'
}

export interface BulkTransactionStatusUpdateVariables {
    account_id: number;
    initial_state: string;
    event: TransactionStatusChangeEventValue;
    receiver_id?: number;
    notifications?: any;
    transaction_ids: number[];
    fast_transition?: boolean;
}

export function useMutateBulkUpdateTransactionStatus(
    options?: UseMutationOptions<Transaction[], unknown, BulkTransactionStatusUpdateVariables>,
    fetchOptions?: FetchOptions
) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<Transaction[], unknown, BulkTransactionStatusUpdateVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['transactions']);
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(bulkUpdateTransactionStatus(fetchOptions), config);
}
export function bulkUpdateTransactionStatus(options?: FetchOptions): (variables: BulkTransactionStatusUpdateVariables) => Promise<Transaction[]> {
    return async (variables: BulkTransactionStatusUpdateVariables): Promise<Transaction[]> => {
        if (variables.notifications === undefined) variables.notifications = {};
        const response = await postApi(`${ENDPOINT}status_update/`, variables, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error trying to bulk update transactions');
            }
            throw new ApiError('Error trying to bulk update transactions', json);
        }
        let bulkStatus = (await response.json()) as BulkTransactionsResponse;
        while (bulkStatus.state === 'pending' || bulkStatus.state === 'init') {
            await sleep(500);
            const fetchBulkResponse = await fetchApi(`${ENDPOINT}${bulkStatus.id}/`, {}, options);
            if (!fetchBulkResponse.ok) {
                let json;
                try {
                    json = await fetchBulkResponse.json();
                } catch (e) {
                    throw new ApiError('Error trying to get the bulk update transactions status');
                }
                throw new ApiError('Error trying to get the bulk update transactions status', json);
            }
            bulkStatus = await fetchBulkResponse.json();
        }

        const trResponse = await fetchApi(`/transactions/?bulk_transaction=${bulkStatus.id}&status=${CreateTransactionStatus.COMPLETED}`);
        if (!trResponse.ok) {
            let json;
            try {
                json = await trResponse.json();
            } catch (e) {
                throw new ApiError('Error trying to get the resulting transaction from the bulk update.');
            }
            throw new ApiError('Error trying to get the resulting transaction from the bulk update.', json);
        }
        return (await trResponse.json()) as Transaction[];
    };
}
