import { ProtonCommands } from '../../services/ProtonScalesService';
import { IProtonResponse, isProtonResponse } from './IProtonResponse';

export interface HealthCheckResponse extends IProtonResponse {
    header: {
        action: ProtonCommands.ARE_U_OK;
    } & Omit<IProtonResponse['header'], 'action'>;
    payload: {
        appVersion: string;
        state: string; //"IMOK"
        stationId: string;
    };
}

export function isHealthCheckResponse(response: unknown): response is HealthCheckResponse {
    return (
        isProtonResponse(response) && (response as HealthCheckResponse).header && (response as HealthCheckResponse).header.action === ProtonCommands.ARE_U_OK
    );
}
