import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../../TerminalInit';
import { Contact } from '../../../../api/contacts';
import { Product, useProducts } from '../../../../api/products';
import { useShops } from '../../../../api/shops';
import { Slot, SlotForTransaction, getRealIdFromId, getTransactionsFromSlot, useSlots } from '../../../../api/slots';
import { SpotLayoutItem, getSlotFromSpotLayoutItem, getTransactionsFromSpotlayoutItem, useSpotLayoutItems } from '../../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../../api/spotLayouts';
import { useSpot } from '../../../../api/spots';
import { StockFill, getValidSlotsIncludedInSFR, useMutateConfirmStockFillReport, useStockFills } from '../../../../api/stockFills';
import { useMutateCreateTransaction, useMutateTransactionStatus, useMutateUpdateTransaction, useTransactions } from '../../../../api/transactions';
import { TransactionStatus, TransactionStatusEventType, TransactionType, UpdateTransactionStatus } from '../../../../common/transactions';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import useActivity from '../../../../hooks/useActivity';
import useTransactionsWithSlots from '../../../../hooks/useTransactionsWithSlots';
import { Logger } from '../../../../logs/Logger';
import { AuthenticatedContact } from '../../../../services/contact/GlobalPermissions';
import { getRandomString } from '../../../../utils';
import BackAndHomeNavigationButtons from '../../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../../views/common/BaseView';
import LoadingView from '../../../../views/common/LoadingView';
import SpotLayout from '../../../../views/elements/SpotLayout';
import SelectStockFillReport from '../../../../views/vending/SelectStockFillReport';

interface ManualStockRefillActionProps extends AllowedActionViewProps {
    skipReportNumber?: boolean;
}

export const ManualStockRefillWorkflowGeneral = (props: ManualStockRefillActionProps) => {
    const [, newActivity] = useActivity();

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });

    const { data: shops } = useShops({ terminal: terminal });
    const allProducts = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: slots } = useSlots({ spot: spot?.id });
    const { data: transactionSlotMap } = useTransactionsWithSlots({
        spotId: spot?.id
    });
    const {
        data: stockFillReports,
        isLoading: sfrLoading,
        isSuccess: sfrSuccess
    } = useStockFills({
        account: JSON.stringify(spot?.account)
    });

    const [spotSlotMap, changeSpotSlotMap] = useState<Map<SpotLayoutItem, Slot>>(new Map());

    const [title, changeTitle] = useState<JSX.Element>();
    const [report, changeReport] = useState<StockFill | undefined>(undefined);
    const [reportNumberPageActive, changeReportNumberPageActive] = useState<boolean>(props.skipReportNumber !== undefined ? !props.skipReportNumber : true);

    //all possible submitted items
    const [selectedSlots, changeSelectedSlots] = useState<Slot[] | undefined>();
    useEffect(() => {
        if (!props.skipReportNumber && report !== undefined) {
            changeSelectedSlots(getValidSlotsIncludedInSFR(transactionSlotMap, report?.id));
        }
    }, [report, props.skipReportNumber]);

    const [editSelection, changeEditSelection] = useState<boolean>(props.skipReportNumber ? true : false);
    useEffect(() => {
        if (slots && spotLayoutItems) {
            const newMap = new Map();
            spotLayoutItems.forEach((item) => {
                const foundSlot = slots.find((s) => s.id === item.slot);
                if (foundSlot !== undefined) newMap.set(item, foundSlot);
            });
            changeSpotSlotMap(newMap);
        }
    }, [slots, spotLayoutItems]);

    const onBack = () => {
        newActivity();
        if (!reportNumberPageActive && !props.skipReportNumber) {
            if (editSelection) {
                changeEditSelection(false);
            } else changeReportNumberPageActive(true);
        } else {
            if (props.onHome) props.onHome();
        }
    };

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={onBack}
        />
    );

    const clickableSpotlayoutItem = (item: SpotLayoutItem, isAlreadySelected: boolean): boolean => {
        if (isAlreadySelected) return true; //User can always deselect
        const slot = spotSlotMap.get(item);
        if (slot !== undefined) {
            const prod = allProducts.data?.find((p) => p.slots.includes(slot!.real_id));
            if (prod === undefined) {
                return false;
            }
        }
        if (transactionSlotMap) {
            const relevantTransactions = getTransactionsFromSpotlayoutItem(transactionSlotMap, item);

            //With Report
            if (report !== undefined) {
                const isValid = relevantTransactions.find((tr) => {
                    return getValidSlotsIncludedInSFR(transactionSlotMap, report.id).find((s) => s.id === tr.id);
                });
                if (isValid !== undefined) return true;
            }

            //Without Report or if the previeus failed with a report
            //if there is space for a new transaction or there is a transaction that can be updated to dropoff done it should be valid
            let valid = false;

            valid = relevantTransactions.length === 0 || spotSlotMap.get(item)?.settings_transactions_limit_for_vending === undefined;
            if (
                !valid &&
                spotSlotMap.get(item) &&
                spotSlotMap.get(item)!.settings_transactions_limit_for_vending &&
                spotSlotMap.get(item)!.settings_transactions_limit_for_vending <= relevantTransactions.length
            ) {
                const foundTransaction = relevantTransactions.find((tr) => TransactionStatus.before_dropoff_states().includes(tr.status));
                valid = foundTransaction !== undefined;
            }

            if (!valid) {
                relevantTransactions?.forEach((tr) => {
                    if (TransactionStatus.before_dropoff_states().includes(tr.status)) valid = true;
                });
            }
            return valid;
        }
        return false;
    };

    const submitSelectedItems = (items: SpotLayoutItem[]) => {
        changeEditSelection(false);
        changeSelectedSlots(items.map((item) => getSlotFromSpotLayoutItem(slots!, item)));
    };

    useEffect(() => {
        if (report !== undefined) {
            changeTitle(
                <FormattedMessage
                    id='workflows.ManualStockRefill.ExtraFilledSlots.Title'
                    description='The title for the select slots screen during manual stockrefill.'
                    defaultMessage='You can edit the SLOTs that will be updated here.'
                />
            );
        } else {
            changeTitle(
                <FormattedMessage
                    id='workflows.ManualStockRefill.FilledSlots.Title'
                    description='The title for the select slots screen during manual stockrefill without report.'
                    defaultMessage='Please select all SLOTs that were filled.'
                />
            );
        }
    }, [report]);

    const [items, changeItems] = useState<SpotLayoutItem[]>([]);
    useEffect(() => {
        if (selectedSlots && spotLayoutItems && slots) {
            const list: SpotLayoutItem[] = [];
            spotLayoutItems.forEach((item) => {
                if (selectedSlots.includes(getSlotFromSpotLayoutItem(slots, item))) {
                    list.push(item);
                }
            });
            changeItems(list);
        }
    }, [selectedSlots, spotLayoutItems, slots]);

    const selectable = spotLayoutItems?.filter((item) => {
        return clickableSpotlayoutItem(item, items.includes(item));
    });

    if ((sfrLoading === true || sfrSuccess === false) && reportNumberPageActive) {
        return <LoadingView></LoadingView>;
    } else if (
        !reportNumberPageActive &&
        (spot === undefined || spotSlotMap === new Map() || spotLayoutItems === undefined || transactionSlotMap === undefined)
    ) {
        return <LoadingView></LoadingView>;
    } else if (reportNumberPageActive) {
        return (
            <SelectStockFillReport
                allReports={stockFillReports!.filter((re) => re.spot !== spot?.id).filter((re) => !re.is_completed)}
                saveReportNumber={(value) => {
                    changeReport(value);
                    newActivity();
                    changeReportNumberPageActive(false);
                }}
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    }

    if (!props.skipReportNumber && selectedSlots === undefined) {
        return <LoadingView></LoadingView>;
    }


    if (editSelection) {
        return (
            <BaseView
                navbarItems={backHomeNav}
                onInactivity={props.onInactivity}>
                <h1 className='title text-center'>{title}</h1>
                <SpotLayout
                    onSelect={(item) => {
                        if (items.includes(item)) {
                            changeItems(items.filter((i) => i.id !== item.id));
                        } else {
                            changeItems([...items, item]);
                        }
                    }}
                    highlightedItems={items}
                    selectableItems={selectable}
                />
                <div className='d-flex flex-row justify-content-between align-items-center m-2 w-100'>
                    <button
                        className='success-button success-button-small w-50'
                        onClick={() => {
                            newActivity();
                            submitSelectedItems(items);
                            changeItems([]);
                        }}>
                        submit
                    </button>
                    <button
                        className='warning-button warning-button-small w-50'
                        onClick={onBack}>
                        cancel
                    </button>
                </div>
            </BaseView>
        );
    } else {
        return (
            <ManualStockRefillTransactionUpdate
                spotSlotMap={spotSlotMap}
                report={report}
                selectedSlots={selectedSlots}
                contact={props.contact}
                transactionSlotMap={transactionSlotMap!}
                editSelection={() => {
                    changeEditSelection(!editSelection);
                }}
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    }
};

interface ManualStockRefillTransactionUpdateProps {
    contact: AuthenticatedContact | Contact | undefined;
    report: StockFill | undefined;
    selectedSlots: Slot[] | undefined;
    transactionSlotMap: Map<string, SlotForTransaction[]>;
    spotSlotMap: Map<SpotLayoutItem, Slot>;
    editSelection: () => void;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

const ManualStockRefillTransactionUpdate = (props: ManualStockRefillTransactionUpdateProps) => {
    const [, newActivity] = useActivity();

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: transactionSlotMap } = useTransactionsWithSlots({
        spotId: spot?.id
    });
    const { data: products } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);

    const [originalSlots] = useState<Slot[] | undefined>(
        props.report !== undefined ? getValidSlotsIncludedInSFR(transactionSlotMap, props.report.id) : undefined
    );
    const [includedSlots, changeIncludedSlots] = useState<Slot[] | undefined>();
    const [excludedSlots, changeExcludedSlots] = useState<Slot[] | undefined>();
    useEffect(() => {
        const include: Slot[] = [];
        const exclude: Slot[] = [];
        props.selectedSlots?.forEach((slot) => {
            const isFound = originalSlots?.find((s) => s.id === slot.id);
            if (isFound === undefined) {
                include.push(slot);
            }
        });
        originalSlots?.forEach((slot) => {
            const isFound = props.selectedSlots?.find((s) => s.id === slot.id);
            if (isFound === undefined) {
                exclude.push(slot);
            }
        });
        changeIncludedSlots(include);
        changeExcludedSlots(exclude);
    }, [originalSlots, props.selectedSlots]);

    const [toUpdateAmount, changeToUpdateAmount] = useState<number>(0);

    useEffect(() => {
        changeToUpdateAmount(() => {
            let amount = 0;
            if (excludedSlots && excludedSlots.length >= 1) amount += 1;
            if (includedSlots && includedSlots.length >= 1) amount += 1;
            return amount;
        });
    }, [excludedSlots, includedSlots]);

    const [updatePending, changeUpdatePending] = useState<boolean | undefined>(undefined);
    const [stockRefillUpdateSuccess, changeStockRefillUpdateSuccess] = useState<boolean | undefined>(undefined);
    useTransactions(undefined, {
        enabled: stockRefillUpdateSuccess === true,
        onSuccess: (transactions) => {
            changeStockRefillUpdateSuccess(false);
            changeUpdatePending(false);
        }
    });
    const confirmStockFillReport = useMutateConfirmStockFillReport({
        onSuccess: (stockFill) => {
            Logger.log('confirmed stockfill report', { contact: props.contact?.id }, stockFill);
            changeStockRefillUpdateSuccess(true);
            changeUpdatePending(false);
        },
        onError: (err) => {
            Logger.error(err);
            changeStockRefillUpdateSuccess(false);
        }
    });
    const dropoffTransaction = useMutateTransactionStatus({
        onSuccess: (transaction) => {
            changeToUpdateAmount(toUpdateAmount - 1);
        },
        onError: (err) => {
            changeToUpdateAmount(toUpdateAmount - 1);
            Logger.error(err);
        }
    });
    const cancelTransaction = useMutateUpdateTransaction({
        onSuccess: (transaction) => {
            changeToUpdateAmount(toUpdateAmount - 1);
        },
        onError: (err) => {
            changeToUpdateAmount(toUpdateAmount - 1);
            Logger.error(err);
        }
    });
    const createTransaction = useMutateCreateTransaction({
        onSuccess: (transaction) => {
            if (props.report === undefined) {
                dropoffTransaction.mutateAsync({
                    transaction: transaction,
                    transactionStatusUpdate: {
                        event: TransactionStatusEventType.DROPOFF,
                        dropoff: {
                            slot_id: transaction.slot_id
                        }
                    }
                });
            } else changeToUpdateAmount(toUpdateAmount - 1);
        },
        onError: (err) => Logger.error(err)
    });

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.report ? props.onBack : props.editSelection}
        />
    );

    useEffect(() => {
        if (props.report !== undefined && toUpdateAmount === 0 && updatePending === true) {
            confirmStockFillReport.mutate({
                report_code: props.report.report_code,
                updateVariables: {
                    completed_by: props.contact!.id,
                    account: props.contact!.account
                }
            });
        } else if (toUpdateAmount === 0 && updatePending === true) {
            changeUpdatePending(false);
        }
    }, [toUpdateAmount, updatePending]);

    const isBeingFilled = (item: SpotLayoutItem): boolean => {
        if (props.selectedSlots) {
            const isDefinetlyFilled = props.selectedSlots.find((s) => s.id === item.slot);
            if (isDefinetlyFilled !== undefined) return true;
        }
        return false;
    };

    const getSlotMap = (): Map<Product | null, SpotLayoutItem[]> | undefined => {
        if (spotLayoutItems === undefined) {
            return undefined;
        }
        const result: Map<Product | null, SpotLayoutItem[]> = new Map();
        spotLayoutItems.forEach((item) => {
            if (item.slot) {
                const product = products?.find((prod) => prod.slots.includes(getRealIdFromId(item.slot as string)));
                if (product && result.get(product)) {
                    result.set(product, [...result.get(product)!, item]);
                } else if (product) {
                    result.set(product, [item]);
                } else {
                    if (result.get(null)) {
                        result.set(null, [...result.get(null)!, item]);
                    } else result.set(null, [item]);
                }
            }
        });
        return result;
    };

    const submit = () => {
        newActivity();
        changeUpdatePending(true);
        if (includedSlots) {
            includedSlots.forEach((slot) => {
                const product = products?.find((p) => p.slots.includes(+slot.id));
                const transactionArray = getTransactionsFromSlot(props.transactionSlotMap, slot);
                const transaction = transactionArray.find((t) => TransactionStatus.before_dropoff_states().includes(t.status));
                if (transaction === undefined) {
                    createTransaction.mutate({
                        createTransaction: {
                            account_id: spot!.account,
                            slot_id: slot!.id,
                            tt_number: getRandomString(20),
                            receiver_id: undefined,
                            receiver_group_id: undefined,
                            sender_id: props.contact?.id,
                            sender_group_id: undefined,
                            shipping_notes: 'none',
                            notifications_config: undefined,
                            product_ids: product ? [product?.id] : [],
                            product_instances: undefined,
                            product_instance_ids: undefined,
                            type: TransactionType.VENDING
                        }
                    });
                } else if (props.report === undefined) {
                    dropoffTransaction.mutate({
                        transaction: transaction,
                        transactionStatusUpdate: {
                            event: TransactionStatusEventType.DROPOFF,
                            dropoff: {
                                slot_id: transaction.slot_id
                            }
                        }
                    });
                }
            });
        }
        if (excludedSlots) {
            excludedSlots.forEach((slot) => {
                const slotForTrans = props.transactionSlotMap.get(slot.id);
                if (slotForTrans) {
                    cancelTransaction.mutate({
                        transaction: slotForTrans[0].transaction,
                        updateTransaction: {
                            status: UpdateTransactionStatus.CANCELLED
                        }
                    });
                }
            });
        }
    };

    const [slotRowArray, changeSlotRowArray] = useState<JSX.Element[]>([]);
    const slotProductMap = getSlotMap();
    useEffect(() => {
        const result: JSX.Element[] = [];
        const itterator = slotProductMap?.keys();
        let key = itterator?.next().value;

        while ((key as Product) || key === null) {
            if (slotProductMap?.get(key) !== undefined) {
                const spotLayoutItems = slotProductMap.get(key)!.filter((i) => isBeingFilled(i));
                const slotNumbers: string[] = [];
                spotLayoutItems.forEach((item) => {
                    const slot = props.spotSlotMap.get(item);
                    if (slot) slotNumbers.push(slot.slot_nr);
                });
                if (spotLayoutItems.length > 0)
                    result.push(
                        <div className='information-card'>
                            <p className='title'>{key ? key.name : 'No linked product'}</p>
                            <p>{slotNumbers.join(', ')}</p>
                            <span className='text-success fw-bold'>total {spotLayoutItems.length}</span>
                        </div>
                    );
            }
            key = itterator?.next().value;
        }
        changeSlotRowArray(result);
    }, [slotProductMap]);

    if (updatePending === undefined) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}>
                <div>
                    {slotRowArray.length > 0 ? (
                        slotRowArray
                    ) : (
                        <p className='title text-center'>
                            <FormattedMessage
                                id='views.vending.manualstockrefillchangeoverview.NoEligibleSlotsForRefillMessage'
                                description='The message telling the user there are no slots with a transaction that is ready for dropoff. This would mean that the fill report is empty or it was not created yet.'
                                defaultMessage="There aren't any SLOTS eligible for a stock refill. Please make sure your configuration on the platform is correct, or select the SLOTS you filled with the include SLOTS button."
                            />
                        </p>
                    )}
                    <div className='d-flex flex-column align-items-center'>
                        <button
                            className='warning-button warning-button-small mb-2'
                            onClick={props.editSelection}>
                            <FormattedMessage
                                id='views.vending.manualstockrefillchangeoverview.IncludeSlotsButton'
                                description='The button that allows the user to edit the selection of slots.'
                                defaultMessage='edit selection'
                            />
                        </button>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <button
                        className='primary-button primary-button-small'
                        onClick={submit}>
                        submit
                    </button>
                </div>
            </BaseView>
        );
    } else if (updatePending === true) {
        return (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.vending.management.manualstockrefillupdate.loadingscreen'
                        description='The stock update loading message to inform the user that the update will run in the background.'
                        defaultMessage='The stock is being updated. This may take a while so if you are finished you can go back to the homescreen. The update will continue in the background.'
                    />
                }
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else {
        props.onHome ? props.onHome() : props.onInactivity && props.onInactivity();
        //this screen should not be shown but it is there as a failsafe.
        return (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.vending.management.manualstockrefillupdate.updateFinished'
                        description='The stock update loading message to inform the user that the update is finished and he/she will be redirected to home.'
                        defaultMessage='The update is finished you are being redirected to the home screen.'
                    />
                }
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    }
};
