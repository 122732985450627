import CallToAction, { CallToActionSize } from '../domain/CallToAction';

interface CallToActionElementProps extends CallToAction {
    className?: string;
}

function CallToActionElement(props: CallToActionElementProps) {
    const btnClassBs = props.type ? `btn-${props.type} ` : 'btn-primary ';
    const btnClassSize = props.size && props.size !== CallToActionSize.NORMAL ? `btn-${props.size} ` : '';

    return (
        <button
            className={`${props.className ? props.className.trim() + ' ' : ''}btn ${btnClassBs}${btnClassSize}`}
            onClick={props.onClick}>
            {props.message}
        </button>
    );
}

export default CallToActionElement;
