import { Transaction, TransactionStatus } from '../common/transactions';
import { Logger } from '../logs/Logger';
import { IndexedDbStores, getConnection } from '../services/indexedDb/IndexedDB';

export async function addTransactionInProgress(transaction: Transaction) {
    const db = await getConnection();
    const dbTransaction = db.transaction([IndexedDbStores.TRANSACTIONS_IN_PROGRESS], 'readwrite'); //the error
    const store = dbTransaction.objectStore(IndexedDbStores.TRANSACTIONS_IN_PROGRESS);

    const validUntil = new Date();
    if (transaction.status === TransactionStatus.DROPOFF_IN_PROGRESS) {
        if (transaction.settings_dropoff_in_progress_timeout_in_sec_value) {
            validUntil.setSeconds(validUntil.getSeconds() + transaction.settings_dropoff_in_progress_timeout_in_sec_value);
        }
    } else if (transaction.status === TransactionStatus.PICKUP_IN_PROGRESS) {
        if (transaction.settings_pickup_in_progress_timeout_in_sec_value) {
            validUntil.setSeconds(validUntil.getSeconds() + transaction.settings_pickup_in_progress_timeout_in_sec_value);
        }
    } else if (transaction.status === TransactionStatus.CANCELLED) {
        if (transaction.settings_remove_parcel_in_progress_timeout_in_sec_value) {
            validUntil.setSeconds(validUntil.getSeconds() + transaction.settings_remove_parcel_in_progress_timeout_in_sec_value);
        }
    } else {
        console.info('The given transaction is not in the correct state to add to the IN_PROGRESS store.');
        return;
    }

    const addedTransaction = store.put({ id: transaction.id, validUntil: validUntil.toLocaleString() });
    addedTransaction.onsuccess = (event) => {
        Logger.log(event);
    };
    addedTransaction.onerror = (event) => {
        Logger.error(event);
    };
}

export async function checkIfTransactionInProgressIsStillValid(transaction: Transaction): Promise<boolean> {
    const db = await getConnection();
    const dbTransaction = db.transaction(IndexedDbStores.TRANSACTIONS_IN_PROGRESS, 'readwrite');
    const store = dbTransaction.objectStore(IndexedDbStores.TRANSACTIONS_IN_PROGRESS);

    const transactionToCheck = store.get(transaction.id);
    return new Promise<boolean>((resolve, reject) => {
        if (TransactionStatus.is_in_progress_status().includes(transaction.status)) {
            transactionToCheck.onsuccess = () => {
                try {
                    const now = new Date();
                    const later = new Date(now.setHours(now.getHours() + 24)); //only delete entries when invalid for more then 24 hours
                    if (new Date(transactionToCheck.result.validUntil) < now) {
                        resolve(true);
                    } else if (new Date(transactionToCheck.result.validUntil) < later) {
                        const remove = store.delete(transaction.id);
                        remove.onsuccess = () => {
                            Logger.log('Transaction found but no longer valid (thus it will be removed from the database)');
                            resolve(false);
                        };
                    }
                } catch (error) {
                    Logger.log('No transaction found so should fall back on the backend');
                    resolve(false);
                }
            };
        } else {
            transactionToCheck.onsuccess = () => {
                const remove = store.delete(transaction.id);
                remove.onsuccess = () => {
                    Logger.log('Transaction found but no longer valid (thus it will be removed from the database)');
                    resolve(false);
                };
            };
            Logger.log('The given transaction is not in the correct state to be in the IN_PROGRESS store.');
        }
    });
}
