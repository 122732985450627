import { ProtonHardwareDriverOption } from './ProtonPacketUtils';

export default class ProtonDevice {
    id: number[];
    eslId: number | undefined;
    clusterUri: string;
    stationId: string;

    constructor(option: ProtonHardwareDriverOption) {
        this.clusterUri = option.config.clusterURI;
        this.id = option.config.deviceId;
        this.eslId = option.config.eslId;
        this.stationId = option.config.stationId;
    }

    getDevices(): string[] {
        return this.id.map((id) => this.clusterUri + '/' + id + '?device=weight_sensor');
    }

    getESL(): string | undefined {
        if (this.eslId === undefined) return undefined;
        return this.clusterUri + '/' + this.eslId + '?device=esl&esl_role=master';
    }

    getMapping(): string[] {
        const esl = this.getESL();
        if (esl !== undefined) {
            return [...this.getDevices(), esl];
        }
        return [...this.getDevices()];
    }
}
