import { useEffect } from 'react';

import { AccessToken } from '../Connection';
import { ServiceWorkerInit } from '../ServiceWorkerInit';
import { ServiceWorkerInitState } from '../TerminalInit';
import { Logger } from '../logs/Logger';

interface Props {
    serviceWorker: ServiceWorkerInit;
    serviceWorkerInitState: ServiceWorkerInitState;
    accessToken: AccessToken | null;

    changeAccessToken: (value: AccessToken | null) => void;
    changeServiceWorkerInitState: (walue: ServiceWorkerInitState) => void;
}

export default function useRequiresAuthCheck(props: Props) {
    useEffect(() => {
        if (props.serviceWorkerInitState === ServiceWorkerInitState.REQUIRES_AUTH && props.accessToken != null) {
            props.changeAccessToken(null);
            props.serviceWorker
                .newAccessToken(props.accessToken)
                .then(() => {
                    props.changeServiceWorkerInitState(ServiceWorkerInitState.DONE);
                })
                .catch((e) => {
                    Logger.error('Could not set access token in service worker', {}, e);
                    props.changeServiceWorkerInitState(ServiceWorkerInitState.ERROR);
                });
        }
    }, [props.serviceWorker, props.serviceWorkerInitState, props.accessToken]);
}
