import { useEffect, useState } from 'react';

import { SpotLayoutItem } from '../../../api/spotLayoutItems';
import ProtonDevice from '../../../scales/proton/ProtonDevice';
import { ProtonPacketUtils } from '../../../scales/proton/ProtonPacketUtils';
import ProtonScalesService from '../../../scales/services/ProtonScalesService';
import { useHardwareConnection } from '../../../terminal/useHardwareConnection';

interface Props {
    spotLayoutItem?: SpotLayoutItem;
}

export default function NullScaleButton(props: Props) {
    const connection = useHardwareConnection();
    const [device, changeDevice] = useState<ProtonDevice | undefined>();
    const [service, changeService] = useState<ProtonScalesService | undefined>();

    useEffect(() => {
        if (props.spotLayoutItem) {
            const result = ProtonPacketUtils.mapToProtonDevices([props.spotLayoutItem]);
            if (result.length > 0) changeDevice(result[0]);
        }
    }, [props.spotLayoutItem]);
    useEffect(() => {
        if (connection !== null && service === undefined) {
            changeService(new ProtonScalesService(connection));
        }
    }, [connection]);

    if (props.spotLayoutItem === undefined || device === undefined || connection === null || service === undefined) {
        return <></>;
    }

    return (
        <button
            onClick={() => {
                service.zeroScales([device]);
            }}
            className='success-button btn-lg'>
            Tare
        </button>
    );
}
