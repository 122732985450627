import { FormattedMessage } from 'react-intl';

import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';

interface BadgeRegistrationNotPossibleViewProps {
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;
}

export default function BadgeRegistrationNotPossibleView(props: BadgeRegistrationNotPossibleViewProps) {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationNotPossibleView.title'
                    description='The title on the view that tells the Nike user badge registration is not possible without employee id or badge nr.'
                    defaultMessage='Badge registration not possible'
                />
            </ViewTitle>
            <p className='mb-4'>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationNotPossibleView.message'
                    description='The message on the view that tells the Nike user badge registration is not possible without employee id or badge nr.'
                    defaultMessage='You need your employee ID or badge number to register. Contact your coach for assistance.'
                />
            </p>
            <button
                className='primary-button'
                onClick={props.onHome}>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationNotPossibleView.leaveCallToAction'
                    description='The call to action to leave on the view that tells the Nike user badge registration is not possible without employee id or badge nr.'
                    defaultMessage='Leave DistriSPOT'
                />
            </button>
        </BaseView>
    );
}
