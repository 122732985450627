import Connection, { AccessToken, SetLightsOptions } from '../../Connection';
import { SpotLayoutItem } from '../../api/spotLayoutItems';
import { WindowsEventListener } from '../../events/windows/WindowsEventListener';
import { Logger } from '../../logs/Logger';
import { PSPGHardwareObject, SlotAction } from '../../services/platformHardwareDriver/utils/PSPGHardwareOptions';
import TerminalInterface from '../TerminalInterface';
import { TerminalSoftware } from './TerminalConnectionManager';

export default class WindowsTerminalSoftwareHelper implements Connection {
    private static instance: WindowsTerminalSoftwareHelper | null;
    private terminalApp: TerminalInterface;
    private slotObject: PSPGHardwareObject;
    private config: any;

    private softwareVersion: number | null = null;

    private constructor(terminalApp: TerminalInterface, slotObject: PSPGHardwareObject) {
        this.terminalApp = terminalApp;
        this.slotObject = slotObject;
        new WindowsEventListener(this);
    }
    setWebURL(url: string): void {
        throw new Error('Method not implemented.');
    }
    setFan(on: boolean): void {
        throw new Error('Method not implemented.');
    }

    public static getInstance(terminalApp: TerminalInterface, slotObject: PSPGHardwareObject): WindowsTerminalSoftwareHelper {
        if (!WindowsTerminalSoftwareHelper.instance) {
            WindowsTerminalSoftwareHelper.instance = new WindowsTerminalSoftwareHelper(terminalApp, slotObject);
        }

        return WindowsTerminalSoftwareHelper.instance;
    }

    getTerminalSoftware() {
        return TerminalSoftware.WINDOWS;
    }

    getTerminalSoftwareVersion(): number {
        if (this.softwareVersion === null) {
            return 0;
        } else {
            return +this.softwareVersion;
        }
    }
    init(): Promise<AccessToken | null> {
        Logger.log('should be handled by the parent');
        throw new Error('Method not implemented.');
    }
    setConfiguration(doorOpenTimeInSeconds = 5): void {
        Logger.log('setting doorOpenTimeInSeconds on windows driver config to ' + doorOpenTimeInSeconds + ' seconds');
        try {
            this.config = {
                doorOpenTimeInSeconds: doorOpenTimeInSeconds
            };
            Logger.log(this.config);
            this.terminalApp.configure(this.config);
        } catch (e) {
            Logger.error('Something went wrong trying to set the dooropentime', {}, e);
        }
    }
    sendSpotLayoutConfig(spotLayoutItems: SpotLayoutItem[]): void {
        Logger.log('not relevant yet for windows driver');
    }
    reConfigureTerminal(): Promise<AccessToken | null> {
        Logger.log('should be handled by the parent');
        throw new Error('Method not implemented.');
    }
    reset(): void {
        this.terminalApp.resetSystem();
    }

    async openSlot(object: PSPGHardwareObject[] | null): Promise<void> {
        if (object === null) return;
        Logger.log('for windows');
        this.slotObject.driver = object[0].driver;
        this.slotObject.column = object[0].column;
        this.slotObject.row = object[0].row;
        this.slotObject.ip = object[0].ip;
        this.slotObject.action = object[0].action;
        const code = await this.confirmCorrectStatus();
        return new Promise((resolve, reject) => {
            switch (code) {
                case 0:
                    break;
                case -1:
                    if (object[0].action === SlotAction.CLOSE) break;
                    throw new Error('One of the doors is still opened, please wait until it is closed and try again.');
                case -2:
                    throw new Error('The door could not be opened, please try again later.');
                case -4:
                    throw new Error('Vending machine is still busy with the previeus door, please wait until it is closed and try again.');
            }
            this.terminalApp.openSlot(this.slotObject);
            resolve();
        });
    }

    /**
     * Will do nothing if the status is correct. But it will throw errors when the status received is incorrect.
     * (Error cases: One of the doors is still open, the multispot is not able to open a door)
     */
    private async confirmCorrectStatus(): Promise<number> {
        const result = await this.terminalApp.getStatus();
        const code = await result.result_code;
        return new Promise((resolve, reject) => {
            resolve(code);
        });
    }

    openSlotRange(first: string, last: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    keepAlive(): void {
        this.terminalApp.keepAlive();
    }
    resetPcb(): void {
        Logger.log('reset pcb will simply restart the windows PC');
        this.terminalApp.resetPCB();
    }
    setLights(options: SetLightsOptions): Promise<void> {
        Logger.log('lights not available for windows terminals');
        throw new Error('Method not implemented.');
    }
    resetCache(): void {
        this.terminalApp.resetCache();
    }
    resetData(): void {
        this.terminalApp.resetData();
    }
    broadcast(data: any): void {
        Logger.log('ws commands to terminal not available yet for windows terminals');
        throw new Error('Method not implemented.');
    }
    send(id: string, data: any): void {
        Logger.log('ws commands to terminal not available yet for windows terminals');
        throw new Error('Method not implemented.');
    }
}
