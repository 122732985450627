import { useContext, useEffect } from 'react';

import { TerminalContext, TerminalContextInterface } from './TerminalInit';
import { TerminalWorkflowType } from './api/terminals';
import nikeLogo from './static/nike/nike-logo.png';
import LendingWorkflow from './workflow/lending/LendingWorkflow';
import MainWorkflow from './workflow/main/MainWorkflow';
import WarehouseWorkflow from './workflow/warehouse/WarehouseWorkflow';

const TerminalWorkflow = () => {
    const { terminal, changeThemeLogo } = useContext<TerminalContextInterface>(TerminalContext);

    const workflow = terminal ? terminal.workflow : undefined;

    useEffect(() => {
        if (workflow === TerminalWorkflowType.IMES_DEXIS_NIKE) {
            changeThemeLogo(nikeLogo);
        }
    }, [workflow]);

    if (terminal) {
        //TODO: workflows should eventually be all the same (checks should happen in the actions they can take)
        if (terminal.workflow === TerminalWorkflowType.LENDING) {
            return <LendingWorkflow />;
        } else if (terminal.workflow === TerminalWorkflowType.WAREHOUSE) {
            //TODO change to warehouse only
            return <WarehouseWorkflow />;
        } else {
            return <MainWorkflow />;
        }
    }

    return <MainWorkflow />;
};

export default TerminalWorkflow;
