import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Contact, useMutateContactAdd } from '../../api/contacts';
import ContactForm, { ContactFormFields } from '../../forms/ContactForm';
import { Logger } from '../../logs/Logger';

interface RegisterNewContactFormProps {
    onContactAdded: (contact: Contact) => void;
    accountId: number;

    onHome?: () => void;
    onInactivity?: () => void;
    onBack?: () => void;
}

const RegisterNewContactForm = (props: RegisterNewContactFormProps) => {
    const intl = useIntl();
    const mutateContactAdd = useMutateContactAdd({
        onSuccess: (contact) => {
            changeLoading(false);
            props.onContactAdded(contact);
        },
        onError: (err) => {
            changeLoading(false);
            Logger.log(err);
        }
    });
    const [loading, changeLoading] = useState<boolean>(false);

    const onSubmit = (contact: Contact) => {
        changeLoading(true);
        mutateContactAdd.mutate({ contact: contact });
    };

    const title = intl.formatMessage({
        id: 'registerNewContactForm.title',
        description: 'Message telling the user to enter as much info as possible for registration',
        defaultMessage: 'Please enter as much of your information as possible.'
    });

    return (
        <ContactForm
            title={title}
            loading={loading}
            onSubmit={onSubmit}
            accountId={props.accountId}
            fields={[
                ContactFormFields.FIRSTNAME,
                ContactFormFields.LASTNAME,
                ContactFormFields.EMAIL,
                ContactFormFields.PHONENUMBER,
                ContactFormFields.BIRTHDAY,
                ContactFormFields.EMPLOYEE_ID,
                ContactFormFields.BADGE_NR
            ]}
            requiredFields={[ContactFormFields.FIRSTNAME, ContactFormFields.LASTNAME, ContactFormFields.BIRTHDAY, ContactFormFields.PHONENUMBER]}
            onInactivity={props.onInactivity}
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );
};

export default RegisterNewContactForm;
