import React from 'react';
import { Color } from 'react-bootstrap/esm/types';

import ActionCard from '../domain/ActionCard';

interface ActionCardElementProps {
    children: React.ReactNode;
    description?: React.ReactNode;
    callToAction?: ActionCard;
    fullWidth?: boolean;
    onClick?: () => void;
    onCallToActionClick?: () => void;
    highlight?: boolean;
    color?: Color;
}

export function ActionCardElement(props: ActionCardElementProps) {
    const fullWidth = props.fullWidth === true;

    let style = props.highlight ? 'blue' : props.color;
    style = props.color ? props.color : style;

    if (fullWidth) {
        return (
            <div className='col-12 p-1'>
                <button
                    className={
                        'col-12 dashboard-action dashboard-action-' + (props.highlight ? (props.color ? props.color : 'primary') : 'light') + ' card clickable'
                    }
                    onMouseUp={props.onClick}>
                    <div className='card-body'>
                        {props.callToAction ? (
                            <div className='d-flex'>
                                <div className={'col-6 ' + (props.highlight ? 'text-light' : 'text-' + (props.color ? props.color : 'primary'))}>
                                    {props.children}
                                </div>
                                <div className='col-6 d-flex align-items-center'>
                                    <button
                                        className='btn btn-outline-primary alert-primary'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (props.onCallToActionClick) {
                                                props.onCallToActionClick();
                                            }
                                        }}>
                                        {props.callToAction.actionText} <i className='bi bi-arrow-right' />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <span className={'' + (props.highlight ? 'text-light' : 'text-' + (props.color ? props.color : 'primary'))}>{props.children}</span>
                        )}
                    </div>

                    <div className={'dashboard-action-footer dashboard-action-footer-' + (props.color ? props.color : 'primary')}>
                        <h5 className='text-light font-weight-bold py-2'>{props.description}</h5>
                    </div>
                </button>
            </div>
        );
    }

    return (
        <div className='col-6 p-2'>
            <button
                type='button'
                className={'dashboard-action dashboard-action-' + (props.highlight ? (props.color ? props.color : 'primary') : 'light') + ' card clickable'}
                onMouseUp={props.onClick}>
                <div
                    className={
                        'card-body d-flex flex-row justify-content-center align-items-center ' +
                        (props.highlight ? 'text-light' : 'text-' + (props.color ? props.color : 'primary'))
                    }>
                    {props.children}
                </div>
                {props.description ? (
                    <div className={'dashboard-action-footer dashboard-action-footer-' + (props.color ? props.color : 'primary')}>
                        <h5 className='text-light font-weight-bold py-2'>{props.description}</h5>
                    </div>
                ) : (
                    <></>
                )}
            </button>
        </div>
    );
}
