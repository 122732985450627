import { omit } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FetchQueryOptions, QueryClient, UseMutationOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import {
    CreateTransaction,
    Transaction,
    TransactionStatus,
    TransactionStatusResult,
    TransactionStatusUpdate,
    UpdateTransaction,
    transactionStatusFromUpdate
} from '../common/transactions';
import { Logger } from '../logs/Logger';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { ApiQueryParams, queryEnabled, queryParamsToCacheKeys } from './baseQueryParams';
import { ApiError, FetchOptions, postApi, putApi } from './utils';

const defaultConfig = {
    staleTime: 0,
    cacheTime: Infinity
};

const transactionsViewSet: ApiViewSet = {
    baseName: 'transactions'
};

interface MutateTransactionStatusVariables {
    transaction: Transaction;
    transactionStatusUpdate: TransactionStatusUpdate;
}

interface MutateCreateTransactionVariables {
    createTransaction: CreateTransaction;
}

interface MutateUpdateTransactionVariables {
    transaction: Transaction;
    updateTransaction: UpdateTransaction;
}

interface MutateMoveTransactionVariables {
    transactionId: string;
    body: {
        new_spot_id: string;
        new_slot_id?: string;
        overwrite?: boolean;
    };
}

enum TransactionsQueryParams {
    SLOT = 'slot',
    SPOT = 'spot',
    SENDER = 'sender',
    RECEIVER = 'receiver',
    SENDER_GROUP = 'sender_group',
    RECEIVER_GROUP = 'receiver_group',
    STATUS = 'status',
    CREATED_DATE_AFTER = 'created_date_after',
    CREATED_DATE_BEFORE = 'created_date_before',

    BULK_ID = 'bulk_transaction'
}

function fetchTransactionsApi(queryParams?: ApiQueryParams<TransactionsQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Transaction[]> {
    return apiList<Transaction, TransactionsQueryParams>(transactionsViewSet, queryParams, fetchOptions);
}

function fetchTransactionApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<Transaction> {
    return apiDetail<Transaction>(transactionsViewSet, options, fetchOptions);
}

export function updateTransactionStatus(options?: FetchOptions): (variables: MutateTransactionStatusVariables) => Promise<TransactionStatusResult> {
    return async (variables: MutateTransactionStatusVariables): Promise<TransactionStatusResult> => {
        const response = await postApi(`/transactions/${variables.transaction.id}/status/`, variables.transactionStatusUpdate, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error in status update of transaction');
            }
            throw new ApiError('Error in status update of transaction', json);
        }
        return await response.json();
    };
}

export function createTransaction(options?: FetchOptions): (variables: MutateCreateTransactionVariables) => Promise<Transaction> {
    return async (variables: MutateCreateTransactionVariables): Promise<Transaction> => {
        const response = await postApi(`/transactions/`, variables.createTransaction, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating transaction');
            }
            throw new ApiError('Error creating transaction', json);
        }
        return await response.json();
    };
}

export function updateTransaction(options?: FetchOptions): (variables: MutateUpdateTransactionVariables) => Promise<Transaction> {
    return async (variables: MutateUpdateTransactionVariables): Promise<Transaction> => {
        const response = await putApi(`/transactions/${variables.transaction.id}/`, variables.updateTransaction, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating transaction');
            }
            throw new ApiError('Error updating transaction', json);
        }
        return await response.json();
    };
}

export function moveTransaction(options?: FetchOptions): (variables: MutateMoveTransactionVariables) => Promise<Transaction> {
    return async (variables: MutateMoveTransactionVariables): Promise<Transaction> => {
        const body = variables.body;
        if (body.overwrite === undefined) body.overwrite = false;
        const response = await postApi(`/transactions/${variables.transactionId}/change_spot_slot/`, variables.body, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error moving transaction');
            }
            throw new ApiError('Error moving transaction', json);
        }
        return await response.json();
    };
}

function getTransactionsUseQueryOptions(
    queryParams?: ApiQueryParams<TransactionsQueryParams> | null,
    queryOptions?: UseQueryOptions<Transaction[]>,
    fetchOptions?: FetchOptions
): UseQueryOptions<Transaction[]> {
    const config = {
        ...defaultConfig,
        enabled: queryEnabled(queryParams),
        ...queryOptions
    };

    return {
        queryKey: ['transactions', queryParamsToCacheKeys<TransactionsQueryParams>(TransactionsQueryParams, queryParams)],
        queryFn: fetchTransactionsApi(queryParams, fetchOptions),
        ...config
    };
}

export async function fetchSpotTransactions(
    queryClient: QueryClient,
    queryParams?: ApiQueryParams<TransactionsQueryParams> | null,
    queryOptions?: FetchQueryOptions<Transaction[]>,
    fetchOptions?: FetchOptions
): Promise<Transaction[]> {
    return await queryClient.fetchQuery(getTransactionsUseQueryOptions(queryParams, queryOptions, fetchOptions));
}

export function useTransactions(
    queryParams?: ApiQueryParams<TransactionsQueryParams> | null,
    queryOptions?: UseQueryOptions<Transaction[]>,
    fetchOptions?: FetchOptions
) {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Transaction[]>(getTransactionsUseQueryOptions(queryParams, queryOptions, fetchOptions));
}

export function useTransaction(transactionUrl?: string, transactionId?: number | string, enabled?: boolean, fetchOptions?: FetchOptions) {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    let detailOptions: DetailOptions;
    if (transactionUrl) {
        detailOptions = {
            url: transactionUrl
        };
    } else if (transactionId) {
        detailOptions = {
            id: transactionId
        };
    } else {
        detailOptions = {
            id: '' // This case only occurs when no transactionId or transactionUrl is provided, and the query is never actually executed
        };
    }

    const [toUpdateTransaction, changeToUpdateTransaction] = useState<Transaction | undefined>(undefined);

    useEffect(() => {
        if (enabled) {
            const interval = setInterval(async () => {
                changeToUpdateTransaction(await fetchTransactionApi(detailOptions, fetchOptions)());
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [enabled]);

    return toUpdateTransaction;
}

function updateTransactionsData(transaction: Transaction, transactions?: Transaction[]): Transaction[] | undefined {
    if (!transactions) return undefined;

    let success = false;
    const resultTransactions = transactions.map((tx) => {
        if (tx.id === transaction.id) {
            success = true;
            return transaction;
        } else {
            return tx;
        }
    });
    return success ? resultTransactions : undefined;
}

export function useMutateTransactionStatus(
    options?: UseMutationOptions<TransactionStatusResult, unknown, MutateTransactionStatusVariables>,
    fetchOptions?: FetchOptions
) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<TransactionStatusResult, unknown, MutateTransactionStatusVariables> = {
        ...options,
        onMutate: async (variables) => {
            Logger.log('Mutating transaction status.', { transaction: variables.transaction.id });
            const status = transactionStatusFromUpdate(variables.transactionStatusUpdate);
            const transaction: Transaction = {
                status: status,
                ...omit(variables.transaction, ['status'])
            };

            queryClient.setQueryData(['transaction', variables.transaction.id], transaction);
            if (TransactionStatus.is_in_progress_status().includes(variables.transaction.status)) {
                await queryClient.invalidateQueries(['transaction', variables.transaction.id]);
            }

            let transactionsData = queryClient.getQueryData(['transactions', queryParamsToCacheKeys<TransactionsQueryParams>(TransactionsQueryParams)]) as
                | Transaction[]
                | undefined;
            transactionsData = updateTransactionsData(transaction, transactionsData);
            if (transactionsData) {
                queryClient.setQueryData(['transactions', queryParamsToCacheKeys<TransactionsQueryParams>(TransactionsQueryParams)], transactionsData);
            }

            let transactionsSpotData = queryClient.getQueryData([
                'transactions',
                queryParamsToCacheKeys<TransactionsQueryParams>(TransactionsQueryParams, { spot: variables.transaction.spot_id })
            ]) as Transaction[] | undefined;
            transactionsSpotData = updateTransactionsData(transaction, transactionsSpotData);
            if (transactionsSpotData) {
                queryClient.setQueryData(
                    ['transactions', queryParamsToCacheKeys<TransactionsQueryParams>(TransactionsQueryParams, { spot: variables.transaction.spot_id })],
                    transactionsSpotData
                );
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['transaction', variables.transaction.id]);
            await queryClient.invalidateQueries(['transactions']); // Invalidates all 'transactions' queries, even those with additional information
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(updateTransactionStatus(fetchOptions), config);
}

export function useMutateCreateTransaction(options?: UseMutationOptions<Transaction, unknown, MutateCreateTransactionVariables>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<Transaction, unknown, MutateCreateTransactionVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            await queryClient.setQueryData(['transaction', data.id], data);
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['transactions']); // Invalidates all 'transactions' queries, even those with additional information
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(createTransaction(fetchOptions), config);
}

export function useMutateUpdateTransaction(options?: UseMutationOptions<Transaction, unknown, MutateUpdateTransactionVariables>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<Transaction, unknown, MutateUpdateTransactionVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            await queryClient.setQueryData(['transaction', data.id], data);
            if (TransactionStatus.is_in_progress_status().includes(data.status)) {
                await queryClient.invalidateQueries(['transaction', data.id]);
            }
            if (options?.onSuccess) {
                await options.onSuccess(data, variables, context);
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['transaction', variables.transaction.id]);
            await queryClient.invalidateQueries(['transactions']); // Invalidates all 'transactions' queries, even those with additional information
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(updateTransaction(fetchOptions), config);
}

export function useMutateMoveTransaction(options?: UseMutationOptions<Transaction, unknown, MutateMoveTransactionVariables>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();

    const config: UseMutationOptions<Transaction, unknown, MutateMoveTransactionVariables> = {
        ...options,
        onSuccess: async (data, variables, context) => {
            if (data.remove_parcel_code !== undefined && data.remove_parcel_code !== null) {
                await queryClient.setQueryData(['transaction', data.id], data);
                if (TransactionStatus.is_in_progress_status().includes(data.status)) {
                    await queryClient.invalidateQueries(['transaction', variables.transactionId]);
                }
                if (options?.onSuccess) {
                    await options.onSuccess(data, variables, context);
                }
            }
        },
        onSettled: async (data, error, variables) => {
            await queryClient.invalidateQueries(['transaction', variables.transactionId]);
            await queryClient.invalidateQueries(['transactions']); // Invalidates all 'transactions' queries, even those with additional information
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(moveTransaction(fetchOptions), config);
}
