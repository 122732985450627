import { useContext } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { Contact } from '../api/contacts';
import { Product } from '../api/products';
import { TerminalWorkflowType } from '../api/terminals';
import { isNikeContactData } from '../workflow/nike/api';

export interface ProductFilterProps {
    contact: Contact;
    products: Product[];
}

const useProductFilter = () => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);

    const productFilter = (props: ProductFilterProps): Product[] => {
        let result = props.products;

        if (terminal?.workflow == TerminalWorkflowType.IMES_DEXIS_NIKE) {
            result = nikeOnlyMaintenanceTeamShouldGetMartinSports(result, props.contact);
        }
        return result;
    };

    return [productFilter] as const;
};

const nikeOnlyMaintenanceTeamShouldGetMartinSports = (products: Product[], contact: Contact): Product[] => {
    if (isNikeContactData(contact.additional_data) && contact.additional_data.team_id?.startsWith('D3')) {
        return products;
    } else {
        return products.filter((p) => !p.name.includes('MARTEN XXSPORTS PRO'));
    }
};

export default useProductFilter;
