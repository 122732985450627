import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact, SearchContact, useMutateSearchContact } from '../../../api/contacts';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import useTerminal from '../../../hooks/useTerminal';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import ErrorView from '../../../views/common/ErrorView';
import LoadingView from '../../../views/common/LoadingView';
import BadgeRegistrationBadgeNrView from '../../../views/nike/registration/BadgeRegistrationBadgeNrView';
import BadgeRegistrationConfirmationView from '../../../views/nike/registration/BadgeRegistrationConfirmationView';
import BadgeRegistrationEmployeeIdView from '../../../views/nike/registration/BadgeRegistrationEmployeeIdView';
import BadgeRegistrationNotPossibleView from '../../../views/nike/registration/BadgeRegistrationNotPossibleView';
import EmployeeSelectView from '../../../views/nike/registration/EmployeeSelectView';
import RegisterNewContactForm from '../../../views/registration/RegisterNewContactForm';
import CoachApprovalRequiredWorkflow from '../CoachApprovalRequiredWorkflow';
import ContactAddBadgeWorkflow from './ContactAddBadgeWorkflow';

interface EmployeeSearchWorkflowProps {
    badgeValue: string;
    onEmployeeFound: (employee: Contact) => void;
    searchByBadgeNrRequiresCoach?: boolean;
    overwriteIsEnabled?: boolean;
    onInactivity?: () => void;
    onHome?: () => void;
}

interface NikeSearchContactEmployeeId extends SearchContact {
    employee_id: string;
}

interface NikeSearchContactBadgeNr extends SearchContact {
    badge_nr: string;
}

export default function EmployeeSearchWorkflow(props: EmployeeSearchWorkflowProps) {
    const [confirmed, changeConfirmed] = useState(false);
    const [employee, changeEmployee] = useState<Contact | null>(null);
    const [employees, changeEmployees] = useState<Contact[] | null>(null);
    const [employeeId, changeEmployeeId] = useState<string | null>(null);
    const [employeeIdAvailable, changeEmployeeIdAvailable] = useState(true);
    const [badgeNr, changeBadgeNr] = useState<string | null>(null);
    const [badgeNrAvailable, changeBadgeNrAvailable] = useState(true);
    const [coachApproval, changeCoachApproval] = useState(false);

    const terminal = useTerminal();
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    const mutateSearchContact = useMutateSearchContact();

    useEffect(() => {
        if (spot && (employeeId || badgeNr)) {
            let search: SearchContact;
            if (employeeId) {
                const searchContact: NikeSearchContactEmployeeId = {
                    account: JSON.stringify(spot.account),
                    employee_id: employeeId
                };
                search = searchContact;
            } else {
                const searchBadge: NikeSearchContactBadgeNr = {
                    account: JSON.stringify(spot.account),
                    badge_nr: badgeNr!
                };
                search = searchBadge;
            }
            mutateSearchContact.mutate(
                {
                    searchContact: search
                },
                {
                    onSuccess: (data) => {
                        changeEmployees(data);
                    }
                }
            );
        }
    }, [spot, employeeId, badgeNr]);

    useEffect(() => {
        if (employees !== null && employees.length === 1) {
            onEmployeeSelect(employees[0]);
        }
    }, [employees]);

    function onEmployeeIdEntered(employeeId: string) {
        changeEmployeeId(employeeId);
    }

    function onEmployeeIdNotAvailable() {
        changeEmployeeIdAvailable(false);
    }

    function onBadgeNrEntered(badgeNr: string) {
        changeBadgeNr(badgeNr);
    }

    function onBadgeNrNotAvailable() {
        changeBadgeNrAvailable(false);
    }

    function onEmployeeSelect(employee: Contact | null) {
        if (employee !== null) {
            changeEmployee(employee);
        }
    }

    function onConfirm() {
        changeConfirmed(true);
    }

    function onCoachApproval() {
        changeCoachApproval(true);
    }

    function reset(full = false) {
        changeEmployeeId(null);
        changeEmployees(null);
        changeEmployee(null);
        changeBadgeNr(null);
        changeConfirmed(false);
        if (full) {
            changeEmployeeIdAvailable(true);
            changeBadgeNrAvailable(true);
            changeCoachApproval(false);
        }
    }

    if (employee !== null) {
        if (confirmed) {
            return (
                <ContactAddBadgeWorkflow
                    employee={employee}
                    badgeValue={props.badgeValue}
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                    overwriteIsEnabled={props.overwriteIsEnabled}
                />
            );
        } else {
            return (
                <BadgeRegistrationConfirmationView
                    employee={employee}
                    onConfirm={onConfirm}
                    onCancel={reset}
                    onInactivity={props.onInactivity}
                    onBack={reset}
                    onHome={props.onHome}
                />
            );
        }
    } else if (employees !== null) {
        if (employees.length == 0) {
            const backHomeNav = (
                <BackAndHomeNavigationButtons
                    onBack={reset}
                    onHome={props.onHome}
                />
            );
            return (
                <ErrorView
                    onInactivity={props.onInactivity}
                    navbarItems={backHomeNav}
                    title={
                        <FormattedMessage
                            id='workflow.nike.registration.EmployeeSearchWorkflow.noEmployeesErrorTitle'
                            description='The title on the error view when searching for Nike employees during badge registration and no matching employees were found.'
                            defaultMessage='No matching employee found'
                        />
                    }
                />
            );
        } else if (employees.length === 1) {
            // We move away from this state automatically
            return (
                <LoadingView
                    onInactivity={props.onInactivity}
                    onHome={props.onHome}
                />
            );
        } else {
            return (
                <EmployeeSelectView
                    employees={employees}
                    onCancel={reset}
                    onSelect={onEmployeeSelect}
                    onInactivity={props.onInactivity}
                    onBack={reset}
                    onHome={props.onHome}
                />
            );
        }
    } else if (employeeId !== null || (!employeeIdAvailable && badgeNr !== null)) {
        if (mutateSearchContact.isError) {
            const backHomeNav = (
                <BackAndHomeNavigationButtons
                    onBack={reset}
                    onHome={props.onHome}
                />
            );
            return (
                <ErrorView
                    onInactivity={props.onInactivity}
                    navbarItems={backHomeNav}
                    title={
                        <FormattedMessage
                            id='workflow.nike.registration.EmployeeSearchWorkflow.searchFailedErrorTitle'
                            description='The title on the error view when searching for Nike employees during badge registration and the search failed.'
                            defaultMessage='Search failed'
                        />
                    }
                />
            );
        } else {
            return (
                <LoadingView
                    onInactivity={props.onInactivity}
                    onBack={reset}
                    onHome={props.onHome}
                    title={
                        <FormattedMessage
                            id='workflow.nike.registration.EmployeeSearchWorkflow.searchingTitle'
                            description='The title on the loading view when searching for Nike employees during badge registration.'
                            defaultMessage='Searching...'
                        />
                    }
                />
            );
        }
    } else if (employeeIdAvailable) {
        return (
            <BadgeRegistrationEmployeeIdView
                onEmployeeIdEntered={onEmployeeIdEntered}
                onEmployeeIdNotAvailable={onEmployeeIdNotAvailable}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
            />
        );
    } else if (props.searchByBadgeNrRequiresCoach && !coachApproval) {
        return (
            <CoachApprovalRequiredWorkflow
                onApprovalGranted={onCoachApproval}
                onCancel={() => {
                    reset(true);
                }}
                onInactivity={props.onInactivity}
                onBack={() => {
                    reset(true);
                }}
                onHome={props.onHome}
            />
        );
    } else if (badgeNrAvailable) {
        const onBack = () => {
            changeEmployeeIdAvailable(true);
        };
        return (
            <BadgeRegistrationBadgeNrView
                onBadgeNrEntered={onBadgeNrEntered}
                onBadgeNrNotAvailable={onBadgeNrNotAvailable}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={onBack}
            />
        );
    } else if (terminal?.settings_unknown_contact_registration_allowed === true && spot?.account) {
        return (
            <RegisterNewContactForm
                accountId={spot?.account}
                onContactAdded={onEmployeeSelect}
                onInactivity={props.onInactivity}
                onBack={() => {
                    reset(true);
                }}
                onHome={props.onHome}
            />
        );
    }
    return (
        <BadgeRegistrationNotPossibleView
            onInactivity={props.onInactivity}
            onBack={() => {
                reset(true);
            }}
            onHome={props.onHome}
        />
    );
}
