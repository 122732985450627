import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../api/contacts';
import useActivity from '../../hooks/useActivity';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import ErrorView from '../../views/common/ErrorView';
import { HelpActionProps } from '../../views/nike/help/HelpAction';
import HelpOverview from '../../views/nike/help/HelpOverview';
import HelpOrthopedicWorkflow from './HelpOrthopedicWorkflow';

enum HelpView {
    HELP_OVERVIEW = 'helpOverview',
    HELP_ORTHOPEDIC = 'helpOrthopedic'
}

interface HelpWorkflowProps {
    loggedInContact: Contact;
    onHome?: () => void;
    onInactivity?: () => void;
}

interface HelpActionData {
    view: HelpView;
    title?: React.ReactNode;
    subText?: React.ReactNode;
}

const helpActions: HelpActionData[] = [
    {
        view: HelpView.HELP_ORTHOPEDIC,
        title: (
            <FormattedMessage
                id='workflow.nike.HelpWorkflow'
                description='The title in the help item to request orthopedic shoes in the Nike flow.'
                defaultMessage='Request orthopedic support'
            />
        )
    }
];

function HelpWorkflow(props: HelpWorkflowProps) {
    const [currentView, changeCurrentView] = useState(HelpView.HELP_OVERVIEW);
    const [, newActivity] = useActivity();

    const onActionSelected = (helpAction: HelpActionData) => {
        newActivity();
        changeCurrentView(helpAction.view);
    };

    const onBack = () => {
        newActivity();
        changeCurrentView(HelpView.HELP_OVERVIEW);
    };
    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;
    const helpOverviewActionProps: HelpActionProps[] = helpActions.map((helpAction) => {
        return {
            title: helpAction.title,
            subtext: helpAction.subText,
            onClick: () => {
                onActionSelected(helpAction);
            }
        };
    });

    if (currentView === HelpView.HELP_OVERVIEW) {
        return (
            <HelpOverview
                actions={helpOverviewActionProps}
                loggedInContact={props.loggedInContact}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else if (currentView === HelpView.HELP_ORTHOPEDIC) {
        return (
            <HelpOrthopedicWorkflow
                loggedInContact={props.loggedInContact}
                onHome={props.onHome}
                onBack={onBack}
                onInactivity={props.onInactivity}
            />
        );
    } else {
        return (
            <ErrorView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
            />
        );
    }
}

export default HelpWorkflow;
