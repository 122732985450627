import FEATURE_TOGGLES from '../../FeatureToggles';
import { SpotLayoutItem, isSpotLayoutItem } from '../../api/spotLayoutItems';
import { Terminal } from '../../api/terminals';
import { Logger } from '../../logs/Logger';
import { SliceStatus } from '../../redux/ReduxCommonVariables';
import { SensorType, SlotDoorState, SlotSensorState, SpotLayoutLogsState } from '../../redux/SpotLayoutLogsSlice';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest, WebsocketCommandType } from '../useManagedWebsocket';

export class WebsocketSlotStateRequestCommand extends WebsocketCommand {
    data: {
        spotLayoutItem: SpotLayoutItem;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        if (request.spotLayoutItem === undefined || !isSpotLayoutItem(request.spotLayoutItem)) throw new Error('Incorect information passed.');
        this.data = {
            spotLayoutItem: request.spotLayoutItem
        };
    }

    public async execute(spotLayoutLogsState: SpotLayoutLogsState, ws: WebSocket, terminal: Terminal | null): Promise<WebsocketCommandResponse> {
        if (FEATURE_TOGGLES.SLOT_LOGGING === false) return { success: false, message: 'Slot logging disbabled in feature toggles.', send: true };
        Logger.log('Searching for spotLayoutItemState.', { slot: this.data.spotLayoutItem.slot }, this, spotLayoutLogsState);
        if (spotLayoutLogsState.status !== SliceStatus.INIT && spotLayoutLogsState.spotLayoutState !== null && terminal !== null) {
            const slots = spotLayoutLogsState.spotLayoutState;
            slots
                .filter((s) => s.spotLayoutItemId === this.data.spotLayoutItem.id)
                .forEach((relevantInfo) => {
                    ws?.send(
                        JSON.stringify({
                            data: [
                                {
                                    type: SensorType.DOOR,
                                    state:
                                        relevantInfo.open !== undefined
                                            ? relevantInfo.open.toString() === 'open'
                                                ? SlotDoorState.OPENED
                                                : relevantInfo.open
                                            : SlotDoorState.UNKNOWN,
                                    slot: this.data.spotLayoutItem.slot,
                                    info: ''
                                },
                                {
                                    type: SensorType.SENSOR,
                                    state: relevantInfo.occupied !== undefined ? relevantInfo.occupied : SlotSensorState.UNKNOWN,
                                    slot: this.data.spotLayoutItem.slot,
                                    info: ''
                                }
                            ],
                            command: WebsocketCommandType.SLOT_STATE,
                            terminal
                        })
                    );
                });
            return { success: true };
        } else {
            Logger.error('Wrong driver params where given.');
            return { success: false, message: 'Wrong driver params where given.', send: true };
        }
    }
}
