import { useState } from 'react';

const useActivity = (): [Date, (date?: Date) => Date] => {
    const [lastActivityDate, changeLastActivityDate] = useState(new Date());

    function activate(date?: Date) {
        const newDate = date ? date : new Date();
        changeLastActivityDate(newDate);
        return newDate;
    }

    return [lastActivityDate, activate];
};

export default useActivity;
