import { useContext } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { Slot } from '../api/slots';
import { SpotLayoutItem, useSpotLayoutItems } from '../api/spotLayoutItems';
import { useSpotLayout } from '../api/spotLayouts';

export default function useSpotLayoutItemsForSlots(slots: Slot[]): SpotLayoutItem[] | undefined {
    const spotLayoutItems = useAllSpotLayoutItemsForSlots();
    if (!spotLayoutItems) {
        return undefined;
    }
    return spotLayoutItems.filter((sli) => {
        return slots.find((slot) => {
            return sli.slot === slot.id;
        });
    });
}

export function useAllSpotLayoutItemsForSlots(): SpotLayoutItem[] | undefined {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });

    if (!spotLayoutItems) {
        return undefined;
    }
    return spotLayoutItems;
}
