import { useIntl } from 'react-intl';

import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import { isNSDelivery, isNSEmployee } from '../../../../services/contact/GlobalPermissions';
import { CollectWorkflow } from '../../../nike/CollectWorkflow';
import { NikeRole, isNikeContactData } from '../../../nike/api';

type CollectActionProps = AllowedActionViewProps;

function CollectWorkflowAction(props: CollectActionProps) {
    const intl = useIntl();
    //conditions
    if (props.contact === undefined) return undefined;
    if (
        (!isNikeContactData(props.contact.additional_data) ||
            (isNikeContactData(props.contact.additional_data) && props.contact.additional_data.role === NikeRole.DELIVERY)) &&
        !isNSEmployee(props.contact) &&
        !isNSDelivery(props.contact)
    )
        return undefined;

    const CollectWorkflowAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.CollectItemsAction',
            description: 'This is the collect action on the Nike dashboard',
            defaultMessage: 'Collect item(s)'
        }),
        icon: <i className='bi bi-cart4 dashboard-action-icon' />,
        view: (
            <CollectWorkflow
                loggedInContact={props.contact}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
                onLogout={props.onHome}
            />
        )
    };
    return CollectWorkflowAction;
}

export default CollectWorkflowAction;
