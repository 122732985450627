import { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../api/contacts';
import { Product } from '../../api/products';
import { Transaction } from '../../common/transactions';
import useLookupProductScan from '../../hooks/lookup/products/useLookupProductScan';
import usePerformLookup, { LookupType, ValueInputType } from '../../hooks/lookup/usePerformLookup';
import useActivity from '../../hooks/useActivity';
import useScannerValue from '../../hooks/useScannerValue';

export enum ScannerLookupType {
    TRANSACTION,
    CONTACT,
    PRODUCT
}

export interface ScannerLookupCallbackResult {
    contact?: Contact;
    transaction?: Transaction;
    transactionType?: LookupType;
    product?: Product;
    currentLookup?: string;
}

interface ScannerLookupModalProps {
    scannerLookupTypes: ScannerLookupType[];
    enabled: boolean;
    resultCallback: (result: ScannerLookupCallbackResult) => void;
    doReset: boolean; //simply change boolean to reset lookup
}

/**
 * This scannerModal should be used to handle everything conserning the scanner
 * Once called upon it should be told wath objects it has to look for
 * The callback can be used to retreive the values that where found
 *
 * first prio will be the contact (to log in the user)
 * only if no contact is found will the transaction and the product be returned (if a contact was asked for)
 *
 * TODO: eventualy the useLookupProductScan should also be added to the usePerformlookup
 */
const ScannerLookupModal = (props: ScannerLookupModalProps) => {
    const [, newActivity] = useActivity();
    const [lookupValue, changeLookupValue] = useState<string | undefined>(undefined);
    const [contact, transaction, transactionType, loading, error, performLookup, resetLookup] = usePerformLookup();
    const [productScan, changeProductScan] = useState<string | null>(null);
    const { result: product, isInProgress: productLoading } = useLookupProductScan({ lookupValue: productScan });
    const [percentage, changePercentage] = useState<number>(0);

    useEffect(() => {
        reset();
    }, [props.doReset]);

    useEffect(() => {
        let newPercentage = 0;
        if (loading === false) newPercentage += 66;
        else if (contact || transaction) newPercentage += 33;
        if (productLoading === false) newPercentage += 33;
        if (newPercentage === 99) {
            newPercentage = 100;
        }
        changePercentage(newPercentage);
    }, [loading, contact, transaction, productLoading]);

    useEffect(() => {
        if (percentage === 100) callBackFunction();
    }, [percentage]);

    useScannerValue((value) => {
        changeLookupValue(value);
        if (!props.enabled) return;
        newActivity();
        if (props.enabled === true) {
            if (props.scannerLookupTypes.includes(ScannerLookupType.PRODUCT)) changeProductScan(value);
            if (props.scannerLookupTypes.includes(ScannerLookupType.CONTACT) || props.scannerLookupTypes.includes(ScannerLookupType.TRANSACTION)) {
                performLookup({
                    value: value,
                    valueInputType: ValueInputType.SCANNER,
                    lookupType: value.includes('/api/qr/') ? LookupType.PICKUP : undefined
                });
            }
        }
    });

    const reset = () => {
        changeLookupValue(undefined);
        resetLookup();
        changeProductScan(null);
        changePercentage(0);
    };

    const callBackFunction = () => {
        if (props.scannerLookupTypes.includes(ScannerLookupType.CONTACT) && contact) props.resultCallback({ contact: contact, currentLookup: lookupValue });
        else
            props.resultCallback({
                contact: contact ? contact : undefined,
                transaction: transaction ? transaction : undefined,
                transactionType: transactionType ? transactionType : undefined,
                product: product ? product : undefined,
                currentLookup: lookupValue
            });

        changePercentage(0);
    };
    if (!props.enabled) return <></>;

    let modalContent: string | React.ReactNode = (
        <div className='d-flex flex-row justify-content-center'>
            <Spinner
                animation='border'
                role='status'></Spinner>
        </div>
    );
    if (!loading && percentage === 100) {
        if ((contact === null && transaction === null) || error) {
            modalContent = (
                <p className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow.invalidBadgeModalMessage'
                        description='This message is shown on the modal popup when the badge or code could was not recognized in the nike workflow'
                        defaultMessage='Invalid badge or code. Try again.'
                    />
                </p>
            );
        } else {
            // Redirecting soon
            modalContent = (
                <p className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow.correctBadgeModalMessage'
                        description='This message is shown on the modal popup when the badge or code was successfully recognized in the nike workflow'
                        defaultMessage='Successfully recognized badge or code. Loading next page.'
                    />
                </p>
            );
        }
    } else if (error && contact === null && transaction === null && (product === null || product === undefined)) {
        modalContent = (
            <p className='text-center'>
                <FormattedMessage
                    id='workflow.nike.NikeWorkflow.lookupErrorModalMessage'
                    description='This message is shown on the modal popup when an error occurred trying to process the badge/QR value in the nike workflow'
                    defaultMessage='Something went wrong. Try again later.'
                />
            </p>
        );
    }

    return (
        <Modal
            centered
            size='sm'
            animation={true}
            show={((percentage !== 100 && percentage !== 0) || loading || error !== null) && props.enabled}
            onHide={resetLookup}>
            <Modal.Body>{modalContent}</Modal.Body>
            <Modal.Footer className='border-0 justify-content-center'>
                <button
                    className='primary-button btn-lg'
                    onClick={reset}>
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow'
                        description='The dismiss button on the processing scanner value modal (for Nike)'
                        defaultMessage='Dismiss'
                    />
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScannerLookupModal;
