import { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext } from '../../TerminalInit';
import { useContactGroup } from '../../api/contactGroups';
import { Contact } from '../../api/contacts';
import { ProductInstanceForTransaction } from '../../api/productInstances';
import { NotificationsConfigProfile, Transaction, TransactionType } from '../../common/transactions';
import useContactTransactionHistory from '../../hooks/useContactTransactionHistory';
import { Logger } from '../../logs/Logger';
import { getNonStockSlots } from '../../services/slot/SlotFilter';
import { DAY_MS, getFullName } from '../../utils';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import ReturnConfirmationView from '../../views/nike/ReturnConfirmationView';
import ReturnView from '../../views/nike/ReturnView';
import SelectContactView from '../../views/nike/SelectContactView';
import JustDrop from '../JustDrop';
import { isNikeTerminalData } from './api';
import { canReturnForOthers, isNikeManager } from './permissions';
import { ProductInstanceOwnership, transformTransactionHistoryToOwnershipInfo } from './utils';

interface ReturnWorkflowProps {
    loggedInContact: Contact;
    onHome?: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
}

const returnCutoffPeriod = 180 * DAY_MS; // 180 days duration in ms
const returnGracePeriod = 9 * 60 * 60 * 1000; // 9 hours duration in ms

function findEligibleForReturn(transactions: Transaction[], ownerContact: Contact, returnContact?: Contact | null): ProductInstanceOwnership[] {
    let returnContactOrOwner: Contact;
    if (!returnContact) {
        returnContactOrOwner = ownerContact;
    } else {
        returnContactOrOwner = returnContact;
    }
    const ownershipInfo = transformTransactionHistoryToOwnershipInfo(transactions);

    return Array.from(ownershipInfo.values()).filter((ownershipInfo) => {
        // Immediately remove items that are currently inside the distrispot, they cannot be returned again.
        if (!ownershipInfo.transferDone) return false;
        // If the owner is not the authenticated contact, then of course we cannot return the item.
        if (ownershipInfo.transferDone.receiver?.id !== ownerContact.id) return false;

        const transactionCompletedOrCreatedDate = new Date(
            ownershipInfo.transferDone.completed_date ? ownershipInfo.transferDone.completed_date : ownershipInfo.transferDone.created_date
        );
        if (transactionCompletedOrCreatedDate.getTime() <= Date.now() - returnCutoffPeriod) {
            return false;
        }
        return transactionCompletedOrCreatedDate.getTime() > Date.now() - returnGracePeriod || isNikeManager(returnContactOrOwner);
    });
}

function ReturnWorkflow(props: ReturnWorkflowProps) {
    const [ownerContact, changeOwnerContact] = useState<null | Contact>(canReturnForOthers(props.loggedInContact) ? null : props.loggedInContact);

    const [returnProductInstance, changeReturnProductInstance] = useState<ProductInstanceForTransaction | null>(null);
    const [confirmed, changeConfirmed] = useState(false);
    const [returnReason, changeReturnReason] = useState<string | undefined>(undefined);
    const [eligibleForReturn, changeEligibleForReturn] = useState<null | ProductInstanceOwnership[]>(null);

    const { terminal } = useContext(TerminalContext);
    const { data: returnItemsReceiverGroup } = useContactGroup(
        undefined,
        terminal && isNikeTerminalData(terminal.additional_data) ? terminal.additional_data.return_items_contact_group_id : undefined
    );
    const contactHistoryResult = useContactTransactionHistory({
        cutoffTimeDeltaMs: returnCutoffPeriod,
        contactId: ownerContact ? ownerContact.id : null
    });

    const loadingDone = contactHistoryResult.isSuccess || contactHistoryResult.isError;
    useEffect(() => {
        if (loadingDone && eligibleForReturn === null && contactHistoryResult.data && ownerContact) {
            changeEligibleForReturn(findEligibleForReturn(contactHistoryResult.data, ownerContact, props.loggedInContact));
        }
    });

    const reset = useMemo(() => {
        return () => {
            changeReturnProductInstance(null);
            changeConfirmed(false);
            changeReturnReason(undefined);
        };
    }, []);

    const onHome = useMemo(() => {
        return props.onHome
            ? () => {
                  reset();
                  props.onHome!();
              }
            : undefined;
    }, [props.onHome, reset]);

    const onInactivity = useMemo(() => {
        return props.onInactivity
            ? () => {
                  reset();
                  props.onInactivity!();
              }
            : undefined;
    }, [props.onInactivity, reset]);

    const onSelectContact = (contact: Contact) => {
        changeOwnerContact(contact);
    };

    const homeNav = <BackAndHomeNavigationButtons onHome={onHome} />;

    if (ownerContact === null) {
        return (
            <SelectContactView
                onSelectContact={onSelectContact}
                loggedInContact={props.loggedInContact}
                onHome={onHome}
                onInactivity={onInactivity}
            />
        );
    } else if (eligibleForReturn === null) {
        return (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.nike.ReturnWorkflow.loadingTitle'
                        description="The title that's shown on the loading screen when searching for items the user can return in the Nike workflow"
                        defaultMessage='Searching items available to return'
                    />
                }
                onInactivity={onInactivity}
                onHome={onHome}
            />
        );
    } else if (eligibleForReturn.length > 0) {
        if (!returnProductInstance) {
            return (
                <ReturnView
                    productInstances={eligibleForReturn.map((item) => item.productInstance)}
                    onSelect={(productInstance) => {
                        Logger.log(
                            'Selected product instance for return.',
                            { transaction: productInstance?.transaction.id, product: productInstance?.product },
                            productInstance
                        );
                        changeReturnProductInstance(productInstance);
                    }}
                    returnFor={ownerContact !== props.loggedInContact ? ownerContact : undefined}
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                    onLogout={props.onLogout}
                />
            );
        } else if (returnProductInstance && !confirmed) {
            return (
                <ReturnConfirmationView
                    productInstance={returnProductInstance}
                    onConfirm={(reason) => {
                        Logger.log(
                            'Confirmed returning of product instance',
                            { transaction: returnProductInstance.transaction.id, product: returnProductInstance.product },
                            returnProductInstance,
                            reason
                        );
                        changeConfirmed(true);
                        changeReturnReason(reason);
                    }}
                    returnFor={ownerContact !== props.loggedInContact ? ownerContact : undefined}
                    onBack={
                        eligibleForReturn.length > 1
                            ? () => {
                                  changeReturnProductInstance(null);
                              }
                            : props.onHome
                    }
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                    onLogout={props.onLogout}
                />
            );
        } else {
            const shippingNotesLines = [];
            if (returnReason) {
                shippingNotesLines.push(`Return reason: ${returnReason}`);
            }
            if (ownerContact !== props.loggedInContact) {
                shippingNotesLines.push(`Returned by: ${getFullName(props.loggedInContact)} (#${props.loggedInContact.id})`);
            }
            Logger.log(
                'Trying to drop return',
                {},
                {
                    reason: returnReason,
                    receiverGroup: returnItemsReceiverGroup,
                    senderId: ownerContact.id,
                    prod_instance: returnProductInstance,
                    terminal: terminal
                }
            );
            return (
                <JustDrop
                    onInactivity={props.onInactivity}
                    onHome={props.onLogout}
                    autoAssignSlot={true}
                    autoAssignFilter={getNonStockSlots}
                    senderData={{ senderContact: ownerContact }}
                    productInstances={[returnProductInstance]}
                    receiverData={returnReason !== 'wrong_size' ? { contactGroup: returnItemsReceiverGroup } : {}}
                    isReturn={returnReason !== 'wrong_size'}
                    skipConfirm={true}
                    shippingNotes={shippingNotesLines.join('\n')}
                    notificationsConfig={{ profile: NotificationsConfigProfile.NO_NOTIFICATIONS }}
                    transactionType={returnReason === 'wrong_size' ? TransactionType.VENDING : TransactionType.RETURN}
                />
            );
        }
    } else {
        return (
            <ErrorView
                title={
                    <FormattedMessage
                        id='workflow.nike.ReturnWorkflow.titleNoItemsFound'
                        description='The title message shown when no items are found to be returned in the Nike workflow'
                        defaultMessage='No eligible items for automatic return'
                    />
                }
                message={
                    <p className='text-center'>
                        <FormattedMessage
                            id='workflow.nike.ReturnWorkflow.messageNoItemsFound'
                            description='The explanation message shown when no items are found to be returned in the Nike workflow'
                            defaultMessage='If you still want to return something, you will need to contact your coach.'
                        />
                    </p>
                }
                onInactivity={onInactivity}
                navbarItems={homeNav}
            />
        );
    }
}

export default ReturnWorkflow;
