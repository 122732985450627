export enum SliceStatus {
    INIT,
    LOADING,
    IDLE,
    ERROR
}

export enum SliceAsyncProcessStatus {
    BLOCKED,
    FREE
}
