import { memo, useEffect, useState } from 'react';

import { Slot, getRowNumber } from '../../api/slots';
import { SpotLayoutItem, sortBySlot } from '../../api/spotLayoutItems';
import useActivity from '../../hooks/useActivity';

interface MultiSpotLayoutProps {
    clickableSpotlayoutItem: (item: SpotLayoutItem, isAlreadySelected: boolean) => boolean;
    alreadySelected?: Slot[];
    spotLayout: Map<string, SpotLayoutItem[]>;
    onSubmit: (items: SpotLayoutItem[]) => void;
    onCancel: () => void;
    spotSlotMap: Map<SpotLayoutItem, Slot>;
}

interface MultiSpotRowProps {
    clickableSpotlayoutItem: (item: SpotLayoutItem, isAlreadySelected: boolean) => boolean;
    selected: SpotLayoutItem[];
    row: SpotLayoutItem[];
    onSelectSlot: (item: SpotLayoutItem) => void;
    getLabel: (item: SpotLayoutItem) => string;
}

const MultiSpotLayout = (props: MultiSpotLayoutProps) => {
    const [, newActivity] = useActivity();
    const [rows, changeRows] = useState<Map<string, SpotLayoutItem[]>>(new Map());
    const [selectedSlots, changeSelectedSlots] = useState<SpotLayoutItem[]>(
        props.alreadySelected
            ? () => {
                  const result: SpotLayoutItem[] = [];
                  props.alreadySelected?.forEach((s) => {
                      const item = getSpotFromSlot(props.spotSlotMap, s);
                      if (item) result.push(item);
                  });
                  return result;
              }
            : []
    );

    useEffect(() => {
        changeRows(props.spotLayout);
    }, [props.spotLayout, props.clickableSpotlayoutItem]);

    const onSelectSlot = (item: SpotLayoutItem) => {
        newActivity();
        const slot = props.spotSlotMap.get(item);
        if (slot) {
            selectedSlots.includes(item) ? changeSelectedSlots(selectedSlots.filter((i) => i.id !== item.id)) : changeSelectedSlots([...selectedSlots, item]);
            let existingRow = rows.get(slot.slot_nr.substring(0, 1));
            const updatedRows = new Map([...rows.entries()].sort());
            if (existingRow) {
                existingRow = existingRow.filter((spotLayoutItem) => spotLayoutItem.id !== item.id);
                existingRow.push(item);
                updatedRows.set(
                    getRowNumber(slot),
                    existingRow.sort((a, b) => sortBySlot(a, b))
                );
                changeRows(updatedRows);
            }
        }
    };

    const getLabel = (item: SpotLayoutItem): string => {
        const slot = props.spotSlotMap.get(item);
        if (slot) return slot.slot_nr;
        return '';
    };

    const onCancel = () => {
        newActivity();
        changeRows(new Map());
        props.onCancel();
    };

    return (
        <div className='multiSpot'>
            {[...rows.values()].map((value) => (
                <MultiSpotRow
                    key={value.toString()}
                    row={value}
                    onSelectSlot={onSelectSlot}
                    selected={selectedSlots}
                    getLabel={getLabel}
                    clickableSpotlayoutItem={props.clickableSpotlayoutItem}
                />
            ))}
            <div className='d-flex flex-column align-items-center'>
                <button
                    className='success-button success-button-small'
                    onClick={() => {
                        newActivity();
                        props.onSubmit(selectedSlots);
                        changeSelectedSlots([]);
                        changeRows(new Map());
                    }}>
                    submit
                </button>
                <button
                    className='warning-button warning-button-small'
                    onClick={onCancel}>
                    cancel
                </button>
            </div>
        </div>
    );
};

const MultiSpotRow = (props: MultiSpotRowProps) => {
    return (
        <div className='multiSpotRow'>
            {props.row.map((slot) => {
                const styling = props.selected.includes(slot) ? 'highlighted' : 'normal';
                return (
                    <button
                        key={slot.id}
                        disabled={!props.clickableSpotlayoutItem(slot, props.selected.includes(slot))}
                        className={'slot slot-' + styling}
                        onClick={() => props.onSelectSlot(slot)}>
                        {props.getLabel(slot)}
                    </button>
                );
            })}
        </div>
    );
};

export default memo(MultiSpotLayout);

function getSpotFromSlot(spotSlotMap: Map<SpotLayoutItem, Slot>, slot: Slot): SpotLayoutItem | undefined {
    let item;
    spotSlotMap.forEach((val, key) => {
        if (val === slot) {
            item = key;
            return;
        }
    });
    return item;
}
