import { useEffect } from 'react';

import { Logger } from '../logs/Logger';
import useTerminalEventHandler from '../terminal/useTerminalEventHandler';

const useScannerValue = (callback: (scannerValue: string) => void) => {
    const { scannerEvent } = useTerminalEventHandler();

    useEffect(() => {
        if (scannerEvent !== undefined) {
            Logger.log('New scanner value: ' + scannerEvent);
            callback(scannerEvent);
        }
    }, [scannerEvent]);
};

export default useScannerValue;
