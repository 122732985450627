import { useContext, useState } from 'react';
import { MdManageSearch } from 'react-icons/md';
import { useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../../TerminalInit';
import { Product } from '../../../../api/products';
import { TerminalWorkflowType } from '../../../../api/terminals';
import { NotificationsConfigProfile, TransactionType } from '../../../../common/transactions';
import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import { preferNonStockSlot } from '../../../../services/slot/SlotFilter';
import JustDrop from '../../../JustDrop';
import ProductSelectWorkflow from '../../../nike/ProductSelectWorkflow';
import { isNikeDeliveryPerson } from '../../../nike/permissions';

type EnterProductCodeActionProps = AllowedActionViewProps;

const EnterProductCodeWorkflow = (props: EnterProductCodeActionProps) => {
    const [product, changeProduct] = useState<Product | undefined>();

    if (product !== undefined) {
        return (
            <JustDrop
                contact={props.contact}
                products={[product!]}
                senderData={{ senderContact: props.contact }}
                receiverData={{}}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
                autoAssignSlot={true}
                autoAssignFilter={preferNonStockSlot}
                notificationsConfig={{
                    profile: NotificationsConfigProfile.NO_NOTIFICATIONS
                }}
                transactionType={TransactionType.VENDING}
            />
        );
    } else {
        return (
            <ProductSelectWorkflow
                onSelect={changeProduct}
                onInactivity={props.onInactivity}
                onLogout={props.onHome}
                onHome={props.onHome}
            />
        );
    }
};

function EnterProductCodeAction(props: EnterProductCodeActionProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const intl = useIntl();
    if (!isNikeDeliveryPerson(props.contact) || terminal?.workflow === TerminalWorkflowType.WAREHOUSE) return undefined;

    const enterProductCodeAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.enterProductCodeAction',
            description: 'This is the call to action on the Nike dashboard for the delivery person if he has no product code for a product',
            defaultMessage: "I don't have a product code"
        }),
        icon: (
            <MdManageSearch
                className='dashboard-action-icon'
                size={264}
            />
        ),
        view: <EnterProductCodeWorkflow {...props} />
    };

    return enterProductCodeAction;
}

export default EnterProductCodeAction;
