import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ViewState {
    previousActions: string[];
    allowRedirect: string[];
}

const initialState: ViewState = {
    previousActions: [],
    allowRedirect: []
};

export const ViewSlice = createSlice({
    name: 'ViewState',
    initialState,
    reducers: {
        home(state) {
            state.allowRedirect = [];
            state.previousActions = [];
        },
        forward(state, action: PayloadAction<string>) {
            state.previousActions.push(action.payload);
            if (state.previousActions.length > 20) state.previousActions.shift();
        },
        backward(state) {
            state.previousActions.pop();
        },
        changeAllowRedirect(state, action: PayloadAction<{ views: string[]; value: boolean }>) {
            if (action.payload.value) {
                if (action.payload.views.length === 0) state.allowRedirect = state.previousActions;
                else state.allowRedirect = action.payload.views;
            } else {
                if (action.payload.views.length === 0) state.allowRedirect = [];
                else state.allowRedirect = state.allowRedirect.filter((v) => !action.payload.views.includes(v));
            }
        }
    }
});

export const { home, forward, backward, changeAllowRedirect } = ViewSlice.actions;
export default ViewSlice.reducer;
