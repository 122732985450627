import { ProtonCommands } from '../../services/ProtonScalesService';
import { IProtonResponse, isProtonResponse } from './IProtonResponse';

export interface BindProductsResponse extends IProtonResponse {
    header: {
        action: ProtonCommands.BIND_PRODUCT;
    } & Omit<IProtonResponse['header'], 'action'>;
}

export function isBindProductsResponse(response: unknown): response is BindProductsResponse {
    return (
        isProtonResponse(response) &&
        (response as BindProductsResponse).header &&
        (response as BindProductsResponse).header.action === ProtonCommands.BIND_PRODUCT
    );
}
