import { useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { TerminalContext } from '../TerminalInit';
import { oauth2 } from '../demo/OAuth2';
import { HardwareConnectionContext } from '../terminal/useHardwareConnection';
import { getRandomString } from '../utils';
import BackAndHomeNavigationButtons from './common/BackAndHomeNavigationButtons';
import BaseView from './common/BaseView';

interface ResetViewProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

const ResetView = (props: ResetViewProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    const connection = useContext(HardwareConnectionContext)!;
    const { changeTerminal, serviceWorkerInit } = useContext(TerminalContext);
    const queryClient = useQueryClient();
    const terminalSoftware = connection.getTerminalSoftware();

    const cacheReset = async () => {
        await serviceWorkerInit.reset(false);
        queryClient.clear();
    };

    const resetServiceWorker = async () => {
        await serviceWorkerInit.serviceWorkerReset(false);
        queryClient.clear();
    };

    const pcbReset = async () => {
        await serviceWorkerInit.reset();
        changeTerminal(null);
        queryClient.clear();
        connection?.resetPcb();
    };

    const fullReset = async () => {
        await serviceWorkerInit.reset();
        changeTerminal(null);
        queryClient.clear();
        connection?.resetPcb();
        connection?.reset();
    };

    const logout = async () => {
        indexedDB.deleteDatabase('oauth2Tokens');
        indexedDB.deleteDatabase('workbox-expiration');
        window.localStorage.clear();
        window.sessionStorage.clear();
        document.cookie = 'sessionid= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        document.cookie = 'csrftoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        window.location.href = oauth2.code.getUri({
            state: getRandomString(64)
        });
    };

    const androidCacheReset = async () => {
        connection?.resetCache();
    };

    const androidDataReset = async () => {
        connection?.resetData();
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <div className='flex-grow-1 text-center'>
                <h3>Terminal reset options</h3>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='primary-button btn-lg col-4'
                        onClick={(event) => {
                            event.preventDefault();
                            cacheReset().then(() => {
                                window.location.href = '/';
                            });
                        }}>
                        Cache Reset
                    </button>

                    <button
                        className='warning-button btn-lg col-4'
                        onClick={(event) => {
                            event.preventDefault();
                            resetServiceWorker().then(() => {
                                window.location.href = '/';
                            });
                        }}>
                        SW Reset
                    </button>
                </div>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='primary-button btn-lg col-4'
                        onClick={(event) => {
                            event.preventDefault();
                            pcbReset().then(() => {
                                window.location.href = '/';
                            });
                        }}>
                        PCB Reset
                    </button>

                    <button
                        className='warning-button btn-lg col-4'
                        onClick={async (event) => {
                            event.preventDefault();
                            await fullReset();
                            window.location.href = '/';
                        }}>
                        Full Reset
                    </button>
                </div>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='warning-button btn-lg col-4'
                        onClick={(event) => {
                            event.preventDefault();
                            logout().then(() => {
                                window.location.href = '/';
                            });
                        }}>
                        Logout
                    </button>
                </div>
                <h3 className='mt-5'>Terminal software options</h3>
                <small>
                    {terminalSoftware} {connection?.getTerminalSoftwareVersion()}
                </small>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='primary-button btn-lg col-4'
                        onClick={(event) => {
                            event.preventDefault();
                            androidCacheReset().then(() => {
                                window.location.href = '/';
                            });
                        }}>
                        Cache Reset
                    </button>

                    <button
                        className='warning-button btn-lg col-4'
                        onClick={(event) => {
                            event.preventDefault();
                            androidDataReset().then(() => {
                                window.location.href = '/';
                            });
                        }}>
                        Data Reset
                    </button>
                </div>

                <h3 className='mt-5'>Testing</h3>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='danger-button btn-lg col-4'
                        onClick={(event) => {
                            event.preventDefault();
                            // eslint-disable-next-line no-constant-condition, no-empty
                            while (true) {}
                        }}>
                        Simulate crash
                    </button>
                </div>
            </div>
            <Navbar className='justify-content-end'>
                <div className='d-flex justify-content-between w-100'>
                    <small>v1.7.46.1</small>
                    <Link
                        to='/'
                        className='navbar-brand'>
                        distriSPOT
                    </Link>
                    <small> </small>
                </div>
            </Navbar>
        </BaseView>
    );
};

export default ResetView;
