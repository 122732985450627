import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';

import Connection, { ConnectionProps } from '../Connection';
import useDemoLib from '../demo/DemoLib';
import useTerminalLib from './TerminalLib';

export function useHardwareConnection(): Connection | null {
    const [connection, changeConnection] = useState<Connection | null>(null);
    const terminalLibConnection = useTerminalLib();
    const demoLibConnection = useDemoLib();

    useEffect(() => {
        const terminalConnection = terminalLibConnection != null ? terminalLibConnection : demoLibConnection;
        changeConnection(terminalConnection);
    }, [terminalLibConnection, demoLibConnection]);

    return connection;
}

export default function HardwareConnectionComponent(props: ConnectionProps) {
    const connection = useHardwareConnection();

    const initEnabled = props.init !== undefined;

    useEffect(() => {
        if (initEnabled && connection) {
            connection.init().then(props.init!.initDone).catch(props.init!.initError);
        }
    }, [initEnabled, connection]);

    if (connection === null) {
        return (
            <div className='d-flex flex-column align-items-center'>
                <p>Trying to establish hardware connection</p>
                <Spinner
                    animation='border'
                    role='status'
                />
            </div>
        );
    }

    return <HardwareConnectionContext.Provider value={connection}>{props.children}</HardwareConnectionContext.Provider>;
}

export const HardwareConnectionContext = React.createContext<Connection | null>(null);
