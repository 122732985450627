import { useState } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import { useIntl } from 'react-intl';

import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import Printer, { PrinterStatus } from '../../../printing/Printer';
import PrinterQueue from '../../../printing/PrinterQueue';
import { isAdmin } from '../../../services/contact/GlobalPermissions';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import { WebsocketCommandResponse } from '../../../websocket/MainWebsocketCommands';
import { WebsocketPrintLabelCommand } from '../../../websocket/commands/WebsocketPrintLabelCommand';
import { WebsocketPrinterStatusCommand } from '../../../websocket/commands/WebsocketPrinterStatusCommand';
import { WebsocketCommand, WebsocketCommandType } from '../../../websocket/useManagedWebsocket';
import useWebsocket from '../../../websocket/useWebsocket';

export default function PrinterManagementWorkflow(props: AllowedActionViewProps) {
    const ws = useWebsocket();
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    const [status, changeStatus] = useState<PrinterStatus>();
    const [printerQueue, changePrinterQueue] = useState<WebsocketCommand[] | undefined>();

    return (
        <BaseView
            navbarItems={backHomeNav}
            onInactivity={props.onInactivity}>
            <div className='flex-grow-1 text-center'>
                <h3>Actions</h3>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='warning-button btn-lg col-4'
                        onClick={() => Printer.getInstance().resetPrinter()}>
                        Reset
                    </button>

                    <button
                        className='primary-button btn-lg col-4'
                        onClick={() => {
                            PrinterQueue.clearQueue();
                            Printer.getInstance().cancelAll();
                        }}>
                        Clear queue
                    </button>
                </div>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='primary-button btn-lg col-4'
                        onClick={() => Printer.getInstance().setPausePrinter(true)}>
                        pause
                    </button>

                    <button
                        className='primary-button btn-lg col-4'
                        onClick={() => Printer.getInstance().setPausePrinter(false)}>
                        unpause
                    </button>
                </div>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='primary-button btn-lg col-4'
                        onClick={async () => changePrinterQueue(await PrinterQueue.getAllJobs())}>
                        Get queue
                    </button>

                    <button
                        className='primary-button btn-lg col-4'
                        onClick={async () => {
                            try {
                                changeStatus(((await statusCommand.execute()) as WebsocketCommandResponse).message as PrinterStatus);
                            } catch {}
                        }}>
                        Get status
                    </button>
                </div>
                <div className='d-flex justify-content-around mt-4'>
                    <button
                        className='warning-button btn-lg col-4'
                        onClick={() => Printer.getInstance().setSynchronousMode(true)}>
                        Set synchronous mode on
                    </button>
                    <button
                        className='warning-button btn-lg col-4'
                        onClick={() => Printer.getInstance().setSynchronousMode(false)}>
                        Set synchronous mode off
                    </button>
                </div>
                <div className='d-flex justify-content-around mt-4'>
                    {ws && (
                        <button
                            className='primary-button btn-lg col-4'
                            onClick={() => emptyLabelCommand.execute(null, ws)}>
                            Feed label
                        </button>
                    )}
                </div>

                {status && (
                    <>
                        <h3>Status</h3>
                        <div>
                            <p>Offline: {status.offline ? 'yes' : 'no'}</p>
                            <p>Paused: {status.paused ? 'yes' : 'no'}</p>
                            <p>Head: {status.headOpen ? 'yes' : 'no'}</p>
                            <p>Ribbon: {status.ribbonOut ? 'yes' : 'no'}</p>
                            <p>Paper: {status.paperOut ? 'yes' : 'no'}</p>
                        </div>
                    </>
                )}
                {printerQueue && (
                    <>
                        <h3>Queue</h3>
                        <div>
                            {printerQueue?.map((j, index) => {
                                return <p key={'PrinterQueueItem' + index}>{JSON.stringify(j)}</p>;
                            })}
                        </div>
                    </>
                )}
            </div>
        </BaseView>
    );
}

export function PrinterManagementAction(props: AllowedActionViewProps) {
    const intl = useIntl();
    if (isAdmin(props.contact)) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.AuthenticatedContactWorkflow.PrinterManagement',
                description: 'This is the printer management action in the AuthenticatedContactWorkflow',
                defaultMessage: 'Printer management'
            }),
            icon: (
                <AiFillPrinter
                    size={264}
                    className='dashboard-action-icon'
                />
            ),
            view: <PrinterManagementWorkflow {...props} />
        };
    }
    return undefined;
}

const emptyLabelCommand = new WebsocketPrintLabelCommand({ command: WebsocketCommandType.PRINT_LABEL, document: '^XA   ^FD   ^XZ', number_of_copies: 1 });
const statusCommand = new WebsocketPrinterStatusCommand({ command: WebsocketCommandType.PRINTER_STATUS });
