enum ProductDataType {
    NIKE_1 = 'product.product_data.nike_1'
}

export enum NikeSafetyShoeWidth {
    WIDE = 'wide',
    NORMAL = 'normal'
}

export enum NikeSafetyShoeHeight {
    LOW = 'low',
    HIGH = 'high'
}

export enum NikeSafetyShoeModel {
    LAKERS = 'lakers',
    MADDOX = 'maddox',
    LOTTE = 'lotte',
    MARTEN = 'marten'
}

export interface NikeProductData {
    type: ProductDataType;
    size: number | string;
    width: NikeSafetyShoeWidth;
    height: NikeSafetyShoeHeight;
    model: NikeSafetyShoeModel;
}

export const isNikeProductData = (productData: unknown): productData is NikeProductData => {
    return (
        productData !== undefined &&
        productData !== null &&
        (productData as NikeProductData).type !== undefined &&
        (productData as NikeProductData).type === ProductDataType.NIKE_1
    );
};

enum SlotDataType {
    NIKE_1 = 'slot.additional_data.nike_1'
}

export interface NikeSlotData {
    type: SlotDataType;
    is_stock?: boolean | null;
}

enum SpotDataType {
    NIKE_1 = 'spot.additional_data.nike_1'
}

export interface NikeSpotData {
    type: SpotDataType;
    nearby_spot_ids?: Array<string | number> | null;
}

export const isNikeSpotData = (spotData: unknown): spotData is NikeSpotData => {
    return (
        spotData !== undefined && spotData !== null && (spotData as NikeSpotData).type !== undefined && (spotData as NikeSpotData).type === SpotDataType.NIKE_1
    );
};

enum ContactDataType {
    NIKE_1 = 'contact.additional_data.nike_1'
}

export enum NikeRole {
    INTERIM = 'interim',
    EMPLOYEE = 'employee',
    MANAGER = 'manager',
    DELIVERY = 'delivery'
}

export interface NikeContactData {
    type: ContactDataType;
    role?: NikeRole | null;
    login_allowed?: boolean;
    team_id?: string;
    badge_nr?: string;
    pre_badge_nr?: string;
    manager_email?: string;
    manager_full_name?: string;
    nike_employee_id?: string;
}

export const isNikeContactData = (contactData: unknown): contactData is NikeContactData => {
    return contactData !== undefined && contactData !== null && isNikeContactRole((contactData as NikeContactData).role);
};

function isNikeContactRole(role: unknown): role is NikeRole {
    return role !== undefined && role !== null && typeof role == 'string' && Object.values(NikeRole).includes(role as NikeRole);
}

enum TerminalDataType {
    NIKE_1 = 'terminal.additional_data.nike_1'
}

export interface NikeTerminalData {
    type: TerminalDataType;
    return_items_contact_group_id?: number | string | null;
    removed_items_contact_group_id?: number | string | null;
    damaged_items_contact_group_id?: number | string | null;
    orthopedic_request_product_id?: number | string | null;
}

export const isNikeTerminalData = (terminalData: unknown): terminalData is NikeTerminalData => {
    return (
        terminalData !== undefined &&
        terminalData !== null &&
        (terminalData as NikeTerminalData).type !== undefined &&
        (terminalData as NikeTerminalData).type === TerminalDataType.NIKE_1
    );
};
