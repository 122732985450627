import { FormattedMessage } from 'react-intl';

import { ProductInstance } from '../../api/productInstances';
import { Transaction } from '../../common/transactions';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import TransactionProductsCardElement from './TransactionProductsCardElement';

interface PickupViewProps {
    transactions: Transaction[];
    onOpen: (transactions: Transaction[]) => void;
    onHome?: () => void;
    onInactivity?: () => void;
}

interface PickupHighlightProps {
    transaction: Transaction;
    productInstances?: ProductInstance[];
    urgent?: boolean;
    onOpen?: () => void;
}

const PickupHighlight = (props: PickupHighlightProps) => {
    return (
        <TransactionProductsCardElement
            transaction={props.transaction}
            onOpen={() => {
                if (props.onOpen) {
                    props.onOpen();
                }
            }}
        />
    );
};

const PickupView = (props: PickupViewProps) => {
    const transactionsFound = props.transactions.length > 0;

    if (!transactionsFound) {
        if (props.onHome) {
            props.onHome();
        }
        return <div>Unexpected error, no transactions found to pick up.</div>;
    }

    // TODO: Maybe sort on urgency?
    const contentElements = props.transactions.map((transaction) => {
        return (
            <PickupHighlight
                key={transaction.id}
                transaction={transaction}
                productInstances={transaction.product_instances}
                onOpen={() => {
                    props.onOpen([transaction]);
                }}
            />
        );
    });

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <h3 className='text-center mt-3'>
                <FormattedMessage
                    id='views.nike.PickupView'
                    description='The title shown on the pickup overview in the Nike workflow'
                    defaultMessage='{transactionCount, plural, one {This item is waiting for you} other {These items are waiting for you}}'
                    values={{
                        transactionCount: props.transactions.length
                    }}
                />
            </h3>
            {contentElements}
            {props.transactions.length > 1 ? (
                <button
                    className='primary-button mt-4'
                    onClick={() => {
                        props.onOpen(props.transactions);
                    }}>
                    <FormattedMessage
                        id='views.nike.PickupView.openAllButton'
                        description='The button that is shown on the pickup overview screen to open all pickups at once (in the nike flow)'
                        defaultMessage='Open all'
                    />
                </button>
            ) : undefined}
        </BaseView>
    );
};

export default PickupView;
