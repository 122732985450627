import { useContext } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';

const useTheme = () => {
    const { themeLogo } = useContext<TerminalContextInterface>(TerminalContext);
    return {
        themeLogo
    };
};

export default useTheme;
