import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { Locale } from '../../App';
import { Contact, NotificationChannel } from '../../api/contacts';
import useActivity from '../../hooks/useActivity';
import { Logger } from '../../logs/Logger';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import BicycleQuestionModal from './BicycleQuestionModal';

interface UserInfoFormViewProps {
    onHome?: () => void;
    onInactivity?: () => void;
    onBack?: () => void;
    onSubmit: (contact: Contact) => void;
    contact?: Contact;
    modalOpen: boolean;
    account_id: number;
    language?: Locale;
    modalOnAnswer: (answer: string) => void;
    children?: React.ReactNode;
}

const UserInfoFormView = (props: UserInfoFormViewProps) => {
    const [invalidInputError, changeInvalidInputError] = useState('');
    const [firstname, changeFirstname] = useState(props.contact?.first_name ? props.contact.first_name : '');
    const [lastname, changeLastname] = useState(props.contact?.last_name ? props.contact.last_name : '');
    const [email, changeEmail] = useState(props.contact?.email ? props.contact.email : '');
    const [phoneNumber, changePhoneNumber] = useState(props.contact?.mobile ? props.contact.mobile : '');
    const [, newActivity] = useActivity();

    const [emailInputClassName, changeEmailInputClassName] = useState<string>('form-control');
    const [emailInputGroupClassName, changeEmailInputGroupClassName] = useState<string>('input-group');
    const [phoneInputClassName, changePhoneInputClassName] = useState<string>('form-control input-group-append');
    const [phoneInputGroupClassName, changePhoneInputGroupClassName] = useState<string>('input-group');
    const [firstnameClassName, changeFirstnameClassName] = useState<string>('form-control inline-block');
    const [lastnameClassName, changeLastnameClassName] = useState<string>('form-control inline-block');

    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    useEffect(() => {
        newActivity();
    }, [firstname, lastname, email, phoneNumber]);

    const checkPhoneNumber = (phoneNumber: string): boolean => {
        let result = true;
        if (phoneNumber.startsWith('00')) {
            phoneNumber.replace('00', '+');
        }
        if (!(phoneNumber.startsWith('0') && phoneNumber.length >= 10 && phoneNumber.length < 20)) {
            result = false;
        }
        if (!result) {
            phoneNumber.replace('0', '+32');
        }

        let code, i, len;
        for (i = 0, len = phoneNumber.length; i < len; i++) {
            code = phoneNumber.charCodeAt(i);
            if (!(code > 47 && code < 58)) {
                result = false;
            }
        }

        if (!result && isPossiblePhoneNumber(phoneNumber)) result = true;
        return result;
    };

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let emailClass = 'form-control';
        let emailGroupClassName = 'input-group';
        let phoneClass = 'form-control input-group-append';
        let phoneGroupClass = 'input-group';
        let firstnameClass = 'form-control inline-block';
        let lastnameClass = 'form-control inline-block';

        const notificationChannels = [];
        let error = '';
        if (firstname === '') {
            firstnameClass += ' is-invalid';
            error = 'invalid firstname';
        } else {
            firstnameClass += ' is-valid';
        }
        if (lastname === '') {
            lastnameClass += ' is-invalid';
            error = 'invalid firstname';
        } else {
            lastnameClass += ' is-valid';
        }

        if (email === '' && phoneNumber === '') {
            phoneClass += ' is-invalid';
            emailClass += ' is-invalid';
            emailGroupClassName += ' is-invalid';
            phoneGroupClass += ' is-invalid';
            error = 'You must enter your email or your phone number.';
        } else {
            if (phoneNumber !== '') {
                if (checkPhoneNumber(phoneNumber)) {
                    notificationChannels.push(NotificationChannel.SMS);
                    phoneClass += ' is-valid';
                    phoneGroupClass += ' is-valid';
                } else {
                    error = 'Invalid phonenumber.';
                    phoneClass += ' is-invalid';
                    phoneGroupClass += ' is-invalid';
                }
            }
            if (email !== '') {
                changeEmailInputClassName('form-control');
                if (emailRegex.test(email)) {
                    notificationChannels.push(NotificationChannel.EMAIL);
                    emailClass += ' is-valid';
                    emailGroupClassName += ' is-valid';
                } else {
                    error = 'Invalid email.';
                    emailClass += ' is-invalid';
                    emailGroupClassName += ' is-invalid';
                }
            }
        }
        changePhoneInputClassName(phoneClass);
        changePhoneInputGroupClassName(phoneGroupClass);
        changeEmailInputClassName(emailClass);
        changeEmailInputGroupClassName(emailGroupClassName);
        changeFirstnameClassName(firstnameClass);
        changeLastnameClassName(lastnameClass);

        if (error === '') {
            let parsedPhoneNumber = phoneNumber;
            if (parsedPhoneNumber.startsWith('00')) {
                parsedPhoneNumber = parsedPhoneNumber.replace('00', '+');
            } else if (parsedPhoneNumber.startsWith('0')) {
                parsedPhoneNumber = parsedPhoneNumber.replace('0', '+32');
            }
            const contact: Contact = {
                id: 0,
                first_name: firstname,
                last_name: lastname,
                email: email,
                mobile: parsedPhoneNumber,
                url: '',
                title: '',
                notification_channels: notificationChannels,
                language: props.language ? props.language : Locale.DUTCH,
                account: props.account_id,
                contact_groups: [],
                contact_group_urls: [],
                additional_data: {}
            };
            props.onSubmit(contact);
        } else {
            Logger.log(
                error,
                {},
                {
                    id: 0,
                    first_name: firstname,
                    last_name: lastname,
                    email: email,
                    mobile: phoneNumber,
                    url: '',
                    title: '',
                    notification_channels: notificationChannels,
                    language: props.language ? props.language : Locale.DUTCH,
                    account: props.account_id,
                    contact_groups: [],
                    contact_group_urls: [],
                    additional_data: {}
                }
            );
            changeInvalidInputError(error);
        }
    };

    const [autofocus, chagneAutofocus] = useState<string>('firstname');

    const getFocus = (input: string) => {
        if (autofocus === input) return true;
        return false;
    };
    const inputRef = React.forwardRef<HTMLInputElement>((props, ref) => (
        <input
            ref={ref}
            className={phoneInputClassName}
            autoFocus={getFocus('phonenumber')}
            value={phoneNumber}
            inputMode='numeric'
            type='tel'
            onChange={(e) => {
                chagneAutofocus('phonenumber');
                changePhoneNumber(e.target.value);
            }}>
            {props.children}
        </input>
    ));
    inputRef.displayName = 'PhoneInputRef';

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <h3 className='mt-4 text-center'>
                <FormattedMessage
                    id='views.lending.UserInfoFormView.title'
                    description='The title on the lending user info form view'
                    defaultMessage='User info'
                />
            </h3>
            <h5 className='mt-4 text-center'>
                <FormattedMessage
                    id='views.lending.UserInfoFormView.description'
                    description='The description on the lending user info form view'
                    defaultMessage=' Your access code will be send to your email address or mobile number.'
                />
            </h5>
            <form
                onSubmit={submitForm}
                noValidate={true}>
                <div className='input-group mt-4 justify-content-between'>
                    <div className='col-md-6'>
                        <label htmlFor='firstname'>
                            <FormattedMessage
                                id='views.lending.UserInfoFormView.firstnameLabel'
                                description='The firstname label on the lending user info form view'
                                defaultMessage='Firstname'
                            />
                        </label>
                        <input
                            type='text'
                            id='firstname'
                            autoComplete='off'
                            autoFocus={getFocus('firstname')}
                            required
                            value={firstname}
                            onChange={(e) => {
                                chagneAutofocus('firstname');
                                changeFirstname(e.target.value);
                            }}
                            className={firstnameClassName}
                        />
                        <div className='invalid-feedback'>Please enter a valid firstname.</div>
                    </div>
                    <div className='col-md-6'>
                        <label htmlFor='lastname'>
                            <FormattedMessage
                                id='views.lending.UserInfoFormView.lastnameLabel'
                                description='The lastname label on the lending user info form view'
                                defaultMessage='Lastname'
                            />
                        </label>
                        <input
                            type='text'
                            id='lastname'
                            autoComplete='off'
                            required
                            value={lastname}
                            autoFocus={getFocus('lastname')}
                            onChange={(e) => {
                                chagneAutofocus('lastname');
                                changeLastname(e.target.value);
                            }}
                            className={lastnameClassName}
                        />
                        <div className='invalid-feedback'>Please enter a valid lastname.</div>
                    </div>
                </div>

                <div className='form-row mt-2'>
                    <div className='col'>
                        <label htmlFor='email'>Email</label>
                        <div className={emailInputGroupClassName}>
                            <div className='input-group-prepend'>
                                <span
                                    className='input-group-text'
                                    id='inputGroupPrepend'>
                                    @
                                </span>
                            </div>
                            <input
                                type='email'
                                id='email'
                                autoComplete='off'
                                value={email}
                                autoFocus={getFocus('email')}
                                onChange={(e) => {
                                    chagneAutofocus('email');
                                    changeEmail(e.target.value);
                                }}
                                aria-describedby='inputGroupPrepend'
                                className={emailInputClassName}
                            />
                        </div>
                        <div className='invalid-feedback'>{invalidInputError}</div>
                    </div>
                </div>

                <div className='form-row mt-2'>
                    <div className='col from-group'>
                        <label htmlFor='phone'>
                            <FormattedMessage
                                id='views.lending.UserInfoFormView.mobileNumberLabel'
                                description='The mobile number label on the lending user info form view'
                                defaultMessage='Mobile number'
                            />
                        </label>
                        <PhoneInput
                            international={false}
                            defaultCountry='BE'
                            autoComplete='off'
                            id='phone'
                            value={phoneNumber}
                            onChange={(value) => changePhoneNumber(value ? value : '')}
                            inputComponent={inputRef}
                            className={phoneInputGroupClassName}
                            smartCaret={false}
                            inputMode='numeric'
                            type='tel'
                        />
                        <div className='invalid-feedback'>{invalidInputError}</div>
                    </div>
                </div>
                <div className='text-center mt-4'>
                    <button
                        type='submit'
                        className='primary-button btn-lg'>
                        <FormattedMessage
                            id='views.lending.UserInfoFormView.submitButton'
                            description='The submitButton on the lending user info form view'
                            defaultMessage='Submit'
                        />
                    </button>
                </div>
            </form>
            <BicycleQuestionModal
                open={props.modalOpen}
                onAnswer={props.modalOnAnswer}
            />
        </BaseView>
    );
};

export default UserInfoFormView;
