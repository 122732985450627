import _ from 'lodash';
import React from 'react';

import { Logger } from './logs/Logger';
import SpinnerView from './views/common/SpinnerView';
import { WebsocketCommandResponseStatus } from './websocket/useManagedWebsocket';

interface GlobalErrorBoundaryProps {
    children: React.ReactNode;
    websocket: WebSocket | null;
}

interface GlobalErrorBoundaryState {
    hasError: boolean;
}

class GlobalErrorBoundary extends React.Component<GlobalErrorBoundaryProps, GlobalErrorBoundaryState> {
    state: GlobalErrorBoundaryState = {
        hasError: false
    };

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Logger.error('Error in GlobalErrorBoundary:', {}, error, errorInfo);
        Logger.error('Terminal restarting.');
        window.setTimeout(() => {
            window.location.href = '/';
        }, 5000);
        if (this.props.websocket) {
            Logger.log('Terminal got uncaught error thus sending logs to backend.');
            Logger.getLogs().then((logs) => {
                _.chunk(logs, 100).forEach((cl) =>
                    this.props.websocket!.send(JSON.stringify({ message: cl, status: WebsocketCommandResponseStatus.CRITICAL, command: 'logs' }))
                );
                logs && Logger.removeById(logs.map((l) => l.raw_data.id));
            });
        }
    }

    public render() {
        if (this.state.hasError) {
            return <SpinnerView title='There was an error. Gathering diagnostic data and restarting. Please wait...' />;
        }

        return this.props.children;
    }
}

export default GlobalErrorBoundary;
