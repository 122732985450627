import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { useSlot } from '../../api/slots';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { useTransactions } from '../../api/transactions';
import { Transaction } from '../../common/transactions';
import useContactTransactionHistory from '../../hooks/useContactTransactionHistory';
import { Logger } from '../../logs/Logger';
import { DAY_MS } from '../../utils';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import { ReturnsInDistrispotSelect } from '../../views/nike/ReturnsInDistrispotSelect';
import { ReturnsNoFinalDeterminationSelect } from '../../views/nike/ReturnsNoFinalDeterminationSelect';
import Pickup from '../Pickup';
import ReviewReturnedProductWorkflow from './ReviewReturnedProductWorkflow';
import { isNikeTerminalData } from './api';
import { ProductInstanceOwnership, transformTransactionHistoryToOwnershipInfo } from './utils';

interface CheckReturnsWorkflowProps {
    loggedInContact: Contact;
    onHome?: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
}

const returnCutoffPeriod = 30 * DAY_MS; // 30 days duration in ms

export function findReturns(transactions: Transaction[], returnsContactGroupId: string | number): ProductInstanceOwnership[] {
    const ownershipInfo = transformTransactionHistoryToOwnershipInfo(transactions);

    return Array.from(ownershipInfo.values()).filter((ownershipInfo) => {
        // Immediately remove items that are currently inside the distrispot, we are not interested in them.
        if (!ownershipInfo.transferDone) return false;

        // Return true if the returns group is the receiver.
        return ownershipInfo.transferDone.receiver_group?.id === returnsContactGroupId;
    });
}

function CheckReturnsWorkflow(props: CheckReturnsWorkflowProps) {
    const [selectedTransaction, changeSelectedTransaction] = useState<Transaction | null>(null);
    const [selectedProductForReview, changeSelectedProductForReview] = useState<ProductInstanceOwnership | null>(null);

    const slotResult = useSlot(selectedTransaction ? selectedTransaction.slot_id : undefined);

    const { terminal } = useContext(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const returnGroupId = terminal && isNikeTerminalData(terminal.additional_data) ? terminal.additional_data.return_items_contact_group_id : undefined;
    const transactionsResult = useTransactions({
        spot: spot?.id,
        receiver_group: returnGroupId ? returnGroupId.toString() : undefined
    });
    const returnsHistoryResult = useContactTransactionHistory({
        cutoffTimeDeltaMs: returnCutoffPeriod,
        contactGroupId: returnGroupId
    });
    const [returnsNoFinalDetermination, changeReturnsNoFinalDetermination] = useState<null | ProductInstanceOwnership[]>(null);

    useEffect(() => {
        if (returnsNoFinalDetermination === null && returnsHistoryResult.isSuccess && returnsHistoryResult.data && returnGroupId) {
            changeReturnsNoFinalDetermination(findReturns(returnsHistoryResult.data, returnGroupId));
        }
    });

    const reset = useMemo(() => {
        return () => {
            changeSelectedTransaction(null);
        };
    }, []);

    const onHome = useMemo(() => {
        return props.onHome
            ? () => {
                  reset();
                  props.onHome!();
              }
            : undefined;
    }, [props.onHome, reset]);

    const onInactivity = useMemo(() => {
        return props.onInactivity
            ? () => {
                  reset();
                  props.onInactivity!();
              }
            : undefined;
    }, [props.onInactivity, reset]);

    const onLogout = useMemo(() => {
        return props.onLogout
            ? () => {
                  reset();
                  props.onLogout!();
              }
            : undefined;
    }, [props.onLogout, reset]);

    const onOpen = useMemo(
        () => (transaction: Transaction) => {
            changeSelectedTransaction(transaction);
        },
        [changeSelectedTransaction]
    );

    const onReview = useCallback(
        (ownedProductInstance: ProductInstanceOwnership) => {
            Logger.log(
                'Review product',
                { product: ownedProductInstance.productInstance.product, transaction: ownedProductInstance.transferInProgress?.id },
                ownedProductInstance
            );
            changeSelectedProductForReview(ownedProductInstance);
        },
        [changeSelectedProductForReview]
    );

    const onReviewBack = useCallback(() => {
        changeSelectedProductForReview(null);
    }, [changeSelectedProductForReview]);

    const homeNav = <BackAndHomeNavigationButtons onHome={onHome} />;

    if (selectedProductForReview) {
        return (
            <ReviewReturnedProductWorkflow
                ownedProductInstance={selectedProductForReview}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={onReviewBack}
            />
        );
    }
    if (selectedTransaction) {
        const isLoading = !(slotResult.isSuccess || slotResult.isError);
        if (isLoading || !slotResult.data) {
            return <LoadingView />;
        } else {
            return (
                <Pickup
                    slot={slotResult.data}
                    transaction={selectedTransaction}
                    onInactivity={onInactivity}
                    onHome={props.onHome}
                    skipConfirm={true}
                />
            );
        }
    } else {
        if (returnsNoFinalDetermination) {
            Logger.log('Returns found without final determination', {}, returnsNoFinalDetermination);
        }

        if (transactionsResult.isError || returnsHistoryResult.isError) {
            return (
                <ErrorView
                    title='An error occurred while determining returned items'
                    navbarItems={homeNav}
                    onInactivity={onInactivity}
                />
            );
        } else if (!transactionsResult.isSuccess || !transactionsResult.data || !returnsNoFinalDetermination) {
            return (
                <LoadingView
                    title='Loading returned item(s)'
                    onHome={onHome}
                    onInactivity={onInactivity}
                />
            );
        } else if (transactionsResult.data.length === 0 && returnsNoFinalDetermination.length === 0) {
            return (
                <ErrorView
                    navbarItems={homeNav}
                    hideErrorIcon={true}
                    title={
                        <FormattedMessage
                            id='workflow.nike.CheckReturnsWorkflow.titleNoReturnedItems'
                            description="The title that's shown when no items have been returned on the Nike check returns workflow"
                            defaultMessage='No returned items found'
                        />
                    }
                    message={
                        <>
                            <p className='text-center'>
                                <FormattedMessage
                                    id='workflow.nike.CheckReturnsWorkflow.messageNoReturnedItems'
                                    description="The message that's shown when no items have been returned on the Nike check returns workflow"
                                    defaultMessage='There are no returned items in this DistriSPOT.'
                                />
                            </p>
                            <p className='text-center'>
                                <button
                                    className='primary-button'
                                    onClick={onHome}>
                                    <FormattedMessage
                                        id='workflow.nike.CheckReturnsWorkflow.buttonBackNoReturnedItems'
                                        description="The back button call to action that's shown when no items have been returned on the Nike check returns workflow"
                                        defaultMessage='Back to dashboard'
                                    />
                                </button>
                            </p>
                        </>
                    }
                />
            );
        } else {
            let returnedItemsContent;
            let returnsNoFinalDeterminationContent;
            if (transactionsResult.data.length > 0) {
                returnedItemsContent = (
                    <ReturnsInDistrispotSelect
                        transactions={transactionsResult.data}
                        onOpen={onOpen}
                    />
                );
            }
            if (returnsNoFinalDetermination.length > 0) {
                returnsNoFinalDeterminationContent = (
                    <ReturnsNoFinalDeterminationSelect
                        returnedProductInstances={returnsNoFinalDetermination}
                        onSelect={onReview}
                    />
                );
            }

            return (
                <BaseView
                    onInactivity={onInactivity}
                    navbarItems={homeNav}>
                    {returnsNoFinalDeterminationContent}
                    {returnedItemsContent}
                </BaseView>
            );
        }
    }
}

export default CheckReturnsWorkflow;
