import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { useMutateAuthenticateContact } from '../../../api/contacts';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import useBaseLookup from '../useBaseLookup';

interface ContactAuthenticationProps {
    lookupValue?: string | null;
}

const useContactAuthentication = (props: ContactAuthenticationProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const mutateAuthenticateContact = useMutateAuthenticateContact();
    const { data: authenticatedContact } = mutateAuthenticateContact;
    const [currentLookupValue, changeCurrentLookupValue] = useState<string | null | undefined>(null);

    useEffect(() => {
        if (props.lookupValue !== currentLookupValue) {
            changeCurrentLookupValue(props.lookupValue);
        }
    }, [currentLookupValue, props.lookupValue, changeCurrentLookupValue]);

    const spotId: number | undefined = spot ? parseInt(spot.id.replace('SPT', '')) : undefined;
    const mutate = mutateAuthenticateContact.mutate;
    useEffect(() => {
        if (currentLookupValue) {
            mutate({
                authenticateContact: {
                    key: currentLookupValue,
                    spot: spotId
                }
            });
        }
    }, [currentLookupValue, mutate]);

    const requiredDataFetched = mutateAuthenticateContact.isSuccess || mutateAuthenticateContact.isError;
    const foundContact = mutateAuthenticateContact.isSuccess ? authenticatedContact : null;

    const LookupImpl = {
        lookupValue: currentLookupValue,
        requiredDataFetched,
        result: foundContact,
        refetchTransactions: () => undefined,
        isRefetching: false,
        error: mutateAuthenticateContact.isError
    };

    return useBaseLookup(LookupImpl);
};

export default useContactAuthentication;
