import { useContext } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';

import { AccessToken } from '../Connection';
import { TerminalContext } from '../TerminalInit';
import { ApiError, FetchOptions, fetchApi, postApi } from './utils';

export interface SSOAuthResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
}

export interface UserInfo {
    id: number;
    url: string;
    first_name: string;
    last_name: string;
    language: any;
    username: string;
    email: string;
    mobile: string;
    phone: string;
    account_members: AccountMembership[];
    is_terminal: boolean;
    authentication_backend: any;
    accounts: number[];
}
export interface AccountMembership {
    account: Account;
    role: string;
}

export interface Account {
    contacts: AccountContactModel[];
    id: number;
    name: string;
    transation_scheduling_config: any;
}

export interface AccountContactModel {
    email: string;
    first_name: string;
    last_name: string;
    pk: number;
}

export function useMutateAuthenticateItsme(
    options?: UseMutationOptions<SSOAuthResponse | null, unknown, { mobile: string; accountId: string }>,
    fetchOptions?: FetchOptions
) {
    const config: UseMutationOptions<SSOAuthResponse | null, unknown, { mobile: string; accountId: string }> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(authenticateWithItsme(fetchOptions), config);
}

export function authenticateWithItsme(options?: FetchOptions): (variables: { mobile: string; accountId: string }) => Promise<SSOAuthResponse | null> {
    return async (variables: { mobile: string; accountId: string }): Promise<SSOAuthResponse | null> => {
        let response = undefined;
        let requestId = undefined;
        while (response == undefined) {
            const res: UnfinishedRequest | SSOAuthResponse = await itsmeAuthenticationRequest()(variables.accountId, variables.mobile, requestId, options);
            if ((res as UnfinishedRequest).auth_req !== undefined) {
                if ((res as UnfinishedRequest).auth_req.auth_req_id !== undefined) requestId = (res as UnfinishedRequest).auth_req.auth_req_id;
            } else {
                response = res as SSOAuthResponse;
            }
        }
        return response;
    };
}

interface UnfinishedRequest {
    auth_req: {
        auth_req_id?: string;
        status?: number;
    };
}

function itsmeAuthenticationRequest(): (
    accountId: string,
    mobile: string,
    req_id?: string,
    options?: FetchOptions
) => Promise<UnfinishedRequest | SSOAuthResponse> {
    return async (accountId: string, mobile: string, req_id?: string, options?: FetchOptions): Promise<UnfinishedRequest | SSOAuthResponse> => {
        const authenticateContactUrl = process.env.REACT_APP_API_URL + '/rest-auth/itsme/';
        const response = await postApi(
            authenticateContactUrl.replace('/api', ''),
            {
                auth_req_id: req_id || null,
                mobile: mobile,
                client_id: process.env.REACT_APP_CLIENT_ID,
                account_id: accountId
            },
            options
        );
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error authenticating contact via itsme');
            }
            throw new ApiError('Error authenticating contact via itsme', json);
        }
        return await response.json();
    };
}

//USER INFORMATION
export function getUserInfo(options?: FetchOptions): () => Promise<UserInfo | null> {
    return async (): Promise<UserInfo | null> => {
        const authenticateContactUrl = '/me/';
        const response = await fetchApi(authenticateContactUrl, {}, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error getting user information');
            }
            throw new ApiError('Error getting user information', json);
        }
        return await response.json();
    };
}

export function mapSSOResponseToAccessToken(response: SSOAuthResponse): AccessToken {
    return {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expires: new Date(response.expires_in),
        tokenType: response.token_type
    };
}
