import { StockFill } from '../../../api/stockFills';

const StockFillReportListElement = (stockFillReport: StockFill, onSelect?: (stockFill: StockFill) => void): JSX.Element => {
    return (
        <tr
            onClick={() => {
                if (onSelect) onSelect(stockFillReport);
            }}>
            <td
                className='py-2'
                style={{ verticalAlign: 'middle' }}>
                {stockFillReport.report_code}
            </td>
        </tr>
    );
};

export default StockFillReportListElement;
