import { HiHome } from 'react-icons/hi';
import { ImExit } from 'react-icons/im';
import { IoMdArrowRoundBack } from 'react-icons/io';

interface BackAndHomeNavigationButtonsProps {
    onLogout?: () => void;
    onHome?: () => void;
    onBack?: () => void;
}

const BackAndHomeNavigationButtons = (props: BackAndHomeNavigationButtonsProps) => {
    const logoStyling = { width: '3rem', height: '3rem' };
    return (
        <div
            className='navbar-button-group'
            role='group'
            aria-label='Basic example'>
            {props.onBack ? (
                <button
                    className='navbar-button'
                    onClick={() => {
                        if (props.onBack) {
                            props.onBack();
                        }
                    }}>
                    <IoMdArrowRoundBack
                        className='text-primary'
                        style={logoStyling}
                    />
                </button>
            ) : (
                <></>
            )}
            {props.onHome ? (
                <button
                    className='navbar-button'
                    onClick={() => {
                        if (props.onHome) {
                            props.onHome();
                        }
                    }}>
                    <HiHome
                        className='text-primary'
                        style={logoStyling}
                    />
                </button>
            ) : (
                <></>
            )}
            {props.onLogout ? (
                <button
                    className='navbar-button'
                    onClick={() => {
                        if (props.onLogout) {
                            props.onLogout();
                        }
                    }}>
                    <span className='d-flex flex-row justify-content-center text-danger'>
                        <ImExit
                            className='pe-1'
                            style={logoStyling}
                        />
                        Exit
                    </span>
                </button>
            ) : (
                <></>
            )}
        </div>
    );
};

export default BackAndHomeNavigationButtons;
