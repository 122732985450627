import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Attribute, useAttributes } from '../../../api/attribute';
import { Category } from '../../../api/category';
import { Product, useProducts } from '../../../api/products';
import { useShops } from '../../../api/shops';
import { Spot } from '../../../api/spots';
import useActivity from '../../../hooks/useActivity';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ErrorView from '../../../views/common/ErrorView';
import LoadingView from '../../../views/common/LoadingView';
import ShopCategoryCard from '../../../views/elements/shop/ShopProductCard';

interface CategroyFilterProps {
    spot: Spot | undefined;
    allCategories: Category[] | undefined;
    categoriesLoading: boolean;
    parents: Category[];

    parentHistory: Category[];
    removeHistory: () => void;
    selectProducts: (product: Product[], attributes: Attribute[], parentHistory: Category[]) => void;

    onBack?: () => void;
    onHome?: () => void;
    onInactivity?: () => void;
}

const CategroyFilter = (props: CategroyFilterProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    const [, newActivity] = useActivity();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);

    const currentCategory = props.parents.length > 0 ? props.parents[props.parents.length - 1]! : undefined;

    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: productsData } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const { data: attributesData } = useAttributes();

    const [children, changeChildren] = useState<Category[] | undefined>();
    const [selectedCategory, changeSelectedCategory] = useState<Category | undefined>(undefined);

    useEffect(() => {
        if (props.parentHistory.length > 0) {
            changeSelectedCategory(props.parentHistory.at(props.parentHistory.length - 1));
        } else {
            props.removeHistory();
        }
        if (selectedCategory === undefined) {
            if (props.parents.length === 0) {
                //root categories should be displayed
                changeChildren(props.allCategories?.filter((category) => category.parent_id === null));
            } else {
                const child = currentCategory!.children;
                if (child !== undefined && child.length !== 0) {
                    changeChildren(child);
                }
            }
        }
    }, [attributesData, productsData, props.allCategories]);

    const select = (category: Category) => {
        newActivity();
        if (category.children === undefined || category.children === null || category.children.length === 0) {
            const newCategoryTree = [...props.parents, category];
            const attributes: Attribute[] = [];
            newCategoryTree.forEach((cat) => {
                if (cat.attributes) {
                    cat.attributes.forEach((attr) => {
                        const a = attributesData?.find((a) => a.id === attr);
                        if (a && !attributes.includes(a)) {
                            attributes.push(a);
                        }
                    });
                }
            });
            const products = productsData?.filter((p) => p.category === category?.id);
            products!.forEach((p) => p.attributes && attributes.push(...p.attributes));
            props.selectProducts(products!, attributes, props.parents);
        } else {
            changeSelectedCategory(category);
        }
    };

    const remove = (): Category[] => {
        const temp = JSON.parse(JSON.stringify(props.parentHistory));
        temp.pop();
        return temp;
    };

    return (
        <>
            {children === undefined ? (
                <LoadingView
                    onBack={props.onBack}
                    onHome={props.onHome}
                    onInactivity={props.onHome}
                />
            ) : (
                <>
                    {props.allCategories && props.allCategories.length > 0 ? (
                        <>
                            {selectedCategory === undefined ? (
                                <BaseView
                                    navbarItems={backHomeNav}
                                    onInactivity={props.onHome}>
                                    <div className='d-flex justify-content-center title'>{currentCategory?.name}</div>
                                    {children ? (
                                        <div className='shop-product-list'>
                                            {children.map((c) => (
                                                <ShopCategoryCard
                                                    key={c.id}
                                                    onClick={() => {
                                                        select(c);
                                                    }}
                                                    category={c}
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </BaseView>
                            ) : (
                                <>
                                    {productsData ? (
                                        <CategroyFilter
                                            parents={[...props.parents, selectedCategory]}
                                            parentHistory={remove()}
                                            spot={props.spot}
                                            allCategories={props.allCategories}
                                            categoriesLoading={props.categoriesLoading}
                                            selectProducts={props.selectProducts}
                                            onBack={() => {
                                                changeSelectedCategory(undefined);
                                            }}
                                            onHome={props.onHome}
                                            onInactivity={props.onInactivity}
                                            removeHistory={props.removeHistory}
                                        />
                                    ) : (
                                        <LoadingView
                                            onBack={() => {
                                                changeSelectedCategory(undefined);
                                            }}
                                            onHome={props.onHome}
                                            onInactivity={props.onInactivity}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <ErrorView
                                navbarItems={backHomeNav}
                                onInactivity={props.onInactivity}
                                message={'No categories to display please contact the administrator to finish the configuration.'}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default CategroyFilter;
