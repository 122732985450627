export enum IndexedDbStores {
    TERMINAL_LOGS = 'terminal_logs',
    PRINTER_QUEUE = 'printer_queue',
    BULK_FAILSAFE_RESPONSES = 'bulk_failsafe_responses',

    //unused (should be updated/removed)
    TRANSACTIONS_IN_PROGRESS = 'transactions_in_progress'
}

const DB_NAME = 'main';
const DB_VERSION = 4;
const request = indexedDB?.open(DB_NAME, DB_VERSION);
let indexedDbConnection: IDBDatabase | undefined = undefined;

request.onerror = () => {
    console.log('failed connection to indexed db');
};

request.onsuccess = () => {
    console.log('successfully connected to indexed db');
    indexedDbConnection = request.result;
};

request.onupgradeneeded = (event: any) => {
    const db = event.target.result;
    if (!event.target.result.objectStoreNames.contains(IndexedDbStores.TERMINAL_LOGS)) {
        db.createObjectStore(IndexedDbStores.TERMINAL_LOGS, { keyPath: 'id', autoIncrement: true });
    }
    if (!event.target.result.objectStoreNames.contains(IndexedDbStores.BULK_FAILSAFE_RESPONSES)) {
        db.createObjectStore(IndexedDbStores.BULK_FAILSAFE_RESPONSES, { keyPath: 'id' });
    }
    if (!event.target.result.objectStoreNames.contains(IndexedDbStores.TRANSACTIONS_IN_PROGRESS)) {
        db.createObjectStore(IndexedDbStores.TRANSACTIONS_IN_PROGRESS, { keyPath: 'id' });
    }
    if (!event.target.result.objectStoreNames.contains(IndexedDbStores.PRINTER_QUEUE)) {
        db.createObjectStore(IndexedDbStores.PRINTER_QUEUE, { keyPath: 'relevantUntil' });
    }
};

export async function getConnection(): Promise<IDBDatabase> {
    return new Promise((resolve) => {
        if (indexedDbConnection === undefined) {
            setTimeout(() => getConnection().then(resolve), 1000);
        } else {
            resolve(indexedDbConnection);
        }
    });
}
export async function getStoreConnection(
    storeName: string,
    mode?: IDBTransactionMode | undefined,
    options?: IDBTransactionOptions | undefined
): Promise<IDBObjectStore> {
    return new Promise((resolve) => {
        if (indexedDbConnection === undefined) {
            setTimeout(() => getStoreConnection(storeName, mode, options).then(resolve), 1000);
        } else {
            resolve(indexedDbConnection.transaction(storeName, mode, options).objectStore(storeName));
        }
    });
}
