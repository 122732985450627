import { ReactNode, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { useSpotLayout } from '../api/spotLayouts';
import { useSpot } from '../api/spots';
import ActionCard from '../components/domain/ActionCard';
import ActionCardGridElement from '../components/elements/ActionCardGridElement';
import useReadyForUpdate from '../hooks/useReadyForUpdate';
import badgeNfc from '../static/nike/badge-nfc-alt.png';
import BaseView from './common/BaseView';
import LanguagePicker from './elements/LanguagePicker';
import ScannerModal from './elements/ScannerModal';
import ViewTitle from './nike/ViewTitle';

interface HomeViewProps {
    [x: string]: ReactNode;
    actions: (ActionCard | undefined)[];
    onSelectAction: (action: ActionCard) => void;
    onHowDoesItWork?: () => void;
    children?: ReactNode;
}

const HomeView = (props: HomeViewProps) => {
    const intl = useIntl();

    const [showScanModal, changeShowScanModal] = useState(false);
    const [showScannerHelp, changeShowScannerHelp] = useState(false);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const showScanImage = props.actions.filter((a) => a !== undefined).length === 0;

    useReadyForUpdate({
        delayMs: parseInt(process.env.REACT_APP_READY_FOR_UPDATE_TIMEOUT!, 10)
    });

    const selectAction = (action: ActionCard) => {
        if (action.actionText.includes('QR') || action.actionText.includes('NFC')) {
            changeShowScannerHelp(true);
            setTimeout(function () {
                changeShowScannerHelp(false);
            }, 6000);
        } else {
            props.onSelectAction(action);
        }
    };

    return (
        <BaseView
            navbarItems={<LanguagePicker />}
            ShowQrHelp={showScannerHelp}>
            <div className='flex-grow-1 d-flex flex-column'>
                <ViewTitle>
                    <FormattedMessage
                        id='views.PuDo.HomeView.WelcomeTitle'
                        description='The welcome title message on the PuDo home screen'
                        defaultMessage='Welcome to {spotName}'
                        values={{
                            spotName:
                                spot && spot.name !== '' ? (
                                    spot.name
                                ) : (
                                    <FormattedMessage
                                        id='views.PuDo.HomeView.WelcomeTitleNoSpotName'
                                        description='The text used as spot name in the welcome title message if the spot has no name. Should be read in combination with message id views.PuDo.HomeView.WelcomeTitle.'
                                        defaultMessage='the DistriSPOT'
                                    />
                                )
                        }}
                    />
                </ViewTitle>
                {props.children}
                {showScanImage ? (
                    <div className='row'>
                        <div className='col-12 col-xl-6 d-flex justify-content-center'>
                            <img
                                src={badgeNfc}
                                className='dashboard-scan-image mb-3 filter-primary'
                                alt={intl.formatMessage({
                                    id: 'views.nike.HomeView.badgeImgAlt',
                                    description: 'The alt image text for the home screen NFC badge image',
                                    defaultMessage: 'NFC badge'
                                })}
                            />
                            <ScannerModal
                                show={showScanModal}
                                changeShow={changeShowScanModal}
                            />
                        </div>
                        <div className='col-12 col-xl-6 d-flex flex-column justify-content-center align-items-center'>
                            <p className='text-center'>
                                <FormattedMessage
                                    id='views.nike.HomeView.ScanMessage'
                                    description='The message on the Nike home screen encouraging the user to scan'
                                    defaultMessage='Please scan your Nike badge to continue.'
                                />
                            </p>
                            <button
                                className='btn btn-outline-primary rounded-pill'
                                onClick={props.onHowDoesItWork}>
                                <i className='bi bi-exclamation-circle me-2' />
                                <FormattedMessage
                                    id='views.nike.HomeView.HelpButton'
                                    description='The button on the Nike home screen for the user to get an introduction on how to scan.'
                                    defaultMessage='How does it work?'
                                />
                            </button>
                        </div>
                    </div>
                ) : (
                    <ActionCardGridElement
                        actions={props.actions}
                        onSelectAction={selectAction}
                    />
                )}
            </div>
        </BaseView>
    );
};

export default HomeView;
