import { useIntl } from 'react-intl';

import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import { isNSDelivery } from '../../../../services/contact/GlobalPermissions';
import { isNikeManager } from '../../../nike/permissions';
import BadgeRegistrationWorkflow from '../../../nike/registration/BadgeRegistrationWorkflow';

type ResetBadgeActionProps = AllowedActionViewProps;

function ResetBadgeAction(props: ResetBadgeActionProps) {
    const intl = useIntl();

    if (!isNikeManager(props.contact) && !isNSDelivery(props.contact)) return undefined;

    const overwriteUserBadgeAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.ResetBadgeAction',
            description: 'This is the overwrite badge action on the Nike dashboard for the managers',
            defaultMessage: 'Reset badge'
        }),
        icon: <i className='bi bi-person-badge dashboard-action-icon'></i>,
        view: (
            <BadgeRegistrationWorkflow
                badgeValue={undefined}
                overwriteIsEnabled={true}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
            />
        )
    };

    return overwriteUserBadgeAction;
}

export default ResetBadgeAction;
