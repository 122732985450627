import { Terminal } from '../api/terminals';
import useInterval from './useInterval';

interface Props {
    terminal: Terminal | null;
}

const standbyCheckTimer = 2 * 60 * 1000; //2 minutes

export default function useStandby(props: Props) {
    useInterval(() => {
        if (props.terminal && props.terminal.settings_switch_terminal_on_at && props.terminal.settings_switch_terminal_off_at) {
            const now = new Date();
            const threeMinutesAgo = new Date();
            threeMinutesAgo.setMinutes(now.getMinutes() - 3);
            if (window.location.href.includes('standby')) {
                const start = props.terminal.settings_switch_terminal_on_at.split(':');
                const startTime = new Date();
                startTime.setHours(+start[0]);
                startTime.setMinutes(+start[1]);
                if (startTime > threeMinutesAgo && startTime <= now) {
                    window.location.href = '/';
                }
            } else {
                const end = props.terminal.settings_switch_terminal_off_at.split(':');
                const endTime = new Date();
                endTime.setHours(+end[0]);
                endTime.setMinutes(+end[1]);
                if (endTime > threeMinutesAgo && endTime <= now) {
                    window.location.href = '/standby';
                }
            }
        }
    }, standbyCheckTimer);
}
