import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { Logger } from '../logs/Logger';
import { sleep } from '../utils';

interface Props {
    [value: string]: any;
}

export default function ErrorInitializingView(props: Props) {
    const [disabled, changeDisabled] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            changeDisabled(false);
        }, 5 * 1000);

        const timeout = setTimeout(async () => {
            Logger.error('Terminal stuck on Error initialising page. (refreshing)', props);
            await sleep(1000);
            window.location.href = '/';
        }, 20 * 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className='text-center flex-grow-1 mt-2'>
            <h1>Error initializing app</h1>

            <button
                className={'btn btn-primary' + (disabled ? ' disabled' : '')}
                onClick={() => {
                    window.location.href = '/';
                }}>
                {disabled && (
                    <Spinner
                        animation='border'
                        role='status'
                    />
                )}{' '}
                Refresh
            </button>
        </div>
    );
}
