import { FormattedMessage } from 'react-intl';

import { Contact } from '../../../api/contacts';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';
import HelpAction, { HelpActionProps } from './HelpAction';

interface HelpOverviewProps {
    loggedInContact: Contact;
    onHome?: () => void;
    onInactivity?: () => void;
    actions: HelpActionProps[];
}

function HelpOverview(props: HelpOverviewProps) {
    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.help.HelpOverview.title'
                    description='The title on the help overview when a Nike user is logged.'
                    defaultMessage='Need help with something?'
                />
            </ViewTitle>
            {props.actions.map((actionProps) => {
                return (
                    <HelpAction
                        key={`${actionProps.title} - ${actionProps.subtext}`}
                        title={actionProps.title}
                        subtext={actionProps.subtext}
                        onClick={actionProps.onClick}
                    />
                );
            })}
        </BaseView>
    );
}

export default HelpOverview;
