import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../../TerminalInit';
import { TerminalWorkflowType } from '../../../../api/terminals';
import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import CheckReturnsWorkflow from '../../../nike/CheckReturnsWorkflow';
import { isNikeDeliveryPerson } from '../../../nike/permissions';

type CheckReturnsActionProps = AllowedActionViewProps;

const CheckReturns = (props: CheckReturnsActionProps) => {
    return (
        <CheckReturnsWorkflow
            loggedInContact={props.contact!}
            onInactivity={props.onInactivity}
            onLogout={props.onHome}
            onHome={props.onHome}
        />
    );
};

function CheckReturnsAction(props: CheckReturnsActionProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const intl = useIntl();
    if (!isNikeDeliveryPerson(props.contact) || terminal?.workflow === TerminalWorkflowType.WAREHOUSE) return undefined;

    const checkReturnsAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.CheckReturns',
            description: 'This is the check returns action on the Nike dashboard for the delivery person',
            defaultMessage: 'Check returns'
        }),
        icon: <i className='bi bi-arrow-repeat dashboard-action-icon' />,
        view: <CheckReturns {...props} />
    };

    return checkReturnsAction;
}

export default CheckReturnsAction;
