import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Logger } from '../logs/Logger';
import { SliceStatus } from './ReduxCommonVariables';

interface ScalesWeightState {
    savedState: { [spotLayoutItemId: string]: number };
    currentState: { [spotLayoutItemId: string]: number };
    difference: { [spotLayoutItemId: string]: number };
    lastUpdate: number;
    errors: any[];
    dropoffEnabled: boolean;
    status: SliceStatus;
}
const initialState: ScalesWeightState = {
    savedState: {},
    currentState: {},
    difference: {},
    lastUpdate: Date.now(),
    errors: [],
    dropoffEnabled: false,
    status: SliceStatus.INIT
};

export const ScalesWeightSlice = createSlice({
    name: 'ScalesWeightState',
    initialState,
    reducers: {
        initDropoff(state, action: PayloadAction<boolean>) {
            state.dropoffEnabled = action.payload;
        },
        init(state, action: PayloadAction<{ [spotLayoutItemId: string]: number }>) {
            if (state.status !== SliceStatus.INIT) return;
            state.savedState = action.payload;
            state.currentState = action.payload;
            state.dropoffEnabled = false;
            state.status = SliceStatus.IDLE;
            state.lastUpdate = Date.now();
        },
        /**
         * @param state
         * @param action should provide all the updated values (not needed when filling the warehouse because this should always be a complete update)
         */
        sync(state, action: PayloadAction<{ changes: { [spotLayoutItemId: string]: number }; dropoff?: boolean }>) {
            if (state.dropoffEnabled === true && action.payload.dropoff === true) {
                Object.entries(action.payload.changes).forEach(([spotLayoutItemId, value]) => {
                    state.savedState[spotLayoutItemId] = state.savedState[spotLayoutItemId] + value;
                    if (state.difference[spotLayoutItemId] !== undefined) state.difference[spotLayoutItemId] = state.difference[spotLayoutItemId] + value;
                });
                state.dropoffEnabled = false;
            } else {
                Object.entries(action.payload.changes).forEach(([spotLayoutItemId, value]) => {
                    state.savedState[spotLayoutItemId] = state.savedState[spotLayoutItemId] - value;
                    if (state.difference[spotLayoutItemId] !== undefined) state.difference[spotLayoutItemId] = state.difference[spotLayoutItemId] - value;
                });
            }
            state.lastUpdate = Date.now();
        },
        updateCurrent(state, action: PayloadAction<{ spotLayoutItemId: string; currentCount: number; aditional_data?: any }[]>) {
            Logger.debug('Scales state update.', {}, action.payload);
            action.payload.forEach((payload) => {
                Logger.log(
                    `SpotLayoutItem ${payload.spotLayoutItemId} received new count (${payload.currentCount}).`,
                    { spot_layout_item: payload.spotLayoutItemId },
                    payload.aditional_data
                );
                if (state.dropoffEnabled === false) {
                    if (
                        state.savedState[payload.spotLayoutItemId] < state.currentState[payload.spotLayoutItemId] &&
                        state.savedState[payload.spotLayoutItemId] >= payload.currentCount
                    ) {
                        const error = state.errors.find((er) => er.data?.spotLayoutItemId == payload.spotLayoutItemId);
                        state.errors[state.errors.indexOf(error)] = { ...error, resolved: true, sent: false };
                        Logger.log('Current count was to high on a previeus event but got resolved.', {}, payload);
                    }
                }

                state.currentState[payload.spotLayoutItemId] = payload.currentCount;
                state.difference[payload.spotLayoutItemId] = state.savedState[payload.spotLayoutItemId] - payload.currentCount;

                if (state.dropoffEnabled === false) {
                    if (state.savedState[payload.spotLayoutItemId] < payload.currentCount) {
                        const error = {
                            message: 'Scale measured increase in wait while dropoff is not allowed.',
                            sent: false,
                            data: payload,
                            previousState: state.savedState,
                            currentState: state.currentState
                        };
                        Logger.error(error.message, {}, error);
                        state.errors = [...state.errors, error];
                    }
                }
            });
            state.lastUpdate = Date.now();
        },

        errorSent(state) {
            state.errors = state.errors.map((e) => {
                return { ...e, sent: true };
            });
        },
        clearErrors(state) {
            state.errors = [];
        }
    }
});

export const { initDropoff, init, sync, updateCurrent, clearErrors, errorSent } = ScalesWeightSlice.actions;
export default ScalesWeightSlice.reducer;
