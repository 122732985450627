import { FormattedMessage, useIntl } from 'react-intl';

import { Contact } from '../../../api/contacts';
import badgeNfc from '../../../static/nike/badge-nfc-alt.png';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';

interface HelpOrthopedicManagerProps {
    buyer: Contact;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onSelectReceiver?: (receiver: Contact) => void;
}

function HelpOrthopedicManager(props: HelpOrthopedicManagerProps) {
    const homeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );

    const intl = useIntl();

    const onForMyself = () => {
        if (props.onSelectReceiver) {
            props.onSelectReceiver(props.buyer);
        }
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.help.HelpOrthopedicManager.title'
                    description='The title on the help orthopedic support page when a Nike manager is logged in.'
                    defaultMessage='Request orthopedic support'
                />
            </ViewTitle>
            <p className='text-center'>
                <FormattedMessage
                    id='views.nike.help.HelpOrthopedicManager.message'
                    description='The message on the help orthopedic support page when a Nike manager is logged in.'
                    defaultMessage='Please identify the athlete you are ordering orthopedic support for. Scan their badge now.'
                />
            </p>
            <img
                src={badgeNfc}
                className='dashboard-scan-image mb-3 filter-primary'
                alt={intl.formatMessage({
                    id: 'views.nike.help.HelpOrthopedic.badgeImgAlt',
                    description: 'The alt image text for the home screen NFC badge image',
                    defaultMessage: 'NFC badge'
                })}
            />
            <p className='text-center'>
                <button
                    className='light-button'
                    onClick={onForMyself}>
                    <FormattedMessage
                        id='views.nike.help.HelpOrthopedicManager.forMyselfButton'
                        description='The message on the button when the coach is ordering orthopedic shoes for himself. This is on the help orthopedic support page when a Nike manager is logged in.'
                        defaultMessage='Or request orthopedic support for yourself'
                    />
                    <i className='bi bi-arrow-right-circle ms-2'></i>
                </button>
            </p>
        </BaseView>
    );
}

export default HelpOrthopedicManager;
