import BrowserPrint from '../BrowserPrint';
import { PrinterStatus } from '../Printer';
import ZebraPrinter from '../printers/ZebraPrinter';
import IPrintingService from './IPrintingService';

export default class ZebraPrintingService implements IPrintingService {
    printer: ZebraPrinter;

    constructor(printer: ZebraPrinter) {
        this.printer = printer;
    }

    async print(zplString: string): Promise<void> {
        return await BrowserPrint.print(this.printer, zplString);
    }

    async getTotalLabelsPrinted(): Promise<number | undefined> {
        return await BrowserPrint.getTotalLabelsPrinted(this.printer);
    }

    async resetPrinter() {
        return await BrowserPrint.reset(this.printer);
    }

    async setPausePrinter(value: boolean) {
        return await BrowserPrint.setPausePrinter(this.printer, value);
    }

    async setSleepMode(value: boolean) {
        return await BrowserPrint.setSleepMode(this.printer, value);
    }

    async getPrinterStatus(): Promise<PrinterStatus> {
        try {
            const result = await BrowserPrint.getStatus(this.printer);
            return this.printer.parsePrinterStatus(result);
        } catch (error) {
            return {
                offline: true,
                paperOut: false,
                paused: false,
                headOpen: false,
                ribbonOut: false,
                error: 'Printer is offline or off.'
            };
        }
    }

    async cancelAll(): Promise<void> {
        return await BrowserPrint.cancelAll(this.printer);
    }

    async setSynchronousMode(value: boolean): Promise<void> {
        return await BrowserPrint.setSynchronousMode(this.printer, value);
    }
}
