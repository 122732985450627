import { ContactGroup } from '../../api/contactGroups';
import { Contact } from '../../api/contacts';
import { Product } from '../../api/products';
import { Transaction, TransactionStatus, TransactionType } from '../../common/transactions';
import { filterContactsByContactGroupId } from '../contact/ContactFilter';
import { sortTransactionsByCreatedOnDate } from './TransactionSort';

export function getOldestTransaction(transactions: Transaction[]): Transaction {
    return sortTransactionsByCreatedOnDate(transactions)[0];
}

export function filterTransactionsByProduct(transactions: Transaction[], product: Product): Transaction[] {
    const result: Transaction[] = [];
    for (const transaction of transactions) {
        transaction.product_instances.forEach((instance) => {
            if (instance.product === product.id) {
                result.push(transaction);
                return false;
            }
            return true;
        });
    }
    return result;
}

export function filterTransactionsByReceiver(transactions: Transaction[], receiverId: string | number): Transaction[] {
    return transactions.filter((transaction) => transaction.receiver !== null && transaction.receiver!.id === receiverId);
}

export function getTransactionsFromContactGroups(transactions: Transaction[], groups: ContactGroup[], contacts: Contact[]): Transaction[] {
    if (contacts === undefined) return [];
    const result: Transaction[] = [];
    const filteredContacts = [...contacts];
    for (const group of groups) {
        filterContactsByContactGroupId(filteredContacts, group.id).forEach((contact) => {
            result.push(...filterTransactionsByReceiver(transactions!, contact.id));
            filteredContacts.splice(filteredContacts.indexOf(contact), 1);
        });
    }
    return result;
}

export function filterTransactionsByStatus(transactions: Transaction[], status: TransactionStatus): Transaction[] {
    return transactions.filter((transaction) => transaction.status === status);
}

/**
 * Will filter the transactions on the given statuses.
 * @param transactions
 * @param status
 * @returns transactions with any of the provided statuses.
 */
export function filterTransactionsByStatuses(transactions: Transaction[], status: TransactionStatus[]): Transaction[] {
    return transactions.filter((transaction) => status.includes(transaction.status));
}

/**
 * This function should be used for hard filtering opperations for async processing.
 * @param arr the array that needs to be filtered.
 * @param predicate function that should be ran over the array.
 */
export async function asyncTransactionFilter(arr: Transaction[], predicate: any) {
    const results = await Promise.all(arr.map(predicate));
    return arr.filter((_v, index) => results[index]);
}

/**
 * Will filter the transactions on the given types.
 * @param transactions
 * @param types
 * @returns transactions wih any of the given types.
 */
export function filterTransactionsByType(transactions: Transaction[], types: TransactionType[]): Transaction[] {
    return transactions.filter((transaction) => types.includes(transaction.type));
}

/**
 * Will filter the transactions on the type lending.
 * @param transactions
 * @returns only transactions with the type lending.
 */
export function getLendingTransactions(transactions: Transaction[]): Transaction[] {
    return transactions.filter((transaction) => isLendingTransaction(transaction));
}
/**
 * Will filter the transactions on the type vending.
 * @param transactions
 * @returns only transactions with the type vending.
 */
export function getVendingTransactions(transactions: Transaction[]): Transaction[] {
    return transactions.filter((transaction) => isVendingTransaction(transaction));
}
/**
 * Will filter the transactions on the type PuDo.
 * @param transactions
 * @returns only transactions with the type PuDo.
 */
export function getPuDoTransactions(transactions: Transaction[]): Transaction[] {
    return transactions.filter((transaction) => isPuDoTransaction(transaction));
}
/**
 * Will filter the transactions on the type return.
 * @param transactions
 * @returns only transactions with the type return.
 */
export function getReturnTransactions(transactions: Transaction[]): Transaction[] {
    return transactions.filter((transaction) => isReturnTransaction(transaction));
}

/**
 * Will check if the transaction is a lending transaction.
 * @param transaction
 * @returns True if the transaction is of type lending.
 */
export function isLendingTransaction(transaction: Transaction): boolean {
    return transaction.type === TransactionType.LENDING;
}
/**
 * Will check if the transaction is a PuDO transaction.
 * @param transaction
 * @returns True if the transaction is of type PuDo.
 */
export function isPuDoTransaction(transaction: Transaction): boolean {
    return transaction.type === TransactionType.PUDO;
}
/**
 * Will check if the transaction is a return transaction.
 * @param transaction
 * @returns True if the transaction is of type return.
 */
export function isReturnTransaction(transaction: Transaction): boolean {
    return transaction.type === TransactionType.RETURN;
}
/**
 * Will check if the transaction is a vending transaction.
 * @param transaction
 * @returns True if the transaction is of type vending.
 */
export function isVendingTransaction(transaction: Transaction): boolean {
    return transaction.type === TransactionType.VENDING;
}
