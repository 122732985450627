import { useIntl } from 'react-intl';

import { AllowedActionViewProps } from '../../components/domain/AllowedAction';
import { isAdmin } from '../../services/contact/GlobalPermissions';
import ResetView from '../../views/ResetView';

type TerminalConfigActionProps = AllowedActionViewProps;

const TerminalConfig = (props: TerminalConfigActionProps) => {
    return (
        <ResetView
            onHome={props.onHome}
            onBack={props.onBack}
            onInactivity={props.onInactivity}
        />
    );
};

function TerminalConfigAction(props: AllowedActionViewProps) {
    const intl = useIntl();
    if (isAdmin(props.contact)) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.AuthenticatedContactWorkflow.TerminalConfigAction',
                description: 'This is the terminal config action for AuthenticatedContactWorkflow',
                defaultMessage: 'Terminal config'
            }),
            icon: <i className='bi bi-gear dashboard-action-icon' />,
            view: <TerminalConfig {...props} />
        };
    }
}

export default TerminalConfigAction;
