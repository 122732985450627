import { useContext } from 'react';
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { ApiQueryParams, queryParamsToCacheKeys } from './baseQueryParams';
import { Contact, isContact } from './contacts';
import { ApiError, FetchOptions, postApi } from './utils';

export interface ProductOrder {
    id: string | number;
    product: string | number;
    order: string | number;
    amount: number;
}

export interface Order {
    id: number | string;
    url: string;
    shop?: number | string | null;
    receiver: string | number;
    buyer: string | number;
    product_orders: ProductOrder[];
    additional_data: unknown;
    created_date: string;
    fulfilled_date?: string | null;
}

export interface CreateProductOrder {
    product: string | number;
    amount?: number;
}

export interface CreateOrder {
    shop?: number | string | null;
    receiver: string | number;
    buyer?: string | number;
    product_orders: CreateProductOrder[];
    additional_data?: unknown;
}

export const isOrder = (order: any): order is Order => {
    return (order as Order).url !== undefined && (order as Order).url.includes('/orders/');
};

enum OrdersQueryParams {
    RECEIVER = 'receiver',
    BUYER = 'buyer'
}

const ordersViewSet: ApiViewSet = {
    baseName: 'orders'
};

export interface OrdersOptions {
    receiver?: Contact | string | number | null;
    buyer?: Contact | string | number | null;
}

interface MutateCreateOrderVariables {
    createOrder: CreateOrder;
}

const defaultConfig = {
    staleTime: Infinity
};

function fetchOrdersApi(queryParams?: ApiQueryParams<OrdersQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Order[]> {
    return apiList<Order, OrdersQueryParams>(ordersViewSet, queryParams, fetchOptions);
}

function fetchOrderApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<Order> {
    return apiDetail<Order>(ordersViewSet, options, fetchOptions);
}

function createOrder(options?: FetchOptions): (variables: MutateCreateOrderVariables) => Promise<Order> {
    return async (variables: MutateCreateOrderVariables): Promise<Order> => {
        const response = await postApi(`/orders/`, variables.createOrder, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating order');
            }
            throw new ApiError('Error creating order', json);
        }
        return await response.json();
    };
}

export function useOrders(options?: OrdersOptions, queryOptions?: UseQueryOptions<Order[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    const apiOptions = {
        receiver: options?.receiver,
        buyer: options?.buyer
    } as ApiQueryParams<OrdersQueryParams>;

    const receiverId = apiOptions.receiver ? (isContact(apiOptions.receiver) ? apiOptions.receiver.id : apiOptions.receiver).toString() : undefined;
    const buyerId = apiOptions.buyer ? (isContact(apiOptions.buyer) ? apiOptions.buyer.id : apiOptions.buyer).toString() : undefined;
    const queryParams: ApiQueryParams<OrdersQueryParams> = {
        receiver: receiverId,
        buyer: buyerId
    };

    return useQuery<Order[]>(['orders', queryParamsToCacheKeys(OrdersQueryParams, queryParams)], fetchOrdersApi(queryParams, fetchOptions), config);
}

export function useMutateCreateOrder(options?: UseMutationOptions<Order, unknown, MutateCreateOrderVariables>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();
    const config: UseMutationOptions<Order, unknown, MutateCreateOrderVariables> = {
        ...options,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['orders']);
        }
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(createOrder(fetchOptions), config);
}
