import { toNumber } from 'lodash';

import { Terminal } from '../../api/terminals';
import { Logger } from '../../logs/Logger';
import Printer from '../../printing/Printer';
import PrinterQueue from '../../printing/PrinterQueue';
import PrinterPrintException from '../../printing/exceptions/PrinterPrintException';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest, WebsocketCommandResponseStatus, WebsocketCommandType } from '../useManagedWebsocket';

export class WebsocketPrintLabelCommand extends WebsocketCommand {
    data: {
        document: string;
        numberOfCopies: number;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        if (request.number_of_copies === undefined) throw new Error('Incorect information passed.');
        const numberOfCopies = toNumber(request.number_of_copies);
        if (request.document === undefined || typeof request.document !== 'string' || typeof numberOfCopies !== 'number')
            throw new Error('Incorect information passed.');

        this.data = {
            document: request.document,
            numberOfCopies: numberOfCopies
        };
    }

    public async execute(terminal: Terminal | null, websocket: WebSocket): Promise<WebsocketCommandResponse> {
        const processId = await PrinterQueue.addJob(this);
        return new Promise((resolve) => {
            const listener = async () => {
                Logger.log('revieved event: ' + processId);
                let printResult;
                try {
                    printResult = await Printer.getInstance().print(processId, this.data.document, this.data.numberOfCopies);
                    Logger.log(printResult);
                } catch (error) {
                    Logger.error((error as PrinterPrintException).message, {}, (error as PrinterPrintException).printResponse);
                    printResult = (error as PrinterPrintException).printResponse;
                }

                websocket.send(
                    JSON.stringify({
                        message: {
                            ...printResult,
                            additional_data: this.additional_data
                        },
                        status: printResult?.error === undefined ? WebsocketCommandResponseStatus.SUCCESS : WebsocketCommandResponseStatus.ERROR,
                        command: this.type,
                        terminal: terminal
                    })
                );
                if (printResult !== undefined && printResult.error === undefined) {
                    Logger.log('removed listener:' + processId);
                    clearTimeout(timeout);
                    removeListener();
                    resolve({ success: true });
                }
            };

            const removeListener = () => {
                window.removeEventListener(WebsocketCommandType.PRINT_LABEL + ':' + processId, listener);
            };
            window.addEventListener(WebsocketCommandType.PRINT_LABEL + ':' + processId, listener);

            const timeout = setTimeout(() => {
                Logger.error('EventListenerTimeout: Job no longer relevant.', {}, this);
                removeListener();
                resolve({
                    success: false,
                    message: 'Job waited in queue for to long.',
                    send: true
                });
                PrinterQueue.removeById(processId);
                clearTimeout(timeout);
            }, processId - Date.now());
        });
    }
}
