import { ContactGroup } from '../../api/contactGroups';
import { Contact } from '../../api/contacts';
import { Spot } from '../../api/spots';
import { TransactionContactGroup } from '../../common/transactions';

export function filterContactGroupsByContact(groups: ContactGroup[], contact: Contact): ContactGroup[] {
    return groups.filter((group) => contact.contact_groups.includes(+group.id));
}

export function getOnlySharingGroups(groups: ContactGroup[], spot: Spot): ContactGroup[] {
    return groups.filter((group) => spot.sharing_allowed_for_contact_groups.includes(group.id));
}

export function getContactGroupFromTransactionContactGroup(
    contactGroups: ContactGroup[],
    transactionContactGroup: TransactionContactGroup
): ContactGroup | undefined {
    return contactGroups.find((contactGroup) => contactGroup.id === transactionContactGroup.id);
}
