import { FaUserCog } from 'react-icons/fa';
import { useIntl } from 'react-intl';

import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { isAdmin } from '../../../services/contact/GlobalPermissions';
import AdminWorkflow from '../../Admin/AdminWorkflow';

type AdminPanelActionProps = AllowedActionViewProps;

const AdminPanel = (props: AdminPanelActionProps) => {
    return (
        <AdminWorkflow
            onInactivity={props.onInactivity}
            onHome={props.onHome}
            onBack={props.onBack}
            contact={props.contact}
        />
    );
};

function AdminPanelAction(props: AllowedActionViewProps) {
    const intl = useIntl();
    if (isAdmin(props.contact)) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.AuthenticatedContactWorkflow.AdminPanelAction',
                description: 'This is the admin panel action',
                defaultMessage: 'Admin'
            }),
            icon: (
                <FaUserCog
                    className='dashboard-action-icon'
                    size={264}
                />
            ),
            view: <AdminPanel {...props} />,
            highlight: isAdmin(props.contact)
        };
    }
    return undefined;
}

export default AdminPanelAction;
