import { ProtonCommands } from '../../services/ProtonScalesService';
import { IProtonResponse, isProtonResponse } from './IProtonResponse';

export interface DoZeroResponse extends IProtonResponse {
    header: {
        action: ProtonCommands.ZERO_DEVICE;
    } & Omit<IProtonResponse['header'], 'action'>;
}

export function isDoZeroResponse(response: unknown): response is DoZeroResponse {
    return isProtonResponse(response) && (response as DoZeroResponse).header && (response as DoZeroResponse).header.action === ProtonCommands.ZERO_DEVICE;
}
