import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Transaction } from '../common/transactions';
import { LookupFlags } from '../hooks/lookup/transactions/actions';

interface ScannerProcessingModalProps {
    isInProgress: boolean;
    currentLookup: CurrentLookup | null;
    openDropoffEnabled?: boolean;
    contactFound: boolean;
    transactionFound: boolean;
    startDropoff: (val: StartDropoffOptions) => void;
    resetLookup: () => void;
}

interface CurrentLookup {
    value: string;
    lookupFlags?: LookupFlags;
}

interface StartDropoffOptions {
    transaction?: Transaction;
    tracking_number?: string;
}

const ScannerProcessingModal = (props: ScannerProcessingModalProps) => {
    let processingModalBody: string | React.ReactNode = '';
    const processingModelBodySpinner = (
        <div className='d-flex flex-row justify-content-center'>
            <Spinner
                animation='border'
                role='status'></Spinner>
        </div>
    );
    if (props.isInProgress) {
        processingModalBody = processingModelBodySpinner;
    } else {
        if (!props.transactionFound && !props.contactFound) {
            // In this case, it might be possible to perform open dropoff. But me need to be sure it's not a pincode.
            if (
                props.currentLookup &&
                props.currentLookup.lookupFlags &&
                (props.currentLookup.lookupFlags & (LookupFlags.DROPOFF_CODE | LookupFlags.PICKUP_CODE)) === 0 &&
                props.openDropoffEnabled
            ) {
                processingModalBody = (
                    <>
                        <p className='text-center'>
                            <FormattedMessage
                                id='workflow.config.pudo.BarcodeNotRecognizedOpenDropoffEnabled'
                                description='The message shown on the modal dialog that a scanned code is not valid but open dropoff is enabled.'
                                defaultMessage='Code not recognized.'
                            />
                        </p>
                        <button
                            className='primary-button'
                            onClick={() => {
                                props.startDropoff({
                                    tracking_number: props.currentLookup!.value
                                });
                                props.resetLookup();
                            }}>
                            <FormattedMessage
                                id='workflow.config.pudo.OpenDropoffButton'
                                description='The message shown on the button of the modal dialog that a scanned code is not valid but open dropoff is enabled.'
                                defaultMessage='Drop off anyway'
                            />
                        </button>
                    </>
                );
            } else {
                processingModalBody = (
                    <p className='text-center'>
                        <FormattedMessage
                            id='workflow.config.pudo.BarcodeNotRecognizedOpenDropoffDisabled'
                            description='The message shown on the modal dialog that a scanned code is not valid and open dropoff is not enabled.'
                            defaultMessage='Invalid code. Try again.'
                        />
                    </p>
                );
            }
        } else {
            // Redirecting soon
            processingModalBody = processingModelBodySpinner;
        }
    }
    return <div>{processingModalBody}</div>;
};

export default ScannerProcessingModal;
