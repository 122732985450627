import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import ErrorView from '../common/ErrorView';

interface NoSlotsAvailableErrorViewProps {
    onHome?: () => void;
    onInactivity?: () => void;
}

const NoSlotsAvailableErrorView = (props: NoSlotsAvailableErrorViewProps) => {
    const backHomeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;
    return (
        <ErrorView
            navbarItems={backHomeNav}
            title={'No more SLOTS available'}
            message={'All SLOTS are in use. Please try again later.'}
            onInactivity={props.onHome}
        />
    );
};

export default NoSlotsAvailableErrorView;
