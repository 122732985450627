import React from 'react';

interface ViewTitleProps {
    children?: React.ReactNode;
}

function ViewTitle(props: ViewTitleProps) {
    return <h3 className='text-center mt-xl-0 mt-3'>{props.children}</h3>;
}

export default ViewTitle;
