import { useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import FEATURE_TOGGLES from '../FeatureToggles';
import { TerminalContext } from '../TerminalInit';
import { prefetchAccessControls } from '../api/IOT/accessContols';
import { prefetchPrinters } from '../api/IOT/printers';
import { prefetchAttributes } from '../api/attribute';
import { prefetchCategories } from '../api/category';
import { prefetchContactGroups } from '../api/contactGroups';
import { prefetchContacts } from '../api/contacts';
import { prefetchProducts } from '../api/products';
import { prefetchShops } from '../api/shops';
import { prefetchSlots } from '../api/slots';
import { prefetchSpotLayoutItems } from '../api/spotLayoutItems';
import { prefetchSpotLayout } from '../api/spotLayouts';
import { prefetchSpot } from '../api/spots';
import { fetchStockFills } from '../api/stockFills';
import { fetchSpotTransactions } from '../api/transactions';
import { ApiError } from '../api/utils';
import { Logger } from '../logs/Logger';
import { initSpotLayoutLogs } from '../redux/SpotLayoutLogsSlice';
import { HardwareConnectionContext } from '../terminal/useHardwareConnection';
import { useAppDispatch } from './redux';

const usePrefetchInitialData = () => {
    const connection = useContext(HardwareConnectionContext)!;
    const queryClient = useQueryClient();
    const [prefetchDone, changePrefetchDone] = useState(false);
    const [prefetchError, changePrefetchError] = useState(false);
    const [spotLayoutFetched, changeSpotLayoutFetched] = useState(false);
    const [spotLayoutItemsFetched, changeSpotLayoutItemsFetched] = useState(false);
    const [spotFetched, changeSpotFetched] = useState(false);
    const [slotsFetched, changeSlotsFetched] = useState(false);
    const [transactionsFetched, changeTransactionsFetched] = useState(false);
    const [contactsFetched, changeContactsFetched] = useState(false);
    const [contactGroupsFetched, changeContactGroupsFetched] = useState(false);
    const [printersFetched, changePrintersFetched] = useState(false);
    const [shopsFetched, changeShopsFetched] = useState(false);
    const [productsFetched, changeProductsFetched] = useState(false);
    const [stockFillsFetched, changeStockFillsFetched] = useState(false);
    const [categoriesFetched, changeCategoriesFetched] = useState(false);
    const [attributesFetched, changeAttributesFetched] = useState(false);
    const [accessControlsFetched, changeAccessControlsFetched] = useState(false);
    const terminalContext = useContext(TerminalContext);
    const enabled = !!terminalContext.terminal && (!terminalContext.includeAccessToken || !!terminalContext.accessToken);
    const terminal = terminalContext.terminal;
    const dispatch = useAppDispatch();

    const doPrefetch = useCallback(async () => {
        if (!terminal) return;

        const fetchOptions = {
            includeAccessToken: terminalContext.includeAccessToken,
            accessToken: terminalContext.accessToken
        };

        const fetchSpotLayoutAndDependants = async () => {
            const spotLayout = await prefetchSpotLayout(queryClient, terminal, undefined, fetchOptions);
            changeSpotLayoutFetched(true);

            const prefetchSpotLayoutItemsFunc = async () => {
                const items = await prefetchSpotLayoutItems(queryClient, spotLayout, undefined, fetchOptions);
                try {
                    Logger.log('Initializing spotlayout.');
                    if (FEATURE_TOGGLES.SLOT_LOGGING) {
                        dispatch(initSpotLayoutLogs(items));
                        connection.sendSpotLayoutConfig(items.filter((i) => i.terminal === undefined || i.terminal === null));
                    }
                } catch {}
                changeSpotLayoutItemsFetched(true);
            };

            const prefetchSpotAndDependants = async () => {
                const spot = await prefetchSpot(queryClient, spotLayout, undefined, fetchOptions);
                changeSpotFetched(true);

                const prefetchSlotsFunc = async () => {
                    await prefetchSlots(queryClient, { spot: spot.id }, undefined, fetchOptions);
                    changeSlotsFetched(true);
                };

                const prefetchStockFills = async () => {
                    await fetchStockFills(queryClient, { account: JSON.stringify(spot.account) }, undefined, fetchOptions);
                    changeStockFillsFetched(true);
                };

                const fetchTransactions = async () => {
                    await fetchSpotTransactions(queryClient, { spot: spot.id }, undefined, fetchOptions);
                    changeTransactionsFetched(true);
                };

                const prefetchContactsFunc = async () => {
                    await prefetchContacts(queryClient, spot.account, undefined, fetchOptions);
                    changeContactsFetched(true);
                };

                const prefetchContactGroupsFunc = async () => {
                    await prefetchContactGroups(queryClient, spot.account, undefined, fetchOptions);
                    changeContactGroupsFetched(true);
                };

                const fetchPrinters = async () => {
                    try {
                        await prefetchPrinters(queryClient, spot.id, undefined, fetchOptions);
                        changePrintersFetched(true);
                    } catch (error: unknown) {
                        if (
                            (error as ApiError).json &&
                            ((error as ApiError).json as { message: string }).message &&
                            ((error as ApiError).json as { message: string }).message === 'You have to enable the account iot connection'
                        ) {
                            changePrintersFetched(true);
                        } else throw error;
                    }

                    changePrintersFetched(true);
                };

                const fetchAccessControls = async () => {
                    try {
                        await prefetchAccessControls(queryClient, spot.id, undefined, fetchOptions);
                        changeAccessControlsFetched(true);
                    } catch (error: unknown) {
                        if (
                            (error as ApiError).json &&
                            ((error as ApiError).json as { message: string }).message &&
                            ((error as ApiError).json as { message: string }).message === 'You have to enable the account iot connection'
                        ) {
                            changeAccessControlsFetched(true);
                        } else throw error;
                    }

                    changeAccessControlsFetched(true);
                };

                await Promise.all([
                    prefetchSlotsFunc(),
                    fetchTransactions(),
                    prefetchContactsFunc(),
                    prefetchContactGroupsFunc(),
                    prefetchStockFills(),
                    fetchPrinters(),
                    fetchAccessControls()
                ]);
            };

            await Promise.all([prefetchSpotLayoutItemsFunc(), prefetchSpotAndDependants()]);
        };

        const fetchShopsAndDependants = async () => {
            const shops = await prefetchShops(queryClient, terminal, undefined, fetchOptions);
            changeShopsFetched(true);
            await prefetchProducts(queryClient, shops, undefined, fetchOptions);
            changeProductsFetched(true);
            await prefetchCategories(queryClient, shops, undefined, fetchOptions);
            changeCategoriesFetched(true);

            await prefetchAttributes(queryClient, undefined, fetchOptions);
            changeAttributesFetched(true);
        };

        await Promise.all([fetchSpotLayoutAndDependants(), fetchShopsAndDependants()]);
        changePrefetchDone(true);
    }, [queryClient, terminal, terminalContext.includeAccessToken, terminalContext.accessToken]);

    useEffect(() => {
        if (!prefetchError && enabled) {
            doPrefetch().catch(() => {
                Logger.log('Prefetch error.');
                changePrefetchError(true);
            });
        }
    }, [prefetchError, enabled]);

    const retry = useCallback(() => {
        changePrefetchError(false);
    }, [changePrefetchError]);

    return {
        prefetchError,
        prefetchDone,
        spotLayoutFetched,
        spotLayoutItemsFetched,
        spotFetched,
        slotsFetched,
        transactionsFetched,
        contactsFetched,
        contactGroupsFetched,
        printersFetched,
        shopsFetched,
        productsFetched,
        stockFillsFetched,
        categoriesFetched,
        attributesFetched,
        accessControlsFetched,
        retry
    };
};

export default usePrefetchInitialData;
