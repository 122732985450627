import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { SpotLayoutItem, useSpotLayoutItems } from '../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../api/spotLayouts';
import SpotLayout from '../../elements/SpotLayout';

interface SlotOpenMultiSpotLayoutProps {
    highlightedItem?: SpotLayoutItem;
}

const SlotOpenMultiSpotLayout = (props: SlotOpenMultiSpotLayoutProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });

    const [overwriteSpotLayoutItems, changeOverwriteSpotLayoutItems] = useState<SpotLayoutItem[] | undefined>();
    const [correctHighlightedItem, changeCorrectHighlightedItem] = useState<SpotLayoutItem[] | undefined>();

    useEffect(() => {
        let validItems = spotLayoutItems?.filter((item) => {
            return item.hardware_driver_options.data[0].config.column === '1';
        });
        validItems = validItems?.map((item) => {
            const copy = JSON.parse(JSON.stringify(item));
            copy.width = 100;
            return copy as SpotLayoutItem;
        });
        changeOverwriteSpotLayoutItems(validItems);
        if (props.highlightedItem) {
            changeCorrectHighlightedItem(
                validItems?.filter((item) => {
                    return item.hardware_driver_options.data[0].config.row === props.highlightedItem?.hardware_driver_options.data[0].config.row;
                })
            );
        }
    }, [spotLayoutItems]);

    const labelForItem = (spotLayoutItem: SpotLayoutItem): string => {
        return spotLayoutItem.hardware_driver_options.data[0].config.row ? spotLayoutItem.hardware_driver_options.data[0].config.row : '';
    };
    return (
        <SpotLayout
            highlightedItems={correctHighlightedItem}
            spotLayoutItemsOverwrite={overwriteSpotLayoutItems}
            labelForItem={labelForItem}
        />
    );
};

export default SlotOpenMultiSpotLayout;
