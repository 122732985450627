import { PlatformHardwareDriver, SpotLayoutItem } from '../../../api/spotLayoutItems';
import { Logger } from '../../../logs/Logger';
import { BackendHardwareDriverOptionsObject, TerminalHardwareObject } from '../DriverSlotObjects';

export interface KerongHardwareObject extends TerminalHardwareObject {
    id: string;
    slots: {
        board: string;
        spotLayoutItemId: string;
        id: string;
        ip: string;
    }[];
}

export function mapKerongHardwareOptions(options: KerongHardwareObject[]): KerongHardwareObject[] {
    const mapped: KerongHardwareObject[] = [];
    options.forEach((option) => {
        const alreadyExists = mapped.find((i) => i.id === option.id);
        if (alreadyExists) {
            mapped[mapped.indexOf(alreadyExists)] = {
                ...alreadyExists,
                slots: [...alreadyExists.slots, ...option.slots].sort((a, b) => +a.id - +b.id)
            };
        } else {
            mapped.push(option);
        }
    });
    return mapped;
}

export function getKerongHardwareOptions(spotLayoutItem: SpotLayoutItem): KerongHardwareObject | null {
    const options = spotLayoutItem.hardware_driver_options.data;
    if (options.find((option) => !checkKerongDriverOptions(option)) !== undefined) {
        Logger.error('incorrect driver params given', {}, spotLayoutItem);
        return null;
    }
    return getKerongDriverOptions(spotLayoutItem);
}

function getKerongDriverOptions(spotLayoutItem: SpotLayoutItem): KerongHardwareObject {
    const options = spotLayoutItem.hardware_driver_options.data;
    return {
        driver: options[0].driver,
        id: options[0].config.ip!,
        slots: options.map((option) => {
            return {
                spotLayoutItemId: spotLayoutItem.id,
                board: option.config.board!,
                id: option.config.slot!,
                ip: option.config.ip!
            };
        })
    };
}

function checkKerongDriverOptions(options: BackendHardwareDriverOptionsObject): boolean {
    return (
        (options.driver === PlatformHardwareDriver.KERONG_CU ||
            options.driver === PlatformHardwareDriver.KERONG_SCU ||
            options.driver === PlatformHardwareDriver.KERONG_12CU) &&
        options.config.ip !== undefined &&
        options.config.slot !== undefined
    );
}

export function isKerongDriverOptions(options: TerminalHardwareObject): options is KerongHardwareObject {
    return (
        (options.driver === PlatformHardwareDriver.KERONG_CU ||
            options.driver === PlatformHardwareDriver.KERONG_SCU ||
            options.driver === PlatformHardwareDriver.KERONG_12CU) &&
        Array.isArray((options as any).slots)
    );
}

export function isValidKerongSpotLayoutItem(spotLayoutItem: SpotLayoutItem): boolean {
    return spotLayoutItem.hardware_driver_options.data.some((driver) => isValidKerongDriver(driver.driver));
}

export function isValidKerongDriver(driver: PlatformHardwareDriver): boolean {
    return driver === PlatformHardwareDriver.KERONG_CU || driver === PlatformHardwareDriver.KERONG_SCU || driver === PlatformHardwareDriver.KERONG_12CU;
}
