import { useContext, useEffect, useState } from 'react';

import FEATURE_TOGGLES from '../../FeatureToggles';
import { useSpotLayoutItems } from '../../api/spotLayoutItems';
import { useSpotLayout } from '../../api/spotLayouts';
import { Terminal } from '../../api/terminals';
import { Logger } from '../../logs/Logger';
import { SliceAsyncProcessStatus, SliceStatus } from '../../redux/ReduxCommonVariables';
import { changeSpotLayoutStateStatus, initSpotLayoutLogs, invalidateSpotLayoutState, updateSpotLayoutState } from '../../redux/SpotLayoutLogsSlice';
import { TerminalHardwareObject } from '../../services/platformHardwareDriver/DriverSlotObjects';
import { HardwareConnectionContext } from '../../terminal/useHardwareConnection';
import { useAppDispatch, useAppSelector } from '../redux';

const useSlotStateValue = (terminal: Terminal | null, websocket: WebSocket | null) => {
    const connection = useContext(HardwareConnectionContext)!;
    const dispatch = useAppDispatch();
    const spotLayoutLogsStore = useAppSelector((state) => state.spotLayoutLogs);

    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const [stateUpdate, changeStateUpdate] = useState<TerminalHardwareObject[] | null>(null);

    useEffect(() => {
        window.addEventListener('terminal:slotsStateUpdateResult', (event) => {
            Logger.debug('New slots state update value', {}, event.detail);
            changeStateUpdate(JSON.parse(event.detail.result) as TerminalHardwareObject[]);
        });
    }, []);

    const fullStateCheck = async () => {
        if (FEATURE_TOGGLES.SLOT_LOGGING === false) return;
        if (spotLayoutLogsStore.spotLayoutStateStatus === SliceAsyncProcessStatus.BLOCKED) {
            Logger.debug('Cannot update openSlotState since it is already blocked by another process.');
            return;
        }
        Logger.debug('openSlotState check start');
        dispatch(changeSpotLayoutStateStatus());
        if (spotLayoutItems === undefined) {
            Logger.log('No spotlayout items found or context not initiated yet. This check was simply called to early in the initialising process.');
            dispatch(changeSpotLayoutStateStatus());
            return;
        }
        dispatch(invalidateSpotLayoutState(spotLayoutItems));

        //request slot state of all slots
        connection.sendSpotLayoutConfig(spotLayoutItems);

        Logger.debug('openSlotState check done');
        dispatch(changeSpotLayoutStateStatus());
    };

    useEffect(() => {
        if (spotLayoutLogsStore.status === SliceStatus.INIT && spotLayoutItems) {
            dispatch(initSpotLayoutLogs(spotLayoutItems));
        }
    }, [spotLayoutItems, spotLayoutLogsStore.status]);

    useEffect(() => {
        if (FEATURE_TOGGLES.SLOT_LOGGING === false) return;
        if (stateUpdate !== null && spotLayoutItems !== undefined) {
            dispatch(
                updateSpotLayoutState({
                    slotState: stateUpdate,
                    websocket: websocket ? websocket : undefined,
                    spotlayoutItems: spotLayoutItems
                })
            );
            changeStateUpdate(null);
        }
    }, [stateUpdate, spotLayoutItems]);

    return { fullStateCheck } as const;
};

export default useSlotStateValue;
