import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import { Terminal } from './api/terminals';

type PickTerminalProps = {
    terminals: Terminal[];
    onTerminalChanged: (terminal: Terminal | null) => void;
};

const PickTerminal = (props: PickTerminalProps) => {
    const terminalsMap = new Map<string, Terminal>();
    for (const terminal of props.terminals) {
        terminalsMap.set(terminal.id.toString(), terminal);
    }

    const [terminalSelected, changeTerminalSelected] = useState<Terminal | null>(props.terminals.length > 0 ? props.terminals[0] : null);

    return (
        <Container>
            <div className='text-center mt-2'>
                {props.terminals.length === 0 ? (
                    <p>You do not have access to any Terminals. Did you login as the correct user?</p>
                ) : (
                    <Form
                        onSubmit={(event) => {
                            props.onTerminalChanged(terminalSelected);
                            event.preventDefault();
                        }}>
                        <Form.Group>
                            <Form.Label htmlFor='selectTerminal'>Which Terminal does this represent?</Form.Label>
                            <Form.Control
                                as='select'
                                id='selectTerminal'
                                value={terminalSelected?.id}
                                onChange={(event) => {
                                    const selected = terminalsMap.get(event.target.value);
                                    changeTerminalSelected(selected ? selected : null);
                                }}>
                                {props.terminals.map((terminal) => {
                                    return (
                                        <option
                                            key={terminal.id}
                                            value={terminal.id}>
                                            {terminal.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        <button
                            type='submit'
                            className='primary-button btn-lg'>
                            Submit
                        </button>
                    </Form>
                )}
            </div>
        </Container>
    );
};

export default PickTerminal;
