import { useContext } from 'react';
import { IntlShape } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { TerminalWorkflowType } from '../../../api/terminals';
import { TransactionType } from '../../../common/transactions';
import ActionCard from '../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { isDelivery, isOpenDropoffAllowed } from '../../../services/contact/GlobalPermissions';
import JustDrop from '../../JustDrop';

type OpenDropoffActionProps = AllowedActionViewProps;

function OpenDropoffAction(intl: IntlShape, props: OpenDropoffActionProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);

    const OpenDropoffAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'views.OpenDropoff.justDropButton',
            description: 'The just drop button on the PuDo dropoff view',
            defaultMessage: 'Without code'
        }),
        icon: <i className='bi bi-box-arrow-in-down dashboard-action-icon' />,
        view: (
            <JustDrop
                contact={props.contact}
                onInactivity={props.onHome}
                onHome={props.onHome}
                onBack={props.onBack}
                transactionType={TransactionType.PUDO}
            />
        )
    };

    if (
        terminal !== null &&
        (terminal.workflow === TerminalWorkflowType.PUDO ||
            (terminal.workflow === TerminalWorkflowType.PUDO_PRIVATE && (isDelivery(props.contact) || isOpenDropoffAllowed(props.contact))))
    )
        return OpenDropoffAction;

    return undefined;
}

export default OpenDropoffAction;
