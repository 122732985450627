import { useEffect, useState } from 'react';

import { Logger } from '../../logs/Logger';
import useTerminalEventHandler from '../../terminal/useTerminalEventHandler';

export default function useProtonKeepAlive() {
    const [timer, changeTimer] = useState<NodeJS.Timeout | undefined>(undefined);
    const { protonEvent } = useTerminalEventHandler();

    const startOrResetTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }

        changeTimer(
            setTimeout(() => {
                Logger.error('Did not receive any events from proton for over 6 minutes. Restarting Android and Proton. (proton heartbeat interval is 5min)');
                //TODO android and proton should be reset
            }, 6 * 60 * 1000)
        );
    };

    useEffect(() => {
        if (protonEvent != undefined) {
            if (timer != undefined) {
                startOrResetTimer();
            } else {
                startOrResetTimer();
                Logger.log('Received first Proton event, starting keep alive check for the Proton scales.');
            }
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [protonEvent]);
}
