import { FormattedMessage } from 'react-intl';

import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';

interface HelpOrthopedicProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

function HelpOrthopedicEmployee(props: HelpOrthopedicProps) {
    const homeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.help.HelpOrthopedicEmployee.title'
                    description='The title on the help orthopedic support for employees page when a Nike employee is logged.'
                    defaultMessage='Request orthopedic support'
                />
            </ViewTitle>
            <p>
                <FormattedMessage
                    id='views.nike.help.HelpOrthopedic.message'
                    description='The message on the help orthopedic support for employees page when a Nike employee is logged.'
                    defaultMessage="Ordering orthopedic support requires your team coach's approval. Ask your team coach to order them at this DistriSPOT for you."
                />
            </p>
        </BaseView>
    );
}

export default HelpOrthopedicEmployee;
