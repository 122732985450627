import ClientOAuth2, { Token } from 'client-oauth2';

import { Logger } from '../logs/Logger';

export const redirectUri = process.env.REACT_APP_REDIRECT_URL!;
export const accessTokenUri = process.env.REACT_APP_TOKEN_URL!;
export const oauth2 = new ClientOAuth2({
    clientId: process.env.REACT_APP_CLIENT_ID,
    accessTokenUri: accessTokenUri,
    authorizationUri: process.env.REACT_APP_AUTHORIZATION_URL,
    redirectUri: redirectUri,
    scopes: ['terminal']
}); //de request wordt naar de auth url uitgestuurd en er wordt mooi een token terug gestuurd maar deze wordt niet opgeslagen door de librarie (dit is dus echt wel een librarie bug)

export interface LibAccessToken extends Token {
    expires: Date;
}

export const getTokenFromState = async (state: string) => {
    try {
        let token = (await oauth2.code.getToken(window.location, { state: state })) as LibAccessToken;
        if (!token) {
            try {
                token = JSON.parse(window.localStorage.getItem('oauth2Result')!);
            } catch (err) {
                Logger.error('no token available');
            }
        }
        Logger.debug('Got a token', {}, Logger.generateHash(token.accessToken));
        window.history.replaceState({}, '', redirectUri);
        return token;
    } catch (e) {
        Logger.log('Token exchange failed', {}, e);
    }
};
