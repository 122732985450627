import { toNumber } from 'lodash';

import { Logger } from '../logs/Logger';
import PrinterException from './exceptions/PrinterException';
import IPrinter from './printers/IPrinter';

const BASE_URL = 'http://127.0.0.1:9100/';

export default class BrowserPrint {
    static async getDefaultPrinter(type?: string): Promise<IPrinter> {
        if (type === undefined) type = 'printer';
        try {
            const result = await window.fetch(BASE_URL + 'default?type=' + type, { method: 'GET' });
            const printer = await result.json();
            if (printer?.manufacturer) {
                return printer as IPrinter;
            }
        } catch {}
        throw new PrinterException('Unable to find a default printer.');
    }

    static async getAvailablePrinters(): Promise<IPrinter[]> {
        try {
            const result = await window.fetch(BASE_URL + 'available', { method: 'GET' });
            const printers = (await result.json()) as any[];
            return printers.filter((p) => {
                if (p.manufacturer) {
                    return p as IPrinter;
                }
            });
        } catch {}
        throw new PrinterException('Unable to find available printers.');
    }

    static async getStatus(printer: IPrinter): Promise<string> {
        return await execute(printer, '~hs\r\n');
    }

    static async getTotalLabelsPrinted(printer: IPrinter): Promise<number | undefined> {
        const amount = toNumber(JSON.parse(await execute(printer, createGetCommand('odometer.total_label_count'))));
        if (isNaN(amount)) return undefined;
        return amount;
    }

    static async print(printer: IPrinter, file: string): Promise<void> {
        return await execute(printer, file);
    }

    static async feedLabel(printer: IPrinter): Promise<void> {
        return await execute(printer, '^XA   ^FD   ^XZ');
    }

    static async reset(printer: IPrinter): Promise<void> {
        return await execute(printer, createSetCommand('device.reset'));
    }

    static async setPausePrinter(printer: IPrinter, value: boolean): Promise<void> {
        return await execute(printer, createSetCommand(value ? 'device.pause' : 'device.unpause'));
    }

    static async setSleepMode(printer: IPrinter, value: boolean): Promise<void> {
        return await execute(printer, createSetCommand('power.energy_star.enable', value ? 'on' : 'off'));
    }

    static async cancelAll(printer: IPrinter): Promise<void> {
        return await execute(printer, createSetCommand('formats.cancel_all'));
    }

    static async setSynchronousMode(printer: IPrinter, value: boolean): Promise<void> {
        return await execute(printer, createSetCommand('device.cpcl_synchronous_mode', value ? 'on' : 'off'));
    }
}

async function execute(printer: IPrinter, data: string) {
    await write(printer, data);
    return await read(printer);
}
async function read(printer: IPrinter): Promise<any> {
    try {
        const response = await window
            .fetch(BASE_URL + 'read', {
                method: 'POST',
                body: JSON.stringify({
                    device: printer
                })
            })
            .catch((err) => {
                Logger.error('Something went wrong trying to read command from printer.', {}, err, printer);
                throw new PrinterException('Something went wrong trying to read command from printer.');
            });

        if (response.status === 200) {
            const result = response.text();
            return result;
        }
        throw new PrinterException('Something went wrong trying to read command from printer.');
    } catch (error) {
        throw new PrinterException('Something went wrong trying to read command from printer.');
    }
}
async function write(printer: IPrinter, data: string): Promise<void> {
    try {
        await window.fetch(BASE_URL + 'write', {
            method: 'POST',
            body: JSON.stringify({
                device: printer,
                data: data
            })
        });
    } catch (err) {
        Logger.error('Something went wrong trying to write command to printer.', {}, err, printer);
        throw new PrinterException('Something went wrong trying to write command to printer.');
    }
}

function createSetCommand(command: string, value?: string): string {
    return '! U1 setvar "' + command + '" "' + (value !== undefined ? value : '') + '"\r\n';
}
function createGetCommand(command: string): string {
    return '! U1 getvar "' + command + '"\r\n';
}
