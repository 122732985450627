import Connection from '../../Connection';
import { PSPGHardwareObject } from '../../services/platformHardwareDriver/utils/PSPGHardwareOptions';
import TerminalInterface from '../TerminalInterface';
import AndroidTerminalSoftwareHelper from './AndroidTerminalSoftwareHelper';
import WindowsTerminalSoftwareHelper from './WindowsTerminalSoftwareHelper';

interface TerminalConnectionManagerProps {
    TerminalApp: TerminalInterface;
    windowsSlotObj?: PSPGHardwareObject;
}

export enum TerminalSoftware {
    WINDOWS = 'windows',
    ANDROID = 'android',
    WEBSOCKET = 'websocket',
    UNKNOWN = 'unknown'
}

const useTerminalConnectionManager = (props: TerminalConnectionManagerProps): Connection => {
    if (props.windowsSlotObj) {
        return WindowsTerminalSoftwareHelper.getInstance(props.TerminalApp, props.windowsSlotObj);
    } else {
        return AndroidTerminalSoftwareHelper.getInstance(props.TerminalApp);
    }
};

export default useTerminalConnectionManager;
