import { Logger } from '../../logs/Logger';
import BrowserPrint from '../BrowserPrint';
import PrinterException from '../exceptions/PrinterException';
import IPrinter from '../printers/IPrinter';
import ZebraPrinter from '../printers/ZebraPrinter';
import IPrintingService from './IPrintingService';
import ZebraPrintingService from './ZebraPrintingService';

export default class PrintingServiceFactory {
    static async getPrinterService(): Promise<IPrintingService> {
        const printer = await this.getPrinter();
        if (ZebraPrinter.isZebraPrinter(printer)) return new ZebraPrintingService(new ZebraPrinter(printer));
        throw new PrinterException('Printer found but the manufacturer is unknown.');
    }

    /**
     * @todo
     * @param id not used yet (further implementation needed)
     * @returns
     */
    private static async getPrinter(id?: string): Promise<IPrinter> {
        let printers: IPrinter[] = [];
        try {
            printers.push(await BrowserPrint.getDefaultPrinter());
        } catch (error) {
            Logger.log(error);
        }

        try {
            if (printers.length === 0) {
                printers = await BrowserPrint.getAvailablePrinters();
            }
        } catch (error) {
            Logger.log(error);
        }

        return new Promise((resolve, reject) => {
            if (printers.length > 0) {
                resolve(printers[0] as unknown as IPrinter);
            } else {
                throw new PrinterException('No printers where found.');
            }
        });
    }
}
