import ActionCard from '../../components/domain/ActionCard';
import ActionCardGridElement from '../../components/elements/ActionCardGridElement';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ViewTitle from './ViewTitle';

interface ManageShopViewProps {
    actions: ActionCard[];
    onSelectAction: (action: ActionCard) => void;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

export default function ManageShopView(props: ManageShopViewProps) {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>Shop management</ViewTitle>
            <ActionCardGridElement
                actions={props.actions}
                onSelectAction={props.onSelectAction}
            />
        </BaseView>
    );
}
