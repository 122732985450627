import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import FEATURE_TOGGLES from '../FeatureToggles';
import { SpotLayoutItem } from '../api/spotLayoutItems';
import { Logger } from '../logs/Logger';
import { TerminalHardwareObject, getHardwareDriverConfig } from '../services/platformHardwareDriver/DriverSlotObjects';
import { isZhilaiDriverOptions } from '../services/platformHardwareDriver/utils/ZhilaiHardwareOptions';
import { WebsocketCommandType } from '../websocket/useManagedWebsocket';
import { SliceAsyncProcessStatus, SliceStatus } from './ReduxCommonVariables';

interface RelevantInfo {
    spotLayoutItemId: string;
    id: string;
    open: SlotDoorState | undefined;
    occupied: SlotSensorState | undefined;
}
export interface SpotLayoutLogsState {
    spotLayoutState: RelevantInfo[] | null;
    lastSpotLayoutInfoStateUpdate: { lastUpdate: any; time: number } | undefined;
    openedSpotLayoutItems: string[];

    spotLayoutStateStatus: SliceAsyncProcessStatus;
    status: SliceStatus;
}

const initialState: SpotLayoutLogsState = {
    spotLayoutState: null,
    lastSpotLayoutInfoStateUpdate: undefined,
    openedSpotLayoutItems: [],

    spotLayoutStateStatus: SliceAsyncProcessStatus.FREE,
    status: SliceStatus.INIT
};

export const SpotLayoutLogsSlice = createSlice({
    name: 'SpotLayoutLogs',
    initialState,
    reducers: {
        initSpotLayoutLogs(state, action: PayloadAction<SpotLayoutItem[]>) {
            if (FEATURE_TOGGLES.SLOT_LOGGING === false) return;
            if (state.status === SliceStatus.INIT) {
                Logger.debug('initialising SpotLayoutLogs');
                state.spotLayoutState = getRelevantInfoFromTerminalHardwareObjects(getHardwareDriverConfig(action.payload));
                state.status = SliceStatus.IDLE;
            } else {
                Logger.debug('SpotLayoutLogs state is already initialized.');
            }
        },
        changeSpotLayoutStateStatus(state) {
            if (state.spotLayoutStateStatus === SliceAsyncProcessStatus.BLOCKED) {
                state.spotLayoutStateStatus = SliceAsyncProcessStatus.FREE;
            } else {
                state.spotLayoutStateStatus = SliceAsyncProcessStatus.BLOCKED;
            }
        },

        invalidateSpotLayoutState(state, action: PayloadAction<SpotLayoutItem[]>) {
            if (stateIsInitialisedCheck(state) === true) return;
            state.spotLayoutState = getRelevantInfoFromTerminalHardwareObjects(getHardwareDriverConfig(action.payload));
        },
        updateSpotLayoutState(
            state,
            action: PayloadAction<{ slotState: TerminalHardwareObject[]; websocket?: WebSocket; spotlayoutItems?: SpotLayoutItem[] }>
        ) {
            if (stateIsInitialisedCheck(state) === true) return;
            if (checkIfStateChangeWasAlreadyTriggered(state, action.payload.slotState) === true) return;

            state.lastSpotLayoutInfoStateUpdate = { lastUpdate: action.payload.slotState, time: Date.now() };

            const updatedSlots: StateWebsocketCommand[][] = [];
            const newSlots = getRelevantInfoFromTerminalHardwareObjects(action.payload.slotState);
            const oldSlots = state.spotLayoutState!;

            const slotsCopy = JSON.parse(JSON.stringify(newSlots));

            slotsCopy.forEach((slot: RelevantInfo) => {
                const foundSlot = oldSlots.find((s) => s.id === slot.id);
                if (![foundSlot, foundSlot?.occupied, foundSlot?.open, slot.open, slot.occupied].includes(undefined)) {
                    if (foundSlot!.open !== slot.open || foundSlot!.occupied !== slot.occupied) {
                        updatedSlots.push([
                            {
                                type: SensorType.DOOR,
                                state: slot.open !== undefined ? (slot.open.toString() === 'open' ? SlotDoorState.OPENED : slot.open) : SlotDoorState.UNKNOWN,
                                slot: toNumber(action.payload.spotlayoutItems?.find((s) => s.id === slot.spotLayoutItemId)?.slot?.replace('SLT', '')),
                                info: ''
                            },
                            {
                                type: SensorType.SENSOR,
                                state: slot.occupied !== undefined ? slot.occupied : SlotSensorState.UNKNOWN,
                                slot: toNumber(action.payload.spotlayoutItems?.find((s) => s.id === slot.spotLayoutItemId)?.slot?.replace('SLT', '')),
                                info: ''
                            }
                        ]);
                        if (slot.open !== SlotDoorState.CLOSED && !state.openedSpotLayoutItems.includes(slot.spotLayoutItemId)) {
                            state.openedSpotLayoutItems = [...state.openedSpotLayoutItems, slot.spotLayoutItemId];
                        } else if (slot.open === SlotDoorState.CLOSED && state.openedSpotLayoutItems.includes(slot.spotLayoutItemId)) {
                            state.openedSpotLayoutItems = state.openedSpotLayoutItems.filter((i) => i !== slot.spotLayoutItemId);
                        }
                    }
                }
            });

            state.spotLayoutState = newSlots;

            updatedSlots.forEach((update) => {
                action.payload.websocket?.send(
                    JSON.stringify({
                        data: update,
                        command: WebsocketCommandType.SLOT_STATE
                    })
                );
            });
        }
    }
});

export const { initSpotLayoutLogs, updateSpotLayoutState, changeSpotLayoutStateStatus, invalidateSpotLayoutState } = SpotLayoutLogsSlice.actions;
export default SpotLayoutLogsSlice.reducer;

export enum SensorType {
    DOOR = 'door',
    SENSOR = 'sensor'
}

export enum SlotDoorState {
    OPENED = 'opened',
    CLOSED = 'closed',
    UNKNOWN = 'unknown'
}

export enum SlotSensorState {
    EMPTY = 'empty',
    NOT_EMPTY = 'not_empty',
    UNKNOWN = 'unknown'
}

interface StateWebsocketCommand {
    type: SensorType;
    state: SlotDoorState | SlotSensorState;
    slot?: number;
    info?: string;
}

function stateIsInitialisedCheck(state: SpotLayoutLogsState): boolean {
    if (state.status === SliceStatus.INIT) {
        Logger.error('Cannot update SpotLayoutLogs when it is not yet initialised.');
        return true;
    }
    return false;
}

function checkIfStateChangeWasAlreadyTriggered(state: SpotLayoutLogsState, slotState: TerminalHardwareObject[]): boolean {
    const now = Date.now();
    const { lastSpotLayoutInfoStateUpdate } = state;
    if (
        lastSpotLayoutInfoStateUpdate &&
        JSON.stringify(lastSpotLayoutInfoStateUpdate.lastUpdate) === JSON.stringify(slotState) &&
        (now - lastSpotLayoutInfoStateUpdate.time) / 60000 < 1
    ) {
        Logger.log('The same state update was done already as the last update and it was less then a minute ago, thus skipping update.');
        return true;
    }
    return false;
}

export function getRelevantInfoFromTerminalHardwareObjects(objects: TerminalHardwareObject[]): RelevantInfo[] {
    const relevantInfo: RelevantInfo[] = [];
    objects.forEach((options) => {
        if (isZhilaiDriverOptions(options)) {
            options.slots.forEach((option) => {
                relevantInfo.push({
                    spotLayoutItemId: option.spotLayoutItemId,
                    id: options.name + option.id,
                    open: option.open,
                    occupied: option.occupied
                });
            });
        }
    });
    return relevantInfo;
}
