import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../api/contacts';
import { ProductInstanceForTransaction } from '../../api/productInstances';
import { useProduct } from '../../api/products';
import { formatDatetime, getFullName } from '../../utils';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import LoadingView from '../common/LoadingView';
import { OwnedProductHighlight } from './OwnedProductHighlight';
import ViewTitle from './ViewTitle';

interface ReturnViewProps {
    productInstances: ProductInstanceForTransaction[];
    onSelect: (productInstance: ProductInstanceForTransaction | null) => void;
    returnFor?: Contact | null;
    onHome?: () => void;
    onLogout?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

interface ProductSmallSelectProps {
    productInstance: ProductInstanceForTransaction;
    onSelect: () => void;
}

function ProductSmallSelect(props: ProductSmallSelectProps) {
    const productResult = useProduct(props.productInstance.product);

    const fetchDone = productResult.isSuccess || productResult.isError;

    return (
        <>
            {!fetchDone ? (
                <div
                    className='spinner-border text-primary align-self-center'
                    role='status'></div>
            ) : (
                <button
                    className='card mb-2 btn btn-block text-start'
                    onClick={() => {
                        props.onSelect();
                    }}>
                    <div className='card-body d-flex flex-row justify-content-between align-items-center'>
                        <div>
                            <h6 className='font-weight-bold'>{productResult.data!.name}</h6>
                            <span>
                                <FormattedMessage
                                    id='views.nike.ReturnView.productOtherViewOrderedOnLabel'
                                    description='In a small return product info card, this is the label that tells the date when the product was ordered, in the Nike workflow.'
                                    defaultMessage='Ordered on {orderedDate}'
                                    values={{
                                        orderedDate: formatDatetime(props.productInstance.transaction.completed_date)
                                    }}
                                />
                            </span>
                        </div>
                        <div>
                            <span className='btn btn-link text-primary text-decoration-none text-uppercase'>
                                <FormattedMessage
                                    id='views.nike.ReturnView.productOtherViewReturnLabel'
                                    description='In a small return product info card, this is the call to action to return the product, in the Nike workflow.'
                                    defaultMessage='Return'
                                />
                            </span>
                            <i className='bi bi-arrow-right-circle-fill' />
                        </div>
                    </div>
                </button>
            )}
        </>
    );
}

function ReturnView(props: ReturnViewProps) {
    useEffect(() => {
        if (props.productInstances) {
            if (props.productInstances.length === 0) {
                props.onSelect(null);
            } else if (props.productInstances.length === 1) {
                props.onSelect(props.productInstances[0]);
            }
        }
    }, [props.productInstances]);

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    if (props.productInstances.length < 2) {
        return (
            <LoadingView
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.ReturnView.title'
                    description='The title shown on the select item to return view in the nike workflow'
                    defaultMessage='Please select your item to return'
                />
            </ViewTitle>
            {props.returnFor ? (
                <p className='text-center'>
                    for <span className='font-weight-bold'>{getFullName(props.returnFor)}</span>
                </p>
            ) : undefined}
            <OwnedProductHighlight
                productInstance={props.productInstances[0]}
                onSelect={() => {
                    props.onSelect(props.productInstances[0]);
                }}
            />
            <h4>
                <FormattedMessage
                    id='views.nike.ReturnView.titleMoreItems'
                    description='The subtitle shown on the select item to return view in the nike workflow'
                    defaultMessage='You have more items'
                />
            </h4>
            {props.productInstances.slice(1).map((productInstance) => {
                return (
                    <ProductSmallSelect
                        key={productInstance.id}
                        productInstance={productInstance}
                        onSelect={() => {
                            props.onSelect(productInstance);
                        }}
                    />
                );
            })}
        </BaseView>
    );
}

export default ReturnView;
