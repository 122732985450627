import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import useActivity from '../../hooks/useActivity';

export enum WarningModalType {
    WARNING = 'warning',
    DANGER = 'danger',
    INFO = 'info',
    PRIMARY = 'primary',
    SUCCESS = 'success'
}

export interface WarningModalProps {
    show: boolean;
    description: JSX.Element | string;
    title?: React.ReactNode | string;
    type: WarningModalType;
    cancelButton?: boolean;
    submitButton?: JSX.Element | string;
    onHide?: (result: boolean) => void;
}

const WarningModal = (props: WarningModalProps) => {
    const [, newActivity] = useActivity();
    const [buttonClass, changeButtonClass] = useState<string>('primary-button btn-lg');

    useEffect(() => {
        buttonType();
    });

    const buttonType = () => {
        switch (props.type) {
            case WarningModalType.SUCCESS:
                changeButtonClass('success-button btn-lg');
                break;
            case WarningModalType.DANGER:
                changeButtonClass('danger-button btn-lg');
                break;
            case WarningModalType.WARNING:
                changeButtonClass('warning-button btn-lg');
                break;
            case WarningModalType.INFO:
                changeButtonClass('light-button btn-lg');
                break;
            case WarningModalType.PRIMARY:
                changeButtonClass('primary-button btn-lg');
                break;
        }
    };

    const hide = (result: boolean) => {
        newActivity();
        if (props.onHide) {
            props.onHide(result);
        }
    };

    return (
        <Modal
            centered
            size='lg'
            animation={false}
            show={props.show}>
            {props.title ? (
                <div className='modal-header'>
                    <h5 className='modal-title'>{props.title}</h5>
                </div>
            ) : (
                <></>
            )}
            <Modal.Body className='modal-body'>{props.description}</Modal.Body>
            <Modal.Footer className='modal-footer'>
                {props.cancelButton === false ? (
                    <></>
                ) : (
                    <button
                        className='secondary-button btn-lg'
                        onClick={() => {
                            hide(false);
                        }}>
                        <FormattedMessage
                            id='workflow.all.warningModal.cancel'
                            description='The message shown in the global warning message cancel button.'
                            defaultMessage='Cancel'
                        />
                    </button>
                )}

                {props.submitButton ? (
                    <button
                        className={buttonClass}
                        onClick={() => {
                            hide(true);
                        }}>
                        {props.submitButton}
                    </button>
                ) : (
                    <button
                        className={buttonClass}
                        onClick={() => {
                            hide(true);
                        }}>
                        <FormattedMessage
                            id='workflow.all.warningModal.accept'
                            description='The message shown in the global warning accept button.'
                            defaultMessage='Submit'
                        />
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default WarningModal;
