import { useEffect, useState } from 'react';

import { Attribute, AttributeType } from '../../../api/attribute';
import { Product } from '../../../api/products';
import { useTransactions } from '../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../common/transactions';
import useActivity from '../../../hooks/useActivity';
import { Logger } from '../../../logs/Logger';
import { filterTransactionsByProduct } from '../../../services/transaction/TransactionFilters';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import LoadingView from '../../../views/common/LoadingView';
import ProductAttributeMultiSelect from '../../../views/elements/shop/ProductAttributeMultiSelect';
import ProductAttributeSelect from '../../../views/elements/shop/ProductAttributeSelect';

interface ShopProductAttributeFilterProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    submit: (transactions: Transaction[]) => void;
    products: Product[];
    attributes: Attribute[];
    spotId: string;
}

const ShopProductAttributeFilter = (props: ShopProductAttributeFilterProps) => {
    const [, newActivity] = useActivity();
    const homeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const [attrValueMap, changeAttrValueMap] = useState<Map<Attribute, string | string[]>>();
    const [formValid, changeFormValid] = useState<boolean>(false);
    const [invalidFormMessage, changeInvalidFormMessage] = useState<JSX.Element>(<></>);

    const [allowedProducts, changeAllowedProducts] = useState<Product[]>(props.products);
    const { data: allTransactions } = useTransactions({
        spot: props.spotId,
        status: TransactionStatus.READY_FOR_PICKUP
    });
    const [filteredTransactions, changeFilteredTransactions] = useState<Transaction[] | undefined>(undefined);
    const lastTransactionsPickedUp = window.localStorage.getItem('lastTransactionsPickedUp')
        ? (JSON.parse(window.localStorage.getItem('lastTransactionsPickedUp')!) as string[])
        : null;

    useEffect(() => {
        if (allTransactions !== undefined && (filteredTransactions === undefined || filteredTransactions.length === 0)) {
            const initMap: Map<Attribute, string | string[]> = new Map();
            props.attributes.forEach((a) => {
                if (a.type === AttributeType.DROP_DOWN) {
                    initMap.set(a, '');
                } else {
                    initMap.set(a, []);
                }
            });
            changeAttrValueMap(initMap);
            updateAvailableProducts(initMap);
        }
    }, [allTransactions]);

    useEffect(() => {
        if (filteredTransactions) checkFormValid(attrValueMap, allowedProducts, filteredTransactions);
    }, [attrValueMap, allowedProducts, filteredTransactions]);

    const onSelect = (val: string | string[], attribute: Attribute) => {
        newActivity();
        const updatedMap = attrValueMap!;
        if (typeof updatedMap.get(attribute) === typeof val) {
            if (updatedMap.get(attribute) && updatedMap.get(attribute) === val) {
                if (typeof val == 'string') {
                    updatedMap.set(attribute, '');
                } else updatedMap.set(attribute, []);
            } else updatedMap.set(attribute, val);
            changeAttrValueMap(updatedMap);
        } else {
            Logger.warn('oops something wrong here, probably still loading');
        }
        updateAvailableProducts(updatedMap);
    };

    const updateAvailableProducts = (attrValueMap: Map<Attribute, string | string[]>) => {
        if (attrValueMap !== undefined) {
            const newProducts: Product[] = [];

            props.products.forEach((product) => {
                let valid = true;
                if (product.product_attributes && product.product_attributes.length === props.attributes.length) {
                    product.product_attributes.forEach((attr) => {
                        const attribute = props.attributes.find((a) => a.id === attr.attribute);
                        let value = attrValueMap?.get(attribute!);
                        if (typeof value === 'string') {
                            value = value as string;
                            if (!value || attr.value !== value) {
                                if (!(value === '' && valid)) valid = false;
                            }
                        } else {
                            value = value as string[];
                            if (!value || !value.includes(attr.value)) valid = false;
                        }
                    });
                } else valid = false;
                if (valid) newProducts.push(product);
            });
            changeAllowedProducts(newProducts);
        }
    };

    useEffect(() => {
        let transactions: Transaction[] = [];
        if (allTransactions) {
            for (const product of allowedProducts) {
                transactions.push(...filterTransactionsByProduct(allTransactions, product));
            }
            transactions = transactions.filter((transaction) => transaction.status === TransactionStatus.READY_FOR_PICKUP);
        }
        changeFilteredTransactions(transactions);
    }, [allowedProducts]);

    const checkFormValid = (updatedMap: Map<Attribute, string | string[]> | undefined, newProducts: Product[], transactions: Transaction[]) => {
        //check only one productinstance remains (or only instances that have the exact same config)
        if (!updatedMap) {
            changeInvalidFormMessage(<p>Loading</p>);
            changeFormValid(false);
            return;
        }
        let valid = true;
        updatedMap.forEach((value) => {
            if (typeof value === 'string') {
                if (value === '') {
                    valid = false;
                }
            } else if (value.length === 0) {
                valid = false;
            }
        });
        if (newProducts.length > 1) {
            changeInvalidFormMessage(<p>There are multiple products with this configuration please select more filters.</p>);
            changeFormValid(false);
            return;
        }
        if (newProducts.length === 0) {
            changeInvalidFormMessage(<p>There are no existing products with your current filters please try a different configuration.</p>);
            changeFormValid(false);
            return;
        }
        if (transactions.length === 0) {
            changeInvalidFormMessage(<p>This product is currently not available, please come back later.</p>);
            changeFormValid(false);
            return;
        }
        if (!valid) {
            changeInvalidFormMessage(<p>Please select at least one of each parameter to go further.</p>);
            changeFormValid(valid);
            return;
        }
        changeFormValid(true);
    };

    const submit = () => {
        newActivity();
        if (lastTransactionsPickedUp !== null) {
            props.submit(filteredTransactions!.filter((transaction) => !lastTransactionsPickedUp.includes(transaction.id)));
        } else {
            props.submit(filteredTransactions!);
        }
    };

    let productContent = <></>;
    if (allowedProducts.length >= 1) {
        const prod = allowedProducts[0];
        productContent = (
            <>
                <p>{prod?.name}</p>
                <img
                    className='product_image'
                    alt='product_image'
                    src={prod?.image ? prod.image : ''}
                />
            </>
        );
    } else {
        const prod = props.products[0];
        productContent = (
            <>
                <p>{prod?.name}</p>
                <img
                    className='product_image'
                    alt='product_image'
                    src={prod?.image ? prod.image : ''}
                />
            </>
        );
    }

    const isValueSelectable = (value: string, attribute: Attribute): boolean => {
        if (allTransactions === undefined || allTransactions.length === 0) return false;
        const presentProducts = props.products.filter((product) => {
            const foundTransactions = filterTransactionsByProduct(allTransactions, product);
            const atLeastOneValidTransaction = foundTransactions.find((tr) => TransactionStatus.inside_distrispot_states().includes(tr.status));
            if (atLeastOneValidTransaction !== undefined) return true;
            return false;
        });

        const available = presentProducts.find((product) => {
            return product.product_attributes.find((attr) => attr.attribute === attribute.id && attr.value === value);
        });

        return !!available;
    };

    const isValueVisible = (value: string, attribute: Attribute): boolean => {
        for (const product of allowedProducts) {
            for (const product_attribute of product.product_attributes) {
                if (attribute.id === product_attribute.attribute && product_attribute.value === value) {
                    return true;
                }
            }
        }
        return false;
    };

    if (allTransactions === undefined || attrValueMap === undefined || allowedProducts === undefined) {
        return (
            <LoadingView
                onBack={props.onBack}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    }

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <div className='shop-attr-filter'>
                {productContent}

                <div>
                    {props.attributes ? (
                        props.attributes.map((a) => {
                            if (a.type === AttributeType.DROP_DOWN) {
                                return (
                                    <ProductAttributeSelect
                                        key={a.id}
                                        isValueVisible={isValueVisible}
                                        isSelectable={isValueSelectable}
                                        onSelect={(val) => {
                                            onSelect(val, a);
                                        }}
                                        attribute={a}
                                    />
                                );
                            }
                            return (
                                <ProductAttributeMultiSelect
                                    key={a.id}
                                    isValueVisible={isValueVisible}
                                    isSelectable={isValueSelectable}
                                    onSelect={(val) => {
                                        onSelect(val, a);
                                    }}
                                    attribute={a}
                                />
                            );
                        })
                    ) : (
                        <></>
                    )}
                </div>

                {!formValid ? invalidFormMessage : <></>}
                <button
                    disabled={!formValid}
                    onClick={submit}
                    className='success-button'>
                    Submit
                </button>
            </div>
        </BaseView>
    );
};

export default ShopProductAttributeFilter;
