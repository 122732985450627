import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductInstanceForTransaction } from '../../api/productInstances';
import { useProduct } from '../../api/products';
import CallToAction from '../../components/domain/CallToAction';
import HighlightCardElement from '../../components/elements/HighlightCardElement';
import { formatDatetime, getFullName } from '../../utils';
import { ProductInstanceOwnership } from '../../workflow/nike/utils';

interface ReviewReturnedProductHighlightCardProps {
    productInstanceOwned: ProductInstanceOwnership;
}

export function ReviewReturnedProductHighlightCard(props: ReviewReturnedProductHighlightCardProps) {
    const productResult = useProduct(props.productInstanceOwned.productInstance.product);

    const fetchDone = productResult.isSuccess || productResult.isError;

    if (!fetchDone) {
        return (
            <div
                className='spinner-border text-primary align-self-center'
                role='status'></div>
        );
    } else {
        return (
            <HighlightCardElement
                informationElements={[
                    {
                        header: (
                            <FormattedMessage
                                id='views.nike.ReviewReturnedProductHighlight.productHighlightTableProductHeader'
                                description='In a product for review highlight card, this is the table header for the product name, in the Nike workflow.'
                                defaultMessage='Product'
                            />
                        ),
                        value: productResult.data!.name
                    },
                    {
                        header: (
                            <FormattedMessage
                                id='views.nike.ReviewReturnedProductHighlight.productHighlightTableReturnDateHeader'
                                description='In a product for review highlight card, this is the table header for the return date, in the Nike workflow.'
                                defaultMessage='Return date'
                            />
                        ),
                        value: formatDatetime(props.productInstanceOwned.productInstance.transaction.created_date)
                    },
                    {
                        header: (
                            <FormattedMessage
                                id='views.nike.ReviewReturnedProductHighlight.productHighlightTableOwnedBy'
                                description='In a product for review highlight card, this is the table header for the owned by contact, in the Nike workflow.'
                                defaultMessage='Owned by'
                            />
                        ),
                        value: getFullName(props.productInstanceOwned.productInstance.transaction.sender)
                    }
                ]}
                callToActions={[]}
                // callToActions={[{
                //   message: props.selectCallToAction
                //     ? props.selectCallToAction.message
                //     : (
                //       <FormattedMessage
                //         id="views.nike.OwnedProductHighlight.defaultCallToAction"
                //         description="On a owned product highlight card, this is the default call to action button if not other is specified."
                //         defaultMessage="Select"
                //       />
                //     ),
                //   onClick: props.onSelect,
                // }]}
            />
        );
    }
}
