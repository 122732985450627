import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../api/contacts';
import useContactAuthentication from '../../hooks/lookup/authentication/useContactAuthentication';
import useScannerValue from '../../hooks/useScannerValue';
import badgeNfc from '../../static/nike/badge-nfc-alt.png';
import { normalizeNfcValue } from '../../utils';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ScanProcessingModal from '../../views/nike/ScanProcessingModal';
import ViewTitle from '../../views/nike/ViewTitle';
import { isNikeManager } from './permissions';

interface CoachApprovalRequiredWorkflowProps {
    onApprovalGranted: () => void;
    onCancel: () => void;
    authenticatedContact?: Contact | null;
    title?: React.ReactNode;
    message?: React.ReactNode;
    onInactivity?: () => void;
    onHome?: () => void;
    onBack?: () => void;
}

export default function CoachApprovalRequiredWorkflow(props: CoachApprovalRequiredWorkflowProps) {
    const [currentLookup, changeCurrentLookup] = useState<string | null>(null);

    const {
        isIdle: contactAuthenticationIsIdle,
        isInProgress: contactAuthenticationIsInProgress,
        isSuccess: contactAuthenticationIsSuccess,
        isError: contactAuthenticationIsError,
        result: contactResult
    } = useContactAuthentication({ lookupValue: currentLookup });

    useScannerValue((value) => {
        changeCurrentLookup(normalizeNfcValue(value));
    });

    useEffect(() => {
        if (contactResult) {
            if (isNikeManager(contactResult)) {
                props.onApprovalGranted();
            }
        }
    }, [contactResult]);

    const onReset = () => {
        changeCurrentLookup(null);
    };

    const homeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                {props.title ? (
                    props.title
                ) : (
                    <FormattedMessage
                        id='views.nike.CoachApprovalRequiredWorkflow.defaultTitle'
                        description='The default title on the coach approval screen (Nike).'
                        defaultMessage='Coach approval required'
                    />
                )}
            </ViewTitle>
            <p>
                {props.message ? (
                    props.message
                ) : (
                    <FormattedMessage
                        id='views.nike.CoachApprovalRequiredWorkflow.defaultMessage'
                        description='The default message on the coach approval screen.'
                        defaultMessage='Your coach must approve this action. Let them scan their badge now.'
                    />
                )}
            </p>
            <img
                src={badgeNfc}
                className='dashboard-scan-image mb-3 filter-primary'
                alt='scan image'
            />
            <p className='text-center'>
                <button
                    className='primary-button'
                    onClick={props.onCancel}>
                    {
                        <FormattedMessage
                            id='views.nike.CoachApprovalRequiredWorkflow.cancelButton'
                            description='The cancel button on the coach approval screen.'
                            defaultMessage='Cancel'
                        />
                    }
                </button>
            </p>
            <ScanProcessingModal
                idle={contactAuthenticationIsIdle}
                error={contactAuthenticationIsError}
                success={!!contactResult}
                done={contactAuthenticationIsSuccess}
                reset={onReset}
            />
        </BaseView>
    );
}
