import { useContext, useEffect } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import useKeepAlive from '../hooks/useKeepAlive';

const StandByView = () => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    useKeepAlive();

    const displayText = terminal?.settings_informational_message ? terminal?.settings_informational_message : undefined;
    const backOnTime = terminal?.settings_switch_terminal_on_at ? terminal?.settings_switch_terminal_on_at : undefined;

    function refreshClock() {
        if (backOnTime) {
            const now = new Date();
            const momentAgo = new Date();
            momentAgo.setSeconds(now.getSeconds() - 10);
            const start = backOnTime.split(':');
            const startTime = new Date();
            startTime.setHours(+start[0]);
            startTime.setMinutes(+start[1]);
            if (startTime > momentAgo && startTime <= now) {
                window.location.href = '/';
            }
        }
    }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    function createMarkup() {
        if (displayText) return { __html: displayText };
    }

    return (
        <div className='standby'>
            <div className='flex-grow-1 text-center'>
                {displayText ? <h3 dangerouslySetInnerHTML={createMarkup()} /> : 'terminal is on standby'}
                <h1>{getTime()}</h1>

                <h4>{backOnTime && !displayText ? 'It will be available again at ' + backOnTime + '.' : ''}</h4>
            </div>
        </div>
    );
};

function getTime(): string {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
}

export default StandByView;
