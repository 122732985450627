import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../api/contacts';
import useContactAuthentication from '../../hooks/lookup/authentication/useContactAuthentication';
import useScannerValue from '../../hooks/useScannerValue';
import badgeNfc from '../../static/nike/badge-nfc-alt.png';
import { normalizeNfcValue } from '../../utils';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ScanProcessingModal from './ScanProcessingModal';
import ViewTitle from './ViewTitle';

interface SelectContactWorkflowProps {
    onSelectContact: (contact: Contact) => void;
    loggedInContact?: Contact | null;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    title?: React.ReactNode;
    message?: React.ReactNode;
    myselfButton?: React.ReactNode;
}

function SelectContactView(props: SelectContactWorkflowProps) {
    const [currentLookup, changeCurrentLookup] = useState<string | null>(null);

    const {
        isIdle: contactAuthenticationIsIdle,
        isInProgress: contactAuthenticationIsInProgress,
        isSuccess: contactAuthenticationIsSuccess,
        isError: contactAuthenticationIsError,
        result: contactResult
    } = useContactAuthentication({ lookupValue: currentLookup });

    useScannerValue((value) => {
        changeCurrentLookup(normalizeNfcValue(value));
    });

    useEffect(() => {
        if (contactResult) {
            props.onSelectContact(contactResult);
            onReset();
        }
    }, [contactResult]);

    const onReset = () => {
        changeCurrentLookup(null);
    };

    const homeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );

    const onForMyself = () => {
        if (props.onSelectContact && props.loggedInContact) {
            props.onSelectContact(props.loggedInContact);
        }
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                {props.title ? (
                    props.title
                ) : (
                    <FormattedMessage
                        id='views.nike.SelectContactView.defaultTitle'
                        description='The default title on the select contact screen. This is for example used to allow managers to select an employee to do things for (ex: return shoes for employee).'
                        defaultMessage='Select an athlete'
                    />
                )}
            </ViewTitle>
            <p>
                {props.message ? (
                    props.message
                ) : (
                    <FormattedMessage
                        id='views.nike.SelectContactView.defaultMessage'
                        description='The default message on the select contact screen. This is for example used to allow managers to select an employee to do things for (ex: return shoes for employee).'
                        defaultMessage='Scan the badge of the Athlete to perform the action as.'
                    />
                )}
            </p>
            <img
                src={badgeNfc}
                className='dashboard-scan-image mb-3 filter-primary'
                alt='scan image'
            />
            <p className='text-center'>
                <button
                    className='primary-button'
                    onClick={onForMyself}>
                    {props.myselfButton ? (
                        props.myselfButton
                    ) : (
                        <FormattedMessage
                            id='views.nike.SelectContactView.defaultMyselfButton'
                            description='The default message on button to select myself on the select contact screen. This is for example used to allow managers to select an employee to do things for (ex: return shoes for employee).'
                            defaultMessage='Do for myself'
                        />
                    )}
                </button>
            </p>
            <ScanProcessingModal
                idle={contactAuthenticationIsIdle}
                error={contactAuthenticationIsError}
                success={!!contactResult}
                done={contactAuthenticationIsSuccess}
                reset={onReset}
            />
        </BaseView>
    );
}

export default SelectContactView;
