import { useContext, useState } from 'react';

import FEATURE_TOGGLES from '../FeatureToggles';
import { SpotLayoutItem } from '../api/spotLayoutItems';
import { Logger } from '../logs/Logger';
import { SlotDoorState } from '../redux/SpotLayoutLogsSlice';
import { store } from '../redux/store';
import {
    TerminalHardwareObject,
    checkHardwareDriverOptionsBySpotLayoutItem,
    getHardwareDriverConfig
} from '../services/platformHardwareDriver/DriverSlotObjects';
import { isZhilaiDriverOptions } from '../services/platformHardwareDriver/utils/ZhilaiHardwareOptions';
import { HardwareConnectionContext } from '../terminal/useHardwareConnection';

export interface openSlotProps {
    spotLayoutItem: SpotLayoutItem;
}

const useOpenSlot = () => {
    const connection = useContext(HardwareConnectionContext)!;

    const [objectsToWatch, changeObjectsToWatch] = useState<TerminalHardwareObject[]>([]);

    const openSlot = (props: openSlotProps): Promise<boolean> => {
        Logger.log('Start openslot command.', { slot: props.spotLayoutItem.slot });
        return new Promise<boolean>((resolve, reject) => {
            if (checkHardwareDriverOptionsBySpotLayoutItem(props.spotLayoutItem)) {
                const slotObjects = getHardwareDriverConfig([props.spotLayoutItem]);

                try {
                    connection.openSlot(slotObjects);
                } catch (error) {
                    reject(error);
                }

                const relevantObjects = slotObjects.filter((options) => isZhilaiDriverOptions(options));
                if (relevantObjects.length === 0 || FEATURE_TOGGLES.SLOT_LOGGING === false || connection.getTerminalSoftwareVersion() < 3) {
                    resolve(true);
                } else {
                    changeObjectsToWatch(relevantObjects);
                    const unsubscribe = store.subscribe(() => {
                        const relevantSlotsResult: boolean[] = [];
                        store.getState().spotLayoutLogs.openedSpotLayoutItems.forEach((id) => {
                            objectsToWatch.find((options) => {
                                if (isZhilaiDriverOptions(options)) {
                                    const existingOption = options.slots.find((s) => s.spotLayoutItemId === id);
                                    if (existingOption !== undefined) {
                                        return relevantSlotsResult.push(existingOption.open === SlotDoorState.OPENED);
                                    }
                                }
                            });
                        });

                        if (relevantSlotsResult.find((open) => open === false) === undefined) {
                            changeObjectsToWatch([]);
                            resolve(true);
                            unsubscribe();
                        }
                    });
                }
            }
        });
    };

    return [openSlot] as const;
};

export default useOpenSlot;
