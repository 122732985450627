export type ApiQueryParams<T extends string | number> = { [key in T]?: string | string[] | undefined | null };

export function buildApiEndpoint<T extends string>(endpoint: string, queryParams?: ApiQueryParams<T> | null): string {
    if (!queryParams) {
        return endpoint;
    }

    // This bizarre construct is necessary because Tyepscript 4.2.3 does not seem to understand that queryParams has
    // string values but instead uses unknown. This looks like a bug in Typescript. If you are using a newer version of
    // Typescript, try removing this parameter and see if it validates.
    const queryParams2: { [key in string]?: string | string[] | undefined | null } = queryParams;
    const searchParams = Object.entries(queryParams2).reduce((result, queryParam) => {
        if (queryParam[1]) {
            if (typeof queryParam[1] === 'string') {
                result.append(queryParam[0], queryParam[1]);
            } else {
                for (const queryParamValue of queryParam[1]) {
                    result.append(queryParam[0], queryParamValue);
                }
            }
        }
        return result;
    }, new URLSearchParams());
    const queryString = searchParams.toString();
    if (queryString !== '') {
        return `${endpoint}?${queryString}`;
    }
    return endpoint;
}

export function queryParamsToCacheKeys<T extends string>(enumVariable: { [key in string]: T }, queryParams?: ApiQueryParams<T> | null): ApiQueryParams<T> {
    const result = Object.fromEntries(Object.values(enumVariable).map((value) => [value, undefined]));
    return {
        ...result,
        ...queryParams
    };
}

export function queryEnabled<T extends string>(queryParams?: ApiQueryParams<T> | null): boolean {
    if (!queryParams) {
        return true;
    }

    return Object.values(queryParams).every((queryParamValue) => {
        return queryParamValue !== undefined;
    });
}
