import { Terminal, isTerminal } from '../../api/terminals';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest } from '../useManagedWebsocket';

export class WebsocketConfigCommand extends WebsocketCommand {
    data: {
        terminal: Terminal | null;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        if (request.terminal !== null || (request.terminal !== null && !isTerminal(request.terminal))) throw new Error('Incorect information passed.');
        this.data = {
            terminal: request.terminal
        };
    }

    public async execute(changeTerminal: (value: Terminal | null) => void): Promise<WebsocketCommandResponse> {
        changeTerminal(this.data.terminal);
        return { success: true };
    }
}
