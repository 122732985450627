import { useEffect } from 'react';

import { Contact } from '../api/contacts';
import { GlobalEvents } from './GlobalEvents';

declare global {
    interface WindowEventMap {
        login: CustomEvent<Contact>;
    }
}

export class LoginEvent {
    contact: Contact;

    constructor(contact: Contact) {
        this.contact = contact;
    }

    dispatch() {
        window.dispatchEvent(new CustomEvent(GlobalEvents.LOGIN, { detail: this.contact }));
    }
}

interface Props {
    callback: (event: { contact: Contact }) => void;
}

export function useLoginEventListener(props: Props) {
    useEffect(() => {
        window.addEventListener(GlobalEvents.LOGIN, (event: CustomEvent<Contact>) => {
            props.callback({ contact: event.detail });
        });
    }, []);
}
