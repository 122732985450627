import { useEffect } from 'react';

import { ServiceWorkerInit, ServiceWorkerInitResult } from '../ServiceWorkerInit';
import { ServiceWorkerInitState } from '../TerminalInit';

interface Props {
    serviceWorker: ServiceWorkerInit;
    serviceWorkerInitState: ServiceWorkerInitState;

    onResponse: (result: ServiceWorkerInitResult) => void;
    onError: () => void;
}

export default function useInitializeServiceWorker(props: Props) {
    useEffect(() => {
        if (props.serviceWorkerInitState === ServiceWorkerInitState.INIT) {
            props.serviceWorker.init().then(props.onResponse).catch(props.onError);
        }
    }, [props.serviceWorker, props.serviceWorkerInitState]);
}
