import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, useQuery, useQueryClient } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { Terminal } from './terminals';
import { ApiError, FetchOptions, fetchApi } from './utils';

export interface SpotLayout {
    id: string;
    url: string;
    spot_url: string;
    spot: number | string;
    height: number;
    width: number;
    depth: number;
}

const defaultConfig = {
    staleTime: Infinity
};

const fetchSpotLayoutsApi = (options?: FetchOptions): (() => Promise<SpotLayout[]>) => {
    return async (): Promise<SpotLayout[]> => {
        const response = await fetchApi('/spot-layouts/', undefined, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error fetching spot layout');
            }
            throw new ApiError('Error fetching spot layout', json);
        }
        return await response.json();
    };
};

const fetchSpotLayoutApi = (spotLayoutUrl?: string, spotLayoutId?: number | string, options?: FetchOptions): (() => Promise<SpotLayout>) => {
    return async (): Promise<SpotLayout> => {
        if (!spotLayoutUrl) {
            spotLayoutUrl = `/spot-layouts/${spotLayoutId!}/`;
        }
        const response = await fetchApi(spotLayoutUrl, undefined, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error fetching spot layout');
            }
            throw new ApiError('Error fetching spot layout', json);
        }
        return await response.json();
    };
};

export async function prefetchSpotLayout(queryClient: QueryClient, terminal: Terminal, options?: FetchQueryOptions<SpotLayout>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...options
    };

    const spotLayout = await queryClient.fetchQuery(
        ['spot-layout', undefined, { url: terminal.spot_layout_url }],
        fetchSpotLayoutApi(terminal.spot_layout_url, undefined, fetchOptions),
        config
    );
    // Set the spot layout detail caches as well
    queryClient.setQueryData(['spot-layout', spotLayout.id, undefined], spotLayout);
    if (spotLayout.url !== terminal.spot_layout_url) {
        queryClient.setQueryData(['spot-layout', undefined, { url: terminal.spot_layout_url }], spotLayout);
    }

    return spotLayout;
}

export function useSpotLayout(spotLayoutUrl?: string, spotLayoutId?: number | string, options?: UseQueryOptions<SpotLayout>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        enabled: !!spotLayoutId || !!spotLayoutUrl,
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<SpotLayout>(['spot-layout', spotLayoutId, { url: spotLayoutUrl }], fetchSpotLayoutApi(spotLayoutUrl, spotLayoutId, fetchOptions), config);
}

export function useSpotLayouts(options?: UseQueryOptions<SpotLayout[]>, fetchOptions?: FetchOptions) {
    const queryClient = useQueryClient();
    const config = {
        ...defaultConfig,
        onSuccess: (spotLayouts: SpotLayout[]) => {
            for (const layout of spotLayouts) {
                queryClient.setQueryData(['spot-layout', layout.id, { url: layout.url }], layout);
            }
        },
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<SpotLayout[]>('spot-layouts', fetchSpotLayoutsApi(fetchOptions), config);
}
