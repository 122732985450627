import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FEATURE_TOGGLES from '../../FeatureToggles';
import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { getUserInfo, mapSSOResponseToAccessToken, useMutateAuthenticateItsme } from '../../api/SSO';
import { AuthenticationMethodValue } from '../../api/authenticationMethods';
import { Contact, useContact } from '../../api/contacts';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import itsme from '../../assets/itmse_logo.svg';
import { AllowedActionViewProps } from '../../components/domain/AllowedAction';
import ContactForm, { ContactFormFields } from '../../forms/ContactForm';
import { Logger } from '../../logs/Logger';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';

interface Props extends AllowedActionViewProps {
    authenticateContact?: (contact: Contact | undefined) => void;
}

function ItsmeLogin(props: Props) {
    const authViaItsmeMutation = useMutateAuthenticateItsme();
    const [mobile, changeMobile] = useState<string | undefined>(undefined);
    const [contactId, changeContactId] = useState<number | undefined>();
    const [content, changeContent] = useState<React.ReactNode | undefined>(undefined);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: authenticatingContact } = useContact(contactId);
    const [cancelled, changeCancelled] = useState<boolean>(false);
    const [error, changeError] = useState<boolean>(false);
    const homeNav = (
        <BackAndHomeNavigationButtons
            onHome={() => {
                changeContent(undefined);
                changeCancelled(true);
                props.onHome && props.onHome();
            }}
        />
    );

    useEffect(() => {
        if (mobile) authViaItsmeMutation.mutate({ accountId: spot!.account.toString(), mobile: mobile });
    }, [mobile]);

    useEffect(() => {
        authViaItsmeMutation.isLoading && !cancelled && changeContent(authenticatingMessage);
        if (authViaItsmeMutation.isSuccess && authViaItsmeMutation.data) {
            !cancelled && changeContent(retreivingUserInfoMessage);
            getUserInfo({ includeAccessToken: true, accessToken: mapSSOResponseToAccessToken(authViaItsmeMutation.data) })().then((response) => {
                const correctMembership = response?.account_members.find((am) => am.account.id === spot?.account);
                const foundContactID = correctMembership?.account.contacts[0]?.pk;
                if (foundContactID == undefined) {
                    changeContent(authenticationFailedErrorMessage);
                    changeError(true);
                } else {
                    Logger.log('Contact id found: ' + foundContactID, { contact: foundContactID });
                    changeContactId(foundContactID);
                }
            });
        } else if (authViaItsmeMutation.isError) {
            changeContent(authenticationFailedErrorMessage);
            changeError(true);
        }
    }, [authViaItsmeMutation]);

    useEffect(() => {
        if (authenticatingContact !== undefined && props.authenticateContact && !cancelled) {
            Logger.log('Contact authenticated', { contact: authenticatingContact.id });
            props.authenticateContact(authenticatingContact);
            changeContent(undefined);
            changeCancelled(false);
        }
    }, [authenticatingContact]);

    const onHome = () => {
        changeContent(undefined);
        changeCancelled(true);
        props.onHome && props.onHome();
    };

    if (error === true) {
        return (
            <ErrorView
                navbarItems={homeNav}
                onInactivity={() => {
                    onHome();
                    props.onInactivity && props.onInactivity();
                }}
                title={content}
            />
        );
    } else if (mobile == undefined && spot !== undefined) {
        return (
            <ContactForm
                title={
                    <img
                        src={itsme}
                        width={264}
                    />
                }
                accountId={spot.account}
                onSubmit={(c) => changeMobile(c.mobile)}
                fields={[ContactFormFields.PHONENUMBER]}
                requiredFields={[ContactFormFields.PHONENUMBER]}
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
                belgiumPhoneOnly={true}
            />
        );
    }

    return (
        <LoadingView
            onHome={onHome}
            title={content}
        />
    );
}

export default function ItmseLoginAction(props: Props) {
    const intl = useIntl();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const itsmeConfig = terminal?.computed_authentication_methods?.find((m) => m.value === AuthenticationMethodValue.ITSME);

    if (
        props.contact !== undefined ||
        props.authenticateContact === undefined ||
        !FEATURE_TOGGLES.ITSME ||
        itsmeConfig === undefined ||
        !(itsmeConfig.default_dropoff_action || itsmeConfig.default_pickup_action || itsmeConfig.default_remove_parcel_action)
    )
        return undefined;

    return {
        actionText: intl.formatMessage({
            id: 'workflow.SocialLogin.ITSME',
            description: 'This is the log in with itsme button',
            defaultMessage: 'Log in with itsme'
        }),
        icon: (
            <>
                <img
                    src={itsme}
                    width={264}
                />
            </>
        ),
        view: <ItsmeLogin {...props} />
    };
}

const authenticatingMessage = (
    <FormattedMessage
        id='Itsme.Authenticating.checkItsmeApp'
        description='The message being displayed when the backend is authenticating the user in the backend via itsme.'
        defaultMessage='Please check your itsme application and accept the authentication request.'
    />
);
const retreivingUserInfoMessage = (
    <FormattedMessage
        id='Itsme.Modal.retreivingUserInfo'
        description='The message being displayed when fetching the userinfo for itsme login.'
        defaultMessage='Authentication successful. Attempting to retrieve your information.'
    />
);
const authenticationFailedErrorMessage = (
    <FormattedMessage
        id='Itsme.Modal.authenticationFailedErrorMessage'
        description='The message being displayed when something went wrong trying to aithenticate the user via itsme.'
        defaultMessage='Apologies, an error occurred while attempting to authenticate via itsme. Please try again later or reach out to the helpdesk or an administrator for assistance.'
    />
);
