import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, QueryObserverResult, useQueries, useQuery, useQueryClient } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { Transaction } from '../common/transactions';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { ApiQueryParams, queryParamsToCacheKeys } from './baseQueryParams';
import { Contact, isContact } from './contacts';
import { FetchOptions } from './utils';

export enum ContactGroupType {
    GROUP = 'group',
    COMPANY = 'company'
}

export interface ContactGroup {
    id: number;
    url: string;
    name: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    account: number | null;
    parent: string | null;
    children: string | null;
    contacts: string | null;
    group_type: ContactGroupType;
    group_logo: string | null;
    group_color: string;
}

export interface ContactGroupForContact extends ContactGroup {
    contact: Contact;
}

export const isContactGroup = (contact: any): contact is ContactGroup => {
    return (contact as ContactGroup).url !== undefined && (contact as ContactGroup).url.includes('/contact-groups/');
};

enum ContactGroupQueryParams {
    PARENT = 'parent',
    ACCOUNT = 'account',
    CONTACT = 'contact',
    GROUP_TYPE = 'group_type'
}

const contactGroupsViewSet: ApiViewSet = {
    baseName: 'contact-groups'
};

const defaultConfig = {
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity
};

interface ContactGroupsOptions {
    account?: string | number | null;
    parent?: string | null;
    contact?: Contact | string | number | null;
    groupType?: ContactGroupType | null;
}

function fetchContactGroupsApi(options?: ContactGroupsOptions, fetchOptions?: FetchOptions): () => Promise<ContactGroup[]> {
    const accountId = options && options.account ? options.account.toString() : undefined;
    const parentId = options && options.parent ? options.parent.toString() : undefined;
    const contactId = options && options.contact ? (isContact(options.contact) ? options.contact.id : options.contact).toString() : undefined;
    return apiList<ContactGroup, ContactGroupQueryParams>(
        contactGroupsViewSet,
        {
            account: accountId,
            parent: parentId,
            contact: contactId,
            group_type: options?.groupType
        },
        fetchOptions
    );
}

function fetchContactGroupApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<ContactGroup> {
    return apiDetail<ContactGroup>(contactGroupsViewSet, options, fetchOptions);
}

export async function prefetchContactGroups(
    queryClient: QueryClient,
    accountId: string | number,
    options?: FetchQueryOptions<ContactGroup[]>,
    fetchOptions?: FetchOptions
) {
    const config = {
        ...defaultConfig,
        ...options
    };

    const contactGroups = await queryClient.fetchQuery(
        [
            'contact-groups',
            {
                account: accountId,
                parent: undefined
            }
        ],
        fetchContactGroupsApi({}, fetchOptions),
        config
    );
    // Set the contact groups detail caches as well
    for (const contactGroup of contactGroups) {
        queryClient.setQueryData(['contact-group', contactGroup.id], contactGroup);
        queryClient.setQueryData(['contact-group', contactGroup.url], contactGroup);
    }
}

export function useContactGroups(
    contactGroupsUrl?: string | null,
    accountId?: string | number,
    options?: UseQueryOptions<ContactGroup[]>,
    fetchOptions?: FetchOptions
) {
    const queryClient = useQueryClient();

    const config = {
        ...defaultConfig,
        onSuccess: (contactGroups: ContactGroup[]) => {
            // Set the contact detail cache as well
            for (const contactGroup of contactGroups) {
                queryClient.setQueryData(['contact-group', contactGroup.id], contactGroup);
                queryClient.setQueryData(['contact-group', contactGroup.url], contactGroup);
            }
        },
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    const apiOptions = {
        account: accountId,
        parent: undefined
    } as ContactGroupsOptions;
    if (contactGroupsUrl) {
        try {
            const url = new URL(contactGroupsUrl);
            const urlSearchParams = new URLSearchParams(url.search);
            if (urlSearchParams.has('parent')) {
                apiOptions.parent = urlSearchParams.get('parent');
            }
            if (urlSearchParams.has('account')) {
                apiOptions.account = urlSearchParams.get('account');
            }
        } catch (e) {}
    }

    return useQuery<ContactGroup[]>(
        [
            'contact-groups',
            {
                account: apiOptions.account,
                parent: apiOptions.parent
            }
        ],
        fetchContactGroupsApi(apiOptions, fetchOptions),
        config
    );
}

export function useContactGroup(
    contactGroupUrl?: string | null,
    contactGroupId?: number | string | null,
    options?: UseQueryOptions<ContactGroup>,
    fetchOptions?: FetchOptions
) {
    const config = {
        ...defaultConfig,
        enabled: !!contactGroupId || !!contactGroupUrl,
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    let detailOptions: DetailOptions;
    if (contactGroupUrl) {
        detailOptions = {
            url: contactGroupUrl
        };
    } else if (contactGroupId) {
        detailOptions = {
            id: contactGroupId
        };
    } else {
        detailOptions = {
            id: '' // This case only occurs when no contactGroupId or contactGroupUrl is provided, and the query is never actually executed
        };
    }

    return useQuery<ContactGroup>(['contact-group', contactGroupUrl || contactGroupId], fetchContactGroupApi(detailOptions, fetchOptions), config);
}

function transactionsSenderContactGroupUseQueryOptions(
    transaction: Transaction,
    options?: UseQueryOptions<ContactGroup>,
    fetchOptions?: FetchOptions
): UseQueryOptions<ContactGroup> {
    const config = {
        ...defaultConfig,
        ...options,
        enabled: (options ? options.enabled : true) && transaction.sender_group != null
    };

    return {
        queryKey: ['contact-group', transaction.sender_group?.id],
        queryFn: fetchContactGroupApi({ id: transaction.sender_group?.id ? transaction.sender_group?.id : '' }, fetchOptions),
        ...config
    };
}

function transactionsReceiverContactGroupUseQueryOptions(
    transaction: Transaction,
    options?: UseQueryOptions<ContactGroup>,
    fetchOptions?: FetchOptions
): UseQueryOptions<ContactGroup> {
    const config = {
        ...defaultConfig,
        ...options,
        enabled: (options ? options.enabled : true) && transaction.receiver_group != null
    };

    return {
        queryKey: ['contact-group', transaction.receiver_group?.id],
        queryFn: fetchContactGroupApi({ id: transaction.receiver_group?.id ? transaction.receiver_group?.id : '' }, fetchOptions),
        ...config
    };
}

function contactsGroupUseQueryOptions(
    contact?: Contact | null,
    options?: UseQueryOptions<ContactGroup[], unknown, ContactGroupForContact[]>,
    fetchOptions?: FetchOptions
): UseQueryOptions<ContactGroup[], unknown, ContactGroupForContact[]> {
    const contactId = contact ? contact.id.toString() : undefined;
    const config = {
        ...defaultConfig,
        select: (data: ContactGroup[]): ContactGroupForContact[] => {
            return data.map((cg) => {
                return {
                    contact: contact!,
                    ...cg
                };
            });
        },
        ...options,
        enabled: (options ? options.enabled : true) && contactId !== undefined
    };

    const queryParams: ApiQueryParams<ContactGroupQueryParams> = {
        contact: contactId
    };

    return {
        queryKey: ['contact-group', queryParamsToCacheKeys(ContactGroupQueryParams, queryParams)],
        queryFn: fetchContactGroupsApi(
            {
                contact: contactId
            },
            fetchOptions
        ),
        ...config
    };
}

export function useTransactionsSenderContactGroup(
    transactions?: Transaction[],
    options?: UseQueryOptions<ContactGroup>,
    fetchOptions?: FetchOptions
): QueryObserverResult<ContactGroup>[] {
    return useTransactionsContactGroup(transactions, true, options, fetchOptions);
}

export function useTransactionsReceiverContactGroup(
    transactions?: Transaction[],
    options?: UseQueryOptions<ContactGroup>,
    fetchOptions?: FetchOptions
): QueryObserverResult<ContactGroup>[] {
    return useTransactionsContactGroup(transactions, false, options, fetchOptions);
}

function useTransactionsContactGroup(
    transactions?: Transaction[],
    isSender = true,
    options?: UseQueryOptions<ContactGroup>,
    fetchOptions?: FetchOptions
): QueryObserverResult<ContactGroup>[] {
    const config = {
        ...defaultConfig,
        enabled: !!transactions,
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQueries(
        transactions
            ? transactions.map((transaction) => {
                  if (isSender) {
                      return transactionsSenderContactGroupUseQueryOptions(transaction, config, fetchOptions) as UseQueryOptions;
                  } else {
                      return transactionsReceiverContactGroupUseQueryOptions(transaction, config, fetchOptions) as UseQueryOptions;
                  }
              })
            : []
    ) as QueryObserverResult<ContactGroup>[];
}

export function useContactsGroups(
    contacts?: Contact[],
    options?: UseQueryOptions<ContactGroup[], unknown, ContactGroupForContact[]>,
    fetchOptions?: FetchOptions
): QueryObserverResult<ContactGroupForContact[]>[] {
    const config = {
        ...defaultConfig,
        enabled: !!contacts,
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQueries(
        contacts
            ? contacts.map((contact) => {
                  return contactsGroupUseQueryOptions(contact, config, fetchOptions) as UseQueryOptions;
              })
            : []
    ) as QueryObserverResult<ContactGroupForContact[]>[];
}
