import Printer from '../../printing/Printer';
import PrinterException from '../../printing/exceptions/PrinterException';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand } from '../useManagedWebsocket';

const printer = Printer.getInstance();

export class WebsocketResetPrinterCommand extends WebsocketCommand {
    public async execute(): Promise<WebsocketCommandResponse> {
        try {
            await printer.resetPrinter();
        } catch (error) {
            return {
                success: false,
                message: (error as PrinterException).message,
                send: true
            };
        }
        return {
            success: true,
            send: true
        };
    }
}
