import { useEffect, useState } from 'react';

import { Contact } from '../../../api/contacts';
import { useMutateUpdateTransaction } from '../../../api/transactions';
import { Transaction, TransactionStatus, UpdateTransactionStatus } from '../../../common/transactions';
import useActivity from '../../../hooks/useActivity';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ErrorView from '../../../views/common/ErrorView';
import LoadingView from '../../../views/common/LoadingView';
import ViewTitle from '../../../views/nike/ViewTitle';

enum CancelTransactionState {
    INITIAL = 'initial',
    CONFIRMED = 'confirmed',
    CANCELLING = 'cancelling',
    CANCELLED = 'cancelled',
    CANCEL_FAILED = 'cancelFailed'
}

interface CancelTransactionWorkflowProps {
    loggedInContact: Contact;
    transaction: Transaction;
    confirmed?: boolean;
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;
}

const CancelTransactionWorkflow = (props: CancelTransactionWorkflowProps) => {
    const confirmed = props.confirmed === true;

    const [cancelState, changeCancelState] = useState<CancelTransactionState>(confirmed ? CancelTransactionState.CONFIRMED : CancelTransactionState.INITIAL);
    const [, newActivity] = useActivity();

    const cancelMutation = useMutateUpdateTransaction();

    useEffect(() => {
        if (cancelState === CancelTransactionState.CONFIRMED) {
            newActivity();
            changeCancelState(CancelTransactionState.CANCELLING);
            if (props.transaction.status === TransactionStatus.CANCELLED) {
                changeCancelState(CancelTransactionState.CANCELLED);
            } else {
                cancelMutation.mutate(
                    {
                        transaction: props.transaction,
                        updateTransaction: {
                            status: UpdateTransactionStatus.CANCELLED
                        }
                    },
                    {
                        onSuccess: (transaction) => {
                            // TODO: It is currently not possible to know when the backend has "processed" the cancellation and is ready
                            //  to accept the pickup that will occur next. Therefore we mitigate this issue for now by using a 2 second
                            //  delay before actually "picking up" the transaction. THIS WILL SOLVE 99% OF THE ERRORS BUT NOT ALL OF THEM!
                            //  In the backend, the transaction will go to an error state. To the user however, it appears as if
                            //  everything works, which is good enough. Once the terminal can actually know when a cancelled transaction
                            //  is ready to be picked up, this can be reworked into a better solution.
                            setTimeout(() => {
                                newActivity();
                                changeCancelState(CancelTransactionState.CANCELLED);
                            }, 2000);
                        },
                        onError: () => {
                            newActivity();
                            changeCancelState(CancelTransactionState.CANCEL_FAILED);
                        }
                    }
                );
            }
        }
    }, [cancelState]);

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );

    if (cancelState === CancelTransactionState.INITIAL) {
        return (
            <ErrorView
                message='This functionality is not yet implemented'
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}
            />
        );
    } else if (cancelState === CancelTransactionState.CONFIRMED || cancelState === CancelTransactionState.CANCELLING) {
        return (
            <LoadingView
                title='Cancelling transaction'
                onInactivity={props.onInactivity}
                onBack={props.onBack}
                onHome={props.onHome}
            />
        );
    } else if (cancelState === CancelTransactionState.CANCEL_FAILED) {
        return (
            <ErrorView
                title='Could not cancel transaction'
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
            />
        );
    } else if (cancelState === CancelTransactionState.CANCELLED) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}>
                <ViewTitle>Transaction cancelled</ViewTitle>
            </BaseView>
        );
    } else {
        throw new Error('Unexpected state');
    }
};

export default CancelTransactionWorkflow;
