import { useEffect, useState } from 'react';

import { Contact } from '../../../api/contacts';
import { useSlot } from '../../../api/slots';
import { useMutateUpdateTransaction } from '../../../api/transactions';
import { Transaction, TransactionStatus, UpdateTransactionStatus } from '../../../common/transactions';
import useActivity from '../../../hooks/useActivity';
import { Logger } from '../../../logs/Logger';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import ErrorView from '../../../views/common/ErrorView';
import LoadingView from '../../../views/common/LoadingView';
import ViewTitle from '../../../views/nike/ViewTitle';
import Pickup from '../../Pickup';

enum RemoveTransactionState {
    INITIAL = 'initial',
    CONFIRMED = 'confirmed',
    CANCELLING = 'cancelling',
    CANCELLED = 'cancelled',
    CANCEL_FAILED = 'cancelFailed'
}

interface RemoveTransactionWorkflowProps {
    loggedInContact: Contact;
    transaction: Transaction;
    confirmed?: boolean;
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;

    onFinishCallback?: () => void;
}

export default function RemoveTransactionWorkflow(props: RemoveTransactionWorkflowProps) {
    const confirmed = props.confirmed === true;

    const [removeState, changeRemoveState] = useState<RemoveTransactionState>(confirmed ? RemoveTransactionState.CONFIRMED : RemoveTransactionState.INITIAL);
    const [requiresPickup] = useState(TransactionStatus.inside_distrispot_states().includes(props.transaction.status));
    const [, newActivity] = useActivity();

    const slotResult = useSlot(props.transaction.slot_id, { enabled: requiresPickup });
    const cancelMutation = useMutateUpdateTransaction();

    useEffect(() => {
        if (removeState === RemoveTransactionState.CONFIRMED) {
            newActivity();
            changeRemoveState(RemoveTransactionState.CANCELLING);
            Logger.log('Cancelling transaction', { transaction: props.transaction.id });
            if (props.transaction.status === TransactionStatus.CANCELLED) {
                changeRemoveState(RemoveTransactionState.CANCELLED);
            } else {
                cancelMutation.mutate(
                    {
                        transaction: props.transaction,
                        updateTransaction: {
                            status: UpdateTransactionStatus.CANCELLED
                        }
                    },
                    {
                        onSuccess: (transaction) => {
                            // TODO: It is currently not possible to know when the backend has "processed" the cancellation and is ready
                            //  to accept the pickup that will occur next. Therefore we mitigate this issue for now by using a 2 second
                            //  delay before actually "picking up" the transaction. THIS WILL SOLVE 99% OF THE ERRORS BUT NOT ALL OF THEM!
                            //  In the backend, the transaction will go to an error state. To the user however, it appears as if
                            //  everything works, which is good enough. Once the terminal can actually know when a cancelled transaction
                            //  is ready to be picked up, this can be reworked into a better solution.
                            setTimeout(() => {
                                newActivity();
                                changeRemoveState(RemoveTransactionState.CANCELLED);
                            }, 2000);
                        },
                        onError: () => {
                            newActivity();
                            changeRemoveState(RemoveTransactionState.CANCEL_FAILED);
                        }
                    }
                );
            }
        }
    }, [removeState]);

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );

    if (removeState === RemoveTransactionState.INITIAL) {
        return (
            <ErrorView
                message='This functionality is not yet implemented'
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}
            />
        );
    } else if (removeState === RemoveTransactionState.CONFIRMED || removeState === RemoveTransactionState.CANCELLING) {
        return (
            <LoadingView
                title='Cancelling transaction'
                onInactivity={props.onInactivity}
                onBack={props.onBack}
                onHome={props.onHome}
            />
        );
    } else if (removeState === RemoveTransactionState.CANCEL_FAILED) {
        return (
            <ErrorView
                title='Could not cancel transaction'
                onInactivity={props.onInactivity}
                navbarItems={homeNav}
            />
        );
    } else if (removeState === RemoveTransactionState.CANCELLED) {
        if (!requiresPickup) {
            props.onFinishCallback && props.onFinishCallback();
            return (
                <BaseView
                    onInactivity={props.onInactivity}
                    navbarItems={homeNav}>
                    <ViewTitle>Transaction cancelled</ViewTitle>
                </BaseView>
            );
        }
        if (slotResult.isError) {
            return (
                <ErrorView
                    title='An unexpected error occurred opening the Slot'
                    onInactivity={props.onInactivity}
                    navbarItems={backHomeNav}
                />
            );
        } else if (!slotResult.isSuccess || slotResult.data === undefined) {
            return (
                <LoadingView
                    title='Opening Slot. Please wait.'
                    onInactivity={props.onInactivity}
                />
            );
        }
        props.onFinishCallback && props.onFinishCallback();
        return (
            <Pickup
                slot={slotResult.data}
                transaction={props.transaction}
                skipConfirm={true}
                receiver={props.loggedInContact}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    } else {
        throw new Error('Unexpected state');
    }
}
