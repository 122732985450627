import React, { useContext, useMemo, useState } from 'react';
import { Accordion, AccordionContext, Card, Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { Product } from '../../api/products';
import useActivity from '../../hooks/useActivity';
import { ProductConfiguration, ProductParameter } from './ProductConfigurationView';

interface ProductConfigurationModalProps {
    show: boolean;
    productConfiguration: ProductConfiguration;
    product?: Product | null;
    onSelectProduct: (product: Product | null) => void;
    onHide?: () => void;
}

const filterProducts = (products: Product[], parameterValues: Map<ProductParameter, string>) => {
    return products.filter((product) => {
        return Array.from(parameterValues.entries()).every(([productParameter, parameterValue]) => {
            return productParameter.getValue(product) === parameterValue;
        });
    });
};

const parameterValuesForProducts = (products: Product[], productParameter: ProductParameter): string[] => {
    return Array.from(
        products.reduce((result, product) => {
            const value = productParameter.getValue(product);
            if (value) {
                result.add(value);
            }
            return result;
        }, new Set<string>())
    ).sort((a, b) => {
        return a.localeCompare(b);
    });
};

const parameterValuesForProduct = (product: Product, productParameters: ProductParameter[]): Map<ProductParameter, string> => {
    return productParameters.reduce((result, productParameter) => {
        const value = productParameter.getValue(product);
        if (value) {
            result.set(productParameter, value);
        }
        return result;
    }, new Map<ProductParameter, string>());
};

function copyAndDeleteFromMap<T, S>(map: Map<T, S>, itemToDelete: T): Map<T, S> {
    const mapCopy = new Map(map);
    mapCopy.delete(itemToDelete);
    return mapCopy;
}

interface AccordionAwareCaretProps {
    children?: React.ReactNode;
    eventKey?: string | null;
    callback?: void;
}

const AccordionAwareCaret = (props: AccordionAwareCaretProps) => {
    const currentEventKey = useContext(AccordionContext);

    const isCurrentEventKey = currentEventKey === props.eventKey;

    return <>{isCurrentEventKey ? <i className='bi bi-caret-up-fill' /> : <i className='bi bi-caret-down-fill' />}</>;
};

const ProductConfigurationModal = (props: ProductConfigurationModalProps) => {
    const productParameters: Map<ProductParameter, string> = props.product
        ? parameterValuesForProduct(props.product, props.productConfiguration.parameters)
        : new Map<ProductParameter, string>();
    const [productParameterValues, changeProductParameterValues] = useState(productParameters);
    const [accordionActive, changeAccordionActive] = useState<string | null | undefined>('0');

    const intl = useIntl();
    const [, newActivity] = useActivity();

    const filteredProductsData = useMemo(() => {
        return {
            filteredProducts: filterProducts(props.productConfiguration.products, productParameterValues),
            parameterFilteredProducts: props.productConfiguration.parameters.reduce((result, productParameter) => {
                return result.set(
                    productParameter,
                    filterProducts(
                        props.productConfiguration.products,
                        copyAndDeleteFromMap(productParameterValues, productParameter) // Remove the current product parameter
                    )
                );
            }, new Map<ProductParameter, Product[]>())
        };
    }, [props.productConfiguration.products, props.productConfiguration.parameters, productParameterValues]);

    const onSaveOptions = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        newActivity();
        props.onSelectProduct(filteredProductsData.filteredProducts.length > 0 ? filteredProductsData.filteredProducts[0] : null);
        changeProductParameterValues(new Map());
    };

    return (
        <Modal
            centered
            size='lg'
            animation={false}
            show={props.show}
            onHide={props.onHide}>
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    <FormattedMessage
                        id='views.nike.ProductConfigurationModal.title'
                        description='The title of the modal popup to configure the actual options of a product in the Nike workflow'
                        defaultMessage='Configure product'
                    />
                </Modal.Title>
                {productParameterValues.size > 0 ? (
                    <button
                        className='ms-4 light-button btn-sm'
                        onClick={() => {
                            newActivity();
                            changeProductParameterValues(new Map());
                            changeAccordionActive('0');
                        }}>
                        <FormattedMessage
                            id='views.nike.ProductConfigurationModal.configReset'
                            description='A button in the product config modal to reset all values.'
                            defaultMessage='Reset'
                        />
                    </button>
                ) : (
                    <></>
                )}
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSaveOptions}>
                    <Accordion
                        activeKey={accordionActive ? accordionActive : undefined}
                        onSelect={(eventKey) => {
                            newActivity();
                            changeAccordionActive(eventKey as string);
                        }}>
                        {props.productConfiguration.parameters.map((productParameter, productParameterIndex, parametersArray) => {
                            return (
                                <Accordion.Item eventKey={productParameter.name}>
                                    <Accordion.Header>
                                        <div className='d-flex flex-row justify-content-between d-inline-block'>
                                            <h4 className='mb-0'>{productParameter.getDisplayName(intl)}</h4>
                                            <h6 className='mb-0 pt-1'>
                                                <span className='badge bg-light text-dark ms-1'>{productParameterValues.get(productParameter)}</span>
                                            </h6>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {filteredProductsData.parameterFilteredProducts.has(productParameter)
                                            ? parameterValuesForProducts(
                                                  filteredProductsData.parameterFilteredProducts.get(productParameter)!,
                                                  productParameter
                                              ).map((value, parameterIndex) => {
                                                  return (
                                                      <Form.Check
                                                          key={value}
                                                          type='checkbox'
                                                          className='form-control-lg custom-control-lg'
                                                          id={`${productParameter.name}-${parameterIndex}`}
                                                          label={value}
                                                          onChange={(e) => {
                                                              newActivity();
                                                              if (e.target.checked) {
                                                                  if (productParameterIndex === parametersArray.length - 1) {
                                                                      changeAccordionActive(null);
                                                                  } else {
                                                                      changeAccordionActive((productParameterIndex + 1).toString());
                                                                  }
                                                                  changeProductParameterValues((prevProductParameterValues) => {
                                                                      const newProductParameterValues = new Map(prevProductParameterValues);
                                                                      newProductParameterValues.set(productParameter, value);
                                                                      return newProductParameterValues;
                                                                  });
                                                              } else {
                                                                  changeProductParameterValues((prevProductParameterValues) => {
                                                                      const newProductParameterValues = new Map(prevProductParameterValues);
                                                                      newProductParameterValues.delete(productParameter);
                                                                      return newProductParameterValues;
                                                                  });
                                                              }
                                                          }}
                                                          checked={productParameterValues.get(productParameter) === value}
                                                      />
                                                  );
                                              })
                                            : 'Not found'}
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                    <div className='d-flex d-row justify-content-center mt-4'>
                        <button
                            type='submit'
                            className='primary-button btn-lg'
                            disabled={filteredProductsData.filteredProducts.length !== 1}>
                            <FormattedMessage
                                id='views.nike.ProductConfigurationModal.save'
                                description='The save button on the product config modal.'
                                defaultMessage='Save'
                            />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ProductConfigurationModal;
