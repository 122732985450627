import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { AuthenticationMethod } from './authenticationMethods';
import { ApiViewSet, DetailOptions, apiDetail } from './baseApi';
import { ApiError, FetchOptions, fetchApi } from './utils';

export enum TerminalWorkflowType {
    PUDO = 'pudo',
    PUDO_PRIVATE = 'pudo_private',
    IMES_DEXIS_NIKE = 'imes_dexis_nike',
    LENDING = 'lending',
    VENDING = 'vending',
    WAREHOUSE = 'warehouse'
}

export interface Terminal {
    id: number | string;
    url: string;
    name: string;
    workflow: TerminalWorkflowType;
    spot_layout_url: string;
    spot_layout: number | string;
    delivery_contacts_url?: string;
    delivery_contacts?: number | string;
    delivery_contact_other_url?: string;
    delivery_contact_other?: number | string;
    default_receiver_contact_url?: string | null;
    default_receiver_contact?: number | string | null;
    receiver_contact_group_url?: string | null;
    receiver_contact_group?: number | string | null;

    settings_contact_registration_allowed: boolean;
    settings_unknown_contact_registration_allowed: boolean;
    settings_switch_terminal_on_at: string | null;
    settings_switch_terminal_off_at: string | null;
    settings_informational_message: string | null;
    settings_door_open_time_in_seconds: number | null;
    settings_allow_itsme_authentication: boolean | null;

    settings_enable_ws_logger: boolean;

    additional_data: unknown;

    computed_authentication_methods: AuthenticationMethod[];
}

export const isTerminal = (terminal: any): terminal is Terminal => {
    return (terminal as Terminal).url !== undefined && (terminal as Terminal).url.includes('/terminals/');
};

const terminalsViewSet: ApiViewSet = {
    baseName: 'terminals'
};

function fetchTerminalsApi(options?: FetchOptions): () => Promise<Terminal[]> {
    return async (): Promise<Terminal[]> => {
        const response = await fetchApi('/terminals/', undefined, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error fetching terminals');
            }
            throw new ApiError('Error fetching terminals', json);
        }
        return await response.json();
    };
}

function fetchTerminalApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<Terminal> {
    return apiDetail<Terminal>(terminalsViewSet, options, fetchOptions);
}

export async function fetchTerminal(queryClient: QueryClient, terminalId: string | number, options?: FetchQueryOptions<Terminal>, fetchOptions?: FetchOptions) {
    const config = {
        staleTime: Infinity,
        ...options
    };

    return await queryClient.fetchQuery(['terminal', terminalId], fetchTerminalApi({ id: terminalId }, fetchOptions), config);
}

export function useTerminals<TError = unknown>(options?: UseQueryOptions<Terminal[], TError>, fetchOptions?: FetchOptions) {
    const config = {
        staleTime: Infinity,
        ...options
    };
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Terminal[], TError>('terminals', fetchTerminalsApi(fetchOptions), config);
}
