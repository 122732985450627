import { useState } from 'react';

import { Slot } from '../../api/slots';
import { TransactionType } from '../../common/transactions';
import useActivity from '../../hooks/useActivity';
import useSlotFilter, { FilterProps } from '../../hooks/useSlotFilter';
import SlotConfirmationView from '../../views/SlotConfirmationView';
import SlotSelectView from '../../views/SlotSelectView';

enum SelectSlotViews {
    SLOT_SELECT = 'slot_select',
    CONFIRM_SLOT = 'confirm_slot'
}

interface SelectSlotWorkflowProps {
    onSelect: (slot: Slot) => void;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    currentSlotType?: number[];
    transactionType?: TransactionType;
}

const SelectSlotWorkflow = (props: SelectSlotWorkflowProps) => {
    const [currentView, changeCurrentView] = useState(SelectSlotViews.SLOT_SELECT);
    const [, newActivity] = useActivity();
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [filter] = useSlotFilter();

    const onSelect = (slot: Slot) => {
        changeSlot(slot);
        changeCurrentView(SelectSlotViews.CONFIRM_SLOT);
    };

    const onConfirm = () => {
        if (slot != null) {
            props.onSelect(slot);
        } else {
            onChooseDifferent();
        }
    };

    const onChooseDifferent = () => {
        changeCurrentView(SelectSlotViews.SLOT_SELECT);
    };

    const onUnavailable = () => {
        newActivity();
    };

    const slotsFilter = (slots: Slot[]): Slot[] => {
        const filterProps: FilterProps = {
            slots: slots,
            slotType: props.currentSlotType
        };
        return filter(filterProps);
    };

    if (currentView === SelectSlotViews.CONFIRM_SLOT && slot != null) {
        return (
            <SlotConfirmationView
                onInactivity={props.onHome}
                onHome={props.onHome}
                onBack={props.onBack}
                slot={slot}
                onConfirm={onConfirm}
                onChooseDifferent={onChooseDifferent}
                confirmAndOpen={false}
            />
        );
    } else {
        return (
            <SlotSelectView
                onInactivity={props.onHome}
                onHome={props.onHome}
                onBack={props.onBack}
                onUnavailable={onUnavailable}
                onSelect={onSelect}
                slotsFilter={slotsFilter}
                currentSlotType={props.currentSlotType}
                transactionType={props.transactionType}
            />
        );
    }
};

export default SelectSlotWorkflow;
