import { Logger } from '../../logs/Logger';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand } from '../useManagedWebsocket';

export class WebsocketUpdateCheckCommand extends WebsocketCommand {
    public async execute(): Promise<WebsocketCommandResponse> {
        return new Promise((resolve) => {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (registration) {
                    registration.update();
                    resolve({ success: true });
                } else {
                    Logger.error('Could not perform update check. No service worker registration found.');
                    resolve({ success: false, message: 'Could not perform update check. No service worker registration found.', send: true });
                }
            });
        });
    }
}
