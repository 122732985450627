import { ChangeEvent, FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useActivity from '../../../hooks/useActivity';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';

interface BadgeRegistrationBadgeNrViewProps {
    onBadgeNrEntered: (employeeId: string) => void;
    onBadgeNrNotAvailable: () => void;
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;
}

export default function BadgeRegistrationBadgeNrView(props: BadgeRegistrationBadgeNrViewProps) {
    const [badgeNr, changeBadgeNr] = useState('');
    const [, newActivity] = useActivity();
    const intl = useIntl();

    function onChangeBadgeNr(event: ChangeEvent<HTMLInputElement>) {
        newActivity();
        changeBadgeNr(event.currentTarget.value);
    }

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        newActivity();
        event.preventDefault();
        props.onBadgeNrEntered(badgeNr);
    }

    const homeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );
    const inputPlaceholder = intl.formatMessage({
        id: 'views.nike.registration.BadgeRegistrationBadgeNrView.inputPlaceholder',
        description: 'The placeholder on the badge number input field on the Nike badge registration badge nr view.',
        defaultMessage: 'Enter badge number here'
    });

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationBadgeNrView.title'
                    description='The title on the Nike badge registration badge nr view.'
                    defaultMessage='Register with badge number'
                />
            </ViewTitle>
            <p>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationBadgeNrView.message'
                    description='The message on the Nike badge registration badge nr view.'
                    defaultMessage='To find your badge number, remove both cards from your badge holder. The small number on the second blank card is your badge number. Enter your badge number below and perform a search.'
                />
            </p>
            <form
                className='container-fluid mb-4'
                onSubmit={onSubmit}>
                <div className='input-group'>
                    <input
                        type='text'
                        id='badgeNrInput'
                        className='form-control'
                        value={badgeNr}
                        onChange={onChangeBadgeNr}
                        required
                        placeholder={inputPlaceholder}
                    />
                    <button
                        type='submit'
                        className='primary-button'>
                        <i className='bi bi-search me-2' />
                        <span> </span>
                        <FormattedMessage
                            id='views.nike.registration.BadgeRegistrationBadgeNrView.searchCallToAction'
                            description='The call to action to search on the Nike badge registration badge nr view.'
                            defaultMessage='Search'
                        />
                    </button>
                </div>
            </form>
            <button
                className='secondary-button'
                onClick={() => {
                    props.onBadgeNrNotAvailable();
                }}>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationBadgeNrView.noBadgeNrCallToAction'
                    description='The call to action when no employee available on the Nike badge registration badge nr view.'
                    defaultMessage="My badge doesn't have a number"
                />
            </button>
        </BaseView>
    );
}
