import { useTransactions } from '../api/transactions';
import { TransactionStatus } from '../common/transactions';

interface UseContactTransactionHistoryProps {
    cutoffTimeDeltaMs: number;
    contactId?: string | number | null;
    contactGroupId?: string | number | null;
    includeFutureTransactions?: boolean;
}

function useContactTransactionHistory(props: UseContactTransactionHistoryProps) {
    const includeFutureTransactions = props.includeFutureTransactions === true;
    const created_date_after = new Date();
    created_date_after.setMilliseconds(created_date_after.getMilliseconds() - props.cutoffTimeDeltaMs);
    // Set hours, minutes, seconds and ms to 0 to make caching work correctly, otherwise this would always cause a refetch.
    created_date_after.setHours(0);
    created_date_after.setMinutes(0);
    created_date_after.setSeconds(0);
    created_date_after.setMilliseconds(0);

    const transactionStates = [
        TransactionStatus.DROPOFF_IN_PROGRESS,
        TransactionStatus.READY_FOR_PICKUP,
        TransactionStatus.PICKUP_IN_PROGRESS,
        TransactionStatus.COMPLETED
    ];
    if (includeFutureTransactions) {
        transactionStates.push(TransactionStatus.NEW);
        transactionStates.push(TransactionStatus.READY_FOR_DROPOFF);
    }

    return useTransactions(
        {
            status: transactionStates,
            sender: props.contactId ? props.contactId.toString() : undefined,
            receiver: props.contactId ? props.contactId.toString() : undefined,
            sender_group: props.contactGroupId ? props.contactGroupId.toString() : undefined,
            receiver_group: props.contactGroupId ? props.contactGroupId.toString() : undefined,
            created_date_after: created_date_after.toISOString()
        },
        { enabled: !!props.contactId || !!props.contactGroupId }
    );
}

export default useContactTransactionHistory;
