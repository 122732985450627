import { Contact } from '../../api/contacts';
import { Transaction } from '../../common/transactions';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ErrorView from '../common/ErrorView';
import TransactionInformation from './TransactionInformation';

interface TransactionListProps {
    transactions?: Transaction[];
    transactionMap?: Map<string, Transaction[]>;
    contact?: Contact;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;

    cancelTransaction?: (transaction: Transaction) => void;
    changeTransactionSlot?: (transaction: Transaction) => void;
    removeTransaction?: (transaction: Transaction) => void;
}

const TransactionList = (props: TransactionListProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    if (props.transactionMap) {
        const list: JSX.Element[] = [];
        props.transactionMap.forEach((transactions, key) => {
            list.push(
                <div
                    className='information-card'
                    key={key}>
                    <h5 className='title'>{key}</h5>
                    <div>
                        {transactions.map((transaction) => (
                            <TransactionInformation
                                color='white'
                                key={transaction.id}
                                transaction={transaction}
                                cancelTransaction={props.cancelTransaction}
                                changeTransactionSlot={props.changeTransactionSlot}
                                removeTransaction={props.removeTransaction}
                            />
                        ))}
                    </div>
                </div>
            );
        });
        return (
            <BaseView
                navbarItems={backHomeNav}
                onInactivity={props.onInactivity}>
                {list}
            </BaseView>
        );
    } else if (props.transactions) {
        return (
            <div className='information-card-list'>
                {props.transactions.map((transaction) => (
                    <TransactionInformation
                        key={transaction.id}
                        transaction={transaction}
                        cancelTransaction={props.cancelTransaction}
                        changeTransactionSlot={props.changeTransactionSlot}
                        removeTransaction={props.removeTransaction}
                    />
                ))}
            </div>
        );
    }

    return <ErrorView />;
};

export default TransactionList;
