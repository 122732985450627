import { PlatformHardwareDriver, SpotLayoutItem } from '../../../api/spotLayoutItems';
import { Logger } from '../../../logs/Logger';
import { SlotDoorState, SlotSensorState } from '../../../redux/SpotLayoutLogsSlice';
import { BackendHardwareDriverOptionsObject, TerminalHardwareObject } from '../DriverSlotObjects';

export interface ZhilaiHardwareObject extends TerminalHardwareObject {
    id: number;
    name: string;
    slots: {
        spotLayoutItemId: string;
        id: string;
        open?: SlotDoorState;
        occupied?: SlotSensorState;
    }[];
}

export function mapZhilaiHardwareOptions(options: ZhilaiHardwareObject[]): ZhilaiHardwareObject[] {
    const mapped: ZhilaiHardwareObject[] = [];
    options.forEach((option) => {
        const alreadyExists = mapped.find((i) => i.id === option.id);
        if (alreadyExists) {
            mapped[mapped.indexOf(alreadyExists)] = {
                ...alreadyExists,
                slots: [...alreadyExists.slots, ...option.slots].sort((a, b) => +a.id - +b.id)
            };
        } else {
            mapped.push(option);
        }
    });
    return mapped;
}

export function getZhilaiHardwareOptions(spotLayoutItem: SpotLayoutItem): ZhilaiHardwareObject | null {
    const options = spotLayoutItem.hardware_driver_options.data;
    if (options.find((option) => !checkZhilaiDriverOptions(option)) !== undefined) {
        Logger.error('incorrect driver params given', {}, spotLayoutItem);
        return null;
    }
    return getZhilaiDriverOptions(spotLayoutItem);
}

function getZhilaiDriverOptions(spotLayoutItem: SpotLayoutItem): ZhilaiHardwareObject {
    const options = spotLayoutItem.hardware_driver_options.data;
    return {
        driver: PlatformHardwareDriver.ZHILAI,
        id: options[0].config.board!.charCodeAt(0) - 64,
        name: options[0].config.board!,
        slots: options.map((option) => {
            return {
                spotLayoutItemId: spotLayoutItem.id,
                id: option.config.slot!,
                open: SlotDoorState.UNKNOWN,
                occupied: SlotSensorState.UNKNOWN
            };
        })
    };
}

function checkZhilaiDriverOptions(options: BackendHardwareDriverOptionsObject): boolean {
    return options.driver === PlatformHardwareDriver.ZHILAI && options.config.slot !== undefined && options.config.board !== undefined;
}

export function isValidZhilaiSpotLayoutItem(spotLayoutItem: SpotLayoutItem): boolean {
    return spotLayoutItem.hardware_driver_options.data.some((driver) => checkZhilaiDriverOptions(driver));
}

export function isZhilaiDriverOptions(options: TerminalHardwareObject): options is ZhilaiHardwareObject {
    return options.driver === PlatformHardwareDriver.ZHILAI && Array.isArray((options as any).slots);
}
