import { IProtonEvent } from '../../scales/proton/IProtonEvent';

export enum AndroidEvents {
    INIT = 'terminal:initResult',
    KEEP_ALIVE = 'terminal:keepAliveResult',
    INFO_SLOT = 'terminal:getInfoSlotResult',
    OCCUPY_SLOT = 'terminal:getOccupySlotResult',
    SCANNER = 'terminal:scannerResult',
    SCANNER_QR = 'terminal:scannerResultQR',
    SCANNER_RFID = 'terminal:scannerResultRFID',
    OPEN_SLOT = 'terminal:openSlotResult',
    PROTON = 'terminal:proton',
    SLOT_STATE = 'terminal:slotsStateUpdateResult',
    OAUTH_RESPONSE = 'terminal:externalOAuthResponse'
}

declare global {
    interface WindowEventMap {
        'terminal:initResult': CustomEvent<{ appVersion?: string; androidId?: string }>;
        'terminal:keepAliveResult': CustomEvent<void>;
        'terminal:getInfoSlotResult': CustomEvent<any>;
        'terminal:getOccupySlotResult': CustomEvent<any>;
        //'terminal:accessTokenResult': CustomEvent<AccessToken>;
        //'terminal:resetSystemResult': CustomEvent<any>;
        'terminal:scannerResult': CustomEvent<{ scannerValue: string }>;
        'terminal:scannerResultQR': CustomEvent<{ scannerValue: string }>;
        'terminal:scannerResultRFID': CustomEvent<{ scannerValue: string }>;
        'terminal:openSlotResult': CustomEvent<void>;
        'terminal:proton': CustomEvent<{ proton: IProtonEvent }>;
        //'terminal:openSlotRangeResult': CustomEvent<any>;
        'terminal:slotsStateUpdateResult': CustomEvent<any>;
        'terminal:externalOAuthResponse': CustomEvent<{ url: string }>;
    }
}
