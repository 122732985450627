const InternationalPhonenumberRegex = /([+])\d\d/;

/**
 * This function will make sure a phone number gets parsed to a international phone number (if it isn't one already)
 * @param initial
 * @returns international phone number
 */
export function parseNumberToInternational(initial: string): string {
    if (initial === '') return initial;
    if (InternationalPhonenumberRegex.test(initial)) return initial;
    if (initial.startsWith('0')) initial.replace('0', '');
    if (initial.startsWith('0')) initial.replace('0', '');
    return '+32' + initial;
}
