import React from 'react';

import { formatTimeDelta } from './utils';

interface RefreshFailedProps {
    secondsUntilRetry: number;
}

function RefreshFailed(props: RefreshFailedProps) {
    return (
        <div className='container'>
            <div className='text-center mt-2'>
                <div className='alert alert-danger'>
                    The Terminal could not be initialized. Most likely due to a network connectivity problem. We will try again in{' '}
                    {formatTimeDelta(props.secondsUntilRetry)}.
                </div>
            </div>
        </div>
    );
}

export default RefreshFailed;
