import { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Transaction } from '../../common/transactions';
import usePerformLookup, { LookupType, ValueInputType } from '../../hooks/lookup/usePerformLookup';
import useActivity from '../../hooks/useActivity';

interface InputLookupModalProps {
    value?: string;
    lookupType?: LookupType;
    enabled: boolean;
    resultCallback: (result?: Transaction) => void;
    onClose?: () => void;
    doReset: boolean;
}

const InputLookupModal = (props: InputLookupModalProps) => {
    const [, newActivity] = useActivity();
    const [close, changeClose] = useState<boolean>(true);
    const [, transaction, , loading, error, performLookup, resetLookup] = usePerformLookup();

    useEffect(() => {
        resetLookup();
    }, [props.doReset]);

    useEffect(() => {
        if (!props.enabled || props.value == undefined) return;
        newActivity();
        changeClose(false);
        performLookup({
            value: props.value,
            valueInputType: ValueInputType.PIN,
            lookupType: props.lookupType
        });
    }, [props.value, props.lookupType]);

    function callBackFunction() {
        props.resultCallback(transaction ? transaction : undefined);
    }

    useEffect(() => {
        if (!loading && props.enabled) callBackFunction();
    }, [loading, transaction]);

    let modalContent: string | React.ReactNode = (
        <div className='d-flex flex-row justify-content-center'>
            <Spinner
                animation='border'
                role='status'></Spinner>
        </div>
    );
    if (!loading) {
        if (transaction === null || error) {
            modalContent = <InvalidCodeMessage />;
        } else {
            // Redirecting soon
            modalContent = (
                <p className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow.correctBadgeModalMessage'
                        description='This message is shown on the modal popup when the badge or code was successfully recognized in the nike workflow'
                        defaultMessage='Successfully recognized badge or code. Loading next page.'
                    />
                </p>
            );
        }
    } else if (error) {
        modalContent = (
            <p className='text-center'>
                <FormattedMessage
                    id='workflow.nike.NikeWorkflow.lookupErrorModalMessage'
                    description='This message is shown on the modal popup when an error occurred trying to process the badge/QR value in the nike workflow'
                    defaultMessage='Something went wrong. Try again later.'
                />
            </p>
        );
    }

    return (
        <Modal
            centered
            size='sm'
            animation={true}
            show={props.enabled || !close}
            onHide={resetLookup}>
            <Modal.Body>{modalContent}</Modal.Body>
            <Modal.Footer className='border-0 justify-content-center'>
                <button
                    className='primary-button btn-lg'
                    onClick={() => {
                        changeClose(true);
                        props.onClose && props.onClose();
                    }}>
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow'
                        description='The dismiss button on the processing scanner value modal (for Nike)'
                        defaultMessage='Dismiss'
                    />
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default InputLookupModal;

const InvalidCodeMessage = () => {
    const [content, changeContent] = useState<JSX.Element>(
        <div className='d-flex flex-row justify-content-center'>
            <Spinner
                animation='border'
                role='status'></Spinner>
        </div>
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            changeContent(
                <p className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.NikeWorkflow.invalidBadgeModalMessage'
                        description='This message is shown on the modal popup when the badge or code could was not recognized in the nike workflow'
                        defaultMessage='Invalid badge or code. Try again.'
                    />
                </p>
            );
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return content;
};
