import { useContext } from 'react';

import { TerminalContext } from '../TerminalInit';

const useWebsocket = () => {
    const { websocket } = useContext(TerminalContext)!;
    return websocket;
};

export default useWebsocket;
