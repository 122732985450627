import { useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { Contact } from '../../api/contacts';
import { Slot } from '../../api/slots';
import ActionCard from '../../components/domain/ActionCard';
import { Logger } from '../../logs/Logger';
import ConfirmLendingView from '../../views/lending/ConfirmLendingView';
import { UserSpecificType } from './LendingWorkflow';
import UserInfoWorkflow from './UserInfoWorkflow';

enum ConfirmLendingWorkflowViews {
    USER_INFO_WORkFLOW = 'user_info_workflow',
    CONFIRM_VIEW = 'confirm_view'
}

interface ConfirmLendingWorkflowProps {
    onHome?: () => void;
    onInactivity?: () => void;
    onBack?: () => void;
    onConfirm: (contact: Contact) => void;
    customWorkflow?: UserSpecificType;
    selectedSlot: Slot | null;
    slotType?: number[] | null;
    account_id: number;
    contact?: Contact;
}

const ConfirmLendingWorkflow = (props: ConfirmLendingWorkflowProps) => {
    const [currentView, changeCurrentView] = useState<ConfirmLendingWorkflowViews>(ConfirmLendingWorkflowViews.USER_INFO_WORkFLOW);
    const [contact, changeContact] = useState<Contact | undefined>(props.contact);
    const intl = useIntl();

    const { confirmLendingAction } = useMemo(() => {
        return ConfirmLendingActions(intl);
    }, [intl]);

    const userSubmit = (contact: Contact) => {
        changeContact(contact);
        changeCurrentView(ConfirmLendingWorkflowViews.CONFIRM_VIEW);
        Logger.log('User submitted changing screen to confirm.', { contact: contact.id }, contact);
    };

    switch (currentView) {
        case ConfirmLendingWorkflowViews.USER_INFO_WORkFLOW:
            return (
                <UserInfoWorkflow
                    onBack={props.onBack}
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                    onSubmit={userSubmit}
                    account_id={props.account_id}
                    slotType={props.slotType}
                    customWorkflow={props.customWorkflow}
                    contact={contact}
                />
            );
        case ConfirmLendingWorkflowViews.CONFIRM_VIEW:
            return (
                <ConfirmLendingView
                    onBack={() => changeCurrentView(ConfirmLendingWorkflowViews.USER_INFO_WORkFLOW)}
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                    onConfirm={() => {
                        contact ? props.onConfirm(contact) : changeCurrentView(ConfirmLendingWorkflowViews.USER_INFO_WORkFLOW);
                    }}
                    actions={[confirmLendingAction]}
                    selectedSlot={props.selectedSlot}
                />
            );
    }
};

export default ConfirmLendingWorkflow;

function ConfirmLendingActions(intl: IntlShape) {
    const confirmLendingAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.lending.LendingWorkflow.confirmLending',
            description: 'This is the confirm lending action in the lending new location flow',
            defaultMessage: 'Confirm'
        }),
        icon: <i className='bi bi-check-circle dashboard-action-icon' />
    };

    return {
        confirmLendingAction
    };
}
