import { PrinterStatus } from '../Printer';

export enum PrinterManufacturers {
    ZEBRA = 'Zebra Technologies'
}

export default abstract class IPrinter {
    abstract manufacturer: PrinterManufacturers;

    abstract parsePrinterStatus: (value: string) => PrinterStatus;
}
