import { useIntl } from 'react-intl';

import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { isAdmin } from '../../../services/contact/GlobalPermissions';
import AdjustLightWorkflow from '../../Admin/AdjustLightWorkflow';

type ConfigureLightsActionProps = AllowedActionViewProps;

const ConfigureLights = (props: ConfigureLightsActionProps) => {
    return (
        <AdjustLightWorkflow
            onHome={props.onHome}
            onBack={props.onBack}
            onInactivity={props.onInactivity}
        />
    );
};

function ConfigureLightsAction(props: AllowedActionViewProps) {
    const intl = useIntl();
    if (isAdmin(props.contact)) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.AuthenticatedContactWorkflow.ConfigureLightsAction',
                description: 'This is the ConfigureLights action in the AuthenticatedContactWorkflow',
                defaultMessage: 'Configure lights'
            }),
            icon: <i className='bi bi-brightness-alt-high dashboard-action-icon' />,
            view: <ConfigureLights {...props} />
        };
    }
    return undefined;
}

export default ConfigureLightsAction;
