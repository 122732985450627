import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../../TerminalInit';
import { Contact } from '../../../../api/contacts';
import { useSpotLayout } from '../../../../api/spotLayouts';
import { useMutateMoveTransaction, useTransaction } from '../../../../api/transactions';
import { Transaction, TransactionStatus } from '../../../../common/transactions';
import useActivity from '../../../../hooks/useActivity';
import { Logger } from '../../../../logs/Logger';
import BackAndHomeNavigationButtons from '../../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../../views/common/BaseView';
import LoadingView from '../../../../views/common/LoadingView';
import JustDrop from '../../../JustDrop';
import RemoveTransactionWorkflow from '../RemoveTransactionWorkflow';
import SelectNewSlot from './SelectNewSlot';

enum TransactionState {
    SELECTING_SPOT,
    CANCELLING,
    REMOVING,
    DROPPING,
    DONE
}

interface Props {
    loggedInContact: Contact;
    transaction: Transaction;
    onInactivity?: () => void;
    onBack?: () => void;
    onHome?: () => void;
}

export default function MoveTransactionWorkflow(props: Props) {
    const [originalStatus] = useState<TransactionStatus>(props.transaction.status);
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    const [, newActivity] = useActivity();
    const moveTransaction = useMutateMoveTransaction();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const [removeTransaction, changeRemoveTransaction] = useState<Transaction | undefined>(undefined);
    const [newSpotId, changeNewSpotId] = useState<string | undefined>(undefined);
    const [state, changeState] = useState<TransactionState>(TransactionState.SELECTING_SPOT);

    const [transaction, changeTransaction] = useState<Transaction | undefined>(undefined);
    const refetchedTransaction = useTransaction(undefined, props.transaction.id, true);
    useEffect(() => {
        changeTransaction(refetchedTransaction);
    }, [refetchedTransaction]);
    useEffect(() => {
        if (state === TransactionState.REMOVING) {
            if (transaction?.status === TransactionStatus.READY_FOR_DROPOFF) {
                if (newSpotId !== undefined && spotLayout?.spot === +newSpotId!.replace('SPT', '') && originalStatus !== TransactionStatus.READY_FOR_DROPOFF) {
                    changeState(TransactionState.DROPPING);
                } else changeState(TransactionState.DONE);
            }
            if (transaction && TransactionStatus.inside_distrispot_states().includes(transaction.status)) {
                newActivity();
                changeRemoveTransaction(transaction);
            } else if (transaction) {
                changeState(TransactionState.DONE);
            }
        } else if (state === TransactionState.CANCELLING && transaction?.status === TransactionStatus.CANCELLED) {
            changeState(TransactionState.REMOVING);
        } else if (state === TransactionState.DROPPING && transaction?.status === TransactionStatus.READY_FOR_PICKUP) {
            changeState(TransactionState.DONE);
        }
    }, [transaction]);

    const onSlotSelected = (spotId: string, slotId?: string) => {
        changeNewSpotId(spotId);
        changeState(TransactionState.CANCELLING);
        moveTransaction.mutate({
            transactionId: transaction!.id,
            body: {
                new_spot_id: spotId,
                new_slot_id: slotId
            }
        });
    };

    if (transaction === undefined) {
        return (
            <LoadingView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    } else if (
        newSpotId !== undefined &&
        spotLayout?.spot === +newSpotId!.replace('SPT', '') &&
        state === TransactionState.DROPPING &&
        transaction?.status === TransactionStatus.READY_FOR_DROPOFF
    ) {
        return (
            <JustDrop
                transaction={transaction}
                onHome={props.onHome}
                onBack={props.onBack}
                onInactivity={props.onInactivity}
            />
        );
    } else if (removeTransaction && state === TransactionState.REMOVING) {
        Logger.log(transaction.status);
        return (
            <RemoveTransactionWorkflow
                loggedInContact={props.loggedInContact}
                transaction={removeTransaction}
                confirmed={true}
                onInactivity={props.onInactivity}
                onBack={() => changeRemoveTransaction(undefined)}
                onHome={props.onHome}
                onFinishCallback={() => {
                    if (newSpotId !== undefined && spotLayout?.spot === +newSpotId!.replace('SPT', '')) {
                        changeState(TransactionState.DROPPING);
                    } else changeState(TransactionState.DONE);
                    changeRemoveTransaction(undefined);
                }}
            />
        );
    } else if (state === TransactionState.SELECTING_SPOT) {
        return (
            <SelectNewSlot
                transaction={transaction}
                onSubmit={onSlotSelected}
                onBack={props.onBack}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else if (state === TransactionState.DONE) {
        return (
            <BaseView
                navbarItems={backHomeNav}
                onInactivity={props.onInactivity}>
                <h3 className='mt-3 text-center'>
                    <FormattedMessage
                        id='MoveTransactionWorkflow.SuccesMessage'
                        description='The message shown to the user after successfully moving a transaction.'
                        defaultMessage='Successfully moved transaction.'
                    />
                </h3>
            </BaseView>
        );
    } else {
        return (
            <LoadingView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    }
}
