import crypto from 'crypto';

import { Product } from '../../api/products';
import { PlatformHardwareDriver, SpotLayoutItem } from '../../api/spotLayoutItems';
import { BackendHardwareDriverOptionsObject } from '../../services/platformHardwareDriver/DriverSlotObjects';
import ProtonDevice from './ProtonDevice';
import { ProtonPacket } from './ProtonPacket';
import ProtonProduct from './ProtonProduct';
import { IProtonRequest } from './requests/IProtonRequest';

export enum ProtonEndpoint {
    CLUSTER = '/cluster/',
    WT = '/WT/',
    ADDRESS = '/address/'
}

const SIGN_PASSWORD = 'levent8421';

export class ProtonPacketUtils {
    static sign(packet: ProtonPacket) {
        const items = [];
        items.push(`type=${packet.getType()}`);
        items.push(`protocolVer=${packet.getProtocolVer()}`);
        const header = packet.getHeader();
        items.push(`action=${header.getAction()}`);
        items.push(`actionVer=${header.getActionVer()}`);
        items.push(`trace=${header.getTrace()}`);
        items.push(`timestamp=${header.getTimestamp()}`);
        items.push(`priority=${header.getPriority()}`);
        items.sort();

        const sb = [];
        let first = true;

        for (const item of items) {
            if (!first) {
                sb.push('&');
            } else {
                first = false;
            }
            sb.push(item);
        }
        sb.push(SIGN_PASSWORD);

        const signText = sb.join('');
        return this.md5Hex(signText);
    }

    static md5Hex(source: string) {
        try {
            const md5 = crypto.createHash('md5');
            const digest = md5.update(source, 'utf8').digest();
            return this.hex(digest).toUpperCase();
        } catch (error) {
            throw new Error('Can not load MD5 Algorithm');
        }
    }

    static hex(bytes: Buffer) {
        const sb = [];
        for (const b of bytes) {
            const hexItem = ('00' + (b & 0xff).toString(16)).slice(-2);
            sb.push(hexItem);
        }
        return sb.join('');
    }

    static mapToProtonDevices(spotLayoutItems: SpotLayoutItem[]): ProtonDevice[] {
        const protonConfig: ProtonHardwareDriverOption[] = [];
        spotLayoutItems.forEach((item) => {
            const object = item.hardware_driver_options.data.filter(isProtonHardwareDriverOption);
            object.forEach((o) => protonConfig.push(o));
        });
        return protonConfig.map((o) => new ProtonDevice(o));
    }

    static mapToProtonProducts(spotLayoutItems: SpotLayoutItem[], products: Product[]): Map<string, ProtonProduct[]> {
        const foundCombinations: {
            option: ProtonHardwareDriverOption;
            product: Product;
        }[] = [];
        spotLayoutItems.forEach((item) => {
            const validOptions = item.hardware_driver_options.data.filter(isProtonHardwareDriverOption);
            if (validOptions.length === 1 && item.slot !== undefined) {
                const validProducts = products.filter((p) => p.slots.includes(+item.slot!.replace('SLT', '')));
                if (validProducts.length === 1) {
                    foundCombinations.push({
                        option: validOptions[0],
                        product: validProducts[0]
                    });
                }
            }
        });
        const result: Map<string, ProtonProduct[]> = new Map<string, ProtonProduct[]>();
        foundCombinations.forEach((com) => {
            const exists = result.get(com.option.config.stationId);
            if (exists !== undefined) {
                result.set(com.option.config.stationId, [...exists, new ProtonProduct(com.option, com.product)]);
            } else {
                result.set(com.option.config.stationId, [new ProtonProduct(com.option, com.product)]);
            }
        });
        return result;
        /*if (o.product.image) {
                //images dont seem to work
                //the scales only accept base64 but when provided with a valid base64 it still does not display anything
                return new ProtonProduct(o.option, o.product, binary);
            }*/
    }

    static generateEventResponse(event: IProtonRequest): any {
        const packet = new ProtonPacket(event.header.action, 'response', undefined, event.header.trace);
        packet.getHeader().setSign(ProtonPacketUtils.sign(packet));
        return packet;
    }
}
export interface ProtonHardwareDriverOption {
    driver: PlatformHardwareDriver.PROTON;
    config: {
        clusterURI: string;
        deviceId: number[];
        eslId: number | undefined;
        enable: boolean;
        stationId: string;
    };
}

function isProtonHardwareDriverOption(option: BackendHardwareDriverOptionsObject): option is ProtonHardwareDriverOption {
    return option.driver === PlatformHardwareDriver.PROTON && option.config.clusterURI !== undefined;
}
