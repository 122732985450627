import { PlatformHardwareDriver, SpotLayoutItem } from '../../../api/spotLayoutItems';
import { Logger } from '../../../logs/Logger';
import { BackendHardwareDriverOptionsObject, TerminalHardwareObject } from '../DriverSlotObjects';

export interface ADAMHardwareObject extends TerminalHardwareObject {
    id: string;
    slots: {
        username: string;
        password: string;
        ip: string;
        port: string;
        mode?: string;
        doorOpenTime: number;
    }[];
}

export function mapADAMHardwareOptions(options: ADAMHardwareObject[]): ADAMHardwareObject[] {
    const mapped: ADAMHardwareObject[] = [];
    options.forEach((option) => {
        const alreadyExists = mapped.find((i) => i.id === option.id);
        if (alreadyExists) {
            mapped[mapped.indexOf(alreadyExists)] = {
                ...alreadyExists,
                slots: [...alreadyExists.slots, ...option.slots]
            };
        } else {
            mapped.push(option);
        }
    });
    return mapped;
}

export function getADAMHardwareOptions(spotLayoutItem: SpotLayoutItem): ADAMHardwareObject | null {
    const options = spotLayoutItem.hardware_driver_options.data;
    if (options.find((option) => !checkADAMDriverOptions(option)) !== undefined) {
        Logger.error('incorrect driver params given', {}, spotLayoutItem);
        return null;
    }
    return getADAMDriverOptions(spotLayoutItem);
}

export function getADAMDriverOptions(spotLayoutItem: SpotLayoutItem): ADAMHardwareObject {
    const options = spotLayoutItem.hardware_driver_options.data;
    return {
        driver: options[0].driver,
        id: options[0].config.ip!,
        slots: options.map((option) => {
            return {
                username: option.config.username!,
                password: option.config.password!,
                ip: option.config.ip!,
                port: option.config.port != undefined ? option.config.port : '0',
                doorOpenTime: option.config.doorOpenTime != undefined ? option.config.doorOpenTime : 1
            };
        })
    };
}

export function checkADAMDriverOptions(options: BackendHardwareDriverOptionsObject): boolean {
    return (
        options.driver === PlatformHardwareDriver.ADAM6060 &&
        options.config.username !== undefined &&
        options.config.password !== undefined &&
        options.config.doorOpenTime !== undefined &&
        options.config.ip !== undefined
    );
}
