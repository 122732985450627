import { useContext } from 'react';

import { TerminalContext } from '../TerminalInit';

const lockoutSeconds = parseInt(process.env.REACT_APP_LOCKOUT_SECONDS!, 10);
const allowedPinAttempts = parseInt(process.env.REACT_APP_ALLOWED_PIN_ATTEMPTS!, 10);

export const nextAttempt = (pinAttempts: Date[]): Date | null => {
    // Check to see if a new attempt can be performed. The algorithm is:
    //  1) Disregard all attempts more than 15 minutes ago. They could be done by someone else.
    //  2) If more than 3 attempts were made in the last x seconds then make the user wait until the oldest expires.
    const nowMinusT = new Date();
    nowMinusT.setSeconds(nowMinusT.getSeconds() - lockoutSeconds);
    const newPinAttempts = pinAttempts.filter((value) => {
        return value > nowMinusT;
    });
    if (newPinAttempts.length < allowedPinAttempts) {
        return null;
    }
    const sortedNewPinAttempts = newPinAttempts.sort((a, b) => {
        return a.getTime() - b.getTime();
    });
    const thirdToLastAttempt = sortedNewPinAttempts[sortedNewPinAttempts.length - allowedPinAttempts];
    const nextAttempt = new Date(thirdToLastAttempt);
    nextAttempt.setSeconds(nextAttempt.getSeconds() + lockoutSeconds);
    return nextAttempt;
};

const usePinProtection = (): [Date | null, (success: boolean, date?: Date) => Date | null] => {
    const { pinAttempts, addPinAttempt } = useContext(TerminalContext);
    const nextAttemptDate = nextAttempt(pinAttempts);

    return [nextAttemptDate, addPinAttempt];
};

export default usePinProtection;
