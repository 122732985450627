import { PrintResponse } from '../Printer';

export default class PrinterPrintException extends Error {
    printResponse?: PrintResponse;
    constructor(message: string, printResponse?: PrintResponse) {
        super(message);
        if (printResponse) {
            this.printResponse = printResponse;
            this.printResponse.error = message;
        } else {
            //this shouldnt happen
            this.printResponse = {
                error: message,
                amountOfPrintedLabels: 0
            };
        }
    }
}
