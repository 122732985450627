import { FormattedMessage } from 'react-intl';

import { Slot } from '../api/slots';
import useSpotLayoutItemsForSlots from '../hooks/useSpotLayoutItemForSlot';
import BackAndHomeNavigationButtons from './common/BackAndHomeNavigationButtons';
import BaseView from './common/BaseView';
import SpotLayout from './elements/SpotLayout';

interface SlotConfirmationViewProps {
    slot: Slot;
    onConfirm: () => void;
    onChooseDifferent?: () => void;
    confirmAndOpen?: boolean;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    gotoMultipleDrop?: (val: boolean) => void;
}

const SlotConfirmationView = (props: SlotConfirmationViewProps) => {
    const slotItems = useSpotLayoutItemsForSlots([props.slot]);

    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const slotNumbersForTitle = { slotNumber: props.slot.slot_nr };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <h3 className='text-center mt-3'>
                {
                    <FormattedMessage
                        id='views.SlotConfirmationView.defaultTitle'
                        description='The default title on the Slot confirmation view.'
                        defaultMessage={'Confirm SLOT {slotNumber}?'}
                        values={slotNumbersForTitle}
                    />
                }
            </h3>
            <div className='text-center'>
                <button
                    className='primary-button btn-lg'
                    onClick={props.onConfirm}>
                    {props.confirmAndOpen === undefined || props.confirmAndOpen ? (
                        <FormattedMessage
                            id='views.SlotConfirmationView.confirmButton'
                            description='The button label to confirm using the proposed Slot.'
                            defaultMessage='Confirm and open SLOT'
                        />
                    ) : (
                        <FormattedMessage
                            id='views.SlotConfirmationView.confirmButtonButKeepClosed'
                            description='The button label to confirm using the proposed Slot (but not opening).'
                            defaultMessage='Confirm'
                        />
                    )}
                </button>
            </div>
            <SpotLayout
                highlightedItems={slotItems}
                onSelect={props.onConfirm}
                enableZoom
            />
            <div className='text-center mt-4 d-flex flex-column align-items-center'>
                {props.onChooseDifferent ? (
                    <button
                        className='primary-button btn-lg'
                        onClick={props.onChooseDifferent}>
                        <FormattedMessage
                            id='views.SlotConfirmationView.otherSlotButton'
                            description='The button label to use a different Slot than the proposed one.'
                            defaultMessage='Choose a different SLOT'
                        />
                    </button>
                ) : (
                    <></>
                )}
                {props.gotoMultipleDrop !== undefined ? (
                    <button
                        className='secondary-button btn-lg mt-2'
                        onClick={() => props.gotoMultipleDrop!(true)}>
                        <FormattedMessage
                            id='views.SlotConfirmationView.dropoffMultipleButton'
                            description='The button label to goto the dropoff multiple of same product screen.'
                            defaultMessage='Dropoff multiple of the same product'
                        />
                    </button>
                ) : (
                    <></>
                )}
            </div>
        </BaseView>
    );
};

export default SlotConfirmationView;
