import { Contact } from '../../api/contacts';
import { Slot } from '../../api/slots';
import { SpotLayoutItem } from '../../api/spotLayoutItems';
import { Transaction } from '../../common/transactions';
import TransactionList from './TransactionList';
import NullScaleButton from './slots/NullScaleButton';
import OpenSlotButton from './slots/OpenSlotButton';

interface SlotInformationProps {
    slot: Slot;
    spotLayoutItem?: SpotLayoutItem;
    openSlot?: () => void;
    disableSlot?: () => void;
    transactions?: Transaction[];
    contact?: Contact;

    cancelTransaction?: (transaction: Transaction) => void;
    changeTransactionSlot?: (transaction: Transaction) => void;
    removeTransaction?: (transaction: Transaction) => void;
}

const SlotInformation = (props: SlotInformationProps) => {
    return (
        <div className='information-card'>
            <div>
                <h5 className='title'>Slot number {props.slot.slot_nr}</h5>
                <div className='blue'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <b>Slot id: &nbsp;</b> <span>{props.slot.id}</span>
                    </div>
                    <div className='d-flex justify-content-around flex-row'>
                        <span>
                            <b>PuDo:</b> {props.slot.settings_transactions_limit_for_pudo}
                        </span>
                        <span>
                            <b>vending:</b> {props.slot.settings_transactions_limit_for_vending}
                        </span>
                        <span>
                            <b>lending:</b> {props.slot.settings_transactions_limit_for_lending}
                        </span>
                        <span>
                            <b>return:</b> {props.slot.settings_transactions_limit_for_return}
                        </span>
                    </div>
                    <div className='d-flex justify-content-around flex-row'>
                        <span>
                            <b>PuDo mutliple pickup:</b> {props.slot.settings_allow_multiple_pudo_pickup_transactions ? 'enabled' : 'disabled'}
                        </span>
                    </div>
                    <div className='d-flex justify-content-around flex-row'>
                        <span>
                            <b>Vending same product:</b> {props.slot.settings_allow_same_vending_products ? 'enabled' : 'disabled'}
                        </span>
                    </div>
                    <dl className='row d-flex'>
                        {props.transactions && props.transactions.length > 15 && (
                            <div className='text-center'>
                                <b>Transactions: </b>
                                {props.transactions.length}
                            </div>
                        )}
                        {props.transactions && props.transactions.length <= 15 && (
                            <div>
                                <TransactionList
                                    transactions={props.transactions}
                                    contact={props.contact}
                                    cancelTransaction={props.cancelTransaction}
                                    changeTransactionSlot={props.changeTransactionSlot}
                                    removeTransaction={props.removeTransaction}
                                />
                            </div>
                        )}
                    </dl>
                </div>
                <div className='white'>
                    <dl className='row'>
                        <dt className='text-center title col-sm-12'>
                            <h4>Dimensions</h4>
                        </dt>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3 sub-title'>Depth</dt>
                        <p className='col-sm-3'>{props.slot.specification.depth}</p>
                        <dt className='col-sm-3 sub-title'>Height</dt>
                        <p className='col-sm-3'>{props.slot.specification.height}</p>
                        <dt className='col-sm-3 sub-title'>Width</dt>
                        <p className='col-sm-3'>{props.slot.specification.width}</p>
                    </dl>
                </div>
                <OpenSlotButton spotLayoutItem={props.spotLayoutItem} />
                <NullScaleButton spotLayoutItem={props.spotLayoutItem} />
            </div>
        </div>
    );
};

export default SlotInformation;
