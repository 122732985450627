import { useContext, useEffect, useState } from 'react';

import { TerminalContext } from '../TerminalInit';
import { Logger } from '../logs/Logger';
import { HardwareConnectionContext } from '../terminal/useHardwareConnection';
import useInterval from './useInterval';

const keepAliveInterval = 2 * 60 * 1000; //2 minutes

export default function useKeepAlive() {
    const { terminal } = useContext(TerminalContext);
    const connection = useContext(HardwareConnectionContext);

    const [keepAliveIsRunning, changeKeepAliveIsRunning] = useState(false);

    useEffect(() => {
        if (!keepAliveIsRunning && terminal !== null && connection) {
            Logger.setEnableExtensiveLogging(terminal.settings_enable_ws_logger);
            changeKeepAliveIsRunning(true);
            connection.keepAlive();
        }
    }, [keepAliveIsRunning, connection, terminal]);

    useInterval(() => {
        if (connection) {
            Logger.log('Keep alive message sent at: ' + new Date().toTimeString());
            connection.keepAlive();
        } else Logger.warn('No hardware connection found. Skip sending keep alive message.', {}, terminal, connection);
    }, keepAliveInterval);
}
