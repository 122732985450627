import { useEffect, useRef, useState } from 'react';

import { Terminal } from '../api/terminals';
import { Logger } from '../logs/Logger';
import { getRandomInt } from '../utils';
import { WebsocketCommandResponse } from './MainWebsocketCommands';

enum WebSocketState {
    DISABLED = 'disabled',
    NEW = 'new',
    INIT = 'init',
    CONNECTED = 'connected',
    ERROR = 'error'
}
export enum WebsocketCommandResponseStatus {
    ERROR = 'error',
    SUCCESS = 'success',
    CRITICAL = 'critical'
}
interface WebSocketData {
    websocket: WebSocket | null;
    state: WebSocketState;
}

export enum WebsocketCommandType {
    RESET = 'reset',
    SWRESET = 'serviceWorkerReset',
    REFRESH = 'refresh',
    OPEN_SLOT = 'openSlot',
    CONFIG = 'config',
    CACHE_RESET = 'cacheReset',
    UPDATE_CHECK = 'updateCheck',
    RESET_PCB = 'resetPcb',
    POWER_CYCLE = 'powerCycle',
    SET_LIGHTS = 'lightsOn',
    TERMINAL_ON = 'terminalOn',
    TERMINAL_REQUEST = 'terminalRequest',
    SLOT_STATE = 'slotState',
    SPOT_SLOT_STATES = 'spotSlotsStates',
    REQUEST_LOGS = 'requestLogs',
    STATUS_UPDATE = 'statusUpdate',
    LOGS = 'logs',
    AUTHENTICATE_CONTACT = 'authenticateContact',

    //PRINTING
    PRINT_LABEL = 'printLabel',
    RESET_PRINTER = 'resetPrinter',
    PRINTER_STATUS = 'printerStatus',
    PAUSE_PRINTER = 'pausePrinter',
    CLEAR_PRINTER_QUEUE = 'clearPrinterQueue'
}

export interface WebsocketCommandRequest {
    command: WebsocketCommandType;
    additional_data?: unknown;

    [key: string]: unknown;
}
export abstract class WebsocketCommand {
    type: WebsocketCommandType;
    data: unknown;
    additional_data?: unknown;

    constructor(request: WebsocketCommandRequest) {
        this.type = request.command;
        this.additional_data = request.additional_data;
    }

    abstract execute(...args: any): Promise<WebsocketCommandResponse>;
}

interface ManagedWebsocketProps {
    onCommand: (command: WebsocketCommandRequest) => void;
    terminal?: Terminal | null;
}

function useManagedWebsocket(props: ManagedWebsocketProps): [WebSocket | null, () => void] {
    const [currentWs, changeCurrentWs] = useState<WebSocketData>({
        websocket: null,
        state: WebSocketState.DISABLED
    });
    const cleanupRef = useRef<(() => void) | null>(null);

    const startWs = () => {
        const newWs = new WebSocket(`${process.env.REACT_APP_WS_API_URL!}?access_token=${process.env.REACT_APP_CLIENT_ID!}`);
        changeCurrentWs({
            websocket: newWs,
            state: WebSocketState.INIT
        });

        const pingInterval: number | null = null;
        const wsOpened = (event: Event) => {
            Logger.log('Websocket connection to server succeeded', {}, event);
            changeCurrentWs({
                websocket: newWs,
                state: WebSocketState.CONNECTED
            });
            newWs.send(
                JSON.stringify({
                    message: 'Successfully initialised terminal at ' + new Date().toTimeString() + '.',
                    status: WebsocketCommandResponseStatus.SUCCESS,
                    command: 'init',
                    version: `${process.env.REACT_APP_VERSION}`,
                    terminal: props.terminal
                })
            );
        };

        const wsMessage = (event: MessageEvent) => {
            const wsData = JSON.parse(event.data);
            Logger.log('Received ws message', {}, wsData);

            if (wsData.command === undefined || wsData.command === null || typeof wsData.command !== 'string') {
                Logger.error('No websocket command provided.', wsData);
                newWs.send(
                    JSON.stringify({
                        message: 'No websocket command provided.',
                        status: WebsocketCommandResponseStatus.ERROR,
                        command: null,
                        terminal: props.terminal
                    })
                );
            } else if (!Object.values(WebsocketCommandType).includes(wsData.command)) {
                Logger.error('Unknown websocket command.', wsData.command);
                newWs.send(
                    JSON.stringify({
                        message: 'Unknown websocket command.',
                        status: WebsocketCommandResponseStatus.ERROR,
                        command: wsData.command,
                        terminal: props.terminal
                    })
                );
            } else {
                props.onCommand(wsData as WebsocketCommandRequest);
            }
        };

        const cleanupWs = () => {
            if (pingInterval) {
                window.clearInterval(pingInterval);
            }
            newWs.close(1000);
            newWs.onopen = null;
            newWs.onmessage = null;
            newWs.onclose = null;
            newWs.onerror = null;
        };

        const wsClose = (event?: CloseEvent) => {
            //no errors when reason is simply "Going away" this happens every 2 hours
            if (event?.reason === 'Going away') Logger.log('Websocket was closed', {}, event.reason);
            else Logger.error('Websocket was closed with an unexpected reason: ' + event?.reason, {}, event);
            cleanupWs();
            changeCurrentWs({
                websocket: null,
                state: WebSocketState.NEW
            });
        };

        //TODO wsError should also be logged
        const wsError = (event: Event) => {
            cleanupWs();
            changeCurrentWs({
                websocket: null,
                state: WebSocketState.ERROR
            });
        };

        newWs.onopen = wsOpened;
        newWs.onmessage = wsMessage;
        newWs.onclose = wsClose;
        newWs.onerror = wsError;

        return cleanupWs;
    };

    useEffect(() => {
        if (currentWs.state === WebSocketState.NEW) {
            cleanupRef.current = startWs();
        }
        if (currentWs.state === WebSocketState.ERROR) {
            // On error, wait a random amount of time between 4 - 5 minutes and then try again
            const timeout = getRandomInt(4 * 60 * 1000, 5 * 60 * 1000);
            Logger.error('There was an error with the websocket connection, try again in ms: ' + timeout);
            const timer = window.setTimeout(() => {
                changeCurrentWs({
                    websocket: null,
                    state: WebSocketState.NEW
                });
            }, timeout);
            return () => {
                window.clearTimeout(timer);
            };
        }
    }, [currentWs.state]);

    useEffect(() => {
        return () => {
            if (cleanupRef.current) {
                cleanupRef.current();
            }
        };
    }, []);

    function firstStart() {
        if (currentWs.state === WebSocketState.DISABLED) {
            changeCurrentWs({
                websocket: null,
                state: WebSocketState.NEW
            });
        }
    }

    return [currentWs.state === WebSocketState.CONNECTED ? currentWs.websocket : null, firstStart];
}

export default useManagedWebsocket;
