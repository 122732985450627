import Connection, { AccessToken, SetLightsOptions } from '../../Connection';
import { SpotLayoutItem } from '../../api/spotLayoutItems';
import { AndroidEventListener } from '../../events/android/AndroidEventListener';
import { Logger } from '../../logs/Logger';
import { TerminalHardwareObject, getHardwareDriverConfig } from '../../services/platformHardwareDriver/DriverSlotObjects';
import TerminalInterface from '../TerminalInterface';
import { TerminalSoftware } from './TerminalConnectionManager';

export default class AndroidTerminalSoftwareHelper implements Connection {
    private static instance: AndroidTerminalSoftwareHelper | null;
    private terminalApp: TerminalInterface;

    private constructor(terminalApp: TerminalInterface) {
        this.terminalApp = terminalApp;
        new AndroidEventListener(this);
    }

    public static getInstance(terminalApp: TerminalInterface): AndroidTerminalSoftwareHelper {
        if (!AndroidTerminalSoftwareHelper.instance) {
            AndroidTerminalSoftwareHelper.instance = new AndroidTerminalSoftwareHelper(terminalApp);
        }

        return AndroidTerminalSoftwareHelper.instance;
    }

    getTerminalSoftware(): TerminalSoftware {
        return TerminalSoftware.ANDROID;
    }

    getTerminalSoftwareVersion(): number {
        const version = localStorage.getItem('android');
        return version != null ? +version : 0;
    }
    init(): Promise<AccessToken | null> {
        Logger.log('should be handled by the parent');
        throw new Error('Method not implemented.');
    }
    sendSpotLayoutConfig(spotLayoutItems: SpotLayoutItem[]): void {
        Logger.log('sending layout to android');
        if (this.getTerminalSoftwareVersion() < 3) {
            Logger.log('Spotlayout config not relevant for android version: ' + this.getTerminalSoftwareVersion());
        } else {
            const config = JSON.stringify(getHardwareDriverConfig(spotLayoutItems));
            this.terminalApp.config(config);
        }
    }
    setConfiguration(config: any): void {
        Logger.log('Function setConfiguration does not apply to the android drivers.');
    }
    reConfigureTerminal(): Promise<AccessToken | null> {
        Logger.log('should be handled by the parent');
        throw new Error('Method not implemented.');
    }
    reset(): void {
        Logger.log('via android js');
        this.terminalApp.resetSystem();
    }
    openSlot(object: TerminalHardwareObject[] | null): Promise<void> {
        return new Promise((resolve, reject) => {
            Logger.log(this.getTerminalSoftwareVersion());
            if (object !== null) {
                if (this.getTerminalSoftwareVersion() >= 3.0) {
                    this.terminalApp.openSlot(JSON.stringify(object));
                } else {
                    const slotObjects: any[] = [];
                    (object as any).forEach((o: any) => {
                        o.slots.forEach((s: any) => {
                            slotObjects.push({
                                driver: o.driver,
                                driver_params: {
                                    board: o.name ? o.name : s.board,
                                    slot: s.id,
                                    ip: s.ip
                                }
                            });
                        });
                    });
                    if (this.getTerminalSoftwareVersion() >= 2.2) {
                        this.terminalApp.openSlot(JSON.stringify(slotObjects));
                    } else {
                        Logger.log('This is an old android version please update.');
                        this.terminalApp.openSlot(JSON.stringify(slotObjects[0]));
                    }
                }
                resolve();
            } else {
                reject();
            }
        });
    }
    openSlotRange(first: string, last: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.terminalApp.openSlotRange(first, last).then(() => {
                Logger.log('All slot opened');
                resolve();
            });
        });
    }
    setWebURL(url: string): void {
        if (this.getTerminalSoftwareVersion() < 4.1) {
            Logger.warn('Attempted to set webview URL on android but current android version does not support it.');
            return;
        }
        this.terminalApp.setWebURL(url);
    }
    keepAlive(): void {
        this.terminalApp.keepAlive();
    }
    resetPcb(): void {
        this.terminalApp.resetPCB();
    }
    setLights(options: SetLightsOptions): void {
        options.enableMinorLights ? this.terminalApp.minorLightOn() : this.terminalApp.minorLightOff();
    }
    setFan(on: boolean): void {
        on ? this.terminalApp.startFan() : this.terminalApp.stopFan();
    }
    resetCache(): void {
        Logger.log('Android app cache reset.');
        this.terminalApp.resetCache();
    }
    resetData(): void {
        Logger.log('Android app data reset.');
        this.terminalApp.resetData();
    }

    broadcast(data: any): void {
        this.terminalApp.broadcastWebsocket(typeof data === 'string' ? data : JSON.stringify(data));
    }
    send(id: string, data: any): void {
        this.terminalApp.sendWebsocket(id, typeof data === 'string' ? data : JSON.stringify(data));
    }
}
