import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import usePinProtection from '../hooks/usePinProtection';
import { formatTimeDelta } from '../utils';
import BaseView from './common/BaseView';

interface PinLockoutViewProps {
    title?: string;
    children?: React.ReactNode;
}

const LockoutView = (props: PinLockoutViewProps) => {
    const [nextAttemptDate] = usePinProtection();
    const [tickDate, changeTickDate] = useState(new Date());

    useEffect(() => {
        changeTickDate(new Date());
        const timerId = window.setInterval(() => {
            changeTickDate(new Date());
        }, 1000);
        return () => {
            window.clearInterval(timerId);
        };
    }, [changeTickDate]);

    const lockoutEnded = !nextAttemptDate || new Date() >= nextAttemptDate;
    const lockoutEndsInSeconds = nextAttemptDate ? Math.ceil((nextAttemptDate.getTime() - tickDate.getTime()) / 1000) : 0;

    return lockoutEnded ? (
        <>{props.children}</>
    ) : (
        <BaseView>
            <h3 className='mt-4 text-center'>
                {props.title ? (
                    props.title
                ) : (
                    <FormattedMessage
                        id='views.LockoutView.title'
                        description='The title that is shown on the lockout screen when too many failed PIN attempts'
                        defaultMessage='Too many failed PIN attempts'
                    />
                )}
            </h3>
            <div className='row justify-content-center'>
                <div className='col'>
                    <p className='text-center'>
                        <FormattedMessage
                            id='views.LockoutView.message1'
                            description='The first paragraph of the message on the lockout view'
                            defaultMessage='You got locked out because you failed too many times. You can try again in {lockoutEndsDuration}'
                            values={{
                                lockoutEndsDuration: formatTimeDelta(lockoutEndsInSeconds)
                            }}
                        />
                        .
                    </p>
                    <p className='text-center'>
                        This DistriSPOT is under constant surveillance and unauthorized attempts to access SLOTs will be reported to the police.
                    </p>
                </div>
            </div>
        </BaseView>
    );
};

export default LockoutView;
