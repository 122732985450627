import { AccessToken } from './Connection';
import { ServiceWorkerMessageTypes } from './common/enums';
import { Logger } from './logs/Logger';
import { getRandomString } from './utils';

export interface ServiceWorkerInitResult {
    available: boolean;
    requiresAuth: boolean;
    refreshFailed: boolean;
}

export class ServiceWorkerInit {
    messageChannel: null | MessageChannel = null;
    clientId = getRandomString(20);

    serviceWorkerAvailable = () => {
        return process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator;
    };

    init = async (): Promise<ServiceWorkerInitResult> => {
        if (this.serviceWorkerAvailable()) {
            Logger.log('Started initialisation of serviceworker.');

            const swRegistration = await navigator.serviceWorker.ready;

            return await new Promise<ServiceWorkerInitResult>((resolve, reject) => {
                // Send a message up to the service worker, asking for init info. The ready event only fires when one is active.
                this.messageChannel = new MessageChannel();
                Logger.debug('Send init to service worker.');

                const onError = (event: MessageEvent<any>) => {
                    Logger.error('Failed communicating with service worker', {}, event);
                    reject();
                };
                this.messageChannel.port1.addEventListener('messageerror', onError);
                this.messageChannel.port1.addEventListener(
                    'message',
                    (event) => {
                        Logger.debug('Received communication from service worker', {}, event);
                        this.messageChannel!.port1.removeEventListener('messageerror', onError);
                        resolve({
                            available: true,
                            requiresAuth: !!event.data.requiresAuth,
                            refreshFailed: !!event.data.refreshFailed
                        });
                    },
                    { once: true }
                );

                swRegistration.active!.postMessage(
                    {
                        clientId: this.clientId,
                        type: ServiceWorkerMessageTypes.INIT_SERVICE_WORKER
                    },
                    [this.messageChannel.port2]
                );
                this.messageChannel.port1.start();
            });
        } else {
            return {
                available: false,
                requiresAuth: false,
                refreshFailed: false
            };
        }
    };

    retryRefresh = async (): Promise<ServiceWorkerInitResult> => {
        if (this.serviceWorkerAvailable()) {
            return await new Promise<ServiceWorkerInitResult>((resolve, reject) => {
                // Send a message up to the service worker, asking for a new refresh.
                const onError = (event: MessageEvent<any>) => {
                    Logger.error('Failed communicating with service worker', {}, event);
                    reject();
                };
                this.messageChannel!.port1.addEventListener('messageerror', onError);
                this.messageChannel!.port1.addEventListener(
                    'message',
                    (event) => {
                        Logger.debug('Received communication from service worker', {}, event);
                        this.messageChannel!.port1.removeEventListener('messageerror', onError);
                        resolve({
                            available: true,
                            requiresAuth: !!event.data.requiresAuth,
                            refreshFailed: !!event.data.refreshFailed
                        });
                    },
                    { once: true }
                );

                this.messageChannel!.port1.postMessage({
                    clientId: this.clientId,
                    type: ServiceWorkerMessageTypes.RETRY_REFRESH
                });
            });
        } else {
            return {
                available: false,
                requiresAuth: false,
                refreshFailed: false
            };
        }
    };

    newAccessToken = async (accessToken: AccessToken): Promise<void> => {
        if (this.serviceWorkerAvailable()) {
            return await new Promise<void>((resolve, reject) => {
                const onError = (event: MessageEvent<any>) => {
                    Logger.error('Failed communicating with service worker', {}, event);
                    reject();
                };
                this.messageChannel!.port1.addEventListener('messageerror', onError);
                this.messageChannel!.port1.addEventListener(
                    'message',
                    (event) => {
                        Logger.debug('Received communication from service worker', {}, event);
                        this.messageChannel!.port1.removeEventListener('messageerror', onError);
                        resolve();
                    },
                    { once: true }
                );

                this.messageChannel!.port1.postMessage({
                    clientId: this.clientId,
                    type: ServiceWorkerMessageTypes.NEW_ACCESS_TOKEN,
                    accessToken: accessToken
                });
            });
        }
    };

    reset = async (deleteToken = true): Promise<void> => {
        if (this.serviceWorkerAvailable()) {
            return await new Promise<void>((resolve, reject) => {
                const onError = (event: MessageEvent<any>) => {
                    Logger.error('Failed communicating with service worker', {}, event);
                    reject();
                };
                this.messageChannel!.port1.addEventListener('messageerror', onError);
                this.messageChannel!.port1.addEventListener(
                    'message',
                    (event) => {
                        Logger.debug('Received communication from service worker', {}, event);
                        this.messageChannel!.port1.removeEventListener('messageerror', onError);
                        resolve();
                    },
                    { once: true }
                );

                this.messageChannel!.port1.postMessage({
                    clientId: this.clientId,
                    type: ServiceWorkerMessageTypes.RESET,
                    deleteToken: deleteToken
                });
            });
        }
    };

    serviceWorkerReset = async (deleteToken = true): Promise<void> => {
        if (this.serviceWorkerAvailable()) {
            //remove service worker
            navigator.serviceWorker
                .getRegistrations()
                .then((registrations) => {
                    registrations.forEach((registration) => registration.unregister());
                })
                .catch((err) => {
                    Logger.log('unregistering Service Worker failed: ', {}, err);
                });

            //remove caches
            caches.keys().then((keyList) => {
                keyList.forEach((key) => {
                    caches.delete(key);
                });
            });

            return await this.reset(deleteToken);
        }
    };
}
