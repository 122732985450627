import { useState } from 'react';
import { IntlShape } from 'react-intl';

import { useSlot } from '../../../api/slots';
import { Transaction } from '../../../common/transactions';
import ActionCard from '../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { LookupType } from '../../../hooks/lookup/usePerformLookup';
import useActivity from '../../../hooks/useActivity';
import usePinProtection from '../../../hooks/usePinProtection';
import { Logger } from '../../../logs/Logger';
import PinView from '../../../views/PinView';
import Pickup from '../../Pickup';
import InputLookupModal from '../../lookup/InputLookupModal';

interface PickupPinActionProps extends AllowedActionViewProps {
    title?: string;
    lending?: boolean;
}

const PickupPin = (props: PickupPinActionProps) => {
    const [, newActivity] = useActivity();
    const [, addPinAttempt] = usePinProtection();
    const [lookup, changeLookup] = useState<string | undefined>(undefined);
    const [resetLookup, changeResetLookoup] = useState<boolean>(false);
    const [transactionToPickup, changeTransactionToPickup] = useState<Transaction>();
    const { data: transactionToPickupSlot } = useSlot(transactionToPickup?.slot_id);

    const doResetLookup = () => {
        changeResetLookoup(!resetLookup);
        changeLookup(undefined);
    };

    const pickupPin = (pin: string) => {
        newActivity();
        Logger.log('Got pin in pickup: ' + pin);
        changeLookup(pin);
        addPinAttempt(false);
    };

    const endPickup = () => {
        doResetLookup();
        changeTransactionToPickup(undefined);
        props.onHome && props.onHome();
    };

    if (transactionToPickup && transactionToPickupSlot) {
        return (
            <Pickup
                onInactivity={endPickup}
                onHome={endPickup}
                transaction={transactionToPickup}
                slot={transactionToPickupSlot}
            />
        );
    } else {
        return (
            <>
                <PinView
                    title={props.title}
                    onHome={props.onHome}
                    onBack={props.onBack}
                    onInactivity={props.onHome}
                    onSubmit={pickupPin}
                    lending={props.lending}
                />
                <InputLookupModal
                    value={lookup}
                    lookupType={LookupType.PICKUP}
                    enabled={lookup !== undefined}
                    resultCallback={changeTransactionToPickup}
                    onClose={doResetLookup}
                    doReset={resetLookup}
                />
            </>
        );
    }
};

function PickupPinAction(intl: IntlShape, props: PickupPinActionProps) {
    const PickupPinAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.Pickup.pinChoiceButton',
            description: 'The pin code button on the PuDo pickup view',
            defaultMessage: 'Pin code'
        }),
        icon: <i className='bi bi-grid-3x3-gap dashboard-action-icon' />,
        view: <PickupPin {...props} />
    };

    return PickupPinAction;
}

export default PickupPinAction;
