import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Product } from '../../api/products';
import { Slot } from '../../api/slots';
import { SpotLayoutItem } from '../../api/spotLayoutItems';
import LoadingView from '../common/LoadingView';
import StockRefillProductWithSlotsView from '../elements/management/StockRefillProductWithSlotsView';

interface ManualStockRefillChangeOverviewProps {
    slotProductMap: Map<Product | null, SpotLayoutItem[]> | undefined;
    spotSlotMap: Map<SpotLayoutItem, Slot>;
    isBeingFilled: (slot: SpotLayoutItem) => boolean;
    editSelection: () => void;
}

const ManualStockRefillChangeOverview = (props: ManualStockRefillChangeOverviewProps) => {
    const [slotRowArray, changeSlotRowArray] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const result: JSX.Element[] = [];
        const itterator = props.slotProductMap?.keys();
        let key = itterator?.next().value;

        while ((key as Product) || key === null) {
            if (props.slotProductMap?.get(key) !== undefined) {
                const spotLayoutItems = props.slotProductMap.get(key)!.filter((i) => props.isBeingFilled(i));
                if (spotLayoutItems.length > 0)
                    result.push(
                        <StockRefillProductWithSlotsView
                            key={key}
                            spotSlotMap={props.spotSlotMap}
                            product={key}
                            spotLayoutItems={spotLayoutItems}
                        />
                    );
            }
            key = itterator?.next().value;
        }
        changeSlotRowArray(result);
    }, [props.slotProductMap]);
    if (props.slotProductMap === undefined && slotRowArray.length === 0) {
        return (
            <div>
                <LoadingView></LoadingView>
            </div>
        );
    }
    return (
        <div>
            {slotRowArray.length > 0 ? (
                slotRowArray
            ) : (
                <p className='title text-center'>
                    <FormattedMessage
                        id='views.vending.manualstockrefillchangeoverview.NoEligibleSlotsForRefillMessage'
                        description='The message telling the user there are no slots with a transaction that is ready for dropoff. This would mean that the fill report is empty or it was not created yet.'
                        defaultMessage="There aren't any SLOTS eligible for a stock refill. Please make sure your configuration on the platform is correct, or select the SLOTS you filled with the include SLOTS button."
                    />
                </p>
            )}
            <div className='d-flex flex-column align-items-center'>
                <button
                    className='warning-button warning-button-small mb-2'
                    onClick={props.editSelection}>
                    <FormattedMessage
                        id='views.vending.manualstockrefillchangeoverview.IncludeSlotsButton'
                        description='The button that allows the user to edit the selection of slots.'
                        defaultMessage='edit selection'
                    />
                </button>
            </div>
        </div>
    );
};

export default ManualStockRefillChangeOverview;
