import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Transaction } from '../../common/transactions';
import { ProductInstanceOwnership } from '../../workflow/nike/utils';
import { OwnedProductHighlight } from './OwnedProductHighlight';
import TransactionProductsCardElement from './TransactionProductsCardElement';
import ViewTitle from './ViewTitle';

interface ReturnsNoFinalDeterminationSelectProps {
    returnedProductInstances: ProductInstanceOwnership[];
    onSelect: (ownedProductInstance: ProductInstanceOwnership) => void;
}

export function ReturnsNoFinalDeterminationSelect(props: ReturnsNoFinalDeterminationSelectProps) {
    return (
        <>
            <ViewTitle>
                <FormattedMessage
                    id='workflow.nike.ReturnsNoFinalDeterminationSelect.title'
                    description='This title is shown when items have been found that were returned but have not been assigned a final determination (damaged,...)'
                    defaultMessage='{productsCount, plural, one {This product has been returned and requires review} other {These products have been returned and require review}}'
                    values={{
                        productsCount: props.returnedProductInstances.length
                    }}
                />
            </ViewTitle>
            {props.returnedProductInstances.map((ownedProductInstance) => {
                return (
                    <div
                        key={ownedProductInstance.productInstance.id}
                        className='align-self-stretch'>
                        <OwnedProductHighlight
                            productInstance={ownedProductInstance.productInstance}
                            onSelect={() => {
                                props.onSelect(ownedProductInstance);
                            }}
                            selectCallToAction={{
                                message: (
                                    <FormattedMessage
                                        id='views.nike.ReturnsNoFinalDeterminationSelect'
                                        description='This is the call to action text on the button of returns that need to be reviewed in the Nike workflow.'
                                        defaultMessage='Review'
                                    />
                                )
                            }}
                        />
                    </div>
                );
            })}
        </>
    );
}
