import { useIntl } from 'react-intl';

import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import HelpWorkflow from '../../../nike/HelpWorkflow';
import { NikeRole, isNikeContactData } from '../../../nike/api';

type NikeHelpWorkflowActionProps = AllowedActionViewProps;

function NikeHelpWorkflowAction(props: NikeHelpWorkflowActionProps) {
    const intl = useIntl();
    if (props.contact === undefined) return undefined;
    if (
        !isNikeContactData(props.contact.additional_data) ||
        (isNikeContactData(props.contact.additional_data) && props.contact.additional_data.role === NikeRole.DELIVERY)
    )
        return undefined;

    const nikeHelpWorkflowAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.NeedHelpAction',
            description: 'This is the help action on the Nike dashboard',
            defaultMessage: 'Need help?'
        }),
        icon: <i className='bi bi-question-circle dashboard-action-icon' />,
        view: (
            <HelpWorkflow
                loggedInContact={props.contact}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
            />
        )
    };

    return nikeHelpWorkflowAction;
}

export default NikeHelpWorkflowAction;
