import { useEffect, useState } from 'react';

import { Attribute } from '../../../api/attribute';

interface ProductAttributeSelectProps {
    attribute: Attribute;
    onSelect: (val: string) => void;
    isSelectable: (val: string, attribute: Attribute) => boolean;
    isValueVisible: (val: string, attribute: Attribute) => boolean;
}

const ProductAttributeSelect = (props: ProductAttributeSelectProps) => {
    const [selected, changeSelected] = useState<string | undefined>();

    const getSelectedStyling = (val: string): string => {
        if (val === selected) return 'selected';
        return '';
    };

    const Select = (val: string): void => {
        if (selected === val) {
            changeSelected(undefined);
        } else changeSelected(val);
        props.onSelect(val);
    };

    useEffect(() => {
        if (selected === undefined) {
            const validValues = props.attribute.values.filter((value) => {
                if (props.isSelectable(value, props.attribute) && props.isValueVisible(value, props.attribute)) return value;
            });
            if (validValues.length === 1) Select(validValues[0]);
        }
    });

    return (
        <div>
            <p>{props.attribute.name}</p>
            <span className='shop-attr-value-container'>
                {props.attribute.values.map((val) => {
                    if (props.isValueVisible(val, props.attribute)) {
                        if (props.isSelectable(val, props.attribute)) {
                            return (
                                <div
                                    key={props.attribute.id + val}
                                    className={getSelectedStyling(val)}
                                    onClick={() => Select(val)}>
                                    {val}
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={props.attribute.id + val}
                                    className='disabled'>
                                    {val}
                                </div>
                            );
                        }
                    }
                })}
            </span>
        </div>
    );
};

export default ProductAttributeSelect;
