import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../api/contacts';
import { useAppSelector } from '../../hooks/redux';
import useOpenRakinda from '../../hooks/useOpenRakinda';
import { diffFound } from './WarehouseWorkflow';

interface Props {
    show: boolean;
    onExit: () => void;
    handleClose: () => void;
    selectedContact?: Contact;
}

export default function ExitModal(props: Props) {
    const difference = useAppSelector((state) => state.scalesWeight.difference);
    const { openRakinda, availableAccessControls } = useOpenRakinda({ contact: props.selectedContact });

    useEffect(() => {
        if (props.show === true) {
            const timeout = setTimeout(() => {
                props.handleClose();
                clearTimeout(timeout);
            }, 10 * 1000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [props.show]);

    return (
        <Modal
            centered
            size='lg'
            animation={false}
            show={props.show}
            onHide={props.handleClose}>
            <Modal.Body>
                <p className='text-center'>
                    {diffFound(difference) ? (
                        <FormattedMessage
                            id='WarehouseWorkflow.checkout.ExitModal.UnregisteredChangesMessage'
                            description='The message that is shown in the modal on checkout when the user did not register all changes.'
                            defaultMessage='There are still unregistered changes, are you sure you want to leave.'
                        />
                    ) : (
                        <FormattedMessage
                            id='WarehouseWorkflow.checkout.ExitModal.ChangesSavedMessage'
                            description='The message that is shown in the modal on checkout when the users changes are saved.'
                            defaultMessage='Any changes you have made were saved. You can exit safely now.'
                        />
                    )}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                    <button
                        className='secondary-button'
                        onClick={() => {
                            props.handleClose();
                        }}>
                        <FormattedMessage
                            id='WarehouseWorkflow.checkout.ExitModal.Cancel'
                            description='The button for the user to cancel exiting the warehouse.'
                            defaultMessage='Cancel'
                        />
                    </button>
                    {availableAccessControls.map((ac) => {
                        return (
                            <button
                                key={ac.uuid}
                                className='primary-button'
                                onClick={() => {
                                    openRakinda(ac, props.selectedContact);
                                    props.onExit();
                                }}>
                                <small>{ac.name}</small>
                            </button>
                        );
                    })}
                </div>
            </Modal.Footer>
        </Modal>
    );
}
