import { FormattedMessage } from 'react-intl';

import { StockFill } from '../../api/stockFills';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import StockFillReportSelectView from '../elements/stockFillReports/StockFillReportSelectView';

interface SelectStockFillReportProps {
    allReports: StockFill[];
    saveReportNumber: (report: StockFill) => void;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

const SelectStockFillReport = (props: SelectStockFillReportProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <div className='d-flex flex-column align-items-center'>
                <h1 className='d-flex text-center title mb-2'>
                    <FormattedMessage
                        id='views.EnterReportNumber.Title'
                        description='The title on the EnterReportNumber screen.'
                        defaultMessage='Please select the correct fill report if available.'
                    />
                </h1>
                <div className='w-100'>
                    <StockFillReportSelectView
                        onSelect={props.saveReportNumber}
                        stockFillReports={props.allReports}
                    />
                </div>
            </div>
        </BaseView>
    );
};

export default SelectStockFillReport;
