export enum ServiceWorkerMessageTypes {
    INIT_SERVICE_WORKER = 'init_service_worker',
    INIT_SERVICE_WORKER_RESPONSE = 'init_service_worker_response',
    NEW_ACCESS_TOKEN = 'new_access_token',
    NEW_ACCESS_TOKEN_RESPONSE = 'new_access_token_response',
    RESET = 'reset',
    RESET_RESPONSE = 'reset_response',
    RETRY_REFRESH = 'retry_refresh',
    RETRY_REFRESH_RESPONSE = 'retry_refresh_response'
}
