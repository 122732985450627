import Connection from '../../Connection';
import { Logger } from '../../logs/Logger';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest } from '../useManagedWebsocket';

export class WebsocketSetLightsCommand extends WebsocketCommand {
    data: {
        value: boolean;
        spot?: number;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        if (
            request.value === undefined ||
            typeof request.value !== 'boolean' ||
            (request.spot !== null && request.spot !== undefined && typeof request.spot !== 'number')
        )
            throw new Error('Incorect information passed.');

        this.data = {
            value: request.value,
            spot: request.spot === null ? undefined : request.spot
        };
    }

    public async execute(connection: Connection): Promise<WebsocketCommandResponse> {
        Logger.log(`Setting lights ${this.data.value ? 'on' : 'off'} for spot with id: ${this.data.spot}.`, { spot: this.data.spot });
        connection.setLights({ enableMinorLights: this.data.value });
        return { success: true };
    }
}
