import WindowsTerminalVariables from '../terminal/WindowsTerminalVariables';

const useGetWindowsCommunicationObject = (): WindowsTerminalVariables | null => {
    try {
        // windows drivers
        // eslint-disable-next-line no-undef
        return chrome.webview.hostObjects as WindowsTerminalVariables;
    } catch {
        // not windows
        return null;
    }
};

export default useGetWindowsCommunicationObject;
