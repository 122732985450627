import { useContext, useEffect } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { useMutateCreatePushNotification } from '../../api/pubSub';
import { PlatformHardwareDriver, useSpotLayoutItems } from '../../api/spotLayoutItems';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { useTransactions } from '../../api/transactions';
import { TransactionStatus } from '../../common/transactions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { SliceStatus } from '../../redux/ReduxCommonVariables';
import { errorSent, init, updateCurrent } from '../../redux/ScalesWeightSlice';
import { isWeightChangeEvent } from '../../scales/proton/requests/WeightChangeRequest';
import useTerminalEventHandler from '../../terminal/useTerminalEventHandler';

interface Props {
    contacts: Contact[];
}

export default function useScalesWeightUpdates(props: Props) {
    const dispatch = useAppDispatch();
    const createPushNotification = useMutateCreatePushNotification();
    const scalesWeightState = useAppSelector((state) => state.scalesWeight);
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: transactions } = useTransactions(
        {
            spot: spot?.id,
            status: TransactionStatus.READY_FOR_PICKUP
        },
        { enabled: !!spot }
    );

    useEffect(() => {
        const notSent = scalesWeightState.errors.filter((e) => e.sent === false);
        if (notSent.length > 0 && spot != undefined) {
            notSent.forEach((error) => {
                createPushNotification.mutate({
                    body: {
                        identifier: error.resolved ? 'tlp_scale_count_mismatch_resolved' : 'tlp_scale_count_mismatch',
                        value: { spot_layout_item: error.data.spotLayoutItemId },
                        account: spot.account
                    }
                });
            });
            dispatch(errorSent());
        }
    }, [scalesWeightState.errors]);

    useEffect(() => {
        if (spotLayoutItems && transactions && scalesWeightState.status === SliceStatus.INIT) {
            const initial: { [spotLayoutItemId: string]: number } = {};
            spotLayoutItems.forEach((item) => {
                const trs = transactions.filter((tr) => tr.slot_id === item.slot);
                initial[item.id] = trs.length;
            });
            dispatch(init(initial));
        }
    }, [spotLayoutItems, transactions, scalesWeightState.status]);

    const { protonEvent } = useTerminalEventHandler();

    useEffect(() => {
        if (protonEvent && isWeightChangeEvent(protonEvent)) {
            const payload: { spotLayoutItemId: string; currentCount: number; aditional_data?: any }[] = [];
            protonEvent.payload.forEach((update) => {
                if (update.state.weightStable) {
                    const spotLayoutItem = spotLayoutItems?.find((item) => {
                        const validDriver = item.hardware_driver_options.data.find((o) => o.driver === PlatformHardwareDriver.PROTON);
                        return validDriver !== undefined && validDriver.config.clusterURI === update.clusterUri;
                    });

                    if (spotLayoutItem) {
                        payload.push({
                            spotLayoutItemId: spotLayoutItem.id,
                            currentCount: update.state.count,
                            aditional_data: { contacts: props.contacts, date: Date.now(), event: protonEvent }
                        });
                    }
                }
            });
            dispatch(updateCurrent(payload));
        }
    }, [protonEvent]);
}
