import { useState } from 'react';
import { IntlShape } from 'react-intl';

import { useSlot } from '../../../api/slots';
import { Transaction } from '../../../common/transactions';
import ActionCard from '../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { LookupType } from '../../../hooks/lookup/usePerformLookup';
import useActivity from '../../../hooks/useActivity';
import usePinProtection from '../../../hooks/usePinProtection';
import { Logger } from '../../../logs/Logger';
import PinView from '../../../views/PinView';
import LoadingView from '../../../views/common/LoadingView';
import JustDrop from '../../JustDrop';
import InputLookupModal from '../../lookup/InputLookupModal';

interface DropoffPinActionProps extends AllowedActionViewProps {
    title?: string;
    lending?: boolean;
}

const DropoffPin = (props: DropoffPinActionProps) => {
    const [, newActivity] = useActivity();
    const [, addPinAttempt] = usePinProtection();
    const [lookup, changeLookup] = useState<string | undefined>(undefined);
    const [resetLookup, changeResetLookoup] = useState<boolean>(false);

    const [transactionToDropoff, changeTransactionToDropoff] = useState<Transaction>();
    const { data: transactionToDropoffSlot } = useSlot(transactionToDropoff?.slot_id);

    const doResetLookup = () => {
        changeResetLookoup(!resetLookup);
        changeLookup(undefined);
    };

    const dropoffPin = (pin: string) => {
        newActivity();
        Logger.log('Got pin in dropoff: ' + pin);
        changeLookup(pin);
        addPinAttempt(false);
    };

    const endDropoff = (back?: boolean) => {
        doResetLookup();
        changeTransactionToDropoff(undefined);
        !back && props.onHome && props.onHome();
    };

    if (transactionToDropoff) {
        if (transactionToDropoffSlot === undefined && transactionToDropoff.slot_id !== undefined) {
            return (
                <LoadingView
                    onHome={props.onHome}
                    onInactivity={props.onHome}
                />
            );
        } else {
            return (
                <JustDrop
                    contact={props.contact}
                    onInactivity={endDropoff}
                    onHome={endDropoff}
                    onBack={() => {
                        endDropoff(true);
                    }}
                    slot={transactionToDropoffSlot}
                    transaction={transactionToDropoff}
                />
            );
        }
    } else {
        return (
            <>
                <PinView
                    title={props.title}
                    onHome={props.onHome}
                    onBack={props.onBack}
                    onInactivity={props.onHome}
                    onSubmit={dropoffPin}
                    lending={props.lending}
                />
                <InputLookupModal
                    value={lookup}
                    lookupType={LookupType.DROPOFF}
                    enabled={lookup !== undefined}
                    resultCallback={changeTransactionToDropoff}
                    onClose={doResetLookup}
                    doReset={resetLookup}
                />
            </>
        );
    }
};

function DropoffPinAction(intl: IntlShape, props: DropoffPinActionProps) {
    const DropoffPinAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'views.DropoffWorkflow.pinChoiceButton',
            description: 'The pin code button on the PuDo dropoff view',
            defaultMessage: 'Pin code'
        }),
        icon: <i className='bi bi-grid-3x3-gap dashboard-action-icon' />,
        view: <DropoffPin {...props} />
    };

    return DropoffPinAction;
}

export default DropoffPinAction;
