import { useContext } from 'react';
import { ImQrcode } from 'react-icons/im';
import { useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { TerminalWorkflowType } from '../../../api/terminals';
import ActionCard from '../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { isNikeDeliveryPerson } from '../../nike/permissions';

type QRHelpActionProps = AllowedActionViewProps;

function QRHelpAction(props: QRHelpActionProps) {
    const intl = useIntl();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);

    if (terminal?.workflow === TerminalWorkflowType.WAREHOUSE) return undefined;

    const QRCodeAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.AuthenticatedContactWorkflow.QRCodeAction',
            description: 'This is the QR-code action for AuthenticatedContactWorkflow',
            defaultMessage: 'Scan code'
        }),
        icon: (
            <ImQrcode
                className='dashboard-action-icon'
                size={264}
            />
        )
    };

    if (terminal?.workflow !== TerminalWorkflowType.VENDING && terminal?.workflow !== TerminalWorkflowType.IMES_DEXIS_NIKE) {
        return QRCodeAction;
    } else if (props.contact && isNikeDeliveryPerson(props.contact)) {
        return QRCodeAction;
    }

    return undefined;
}

export default QRHelpAction;
