import { createHash } from 'crypto';

import LoggingDatabase from './LoggingDatabase';
import { FilterData, LogLevel, TerminalLog, TerminalLogViewModel } from './TerminalLog';

export class Logger {
    private static extensiveLoggingEnabled = false;

    static setEnableExtensiveLogging(value: boolean) {
        this.extensiveLoggingEnabled = value;
        this.log(`Extensive logging is ${value ? 'enabled' : 'disabled'}.`);
    }

    static async debug(message: string | any, filter?: FilterData, ...data: any) {
        console.debug(message, ...data, this.getSource());
        if (this.extensiveLoggingEnabled === true) this.save(message, LogLevel.DEBUG, filter, ...data);
    }
    static async log(message: string | any, filter?: FilterData, ...data: any) {
        console.log(message, ...data, this.getSource());
        this.save(message, LogLevel.INFO, filter, ...data);
    }
    static async warn(message: string | any, filter?: FilterData, ...data: any) {
        console.warn(message, ...data, this.getSource());
        this.save(message, LogLevel.WARN, filter, ...data);
    }
    static async error(message: string | any, filter?: FilterData, ...data: any) {
        console.error(message, ...data, this.getSource());
        this.save(message, LogLevel.ERROR, filter, ...data);
    }

    private static async save(message: string | any, level: LogLevel, filter?: FilterData, ...data: any) {
        let actualMessage = '';
        if (message instanceof Error) {
            actualMessage = message.message;
        }

        const log =
            typeof message === 'string'
                ? new TerminalLog(message, level, { ...filter }, data && JSON.stringify({ ...data }))
                : new TerminalLog(actualMessage, level, { ...filter }, data && JSON.stringify([message, { ...data }]));

        this.saveLog(log).then((success) => {
            if (!success) console.log('log was not saved');
        });
    }

    private static async saveLog(log: TerminalLog): Promise<boolean> {
        return await LoggingDatabase.saveLog(log);
    }
    static async getLogs(): Promise<TerminalLogViewModel[] | undefined> {
        const logs = await LoggingDatabase.getLogs();
        return logs?.map(TerminalLog.parseToViewModel);
    }
    static async removeById(ids: number[]): Promise<void> {
        return LoggingDatabase.removeById(ids);
    }

    static generateHash(value: string): string {
        return createHash('sha256').update(value).digest('hex');
    }

    private static getSource() {
        try {
            return new Error().stack?.split('\n')[2].trim() || 'Unknown';
        } catch {
            return undefined;
        }
    }
}
