import React from 'react';

import InformationCard from '../domain/InformationCard';
import CallToActionElement from './CallToActionElement';

export default function HighlightCardElement(props: InformationCard) {
    return (
        <div className='card my-4'>
            <div className='card-body'>
                {props.title ? <h4>{props.title}</h4> : undefined}
                <table className='table table-borderless mb-0'>
                    <thead>
                        <tr>
                            {props.informationElements.map((information, index) => {
                                return <th key={index}>{information.header}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {props.informationElements.map((information, index) => {
                                return <td key={index}>{information.value}</td>;
                            })}
                        </tr>
                        {props.callToActions.length > 0 ? (
                            <tr>
                                <td colSpan={2}>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        {props.callToActions.map((callToAction, index) => {
                                            return (
                                                <CallToActionElement
                                                    key={index}
                                                    {...callToAction}
                                                />
                                            );
                                        })}
                                    </div>
                                </td>
                            </tr>
                        ) : undefined}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
