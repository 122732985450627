import { IndexedDbStores, getStoreConnection } from '../services/indexedDb/IndexedDB';
import { TerminalLog } from './TerminalLog';

export default class LoggingDatabase {
    static saveLog = async (log: TerminalLog): Promise<boolean> => {
        const store = await getStoreConnection(IndexedDbStores.TERMINAL_LOGS, 'readwrite');

        const request = store.put(log);

        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                if (request.result !== undefined) resolve(true);
                else resolve(false);
            };

            request.onerror = () => {
                resolve(false);
            };
        });
    };

    static getLogs = async (): Promise<TerminalLog[] | undefined> => {
        const store = await getStoreConnection(IndexedDbStores.TERMINAL_LOGS, 'readonly');
        const request = store.getAll();

        return new Promise((resolve) => {
            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = () => {
                resolve(undefined);
            };
        });
    };

    static removeById = async (ids: number[]): Promise<void> => {
        const store = await getStoreConnection(IndexedDbStores.TERMINAL_LOGS, 'readwrite');
        ids.map((id) => store.delete(id));
    };
}
