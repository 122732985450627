import { useContext, useEffect, useState } from 'react';

import { TerminalContext } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { TerminalWorkflowType } from '../../api/terminals';
import { Transaction } from '../../common/transactions';
import { Logger } from '../../logs/Logger';
import usePinProtection from '../usePinProtection';
import useContactAuthentication from './authentication/useContactAuthentication';
import { LookupFlags, useTransactionsLookup } from './transactions/actions';

export enum ValueInputType {
    SCANNER = 'scanner',
    PIN = 'pin'
}

export enum LookupType {
    AUTHENTICATION = 'auth',
    DROPOFF = 'dropoff',
    PICKUP = 'pickup'
}

export interface lookupProps {
    value: string;
    valueInputType: ValueInputType;
    lookupType?: LookupType;
}

const usePerformLookup = () => {
    const { terminal } = useContext(TerminalContext);
    const [, addPinAttempt] = usePinProtection();

    const [authLookup, changeAuthLookup] = useState<string | null>(null);
    const {
        isInProgress: authIsInProgress,
        isError: authIsError,
        result: authContact,
        state: authState
    } = useContactAuthentication({ lookupValue: authLookup });
    const { lookup, reset, isError, isInProgress, isIdle, isSuccess } = useTransactionsLookup();

    //results
    const [contact, changeContact] = useState<Contact | undefined | null>(null);
    const [transaction, changeTransaction] = useState<Transaction | null>(null);
    const [transactionType, changeTransactionType] = useState<LookupType | null>(null);

    const resetValues = () => {
        changeContact(null);
        changeAuthLookup(null);
        changeTransaction(null);
    };

    useEffect(() => {
        if (authContact !== null) {
            changeContact(authContact);
            changeAuthLookup(null);
        }
    }, [authContact]);

    const loading = !(
        !authIsInProgress &&
        !isInProgress &&
        (authState === 'idle' || authState === 'success' || authState === 'error') &&
        (isIdle || isSuccess || isError)
    );
    let error: string | null = null;
    if (contact === null && authLookup === null && transaction === null) {
        error = null;
    } else if (isError && authIsError) {
        error = 'Both transaction and authentication lookup failed!';
    } else if (authIsError) {
        error = 'Authentication lookup failed!';
    } else if (isError) {
        error = 'Transaction lookup failed!';
    }

    const performLookup = (props: lookupProps): void => {
        resetValues();
        const flags: LookupFlags | null = detectLookupValueType(props.value, props.valueInputType, terminal?.workflow, props.lookupType);
        if (props.value !== '') {
            Logger.log('Looking up: "' + props.value + '", Flags: ' + flags);

            if (
                flags == LookupFlags.ALL ||
                flags == (LookupFlags.DROPOFF_USER_BADGE | LookupFlags.PICKUP_USER_BADGE) ||
                (props.lookupType && props.lookupType == LookupType.AUTHENTICATION)
            ) {
                Logger.log('Auth lookup enabled.');
                const formatedValue = props.value.endsWith(' 90 00') ? props.value.slice(0, props.value.length - 6) : props.value;
                formatedValue.trim();
                changeAuthLookup(formatedValue);
            }

            if (props.value.endsWith(' 90 00')) {
                //assume this is always authentication
                return;
            }

            const lookupFlags = flags !== null ? flags : LookupFlags.ALL;
            Logger.log('Transaction lookup enabled.');
            lookup(props.value, {
                lookup: lookupFlags,
                onSuccess: (transactionResults) => {
                    if (transactionResults.length === 0) {
                        Logger.log('No result');
                        reset();
                        return; // TODO: If open dropoff enabled then give the possibility to dropoff anyway
                    }
                    addPinAttempt(true);
                    if (transactionResults.length > 1) {
                        // TODO: Add additional security request
                        Logger.log('Multiple transactions found that match the value', {}, props.value, transactionResults);
                    }
                    const transactionResult = transactionResults[0]; //TODO: this is where the user is redirected to get his/her first pickup but this is no longer needed with the contact auth
                    const transaction = transactionResult.transaction;

                    if (
                        (transactionResult.lookup &
                            (LookupFlags.PICKUP_QR |
                                LookupFlags.REMOVAL_CODE |
                                LookupFlags.REMOVAL_QR |
                                LookupFlags.PICKUP_CODE |
                                LookupFlags.DROPOFF_QR |
                                LookupFlags.DROPOFF_CODE |
                                LookupFlags.DROPOFF_USER_BADGE |
                                LookupFlags.PICKUP_USER_BADGE)) ===
                        0
                    ) {
                        Logger.warn('Unexpected lookup type match', { transaction: transactionResult.transaction.id }, transactionResult);
                        return;
                    }

                    if (
                        (transactionResult.lookup & LookupFlags.DROPOFF_CODE) === LookupFlags.DROPOFF_CODE ||
                        (transactionResult.lookup & LookupFlags.DROPOFF_QR) === LookupFlags.DROPOFF_QR ||
                        (transactionResult.lookup & LookupFlags.DROPOFF_USER_BADGE) === LookupFlags.DROPOFF_USER_BADGE
                    ) {
                        changeTransactionType(LookupType.DROPOFF);
                        changeTransaction(transaction);
                        reset();
                    } else if (
                        (transactionResult.lookup & LookupFlags.PICKUP_CODE) === LookupFlags.PICKUP_CODE ||
                        (transactionResult.lookup & LookupFlags.PICKUP_QR) === LookupFlags.PICKUP_QR ||
                        (transactionResult.lookup & LookupFlags.REMOVAL_CODE) === LookupFlags.REMOVAL_CODE ||
                        (transactionResult.lookup & LookupFlags.REMOVAL_QR) === LookupFlags.REMOVAL_QR ||
                        (transactionResult.lookup & LookupFlags.PICKUP_USER_BADGE) === LookupFlags.PICKUP_USER_BADGE
                    ) {
                        changeTransactionType(LookupType.PICKUP);
                        changeTransaction(transaction);
                        reset();
                    }
                }
            });
        }
    };

    return [contact, transaction, transactionType, loading, error, performLookup, resetValues] as const;
};

export default usePerformLookup;

const detectLookupValueType = (
    lookupValue: string,
    inputType: ValueInputType,
    workflow?: TerminalWorkflowType,
    lookupType?: LookupType
): LookupFlags | null => {
    let possibleLookupTypes: LookupFlags | null = null;
    // Try to detect the lookup value type
    if (lookupValue.endsWith(' 90 00') && inputType !== ValueInputType.PIN) {
        possibleLookupTypes = LookupFlags.DROPOFF_USER_BADGE | LookupFlags.PICKUP_USER_BADGE;
    } else {
        if (lookupValue.length === 4 && /^\d+$/.test(lookupValue)) {
            if (lookupType) {
                if (lookupType === LookupType.DROPOFF) {
                    possibleLookupTypes = LookupFlags.DROPOFF_CODE;
                } else {
                    possibleLookupTypes = LookupFlags.PICKUP_CODE | LookupFlags.REMOVAL_CODE;
                }
            } else {
                possibleLookupTypes = LookupFlags.PICKUP_CODE | LookupFlags.DROPOFF_CODE | LookupFlags.REMOVAL_CODE;
            }
        } else if (inputType === ValueInputType.SCANNER) {
            if (lookupValue.includes(`/api/qr/pickup/`)) {
                possibleLookupTypes = LookupFlags.PICKUP_QR;
            } else if (lookupValue.includes(`/api/qr/dropoff/`)) {
                possibleLookupTypes = LookupFlags.DROPOFF_QR;
            } else if (lookupValue.includes(`/keys/`)) {
                possibleLookupTypes = LookupFlags.DROPOFF_USER_BADGE | LookupFlags.PICKUP_USER_BADGE;
            } else {
                possibleLookupTypes = LookupFlags.ALL;
            }
        }
    }
    return possibleLookupTypes;
};
