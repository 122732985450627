import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';

import { TerminalContext } from '../../TerminalInit';
import { ApiViewSet, apiPagination } from '../baseApi';
import { ApiQueryParams, queryParamsToCacheKeys } from '../baseQueryParams';
import { ApiError, FetchOptions, putApi } from '../utils';
import { IOT_DEVICE_TYPE } from './common';

enum ACCESS_CONTROL_MODEL {
    rakinda_RD009 = 'rakinda_RD009'
}

export interface AccessControl {
    uuid: string;
    name: string;
    type: IOT_DEVICE_TYPE.ACCESS_CONTROL;
    model: ACCESS_CONTROL_MODEL;
    serial_number: number;
    request_data: unknown;
    additional_data: unknown;
    last_active_state: unknown;
    last_active_time: Date;
    last_action: unknown;
    last_action_time: Date;
}

export enum AccessControlsQueryParams {
    ACCOUNT = 'account',
    SPOT = 'spot',
    PAGE = 'page',
    PAGES_SIZE = 'page_size',
    SEARCH = 'search'
}
const accessControlsViewSet: ApiViewSet = {
    baseName: 'services/iot/tlp_acsy'
};
const defaultConfig = {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 3,
    retryDelay: 5000
};

export interface AcccessControlsResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: AccessControl[];
}

interface MutateAccessControlOpenVariables {
    body: {
        contact?: string;
        spot: string;
    };
    accessControl: AccessControl;
}

function fetchAccessControlsApi(queryParams?: ApiQueryParams<AccessControlsQueryParams>, fetchOptions?: FetchOptions): () => Promise<AccessControl[]> {
    return apiPagination<AccessControl[], AccessControlsQueryParams>(accessControlsViewSet, queryParams, fetchOptions);
}

export function useAccessControls(spot?: string, queryOptions?: UseQueryOptions<AccessControl[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<AccessControl[]>(
        ['accessControls', queryParamsToCacheKeys(AccessControlsQueryParams, { spot })],
        fetchAccessControlsApi({ spot }, fetchOptions),
        config
    );
}

export async function prefetchAccessControls(
    queryClient: QueryClient,
    spot: string,
    options?: FetchQueryOptions<AccessControl[]>,
    fetchOptions?: FetchOptions
) {
    const config = {
        ...defaultConfig,
        ...options
    };

    await queryClient.fetchQuery(['accessControls'], fetchAccessControlsApi({ spot }, fetchOptions), config);
}

function accessControlOpen(options?: FetchOptions): (vairables: MutateAccessControlOpenVariables) => Promise<void> {
    return async (variables: MutateAccessControlOpenVariables): Promise<void> => {
        const response = await putApi(
            `/services/iot/tlp_acsy/${variables.accessControl.model}/${variables.accessControl.uuid}/open_door/`,
            variables.body,
            options
        );
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error opening access control.');
            }
            throw new ApiError('Error opening access control.', json);
        }
        return await response.json();
    };
}

export function useMutateAccessControlOpen(options?: UseMutationOptions<void, unknown, MutateAccessControlOpenVariables>, fetchOptions?: FetchOptions) {
    const config: UseMutationOptions<void, unknown, MutateAccessControlOpenVariables> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(accessControlOpen(fetchOptions), config);
}
