import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, UseQueryOptions, useQuery } from 'react-query';

import { TerminalContext } from '../TerminalInit';
import { ApiViewSet, apiList } from './baseApi';
import { ApiQueryParams, queryParamsToCacheKeys } from './baseQueryParams';
import { FetchOptions } from './utils';

export enum AttributeType {
    MULTI = 'mutli_choice',
    DROP_DOWN = 'drop_down',
    SINGLE_CHOICE = 'single_choice'
}

export interface Attribute {
    id: number;
    url: string;
    name: string;
    type: AttributeType;
    values: string[];
    account: number;
    category: number;
}

const defaultConfig = {
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity
};

const attributesViewSet: ApiViewSet = {
    baseName: 'attributes'
};

enum AttributesQueryParams {
    CATEGORY = 'category',
    NAME = 'name',
    VALUES = 'values',
    ACCOUNT = 'account'
}

export interface AttributeOptions {
    category?: string;
    name?: string;
    values?: string[];
    account?: number;
}

function fetchAttributesApi(queryParams?: ApiQueryParams<AttributesQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Attribute[]> {
    return apiList<Attribute, AttributesQueryParams>(attributesViewSet, queryParams, fetchOptions);
}

export function useAttributes(options?: AttributeOptions, queryOptions?: UseQueryOptions<Attribute[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    const apiOptions = {
        name: options?.name,
        category: options?.category,
        account: options?.account,
        values: options?.values
    } as ApiQueryParams<AttributesQueryParams>;
    const queryParams: ApiQueryParams<AttributesQueryParams> = {
        ...apiOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Attribute[]>(
        ['attributes', queryParamsToCacheKeys(AttributesQueryParams, queryParams)],
        fetchAttributesApi(queryParams, fetchOptions),
        config
    );
}

export async function prefetchAttributes(queryClient: QueryClient, options?: FetchQueryOptions<Attribute[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...options
    };

    await queryClient.fetchQuery(['attributes'], fetchAttributesApi(null, fetchOptions), config);
}
