import { SpotLayoutItem } from '../../../api/spotLayoutItems';
import useOpenSlot from '../../../hooks/useOpenSlot';
import { canOpen } from '../../../services/platformHardwareDriver/DriverSlotObjects';

interface Props {
    spotLayoutItem?: SpotLayoutItem;
}

export default function OpenSlotButton(props: Props) {
    const [openSlot] = useOpenSlot();

    if (props.spotLayoutItem === undefined || !canOpen(props.spotLayoutItem)) {
        return <></>;
    }

    return (
        <button
            onClick={() => {
                openSlot({ spotLayoutItem: props.spotLayoutItem! });
            }}
            className='success-button btn-lg'>
            Open SLOT
        </button>
    );
}
