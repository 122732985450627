import { ContactGroup } from '../../../api/contactGroups';

const ContactGroupListElement = (contactGroup: ContactGroup, onSelect?: (contactGroup: ContactGroup) => void): JSX.Element => {
    return (
        <tr
            onClick={() => {
                if (onSelect) onSelect(contactGroup);
            }}>
            <td
                colSpan={2}
                className='py-2'
                style={{ verticalAlign: 'middle' }}>
                {contactGroup.name}
            </td>
        </tr>
    );
};

export default ContactGroupListElement;
