import { Data } from 'client-oauth2';
import { useState } from 'react';

import Connection, { AccessToken, SetLightsOptions } from '../Connection';
import { SpotLayoutItem } from '../api/spotLayoutItems';
import { refreshOAuth2Token } from '../common/utils';
import useLocalStorage from '../hooks/useLocalStorage';
import useSelectTerminalDriver from '../hooks/useSelectTeminalDriver';
import { Logger } from '../logs/Logger';
import { TerminalSoftware } from '../terminal/terminalSoftwareHelpers/TerminalConnectionManager';
import { sleep } from '../utils';
import { LibAccessToken, getTokenFromState, oauth2, redirectUri } from './OAuth2';

function dec2hex(dec: number) {
    return dec < 10 ? '0' + String(dec) : dec.toString(16);
}

function randomString(len?: number) {
    const crypto = window.crypto;
    const arr = new Uint8Array((len || 40) / 2);
    crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
}

interface AccessTokenStored {
    expires?: Date | null;
    result?: Data;
}

const useDemoLib = (): Connection => {
    const app = useSelectTerminalDriver();

    const [oauth2Result, changeOauth2Result] = useLocalStorage<AccessTokenStored | null>('oauth2Result', null);
    const [oauth2State, changeOauth2State] = useLocalStorage<string | null>('oauth2_state', null);

    const [demoLib] = useState(() => {
        const connection: Connection = {
            getTerminalSoftware: function (): TerminalSoftware {
                return TerminalSoftware.UNKNOWN;
            },
            getTerminalSoftwareVersion(): number {
                return 4.0;
            },
            async init(): Promise<AccessToken | null> {
                Logger.log('DemoLib: init');
                if (oauth2State) {
                    changeOauth2State(null);
                    const token = (await getTokenFromState(oauth2State)) as LibAccessToken;
                    Logger.log('Got token from state', {}, token.accessToken);
                    if (token) {
                        changeOauth2Result({
                            expires: token.expires,
                            result: token.data
                        });
                        window.history.replaceState({}, '', redirectUri);
                        return {
                            accessToken: token.accessToken,
                            expires: token.expires,
                            refreshToken: token.refreshToken,
                            tokenType: token.tokenType
                        };
                    }
                }

                if (oauth2Result && oauth2Result.result) {
                    let token = oauth2.createToken(oauth2Result.result) as LibAccessToken;
                    if (oauth2Result.expires) {
                        token.expiresIn(new Date(oauth2Result.expires));
                        if (token.expired()) {
                            let refreshResult;
                            try {
                                refreshResult = await refreshOAuth2Token(token.refreshToken);
                            } catch (error) {
                                Logger.warn('Unable to refresh access token.');
                                return null;
                            }
                            token = oauth2.createToken(refreshResult) as LibAccessToken;
                            const accessToken = token as AccessToken;
                            if (token.accessToken) {
                                changeOauth2Result({
                                    expires: accessToken.expires,
                                    result: token.data
                                });
                            }
                        }
                    }
                    return {
                        accessToken: token.accessToken,
                        expires: token.expires,
                        refreshToken: token.refreshToken,
                        tokenType: token.tokenType
                    };
                }

                return null;
            },

            setConfiguration(doorOpenTimeInSeconds: any) {
                Logger.log(doorOpenTimeInSeconds);
            },
            sendSpotLayoutConfig(spotLayoutItems: SpotLayoutItem[]) {
                Logger.log('config not relevant for demo');
            },
            async reConfigureTerminal(): Promise<AccessToken | null> {
                Logger.log('DemoLib: newAccessToken');
                const state = randomString();
                changeOauth2State(state);
                changeOauth2Result(null);

                window.location.href = oauth2.code.getUri({ state: state });
                return null;
            },
            async reset(): Promise<void> {
                Logger.log('DemoLib: Reset');
                changeOauth2State(null);
                changeOauth2Result(null);
            },

            openSlot(slotObj: any): Promise<void> {
                return new Promise((resolve, reject) => {
                    if (app) app.openSlot(JSON.stringify(slotObj));
                    Logger.log('DemoLib: SLOT opened');
                    resolve();
                });
            },
            openSlotRange(first: string, last: string): Promise<void> {
                return new Promise((resolve, reject) => {
                    sleep(3000).then(() => {
                        Logger.log('DemoLib: SLOTs opened');
                        resolve();
                    });
                }); // Add a small delay to simulate slot opening
            },
            keepAlive(): void {
                Logger.log('Keep alive send');
            },
            async resetPcb(): Promise<void> {
                Logger.log('Reset PCB (refresh)');
                window.location.href = '/';
            },
            async setLights(options: SetLightsOptions): Promise<void> {
                Logger.log('Settings lights', {}, options);
            },
            resetCache(): void {
                Logger.log('Android app cache reset.');
            },
            resetData(): void {
                Logger.log('Android app data reset.');
            },
            broadcast(data) {
                /*const websocket = new WebSocket('ws://localhost:8080/');
                websocket.send(data);*/
            },
            send(id, data) {
                /*const websocket = new WebSocket('ws://localhost:8080/');
                websocket.send(data);*/
            },
            setFan: function (on: boolean): void {
                throw new Error('Function not implemented.');
            },
            setWebURL: function (url: string): void {
                throw new Error('Function not implemented.');
            }
        };
        return connection;
    });

    return demoLib as Connection;
};

export default useDemoLib;
