import { useContext, useEffect, useState } from 'react';
import { BiError } from 'react-icons/bi';
import { useIntl } from 'react-intl';

import FEATURE_TOGGLES from '../../../FeatureToggles';
import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Slot, useSlots } from '../../../api/slots';
import { SpotLayoutItem, useSpotLayoutItems } from '../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import { useAppSelector } from '../../../hooks/redux';
import useActivity from '../../../hooks/useActivity';
import { SliceStatus } from '../../../redux/ReduxCommonVariables';
import { isAdmin, isDelivery, isNoodorpDelivery } from '../../../services/contact/GlobalPermissions';
import BackAndHomeNavigationButtons from '../../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../../views/common/BaseView';
import LoadingView from '../../../views/common/LoadingView';
import SlotInformation from '../../../views/elements/SlotInformation';
import SpotLayout from '../../../views/elements/SpotLayout';
import { isNikeDeliveryPerson } from '../../nike/permissions';

type SlotIssuesActionProps = AllowedActionViewProps;

const SlotIssues = (props: SlotIssuesActionProps) => {
    const [, newActivity] = useActivity();
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const spotLayoutLogsStore = useAppSelector((state) => state.spotLayoutLogs);
    const availableSlotsResult = useSlots({ spot: spot?.id });
    const availableSlots = availableSlotsResult.data ? availableSlotsResult.data : [];
    const [highlightedItems, changeHighlightedItems] = useState<SpotLayoutItem[] | undefined>();
    const [selectedSlot, changeSelectedSlot] = useState<Slot | undefined>(undefined);
    const [selectedItem, changeSelectedItem] = useState<SpotLayoutItem | undefined>(undefined);

    function selectSlot(item: SpotLayoutItem) {
        newActivity();
        changeSelectedItem(item);
        const slot = availableSlots.find((slot) => {
            return slot.id === item.slot;
        });
        changeSelectedSlot(slot);
    }

    useEffect(() => {
        if (spotLayoutLogsStore.status !== SliceStatus.INIT && spotLayoutItems) {
            changeHighlightedItems(spotLayoutItems.filter((i) => spotLayoutLogsStore.openedSpotLayoutItems.includes(i.id)));
        }
    }, [spotLayoutItems, availableSlots, spotLayoutLogsStore]);

    if (highlightedItems !== undefined) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={backHomeNav}>
                <SpotLayout
                    onSelect={selectSlot}
                    highlightedItems={highlightedItems}
                    selectableItems={highlightedItems}
                    enableColumnButtons={props.contact && (isNikeDeliveryPerson(props.contact) || isAdmin(props.contact) || isDelivery(props.contact))}
                    enableZoom={true}
                />
                {selectedSlot && selectedItem ? (
                    <SlotInformation
                        slot={selectedSlot}
                        spotLayoutItem={selectedItem}
                        contact={props.contact}
                    />
                ) : (
                    <div className='mt-3'>
                        <p className='text-center'>Select a slot to get its information.</p>
                    </div>
                )}
            </BaseView>
        );
    }
    return (
        <LoadingView
            onInactivity={props.onInactivity}
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
};

export default function SlotIssuesAction(props: SlotIssuesActionProps) {
    const intl = useIntl();
    const openedSpotLayoutItems = useAppSelector((state) => state.spotLayoutLogs.openedSpotLayoutItems);

    if (FEATURE_TOGGLES.SLOT_LOGGING === false) return undefined;

    if (isAdmin(props.contact) || isNikeDeliveryPerson(props.contact) || isDelivery(props.contact) || isNoodorpDelivery(props.contact)) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.actions.management.SlotIssuesAction',
                description: 'This is the slot issues Action button',
                defaultMessage: 'SLOT issues'
            }),
            warning: openedSpotLayoutItems.length > 0,
            icon: (
                <BiError
                    className='dashboard-action-icon'
                    size={264}
                />
            ),
            view: <SlotIssues {...props} />
        };
    }
    return undefined;
}
