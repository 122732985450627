import { useContext, useEffect } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { SpotLayoutItem } from '../../api/spotLayoutItems';
import { Spot } from '../../api/spots';
import { useTransactions } from '../../api/transactions';
import { TransactionStatusChangeEventValue, useMutateBulkUpdateTransactionStatus } from '../../api/transactions/bulk/BulkUpdateTransactionStatus';
import { Transaction, TransactionStatus } from '../../common/transactions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Logger } from '../../logs/Logger';
import { sync } from '../../redux/ScalesWeightSlice';
import { diffFound } from './WarehouseWorkflow';

interface Props {
    contacts: Contact[];
    spotLayoutItems?: SpotLayoutItem[];
    spot?: Spot;
}

/**
 * TODO:
 * not only update the diff
 * also send an update to the backend with an error notification if something was added without dropoff being enabled.
 * once pushed remove errors from cache
 *
 * make sure errors are being resolved in the store properly
 *
 */

export default function useUpdateDiffIfIdle(props: Props) {
    const dispatch = useAppDispatch();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const scalesWeightState = useAppSelector((state) => state.scalesWeight);
    const updateTransactions = useMutateBulkUpdateTransactionStatus({
        onSuccess: (data) => {
            const syncData: { [spotLayoutItemId: string]: number } = {};
            data.forEach((tr) => {
                const spotLayoutItem = props.spotLayoutItems?.find(
                    (spt) =>
                        spt.slot !== undefined &&
                        ((tr.slot_id !== null && spt.slot === tr.slot_id) ||
                            (tr.slot_reservation !== null && +spt.slot?.replace('SLT', '') === tr.slot_reservation.slot))
                );
                if (spotLayoutItem) {
                    if (syncData[spotLayoutItem.id] !== undefined) {
                        syncData[spotLayoutItem.id] = syncData[spotLayoutItem.id] + 1;
                    } else {
                        syncData[spotLayoutItem.id] = 1;
                    }
                }
            });
            dispatch(sync({ changes: syncData }));
        }
    });
    const { data: transactions } = useTransactions(
        {
            spot: props.spot?.id,
            status: TransactionStatus.READY_FOR_PICKUP
        },
        { enabled: !!props.spot }
    );

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        timeoutId !== null && clearTimeout(timeoutId);

        if (diffFound(scalesWeightState.difference) && props.contacts.length === 0 && transactions && transactions?.length > 0) {
            timeoutId = setTimeout(() => {
                Logger.log('No movement for 10 minutes but there are unresolved transactions -> orphaning them.');
                const trsToOrphan: Transaction[] = [];
                Object.entries(scalesWeightState.difference).forEach(([spotLayoutItemId, amount]) => {
                    if (amount > 0) {
                        const sptItem = props.spotLayoutItems?.find((i) => i.id.toString() === spotLayoutItemId.toString());
                        if (sptItem) {
                            const validTransactions = transactions.filter((tr) => tr.slot_id === sptItem.slot);
                            trsToOrphan.push(...validTransactions.slice(0, amount));
                        }
                    }
                });
                Logger.log(`Transactions that will be orphaned automaticaly: ${trsToOrphan.map((t) => t.real_id).join(', ')}`);
                if (trsToOrphan.length > 0)
                    updateTransactions.mutate({
                        initial_state: 'pickup_waiting',
                        event: TransactionStatusChangeEventValue.PICKUP_IN_PROGRESS,
                        transaction_ids: trsToOrphan.map((t) => t.real_id),
                        account_id: props.spot!.account,
                        fast_transition: true,
                        receiver_id:
                            terminal?.default_receiver_contact && typeof terminal?.default_receiver_contact === 'number'
                                ? terminal?.default_receiver_contact
                                : undefined
                    });
            }, 10 * 60 * 1000);
        }

        return () => {
            timeoutId !== null && clearTimeout(timeoutId);
        };
    }, [scalesWeightState.difference, props.contacts, transactions]);
}
