import { memo, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { useSlots } from '../../api/slots';
import { SpotLayoutItem, getControllBoardLastNumbers, useSpotLayoutItems } from '../../api/spotLayoutItems';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import useOpenSlotRange from '../../hooks/useOpenSlotRange';
import { getDefaultSpotLayoutLabel } from '../../services/spotLayoutItem/SpotLayoutItemData';
import LoadingView from '../common/LoadingView';
import SpotLayoutItemElement from './SpotLayoutItemElement';

interface SpotLayoutProps {
    highlightedItems?: SpotLayoutItem[];
    onSelect?: (item: SpotLayoutItem) => void;
    selectableItems?: SpotLayoutItem[];
    labelForItem?: (spotLayoutItem: SpotLayoutItem) => string | undefined;
    enableColumnButtons?: boolean;

    spotLayoutItemsOverwrite?: SpotLayoutItem[];
    enableZoom?: boolean;
}

const SPACING = 50;

function SpotLayout(props: SpotLayoutProps) {
    const [width, changeWidth] = useState('100%');
    const [height, changeHeight] = useState('100%');
    const [aspectRatio, changeAspectRatio] = useState(0);
    const layoutBox = useRef<HTMLDivElement>(null);
    const [slotRanges, changeSlotRanges] = useState<string[]>([]);
    const [openSlotRange] = useOpenSlotRange();

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout && !props.spotLayoutItemsOverwrite });
    const [actualSpotLayoutItems, changeActualSpotLayoutItems] = useState<SpotLayoutItem[] | undefined>();
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const availableSlotsResult = useSlots({ spot: spot?.id });
    const [isOpeningMultiple, changeIsOpeningMultiple] = useState<string | undefined>(undefined);
    const [currentZoom, changeCurrentZoom] = useState<number>(1);

    const info = getSpotLayoutCalculatedValues(actualSpotLayoutItems, spotLayout);
    let spotAspectRatio = 0;
    if (info && spotLayout) {
        spotAspectRatio = info.fullWidth / info.fullHeight;
    }

    const containerStyle = {
        width: width,
        height: height,
        transform: `scale(${currentZoom})`
    };

    useEffect(() => {
        changeActualSpotLayoutItems(props.spotLayoutItemsOverwrite ? props.spotLayoutItemsOverwrite : spotLayoutItems);
    }, [spotLayoutItems, props.spotLayoutItemsOverwrite]);

    useEffect(() => {
        if (props.enableColumnButtons) {
            if (actualSpotLayoutItems) {
                changeSlotRanges(getControllBoardLastNumbers(actualSpotLayoutItems.flat()));
                return;
            }
        }
    }, [actualSpotLayoutItems, props.enableColumnButtons]);

    const currentLayoutBoxWidth = layoutBox ? (layoutBox.current ? layoutBox.current.clientWidth : null) : null;
    const currentLayoutBoxHeight = layoutBox ? (layoutBox.current ? layoutBox.current.clientHeight : null) : null;

    useLayoutEffect(() => {
        if (layoutBox && layoutBox.current && spotAspectRatio !== 0 && aspectRatio === 0) {
            const containerAspectRatio = layoutBox.current.clientWidth / layoutBox.current.clientHeight;
            if (spotAspectRatio >= containerAspectRatio) {
                changeWidth('100%');
                changeHeight(`${(1 / spotAspectRatio) * layoutBox.current.clientWidth}px`);
                changeAspectRatio(spotAspectRatio);
            } else {
                changeWidth(`${spotAspectRatio * layoutBox.current.clientHeight}px`);
                changeHeight(`${layoutBox.current.clientHeight}px`);
                changeAspectRatio(spotAspectRatio);
            }
        }
    }, [spotAspectRatio, aspectRatio, currentLayoutBoxWidth, currentLayoutBoxHeight]);

    const isHighlightable = (spotLayoutItem: SpotLayoutItem): boolean => {
        if (props.highlightedItems === undefined) return true;
        return !!props.highlightedItems && props.highlightedItems.includes(spotLayoutItem);
    };

    const isSelectable = (spotLayoutItem: SpotLayoutItem): boolean => {
        if (!props.onSelect) {
            return false;
        }

        if (props.selectableItems !== undefined) {
            return props.selectableItems.includes(spotLayoutItem);
        } else {
            // If selectableSlots is undefined, use highlightableSlot
            return isHighlightable(spotLayoutItem);
        }
    };

    const getSpotLayoutLabel = (spotLayoutItem: SpotLayoutItem): string => {
        if (props.labelForItem) {
            const l = props.labelForItem(spotLayoutItem);
            if (l !== undefined && l !== '') return l;
        }
        return availableSlotsResult.data ? getDefaultSpotLayoutLabel(spotLayoutItem, availableSlotsResult.data) : '';
    };

    const zoom = (zoomIn: boolean) => {
        if (props.enableZoom === false) return;
        if (zoomIn && currentZoom < 15) changeCurrentZoom(currentZoom + 0.2);
        else if (!zoomIn && currentZoom > 1) changeCurrentZoom(currentZoom - 0.2);
    };

    return (
        <div
            ref={layoutBox}
            className={`${aspectRatio !== 0 ? '' : 'flex-grow-1 '}mt-4`}>
            {props.enableZoom && (
                <div className='d-flex justify-content-center'>
                    <div className='btn-group  mb-1'>
                        <button
                            className='primary-button'
                            onClick={() => {
                                zoom(false);
                            }}>
                            -
                        </button>
                        <button
                            className='primary-button'
                            disabled={true}>
                            zoom
                        </button>
                        <button
                            className='primary-button'
                            onClick={() => {
                                zoom(true);
                            }}>
                            +
                        </button>
                    </div>
                </div>
            )}
            <div className='d-flex justify-content-around'>
                {props.enableColumnButtons &&
                    slotRanges.map((value) => {
                        return (
                            <button
                                key={'openboard-' + value}
                                style={{ fontSize: '1rem' }}
                                onClick={async () => {
                                    changeIsOpeningMultiple(value);
                                }}
                                className='warning-button btn-sm mb-1'>
                                open {value}
                            </button>
                        );
                    })}
            </div>
            <div className='spotlayout-container'>
                <div
                    id='spotlayout'
                    className='spotlayout'
                    style={containerStyle}>
                    {info != null && spotLayout && actualSpotLayoutItems ? (
                        actualSpotLayoutItems.map((sli) => {
                            const grVar = Array.from(info.groupOffset.entries()).length - sli.group;
                            const y = spotLayout?.height * grVar + grVar * SPACING;
                            const x = info.groupOffset.get(sli.group)!;

                            return (
                                <SpotLayoutItemElement
                                    key={sli.id}
                                    spotLayoutItem={sli}
                                    label={getSpotLayoutLabel(sli)}
                                    spotWidth={info.fullWidth}
                                    spotHeight={info.fullHeight}
                                    highlighted={isHighlightable(sli)}
                                    selectable={isSelectable(sli)}
                                    offset={{
                                        X: x,
                                        Y: y
                                    }}
                                    onSelect={
                                        props.onSelect && isSelectable(sli)
                                            ? () => {
                                                  if (props.onSelect) {
                                                      props.onSelect(sli);
                                                  }
                                              }
                                            : undefined
                                    }
                                />
                            );
                        })
                    ) : (
                        <LoadingView
                            title={
                                <FormattedMessage
                                    id='views.elements.SpotLayout.loadingMessage'
                                    description='Message shown while the Spot layout is loading'
                                    defaultMessage='Loading DistriSPOT layout'
                                />
                            }
                        />
                    )}
                </div>
            </div>

            <Modal
                centered
                show={isOpeningMultiple !== undefined}
                onEntered={() => {
                    openSlotRange({
                        board: isOpeningMultiple!,
                        callback: () => changeIsOpeningMultiple(undefined)
                    });
                }}>
                <Modal.Body className='text-center'>
                    <div
                        className='spinner-border text-primary align-self-center'
                        role='status'
                        style={{ width: '6rem', height: '6rem' }}></div>
                </Modal.Body>
                <Modal.Footer className='pb-0'>
                    <button
                        className='primary-button btn-lg'
                        onClick={() => changeIsOpeningMultiple(undefined)}>
                        Dismiss
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default memo(SpotLayout);

function getSpotLayoutCalculatedValues(spotLayoutItems?: SpotLayoutItem[], layout?: any) {
    if (spotLayoutItems == null || layout == null) return;
    let fullWidth = 0;
    const groupOffset: Map<number, number> = new Map();
    //Calculate offset for each group
    spotLayoutItems.forEach((item) => {
        const value = groupOffset.get(item.group);
        if (value !== undefined) {
            if (item.origin_x < value) groupOffset.set(item.group, item.origin_x);
        } else {
            groupOffset.set(item.group, item.origin_x);
        }
    });

    //Calculate the max view width
    spotLayoutItems.forEach((item) => {
        const offset = groupOffset.get(item.group);
        if (offset !== undefined) {
            const actualWidth = item.origin_x + item.width - offset;
            if (fullWidth < actualWidth) fullWidth = actualWidth;
        }
    });

    //Calculate the max view heigth
    const amountOfGroups = Array.from(groupOffset.entries()).length;
    const fullHeight = amountOfGroups * layout.height + (amountOfGroups - 1) * SPACING;

    return {
        fullWidth,
        fullHeight,
        groupOffset
    };
}
