import Connection from '../../Connection';
import { SpotLayoutItem, isSpotLayoutItem } from '../../api/spotLayoutItems';
import { getHardwareDriverOptions } from '../../services/platformHardwareDriver/DriverSlotObjects';
import { SlotAction } from '../../services/platformHardwareDriver/utils/PSPGHardwareOptions';
import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand, WebsocketCommandRequest } from '../useManagedWebsocket';

export class WebsocketOpenSlotCommand extends WebsocketCommand {
    data: {
        spotLayoutItem: SpotLayoutItem;
        action?: SlotAction;
    };

    constructor(request: WebsocketCommandRequest) {
        super(request);
        if (request.spotLayoutItem === undefined || !isSpotLayoutItem(request.spotLayoutItem) || typeof request.action !== 'number')
            throw new Error('Incorect information passed.');
        this.data = {
            spotLayoutItem: request.spotLayoutItem,
            action: request.action
        };
    }

    public async execute(connection: Connection): Promise<WebsocketCommandResponse> {
        const options = getHardwareDriverOptions(this.data.spotLayoutItem, this.data.action);
        if (options === null) {
            return { success: false, message: 'Incorrect params given. Unable to open the slot.' };
        } else await connection.openSlot([options]);
        return { success: true };
    }
}
