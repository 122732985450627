import { useContext } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { useSpotLayoutItems } from '../api/spotLayoutItems';
import { useSpotLayout } from '../api/spotLayouts';
import { getHardwareDriverConfig } from '../services/platformHardwareDriver/DriverSlotObjects';
import { isZhilaiDriverOptions } from '../services/platformHardwareDriver/utils/ZhilaiHardwareOptions';
import { HardwareConnectionContext } from '../terminal/useHardwareConnection';

export interface openSlotRangeProps {
    board: string;
    callback?: () => void;
}

const useOpenSlotRange = () => {
    const connection = useContext(HardwareConnectionContext)!;
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });

    const openSlotRange = (props: openSlotRangeProps): void => {
        if (spotLayoutItems !== undefined) {
            const config = getHardwareDriverConfig(spotLayoutItems);
            const validBoardConfig = config.find((board) => {
                if (isZhilaiDriverOptions(board)) {
                    return board.name === props.board;
                }
                return false;
            });
            if (validBoardConfig && isZhilaiDriverOptions(validBoardConfig)) {
                const first = validBoardConfig.name + validBoardConfig.slots[0].id;
                const last = validBoardConfig.name + validBoardConfig.slots[validBoardConfig.slots.length - 1].id;
                connection.openSlotRange(first, last).then(() => {
                    if (props.callback) {
                        props.callback();
                    }
                });
            }
        }
    };
    return [openSlotRange] as const;
};

export default useOpenSlotRange;
