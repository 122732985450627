import React, { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Contact } from '../../../api/contacts';
import useActivity from '../../../hooks/useActivity';
import { getFullName } from '../../../utils';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';

interface HelpOrthopedicConfirmationProps {
    buyer: Contact;
    receiver: Contact;
    onConfirm: (additionalInformation: string) => void;
    onCancel: () => void;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

function HelpOrthopedicConfirmation(props: HelpOrthopedicConfirmationProps) {
    const [additionalInformation, changeAdditionalInformation] = useState('');
    const [wasValidated, changeWasValidated] = useState('');

    const [, newActivity] = useActivity();

    const homeNav = (
        <BackAndHomeNavigationButtons
            onBack={props.onBack}
            onHome={props.onHome}
        />
    );

    const onChangeAdditionalInformation = (event: ChangeEvent<HTMLTextAreaElement>) => {
        newActivity();
        changeAdditionalInformation(event.target.value);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        // Always prevent default action, we don't want to move away from the page
        event.preventDefault();

        changeWasValidated('was-validated');

        const form = event.currentTarget;
        if (form.checkValidity()) {
            props.onConfirm(additionalInformation);
        }
    };

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.help.HelpOrthopedicConfirmation.title'
                    description='The title on the help orthopedic support page right before confirming the order (for nike).'
                    defaultMessage='Request orthopedic support'
                />
            </ViewTitle>
            <p>
                <FormattedMessage
                    id='views.nike.help.HelpOrthopedicConfirmation.message'
                    description='The message on the help orthopedic support page right before confirming the order (for nike).'
                    defaultMessage='The following person is in need of orthopedic support for a new pair of shoes. Is this correct?'
                />
            </p>
            <div className='card my-4'>
                <div className='card-body'>
                    <div className='d-flex flex-row justify-content-between align-items-end'>
                        <h2>{getFullName(props.receiver)}</h2>
                    </div>
                </div>
            </div>
            <p className='mb-4'>
                <FormattedMessage
                    id='views.nike.help.HelpOrthopedicConfirmation.additionalMessage2'
                    description='The message asking for additional information on the help orthopedic support page right before confirming the order (for nike).'
                    defaultMessage='What is the reason for the request?'
                />
            </p>
            <form
                onSubmit={onSubmit}
                className={`d-flex flex-column needs-validation ${wasValidated}`}
                noValidate>
                <div className='form-group'>
                    <textarea
                        className='form-control mb-4'
                        style={{ height: '20rem' }}
                        required={true}
                        value={additionalInformation}
                        onChange={onChangeAdditionalInformation}
                    />
                    <div className='invalid-feedback'>
                        <FormattedMessage
                            id='views.nike.help.HelpOrthopedicConfirmation.fieldRequired'
                            description='The message telling you that the reason for the orthopedic request field is required.'
                            defaultMessage='This field is required'
                        />
                    </div>
                </div>

                <button
                    type='submit'
                    className='primary-button mb-4'>
                    <FormattedMessage
                        id='views.nike.help.HelpOrthopedicConfirmation.confirmButton'
                        description='The message on the confirm button on the help orthopedic support page right before confirming the order (for nike).'
                        defaultMessage='Confirm orthopedic support request'
                    />
                </button>
                <button
                    className='light-button'
                    onClick={props.onCancel}>
                    <FormattedMessage
                        id='views.nike.help.HelpOrthopedicConfirmation.cancelButton'
                        description='The message on the cancel button on the help orthopedic support page right before confirming the order (for nike).'
                        defaultMessage='Go back'
                    />
                </button>
            </form>
        </BaseView>
    );
}

export default HelpOrthopedicConfirmation;
