import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { Contact } from '../../../api/contacts';
import { useSlots } from '../../../api/slots';
import { SpotLayoutItem, useSpotLayoutItems } from '../../../api/spotLayoutItems';
import { useSpotLayout } from '../../../api/spotLayouts';
import { useSpot } from '../../../api/spots';
import { Transaction, TransactionType } from '../../../common/transactions';
import useTransactionsWithSlots from '../../../hooks/useTransactionsWithSlots';
import LoadingView from '../../../views/common/LoadingView';
import StockOverview from '../../../views/nike/manage_stock/StockOverview';
import SlotsOverview from '../../SlotsOverview';

interface StockOverviewWorkflowProps {
    filterObject?: JSX.Element;
    transactionTypeFilter?: TransactionType[];
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    loggedInContact?: Contact;

    cancelTransaction?: (transaction: Transaction) => void;
    changeTransactionSlot?: (transaction: Transaction) => void;
    removeTransaction?: (transaction: Transaction) => void;
}

function StockOverviewWorkflow(props: StockOverviewWorkflowProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout });
    const { data: slots } = useSlots({ spot: spot?.id });
    const { data: slotsMap } = useTransactionsWithSlots({ spotId: spot?.id });

    const [highlightedSpotLayoutItems, changeHighlightedSpotLayoutItems] = useState<SpotLayoutItem[] | undefined>(undefined);

    const markTransactions = (transactions: Transaction[]) => {
        const marked: SpotLayoutItem[] = [];
        slots?.forEach((s) => {
            if (transactions.find((t) => t.slot_id === s.id)) {
                const spotLayoutItem = spotLayoutItems?.find((item) => {
                    if (item.slot === s.id) return item;
                    return false;
                });
                if (spotLayoutItem) marked.push(spotLayoutItem);
            }
        });
        marked.length !== 0 && changeHighlightedSpotLayoutItems(marked);
    };
    if (highlightedSpotLayoutItems === undefined) {
        return (
            <StockOverview
                filterObject={props.filterObject}
                transactionTypeFilter={props.transactionTypeFilter}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
                onMarkTransactions={markTransactions}
            />
        );
    } else if (slotsMap) {
        return (
            <SlotsOverview
                filterObject={props.filterObject}
                transactionTypeFilter={props.transactionTypeFilter}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={() => changeHighlightedSpotLayoutItems(undefined)}
                transactions={slotsMap}
                highlightedItems={highlightedSpotLayoutItems}
                loggedInContact={props.loggedInContact}
                title={
                    <FormattedMessage
                        id='management.StockOverviewWorkflow.title'
                        description='This is the message displayed on the StockOverviewWorkflow'
                        defaultMessage='Stock overview'
                    />
                }
                cancelTransaction={props.cancelTransaction}
                changeTransactionSlot={props.changeTransactionSlot}
                removeTransaction={props.removeTransaction}
            />
        );
    }
    return (
        <LoadingView
            onInactivity={props.onInactivity}
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
}

export default StockOverviewWorkflow;
