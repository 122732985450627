import { Contact } from '../../api/contacts';
import { NikeRole, isNikeContactData } from './api';

export function isNikeManager(contact: Contact | undefined) {
    return contact !== undefined && isNikeContactData(contact.additional_data) && contact.additional_data.role === NikeRole.MANAGER;
}

export function isNikeDeliveryPerson(contact: Contact | undefined) {
    return contact !== undefined && isNikeContactData(contact.additional_data) && contact.additional_data.role === NikeRole.DELIVERY;
}

export function isNikeEmployee(contact: Contact | undefined) {
    return contact !== undefined && isNikeContactData(contact.additional_data) && contact.additional_data.role === NikeRole.EMPLOYEE;
}

export function isNikeInterim(contact: Contact | undefined) {
    return contact !== undefined && isNikeContactData(contact.additional_data) && contact.additional_data.role === NikeRole.INTERIM;
}

export function canPickupForOthers(contact: Contact | undefined) {
    return contact !== undefined && isNikeManager(contact);
}

export function canOrderForOthers(contact: Contact | undefined) {
    return contact !== undefined && isNikeManager(contact);
}

export function canReturnForOthers(contact: Contact | undefined) {
    return contact !== undefined && isNikeManager(contact);
}

export function canOrderOrthopedicShoes(contact: Contact | undefined) {
    return contact !== undefined && isNikeManager(contact);
}
