import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, UseQueryOptions, useQuery } from 'react-query';

import { TerminalContext } from '../../TerminalInit';
import { ApiViewSet, apiPagination } from '../baseApi';
import { ApiQueryParams, queryParamsToCacheKeys } from '../baseQueryParams';
import { FetchOptions } from '../utils';
import { IOT_DEVICE_TYPE } from './common';

export enum PrinterCommandStatus {
    ERROR = 'error',
    SUCCESS = 'success',
    PENDING = 'pending'
}

enum PRINTER_MODEL {
    zebra_ZD421d = 'zebra_ZD421d'
}

export interface Printer {
    uuid: string;
    type: IOT_DEVICE_TYPE.PRINTER;
    model: PRINTER_MODEL;
    request_data: unknown;
    serial_number: string;
    additional_data: {
        printing_platform: PrintingPlatform;
        spot_id: string;
    };
    last_active_state: {
        message: string | PrinterStatus;
        status: PrinterCommandStatus;
        [key: string]: unknown;
    };
    last_active_time?: Date;
    last_action?: unknown;
    last_action_time?: Date;
}

export interface PrinterStatus {
    offline: boolean;
    paperOut: boolean;
    paused: boolean;
    headOpen: boolean;
    ribbonOut: boolean;
}

enum PrintingPlatform {
    SAVANNAH = 'Savannah',
    TELLOPORT = 'Telloport'
}

export enum PrinterQueryParams {
    ACCOUNT = 'account',
    SPOT = 'spot',
    PAGE = 'page',
    PAGES_SIZE = 'page_size',
    SEARCH = 'search'
}

const printerViewSet: ApiViewSet = {
    baseName: 'services/iot/tlp_prnt'
};

const defaultConfig = {
    staleTime: 1000 * 60 * 60,
    cacheTime: Infinity,
    retry: 3,
    retryDelay: 5000
};

export interface PrinterResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: Printer[];
}

function fetchPrintersApi(queryParams?: ApiQueryParams<PrinterQueryParams>, fetchOptions?: FetchOptions): () => Promise<Printer[]> {
    return apiPagination<Printer[], PrinterQueryParams>(printerViewSet, queryParams, fetchOptions);
}

export function usePrinters(spot?: string, queryOptions?: UseQueryOptions<Printer[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Printer[]>(['printers', queryParamsToCacheKeys(PrinterQueryParams, { spot })], fetchPrintersApi({ spot }, fetchOptions), config);
}

export async function prefetchPrinters(queryClient: QueryClient, spot: string, options?: FetchQueryOptions<Printer[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...options
    };

    await queryClient.fetchQuery(['printers'], fetchPrintersApi({ spot }, fetchOptions), config);
}
