import { useState } from 'react';

import Connection, { AccessToken, SetLightsOptions } from '../Connection';
import { SpotLayoutItem } from '../api/spotLayoutItems';
import { getTokenFromState, oauth2, redirectUri } from '../demo/OAuth2';
import useGetWindowsCommunicationObject from '../hooks/useGetWindowsCommunicationObject';
import useLocalStorage from '../hooks/useLocalStorage';
import useSelectTerminalDriver from '../hooks/useSelectTeminalDriver';
import { Logger } from '../logs/Logger';
import { TerminalHardwareObject } from '../services/platformHardwareDriver/DriverSlotObjects';
import { PSPGHardwareObject } from '../services/platformHardwareDriver/utils/PSPGHardwareOptions';
import { getRandomString } from '../utils';
import TerminalInterface from './TerminalInterface';
import useTerminalConnectionManager, { TerminalSoftware } from './terminalSoftwareHelpers/TerminalConnectionManager';

declare global {
    interface Window {
        TerminalApp?: TerminalInterface;
    }

    const chrome: {
        webview: {
            addEventListener: (arg0: string, arg1: (event: { data: string }) => void) => void;
            hostObjects: {
                TerminalApp?: TerminalInterface;
                slotObj?: PSPGHardwareObject;
            };
        };
    };
}

const useTerminalLib = (): Connection | null => {
    const windowsCommunicationObject = useGetWindowsCommunicationObject();
    const app = useSelectTerminalDriver(windowsCommunicationObject);

    const terminalSoftwareSpecificConnection: Connection = useTerminalConnectionManager({
        TerminalApp: app,
        windowsSlotObj: windowsCommunicationObject?.slotObj
    });

    const [oauth2State, changeOauth2State] = useLocalStorage<string | null>('oauth2_state', null);

    const [terminalLib] = useState(() => {
        const connection: Connection = {
            getTerminalSoftware(): TerminalSoftware {
                return terminalSoftwareSpecificConnection.getTerminalSoftware();
            },

            getTerminalSoftwareVersion(): number {
                return terminalSoftwareSpecificConnection.getTerminalSoftwareVersion();
            },
            async init(): Promise<AccessToken | null> {
                return new Promise<AccessToken | null>((resolve, reject) => {
                    Logger.log('send init to app');
                    app!.init();
                    const timer = setTimeout(() => reject(new Error('Timeout reached waiting for terminal response.')), 10000);
                    const getToken = new Promise<AccessToken | null>((resolve, reject) => {
                        if (oauth2State) {
                            changeOauth2State(null);
                            getTokenFromState(oauth2State).then((token) => {
                                if (token) {
                                    window.history.replaceState({}, '', redirectUri);
                                    resolve({
                                        accessToken: token.accessToken,
                                        expires: token.expires,
                                        refreshToken: token.refreshToken,
                                        tokenType: token.tokenType
                                    });
                                }
                                reject('Could not obtain token');
                            });
                        } else {
                            resolve(null);
                            //should try and get a new token
                        }
                    });

                    getToken
                        .then((token) => {
                            if (timer) {
                                clearTimeout(timer);
                            }
                            resolve(token);
                        })
                        .catch(() => {
                            const t = window.localStorage.getItem('oauth2Result');
                            if (t) {
                                changeOauth2State(JSON.parse(t));
                                window.history.replaceState({}, '', redirectUri);
                                resolve(JSON.parse(t));
                            } else {
                                Logger.warn('auth failed');
                            }
                        });
                });
            },

            setConfiguration(doorOpenTimeInSeconds: any) {
                terminalSoftwareSpecificConnection.setConfiguration(doorOpenTimeInSeconds);
            },

            sendSpotLayoutConfig(spotLayoutItems: SpotLayoutItem[]) {
                terminalSoftwareSpecificConnection.sendSpotLayoutConfig(spotLayoutItems);
            },

            async reConfigureTerminal(): Promise<AccessToken | null> {
                return new Promise((resolve, reject) => {
                    const state = getRandomString(64);
                    changeOauth2State(state);

                    window.location.href = oauth2.code.getUri({ state: state });
                });
            },

            reset(): void {
                terminalSoftwareSpecificConnection.reset();
            },

            openSlot(object: TerminalHardwareObject[] | null) {
                return terminalSoftwareSpecificConnection.openSlot(object);
            },

            openSlotRange(first: string, last: string): Promise<void> {
                return terminalSoftwareSpecificConnection.openSlotRange(first, last);
            },
            keepAlive(): void {
                terminalSoftwareSpecificConnection.keepAlive();
            },
            resetPcb(): void {
                terminalSoftwareSpecificConnection.resetPcb();
            },
            setLights(options: SetLightsOptions): void {
                Logger.log('light via js');
                terminalSoftwareSpecificConnection.setLights(options);
            },
            resetCache(): void {
                terminalSoftwareSpecificConnection.resetCache();
            },
            resetData(): void {
                terminalSoftwareSpecificConnection.resetData();
            },
            broadcast(data) {
                terminalSoftwareSpecificConnection.broadcast(data);
            },
            setFan: function (on: boolean): void {
                terminalSoftwareSpecificConnection.setFan(on);
                throw new Error('Function not implemented.');
            },
            send: function (id: string, data: any): void {
                terminalSoftwareSpecificConnection.send(id, data);
            },
            setWebURL: function (url: string): void {
                terminalSoftwareSpecificConnection.setWebURL(url);
            }
        };

        return connection;
    });

    if (!app) {
        return null;
    } else {
        return terminalLib as Connection;
    }
};

export default useTerminalLib;
