import { Category } from '../../../api/category';
import logo from '../../../static/Logo Telloport Horizontal.png';

interface ShopCategoryCardProps {
    category: Category;
    onClick: () => void;
}

const ShopCategoryCard = (props: ShopCategoryCardProps) => {
    return (
        <div onClick={props.onClick}>
            <img
                alt='product_image'
                src={props.category.image ? props.category.image : logo}
            />
            <p>{props.category.name.length > 16 ? props.category.name.substring(0, 16) + '…' : props.category.name}</p>
        </div>
    );
};

export default ShopCategoryCard;
