import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { useSpotLayout } from '../../api/spotLayouts';
import { useMutateSpotLightsStatus, useSpot } from '../../api/spots';
import useActivity from '../../hooks/useActivity';
import { HardwareConnectionContext } from '../../terminal/useHardwareConnection';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';

interface AdjustLightWorkflowProps {
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

const AdjustLightWorkflow = (props: AdjustLightWorkflowProps) => {
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onHome={props.onHome}
            onBack={props.onBack}
        />
    );
    const connection = useContext(HardwareConnectionContext)!;
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const mutateSpotLightsStatus = useMutateSpotLightsStatus();
    const [, newActivity] = useActivity();

    const setLights = (enableMinorLights: boolean) => {
        newActivity();
        connection.setLights({ enableMinorLights: enableMinorLights });
        if (spot && spot.id) {
            mutateSpotLightsStatus.mutate({
                spot_id: spot.id,
                body: {
                    actions_turn_lights_on: enableMinorLights
                }
            });
        }
    };

    const title = (
        <FormattedMessage
            id='views.AdjustLightWorkflow.lightAdjustmentTitle'
            description='This is the message displayed on the Adjust Light Workflow'
            defaultMessage='Adjust the light settings'
        />
    );
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={backHomeNav}>
            <h3 className='mt-4 text-center'>{title}</h3>
            <div className='text-center'>
                <button
                    onClick={() => setLights(true)}
                    className='primary-button'>
                    Lights on
                </button>
                <button
                    onClick={() => setLights(false)}
                    className='primary-button'>
                    Lights off
                </button>
            </div>
        </BaseView>
    );
};

export default AdjustLightWorkflow;
