import crypto from 'crypto';

export class ProtonPacketHeader {
    sign: string | undefined;

    actionVer = 'V0.1';
    action;
    trace = crypto.randomBytes(13).toString('hex');
    timestamp;
    priority = 3;

    constructor(action: string, trace?: string) {
        this.action = action;
        this.timestamp = formatTimestamp(new Date());
        if (trace) this.trace = trace;
    }

    getActionVer() {
        return this.actionVer;
    }

    getAction() {
        return this.action;
    }

    getTrace() {
        return this.trace;
    }

    getTimestamp() {
        return this.timestamp;
    }

    getPriority() {
        return this.priority;
    }

    setSign(sign: string) {
        this.sign = sign;
    }
}

function formatTimestamp(date: Date) {
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());
    const milliseconds = padZero(date.getMilliseconds(), 3);

    return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
}

function padZero(value: any, length = 2) {
    return String(value).padStart(length, '0');
}
