import Switch from 'react-switch';

import { TransactionType } from '../../common/transactions';

interface Props {
    onChange: (type?: TransactionType) => void;
    enabled?: TransactionType[];
}

export default function ContentManagementTransactionTypeFilter(props: Props) {
    const isClicked = (type?: TransactionType): boolean => {
        if (type === undefined) return props.enabled?.length === 4;
        return !!props.enabled?.includes(type);
    };

    return (
        <div className='d-flex btn-group justify-content-between'>
            <label className='d-flex flex-column align-items-center'>
                <Switch
                    onChange={() => props.onChange(TransactionType.VENDING)}
                    checked={isClicked(TransactionType.VENDING)}
                    height={45}
                    width={80}
                    handleDiameter={43}
                />
                <span>Vending</span>
            </label>
            <label className='d-flex flex-column align-items-center'>
                <Switch
                    onChange={() => props.onChange(TransactionType.PUDO)}
                    checked={isClicked(TransactionType.PUDO)}
                    height={45}
                    width={80}
                    handleDiameter={43}
                />
                <span>PuDo</span>
            </label>
            <label className='d-flex flex-column align-items-center'>
                <Switch
                    onChange={() => props.onChange(TransactionType.LENDING)}
                    checked={isClicked(TransactionType.LENDING)}
                    height={45}
                    width={80}
                    handleDiameter={43}
                />
                <span>Lending</span>
            </label>
            <label className='d-flex flex-column align-items-center'>
                <Switch
                    onChange={() => props.onChange(TransactionType.RETURN)}
                    checked={isClicked(TransactionType.RETURN)}
                    height={45}
                    width={80}
                    handleDiameter={43}
                />
                <span>Return</span>
            </label>
            <label className='d-flex flex-column align-items-center'>
                <Switch
                    onChange={() => props.onChange()}
                    checked={isClicked()}
                    height={45}
                    width={80}
                    handleDiameter={43}
                />
                <span>All</span>
            </label>
        </div>
    );
}
