import { PrinterStatus } from '../Printer';
import IPrinter, { PrinterManufacturers } from './IPrinter';

export default class ZebraPrinter implements IPrinter {
    name: string;
    deviceType: string;
    connection: string;
    uid: string;
    version: number;
    provider: string;
    manufacturer: PrinterManufacturers;

    constructor(printer: IPrinter) {
        const zebra = printer as unknown as ZebraPrinter;
        this.name = zebra.name;
        this.deviceType = zebra.deviceType;
        this.connection = zebra.connection;
        this.uid = zebra.uid;
        this.version = zebra.version;
        this.provider = zebra.provider;
        this.manufacturer = zebra.manufacturer;
    }

    parsePrinterStatus(value: string): PrinterStatus {
        const lines = value.split('\n');
        const line1 = lines[0].split(',');
        const line2 = lines[1].split(',');
        return {
            offline: false,
            paperOut: line1[1] === '0' ? false : true,
            paused: line1[2] === '0' ? false : true,
            headOpen: line2[2] === '0' ? false : true,
            ribbonOut: line2[3] === '0' ? false : true
        };
    }

    static isZebraPrinter(printer: IPrinter): printer is ZebraPrinter {
        return printer.manufacturer === PrinterManufacturers.ZEBRA;
    }
}
