import { useState } from 'react';
import { BsShop } from 'react-icons/bs';
import { useIntl } from 'react-intl';

import { TransactionType } from '../../common/transactions';
import { AllowedActionViewProps } from '../../components/domain/AllowedAction';
import { isAdmin, isDelivery, isNoodorpDelivery } from '../../services/contact/GlobalPermissions';
import { isNikeDeliveryPerson } from '../nike/permissions';
import ContentManagementTransactionTypeFilter from './ContentManagementTransactionTypeFilter';
import ContentManagementWorkflow from './ContentManagementWorkflow';

type ContentManagementActionProps = AllowedActionViewProps;

const ContentManagement = (props: ContentManagementActionProps) => {
    const [transactionTypes, changeTransactionTypes] = useState<TransactionType[] | undefined>([
        TransactionType.VENDING,
        TransactionType.PUDO,
        TransactionType.LENDING,
        TransactionType.RETURN
    ]);

    const changeType = (type?: TransactionType) => {
        if (type === undefined) {
            if (transactionTypes === undefined || transactionTypes.length !== 4) {
                changeTransactionTypes([TransactionType.VENDING, TransactionType.PUDO, TransactionType.LENDING, TransactionType.RETURN]);
            } else {
                changeTransactionTypes(undefined);
            }
        } else if (transactionTypes) {
            if ((type === undefined && transactionTypes?.length === 4) || transactionTypes.includes(type)) {
                changeTransactionTypes(transactionTypes.filter((t) => t !== type));
            } else {
                changeTransactionTypes([...transactionTypes, type]);
            }
        } else {
            changeTransactionTypes([type]);
        }
    };

    const filter = (
        <ContentManagementTransactionTypeFilter
            onChange={changeType}
            enabled={transactionTypes}
        />
    );

    return (
        <ContentManagementWorkflow
            filterObject={filter}
            loggedInContact={props.contact!}
            transactionTypeFilter={transactionTypes}
            onInactivity={props.onInactivity}
            onHome={props.onHome}
        />
    );
};

const ContentManagementAction = (props: ContentManagementActionProps) => {
    const intl = useIntl();
    if (isAdmin(props.contact) || isNikeDeliveryPerson(props.contact) || isDelivery(props.contact) || isNoodorpDelivery(props.contact)) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.actions.management.ContentManagementAction',
                description: 'This is the ContentManagement Action button',
                defaultMessage: 'Content management'
            }),
            icon: (
                <BsShop
                    className='dashboard-action-icon'
                    size={264}
                />
            ),
            view: <ContentManagement {...props} />
        };
    }
    return undefined;
};

export default ContentManagementAction;
