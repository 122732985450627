import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../TerminalInit';
import { TerminalWorkflowType } from '../../../api/terminals';
import { AllowedActionViewProps } from '../../../components/domain/AllowedAction';
import Checkout from './Checkout';

export function WarehouseCheckoutAction(props: AllowedActionViewProps) {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const intl = useIntl();

    if (props.contact && terminal?.workflow === TerminalWorkflowType.WAREHOUSE) {
        return {
            actionText: intl.formatMessage({
                id: 'workflow.warehouseCheckout.checkout.action',
                description: 'This is the warehouse checkout Action button',
                defaultMessage: 'Checkout'
            }),
            icon: <i className='bi bi-box-arrow-up dashboard-action-icon' />,
            view: (
                <Checkout
                    contact={props.contact}
                    onInactivity={props.onInactivity}
                    onHome={props.onHome}
                />
            )
        };
    }
    return undefined;
}
