import React, { memo, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Slot } from '../api/slots';
import { SpotType } from '../api/spots';
import useSpotLayoutItemsForSlots from '../hooks/useSpotLayoutItemForSlot';
import { Logger } from '../logs/Logger';
import BackAndHomeNavigationButtons from './common/BackAndHomeNavigationButtons';
import BaseView from './common/BaseView';
import WarningModal, { WarningModalType } from './elements/WarningModal';
import SlotOpenSpotLayout from './spotlayout/slotOpenSpotLayouts/SlotOpenSpotLayout';

export enum SlotOpenState {
    IN_PROGRESS = 'in_progress',
    SUCCESS = 'success',
    ERROR = 'error'
}

export enum SlotsOpenState {
    IN_PROGRESS = 'in_progress',
    SUCCESS = 'success',
    ERROR_SOME = 'error_some',
    ERROR_ALL = 'error_all'
}

export interface SlotOpenInfo {
    slot?: Slot;
    state: SlotOpenState;
}

interface SlotOpenViewProps {
    slotInfos: SlotOpenInfo[];
    title?: string;
    endSession?: boolean;
    doorClosesAutomatically?: boolean;
    showSpotLayout?: boolean;
    spotType: SpotType;

    onHome?: () => void;
    onInactivity?: () => void;

    deliverSameProduct?: () => void;
}

const SlotOpenViewFunction = (props: SlotOpenViewProps) => {
    const [confirmationModalOpen, changeConfirmationModalOpen] = useState<boolean>(props.endSession ? props.endSession : false);
    const slotItems = useSpotLayoutItemsForSlots(props.slotInfos.filter((si) => !!si.slot).map((si) => si.slot!));
    const values = { slotCount: props.slotInfos.length };

    const defaultTitles = useMemo(() => {
        const defaultTitles = new Map<SlotsOpenState, React.ReactNode>();

        defaultTitles.set(
            SlotsOpenState.IN_PROGRESS,
            <FormattedMessage
                id='views.SlotOpenView.slotOpenInProgressTitle'
                description='The title shown on the Slot Open view while the Slot is being opened.'
                defaultMessage='Please wait while we open your {slotCount, plural, one {Slot} other {Slots}}'
                values={values}
            />
        );
        defaultTitles.set(
            SlotsOpenState.SUCCESS,
            <FormattedMessage
                id='views.SlotOpenView.slotOpenSuccessTitle'
                description='The title shown on the Slot Open view when the Slot(s) opened successfully.'
                defaultMessage='Your {slotCount, plural, one {Slot is} other {Slots are}} now open'
                values={values}
            />
        );
        defaultTitles.set(
            SlotsOpenState.ERROR_SOME,
            <FormattedMessage
                id='views.SlotOpenView.slotOpenErrorSomeTitle'
                description='The title shown on the Slot Open view when some Slot(s) had an error. It is possible that some other Slot(s) succeeded in opening, or that others are still in progress.'
                defaultMessage='There was a problem with some of your SLOTS'
            />
        );
        defaultTitles.set(
            SlotsOpenState.ERROR_ALL,
            <FormattedMessage
                id='views.SlotOpenView.slotOpenErrorAllTitle'
                description='The title shown on the Slot Open view when all Slots had an error, even if there is only one.'
                defaultMessage='There was a problem opening your {slotCount, plural, one {SLOT} other {SLOTS}}'
                values={values}
            />
        );
        return defaultTitles;
    }, []);

    let slotsOpenState: SlotsOpenState;
    if (props.slotInfos.some((si) => si.state === SlotOpenState.IN_PROGRESS)) {
        slotsOpenState = SlotsOpenState.IN_PROGRESS;
    } else if (props.slotInfos.every((si) => si.state === SlotOpenState.SUCCESS)) {
        slotsOpenState = SlotsOpenState.SUCCESS;
    } else if (props.slotInfos.every((si) => si.state === SlotOpenState.ERROR)) {
        slotsOpenState = SlotsOpenState.ERROR_ALL;
    } else if (props.slotInfos.some((si) => si.state === SlotOpenState.ERROR)) {
        slotsOpenState = SlotsOpenState.ERROR_SOME;
    } else {
        props.slotInfos.forEach((i) => Logger.log('Unexpected SLOT open state', { slot: i.slot?.id }, props.slotInfos));
        slotsOpenState = SlotsOpenState.ERROR_SOME;
    }

    //always make home button available as a failsafe
    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    let slotInfo: any | null | undefined;

    if (slotsOpenState === SlotsOpenState.IN_PROGRESS) {
        const style = { width: '6rem', height: '6rem' };
        slotInfo = (
            <>
                <div
                    className='spinner-border-lg text-primary align-self-center'
                    role='status'
                    style={style}></div>
                <p className='text-center mt-2'>
                    <FormattedMessage
                        id='views.SlotOpenView.slotOpenInProgressMessage'
                        description='The message shown on the Slot Open view while the Slot is being opened.'
                        defaultMessage='{slotCount, plural, one {Your Slot is being opened} other {Your Slots are being opened}}. Please wait.'
                        values={values}
                    />
                </p>
            </>
        );
    } else if (slotsOpenState === SlotsOpenState.ERROR_ALL) {
        const style = { fontSize: '6rem' };
        slotInfo = (
            <>
                <div className='align-self-center text-danger'>
                    <i
                        className='bi bi-x-circle'
                        style={style}
                    />
                </div>
                <p className='align-self-center text-center mt-2'>
                    <FormattedMessage
                        id='views.SlotOpenView.slotOpenErrorAllMessage'
                        description='The message shown on the Slot Open view when all Slots had an error, even if there is only one.'
                        defaultMessage='You can try again or if the problem persists, come back later.'
                    />
                </p>
            </>
        );
    } else if (slotsOpenState === SlotsOpenState.ERROR_SOME) {
        const style = { fontSize: '6rem' };
        slotInfo = (
            <>
                <div className='align-self-center text-danger'>
                    <i
                        className='bi bi-x-circle'
                        style={style}
                    />
                </div>
                <p className='align-self-center text-center mt-2'>
                    <FormattedMessage
                        id='views.SlotOpenView.slotOpenErrorSomeMessage'
                        description='The message shown on the Slot Open view when some Slot(s) had an error. It is possible that some other Slot(s) succeeded in opening, or that others are still in progress.'
                        defaultMessage='You can try again or if the problem persists, come back later. If a Slot opened and you are the only person using this DistriSPOT, you can take out the item and close the door.'
                    />
                </p>
            </>
        );
    } else {
        if (props.doorClosesAutomatically === true || props.spotType === SpotType.MULTI_DISTRISPOT) {
            //ik ga er momenteel van uit dat dit sowieso een pspg kast is omwillen van tijdsdruk
            const info = props.slotInfos.map((si) => si.slot!.slot_nr.split(' ')[0]).join(', ');
            slotInfo = (
                <>
                    <h1 className='align-self-center'>{info}</h1>
                </>
            );
        } else {
            slotInfo = (
                <>
                    <h1 className='align-self-center'>{props.slotInfos.map((si) => si.slot!.slot_nr).join(', ')}</h1>
                    <p className='align-self-center text-center'>
                        <FormattedMessage
                            id='views.SlotOpenView.slotOpenSuccessMessage'
                            description='The message shown on the Slot Open view when the Slot(s) opened successfully.'
                            defaultMessage='{slotCount, plural, one {You can now use the Slot. Remember to close the door before leaving.} other {You can now use the Slots. Remember to close the door of each Slot before leaving.}}'
                            values={values}
                        />
                    </p>
                </>
            );
        }
    }

    return (
        <BaseView
            onInactivity={slotsOpenState !== SlotsOpenState.IN_PROGRESS ? props.onInactivity : undefined}
            navbarItems={homeNav}>
            <h3 className='text-center mt-3'>{props.title ? props.title : defaultTitles.get(slotsOpenState)}</h3>
            {slotInfo}
            {props.deliverSameProduct && slotsOpenState === SlotsOpenState.SUCCESS ? (
                <div className='d-flex flex-row justify-content-around mb-2 mt-2'>
                    <button
                        onClick={props.deliverSameProduct}
                        className='primary-button primary-button-small'>
                        Deliver same product
                    </button>
                    <button
                        onClick={props.onHome}
                        className='primary-button primary-button-small'>
                        Done
                    </button>
                </div>
            ) : (
                <></>
            )}

            {props.showSpotLayout === false ? (
                <></>
            ) : (
                <SlotOpenSpotLayout
                    type={props.spotType}
                    highlightedItems={slotItems}
                />
            )}

            <WarningModal
                show={confirmationModalOpen}
                onHide={() => changeConfirmationModalOpen(false)}
                description={
                    <FormattedMessage
                        id='workflow.lending.MakeSureYourLockerIsEmptyMessage'
                        description='The message shown on the modal to warn the user to empty his locker before closing it.'
                        defaultMessage='Make sure you take all your belongings before closing the locker.'
                    />
                }
                submitButton={
                    <FormattedMessage
                        id='workflow.lending.MakeSureYourLockerIsEmptyDismissButton'
                        description='The message shown on the button to dismiss the modal dialog that the user should empty his locker before closing.'
                        defaultMessage='Dismiss'
                    />
                }
                type={WarningModalType.PRIMARY}
                cancelButton={false}
            />
        </BaseView>
    );
};

export const SlotOpenView = memo(SlotOpenViewFunction);
