import { useContext, useEffect, useState } from 'react';

import { ReadyForUpdateContext } from '../AppUpdate';

interface ReadyForUpdateProps {
    delayMs?: number;
}

const useReadyForUpdate = (props?: ReadyForUpdateProps) => {
    const [readyTimer, changeReadyTimer] = useState<number | null>(null);
    const readyForUpdatecontext = useContext(ReadyForUpdateContext);

    const delayMs: number = props?.delayMs ? props.delayMs : 0;

    useEffect(() => {
        if (readyTimer == null) {
            changeReadyTimer(
                window.setTimeout(() => {
                    readyForUpdatecontext.readyForUpdateChange(true);
                    changeReadyTimer(null);
                }, delayMs)
            );
        }

        return () => {
            if (readyTimer) {
                window.clearTimeout(readyTimer);
            }
        };
    }, []);
};

export default useReadyForUpdate;
