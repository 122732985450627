import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Product } from '../../api/products';
import { ProductParameter } from './ProductConfigurationView';

interface ProductParametersElementProps {
    product?: Product | null;
    parameters: ProductParameter[];
}

function ProductParametersElement(props: ProductParametersElementProps) {
    const intl = useIntl();

    return (
        <>
            {props.parameters.map((productParameter) => {
                const parameterValue = props.product ? productParameter.getValue(props.product) : undefined;
                return (
                    <p key={productParameter.name}>
                        {productParameter.getDisplayName(intl)}:
                        <span className='font-weight-bold ms-1'>
                            {parameterValue ? (
                                parameterValue
                            ) : (
                                <FormattedMessage
                                    id='views.nike.ProductConfigurationView.optionNotConfigured'
                                    description='The value shown when a product parameter value has not been found in the Nike workflow'
                                    defaultMessage='not configured'
                                />
                            )}
                        </span>
                    </p>
                );
            })}
        </>
    );
}

export default ProductParametersElement;
