export interface AuthenticationMethod {
    label: AuthenticationMethodLabel;
    value: AuthenticationMethodValue;
    default_dropoff_action: boolean;
    default_pickup_action: boolean;
    default_remove_parcel_action: boolean;
}

export enum AuthenticationMethodLabel {
    PIN_CODE = 'Pin Code',
    QR_CODE = 'QR Code',
    ITSME = 'ITSME'
}

export enum AuthenticationMethodValue {
    PIN_CODE = 'pin_code',
    QR_CODE = 'qr_code',
    ITSME = 'itsme'
}
