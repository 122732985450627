import { FormattedMessage } from 'react-intl';

import { Contact } from '../../../api/contacts';
import { getFullName } from '../../../utils';
import { isNikeContactData } from '../../../workflow/nike/api';
import { teamIdToLocation } from '../../../workflow/nike/utils';

interface EmployeeCardProps {
    employee: Contact;
    onClick?: () => void;
    className?: string;
}

export default function EmployeeCard(props: EmployeeCardProps) {
    let additionalDataElement: React.ReactNode | null;
    if (isNikeContactData(props.employee.additional_data)) {
        const location = props.employee.additional_data.team_id ? teamIdToLocation(props.employee.additional_data.team_id) : undefined;
        additionalDataElement = (
            <>
                <div className='row'>
                    <div className='col-3 font-weight-bold'>
                        <FormattedMessage
                            id='views.nike.registration.EmployeeCard.locationLabel'
                            description='The location label on the employee details card'
                            defaultMessage='Location:'
                        />
                    </div>
                    <div className='col'>{location ? location : '-'}</div>
                </div>
            </>
        );
    } else {
        additionalDataElement = (
            <p>
                <FormattedMessage
                    id='views.nike.registration.EmployeeCard.noInformationMessage'
                    description='The message on the employee details card when no additional details are available.'
                    defaultMessage='No additional information available'
                />
            </p>
        );
    }

    return (
        <div
            className={`card ${props.className}`}
            onClick={props.onClick}>
            <div className='card-header'>{getFullName(props.employee)}</div>
            <div className='card-body'>{additionalDataElement}</div>
        </div>
    );
}
