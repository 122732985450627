import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import './App.scss';
import AppUpdate from './AppUpdate';
import TerminalInit from './TerminalInit';
import en from './lang/en.json';
import fr from './lang/fr.json';
import nl from './lang/nl.json';
import { store } from './redux/store';

interface AppProps {
    serviceWorkerUpdating: boolean;
}

export enum Locale {
    ENGLISH = 'en_US',
    DUTCH = 'nl_BE',
    FRENCH = 'fr_BE'
}

const messages = new Map<Locale, any>();
messages.set(Locale.ENGLISH, en);
messages.set(Locale.DUTCH, nl);
messages.set(Locale.FRENCH, fr);

const queryClient = new QueryClient();

const App = (props: AppProps) => {
    const [currentLocale, changeCurrentLocale] = useState<Locale>(Locale.ENGLISH);

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Provider store={store}>
                <IntlProvider
                    messages={messages.get(currentLocale)}
                    locale='nl'
                    defaultLocale='en'>
                    <AppUpdate serviceWorkerUpdating={props.serviceWorkerUpdating}>
                        <TerminalInit
                            currentLocale={currentLocale}
                            changeCurrentLocale={changeCurrentLocale}
                        />
                    </AppUpdate>
                </IntlProvider>
            </Provider>
        </QueryClientProvider>
    );
};

export default App;
