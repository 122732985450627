import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../../../TerminalInit';
import { useSpotLayout } from '../../../../api/spotLayouts';
import { SpotType, useSpot } from '../../../../api/spots';
import { TerminalWorkflowType } from '../../../../api/terminals';
import ActionCard from '../../../../components/domain/ActionCard';
import { AllowedActionViewProps } from '../../../../components/domain/AllowedAction';
import { isDelivery } from '../../../../services/contact/GlobalPermissions';
import { ManualStockRefillWorkflowGeneral } from './ManualStockRefilActionGeneral';
import { ManualStockRefillWorkflow } from './ManualStockRefillAction';

type ManualStockRefillWithoutReportNumberActionProps = AllowedActionViewProps;

const ManualStockRefillWithoutReportNumberWorkflow = (props: ManualStockRefillWithoutReportNumberActionProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    if (spot?.type === SpotType.MULTI_DISTRISPOT) {
        return (
            <ManualStockRefillWorkflow
                {...props}
                skipReportNumber={true}
            />
        );
    }
    return (
        <ManualStockRefillWorkflowGeneral
            {...props}
            skipReportNumber={true}
        />
    );
};

function ManualStockRefillWithoutReportNumberAction(props: ManualStockRefillWithoutReportNumberActionProps) {
    const intl = useIntl();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    if (terminal?.workflow !== TerminalWorkflowType.VENDING) return undefined;
    if (props.contact === undefined) return undefined;
    if (!isDelivery(props.contact)) return undefined;

    const ManualStockRefillWithoutReportNumberAction: ActionCard = {
        actionText: intl.formatMessage({
            id: 'workflow.vending.management.ManualStockRefillWithoutReportNumberAction',
            description: 'This is the action button for the ManualStockRefill without report',
            defaultMessage: 'Manual Stock update'
        }),
        icon: <i className='bi bi-box-arrow-in-down dashboard-action-icon' />,
        view: <ManualStockRefillWithoutReportNumberWorkflow {...props} />
    };
    return ManualStockRefillWithoutReportNumberAction;
}

export default ManualStockRefillWithoutReportNumberAction;
