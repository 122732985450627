import { useContext } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';

import { TerminalContext } from '../TerminalInit';
import { ApiError, FetchOptions, postApi } from './utils';

interface CreatePushNotificationVariables {
    body: {
        account: number;
        identifier: string;
        value: any;
    };
}

export function createPushNotification(options?: FetchOptions): (variables: CreatePushNotificationVariables) => Promise<void> {
    return async (variables: CreatePushNotificationVariables): Promise<void> => {
        const response = await postApi('/push-notifications/', variables.body, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error creating push notification');
            }
            throw new ApiError('Error creating push notification', json);
        }
        return await response.json();
    };
}

export function useMutateCreatePushNotification(options?: UseMutationOptions<unknown, unknown, CreatePushNotificationVariables>, fetchOptions?: FetchOptions) {
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(createPushNotification(fetchOptions));
}
