import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact, useMutateUpdateContact } from '../../api/contacts';
import { Order, useOrders } from '../../api/orders';
import { Product, useProducts } from '../../api/products';
import { useShops } from '../../api/shops';
import { SlotForTransaction } from '../../api/slots';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { Transaction, TransactionStatus } from '../../common/transactions';
import ContactForm, { ContactFormFields } from '../../forms/ContactForm';
import useActivity from '../../hooks/useActivity';
import useContactTransactionHistory from '../../hooks/useContactTransactionHistory';
import useProductFilter from '../../hooks/useProductFilter';
import { Logger } from '../../logs/Logger';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import ProductConfigurationView, { ProductParameter } from '../../views/nike/ProductConfigurationView';
import SelectContactView from '../../views/nike/SelectContactView';
import Pickup from '../Pickup';
import OrderUnavailableWorkflow from './OrderUnavailableWorkflow';
import { SpotAvailabilityFinder } from './SpotAvailabilityFinder';
import { isNikeContactData, isNikeProductData } from './api';
import { canOrderForOthers, isNikeEmployee, isNikeInterim, isNikeManager } from './permissions';
import { transformTransactionHistoryToOwnershipInfo } from './utils';

const historyCutoffPeriod = 365 * 24 * 60 * 60 * 1000; // 365 days duration in ms

export enum ProductAvailability {
    UNKNOWN = 'unknown',
    AVAILABLE = 'available',
    AVAILABLE_IN_STOCK = 'available_in_stock',
    ERROR_AVAILABLE_IN_STOCK = 'error_available_in_stock', // We could not check all Slots due to an error, but the product is available in stock.

    AVAILABLE_OTHER_SPOT = 'available_other_spot',
    AVAILABLE_IN_STOCK_OTHER_SPOT = 'available_in_stock_other_spot',

    NOT_AVAILABLE = 'not_available',
    ERROR = 'error'
}

export interface ProductAvailabilityResult {
    availability: ProductAvailability;
    slotsWithTransaction?: SlotForTransaction[];
}

export const nikeProductParameters: ProductParameter[] = [
    {
        name: 'size',
        getDisplayName: (intl: IntlShape) => {
            return intl.formatMessage({
                id: 'workflow.nike.VendingWorkflow.productParameters.size',
                description: "The Nike product parameter 'size'",
                defaultMessage: 'size'
            });
        },
        getValue: (product: Product) => (isNikeProductData(product.product_data) ? product.product_data.size.toString() : undefined)
    },
    {
        name: 'width',
        getDisplayName: (intl: IntlShape) => {
            return intl.formatMessage({
                id: 'workflow.nike.VendingWorkflow.productParameters.width',
                description: "The Nike product parameter 'width'",
                defaultMessage: 'width'
            });
        },
        getValue: (product: Product) => (isNikeProductData(product.product_data) ? product.product_data.width : undefined)
    },
    {
        name: 'model',
        getDisplayName: (intl: IntlShape) => {
            return intl.formatMessage({
                id: 'workflow.nike.VendingWorkflow.productParameters.model',
                description: "The Nike product parameter 'model'",
                defaultMessage: 'model'
            });
        },
        getValue: (product: Product) => (isNikeProductData(product.product_data) ? product.product_data.model : undefined)
    },
    {
        name: 'height',
        getDisplayName: (intl: IntlShape) => {
            return intl.formatMessage({
                id: 'workflow.nike.VendingWorkflow.productParameters.height',
                description: "The Nike product parameter 'height'",
                defaultMessage: 'height'
            });
        },
        getValue: (product: Product) => (isNikeProductData(product.product_data) ? product.product_data.height : undefined)
    }
];

export function getShortSummaryForNikeProduct(product: Product): string {
    const summaryParts: string[] = [];

    const productSizeParameter = nikeProductParameters.find((param) => param.name === 'size');
    const productWidthParameter = nikeProductParameters.find((param) => param.name === 'width');
    const productModelParameter = nikeProductParameters.find((param) => param.name === 'model');
    const productHeightParameter = nikeProductParameters.find((param) => param.name === 'height');
    if (productModelParameter) {
        const modelValue = productModelParameter.getValue(product);
        if (modelValue && modelValue === 'model') {
            summaryParts.push('Model');
        }
    }
    if (productSizeParameter) {
        const sizeValue = productSizeParameter.getValue(product);
        if (sizeValue) {
            summaryParts.push(sizeValue);
        }
    }
    if (productWidthParameter) {
        const widthValue = productWidthParameter.getValue(product);
        if (widthValue && widthValue === 'wide') {
            summaryParts.push('W');
        }
    }
    if (productHeightParameter) {
        const heightValue = productHeightParameter.getValue(product);
        if (heightValue && heightValue === 'high') {
            summaryParts.push('H');
        }
    }

    if (summaryParts.length > 0) {
        return summaryParts.join(' ');
    } else {
        return '?';
    }
}

interface VendingWorkflowProps {
    loggedInContact: Contact; // buyer
    surrogateContact?: Contact | null; // receiver
    lastOrder?: Transaction | null;
    onHome?: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
}

interface ProductOrderRestrictions {
    amount?: number | null;
    periodMs?: number;
}

function getOrderRestrictionsForContact(contact: Contact): ProductOrderRestrictions {
    if (isNikeManager(contact)) {
        return {}; // No restrictions
    } else if (isNikeEmployee(contact) || isNikeInterim(contact)) {
        return {
            amount: 1,
            periodMs: 365 * 24 * 60 * 60 * 1000 // 1 year
        };
    } else {
        return {
            amount: 0
        };
    }
}

function checkProductOrderRestrictions(history: Transaction[], orders: Order[], ownerContact: Contact, buyerContact: Contact): boolean {
    const restrictions: ProductOrderRestrictions = getOrderRestrictionsForContact(buyerContact);
    if (restrictions.amount === null || restrictions.amount === undefined) {
        return true;
    }
    let historyFiltered: Transaction[];
    let ordersFiltered: Order[];
    if (restrictions.periodMs) {
        const fromDate = new Date();
        fromDate.setMilliseconds(fromDate.getMilliseconds() - restrictions.periodMs);
        historyFiltered = history.filter((t) => {
            const createdDate = new Date(t.created_date);
            return createdDate > fromDate;
        });
        ordersFiltered = orders.filter((o) => {
            const createdDate = new Date(o.created_date);
            return createdDate > fromDate;
        });

        //dont take orthopedic support into account
        ordersFiltered = ordersFiltered.filter((o) => o.product_orders.find((p) => p.product === 1089) === undefined);
    } else {
        ordersFiltered = orders;
        historyFiltered = history;
    }

    const ownershipInfo = transformTransactionHistoryToOwnershipInfo(historyFiltered);

    const ownedItems = Array.from(ownershipInfo.values()).filter((ownershipInfo) => {
        // This should not be possible, but satisfy the type checker nevertheless.
        if (!ownershipInfo.transferDone && !ownershipInfo.transferInProgress) return false;
        // Count all transactions in progress for this person. These are ordered items not yet received by the receiver.
        if (ownershipInfo.transferInProgress && ownershipInfo.transferInProgress.receiver?.id === ownerContact.id) return true;
        // Also count all transactions sent by this person not yet in the distrispot.
        if (
            ownershipInfo.transferInProgress &&
            ownershipInfo.transferInProgress.sender?.id === ownerContact.id &&
            TransactionStatus.before_dropoff_states().includes(ownershipInfo.transferInProgress.status)
        )
            return true;
        // Remove all other items that are in progress (and thus not done). They are probably returns and should not count.
        if (!ownershipInfo.transferDone) return false;
        // Count all products where the transfer is done and the receiver is us.
        return ownershipInfo.transferDone.receiver?.id === ownerContact.id;
    });

    const orderedItems = ordersFiltered.filter((o) => {
        return o.fulfilled_date === undefined || o.fulfilled_date === null;
    });
    return ownedItems.length + orderedItems.length < restrictions.amount;
}

const VendingWorkflow = (props: VendingWorkflowProps) => {
    const [productsAvailability, changeProductsAvailability] = useState<Map<string, ProductAvailabilityResult>>(new Map());
    const [orderedProduct, changeOrderedProduct] = useState<Product | null>(null);

    const [loading, changeLoading] = useState<boolean>(false);
    const mutateContactUpdate = useMutateUpdateContact({
        onSuccess: (contact) => {
            changeLoading(false);
            changeContactInformationSubmitted(true);
            changeReceiverContact(contact);
        },
        onError: (err) => {
            changeLoading(false);
            Logger.error(err);
        }
    });

    const intl = useIntl();
    const [, newActivity] = useActivity();
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const {
        data: products,
        isSuccess: productsIsSuccess,
        isError: productsIsError
    } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);
    const [productsFilter] = useProductFilter();
    const [filteredProducts, changeFilteredProducts] = useState<Product[]>([]);
    const [defaultProduct, changeDefaultProduct] = useState<Product>();
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    // If the logged in contact can order for others, use the surrogate contact (if passed) as the receiver.
    const [receiverContact, changeReceiverContact] = useState<Contact | null>(
        canOrderForOthers(props.loggedInContact) ? (props.surrogateContact !== undefined ? props.surrogateContact : null) : props.loggedInContact
    );
    const [contactInformationSubmitted, changeContactInformationSubmitted] = useState<boolean>(false);

    useEffect(() => {
        if (receiverContact && products) {
            changeFilteredProducts(productsFilter({ contact: receiverContact, products: products }));
        }
    }, [products, receiverContact, productsIsSuccess]);

    useEffect(() => {
        // when products are filtered select a default product
        if (filteredProducts.length > 0) {
            let product = filteredProducts.filter(
                (p) =>
                    p.name.includes('SCHOEN') &&
                    p.name.includes('ESD') &&
                    p.name.includes('LAAG') &&
                    p.name.includes('S3') &&
                    p.name.includes('ZW/ROOD') &&
                    p.name.includes('MADDOX')
            )[0];
            if (!product) {
                product = filteredProducts[0];
            }
            changeDefaultProduct(product);
        }
    }, [filteredProducts]);

    const contactHistoryResult = useContactTransactionHistory({
        cutoffTimeDeltaMs: historyCutoffPeriod,
        contactId: receiverContact ? receiverContact.id : null,
        includeFutureTransactions: true
    });

    const ordersResult = useOrders({
        receiver: receiverContact
    });

    const contactRole = isNikeContactData(props.loggedInContact.additional_data) ? props.loggedInContact.additional_data.role : null;

    useEffect(() => {
        if (shops !== undefined && shops.length > 1) {
            Logger.error('Shops API call returned multiple shops. Nike workflow only supports one shop currently', {}, shops);
        }
    }, [shops]);

    const onCancelOrder = () => {
        newActivity();
        changeOrderedProduct(null);
    };

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    const onSelectContact = (contact: Contact) => {
        changeReceiverContact(contact);
    };

    if (!receiverContact) {
        return (
            <SelectContactView
                onSelectContact={onSelectContact}
                loggedInContact={props.loggedInContact}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else if (
        !(contactHistoryResult.isSuccess || contactHistoryResult.isError) ||
        !contactHistoryResult.data ||
        !(productsIsSuccess || productsIsError) ||
        products === undefined ||
        !(ordersResult.isSuccess || ordersResult.isError) ||
        !ordersResult.data
    ) {
        return (
            <LoadingView
                title={
                    <FormattedMessage
                        id='workflow.nike.VendingWorkflow.loadingProducts'
                        description='The message shown when searching products in the vending workflow'
                        defaultMessage='Searching available products'
                    />
                }
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else if (contactHistoryResult.isError || productsIsError) {
        return (
            <ErrorView
                navbarItems={homeNav}
                title={
                    <FormattedMessage
                        id='workflow.nike.VendingWorkflow.LoadingError'
                        description='The error message shown when products or history could not be loaded due to an error on the Nike vending workflow.'
                        defaultMessage='Unable to order items due to an error.'
                    />
                }
            />
        );
    } else if (products.length === 0) {
        return (
            <ErrorView
                navbarItems={homeNav}
                title={
                    <FormattedMessage
                        id='workflow.nike.VendingWorkflow.noProductsFound'
                        description='The error message shown when no products are found in the Nike vending workflow.'
                        defaultMessage='No products found!'
                    />
                }
            />
        );
    }

    if (!orderedProduct && !checkProductOrderRestrictions(contactHistoryResult.data, ordersResult.data, receiverContact, props.loggedInContact)) {
        return (
            <ErrorView
                navbarItems={homeNav}
                title={
                    <FormattedMessage
                        id='workflow.nike.VendingWorkflow.notAllowedToOrderTitle'
                        description='The error title shown when the employee cannot order any more items in the Nike vending workflow.'
                        defaultMessage='Order limit reached'
                    />
                }
                message={
                    <FormattedMessage
                        id='workflow.nike.VendingWorkflow.notAllowedToOrderMessage'
                        description='The error message shown when the employee cannot order any more items in the Nike vending workflow.'
                        defaultMessage="You have reached the limit on how many products you're allowed to order. Contact your team coach if you think this is incorrect."
                    />
                }
            />
        );
    }

    function onAvailabilityChange(product: Product, availability: ProductAvailabilityResult) {
        changeProductsAvailability((prevProductsAvailability) => {
            const newProductsAvailability = new Map(prevProductsAvailability); // Must copy or react won't re-render
            newProductsAvailability.set(product.id.toString(), availability);
            return newProductsAvailability;
        });
    }

    const onPlaceOrder = (product: Product) => {
        newActivity();
        if (!orderedProduct) {
            changeOrderedProduct(product);
        }
    };

    if (!orderedProduct) {
        return (
            <>
                <ProductConfigurationView
                    receiverContact={receiverContact}
                    buyerInContact={props.loggedInContact}
                    onInactivity={props.onInactivity}
                    onHome={props.onHome}
                    onPlaceOrder={onPlaceOrder}
                    productConfiguration={{
                        products: filteredProducts,
                        defaultProduct: defaultProduct,
                        name: intl.formatMessage({
                            id: 'workflow.nike.VendingWorkflow.defaultProductName',
                            description: 'A default name for the Nike product category (before selecting a product)',
                            defaultMessage: 'SCHOEN ESD LAAG S3 ZW/ROOD MADDOX ELTEN'
                        }),
                        parameters: nikeProductParameters,
                        productsAvailability: productsAvailability
                    }}
                />
                {filteredProducts ? (
                    filteredProducts.map((product) => {
                        return (
                            <SpotAvailabilityFinder
                                key={product.id}
                                propsOnAvailabilityChange={(availability) => {
                                    onAvailabilityChange(product, availability);
                                }}
                                product={product}
                                role={contactRole}
                                spot={spot}
                                checkNearby={true}
                            />
                        );
                    })
                ) : (
                    <></>
                )}
            </>
        );
    } else {
        const productAvailability = productsAvailability.get(orderedProduct.id.toString());
        if (
            productAvailability &&
            (productAvailability.availability === ProductAvailability.AVAILABLE ||
                (isNikeManager(props.loggedInContact) &&
                    (productAvailability.availability === ProductAvailability.AVAILABLE_IN_STOCK ||
                        productAvailability.availability === ProductAvailability.ERROR_AVAILABLE_IN_STOCK))) &&
            productAvailability.slotsWithTransaction &&
            productAvailability.slotsWithTransaction.length > 0
        ) {
            Logger.log(
                'Vending product selected (and found as available) proceeding to pickup',
                { product: orderedProduct.id, contact: receiverContact.id },
                orderedProduct,
                { receiver: receiverContact }
            );
            return (
                <Pickup
                    slot={productAvailability.slotsWithTransaction[0]}
                    transaction={productAvailability.slotsWithTransaction[0].transaction}
                    skipConfirm={true}
                    receiver={receiverContact}
                    onInactivity={props.onInactivity}
                    onHome={props.onLogout}
                />
            );
        } else {
            Logger.log(
                'We are ordering a product that is not available',
                { product: orderedProduct.id, contact: receiverContact.id },
                orderedProduct,
                receiverContact,
                props.loggedInContact
            );
            if (contactInformationSubmitted) {
                return (
                    <OrderUnavailableWorkflow
                        buyerContact={props.loggedInContact}
                        receiverContact={receiverContact}
                        product={orderedProduct}
                        onCancel={onCancelOrder}
                        onInactivity={props.onInactivity}
                        onLogout={props.onLogout}
                    />
                );
            } else {
                const title = intl.formatMessage({
                    id: 'vending.order.addContactInfo.title',
                    description: 'Message telling the user to provide at least a email or phonenumber before placing an order.',
                    defaultMessage:
                        'Please provide us with either an email and/or a phonenumber. This information is needed to contact you when the product you ordered is available.'
                });

                return (
                    <ContactForm
                        title={title}
                        loading={loading}
                        onSubmit={(contact: Contact) => {
                            changeLoading(true);
                            mutateContactUpdate.mutate({ contact: contact });
                        }}
                        existingContact={receiverContact}
                        accountId={spot!.account!}
                        fields={[ContactFormFields.EMAIL, ContactFormFields.PHONENUMBER]}
                        requiredFields={[ContactFormFields.PHONENUMBER]}
                        onInactivity={props.onInactivity}
                        onBack={onCancelOrder}
                        onHome={props.onHome}
                    />
                );
            }
        }
    }
};

export default VendingWorkflow;
