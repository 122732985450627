import { useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext } from '../../TerminalInit';
import { useContactGroup } from '../../api/contactGroups';
import { NotificationsConfigProfile, TransactionType } from '../../common/transactions';
import { Logger } from '../../logs/Logger';
import { preferNonStockSlot } from '../../services/slot/SlotFilter';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';
import ReviewReturnedProductView from '../../views/nike/ReviewReturnedProductView';
import ViewTitle from '../../views/nike/ViewTitle';
import JustDrop from '../JustDrop';
import CompletedTransactionWorkflow from './CompletedTransactionWorkflow';
import { isNikeTerminalData } from './api';
import { ProductInstanceOwnership } from './utils';

enum ReviewResolution {
    PUT_BACK = 'put_back',
    DISPOSE = 'dispose',
    RETURN_TO_STOCK = 'return_to_stock',
    SLOT_EMPTY = 'slot_empty'
}

interface ReviewReturnedProductWorkflowProps {
    ownedProductInstance: ProductInstanceOwnership;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
}

export default function ReviewReturnedProductWorkflow(props: ReviewReturnedProductWorkflowProps) {
    const [reviewResolution, changeReviewResolution] = useState<null | ReviewResolution>(null);

    const { terminal } = useContext(TerminalContext);
    const returnGroupId = terminal && isNikeTerminalData(terminal.additional_data) ? terminal.additional_data.return_items_contact_group_id : undefined;
    const returnGroupResult = useContactGroup(undefined, returnGroupId);

    const onReviewResolution = useCallback(
        (resolution: ReviewResolution | null) => {
            Logger.log(
                'Review resolution is',
                { transaction: props.ownedProductInstance.productInstance.transaction?.id, product: props.ownedProductInstance.productInstance.product },
                resolution
            );
            changeReviewResolution(resolution);
        },
        [changeReviewResolution]
    );

    const removedItemsContactGroupId =
        terminal && isNikeTerminalData(terminal?.additional_data) ? terminal.additional_data.removed_items_contact_group_id : undefined;
    const damagedItemsContactGroupId =
        terminal && isNikeTerminalData(terminal?.additional_data) ? terminal.additional_data.damaged_items_contact_group_id : undefined;

    const onBack = () => {
        onReviewResolution(null);
    };
    const backHomeNav = (
        <BackAndHomeNavigationButtons
            onBack={onBack}
            onHome={props.onHome}
        />
    );
    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    if (reviewResolution === null) {
        return (
            <ReviewReturnedProductView
                onPutBack={() => {
                    onReviewResolution(ReviewResolution.PUT_BACK);
                }}
                onSlotEmpty={
                    damagedItemsContactGroupId
                        ? () => {
                              onReviewResolution(ReviewResolution.SLOT_EMPTY);
                          }
                        : undefined
                }
                onDispose={
                    damagedItemsContactGroupId
                        ? () => {
                              onReviewResolution(ReviewResolution.DISPOSE);
                          }
                        : undefined
                }
                onReturnToStock={
                    removedItemsContactGroupId
                        ? () => {
                              onReviewResolution(ReviewResolution.RETURN_TO_STOCK);
                          }
                        : undefined
                }
                productInstanceOwned={props.ownedProductInstance}
                onInactivity={props.onInactivity}
                onBack={props.onBack}
                onHome={props.onHome}
            />
        );
    } else if (reviewResolution === ReviewResolution.PUT_BACK) {
        if (!(returnGroupResult.isSuccess || returnGroupResult.isError)) {
            return (
                <LoadingView
                    onBack={onBack}
                    onHome={props.onHome}
                />
            );
        } else if (returnGroupResult.isError || !returnGroupResult.data) {
            return <ErrorView navbarItems={backHomeNav} />;
        } else {
            return (
                <JustDrop
                    senderData={{
                        sender: returnGroupResult.data
                    }}
                    receiverData={{}}
                    autoAssignSlot={true}
                    onBack={onBack}
                    onHome={props.onHome}
                    onInactivity={props.onInactivity}
                    productInstances={[props.ownedProductInstance.productInstance]}
                    autoAssignFilter={preferNonStockSlot}
                    notificationsConfig={{ profile: NotificationsConfigProfile.NO_NOTIFICATIONS }}
                    transactionType={TransactionType.VENDING}
                />
            );
        }
    } else if (reviewResolution === ReviewResolution.RETURN_TO_STOCK) {
        if (!removedItemsContactGroupId || !returnGroupId) {
            throw new Error('Expected removed items contact group id');
        }
        return (
            <CompletedTransactionWorkflow
                senderData={{
                    contactGroupId: returnGroupId
                }}
                receiverData={{
                    contactGroupId: removedItemsContactGroupId
                }}
                productInstanceIds={[props.ownedProductInstance.productInstance.id]}>
                <BaseView
                    onInactivity={props.onInactivity}
                    navbarItems={homeNav}>
                    <ViewTitle>
                        <FormattedMessage
                            id='workflow.nike.ReviewReturnedProductWorkflow.removedSuccessTitle'
                            description='This is the title in the review product workflow when the item has been successfully marked as removed from the DistriSPOT.'
                            defaultMessage='The item has been marked as removed'
                        />
                    </ViewTitle>
                </BaseView>
            </CompletedTransactionWorkflow>
        );
    } else if (reviewResolution === ReviewResolution.DISPOSE) {
        if (!damagedItemsContactGroupId || !returnGroupId) {
            throw new Error('Expected damaged items contact group id');
        }
        return (
            <CompletedTransactionWorkflow
                senderData={{
                    contactGroupId: returnGroupId
                }}
                receiverData={{
                    contactGroupId: damagedItemsContactGroupId
                }}
                productInstanceIds={[props.ownedProductInstance.productInstance.id]}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
                shippingNotes={'The item was reported to be damaged.'}>
                <BaseView
                    onInactivity={props.onInactivity}
                    navbarItems={homeNav}>
                    <ViewTitle>
                        <FormattedMessage
                            id='workflow.nike.ReviewReturnedProductWorkflow.disposedSuccessTitle'
                            description='This is the title in the review product workflow when the item has been successfully marked as disposed off.'
                            defaultMessage='The item has been marked as disposed off'
                        />
                    </ViewTitle>
                </BaseView>
            </CompletedTransactionWorkflow>
        );
    }
    if (reviewResolution === ReviewResolution.SLOT_EMPTY) {
        if (!damagedItemsContactGroupId || !returnGroupId) {
            throw new Error('Expected damaged items contact group id');
        }
        return (
            <CompletedTransactionWorkflow
                senderData={{
                    contactGroupId: returnGroupId
                }}
                receiverData={{
                    contactGroupId: damagedItemsContactGroupId
                }}
                productInstanceIds={[props.ownedProductInstance.productInstance.id]}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
                shippingNotes={'The item was not present in the slot.'}>
                <BaseView
                    onInactivity={props.onInactivity}
                    navbarItems={homeNav}>
                    <ViewTitle>
                        <FormattedMessage
                            id='workflow.nike.ReviewReturnedProductWorkflow.disposedSuccessTitle'
                            description='This is the title in the review product workflow when the item has been successfully marked as disposed off.'
                            defaultMessage='The item has been marked as disposed off'
                        />
                    </ViewTitle>
                </BaseView>
            </CompletedTransactionWorkflow>
        );
    } else {
        throw new Error(`Unexpected review resolution ${reviewResolution}`);
    }
}
