declare global {
    interface WindowEventMap {
        'terminal:Proton': CustomEvent<any>;
        'terminal:Scanner': CustomEvent<any>;
        'terminal:OpenSlotRange': CustomEvent<any>;
        'terminal:OpenSlot': CustomEvent<any>;
    }
}
export enum TerminalEvents {
    PROTON = 'terminal:Proton',
    SCANNER_RESULT = 'terminal:Scanner',
    OPEN_SLOT_RANGE = 'terminal:OpenSlotRange',
    OPEN_SLOT = 'terminal:OpenSlot'
}
