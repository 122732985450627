import 'react-app-polyfill/stable';
import '@formatjs/intl-getcanonicallocales/polyfill'  // (react-intl) Polyfill required because chrome 52 < 54
import '@formatjs/intl-locale/polyfill'  // (react-intl) Polyfill required because chrome 52 < 74
import '@formatjs/intl-listformat/polyfill'  // (react-intl) Polyfill required because chrome 52 < 72
import '@formatjs/intl-listformat/locale-data/en' // locale-data for en
import '@formatjs/intl-listformat/locale-data/nl' // locale-data for nl
import '@formatjs/intl-listformat/locale-data/fr' // locale-data for fr
import '@formatjs/intl-displaynames/polyfill'  // (react-intl) Polyfill required because chrome 52 < 81
import '@formatjs/intl-displaynames/locale-data/en' // locale-data for en
import '@formatjs/intl-displaynames/locale-data/nl' // locale-data for nl
import '@formatjs/intl-displaynames/locale-data/fr' // locale-data for fr
import '@formatjs/intl-pluralrules/polyfill'  // (react-intl) Polyfill required because chrome 52 < 63
import '@formatjs/intl-pluralrules/locale-data/en' // locale-data for en
import '@formatjs/intl-pluralrules/locale-data/nl' // locale-data for nl
import '@formatjs/intl-pluralrules/locale-data/fr' // locale-data for fr
import '@formatjs/intl-numberformat/polyfill'  // (react-intl) Polyfill required because chrome 52 does not support Intl.NumberFormat (ES2020)
import '@formatjs/intl-numberformat/locale-data/en' // locale-data for en
import '@formatjs/intl-numberformat/locale-data/nl' // locale-data for nl
import '@formatjs/intl-numberformat/locale-data/fr' // locale-data for fr
import '@formatjs/intl-relativetimeformat/polyfill'  // (react-intl) Polyfill required because chrome 52 < 71
import '@formatjs/intl-relativetimeformat/locale-data/en' // locale-data for en
import '@formatjs/intl-relativetimeformat/locale-data/nl' // locale-data for nl
import '@formatjs/intl-relativetimeformat/locale-data/fr' // locale-data for fr
import '@formatjs/intl-datetimeformat/polyfill'  // (react-intl) Polyfill required because chrome 52 does not support Intl.DateTimeFormat (ES2020)
import '@formatjs/intl-datetimeformat/locale-data/en' // locale-data for en
import '@formatjs/intl-datetimeformat/locale-data/nl' // locale-data for nl
import '@formatjs/intl-datetimeformat/locale-data/fr' // locale-data for fr
import '@formatjs/intl-datetimeformat/add-golden-tz'  // We only add the most common timezone information to keep the build smaller
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


function render(serviceWorkerUpdating) {
  ReactDOM.render(
    <React.StrictMode>
      <App serviceWorkerUpdating={serviceWorkerUpdating}/>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

render(false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
navigator.serviceWorker.ready.then((registration) => {
  if (registration.waiting && registration.active) {
    console.log('Found an existing waiting service worker');
    render(true);
  }
});

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    window.location.href = '/';
    render(true);
  }
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
