import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, UseQueryOptions, useQuery } from 'react-query';

import { TerminalContext } from '../TerminalInit';
import { ApiViewSet, DetailOptions, apiDetail, apiList } from './baseApi';
import { ApiQueryParams, queryParamsToCacheKeys } from './baseQueryParams';
import { Shop } from './shops';
import { FetchOptions } from './utils';

export interface Category {
    id: number;
    url: string;
    name: string;
    image: string | null;
    parent_id: number | null;
    children: Category[];
    account: number;
    attributes: number[];
}

export function isRoot(category: Category): boolean {
    return !category.parent_id;
}
export function isFinalCategory(category: Category): boolean {
    return !category.children || category.children.length === 0;
}

const defaultConfig = {
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity
};
const categoriesViewSet: ApiViewSet = {
    baseName: 'category-trees'
};
const categoryViewSet: ApiViewSet = {
    baseName: 'categories'
};

enum CategoriesQueryParams {
    NAME = 'name',
    PARENT = 'parent',
    ACCOUNT = 'account',
    SHOP = 'shop'
}
export interface CategoryOptions {
    name?: string;
    parent?: string;
    account?: number;
    shop?: string | number | null;
}

function fetchCategoriesApi(queryParams?: ApiQueryParams<CategoriesQueryParams> | null, fetchOptions?: FetchOptions): () => Promise<Category[]> {
    return apiList<Category, CategoriesQueryParams>(categoriesViewSet, queryParams, fetchOptions);
}

function fetchCategoryApi(options: DetailOptions, fetchOptions?: FetchOptions): () => Promise<Category> {
    return apiDetail<Category>(categoryViewSet, options, fetchOptions);
}

export function useCategories(options?: CategoryOptions, queryOptions?: UseQueryOptions<Category[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    const apiOptions = {
        name: options?.name,
        parent: options?.parent,
        account: options?.account,
        shop: options?.shop?.toString()
    } as ApiQueryParams<CategoriesQueryParams>;
    const queryParams: ApiQueryParams<CategoriesQueryParams> = {
        ...apiOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Category[]>(
        ['categories', queryParamsToCacheKeys(CategoriesQueryParams, queryParams)],
        fetchCategoriesApi(queryParams, fetchOptions),
        config
    );
}

export async function prefetchCategories(queryClient: QueryClient, shops: Shop[], options?: FetchQueryOptions<Category[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...options
    };

    await Promise.all(
        shops.map((shop) => {
            return (async () => {
                await queryClient.fetchQuery(['category-trees', shop.id.toString()], fetchCategoriesApi({ shop: shop.id.toString() }, fetchOptions), config);
            })();
        })
    );
}
