import Connection from '../../Connection';
import { TerminalEvents } from '../../events/terminal/Events';
import ProtonDevice from '../proton/ProtonDevice';
import { ProtonPacket } from '../proton/ProtonPacket';
import { ProtonPacketUtils } from '../proton/ProtonPacketUtils';
import ProtonProduct from '../proton/ProtonProduct';
import { isBindProductsResponse } from '../proton/responses/BindProductsResponse';
import { isConfigureScalesResponse } from '../proton/responses/ConfigureScalesResponse';
import { isDoZeroResponse } from '../proton/responses/DoZeroResponse';
import { isHealthCheckResponse } from '../proton/responses/HealthCheckResponse';
import IScalesService from './IScalesService';

export enum ProtonCommands {
    ARE_U_OK = 'ruok',
    DEFINE_DEVICE = 'device.set_definition',
    ZERO_DEVICE = 'cluster.do_zero',
    BIND_PRODUCT = 'dau.set_data_binding',
    GET_DATA = 'dau.get',

    //Requests/events
    WEIGHT_CHANGE = 'dau.count_data_event', //Proton will throw 2 different events on weight change. This one and "dau.weight_data_event". They are the exacts same event except for the name of the command (thus ignoring one of them).
    STATE_EVENT = 'dau.state_event',
    HEARTBEAT = 'proto_tiny.heartbeat'
}

/**
 * GET_DATA command gives back the same information as the WEIGHT_CHANGE event
 * thus we could ignore the event and implement the polling ourself
 * the question is if we actually want this
 *
 * The android is also still sending me my own requests
 * this is not breaking but it is very anoying since everything gets flooded and this makes it hard to debug
 */

export default class ProtonScalesService implements IScalesService {
    connection: Connection;

    constructor(connection: Connection) {
        this.connection = connection;
    }

    /**
     *
     * @returns true is the scales are ok (if not ok it will return false)
     */
    async healthCheck(): Promise<boolean> {
        const packet = new ProtonPacket(ProtonCommands.ARE_U_OK, 'request');
        packet.getHeader().setSign(ProtonPacketUtils.sign(packet));
        this.connection.broadcast(packet);

        const trace = packet.getHeader().getTrace();
        let timeout: NodeJS.Timeout;
        return new Promise((resolve) => {
            const eventHandler = (event: CustomEvent<any>) => {
                if (isHealthCheckResponse(event.detail) && event.detail.header.trace === trace) {
                    resolve(event.detail.payload.state === 'IMOK');
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    resolve(false);
                    clearTimeout(timeout);
                }, 30 * 1000);
            };
            window.addEventListener(TerminalEvents.PROTON, eventHandler);
        });
    }

    async configureScales(stationId: string, devices: string[]): Promise<void> {
        const body = {
            version: 1,
            devices: devices
        };
        const packet = new ProtonPacket(ProtonCommands.DEFINE_DEVICE, 'request', body);
        packet.getHeader().setSign(ProtonPacketUtils.sign(packet));
        console.log(stationId, packet);
        this.connection.send(stationId, packet);

        const trace = packet.getHeader().getTrace();
        let timeout: NodeJS.Timeout;
        return new Promise((resolve, reject) => {
            const eventHandler = (event: CustomEvent<any>) => {
                if (isConfigureScalesResponse(event.detail) && event.detail.header.trace === trace) {
                    if (event.detail.ext.resCode === 200) {
                        window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                        clearTimeout(timeout);
                        resolve();
                    } else {
                        window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                        clearTimeout(timeout);
                        reject(Error('Error ' + event.detail.ext.resCode + ': ' + event.detail.ext.resMsg));
                    }
                }
                timeout = setTimeout(() => {
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    clearTimeout(timeout);
                    reject();
                }, 30 * 1000);
            };
            window.addEventListener(TerminalEvents.PROTON, eventHandler);
        });
    }

    async configureAll(devices: ProtonDevice[]): Promise<void> {
        const deviceInfo: Map<string, string[]> = new Map<string, string[]>();
        devices.forEach((s) => {
            const exists = deviceInfo.get(s.stationId);
            if (exists !== undefined) {
                deviceInfo.set(s.stationId, [...exists, ...s.getMapping()]);
            } else {
                deviceInfo.set(s.stationId, s.getMapping());
            }
        });
        await Promise.all(Array.from(deviceInfo.entries()).map((val) => this.configureScales(val[0], val[1])));
    }

    /**
     * Devices should all have the same station Id otherwise the command may not function as expected.
     * @param devices
     * @returns
     */
    async zeroScales(devices: ProtonDevice[]): Promise<boolean> {
        const packet = new ProtonPacket(
            ProtonCommands.ZERO_DEVICE,
            'request',
            devices.map((d) => d.clusterUri)
        );
        const stationId = devices[0].stationId;

        packet.getHeader().setSign(ProtonPacketUtils.sign(packet));
        this.connection.send(stationId, packet);

        const trace = packet.getHeader().getTrace();
        let timeout: NodeJS.Timeout;
        return new Promise((resolve) => {
            const eventHandler = (event: CustomEvent<any>) => {
                if (isDoZeroResponse(event.detail) && event.detail.header.trace === trace) {
                    resolve(event.detail.ext.resCode === 200 && event.detail.ext.resMsg === 'OK');
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    clearTimeout(timeout);
                } else resolve(false);
                timeout = setTimeout(() => {
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    resolve(false);
                    clearTimeout(timeout);
                }, 30 * 1000);
            };
            window.addEventListener(TerminalEvents.PROTON, eventHandler);
        });
    }

    async bindProducts(stationId: string, products: ProtonProduct[]): Promise<void> {
        const packet = new ProtonPacket(ProtonCommands.BIND_PRODUCT, 'request', {
            version: 1,
            expectDeviceVersion: null,
            daus: products
        });
        packet.getHeader().setSign(ProtonPacketUtils.sign(packet));
        console.log(packet);
        this.connection.send(stationId, packet);
        const trace = packet.getHeader().getTrace();
        let timeout: NodeJS.Timeout;
        return new Promise((resolve, reject) => {
            const eventHandler = (event: CustomEvent<any>) => {
                if (isBindProductsResponse(event.detail) && event.detail.header.trace === trace) {
                    resolve();
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    reject();
                    clearTimeout(timeout);
                }, 30 * 1000);
            };
            window.addEventListener(TerminalEvents.PROTON, eventHandler);
        });
    }

    async bindAllProducts(productMap: Map<string, ProtonProduct[]>): Promise<void> {
        await Promise.all(Array.from(productMap.entries()).map((val) => this.bindProducts(val[0], val[1])));
    }

    async getData(devices: ProtonDevice[]): Promise<unknown> {
        const packet = new ProtonPacket(
            ProtonCommands.GET_DATA,
            'request',
            devices.map((d) => d.clusterUri)
        );

        packet.getHeader().setSign(ProtonPacketUtils.sign(packet));
        this.connection.broadcast(packet);

        const trace = packet.getHeader().getTrace();
        let timeout: NodeJS.Timeout;
        return new Promise((resolve, reject) => {
            const eventHandler = (event: CustomEvent<any>) => {
                if (event.detail.header.action === ProtonCommands.GET_DATA && event.detail.header.trace === trace) {
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    clearTimeout(timeout);
                    resolve(event.detail);
                }
                timeout = setTimeout(() => {
                    window.removeEventListener(TerminalEvents.PROTON, eventHandler);
                    reject();
                    clearTimeout(timeout);
                }, 30 * 1000);
            };

            window.addEventListener(TerminalEvents.PROTON, eventHandler);
        });
    }
}
