import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { useMutateCreateOrder } from '../../api/orders';
import { Product } from '../../api/products';
import { useShops } from '../../api/shops';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { Logger } from '../../logs/Logger';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import ErrorView from '../../views/common/ErrorView';
import LoadingView from '../../views/common/LoadingView';

interface OrderUnavailableWorkflowProps {
    product: Product;
    buyerContact: Contact;
    receiverContact: Contact;
    onCancel: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
}

const OrderUnavailableWorkflow = (props: OrderUnavailableWorkflowProps) => {
    const [confirmed, changeConfirmed] = useState(false);
    const [ordered, changeOrdered] = useState(false);
    const [error, changeError] = useState(false);
    const createOrder = useMutateCreateOrder();

    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);

    useEffect(() => {
        if (confirmed) {
            Logger.log('Ordering item now!');
            // Can change this to ordered immediately. The service worker should make sure the
            createOrder
                .mutateAsync({
                    createOrder: {
                        receiver: props.receiverContact.id,
                        buyer: props.buyerContact.id,
                        shop: shops && shops.length > 0 ? shops[0].id : undefined,
                        additional_data: {
                            spot: spot ? spot.id : undefined
                        },
                        product_orders: [
                            {
                                product: props.product.id,
                                amount: 1
                            }
                        ]
                    }
                })
                .then((result) => {
                    Logger.log(
                        'Item ordered',
                        {
                            spot: spot?.id,
                            shop: shops && shops.length > 0 ? shops[0].id : undefined,
                            product: props.product.id,
                            contact: props.receiverContact.id
                        },
                        result
                    );
                    changeOrdered(true);
                })
                .catch(() => {
                    changeError(true);
                });
        }
    }, [confirmed]);

    const onConfirm = () => {
        changeConfirmed(true);
    };

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onLogout} />;

    if (!confirmed) {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}>
                <h3 className='text-center mt-4'>
                    <FormattedMessage
                        id='workflow.nike.OrderUnavailableWorkflow.confirmTitle'
                        description='The title on the order product page before confirming the actual order. (nike workflow)'
                        defaultMessage='Confirm product order'
                    />
                </h3>
                <p className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.OrderUnavailableWorkflow.confirmMessage'
                        description='The first paragraph of the message on the order product page before confirming the actual order. (nike workflow)'
                        defaultMessage='You are about to place an order for {productName}. This product will be delivered to this DistriSPOT. You or your team coach will receive further instructions on how to pick it up.'
                        values={{
                            productName: <span className='font-weight-bold'>{props.product.name}</span>
                        }}
                    />
                </p>
                <div className='d-flex d-row justify-content-center'>
                    <div className='col d-flex'>
                        <button
                            className='light-button btn-lg mt-4 flex-grow-1 me-2'
                            onClick={props.onCancel}>
                            <FormattedMessage
                                id='views.nike.OrderUnavailableWorkflow.cancelButton'
                                description='The button on the order products page to cancel the ordering of the product'
                                defaultMessage='Cancel'
                            />
                        </button>
                    </div>
                    <div className='col d-flex'>
                        <button
                            className='primary-button btn-lg mt-4 flex-grow-1 me-2'
                            onClick={onConfirm}>
                            <FormattedMessage
                                id='views.nike.OrderUnavailableWorkflow.confirmButton'
                                description='The button on the order products page to confirm the ordering of the product'
                                defaultMessage='Confirm order'
                            />
                        </button>
                    </div>
                </div>
            </BaseView>
        );
    }
    if (!confirmed && !error) {
        return (
            <LoadingView
                onInactivity={props.onInactivity}
                title={
                    <FormattedMessage
                        id='workflow.nike.OrderUnavailableWorkflow.orderingTitle'
                        description='The title on the order product page while waiting for the product to be ordered. (nike workflow)'
                        defaultMessage='Ordering product. Please wait...'
                    />
                }
            />
        );
    } else if (error) {
        return (
            <ErrorView
                navbarItems={homeNav}
                onInactivity={props.onInactivity}
                title={
                    <FormattedMessage
                        id='workflow.nike.OrderUnavailableWorkflow.errorTitle'
                        description='The title on the order product page when something went wrong ordering the product. (nike workflow)'
                        defaultMessage='Could not order product. Please try again or contact your team coach.'
                    />
                }
            />
        );
    } else {
        return (
            <BaseView
                onInactivity={props.onInactivity}
                navbarItems={homeNav}>
                <h3 className='text-center mt-4'>
                    <FormattedMessage
                        id='workflow.nike.OrderUnavailableWorkflow.orderedTitle'
                        description='The title on the order product page after having confirmed the actual order. (nike workflow)'
                        defaultMessage='Product ordered'
                    />
                </h3>
                <p className='text-center'>
                    <FormattedMessage
                        id='workflow.nike.OrderUnavailableWorkflow.orderedMessage'
                        description='The first paragraph of the message on the order product page after having confirmed the actual order. (nike workflow)'
                        defaultMessage='Your order has been submitted. You or your coach will be contacted with further instructions.'
                    />
                </p>
                {props.onLogout ? (
                    <p className='text-center'>
                        <button
                            className='primary-button'
                            onClick={() => props.onLogout!()}>
                            <FormattedMessage
                                id='views.nike.OrderUnavailableWorkflow.doneButton'
                                description='The button on the order products page to leave the page'
                                defaultMessage='I am done. Sign me out.'
                            />
                        </button>
                    </p>
                ) : (
                    <></>
                )}
            </BaseView>
        );
    }
};

export default OrderUnavailableWorkflow;
