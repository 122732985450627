import { useContext } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';

const useTerminal = () => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    return terminal;
};

export default useTerminal;
