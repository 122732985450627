import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface DefaultListProps {
    elements: JSX.Element[];
    headers: JSX.Element[];
    filter?: (value: string) => JSX.Element[];
    asyncFilter?: (value: string) => Promise<JSX.Element[]>;
}

const DefaultList = (props: DefaultListProps): JSX.Element => {
    const [actualList, changeActualList] = useState<JSX.Element[]>(props.elements);
    const [visableElementsAmount, changeVisableElementsAmount] = useState<number>(20);
    const [isLoading, changeIsLoading] = useState<boolean>(false);
    const spinnerStyling = { width: '3rem', height: '3rem' };
    return (
        <div className='default-list'>
            {props.filter || props.asyncFilter ? (
                <span className='list-filter-input'>
                    <input
                        className='form-control'
                        type='text'
                        onChange={async (event) => {
                            if (props.filter) {
                                changeActualList(props.filter!(event.target.value));
                            } else {
                                changeIsLoading(true);
                                const result = await props.asyncFilter!(event.target.value);
                                changeActualList(result);
                                changeIsLoading(false);
                            }
                        }}
                    />
                </span>
            ) : (
                <></>
            )}
            <div className='default-list'>
                <div>
                    <table>
                        <thead>
                            <tr>{props.headers}</tr>
                        </thead>
                        <tbody>
                            {actualList.length > 0 ? (
                                <>
                                    {isLoading ? (
                                        <tr onClick={() => changeVisableElementsAmount(visableElementsAmount + 20)}>
                                            <td
                                                colSpan={2}
                                                className='text-center d-flex flex-column p-2'>
                                                <div
                                                    className='spinner-border text-primary align-self-center'
                                                    role='status'
                                                    style={spinnerStyling}></div>
                                                <FormattedMessage
                                                    id='views.nike.LoadingView.defaultTitle'
                                                    description='The default title on the Nike loading screen'
                                                    defaultMessage='Loading. Please wait...'
                                                />
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {actualList.length > 5 ? (
                                                <>
                                                    {actualList.slice(0, visableElementsAmount)}
                                                    {actualList.length > visableElementsAmount ? (
                                                        <tr onClick={() => changeVisableElementsAmount(visableElementsAmount + 20)}>
                                                            <td
                                                                colSpan={2}
                                                                className='text-center'>
                                                                <p>show more</p>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            ) : (
                                                actualList
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td
                                        colSpan={2}
                                        className='text-center'>
                                        <p>
                                            <FormattedMessage
                                                id='views.elements.DefaultList.emptyMessage'
                                                description="The message that's displayed when no elements are available"
                                                defaultMessage='No results where found.'
                                            />
                                        </p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default DefaultList;
