import { AccessToken } from '../Connection';
import { Logger } from '../logs/Logger';

export const refreshOAuth2Token = async (refreshToken: string) => {
    const params = new Map();
    params.set('grant_type', 'refresh_token');
    params.set('refresh_token', refreshToken);
    params.set('client_id', 'terminal');
    const searchParams = new URLSearchParams();
    for (const [key, value] of params.entries()) {
        searchParams.set(key, value);
    }
    const resp = await fetch(process.env.REACT_APP_TOKEN_URL!, {
        method: 'POST',
        body: searchParams
    });
    if (!resp.ok) {
        let errorBody;
        try {
            errorBody = await resp.json();
        } catch (e) {
            throw new Error('Could not refresh token ' + refreshToken);
        }
        Logger.error('Refresh token failed.', {}, errorBody);
        throw new Error('Could not refresh token ' + refreshToken + '.');
    }
    return await resp.json();
};

export const fetchOAuth2Token = async (code: string, changeAccessToken: (token: AccessToken) => void) => {
    const params = new Map();
    params.set('grant_type', 'authorization_code');
    params.set('code', code);
    params.set('redirect_uri', process.env.REACT_APP_REDIRECT_URL);
    params.set('client_id', 'terminal');
    const searchParams = new URLSearchParams();
    for (const [key, value] of params.entries()) {
        searchParams.set(key, value);
    }
    const resp = await fetch(process.env.REACT_APP_TOKEN_URL!, {
        method: 'POST',
        body: searchParams
    });
    if (!resp.ok) {
        let errorBody;
        try {
            errorBody = await resp.json();
        } catch (e) {
            throw new Error('Could not refresh token ' + code);
        }
        throw new Error('Could not refresh token ' + code + '. Response: ' + errorBody);
    }
    return await resp.json().then((result) => {
        window.localStorage.setItem('oauth2Result', JSON.stringify(parseResponseToAccessToken(result)));
        changeAccessToken(parseResponseToAccessToken(result));
    });
};

export function parseResponseToAccessToken(token: any): AccessToken {
    const exp = new Date();
    exp.setHours(new Date().getHours() + 12);
    return {
        refreshToken: token.refresh_token,
        accessToken: token.access_token,
        tokenType: token.token_type,
        expires: exp
    };
}
