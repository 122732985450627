import { merge } from 'lodash';

import { AccessToken } from '../Connection';
import { Logger } from '../logs/Logger';

export interface FetchOptions {
    accessToken?: AccessToken | null;
    includeAccessToken?: boolean;
}

export class ApiError extends Error {
    json?: unknown;

    constructor(message?: string, json?: string, ...data: any) {
        super(message);

        Object.setPrototypeOf(this, ApiError.prototype);
        this.json = json;

        Logger.error(message, {}, json, data);
    }
}

export interface ApiNonFieldErrors {
    non_field_errors: string[];
}

export function isApiError(error: unknown): error is ApiError {
    return error !== undefined && error !== null && (error as ApiError).json !== undefined;
}

export function isApiErrorNonFieldErrors(json: unknown): json is ApiNonFieldErrors {
    return json !== undefined && json !== null && (json as ApiNonFieldErrors).non_field_errors !== undefined;
}

export function api(endpoint: string): string {
    return process.env.REACT_APP_API_URL! + endpoint;
}

export function fetchApi(endpoint: string, init?: RequestInit, options?: FetchOptions): Promise<Response> {
    if (options && options.includeAccessToken && options.accessToken) {
        init = merge(init, {
            headers: {
                authorization: 'bearer ' + options.accessToken.accessToken
            }
        });
    }
    if (!endpoint.startsWith('http')) {
        endpoint = api(endpoint);
    }
    Logger.log(`Sending request to: ${endpoint.includes('/api/') && endpoint.split('/api/').length > 1 ? endpoint.split('/api/')[1] : endpoint}`, {}, init);
    return window.fetch(endpoint, init);
}

export function postApi(endpoint: string, data?: any, options?: FetchOptions): Promise<Response> {
    return fetchApi(
        endpoint,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data ? JSON.stringify(data) : ''
        },
        options
    );
}

export function putApi(endpoint: string, data?: any, options?: FetchOptions): Promise<Response> {
    return fetchApi(
        endpoint,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data ? JSON.stringify(data) : ''
        },
        options
    );
}

/**
 * @param values
 * @param seperator
 * @returns
 */
export function concatenateString(values: string[], seperator: string) {
    return values.reduce((pre, next) => {
        return pre + seperator + next;
    });
}
