import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ActionCard from '../../components/domain/ActionCard';
import ActionCardGridElement from '../../components/elements/ActionCardGridElement';
import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';
import ScannerModal from '../elements/ScannerModal';
import ViewTitle from '../nike/ViewTitle';

interface UserRegisteredViewProps {
    actions: ActionCard[];
    onSelectAction: (action: ActionCard) => void;
    onHome: () => void;
}

const UserRegisteredView = (props: UserRegisteredViewProps) => {
    const [showScanModal, changeShowScanModal] = useState(false);
    const backHomeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    const onSelect = (action: ActionCard) => {
        if (action.actionText.includes('QR') || action.actionText.includes('NFC')) {
            changeShowScanModal(true);
        } else {
            props.onSelectAction(action);
        }
    };

    return (
        <BaseView
            onInactivity={props.onHome}
            navbarItems={backHomeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.lending.UserReistered.UserRegisteredView.title'
                    description='The title on the UserRegisteredView.'
                    defaultMessage='Thank you for registering. You will now recieve your access code via email or SMS.'
                />
            </ViewTitle>
            <ActionCardGridElement
                actions={props.actions}
                onSelectAction={onSelect}
            />
            <ScannerModal
                show={showScanModal}
                changeShow={changeShowScanModal}
                lending={true}
            />
        </BaseView>
    );
};

export default UserRegisteredView;
