import { FormattedMessage } from 'react-intl';

import BackAndHomeNavigationButtons from '../common/BackAndHomeNavigationButtons';
import BaseView from '../common/BaseView';

interface ProductAuthRequiredViewProps {
    onHome?: () => void;
    onInactivity?: () => void;
}

const ProductAuthRequiredView = (props: ProductAuthRequiredViewProps) => {
    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <h3 className='text-center mt-3'>
                <FormattedMessage
                    id='views.nike.ProductAuthRequiredView.title'
                    description='This is the title on the authentication required screen when having scanned a product barcode without being authenticated as a delivery person in the Nike workflow.'
                    defaultMessage='Authentication required'
                />
            </h3>
            <p className='text-center'>
                <FormattedMessage
                    id='views.nike.ProductAuthRequiredView.message1'
                    description='This is the 1th paragraph of the message on the authentication required screen when having scanned a product barcode without being authenticated as a delivery person in the Nike workflow.'
                    defaultMessage='You need to authenticate as delivery person to be able to store products in this DistriSPOT. Please scan your badge now.'
                />
            </p>
            <p className='text-center'>
                <FormattedMessage
                    id='views.nike.ProductAuthRequiredView.message2'
                    description='This is the 2th paragraph of the message on the authentication required screen when having scanned a product barcode without being authenticated as a delivery person in the Nike workflow.'
                    defaultMessage="If you are an employee and want to return items you collected earlier, then authenticate now and select 'Return item(s)'"
                />
            </p>
        </BaseView>
    );
};

export default ProductAuthRequiredView;
