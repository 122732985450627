import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { TerminalContext } from '../TerminalInit';
import { Transaction } from '../common/transactions';
import { BackendHardwareDriverOptionsObject, getHardwareDriverConfig } from '../services/platformHardwareDriver/DriverSlotObjects';
import { isZhilaiDriverOptions } from '../services/platformHardwareDriver/utils/ZhilaiHardwareOptions';
import { Slot, SlotForTransaction } from './slots';
import { SpotLayout } from './spotLayouts';
import { ApiError, FetchOptions, fetchApi } from './utils';

export enum PlatformHardwareDriver {
    UNKNOWN = '',
    ZHILAI = 'zhilai',
    KERONG_CU = 'kerongcu',
    KERONG_SCU = 'kerongscu',
    KERONG_12CU = 'kerong12cu',
    PSPG = 'pspg',
    PROTON = 'proton',
    ADAM6060 = 'adam6060'
}

export namespace PlatformHardwareDriver {
    export function any_slot_logging_available() {
        return [...slot_occupied_info_available(), ...slot_opened_info_available()];
    }

    export function slot_opened_info_available() {
        return [PlatformHardwareDriver.PSPG, PlatformHardwareDriver.ZHILAI];
    }

    export function slot_occupied_info_available() {
        return [PlatformHardwareDriver.ZHILAI];
    }
}

export const hardwareDriverHasAutomaticallyClosingDoors = (driver: PlatformHardwareDriver) => {
    return driver === PlatformHardwareDriver.PSPG;
};

export interface SpotLayoutItem {
    id: string;
    group: number;
    url: string;
    name: string;
    spot_layout_url: string;
    spot_layout: number | string;
    slot_url?: string | null;
    slot?: string;
    terminal_url?: string | null;
    terminal?: number | string | null;
    origin_x: number;
    origin_y: number;
    width: number;
    height: number;
    hardware_id: string | null;
    hardware_driver: PlatformHardwareDriver | null;
    hardware_driver_options: { data: BackendHardwareDriverOptionsObject[] };
}

export interface SpotLayoutItemsOptions {
    spotLayout?: SpotLayout | null;
}

const defaultConfig = {
    staleTime: Infinity
};

export function isSpotLayoutItem(item: unknown): item is SpotLayoutItem {
    return (item as SpotLayoutItem).url !== undefined && (item as SpotLayoutItem).url.includes('/spot-layout-items/');
}

function fetchSpotLayoutItemsApi(options?: SpotLayoutItemsOptions, fetchOptions?: FetchOptions): () => Promise<SpotLayoutItem[]> {
    return async (): Promise<SpotLayoutItem[]> => {
        const searchParams = new URLSearchParams();
        if (options && options.spotLayout) {
            searchParams.append('spot-layout', options.spotLayout.id);
        }
        const searchParamsStr = searchParams.toString();
        let spotLayoutItemsUrl = '/spot-layout-items/';
        if (searchParamsStr) {
            spotLayoutItemsUrl += '?' + searchParamsStr;
        }
        const response = await fetchApi(spotLayoutItemsUrl, undefined, fetchOptions);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error fetching spot layout items');
            }
            throw new ApiError('Error fetching spot layout items', json);
        }
        return await response.json();
    };
}

export async function prefetchSpotLayoutItems(
    queryClient: QueryClient,
    spotLayout: SpotLayout,
    options?: FetchQueryOptions<SpotLayoutItem[]>,
    fetchOptions?: FetchOptions
) {
    const config = {
        ...defaultConfig,
        ...options
    };

    return await queryClient.fetchQuery(['spot-layout-items', spotLayout.id], fetchSpotLayoutItemsApi({ spotLayout: spotLayout }, fetchOptions), config);
}

export function useSpotLayoutItems(options?: SpotLayoutItemsOptions, queryOptions?: UseQueryOptions<SpotLayoutItem[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<SpotLayoutItem[]>(['spot-layout-items', options?.spotLayout?.id], fetchSpotLayoutItemsApi(options, fetchOptions), config);
}

/**
 * @param options
 * @param queryOptions
 * @param fetchOptions
 * @param enabled
 * @returns
 */
export function useMultipleSpotLayoutItems(
    options?: SpotLayoutItemsOptions,
    queryOptions?: UseQueryOptions<SpotLayoutItem[]>,
    fetchOptions?: FetchOptions,
    enabled?: boolean
) {
    const config = {
        ...defaultConfig,
        enabled: enabled,
        ...queryOptions
    };
    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<SpotLayoutItem[]>(['multiple-spot-layout-items', options?.spotLayout?.id], fetchSpotLayoutItemsApi(options, fetchOptions), config);
}

/**
 * @param slot1
 * @param slot2
 * @returns
 */
export function sortBySlot(slot1: SpotLayoutItem, slot2: SpotLayoutItem): number {
    if (!slot1.hardware_driver_options.data[0].config.slot || !slot2.hardware_driver_options.data[0].config.slot) return 0;
    return parseFloat(slot1.hardware_driver_options.data[0].config.slot!) - parseFloat(slot2.hardware_driver_options.data[0].config.slot!);
}

export function getTransactionsFromSpotlayoutItem(transactionSlotMap: Map<string, SlotForTransaction[]>, item: SpotLayoutItem): Transaction[] {
    const slotForTransaction = transactionSlotMap.get(item.slot as string);
    const result: Transaction[] = [];
    slotForTransaction?.forEach((slot) => {
        slot.transaction && result.push(slot.transaction);
    });
    return result;
}

export function getSlotFromSpotLayoutItem(slots: Slot[], item: SpotLayoutItem) {
    return slots.find((s) => s.id === item.slot)!;
}

export function getSpotLayoutItemFromSlot(items: SpotLayoutItem[], slot: Slot) {
    return items.find((i) => i.slot === slot.id)!;
}

/**
 * @param items
 * @returns
 */
export function getControllBoardLastNumbers(items: SpotLayoutItem[]): string[] {
    const config = getHardwareDriverConfig(items);
    const boards = config.map((o) => {
        if (isZhilaiDriverOptions(o)) {
            return o.name;
        }
        return null;
    });
    return boards.filter((o) => o !== null) as string[];
}
