import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Contact } from '../../api/contacts';
import { useSpotLayout } from '../../api/spotLayouts';
import { useSpot } from '../../api/spots';
import { TerminalWorkflowType } from '../../api/terminals';
import ActionCard from '../../components/domain/ActionCard';
import ActionCardGridElement from '../../components/elements/ActionCardGridElement';
import { useAppDispatch } from '../../hooks/redux';
import { backward } from '../../redux/ViewSlice';
import badgeNfc from '../../static/nike/badge-nfc-alt.png';
import BackAndHomeNavigationButtons from '../../views/common/BackAndHomeNavigationButtons';
import BaseView from '../../views/common/BaseView';
import LoadingView from '../../views/common/LoadingView';
import LanguagePicker from '../../views/elements/LanguagePicker';
import ViewTitle from '../../views/nike/ViewTitle';
import { AccessControllAction } from '../AccessControllAction';
import AdminPanelAction from '../Actions/Admin/AdminPanelAction';
import DropoffWorkflowAction from '../Actions/Dropoff/DropoffWorkflow';
import PickupWorkflowAction from '../Actions/Pickup/PickupWorkflow';
import QRHelpAction from '../Actions/Scanner/QRHelpAction';
import CheckReturnsAction from '../Actions/Vending/Management/CheckReturnsAction';
import EnterProductCodeAction from '../Actions/Vending/Management/EnterProductCodeAction';
import ManualStockRefillAction from '../Actions/Vending/Management/ManualStockRefillAction';
import ManualStockRefillWithoutReportNumberAction from '../Actions/Vending/Management/ManualStockRefillWithoutReportNumberAction';
import CollectWorkflowAction from '../Actions/Vending/Shop/CollectAction';
import ReturnItemsAction from '../Actions/Vending/Shop/ReturnItemsAction';
import ResetBadgeAction from '../Actions/Vending/badgeRegistration/ResetBadgeAction';
import NikeHelpWorkflowAction from '../Actions/Vending/help/NikeHelpWorkflowAction';
import SlotIssuesAction from '../Actions/management/SlotIssuesAction';
import TransactionHistoryAction from '../Actions/transactionManagement/TransactionHistoryAction';
import LaundryVanendeRoxyAction from '../UserSpecificActions/LaundryVanendeRoxyDropoffAction';
import ContentManagementAction from '../contentManagement/ContentManagementAction';
import ItmseLoginAction from '../social-login/ItsmeLogin';
import ProductDiff from '../warehouse/ProductDiff';
import { WarehouseDropoffAction } from '../warehouse/WarehouseDropoff';
import { WarehouseCheckoutAction } from '../warehouse/checkout/WarehouseCheckoutAction';

interface AuthenticatedContactWorkflowProps {
    onHome: () => void;
    onLogout?: () => void;
    onInactivity?: () => void;
    defaultLookup?: (enabled: boolean) => void;
    contact?: Contact;
    onSelectAction: (action: ActionCard) => void;
    onHowDoesItWork?: () => void;
    workflow?: TerminalWorkflowType;

    authenticateContact?: (contact: Contact | undefined) => void;
}

const AuthenticatedContactWorkflow = (props: AuthenticatedContactWorkflowProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const backHomeNav = props.contact ? <BackAndHomeNavigationButtons onLogout={props.onLogout ? props.onLogout : props.onInactivity} /> : <LanguagePicker />;
    const [doLookupReset, changeDoLookupReset] = useState<boolean>(true);
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const onBack = () => {
        dispatch(backward());
        props.onHome();
    };

    useEffect(() => {
        changeDoLookupReset(!doLookupReset);
    }, []);

    const actionsExcludedFromLookup = [
        intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.NeedHelpAction',
            description: 'This is the help action on the Nike dashboard',
            defaultMessage: 'Need help?'
        }),
        intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.ResetBadgeAction',
            description: 'This is the overwrite badge action on the Nike dashboard for the managers',
            defaultMessage: 'Reset badge'
        }),
        intl.formatMessage({
            id: 'workflow.nike.DashboardWorkflow.CollectItemsAction',
            description: 'This is the collect action on the Nike dashboard',
            defaultMessage: 'Collect item(s)'
        })
    ];

    const onSelect = (action: ActionCard) => {
        if (actionsExcludedFromLookup.includes(action.actionText) && props.defaultLookup) props.defaultLookup(false);
        else if (props.defaultLookup) props.defaultLookup(true);

        if (action.onAction) action.onAction();
        else props.onSelectAction(action);
    };

    if (!spot) {
        return (
            <LoadingView
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    }

    return (
        <BaseView
            onInactivity={props.contact ? props.onInactivity : undefined}
            navbarItems={backHomeNav}>
            <ViewTitle>
                {!props.contact ? (
                    <FormattedMessage
                        id='views.PuDo.HomeView.WelcomeTitle'
                        description='The welcome title message on the PuDo home screen'
                        defaultMessage='Welcome to {spotName}'
                        values={{
                            spotName:
                                spot.name !== '' ? (
                                    spot.name
                                ) : (
                                    <FormattedMessage
                                        id='views.PuDo.HomeView.WelcomeTitleNoSpotName'
                                        description='The text used as spot name in the welcome title message if the spot has no name. Should be read in combination with message id views.PuDo.HomeView.WelcomeTitle.'
                                        defaultMessage='the DistriSPOT'
                                    />
                                )
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id='views.AuthorisedContactWorkflow.UserActionsView.title'
                        description='The title on the UserActionsView.'
                        defaultMessage='Welcome {name}'
                        values={{
                            name: props.contact.first_name + ' ' + props.contact.last_name
                        }}
                    />
                )}
            </ViewTitle>
            {props.workflow === TerminalWorkflowType.WAREHOUSE && !props.contact && <ProductDiff />}
            {(props.workflow === TerminalWorkflowType.IMES_DEXIS_NIKE || props.workflow === TerminalWorkflowType.VENDING) && !props.contact && (
                <div className='row'>
                    <div className='col-12 col-xl-6 d-flex justify-content-center'>
                        <img
                            src={badgeNfc}
                            className='dashboard-scan-image mb-3 filter-primary'
                            alt={'NFC badge'}
                        />
                    </div>
                    <div className='col-12 col-xl-6 d-flex flex-column justify-content-center align-items-center'>
                        <p className='text-center'>
                            <FormattedMessage
                                id='views.HomeView.ScanMessage'
                                description='The message home screen encouraging the user to scan'
                                defaultMessage='Please scan your badge to continue.'
                            />
                        </p>
                        {props.workflow === TerminalWorkflowType.IMES_DEXIS_NIKE ? (
                            <button
                                className='btn btn-outline-primary rounded-pill'
                                onClick={props.onHowDoesItWork}>
                                <i className='bi bi-exclamation-circle me-2' />
                                <FormattedMessage
                                    id='views.nike.HomeView.HelpButton'
                                    description='The button on the Nike home screen for the user to get an introduction on how to scan.'
                                    defaultMessage='How does it work?'
                                />
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            )}
            <ActionCardGridElement
                actions={[
                    CollectWorkflowAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    ReturnItemsAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),

                    //PUDO
                    DropoffWorkflowAction({
                        contact: props.contact,
                        onBack: onBack,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity,
                        authenticateContact: props.authenticateContact
                    }),
                    PickupWorkflowAction({
                        contact: props.contact,
                        onBack: onBack,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity,
                        authenticateContact: props.authenticateContact
                    }),
                    QRHelpAction({
                        contact: props.contact
                    }),

                    //NEW VENDING
                    ManualStockRefillAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    ManualStockRefillWithoutReportNumberAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),

                    //VENDING
                    NikeHelpWorkflowAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    ResetBadgeAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    EnterProductCodeAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    ContentManagementAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    CheckReturnsAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    TransactionHistoryAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    SlotIssuesAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    WarehouseDropoffAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    WarehouseCheckoutAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),
                    AccessControllAction({
                        contact: props.contact,
                        onBack: onBack,
                        onHome: props.onHome,
                        onInactivity: props.onLogout
                    }),

                    //VAN ENDE ROXY
                    LaundryVanendeRoxyAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),

                    //ALL
                    AdminPanelAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity
                    }),

                    //SOCIAL LOGIN
                    ItmseLoginAction({
                        contact: props.contact,
                        onBack: props.onHome,
                        onHome: props.onHome,
                        onInactivity: props.onInactivity,
                        authenticateContact: props.authenticateContact
                    })
                ]}
                onSelectAction={onSelect}
            />
        </BaseView>
    );
};

export default AuthenticatedContactWorkflow;
