import { configureStore } from '@reduxjs/toolkit';

import ScalesWeightSlice from './ScalesWeightSlice';
import SpotLayoutLogsSlice from './SpotLayoutLogsSlice';
import ViewSlice from './ViewSlice';

export const store = configureStore({
    reducer: {
        spotLayoutLogs: SpotLayoutLogsSlice,
        viewState: ViewSlice,
        scalesWeight: ScalesWeightSlice
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
