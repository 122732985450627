import { useContext } from 'react';
import { FetchQueryOptions, QueryClient, QueryObserverResult, UseMutationOptions, useMutation, useQueries, useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';

import { Locale } from '../App';
import { TerminalContext } from '../TerminalInit';
import { ContactGroup } from './contactGroups';
import { ApiError, FetchOptions, fetchApi, postApi, putApi } from './utils';

export enum NotificationChannel {
    EMAIL = 'email',
    SMS = 'sms'
}

export interface Contact {
    id: number;
    url: string;
    first_name: string;
    last_name: string;
    title: string;
    mobile: string;
    email: string;
    birthday?: Date | null;
    notification_channels: NotificationChannel[];
    language: Locale;
    account: number;
    contact_groups: number[];
    contact_group_urls: string[];
    additional_data: unknown;
}

export const isContact = (contact: any): contact is Contact => {
    return (contact as Contact).url !== undefined && (contact as Contact).url.includes('/contacts/');
};

export interface ContactsAllLookup {
    contactGroupId: string | number;
    contacts: Contact[];
}

export interface ContactsOptions {
    accountId?: number | string | null;
    contactGroupId?: number | string | null;
    contactGroupHierarchicalId?: number | string | null;
}

interface AuthenticateContact {
    spot?: number;
    key: string;
}

interface MutateAuthenticateContactVariables {
    authenticateContact: AuthenticateContact;
}

export interface SearchContact {
    account: string;
    employee_id?: string;
    badge_nr?: string;
}

interface MutateSearchContactVariables {
    searchContact: SearchContact;
}

export interface ContactAddBadge {
    badge_key: string;
    delete_old_keys?: boolean;
}

interface MutateContactAddVariables {
    contact: Contact;
}

interface MutateContactUpdateVariables {
    contact: Contact;
}

interface MutateContactAddBadgeVariables {
    contact: Contact;
    contactAddBadge: ContactAddBadge;
}

const defaultConfig = {
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity
};

function fetchContactsApi(options?: ContactsOptions, fetchOptions?: FetchOptions): () => Promise<Contact[]> {
    return async (): Promise<Contact[]> => {
        const searchParams = new URLSearchParams();
        if (options && options.accountId) {
            searchParams.append('account', options.accountId.toString());
        }
        if (options && options.contactGroupId) {
            searchParams.append('contact-group', options.contactGroupId.toString());
        }
        if (options && options.contactGroupHierarchicalId) {
            searchParams.append('contact-group-hierarchical', options.contactGroupHierarchicalId.toString());
        }
        const searchParamsStr = searchParams.toString();
        let contactsUrl = '/contacts/';
        if (searchParamsStr) {
            contactsUrl += '?' + searchParamsStr;
        }
        const response = await fetchApi(contactsUrl, undefined, fetchOptions);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error fetching contacts');
            }
            throw new ApiError('Error fetching contacts', json);
        }
        return await response.json();
    };
}

function fetchContactApi(contactUrl?: string, contactId?: string | number | null, options?: FetchOptions): () => Promise<Contact> {
    return async (): Promise<Contact> => {
        if (!contactUrl) {
            contactUrl = `/contacts/${contactId!}/`;
        }
        const response = await fetchApi(contactUrl, undefined, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error fetching contacts');
            }
            throw new ApiError('Error fetching contacts', json);
        }
        return await response.json();
    };
}

function authenticateContactApi(options?: FetchOptions): (variables: MutateAuthenticateContactVariables) => Promise<Contact | null> {
    return async (variables: MutateAuthenticateContactVariables): Promise<Contact | null> => {
        const authenticateContactUrl = '/contacts/authenticate/';
        const response = await postApi(
            authenticateContactUrl,
            {
                key: variables.authenticateContact.key,
                spot: variables.authenticateContact.spot
            },
            options
        );
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error authenticating contact');
            }
            throw new ApiError('Error authenticating contact', json);
        }
        return await response.json();
    };
}

function searchContactApi(options?: FetchOptions): (variables: MutateSearchContactVariables) => Promise<Contact[]> {
    return async (variables: MutateSearchContactVariables): Promise<Contact[]> => {
        const searchContactUrl = '/contacts/search/';
        const response = await postApi(searchContactUrl, variables.searchContact, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error searching contact');
            }
            throw new ApiError('Error searching contact', json);
        }
        return await response.json();
    };
}

function contactAddBadgeApi(options?: FetchOptions): (variables: MutateContactAddBadgeVariables) => Promise<Contact> {
    return async (variables: MutateContactAddBadgeVariables): Promise<Contact> => {
        const contactAddBadgeUrl = `/contacts/${variables.contact.id}/add-badge/`;
        const response = await postApi(contactAddBadgeUrl, variables.contactAddBadge, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error adding badge to contact');
            }
            throw new ApiError('Error adding badge to contact', json);
        }
        return await response.json();
    };
}

function contactUpdateAPI(options?: FetchOptions): (variables: MutateContactUpdateVariables) => Promise<Contact> {
    return async (variables: MutateContactUpdateVariables): Promise<Contact> => {
        const contactUpdateUrl = `/contacts/${variables.contact.id}/`;
        const response = await putApi(contactUpdateUrl, variables.contact, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error updating contact');
            }
            throw new ApiError('Error updating contact', json);
        }
        return await response.json();
    };
}

function contactAddApi(options?: FetchOptions): (variables: MutateContactAddVariables) => Promise<Contact> {
    return async (variables: MutateContactAddVariables): Promise<Contact> => {
        const contactAddUrl = `/contacts/`;
        const response = await postApi(contactAddUrl, variables.contact, options);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError('Error adding contact');
            }
            throw new ApiError('Error adding contact', json);
        }
        return await response.json();
    };
}

export async function prefetchContacts(
    queryClient: QueryClient,
    accountId: string | number,
    options?: FetchQueryOptions<Contact[]>,
    fetchOptions?: FetchOptions
) {
    const config = {
        ...defaultConfig,
        ...options
    };

    await queryClient.fetchQuery(
        [
            'contacts',
            {
                account: accountId,
                contactGroupId: undefined,
                contactGroupHierarchicalId: undefined
            }
        ],
        fetchContactsApi({ accountId: accountId }, fetchOptions),
        config
    );
}

export function useContacts(contactsUrl?: string | null, options?: ContactsOptions, queryOptions?: UseQueryOptions<Contact[]>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        ...queryOptions
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    const apiOptions = {
        accountId: options?.accountId,
        contactGroupId: options?.contactGroupId,
        contactGroupHierarchicalId: options?.contactGroupHierarchicalId
    } as ContactsOptions;

    if (contactsUrl) {
        try {
            const url = new URL(contactsUrl);
            const urlSearchParams = new URLSearchParams(url.search);
            if (urlSearchParams.has('account')) {
                apiOptions.accountId = urlSearchParams.get('account');
            }
            if (urlSearchParams.has('contact-group')) {
                apiOptions.contactGroupId = urlSearchParams.get('contact-group');
            }
            if (urlSearchParams.has('contact-group-hierarchical')) {
                apiOptions.contactGroupHierarchicalId = urlSearchParams.get('contact-group-hierarchical');
            }
        } catch (e) {}
    }

    return useQuery<Contact[]>(
        [
            'contacts',
            {
                account: apiOptions?.accountId,
                contactGroupId: apiOptions?.contactGroupId,
                contactGroupHierarchicalId: apiOptions?.contactGroupHierarchicalId
            }
        ],
        fetchContactsApi(apiOptions, fetchOptions),
        config
    );
}

export function useContact(contactId?: string | number | null, options?: UseQueryOptions<Contact>, fetchOptions?: FetchOptions) {
    const config = {
        ...defaultConfig,
        enabled: !!contactId,
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQuery<Contact>(['contact', contactId], fetchContactApi(undefined, contactId, fetchOptions), config);
}

export function useMutateAuthenticateContact(
    options?: UseMutationOptions<Contact | null, unknown, MutateAuthenticateContactVariables>,
    fetchOptions?: FetchOptions
) {
    const config: UseMutationOptions<Contact | null, unknown, MutateAuthenticateContactVariables> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(authenticateContactApi(fetchOptions), config);
}

export function useMutateSearchContact(options?: UseMutationOptions<Contact[], unknown, MutateSearchContactVariables>, fetchOptions?: FetchOptions) {
    const config: UseMutationOptions<Contact[], unknown, MutateSearchContactVariables> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(searchContactApi(fetchOptions), config);
}

export function useMutateContactAddBadge(options?: UseMutationOptions<Contact, unknown, MutateContactAddBadgeVariables>, fetchOptions?: FetchOptions) {
    const config: UseMutationOptions<Contact, unknown, MutateContactAddBadgeVariables> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(contactAddBadgeApi(fetchOptions), config);
}

export function useMutateContactAdd(options?: UseMutationOptions<Contact, unknown, MutateContactAddVariables>, fetchOptions?: FetchOptions) {
    const config: UseMutationOptions<Contact, unknown, MutateContactAddVariables> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(contactAddApi(fetchOptions), config);
}

export function useMutateUpdateContact(options?: UseMutationOptions<Contact, unknown, MutateContactUpdateVariables>, fetchOptions?: FetchOptions) {
    const config: UseMutationOptions<Contact, unknown, MutateContactUpdateVariables> = {
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useMutation(contactUpdateAPI(fetchOptions), config);
}

function contactGroupsUseQueryOptions(
    contactGroup?: ContactGroup,
    options?: UseQueryOptions<Contact[], unknown, ContactsAllLookup>,
    fetchOptions?: FetchOptions
): UseQueryOptions<Contact[], unknown, ContactsAllLookup> {
    const config = {
        ...defaultConfig,
        select: (data: Contact[]): ContactsAllLookup => {
            return {
                contactGroupId: contactGroup ? contactGroup.id : 0,
                contacts: data
            };
        },
        ...options,
        enabled: (options ? options.enabled : true) && !!contactGroup
    };

    return {
        queryKey: [
            'contacts',
            {
                account: undefined,
                contactGroupId: undefined,
                contactGroupHierarchicalId: contactGroup?.id
            }
        ],
        queryFn: fetchContactsApi(
            {
                contactGroupHierarchicalId: contactGroup?.id
            },
            fetchOptions
        ),
        ...config
    };
}

export function useContactGroupsContactsAll(
    contactGroups?: Array<ContactGroup | undefined>,
    options?: UseQueryOptions<Contact[], unknown, ContactsAllLookup>,
    fetchOptions?: FetchOptions
): QueryObserverResult<ContactsAllLookup>[] {
    const config = {
        ...defaultConfig,
        enabled: !!contactGroups,
        ...options
    };

    const terminalContext = useContext(TerminalContext);
    fetchOptions = {
        includeAccessToken: terminalContext.includeAccessToken,
        accessToken: terminalContext.accessToken,
        ...fetchOptions
    };

    return useQueries(
        contactGroups
            ? contactGroups.map((contactGroup) => {
                  return contactGroupsUseQueryOptions(contactGroup, config, fetchOptions) as UseQueryOptions;
              })
            : []
    ) as QueryObserverResult<ContactsAllLookup>[];
}

//Exported for testing (should not be called upon anywhere else)
export const exportedForTesting = {
    fetchContactsApi,
    fetchContactApi,
    authenticateContactApi,
    searchContactApi,
    contactAddBadgeApi,
    contactAddApi
};
