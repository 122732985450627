import { ChangeEvent, FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useActivity from '../../../hooks/useActivity';
import BackAndHomeNavigationButtons from '../../common/BackAndHomeNavigationButtons';
import BaseView from '../../common/BaseView';
import ViewTitle from '../ViewTitle';

interface BadgeRegistrationEmployeeIdViewProps {
    onEmployeeIdEntered: (employeeId: string) => void;
    onEmployeeIdNotAvailable: () => void;
    onInactivity?: () => void;
    onHome?: () => void;
}

export default function BadgeRegistrationEmployeeIdView(props: BadgeRegistrationEmployeeIdViewProps) {
    const [employeeId, changeEmployeeId] = useState('');
    const [, newActivity] = useActivity();
    const intl = useIntl();

    function onChangeEmployeeId(event: ChangeEvent<HTMLInputElement>) {
        newActivity();
        changeEmployeeId(event.currentTarget.value);
    }

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        newActivity();
        event.preventDefault();
        props.onEmployeeIdEntered(employeeId);
    }

    const homeNav = <BackAndHomeNavigationButtons onHome={props.onHome} />;

    const inputPlaceholder = intl.formatMessage({
        id: 'views.nike.registration.BadgeRegistrationEmployeeIdView.inputPlaceholder',
        description: 'The placeholder on the employee id input field on the Nike badge registration employee id view.',
        defaultMessage: 'Enter employee ID here'
    });
    return (
        <BaseView
            onInactivity={props.onInactivity}
            navbarItems={homeNav}>
            <ViewTitle>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationEmployeeIdView.title'
                    description='The title on the Nike badge registration employee id view.'
                    defaultMessage='Badge registration'
                />
            </ViewTitle>
            <p>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationEmployeeIdView.message1'
                    description='The message paragraph 1 on the Nike badge registration employee id view.'
                    defaultMessage="Your badge was not recognized. Let's get registered now to use this DistriSPOT."
                />
            </p>
            <p>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationEmployeeIdView.message2'
                    description='The message paragraph 2 on the Nike badge registration employee id view.'
                    defaultMessage='Find your employee ID on the front of your badge, under your photo and name. Enter your employee ID below and perform a search.'
                />
            </p>
            <form
                className='mb-2'
                onSubmit={onSubmit}>
                <div className='input-group'>
                    <input
                        type='text'
                        id='employeeIdInput'
                        className='form-control'
                        value={employeeId}
                        onChange={onChangeEmployeeId}
                        required
                        placeholder={inputPlaceholder}
                    />
                    <button
                        type='submit'
                        className='primary-button'>
                        <i className='bi bi-search e2' />
                        <span> </span>
                        <FormattedMessage
                            id='views.nike.registration.BadgeRegistrationEmployeeIdView.searchCallToAction'
                            description='The call to action to search on the Nike badge registration employee id view.'
                            defaultMessage='Search'
                        />
                    </button>
                </div>
            </form>
            <button
                className='secondary-button'
                onClick={() => {
                    props.onEmployeeIdNotAvailable();
                }}>
                <FormattedMessage
                    id='views.nike.registration.BadgeRegistrationEmployeeIdView.noEmployeeIdCallToAction'
                    description='The call to action when no employee available on the Nike badge registration employee id view.'
                    defaultMessage="I don't know my employee ID"
                />
            </button>
        </BaseView>
    );
}
