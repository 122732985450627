import { ProtonCommands } from '../../services/ProtonScalesService';
import { IProtonResponse, isProtonResponse } from './IProtonResponse';

export interface ConfigureScalesResponse extends IProtonResponse {
    header: {
        action: ProtonCommands.DEFINE_DEVICE;
    } & Omit<IProtonResponse['header'], 'action'>;
}

export function isConfigureScalesResponse(response: unknown): response is ConfigureScalesResponse {
    return (
        isProtonResponse(response) &&
        (response as ConfigureScalesResponse).header &&
        (response as ConfigureScalesResponse).header.action === ProtonCommands.DEFINE_DEVICE
    );
}
