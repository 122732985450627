import { useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../TerminalInit';
import { ContactGroup, isContactGroup, useContactGroup } from '../api/contactGroups';
import { Contact, isContact, useContact } from '../api/contacts';
import { ProductInstance } from '../api/productInstances';
import { Product, useProducts } from '../api/products';
import { useShops } from '../api/shops';
import { Slot, SlotType, useSlot } from '../api/slots';
import { hardwareDriverHasAutomaticallyClosingDoors, useSpotLayoutItems } from '../api/spotLayoutItems';
import { useSpotLayout } from '../api/spotLayouts';
import { SpotType, useSpot } from '../api/spots';
import { useMutateCreateTransaction, useMutateTransactionStatus, useTransaction } from '../api/transactions';
import { NotificationsConfig, Transaction, TransactionStatus, TransactionStatusEventType, TransactionType } from '../common/transactions';
import useActivity from '../hooks/useActivity';
import useOpenSlot from '../hooks/useOpenSlot';
import useSlotFilter, { FilterProps } from '../hooks/useSlotFilter';
import { Logger } from '../logs/Logger';
import { addTransactionInProgress, checkIfTransactionInProgressIsStillValid } from '../service-worker/TransactionInProgressDatabase';
import { isDelivery } from '../services/contact/GlobalPermissions';
import { getRandomString } from '../utils';
import SenderSelectView from '../views/SenderSelectView';
import SlotConfirmationView from '../views/SlotConfirmationView';
import { SlotOpenState, SlotOpenView } from '../views/SlotOpenView';
import SlotSelectView from '../views/SlotSelectView';
import LoadingView from '../views/common/LoadingView';
import { isNikeDeliveryPerson } from './nike/permissions';
import SelectReceiverWorkflow from './pudo/SelectReceiverWorkflow';

export interface SenderData {
    senderContact?: Contact | null;
    sender?: ContactGroup | null;
    senderHint?: string;
}

export interface ReceiverData {
    contact?: Contact | null;
    contactGroup?: ContactGroup | null;
    receiverHint?: string;
}

interface JustDropProperties {
    contact?: Contact;

    senderData?: SenderData | null;
    isReturn?: boolean;
    // Omitting receiverData or null means a receiver needs to be asked for.
    // On the other hand, if receiverData is present, but both contact and contactGroup are omitted or null, then this is
    // a vending transaction and no receiver needs to be asked for.
    receiverData?: ReceiverData | null;
    slot?: Slot | null;
    transaction?: Transaction | null;
    tracking_number?: string;
    products?: Product[];
    productInstances?: ProductInstance[];
    autoAssignSlot?: boolean;
    autoAssignFilter?: (slots: Slot[]) => Slot[];
    skipConfirm?: boolean;
    confirmAndOpen?: boolean;
    shippingNotes?: string;
    notificationsConfig?: NotificationsConfig;
    onHome?: () => void;
    onBack?: () => void;
    onInactivity?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
    transactionType?: TransactionType;
    currentSlotType?: number[]; // only relevent when there are multiple types of slots on the distrispot

    deliverSameProduct?: () => void;
}

const JustDrop = (props: JustDropProperties) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const { data: spot } = useSpot(spotLayout?.spot_url);
    const { data: reservedSlot } = useSlot(props.transaction?.slot_id);
    const { data: senderFromTransaction, isLoading: contactIsLoading } = useContact(props.transaction?.sender?.id);
    const { data: senderGroupFromTransaction, isLoading: contactGroupIsLoading } = useContactGroup(undefined, props.transaction?.sender_group?.id);
    const { data: receiverFromTransaction } = useContact(props.transaction?.receiver?.id);
    const { data: receiverGroupFromTransaction } = useContactGroup(undefined, props.transaction?.receiver_group?.id);
    const { data: shops } = useShops({ terminal: terminal }, { enabled: !!terminal });
    const { data: allProducts } = useProducts(shops && shops.length > 0 ? shops[0].products : undefined, undefined);

    //const [gotoMultipleDropoff, changeGotoMultipleDropoff] = useState<boolean>(false);

    const [sender, changeSender] = useState<SenderData | null>(props.senderData ? props.senderData : props.contact ? { senderContact: props.contact } : null);
    const [receiver, changeReceiver] = useState<ReceiverData | null>(props.receiverData ? props.receiverData : null);
    const [slot, changeSlot] = useState<Slot | null>(props.slot ? props.slot : null);
    const [transaction, changeTransaction] = useState<Transaction | null>(props.transaction ? props.transaction : null);
    const { data: processedSlot } = useSlot(transaction ? transaction.slot_id : undefined);
    const [slotConfirmed, changeSlotConfirmed] = useState(props.skipConfirm === true);
    const [transactionCreating, changeTransactionCreating] = useState(false);
    const [slotOpening, changeSlotOpening] = useState(false);
    const [slotOpened, changeSlotOpened] = useState(false);
    const [slotOpenError, changeSlotOpenError] = useState(false);
    const [backStack, changeBackStack] = useState<Array<() => void>>([]);
    const [autoAssign, changeAutoAssign] = useState<boolean>(props.autoAssignSlot !== false);
    const [, newActivity] = useActivity();
    const [openSlot] = useOpenSlot();
    const mutateCreateTransaction = useMutateCreateTransaction({
        onSuccess: (transaction) => {
            changeTransaction(transaction);
        },
        onError: props.onError
    });
    const transactionStatusMutation = useMutateTransactionStatus();
    const [filter] = useSlotFilter();

    const [inProgressTransactionSaved, changeInProgressTransactionSaved] = useState<boolean>(false);
    const toUpdateTransaction = useTransaction(
        transaction?.url,
        undefined,
        !!transaction && (transaction?.status === TransactionStatus.NEW || transaction?.status === TransactionStatus.READY_FOR_DROPOFF) && slotConfirmed
    );

    useEffect(() => {
        if (toUpdateTransaction) {
            changeTransaction(toUpdateTransaction);
            if (!inProgressTransactionSaved && toUpdateTransaction?.status === TransactionStatus.DROPOFF_IN_PROGRESS) {
                addTransactionInProgress(toUpdateTransaction);
                changeInProgressTransactionSaved(true);
            }
        }
    }, [toUpdateTransaction]);

    const spotLayoutItem = spotLayoutItems ? spotLayoutItems.find((li) => li.slot && li.slot === slot?.id) : undefined;

    //if the transaction actualy contains a slot id and the slot was not passed by the previeus view check if it is available now
    useEffect(() => {
        if (processedSlot && props.slot == null && autoAssign) {
            changeSlot(processedSlot);
        }

        if (transaction) {
            //If this returns true the code was already used and the slot should not be changed anymore
            checkIfTransactionInProgressIsStillValid(transaction).then((result) => result === true && changeSlotConfirmed(true));
        }
    }, [processedSlot, transaction]);

    useEffect(() => {
        const doOpen = async () => {
            await openSlot({ spotLayoutItem: spotLayoutItem! })
                .then((result) => {
                    doDropoff(transaction!);
                })
                .catch((error) => {
                    Logger.error(error);
                    changeSlotOpenError(true);
                });
        };
        if (transaction && TransactionStatus.is_valid_dropoff_status().includes(transaction?.status) && slotConfirmed && !slotOpening && spotLayoutItem) {
            changeSlotOpening(true);
            doOpen();
        }
    }, [transaction, transaction?.status, slotConfirmed, slotOpening, slot, transactionStatusMutation, spotLayoutItem]);

    function doDropoff(transaction: Transaction) {
        Logger.log('Mutating dropoff', { transaction: transaction.id }, transaction);

        changeSlotOpened(true);
        transactionStatusMutation.mutate({
            transaction: transaction,
            transactionStatusUpdate: {
                event: TransactionStatusEventType.DROPOFF,
                dropoff: {
                    slot_id: slot!.id
                }
            }
        });
    }

    useEffect(() => {
        if (transaction && !sender && (senderFromTransaction || senderGroupFromTransaction)) {
            changeSender({
                sender: senderGroupFromTransaction,
                senderContact: senderFromTransaction
            });
        }
    }, [transaction, sender, senderFromTransaction, senderGroupFromTransaction]);

    useEffect(() => {
        if (transaction && !receiver && (receiverFromTransaction || receiverGroupFromTransaction)) {
            changeReceiver({
                contact: receiverFromTransaction,
                contactGroup: receiverGroupFromTransaction
            });
        }
    }, [transaction, receiver, receiverFromTransaction, receiverGroupFromTransaction]);

    const resetState = () => {
        changeSender(props.senderData ? props.senderData : null);
        changeReceiver(props.receiverData ? props.receiverData : null);
        changeSlot(props.slot ? props.slot : null);
        changeAutoAssign(props.autoAssignSlot !== false);
        changeSlotConfirmed(props.skipConfirm === true);
        changeTransactionCreating(false);
    };

    const onInactivity = () => {
        resetState();
        if (props.onInactivity) {
            props.onInactivity();
        }
    };

    const onHome = () => {
        newActivity();
        resetState();
        if (props.onHome) {
            props.onHome();
        }
    };

    const onBack =
        props.onBack || backStack.length > 0
            ? () => {
                  newActivity();
                  const back = backStack.pop();
                  if (back) {
                      changeBackStack(backStack);
                      back();
                  } else {
                      resetState();
                      if (props.onBack) {
                          props.onBack();
                      }
                  }
              }
            : undefined;

    const onSelectSender = (newSender: ContactGroup, senderHint?: string) => {
        newActivity();
        const oldSender = sender;
        backStack.push(() => {
            changeSender(oldSender);
        });
        changeBackStack(backStack);
        changeSender({
            sender: newSender,
            senderHint: senderHint
        });
    };

    const onSelectContact = (contact: Contact | ContactGroup, receiverHint?: string) => {
        newActivity();
        const oldReceiver = receiver;
        backStack.push(() => {
            changeReceiver(oldReceiver);
        });
        if (isContact(contact)) {
            changeBackStack(backStack);
            changeReceiver({
                contact: contact,
                receiverHint: receiverHint
            });
        } else if (isContactGroup(contact)) {
            changeBackStack(backStack);
            changeReceiver({
                contactGroup: contact,
                receiverHint: receiverHint
            });
        }
    };

    const onSelectSlot = (newSlot: Slot | null, autoAssigned: boolean) => {
        newActivity();
        const oldSlot = slot;
        if (!autoAssigned) {
            backStack.push(() => {
                changeSlot(oldSlot);
            });
            changeBackStack(backStack);
        }
        changeSlot(newSlot);
    };

    const onConfirmedSlot = () => {
        newActivity();
        changeBackStack([]); // Clear the back stack. Can't go back now anymore.
        changeSlotConfirmed(true);
    };

    const slotsFilter = (slots: Slot[]): Slot[] => {
        Logger.log('Starting slot filter.');

        const filterProps: FilterProps = {
            authenticatedContact: props.contact,
            receiver: receiver?.contact ? receiver.contact : receiver?.contactGroup ? receiver?.contactGroup : undefined,
            slots: slots,
            externalFilters: autoAssign && props.autoAssignFilter ? [props.autoAssignFilter] : undefined,
            products: props.products,
            productInstances: props.productInstances,
            isReturn: props.isReturn,
            transactionType: props.transaction?.type ? props.transaction?.type : props.transactionType,
            isAutoAssign: autoAssign
        };
        return filter(filterProps);
    };

    // This will create the transaction if all the data is gathered (and if necessary)
    useEffect(() => {
        if (spot && slot && receiver && sender && slotConfirmed && !transaction && !transactionCreating) {
            changeTransactionCreating(true);
            const shippingNotesLines = props.shippingNotes ? [props.shippingNotes + '\n'] : [];
            if (sender!.senderHint) {
                shippingNotesLines.push(`Delivery Sender: ${sender!.senderHint}`);
            }
            if (receiver!.receiverHint) {
                shippingNotesLines.push(`Receiver: ${receiver!.receiverHint}`);
            }
            mutateCreateTransaction.mutate({
                createTransaction: {
                    account_id: spot!.account,
                    slot_id: slot!.id,
                    tt_number: props.tracking_number && props.tracking_number.length > 0 ? props.tracking_number : getRandomString(20),
                    receiver_id: receiver!.contact?.id,
                    receiver_group_id: receiver!.contactGroup?.id,
                    sender_id: sender!.senderContact?.id,
                    sender_group_id: sender!.sender?.id,
                    shipping_notes: shippingNotesLines.join('\n'),
                    notifications_config: props.notificationsConfig,
                    product_instances: props.products
                        ? props.products.map((product) => {
                              return {
                                  product: product.id
                              };
                          })
                        : undefined,
                    product_instance_ids: props.productInstances ? props.productInstances.map((productInstance) => productInstance.id) : undefined,
                    type: props.transactionType
                }
            });
        }
    }, [spot, slot, receiver, sender, slotConfirmed, transaction, transactionCreating]);

    /*const multipleDropAllowed = 
		isDelivery(props.contact) &&
		props.products !== undefined &&
		props.products[0] !== undefined &&
		(terminal?.workflow === TerminalWorkflowType.VENDING ||
			terminal?.workflow === TerminalWorkflowType.IMES_DEXIS_NIKE);

	if (gotoMultipleDropoff && multipleDropAllowed) {
		return (
			<MultipleDrop
				sender={props.contact!}
				product={props.products![0]}
				onInactivity={props.onInactivity}
				onHome={props.onHome}
				onBack={props.onBack}
			></MultipleDrop>
		);
	}*/

    const deliverSameProduct =
        isDelivery(props.contact) || isNikeDeliveryPerson(props.contact)
            ? () => {
                  changeSlotConfirmed(false);
                  changeTransaction(null);
                  changeSlot(null);
                  changeTransactionCreating(false);
                  changeSlotOpened(false);
                  changeSlotOpening(false);
                  changeSlotOpenError(false);
                  changeBackStack([]);
              }
            : undefined;

    if (contactGroupIsLoading || contactIsLoading) {
        return <LoadingView />;
    } else if (sender === null) {
        return (
            <SenderSelectView
                onInactivity={onInactivity}
                onHome={onHome}
                onBack={onBack}
                onSelect={onSelectSender}
            />
        );
    } else if ((receiver === null && spot === undefined) || allProducts === undefined) {
        return (
            <LoadingView
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                onBack={props.onBack}
            />
        );
    } else if (receiver === null) {
        return (
            <SelectReceiverWorkflow
                spot={spot!}
                contact={props.contact}
                onSelect={onSelectContact}
                onInactivity={onInactivity}
                onHome={onHome}
                onBack={onBack}
            />
        );
    } else if (slot == null || slot.type == SlotType.RESERVATION) {
        Logger.log('slot is null so giving user change to select one');

        return (
            <SlotSelectView
                onInactivity={onInactivity}
                onHome={onHome}
                onBack={onBack}
                onSelect={onSelectSlot}
                onUnavailable={newActivity}
                deliverySender={sender.sender}
                receiverContact={receiver.contact ? receiver.contact : receiver.contactGroup}
                reservedSlot={reservedSlot}
                autoAssign={autoAssign ? {} : undefined}
                slotsFilter={slotsFilter}
                product={props.products ? props.products[0] : undefined}
                productInstances={props.productInstances}
                transactionType={props.transaction?.type ? props.transaction?.type : props.transactionType}
            />
        );
    } else if (!slotConfirmed) {
        Logger.log('slot is known so user only needs to confirm');
        return (
            <SlotConfirmationView
                onInactivity={onInactivity}
                onHome={onHome}
                onBack={onBack}
                slot={slot}
                onConfirm={onConfirmedSlot}
                onChooseDifferent={() => {
                    if (backStack.length > 0) {
                        // Pop the back action, we don't want to go back to the confirmation screen but to the one before that.
                        backStack.pop();
                        changeBackStack(backStack);
                    }
                    changeSlot(null);
                    changeAutoAssign(false); // Never auto-assign after choosing a different SLOT.
                }}
                confirmAndOpen={props.confirmAndOpen}
                /*gotoMultipleDrop={
					multipleDropAllowed ? changeGotoMultipleDropoff : undefined
				}*/
            />
        );
    } else {
        // These are the opening SLOT cases
        let slotOpenState: SlotOpenState;
        if (mutateCreateTransaction.isLoading) {
            Logger.log('SlotOpenState in progress because transaction is processing (status isLoading)');
            slotOpenState = SlotOpenState.IN_PROGRESS;
        } else if (mutateCreateTransaction.isError || slotOpenError) {
            Logger.error('Something went wrong trying to open the slot', { slot: slot.id }, slotOpenError);
            slotOpenState = SlotOpenState.ERROR;
        } else if (!transaction?.status || transaction?.status === TransactionStatus.NEW) {
            Logger.log('SlotOpenState in progress because transaction is processing (status new)');
            slotOpenState = SlotOpenState.IN_PROGRESS;
        } else if (transaction?.status === TransactionStatus.ERROR) {
            Logger.error('Transaction is in error status', { transaction: transaction.id }, transaction.status);
            slotOpenState = SlotOpenState.ERROR;
        } else if (transaction?.status === TransactionStatus.READY_FOR_DROPOFF && !slotOpened) {
            Logger.log('SlotOpenState in progress because slot is not open yet');
            slotOpenState = SlotOpenState.IN_PROGRESS;
        } else if (slotOpened) {
            slotOpenState = SlotOpenState.SUCCESS;
        } else {
            slotOpenState = SlotOpenState.ERROR; // Unknown state
            Logger.error(
                'Unexpected state',
                { transaction: transaction.id, slot: slot.id },
                mutateCreateTransaction.isLoading,
                mutateCreateTransaction.isError,
                transaction,
                slotOpened
            );
        }
        return (
            <SlotOpenView
                spotType={spot ? spot.type : SpotType.STANDARD_DISTRISPOT}
                slotInfos={[{ slot: slot, state: slotOpenState }]}
                onInactivity={props.onInactivity}
                onHome={props.onHome}
                deliverSameProduct={props.deliverSameProduct ? props.deliverSameProduct : deliverSameProduct}
                doorClosesAutomatically={
                    spotLayoutItem && spotLayoutItem.hardware_driver ? hardwareDriverHasAutomaticallyClosingDoors(spotLayoutItem.hardware_driver) : false
                }
            />
        );
    }
};

export default JustDrop;
