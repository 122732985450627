import { Contact } from '../../api/contacts';
import { TransactionContact } from '../../common/transactions';

export function filterContactsByContactGroupId(contacts: Contact[], groupId: string | number): Contact[] {
    return contacts.filter((contact) => contact.contact_groups.includes(+groupId));
}

export function getContactFromTransactionContact(contacts: Contact[], transactionContact: TransactionContact): Contact | undefined {
    return contacts.find((contact) => contact.id === transactionContact.id);
}
