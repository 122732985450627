import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import usePrefetchInitialData from './hooks/usePrefetchInitialData';
import { Logger } from './logs/Logger';
import { formatTimeDelta, getRandomInt } from './utils';

interface PrefetchContentStatusProps {
    name: string;
    success: boolean;
    error?: boolean;
}

function PrefetchContentStatus(props: PrefetchContentStatusProps) {
    if (props.success) {
        return <p>{props.name} loaded</p>;
    } else if (props.error === true) {
        return <p>{props.name} loading failed</p>;
    } else {
        return <p>{props.name} loading...</p>;
    }
}

interface PrefetchContentProps {
    children?: React.ReactNode;
}

export const PrefetchContent = (props: PrefetchContentProps) => {
    const [timeToRetry, changeTimeToRetry] = useState<number | null>(null);
    const [retries, changeRetries] = useState(0);

    const {
        prefetchError,
        prefetchDone,
        spotFetched,
        spotLayoutFetched,
        spotLayoutItemsFetched,
        slotsFetched,
        transactionsFetched,
        contactsFetched,
        contactGroupsFetched,
        printersFetched,
        shopsFetched,
        productsFetched,
        stockFillsFetched,
        categoriesFetched,
        attributesFetched,
        accessControlsFetched,
        retry
    } = usePrefetchInitialData();

    useEffect(() => {
        if (prefetchError) {
            const currentRetry = retries + 1;
            if (currentRetry > 10) {
                // I give up, reload the page. Reached in an average of 5 hours.
                // TODO: Maybe do a full and complete reset here?
                window.location.href = '/';
            }
            Logger.log('Prefetch failed, retry attempt', {}, currentRetry);
            const retryDate = new Date();
            const baseBackoff = Math.min(currentRetry, 12) * 10;
            const retrySeconds = getRandomInt(baseBackoff, baseBackoff * 2);
            retryDate.setSeconds(retryDate.getSeconds() + retrySeconds);
            changeRetries(currentRetry);

            let recalculateTimer = 0;
            const recalculateTimeToRetry = () => {
                const now = new Date();
                if (retryDate < now) {
                    // Time is up, retry
                    retry();
                    changeTimeToRetry(null);
                    window.clearInterval(recalculateTimer);
                } else {
                    changeTimeToRetry(Math.round((retryDate.getTime() - now.getTime()) / 1000));
                }
            };
            recalculateTimer = window.setInterval(recalculateTimeToRetry, 1000);

            return () => {
                window.clearInterval(recalculateTimer);
            };
        }
    }, [prefetchError]);

    return (
        <>
            {prefetchDone ? (
                props.children
            ) : (
                <div className='text-center flex-grow-1 mt-2'>
                    <h1>Initializing App</h1>
                    {timeToRetry ? <div className='alert alert-danger'>Loading error. Retrying in {formatTimeDelta(timeToRetry)}.</div> : undefined}
                    <p>Terminal loaded</p>
                    <PrefetchContentStatus
                        name='Spot Layout'
                        success={spotLayoutFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Spot Layout Items'
                        success={spotLayoutItemsFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Spot'
                        success={spotFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Slots'
                        success={slotsFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Contacts'
                        success={contactsFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Contact Groups'
                        success={contactGroupsFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Transactions'
                        success={transactionsFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Shops'
                        success={shopsFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Products'
                        success={productsFetched}
                        error={prefetchError}
                    />

                    <PrefetchContentStatus
                        name='Stock fill reports'
                        success={stockFillsFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Categories'
                        success={categoriesFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Attributes'
                        success={attributesFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Printers'
                        success={printersFetched}
                        error={prefetchError}
                    />
                    <PrefetchContentStatus
                        name='Access controls'
                        success={accessControlsFetched}
                        error={prefetchError}
                    />

                    <Spinner
                        animation='border'
                        role='status'></Spinner>
                </div>
            )}
        </>
    );
};
