import { ProtonCommands } from '../../services/ProtonScalesService';
import { IProtonEvent } from '../IProtonEvent';

export interface IProtonResponse extends IProtonEvent {
    ext: {
        resMsg: string;
        bindingVer: number;
        deviceVer: number;
        resCode: number;
    };
    type: 'response';
}

export function isProtonResponse(response: unknown): response is IProtonResponse {
    return (
        (response as IProtonResponse).type === 'response' &&
        (response as IProtonResponse).header &&
        Object.values(ProtonCommands).includes((response as IProtonResponse).header.action as ProtonCommands)
    );
}
