import { ContactGroup } from '../../api/contactGroups';
import { Contact, isContact } from '../../api/contacts';
import { Product } from '../../api/products';
import { Slot, SlotForTransaction, SlotType } from '../../api/slots';
import { Transaction, TransactionType } from '../../common/transactions';
import { filterContactsByContactGroupId } from '../contact/ContactFilter';
import { filterContactGroupsByContact } from '../contactGroup/ContactGroupFilter';
import { filterTransactionsByReceiver, getTransactionsFromContactGroups } from '../transaction/TransactionFilters';

/**
 * @param transactionSlotMap
 * @param transactions
 * @returns
 */
export function getValidSlotWithTransactions(transactionSlotMap: Map<string, SlotForTransaction[]>, transactions: Transaction[]): Slot[] {
    const result: Slot[] = [];
    for (const transaction of transactions) {
        if (transaction.slot_id) {
            const slotForTransaction = transactionSlotMap?.get(transaction.slot_id);
            if (slotForTransaction) {
                for (const slot of slotForTransaction) {
                    result.push(slot);
                }
            }
        }
    }
    return result;
}

export function getValidSlotsWithoutTransaction(
    slots: Slot[],
    transactionSlotMap: Map<string, SlotForTransaction[]> | undefined,
    transactionType: TransactionType
) {
    const result: Slot[] = [];
    for (const slot of slots) {
        if (transactionSlotMap?.get(slot.id) === undefined && checkSlotStillHasASpot(slot, 0, transactionType)) result.push(slot);
    }
    return result;
}

/**
 * @deprecated
 * @param slot
 * @param currentTransactionAmmount
 * @param transactionType
 * @returns
 */
export function checkSlotStillHasASpot(slot: Slot, currentTransactionAmmount: number, transactionType?: TransactionType): boolean {
    switch (transactionType) {
        case TransactionType.PUDO:
            return slot.settings_transactions_limit_for_pudo > currentTransactionAmmount;
        case TransactionType.VENDING:
            return slot.settings_transactions_limit_for_vending > currentTransactionAmmount;
        case TransactionType.LENDING:
            return slot.settings_transactions_limit_for_lending > currentTransactionAmmount;
        case TransactionType.RETURN:
            return slot.settings_transactions_limit_for_return > currentTransactionAmmount;
        default:
            return false;
    }
}

export function getEmptySlots(slots: Slot[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] {
    return slots.filter((slot) => {
        const slotFound = slotsMap.get(slot.id);
        if (!slotFound) {
            return slot;
        }
    });
}

/**
 * Checks if the given slot does not contain any transaction
 * @param slot
 * @param slotsMap
 * @returns True if the slot is empty
 */
export function isEmptySlot(slot: Slot, slotsMap: Map<string, SlotForTransaction[]>): boolean {
    return !slotsMap.get(slot.id);
}

export function getNonEmptySlots(slots: Slot[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] {
    return slots.filter((slot) => slotsMap.get(slot.id));
}

export function getNonFullSlots(slots: Slot[], slotsMap: Map<string, SlotForTransaction[]>, transactionType: TransactionType): Slot[] {
    return slots.filter((slot) => {
        const transactions = slotsMap.get(slot.id);
        let transactionLimit = 0;
        switch (transactionType) {
            case TransactionType.RETURN:
                transactionLimit = slot.settings_transactions_limit_for_return;
                break;
            case TransactionType.VENDING:
                transactionLimit = slot.settings_transactions_limit_for_vending;
                break;
            case TransactionType.PUDO:
                transactionLimit = slot.settings_transactions_limit_for_pudo;
                break;
        }
        if (transactions === undefined) {
            return transactionLimit > 0;
        }
        return transactions.filter((t) => t.transaction.type === transactionType).length < transactionLimit;
    });
}

export function getSlotsWithTheSameProduct(slots: Slot[], products: Product[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] {
    return slots.filter((slot) => {
        const transactions = slotsMap.get(slot.id);
        if (transactions) {
            const transaction = transactions![0].transaction;
            if (products[0] && transaction.product_instances[0]?.product === products[0].id) {
                return true;
            }
        }
        return false;
    });
}
/**
 * This function will filter the slots based on the product that is linked to it.
 * @param slots
 * @param product
 * @returns
 */
export function getSlotsWithProductLinked(slots: Slot[], product: Product): Slot[] {
    return slots.filter((slot) => isSlotLinkedToProduct(slot, product));
}
/**
 * This function will return true if the given slot has the product assigned to it.
 * @param slot
 * @param product
 * @returns
 */
export function isSlotLinkedToProduct(slot: Slot, product: Product): boolean {
    return product.slots.includes(slot.real_id);
}

/**
 * This function will return all products without a product assigned to it.
 * @param slots
 * @returns
 */
export function getSlotsWithoutAssignedProducts(slots: Slot[]): Slot[] {
    return slots.filter((slot) => !doesSlotHaveProductsAssigned(slot));
}

/**
 * This function returns true if the slot has at least one product assigned to it.
 * @param slot
 * @returns
 */
export function doesSlotHaveProductsAssigned(slot: Slot): boolean {
    return slot.assigned_products.length > 0;
}

//transaction type moet hier nu ook mee
// en dan beslissen wat er moet gebeuren per transactie type
export function getWithSameProduct(slots: Slot[], products: Product[], slotsMap: Map<string, SlotForTransaction[]>): Slot[] {
    let result: Slot[] = slots;
    const product = products[0] ? products[0] : undefined;
    if (product) {
        result = result.filter((s) => {
            const slotWithTransactions = slotsMap.get(s.id);
            if (slotWithTransactions) {
                //check if slot has a transaction already (if so go on unless it is a return)
                let validslot = null;
                slotWithTransactions.forEach((slotForTrans) => {
                    if (slotForTrans.transaction.type === TransactionType.VENDING) {
                        if (differentProductsAllowed(slotForTrans.transaction.type, s)) {
                            validslot = s;
                        } else {
                            const productInTransaction = slotForTrans.transaction.product_instances[0];
                            if (productInTransaction && productInTransaction.product === product?.id) {
                                validslot = s;
                            }
                        }
                    }
                });
                if (validslot != null) {
                    return validslot;
                }
            } else {
                return s;
            }
        });
    }
    return result;
}

export function differentProductsAllowed(transactionType: TransactionType, slot: Slot): boolean {
    if (isStockSlot(slot)) return true;
    switch (transactionType) {
        case TransactionType.VENDING:
            return !slot.settings_allow_same_vending_products;
    }
    return false;
}

export function getValidSlotForSharedDropoff(
    transactionSlotMap: Map<string, SlotForTransaction[]>,
    transactions: Transaction[],
    contactOrGroup: Contact | ContactGroup,
    contactGroups: ContactGroup[],
    contacts: Contact[],
    transactionType: TransactionType,
    sharingContactGroups: number[]
): Slot[] {
    let contactTransactions: Transaction[] = [];
    if (isContact(contactOrGroup)) {
        contactTransactions = filterTransactionsByReceiver(transactions, contactOrGroup.id);
        if (contactTransactions.length === 0) {
            const groups = filterContactGroupsByContact(contactGroups, contactOrGroup).filter((group) => sharingContactGroups.includes(group.id));
            contactTransactions = getTransactionsFromContactGroups(transactions, groups, contacts);
        }
    } else {
        if (!sharingContactGroups.includes(contactOrGroup.id)) return [];
        const receivers = filterContactsByContactGroupId(contacts, contactOrGroup.id);
        receivers.forEach((receiver) => {
            contactTransactions.push(...filterTransactionsByReceiver(transactions, receiver.id));
        });
    }

    //Filter all transactions that are in moving state
    contactTransactions = contactTransactions.filter((tr) => tr.slot_reservation_aux === undefined);

    return getNonFullSlots(getValidSlotWithTransactions(transactionSlotMap, contactTransactions), transactionSlotMap, transactionType);
}

export function hasReceiverTransaction(slot: Slot, slotsMap: Map<string, SlotForTransaction[]>, receiver: Contact): boolean {
    return !!slotsMap.get(slot.id)?.find((t) => t.transaction.receiver && t.transaction.receiver.id !== null && t.transaction.receiver?.id === receiver.id);
}

export function hasReceiverGroupsTransaction(slot: Slot, slotsMap: Map<string, SlotForTransaction[]>, receiver: ContactGroup): boolean {
    return !!slotsMap
        .get(slot.id)
        ?.find((t) => t.transaction.receiver_group && t.transaction.receiver_group.id !== null && t.transaction.receiver_group?.id === receiver.id);
}

/**
 * Will remove the stockSlots from the array if it does not make the array empty.
 * @param slots
 * @returns non stock slots if available otherwise it will return the input param slots.
 */
export function preferNonStockSlot(slots: Slot[]): Slot[] {
    const nonStockSlots = getNonStockSlots(slots);
    if (nonStockSlots.length > 0) {
        return nonStockSlots;
    }
    return slots;
}

/**
 * Will filter the slots param for non stock slots.
 * @param slots
 * @returns all found nonstock slots inside an array.
 */
export function getNonStockSlots(slots: Slot[]): Slot[] {
    return slots.filter((slot) => !isStockSlot(slot));
}

/**
 * Will filter the slots param for stock slots.
 * @param slots
 * @returns all found stock slots inside an array.
 */
export function getStockSlots(slots: Slot[]): Slot[] {
    return slots.filter((slot) => isStockSlot(slot));
}

/**
 * @param slot
 * @returns true if it is a stock slot.
 */
export function isStockSlot(slot: Slot): boolean {
    return slot.type === SlotType.STOCK;
}

/**
 * Will filter the slots param for slots that allow returns.
 * @param slots
 * @returns all slots that allow returns inside an array.
 */
export function getReturnSlots(slots: Slot[]): Slot[] {
    return slots.filter((slot) => isReturnSlot(slot));
}

/**
 * @param slot should have settings.transactions_limit_for_return.
 * @returns true if returns are allowed on this slot.
 */
export function isReturnSlot(slot: Slot): boolean {
    return slot.settings_transactions_limit_for_return > 0;
}

/**
 * Checks if the given slot accepts the given transactiontype.
 * @param slot
 * @param transacionType
 * @returns True if the transactionType is allowed in the slot.
 */
export function slotAcceptsTransactionType(slot: Slot, transacionType: TransactionType): boolean {
    switch (transacionType) {
        case TransactionType.PUDO:
            return slot.settings_transactions_limit_for_pudo > 0;
        case TransactionType.VENDING:
            return slot.settings_transactions_limit_for_vending > 0;
        case TransactionType.LENDING:
            return slot.settings_transactions_limit_for_lending > 0;
        case TransactionType.RETURN:
            return slot.settings_transactions_limit_for_return > 0;

        default:
            return false;
    }
}

/**
 * Will filter the slots param for slots that allow vending.
 * @param slots
 * @returns all slots that allow vending inside an array.
 */
export function getVendingSlots(slots: Slot[]): Slot[] {
    return slots.filter((slot) => isVendingSlot(slot));
}

/**
 * @param slot should have settings.transactions_limit_for_vending.
 * @returns true if vending are allowed on this slot.
 */
export function isVendingSlot(slot: Slot): boolean {
    return slot.settings_transactions_limit_for_vending > 0;
}

/**
 * Will filter the slots param for slots that allow lending.
 * @param slots
 * @returns all slots that allow lending inside an array.
 */
export function getLendingSlots(slots: Slot[]): Slot[] {
    return slots.filter((slot) => isLendingSlot(slot));
}

/**
 * @param slot should have settings.transactions_limit_for_lending.
 * @returns true if lending are allowed on this slot.
 */
export function isLendingSlot(slot: Slot): boolean {
    return slot.settings_transactions_limit_for_lending > 0;
}

/**
 * Will filter the slots param for slots that allow pudo.
 * @param slots
 * @returns all slots that allow pudo inside an array.
 */
export function getPuDoSlots(slots: Slot[]): Slot[] {
    return slots.filter((slot) => isPuDoSlot(slot));
}

/**
 * @param slot should have settings.transactions_limit_for_pudo.
 * @returns true if pudo are allowed on this slot.
 */
export function isPuDoSlot(slot: Slot): boolean {
    return slot.settings_transactions_limit_for_pudo > 0;
}
