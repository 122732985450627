import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router } from 'react-router-dom';

import GlobalErrorBoundary from './GlobalErrorBoundary';
import TerminalWorkflow from './TerminalWorkflow';
import useKeepAlive from './hooks/useKeepAlive';
import useWebsocket from './websocket/useWebsocket';

const Terminal = () => {
    const websocket = useWebsocket();
    useKeepAlive();

    return (
        <GlobalErrorBoundary websocket={websocket}>
            <Router>
                <Container
                    fluid={true}
                    className='d-flex flex-column flex-grow-1'>
                    <TerminalWorkflow />
                </Container>
            </Router>
        </GlobalErrorBoundary>
    );
};

export default Terminal;
