import { TransactionType } from '../../../common/transactions';

const TransactionTypePill = (props: { type: TransactionType }) => {
    switch (props.type) {
        case TransactionType.PUDO:
            return <span className='text-success fw-bold me-2'>PuDo</span>;
        case TransactionType.VENDING:
            return <span className='text-warning fw-bold me-2'>VENDING</span>;
        case TransactionType.RETURN:
            return <span className='text-danger fw-bold me-2'>RETURN</span>;
        case TransactionType.LENDING:
            return <span className='text-secondary fw-bold me-2'>LENDING</span>;
    }
    return <></>;
};

export default TransactionTypePill;
