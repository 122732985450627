import { useState } from 'react';

import HowDoesItWorkPart1View from '../../views/nike/how_does_it_work/HowDoesItWorkPart1View';
import HowDoesItWorkPart2View from '../../views/nike/how_does_it_work/HowDoesItWorkPart2View';

enum Step {
    STEP_1 = 'step1',
    STEP_2 = 'step2'
}

interface HowDoesItWorkProps {
    onLogout?: () => void;
    onInactivity?: () => void;
    onDone?: () => void;
}

function HowDoesItWorkWorkflow(props: HowDoesItWorkProps) {
    const [step, changeStep] = useState(Step.STEP_1);

    if (step === Step.STEP_1) {
        return (
            <HowDoesItWorkPart1View
                onInactivity={props.onInactivity}
                onLogout={props.onLogout}
                onNext={() => {
                    changeStep(Step.STEP_2);
                }}
            />
        );
    } else if (step === Step.STEP_2) {
        return (
            <HowDoesItWorkPart2View
                onInactivity={props.onInactivity}
                onLogout={props.onLogout}
                onNext={() => undefined}
            />
        );
    } else {
        throw new Error('Unexpected step');
    }
}

export default HowDoesItWorkWorkflow;
