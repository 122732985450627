import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Locale } from './App';
import { AccessToken } from './Connection';
import { PrefetchContent } from './PrefetchContent';
import { ServiceWorkerInit } from './ServiceWorkerInit';
import Terminal from './Terminal';
import { Terminal as TerminalData, fetchTerminal } from './api/terminals';
import useIncomingKeepAlive from './hooks/keepAlive/useIncomingKeepAlive';
import { useAppSelector } from './hooks/redux';
import useSlotStateValue from './hooks/slotState/useSlotStateValue';
import useBackendStatusUpdate from './hooks/useBackendStatusUpdate';
import useLocalStorage from './hooks/useLocalStorage';
import { nextAttempt } from './hooks/usePinProtection';
import useStandby from './hooks/useStandby';
import useTerminalStartupHealthCheck from './hooks/useTerminalStartupHealthCheck';
import useInitialization from './initialization/useInitialization';
import useInitializeServiceWorker from './initialization/useInitializeServiceWorker';
import useRequiresAuthCheck from './initialization/useRequiresAuthCheck';
import useTokenRetry from './initialization/useTokenRetry';
import { Logger } from './logs/Logger';
import telloportLogo from './static/DistriSPOT Logo.png';
import HardwareConnectionComponent, { useHardwareConnection } from './terminal/useHardwareConnection';
import ResetView from './views/ResetView';
import StandByView from './views/StandByView';
import executeWebsocketCommand, { WebsocketCommandResponse } from './websocket/MainWebsocketCommands';
import useManagedWebsocket, { WebsocketCommandRequest, WebsocketCommandResponseStatus } from './websocket/useManagedWebsocket';

export interface TerminalContextInterface {
    terminal: TerminalData | null;
    changeTerminal: (terminal: TerminalData | null) => void;
    apiUnauthenticated: () => void;
    accessToken: AccessToken | null;
    includeAccessToken: boolean;
    pinAttempts: Date[];
    addPinAttempt: (success: boolean, date?: Date) => Date | null;
    serviceWorkerInit: ServiceWorkerInit;
    currentLocale?: Locale;
    changeCurrentLocale: (locale: Locale) => void;
    websocket: WebSocket | null;
    themeLogo: string;
    changeThemeLogo: (themeLogo: string) => void;
}

export enum TerminalInitState {
    WAITING = 'waiting',
    INIT = 'init',
    DONE = 'done',
    ERROR = 'error'
}

export enum ServiceWorkerInitState {
    INIT = 'init',
    REQUIRES_AUTH = 'requires_auth',
    REFRESH_FAILED = 'refresh_failed',
    RETRY_REFRESH = 'retry_refresh',
    DONE = 'done',
    UNAVAILABLE = 'unavailable',
    ERROR = 'error'
}

export const TerminalContext = React.createContext<TerminalContextInterface>({
    apiUnauthenticated: () => undefined,
    terminal: null,
    changeTerminal: (terminal) => undefined,
    accessToken: null,
    includeAccessToken: false,
    pinAttempts: [],
    addPinAttempt: (success, date) => new Date(),
    serviceWorkerInit: new ServiceWorkerInit(),
    changeCurrentLocale: (locale: Locale) => undefined,
    websocket: null,
    themeLogo: telloportLogo,
    changeThemeLogo: (themeLogo) => undefined
});

interface TerminalInitProps {
    currentLocale: Locale;
    changeCurrentLocale: (locale: Locale) => void;
}

const TerminalInit = (props: TerminalInitProps) => {
    const [initTerminal, initTerminalChange] = useState(TerminalInitState.INIT);
    const [initServiceWorker, initServiceWorkerChange] = useState(ServiceWorkerInitState.INIT);
    const [cacheReset, changeCacheReset] = useState(false);
    const [accessToken, changeAccessToken] = useState<AccessToken | null>(null);
    const [serviceWorkerInit] = useState(() => {
        return new ServiceWorkerInit();
    });
    const [terminal, changeTerminal] = useLocalStorage<TerminalData | null>('terminal', null);
    const [pinAttemptsRaw, changePinAttempts] = useLocalStorage<Array<Date | string>>('pinAttempts', []);
    const pinAttempts: Date[] = pinAttemptsRaw.map((value) => new Date(value));
    const [themeLogo, changeThemeLogo] = useState<string>(telloportLogo);
    const connection = useHardwareConnection();

    const queryClient = useQueryClient();

    useStandby({ terminal: terminal });
    useTerminalStartupHealthCheck({ initialTerminalState: initTerminal, initialServiceWorkerState: initServiceWorker });
    const { timeToRetryRefresh, onServiceWorkerInitResponse } = useTokenRetry({
        serviceWorker: serviceWorkerInit,
        initialServiceWorkerState: initServiceWorker,
        changeInitialServiceWorkerState: initServiceWorkerChange
    });

    //Websocket logic
    const [managedWebsocket, startWs] = useManagedWebsocket({
        onCommand: (command) => changeLastCommandReceived(command),
        terminal: terminal
    });

    useEffect(() => {
        // Start websocket connection if terminal connection found
        if (connection != null) {
            startWs();
        }
    });

    useIncomingKeepAlive();
    useBackendStatusUpdate({ terminal: terminal, websocket: managedWebsocket });

    const { fullStateCheck } = useSlotStateValue(terminal, managedWebsocket);
    const [lastCommandReceived, changeLastCommandReceived] = useState<WebsocketCommandRequest | undefined>(undefined);
    const spotLayoutLogsState = useAppSelector((state) => state.spotLayoutLogs);

    useEffect(() => {
        if (lastCommandReceived && managedWebsocket && connection) {
            executeWebsocketCommand(
                lastCommandReceived,
                connection,
                serviceWorkerInit,
                spotLayoutLogsState,
                managedWebsocket,
                terminal,
                changeTerminal,
                changeCacheReset,
                fullStateCheck
            )
                .then((value: WebsocketCommandResponse | void) => {
                    if (value && value.send === true) {
                        if (value.success) {
                            managedWebsocket?.send(
                                JSON.stringify({
                                    additional_data: lastCommandReceived.additional_data,
                                    message: value.message !== undefined ? value.message : 'Successfully executed ' + lastCommandReceived.command + ' command.',
                                    status: WebsocketCommandResponseStatus.SUCCESS,
                                    command: lastCommandReceived.command,
                                    terminal: terminal
                                })
                            );
                        } else {
                            managedWebsocket?.send(
                                JSON.stringify({
                                    additional_data: lastCommandReceived.additional_data,
                                    message:
                                        value.message !== undefined
                                            ? value.message
                                            : 'Something went wrong trying to execute the ' + lastCommandReceived.command + ' command.',
                                    status: WebsocketCommandResponseStatus.ERROR,
                                    command: lastCommandReceived.command,
                                    terminal: terminal
                                })
                            );
                        }
                    }
                })
                .catch((error) => {
                    Logger.error('Uncaught exception inside websocket command.', {}, error);
                    managedWebsocket?.send(
                        JSON.stringify({
                            additional_data: lastCommandReceived.additional_data,
                            message: 'Something went wrong trying to execute ' + lastCommandReceived.command + '.',
                            status: WebsocketCommandResponseStatus.ERROR,
                            command: lastCommandReceived.command,
                            terminal: terminal
                        })
                    );
                });
        }
    }, [lastCommandReceived]);
    //Websocket logic

    // Perform cache reset if requested and service worker initialized
    useEffect(() => {
        if (initServiceWorker !== ServiceWorkerInitState.INIT && cacheReset) {
            changeCacheReset(false);
            serviceWorkerInit
                .reset(false)
                .then(() => {
                    queryClient.clear();
                    const cacheResetComplete = () => {
                        Logger.log('Cache reset complete, refresh page');
                        window.location.href = '/';
                    };

                    if (terminal) {
                        // Perform a manual fetch of new terminal data
                        fetchTerminal(queryClient, terminal?.id, undefined, {
                            includeAccessToken: !serviceWorkerInit.serviceWorkerAvailable(),
                            accessToken: accessToken
                        })
                            .then((terminal) => {
                                changeTerminal(terminal);
                                setTimeout(cacheResetComplete, 1000); // Wait one second until we can be certain the terminal has changed
                            })
                            .catch(() => {
                                Logger.error('Could not reset terminal');
                                window.location.href = '/';
                            });
                    } else {
                        cacheResetComplete();
                    }
                })
                .catch((error) => {
                    Logger.error('Could not direct reset service worker', {}, error);
                    window.location.href = '/';
                });
        } else if (cacheReset) {
            Logger.debug('Waiting to perform cache reset but service worker is not initialized');
        }
    }, [initServiceWorker, cacheReset]);

    useInitializeServiceWorker({
        serviceWorker: serviceWorkerInit,
        serviceWorkerInitState: initServiceWorker,
        onResponse: onServiceWorkerInitResponse,
        onError: () => initServiceWorkerChange(ServiceWorkerInitState.ERROR)
    });

    useEffect(() => {
        if (
            (initServiceWorker === ServiceWorkerInitState.REQUIRES_AUTH ||
                initServiceWorker === ServiceWorkerInitState.REFRESH_FAILED ||
                initServiceWorker === ServiceWorkerInitState.UNAVAILABLE ||
                initServiceWorker === ServiceWorkerInitState.DONE) &&
            initTerminal === TerminalInitState.WAITING
        ) {
            Logger.debug('Starting library init');
            initTerminalChange(TerminalInitState.INIT);
        }
    }, [serviceWorkerInit, initServiceWorker, initTerminal]);

    useRequiresAuthCheck({
        serviceWorker: serviceWorkerInit,
        serviceWorkerInitState: initServiceWorker,
        accessToken,
        changeAccessToken,
        changeServiceWorkerInitState: initServiceWorkerChange
    });

    const initDone = (accessToken: AccessToken | null) => {
        Logger.debug(
            'init done setting accesstoken',
            {},
            accessToken !== null
                ? {
                      ...accessToken,
                      accessToken: accessToken.accessToken !== null ? Logger.generateHash(accessToken.accessToken) : null,
                      refreshToken: accessToken.refreshToken !== null ? Logger.generateHash(accessToken.refreshToken) : null
                  }
                : null
        );
        changeAccessToken(accessToken);
        initTerminalChange(TerminalInitState.DONE);
    };

    useEffect(() => {
        if (terminal !== null && managedWebsocket !== null) {
            managedWebsocket.send(
                JSON.stringify({
                    command: 'terminalChange',
                    terminal: terminal
                })
            );
        }
    }, [terminal, managedWebsocket]);

    const { view, initializationDone } = useInitialization({
        connection: connection,
        serviceWorkerInit,
        terminal,
        initialServiceWorkerState: initServiceWorker,
        initialTerminalState: initTerminal,
        accessToken,
        timeToRefresh: timeToRetryRefresh,
        changeTerminal
    });

    let content;
    if (!initializationDone) {
        content = view;
    } else {
        terminal?.settings_door_open_time_in_seconds !== null && connection?.setConfiguration(terminal?.settings_door_open_time_in_seconds);
        content = (
            <PrefetchContent>
                <Terminal />
            </PrefetchContent>
        );
    }

    const addPinAttempt = (success: boolean, date?: Date): Date | null => {
        // The return value is when the next attempt can be performed.
        if (success) {
            changePinAttempts([]);
            return null;
        } else {
            const modifiedPinAttempts = pinAttempts.concat(date ? date : new Date());
            changePinAttempts(modifiedPinAttempts);
            return nextAttempt(modifiedPinAttempts);
        }
    };

    const terminalContext = {
        terminal: terminal,
        changeTerminal: changeTerminal,
        apiUnauthenticated: () => undefined,
        accessToken: accessToken,
        includeAccessToken: !serviceWorkerInit.serviceWorkerAvailable(),
        pinAttempts: pinAttempts,
        addPinAttempt: addPinAttempt,
        serviceWorkerInit: serviceWorkerInit,
        currentLocale: props.currentLocale,
        changeCurrentLocale: props.changeCurrentLocale,
        websocket: managedWebsocket,
        themeLogo: themeLogo,
        changeThemeLogo: changeThemeLogo
    };

    return (
        <div className='min-vh-100 d-flex flex-column'>
            <TerminalContext.Provider value={terminalContext}>
                <HardwareConnectionComponent
                    init={{
                        requiresToken: initServiceWorker === ServiceWorkerInitState.UNAVAILABLE || initServiceWorker === ServiceWorkerInitState.REQUIRES_AUTH,
                        initDone: initDone,
                        initError: () => {
                            initTerminalChange(TerminalInitState.ERROR);
                            Logger.error('initError');
                        }
                    }}
                    {...props}>
                    <Router>
                        <Switch>
                            <Route path='/reset'>
                                <ResetView />
                            </Route>
                            <Route path='/standby'>
                                <StandByView />
                            </Route>
                            <Route path='/'>
                                <div className='flex-grow-1 d-flex'>{content}</div>
                            </Route>
                        </Switch>
                    </Router>
                </HardwareConnectionComponent>
            </TerminalContext.Provider>
        </div>
    );
};

export default TerminalInit;
