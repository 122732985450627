import { WebsocketCommandResponse } from '../MainWebsocketCommands';
import { WebsocketCommand } from '../useManagedWebsocket';

/**
 * This command will make the terminal refresh.
 */
export class WebsocketRefreshCommand extends WebsocketCommand {
    public async execute(): Promise<WebsocketCommandResponse> {
        window.location.href = '/';
        return { success: true };
    }
}
