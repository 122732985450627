import { useState } from 'react';
import { Collapse, Form, Toast } from 'react-bootstrap';

import { TerminalEvents } from '../events/terminal/Events';

const Scanner = () => {
    const [scannerValue, changeScannerValue] = useState('');
    const [scannerOpen, changeScannerOpen] = useState(false);

    return (
        <Toast
            style={{
                position: 'absolute',
                top: 0,
                left: 0
            }}>
            <Toast.Header closeButton={false}>
                <strong className='me-auto'>Scanner</strong>
                <a
                    className='ms-2'
                    href='/scanner/'
                    onClick={(event) => {
                        event.preventDefault();
                        changeScannerOpen(!scannerOpen);
                    }}>
                    {scannerOpen ? 'close' : 'open'}
                </a>
            </Toast.Header>
            <Collapse in={scannerOpen}>
                <div>
                    <Toast.Body>
                        <Form
                            onSubmit={(event) => {
                                event.preventDefault();
                                window.dispatchEvent(new CustomEvent(TerminalEvents.SCANNER_RESULT, { detail: scannerValue }));
                                changeScannerValue('');
                            }}>
                            <Form.Control
                                type='password'
                                value={scannerValue}
                                onChange={(event) => {
                                    changeScannerValue(event.target.value);
                                }}
                            />
                        </Form>
                    </Toast.Body>
                </div>
            </Collapse>
        </Toast>
    );
};

export default Scanner;
