import { memo, useContext, useEffect, useState } from 'react';

import { TerminalContext, TerminalContextInterface } from '../../TerminalInit';
import { Slot, useSlotsForTransactions } from '../../api/slots';
import { useSpotLayoutItems } from '../../api/spotLayoutItems';
import { useSpotLayout } from '../../api/spotLayouts';
import { SpotType, useSpot } from '../../api/spots';
import { useMutateTransactionStatus } from '../../api/transactions';
import { Transaction, TransactionStatusEventType } from '../../common/transactions';
import useActivity from '../../hooks/useActivity';
import useOpenSlot from '../../hooks/useOpenSlot';
import { Logger } from '../../logs/Logger';
import { SlotOpenInfo, SlotOpenState, SlotOpenView } from '../../views/SlotOpenView';
import PickupView from '../../views/nike/PickupView';

interface PickupWorkflowProps {
    transactions: Transaction[];
    onCollectOther?: () => void;
    onHome?: () => void;
    onInactivity?: () => void;
    onLogout?: () => void;
}

interface OpenSlotActionProps {
    transaction: Transaction;
    slot: Slot;
    propsOnStateChange?: (state: SlotOpenState) => void;
}

const OpenSlotAction = (props: OpenSlotActionProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spotLayoutItems } = useSpotLayoutItems({ spotLayout: spotLayout }, { enabled: !!spotLayout });
    const [slotOpening, changeSlotOpening] = useState(false);
    const [slotOpenState, changeSlotOpenState] = useState(SlotOpenState.IN_PROGRESS);

    const spotLayoutItem = spotLayoutItems ? spotLayoutItems.find((li) => li.slot && li.slot === props.slot.id) : undefined;

    const transactionStatusMutation = useMutateTransactionStatus();
    const [openSlot] = useOpenSlot();

    useEffect(() => {
        const doOpen = async () => {
            await openSlot({ spotLayoutItem: spotLayoutItem! })
                .then((result) => {
                    doPickup();
                })
                .catch((error) => {
                    Logger.error(error);
                    changeSlotOpenState(SlotOpenState.ERROR);
                });
        };
        if (!slotOpening && spotLayoutItem) {
            changeSlotOpening(true);
            doOpen();
        }
    }, [slotOpening, spotLayoutItem]);

    const { propsOnStateChange } = props;

    function doPickup() {
        changeSlotOpenState(SlotOpenState.SUCCESS);
        transactionStatusMutation.mutate({
            transaction: props.transaction,
            transactionStatusUpdate: {
                event: TransactionStatusEventType.PICKUP,
                pickup: {
                    receiver: props.transaction.receiver
                        ? typeof props.transaction.receiver.id === 'number'
                            ? props.transaction.receiver.id
                            : parseInt(props.transaction.receiver.id)
                        : null
                }
            }
        });
    }

    useEffect(() => {
        if (propsOnStateChange) {
            propsOnStateChange(slotOpenState);
        }
    }, [slotOpenState]);

    return <></>;
};

const PickupWorkflowFunction = (props: PickupWorkflowProps) => {
    const { terminal } = useContext<TerminalContextInterface>(TerminalContext);
    const { data: spotLayout } = useSpotLayout(terminal?.spot_layout_url);
    const { data: spot } = useSpot(spotLayout?.spot_url);
    //somewhere is an infinite loop
    const [transactionsToOpen, changeTransactionsToOpen] = useState<Transaction[]>([]);
    const [slotsOpeningState, changeSlotsOpeningState] = useState<Map<string, SlotOpenState>>(new Map());
    const slotsResult = useSlotsForTransactions(transactionsToOpen, { enabled: transactionsToOpen.length > 0 });

    const [, newActivity] = useActivity();

    useEffect(() => {
        Logger.log('slot state changed to: ' + slotsOpeningState);
    }, [slotsOpeningState]);

    const onOpen = (transactions: Transaction[]) => {
        newActivity();
        Logger.log('Open transactions', {}, transactions);
        changeTransactionsToOpen(transactions);
        const newSlotsOpeningState: Map<string, SlotOpenState> = new Map();
        for (const transaction of transactions) {
            if (transaction.slot_id) {
                newSlotsOpeningState.set(transaction.slot_id, SlotOpenState.IN_PROGRESS);
            }
        }
        changeSlotsOpeningState(newSlotsOpeningState);
    };

    if (transactionsToOpen.length === 0) {
        return (
            <PickupView
                transactions={props.transactions}
                onOpen={onOpen}
                onHome={props.onHome}
                onInactivity={props.onInactivity}
            />
        );
    } else {
        const slotInfos: SlotOpenInfo[] = slotsResult
            .map<SlotOpenInfo | null>((sr) => {
                if (sr.data && slotsOpeningState.has(sr.data.id)) {
                    return {
                        slot: sr.data,
                        state: slotsOpeningState.get(sr.data!.id)!
                    };
                }
                return null;
            })
            .filter((soi): soi is SlotOpenInfo => soi !== null);

        return (
            <>
                {slotInfos.map((slotOpenInfo, index) => {
                    return (
                        <OpenSlotAction
                            key={transactionsToOpen[index].id}
                            transaction={transactionsToOpen[index]}
                            slot={slotOpenInfo.slot!}
                            propsOnStateChange={(state: SlotOpenState) => {
                                const slotId = transactionsToOpen[index].slot_id;
                                if (slotId !== undefined) {
                                    changeSlotsOpeningState((prevSlotsOpeningState) => {
                                        const newSlotsOpeningState = new Map(prevSlotsOpeningState);
                                        newSlotsOpeningState.set(slotId, state);
                                        return newSlotsOpeningState;
                                    });
                                }
                            }}
                        />
                    );
                })}
                <SlotOpenView
                    spotType={spot ? spot.type : SpotType.STANDARD_DISTRISPOT}
                    slotInfos={slotInfos}
                    onInactivity={props.onInactivity}
                    onHome={props.onHome}
                />
            </>
        );
    }
};

export const PickupWorkflow = memo(PickupWorkflowFunction);
