import { Contact, isContact } from '../../api/contacts';

export interface AuthenticatedContact extends Contact {
    additional_data: AuthenticatedContactAditionalData;
}

export interface AuthenticatedContactAditionalData {
    role: AuthenticatedContactRoles;
}

export enum AuthenticatedContactRoles {
    ADMIN = 'admin',
    NOODDORP_DELIVERY = 'nooddorp_delivery',
    DELIVERY_NS = 'deliveryNS',
    DELIVERY = 'standard_delivery',
    EMPLOYEE = 'employeeNS',
    NIKE_DELIVERY = 'delivery',
    USER = 'user',
    LAUNDRY = 'laundry',
    NS = 'ns',
    OPEN_DROPOFF_ALLOWED = 'open_dropoff_allowed'
}

export function isAdmin(contact: Contact | undefined) {
    return isGlobalContact(contact) && contact.additional_data.role === AuthenticatedContactRoles.ADMIN;
}

export function isDelivery(contact: Contact | undefined) {
    return (
        isGlobalContact(contact) &&
        (contact.additional_data.role === AuthenticatedContactRoles.DELIVERY_NS ||
            contact.additional_data.role === AuthenticatedContactRoles.NIKE_DELIVERY ||
            contact.additional_data.role === AuthenticatedContactRoles.NOODDORP_DELIVERY ||
            contact.additional_data.role === AuthenticatedContactRoles.DELIVERY)
    );
}

export function isNoodorpDelivery(contact: Contact | undefined) {
    return isGlobalContact(contact) && contact.additional_data.role === AuthenticatedContactRoles.NOODDORP_DELIVERY;
}

export function isOpenDropoffAllowed(contact: Contact | undefined) {
    return isGlobalContact(contact) && contact.additional_data.role === AuthenticatedContactRoles.OPEN_DROPOFF_ALLOWED;
}

export function isNSDelivery(contact: Contact | undefined) {
    return isGlobalContact(contact) && contact.additional_data.role === AuthenticatedContactRoles.DELIVERY_NS;
}

export function isNSEmployee(contact: Contact | undefined) {
    return isGlobalContact(contact) && contact.additional_data.role === AuthenticatedContactRoles.EMPLOYEE;
}

export function isUser(contact: Contact | undefined) {
    return isGlobalContact(contact) && contact.additional_data.role === AuthenticatedContactRoles.USER;
}

export function isLaundry(contact: Contact | undefined) {
    return isGlobalContact(contact) && contact.additional_data.role === AuthenticatedContactRoles.LAUNDRY;
}

export function isGlobalContact(contact: Contact | null | undefined): contact is AuthenticatedContact {
    return contact !== null && contact !== undefined && isContact(contact) && isGlobalContactAditionalData(contact.additional_data);
}

function isGlobalContactAditionalData(additional_data: unknown): additional_data is AuthenticatedContactAditionalData {
    return (
        additional_data !== undefined &&
        additional_data !== null &&
        (additional_data as AuthenticatedContactAditionalData).role !== undefined &&
        (additional_data as AuthenticatedContactAditionalData).role !== null &&
        isGlobalContactRole((additional_data as AuthenticatedContactAditionalData).role)
    );
}

function isGlobalContactRole(role: unknown): role is AuthenticatedContactRoles {
    return (
        role !== undefined && role !== null && typeof role == 'string' && Object.values(AuthenticatedContactRoles).includes(role as AuthenticatedContactRoles)
    );
}
